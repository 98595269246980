export class Variables {
  //Dev API

  // Url = "http://localhost:8082/";
  // clientId = "9a7ed85c-a7a7-4c00-b5fe-751019042dad";
  // redirectUri= "http://localhost:3000";

 //internal test API

//  redirectUri= "https://egratistest.global.umusic.net/";
//  Url="https://egratistest.sevice.umusic.net/"  
//  clientId = "9a7ed85c-a7a7-4c00-b5fe-751019042dad";

//extrnal Test
 redirectUri= "https://egratistest-inter.umusic.com/"; 
 Url="https://egratistest.sevice-inter.umusic.com/"  
 clientId = "9a7ed85c-a7a7-4c00-b5fe-751019042dad";

 //Url="http://10.254.176.226/" --without sso


  //UAT API
  
 //Url="http://10.254.176.252/"; //new UAT
 
 //PROD API

  
  // redirectUri= "https://egratisprod-inter.umusic.com/"; 
  // Url="https://egratisprod.sevice.umusic.net/"  
  // clientId = "3ee086b2-4b6d-4597-986d-7d8d348de311";

  // redirectUri= "https://egratisprod-inter.umusic.com/"; 
  // Url="https://egratisprod.service-inter.umusic.com/"  
  // clientId = "3ee086b2-4b6d-4597-986d-7d8d348de311";

  // Url = "http://localhost:8082/";
  // redirectUri= "http://localhost:3000";
  //Url = "https://egratis2-service.umusic.net/"
  ReportPath ="http://aws19wvsql086.global.umusic.ext/ReportServer/Pages/ReportViewer.aspx?%2feGratis%2f";

  Controller = {
    login: "login/",
    register: "register/",
    menu: "menu/",
    common: "common/",
    editUser: "editUser/",
    logout: "logout/",
    passwordReset: "passwordReset/",
    userView: "userView/",
    emailPassword: "emailPassword/",
    quickProductSearch: "quickProductSearch/",
    sysop: "sysop/",
    autoAddExecUser: "autoAddExecUser/",
    appSettings: "appSettings/",
    contact: "contact/",
    createOrder: "createOrder/",
    ftpHistory: "ftpHistory/",
    depotOrders: "depotOrders/",
    orderApproval: "orderApproval/",
    orderView: "orderView/",
    ViewOrder: "viewOrder/",
    SearchHistory: "SearchHistory/",
    Report: "Report/",
  };
  loginService = {
    checkUserLocked: "checkUserLocked",
    authenticateUser: "authenticateUser",
    authenticateUserSSO:"authenticateUserSSO",
    authenticateUserSSO1:"authenticateUserSSO1",
    getUserDetails: "getUserDetails",
    loginUser: "loginUser",
    updateUserLocked: "updateUserLocked",
    insertLogDetails:"insertLogDetails"
  };
  commonService = {
    getAllLabel: "getAllLabel",
    getAllDeptForLabel: "getAllDeptForLabel",
    getAllDepartment: "getAllDepartment",
    getApprovalProcess: "getApprovalProcess",
    getSelfApprovers: "getSelfApprovers",
    getProcessLookupUserType: "getProcessLookupUserType",
    getLabel: "getLabel",
  };
  editUserService = {
    getUser: "getUser",
    getUserType: "getUserType",
    getProcessName: "getProcessName",
    updateUser: "updateUser",
    updateSysopUser: "updateSysopUser",
    deleteUser: "deleteUser",
  };
  registerService = {
    checkAliasEmail: "checkAliasEmail",
    getDeptProcessInfo: "getDeptProcessInfo",
    addNewUser: "addNewUser",
    updateSuperExecUser: "updateSuperExecUser",
    getLabel: "getLabel",
    insertPrivateContact: "insertPrivateContact",
    insertPermissionsExceptions: "insertPermissionsExceptions",
  };
  logoutService = {
    updateLoginFlag: "updateLoginFlag",
  };
  menuService = {
    getNTBillableUser: "getNTBillableUser",
  };
  passwordResetService = {
    updateUserPassword: "updateUserPassword",
    checkPreviousPassword: "checkPreviousPassword",
  };

  userViewService = {
    getUserList: "getUserList",
    getUserListByProcessName: "getUserListByProcessName",
  };
  quickProductSearchService = {
    quickProductSearch: "QuickProductSearch",
  };
  emailPasswordService = {
    getPasswordDetails: "getPasswordDetails",
    sendEmail: "sendEmail",
  };
  sysopService = {
    insertDept: "insertDept",
    updateDept: "updateDept",
    getAllProcessForLabel: "getAllProcessForLabel",
    insertLabel: "insertLabel",
    updateLabel: "updateLabel",
    getAllApprovers: "getAllApprovers",
    getApprovalLevel: "getApprovalLevel",
    getApprovalTable: "getApprovalTable",
    getAllProcessForApproverLabel: "getAllProcessForApproverLabel",
    getDefaultProcess: "getDefaultProcess",
    insertApprovalProcess: "insertApprovalProcess",
    addFullLengthCleanApproverToProcess: "addFullLengthCleanApproverToProcess",
    insertCrossLabels: "insertCrossLabels",
    insertLabelExceptions: "insertLabelExceptions",
    getApprovalProcess: "getApprovalProcess",
    countProcessByOrderType: "countProcessByOrderType",
    getApprovalProcessDetails: "getApprovalProcessDetails",
    getApproverNames: "getApproverNames",
    replaceApprover: "replaceApprover",
    addApprover: "addApprover",
    checkAlternateApprovers: "checkAlternateApprovers",
    deleteApprover: "deleteApprover",
    getCrossLabels: "getCrossLabels",
    getLabelExceptions: "getLabelExceptions",
    updateMaxApprovers: "updateMaxApprovers",
    deleteCrossLabels: "deleteCrossLabels",
    deleteLabelExceptions: "deleteLabelExceptions",
    checkIsDefault: "checkIsDefault",
    deleteApproverByProcess: "deleteApproverByProcess",
    getAllProcessByAlias: "getAllProcessByAlias",
    getProcessLookupCounter: "getProcessLookupCounter",
    addApproverForExport: "addApproverForExport",
    checkIsDefaultProcessLookup: "checkIsDefaultProcessLookup",
    addApproverNonExport: "addApproverNonExport",
    getNonExportDefaultProcessCount: "getNonExportDefaultProcessCount",
    updatePermissions: "updatePermissions",
    checkAlternateApprover: "checkAlternateApprover",
  };
  autoAddExecUserService = {
    getExecUserDept: "getExecUserDept",
    deleteExecUserDeptFlag: "deleteExecUserDeptFlag",
    insertExecUserDeptFlag: "insertExecUserDeptFlag",
  };
  appSettingsService = {
    getAllAppSetting: "getAllAppSetting",
    updateAppSetting: "updateAppSetting",
  };
  contactService = {
    selectPersonalContact: "selectPersonalContact",
    selectAllContact: "selectAllContact",
    privateContact: "privateContact",
    getStates: "getStates",
    getAddressValidation: "getAddressValidation",
    getPrivateContact: "getPrivateContact",
  };
  createOrderService = {
    getProductSearch: "getProductSearch",
    getAllProductsByUPC: "getAllProductsByUPC",
    getAllProductsByUPCErrMsg: "getAllProductsByUPCErrMsg",
    getOrderSummary: "getOrderSummary",
    getApproverLevels: "getApproverLevels",
    checkCFOApproval: "checkCFOApproval",
    checkOrderConfiguration: "checkOrderConfiguration",
    updateSelfOrderItems: "updateSelfOrderItems",
    insertTblEmailHistory: "insertTblEmailHistory",
    checkApprovalEmail: "checkApprovalEmail",
    updateCompleteOrder: "updateCompleteOrder",
    insertTblApprovalHistory: "insertTblApprovalHistory",
    getEmailNotice: "getEmailNotice",
    getNotDepotLabelApprover: "getNotDepotLabelApprover",
    updateRejectOrder: "updateRejectOrder",
    getOverNightBillable: "getOverNightBillable",
    insertOrderSummary: "insertOrderSummary",
    updateInsertOrderItems: "updateInsertOrderItems",
    getOrderItemsForByPassApproval: "getOrderItemsForByPassApproval",
    getCompleteOrder: "getCompleteOrder",
    updateOrderItems: "updateOrderItems",
    getOrderDetail: "getOrderDetail",
    getApproverNameComments: "getApproverNameComments",
    getReportingCo: "getReportingCo",
    getApproverEmail: "getApproverEmail",
    sendEmail: "sendEmail",
    getProcessNameforSuperUser: "getProcessNameforSuperUser",
    getProcessNameforRegAndExeUser: "getProcessNameforRegAndExeUser",
    getApprovalProcessValidate: "getApprovalProcessValidate",
    checkSelfApprover: "checkSelfApprover",
    getOrderItems: "getOrderItems",
    getCost: "getCost",
  };
  ftpHistoryService = {
    getFTPOrderHistory: "getFTPOrderHistory",
    resetFTPOrders: "resetFTPOrders",
  };
  depotOrderService = {
    getDepotPendingOrders: "getDepotPendingOrders",
    cancelPendingOrders: "cancelPendingOrders",
    getOrderSummary: "getOrderSummary",
    getDepotHistory: "getDepotHistory",
    getDepotHistoryExport: "getDepotHistoryExport",
    getNextApprover: "getNextApprover",
    getOrderApprovalHistory: "getOrderApprovalHistory",
    getOrderDepotHistory: "getOrderDepotHistory",
    getOrderDetail: "getOrderDetail",
    getFreeGoodsOrderDetail: "getFreeGoodsOrderDetail",
    getOrderDetailsSummary: "getOrderDetailsSummary",
    holdOrder: "holdOrder",
    depotProcessOrder: "depotProcessOrder",
    GetDepotOrderDetail: "getDepotOrderDetail",
    getDepotOrderItemDetail: "getDepotOrderItemDetail",
    getDepotNextApprover: "getDepotNextApprover",
  };
  orderApprovalService = {
    getApproverInbox: "getApproverInbox",
    getApproverHistoryList: "getApproverHistoryList",
  };
  orderViewService = {
    getListOrderView: "getListOrderView",
  };
  viewOrderService = {
    getListViewOrder: "getListViewOrder",
    getOrderSummary: "getOrderSummary",
    getNextApprover: "getNextApprover",
    getApprovalHistory: "getApprovalHistory",
    getOrderDetails: "getOrderDetails",
    getOrderDepotHistory: "getOrderDepotHistory",
    getOrderDepotContact: "getOrderDepotContact",
  };
  SearchHistoryServices = {
    getSearchHistory: "getOrderHistory",
  };
  ReportServices = {
    getUserList: "getUserList",
    getUserActiveList: "getUserActiveList",
  };

  // BEGIN
  thArray = [
    "",
    "Artist",
    "Title",
    "Selection",
    "UPC",
    "Config",
    "Units/Set",
    "Rls Date",
    "Qty",
  ];
  thArrayBillable = [
    "",
    "Artist",
    "Title",
    "Selection",
    "UPC",
    "Config",
    "Units/Set",
    "Rls Date",
    "Qty",
    "Price",
  ];
  thArrayBillableClean = [
    "",
    "Artist",
    "Title",
    "Selection",
    "UPC",
    "Config",
    "Units/Set",
    "Rls Date",
    "Qty",
    "Price",
    "Cost",
    "Discount %",
    "Approval Reqd",
  ];
  thArrayCopy = ["Artist", "Title", "Selection", "Configuration", "Quantity"];
  thArrayCopyBillableClean = [
    "Artist",
    "Title",
    "Selection",
    "Config",
    "Qty",
    "Price",
    "Cost",
    "Discount %",
    "Approval Reqd",
  ];
  thArrayCopyTicketBundlingClean = [
    "Artist",
    "Title",
    "Selection",
    "Config",
    "Qty",
    "Price",
    "Cost",
    "Discount %",
    "Artist StoreName",
    "Final Tour Date (MM/DD/YYYY)",
    "Approval Reqd",
  ];
  thArrayCopyBillable = [
    "Artist",
    "Title",
    "Selection",
    "Config",
    "Qty",
    "Price",
  ];
  thArrayTicketBundlingClean = [
    "",
    "Artist",
    "Title",
    "Selection",
    "UPC",
    "Config",
    "Units/Set",
    "Rls Date",
    "Qty",
    "Price",
    "Cost",
    "Discount %",
    "Artist StoreName",
    "Final Tour Date (MM/DD/YYYY)",
    "Approval Reqd",
  ];
  thFtpHistoryList = [
    "Order Id",
    "Requestor",
    "Request Date",
    "FTP File",
    "FTP Date/Time",
    "Status",
  ];
  thOrderViewList = [
    "Order Id",
    "Requestor",
    "Billable",
    "Overnight",
    "Clean",
    "Status",
    "Req.Wt.",
    "Label",
    "Super Label",
    "Ship Method",
    "Created",
  ];
  thViewOrderList = [
    "",
    "Request Date",
    "Order Id",
    "Ship to",
    "Attention",
    "Status",
  ];
  thOrderDetailList = [
    "Catalog #",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Qty Req.",
    "Qty App.",
    "Depot Status",
  ];
  thApproverHistoryList = [
    "Order Id",
    "Request Date",
    "Ship to",
    "Attention",
    "Status",
  ];
  thOrderDetailBillableList = [
    "Catalog #",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Qty Req.",
    "Qty App.",
    "Price Req.",
    "Price App.",
    "Depot Status",
  ];
  thOrderDetailBillableCleanList = [
    "Catalog #",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Qty Req.",
    "Qty App.",
    "Price Req.",
    "Price App.",
    "Depot Status",
    "Cost",
    "Discount %",
    "Apprl Required",
  ];
  thApproverList = ["Type", "Approver", "Date/Time", "Note"];
  recordsetDepotHistory = { recordset: [{ CreateDateTime: "", Notes: "" }] };
  recordsetApprovalHistory = {
    recordset: [
      {
        Approver: "",
        ApprovalDateTime: "",
        ApproverEmail: "",
        Notes: "",
        ApproverType: "",
        ApproverType_Id: "",
      },
    ],
  };
  thArrayBillableOrderEditClean = [
    "Catalog#",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units /Set",
    " Price Req",
    "Price Appr",
    "Qty",
    "Price",
    "Reject Item",
    "Cost",
    "Discount %",
    "Apprl Req",
  ];
  thArrayBillableOrderEditMarked = [
    "Catalog #",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units /Set",
    "Price Req",
    "Price Appr",
    "Qty",
    "Price",
    "Reject Item",
  ];
  thSearchHistoryList = [
    "Order Id",
    "Requestor",
    "Artist",
    "Title",
    "Selection #",
    "Config",
    "Qty",
    "Ship To Name",
    "Ship To Co",
  ];
  thUserList = [
    "Name",
    "Alias",
    "User Type",
    "Process Name",
    "Label",
    "Department",
    "Business Unit",
    "Customer No",
    "Executive User",
    "Super User",
  ];
  thDepotOrderList = [
    "Catalog #",
    "Artist",
    "Title",
    "Config",
    "Qty",
    "Item Exception",
  ];
  thDepotBillableOrderList = [
    "Catalog #",
    "Artist",
    "Title",
    "Config",
    "Qty",
    "Price",
    "Item Exception",
  ];
  thDepotHistoryList = ["Date/Time", "Note"];
  //END
  tdArray = {
    recordset: [
      {
        Artist: "CHEMICAL BROTHERS",
        Title: "BORN IN THE... (STD)",
        Selection: "2547275264",
        UPC: "00602547275264",
        Config: "CD",
        "Units/Set": "1",
        "Rls Date": "07/17/15",
        Qty: "1",
      },
      {
        Artist: "CHEMICAL BROTHERS",
        Title: "BORN IN THE... (STD)",
        Selection: "2547275264",
        UPC: "00602547275264",
        Config: "CD",
        "Units/Set": "1",
        "Rls Date": "07/17/15",
        Qty: "1",
      },
      {
        Artist: "CHEMICAL BROTHERS",
        Title: "BORN IN THE... (STD)",
        Selection: "2547275264",
        UPC: "00602547275264",
        Config: "CD",
        "Units/Set": "1",
        "Rls Date": "07/17/15",
        Qty: "1",
      },
      {
        Artist: "CHEMICAL BROTHERS",
        Title: "BORN IN THE... (STD)",
        Selection: "2547275264",
        UPC: "00602547275264",
        Config: "CD",
        "Units/Set": "1",
        "Rls Date": "07/17/15",
        Qty: "1",
      },
      {
        Artist: "CHEMICAL BROTHERS",
        Title: "BORN IN THE... (STD)",
        Selection: "2547275264",
        UPC: "00602547275264",
        Config: "CD",
        "Units/Set": "1",
        "Rls Date": "07/17/15",
        Qty: "1",
      },
    ],
  };

  tdArray1 = [
    [
      "CHEMICAL BROTHERS",
      "BORN IN THE... (STD)",
      "2547275264",
      "00602547275264",
      "CD",
      "1",
      "07/17/15",
      "1",
      "1",
      "15",
    ],
    [
      "CHEMICAL BROTHERS",
      "BORN IN THE (2LP SET",
      "2547275288",
      "00602547275288",
      "Album",
      "2",
      "07/17/15",
      "1",
      "1",
      "14",
    ],
    [
      "EMPIRE OF THE SUN",
      "ICE ON THE DUNE",
      "B001847402",
      "00602537375462",
      "CD",
      "1",
      "06/18/13",
      "1",
      "1",
      "13",
    ],
    [" ", " ", " ", " ", " ", " ", " ", " "],
    [" ", " ", " ", " ", " ", " ", " ", " "],
    [" ", " ", " ", " ", " ", " ", " ", " "],
    [" ", " ", " ", " ", " ", " ", " ", " "],
  ];
  tdArray_order = {
    recordset: [
      {
        Order_id: "500612",
        Ship_To: "Music Today",
        Attention: "RAJA PAUL",
        Request_Date: "20/06/2020",
        Status: "Depot Pending",
        Material: "1",
        Billable: "0",
      },
      {
        Order_id: "500611",
        Ship_To: "Ticketmaster",
        Attention: "Rajesh",
        Request_Date: "22/06/2020",
        Status: "Depot Processed",
        Material: "0",
        Billable: "1",
      },
      {
        Order_id: "500610",
        Ship_To: "Official Community",
        Attention: "Music Today",
        Request_Date: "18/06/2020",
        Status: "Approved",
        Material: "0",
        Billable: "0",
      },
      {
        Order_id: "500609",
        Ship_To: "One Live Media",
        Attention: "lady gaga",
        Request_Date: "19/06/2020",
        Status: "Pending",
        Material: "1",
        Billable: "0",
      },
      {
        Order_id: "500608",
        Ship_To: "Astralwerks",
        Attention: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Status: "Approved",
        Material: "0",
        Billable: "1",
      },
      {
        Order_id: "500613",
        Ship_To: "Astralwerks",
        Attention: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Status: "Approved",
        Material: "2",
        Billable: "2",
      },
    ],
  };
  thArrayFreeGoodsOrderView = [
    "#Catalog",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Qty Req",
    "Qty App",
    "Depot Status",
  ];
  tdArrayFreeGoodsOrderView = [
    [
      "B002849001",
      "10/26/2018",
      "UMP",
      "POST MALONE",
      "BEERBONGS & BEN(EXP)",
      "Album",
      "2",
      "1",
      "1",
      "",
    ],
    [
      "B003189901",
      "06/26/2020",
      "UMP",
      "POST MALONE",
      "HOLLYWOOD'S(EX)(TGT)",
      "Album",
      "2",
      "1",
      "1",
      "",
    ],
    [
      "0121532592",
      "06/26/2020",
      "UMP",
      "BIG TYMERS",
      "I GOT THAT WORK (EX)",
      "Album",
      "2",
      "1",
      "1",
      "",
    ],
  ];
  thArrayFreeGoodsOrderApprovers = ["Type", "Approver", "Date/Time", "Notes"];
  tdArrayFreeGoodsOrderApprovers = [
    ["Label Approver", "KEVIN LIPSON", "6/18/2020 5:18:07 PM", ""],
    ["Label Approver", "DOUG KOCH", "6/22/2020 1:53:30 PM", ""],
    ["Overnight Approver", "DOUG KOCH", "6/22/2020 1:53:32 PM", ""],
  ];
  thArrayFreeGoodsOrderEdit = [
    "Catalog #",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Qty",
    "Reject Item",
    "Prior OTD",
    "Dept OTD",
    "DJC OTD",
  ];
  tdArrayFreeGoodsOrderEdit = [
    [
      "B002849001",
      "10/26/2018",
      "UMP",
      "POST MALONE",
      "BEERBONGS & BEN(EXP)",
      "Album",
      "2",
      "1",
      "",
      "2",
      "84",
      "0",
    ],
    [
      "B003189901",
      "06/26/2020",
      "UMP",
      "POST MALONE",
      "HOLLYWOOD'S(EX)(TGT)",
      "Album",
      "2",
      "1",
      "",
      "6",
      "6",
      "0",
    ],
    [
      "0121532592",
      "06/26/2020",
      "UMP",
      "BIG TYMERS",
      "I GOT THAT WORK (EX)",
      "Album",
      "2",
      "1",
      "",
      "2",
      "2",
      "0",
    ],
  ];
  tdArrayFreeGoodsOrderApproversEdit = {
    recordset: [
      {
        "Catalog #": "B002849001",
        "Rel.Date": "10/26/2018",
        Label: "UMP",
        Artist: "POST MALONE",
        Title: "BEERBONGS & BEN(EXP)",
        Config: "Album",
        "Units/Set": "2",
        Qty: "1",
        "Reject Item": "",
        "Prior OTD": "2",
        "Dept OTD": "84",
        "DJC OTD": "0",
      },
    ],
  };

  thProdArray = [
    "Artist",
    "Title",
    "Selection",
    "UPC",
    "Configuration",
    "Release",
    "Label",
    "Cat",
  ];
  tdProdArray = [
    [
      "CHEMICAL BROTHERS",
      "BORN IN THE... (STD)",
      "2547275264",
      "00602547275264",
      "CD",
      "1",
      "07/17/15",
      "1",
    ],
  ];

  tdArray_AprlInbox = {
    recordset: [
      {
        Order_id: "500612",
        Ship_To: "David Hollender",
        Requestor: "RAJA PAUL",
        Request_Date: "20/06/2020",
        Qty: "1",
        OpCo: "UMG",
        Material: "1",
        Billable: "0",
      },
      {
        Order_id: "500611",
        Ship_To: "Jack Dawson",
        Requestor: "Rajesh",
        Request_Date: "22/06/2020",
        Qty: "5",
        OpCo: "UMG",
        Material: "0",
        Billable: "1",
      },
      {
        Order_id: "500610",
        Ship_To: "Emmely Rose",
        Requestor: "Music Today",
        Request_Date: "18/06/2020",
        Qty: "3",
        OpCo: "UMG",
        Material: "0",
        Billable: "0",
      },
      {
        Order_id: "500609",
        Ship_To: "Reacker Tom",
        Requestor: "lady gaga",
        Request_Date: "19/06/2020",
        Qty: "1",
        OpCo: "UMG",
        Material: "1",
        Billable: "0",
      },
      {
        Order_id: "500608",
        Ship_To: "Wills Hanks",
        Requestor: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Qty: "2",
        OpCo: "UMG",
        Material: "0",
        Billable: "1",
      },
      {
        Order_id: "500613",
        Ship_To: "Wills Hanks",
        Requestor: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Qty: "2",
        OpCo: "UMG",
        Material: "2",
        Billable: "2",
      },
    ],
  };
  thArrayBillableOrderView = [
    "#Catalog",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Qty Req",
    "Qty Appr",
    "Price Req",
    "Price Appr",
    "Depot Status",
    "Cost",
    "Discount%",
    "Approval Req",
  ];
  tdArrayBillableOrderView = [
    [
      "B002849001",
      "10/26/2018",
      "UMP",
      "POST MALONE",
      "BEERBONGS & BEN(EXP)",
      "Album",
      "2",
      "1",
      "1",
      "$14.30",
      "$14.30",
      "",
      "$14.30",
      "",
      "",
    ],
    [
      "B003189901",
      "06/26/2020",
      "UMP",
      "POST MALONE",
      "HOLLYWOOD'S(EX)(TGT)",
      "Album",
      "2",
      "1",
      "1",
      "$161.5",
      "$161.5",
      "",
      "$161.5",
      "",
      "",
    ],
    [
      "0121532592",
      "06/26/2020",
      "UMP",
      "BIG TYMERS",
      "I GOT THAT WORK (EX)",
      "Album",
      "2",
      "1",
      "1",
      "$6.25",
      "$6.25",
      "",
      "$6.25",
      "",
      "",
    ],
  ];
  thArrayBillableOrderEdit = [
    "Catalog #",
    "Rel.Date",
    "Label",
    "Artist",
    "Title",
    "Config",
    "Units/Set",
    "Price Req",
    "Price Appr",
    "Qty",
    "Price",
    "Reject Item",
    "Cost",
    "Discount%",
    "Approval Req",
  ];
  //thArrayBillableOrderEditClean = ["Catg#", "Rel.Date", "Lab", "Artist", "Title", "Config", "Units /Set", "Price Req", "Price Appr", "Qty", "Price", "Rej Item", "Cost", "Discnt%", "Appr Req"];
  //thArrayBillableOrderEditMarked = ["Catalog #", "Rel.Date", "Label", "Artist", "Title", "Config", "Units /Set", "Price Req", "Price Appr", "Qty", "Price", "Rej Item"];
  tdArrayBillableOrderEdit = [
    [
      "B002849001",
      "10/26/2018",
      "UMP",
      "POST MALONE",
      "BEERBONGS & BEN(EXP)",
      "Album",
      "2",
      "$14.30",
      "$14.30",
      "5",
      "14.30",
      "",
      "14.30",
      "",
      "",
    ],
    [
      "B003189901",
      "06/26/2020",
      "UMP",
      "POST MALONE",
      "HOLLYWOOD'S(EX)(TGT)",
      "Album",
      "2",
      "$161.5",
      "$161.5",
      "5",
      "161.5",
      "",
      "161.5",
      "",
      "",
    ],
    [
      "0121532592",
      "06/26/2020",
      "UMP",
      "BIG TYMERS",
      "I GOT THAT WORK (EX)",
      "Album",
      "2",
      "$6.25",
      "$6.25",
      "5",
      "6.25",
      "",
      "6.25",
      "",
      "",
    ],
  ];

  thNonBillableOrderArray = [
    "",
    "Artist",
    "Title",
    "Selection",
    "UPC",
    "Config",
    "Unit/Set",
    "Rls Date",
    "Qty",
  ];
  tdNonBillableOrderArray = [
    [
      "",
      "KOOKS,THE",
      "JUNK OF THE HEART",
      "846921",
      "05099908469212",
      "Album",
      "1",
      "09/13/11",
      "1",
    ],
  ];
  depot_order = {
    recordset: [
      {
        Order_id: "500612",
        Ship_To: "Music Today",
        Attention: "RAJA PAUL",
        Request_Date: "20/06/2020",
        Status: "Depot Pending",
        Material: "1",
        Billable: "0",
      },
      {
        Order_id: "500611",
        Ship_To: "Ticketmaster",
        Attention: "Rajesh",
        Request_Date: "22/06/2020",
        Status: "Depot Pending",
        Material: "0",
        Billable: "1",
      },
      {
        Order_id: "500610",
        Ship_To: "Official Community",
        Attention: "Music Today",
        Request_Date: "18/06/2020",
        Status: "Depot Pending",
        Material: "0",
        Billable: "0",
      },
      {
        Order_id: "500609",
        Ship_To: "One Live Media",
        Attention: "lady gaga",
        Request_Date: "19/06/2020",
        Status: "Depot Pending",
        Material: "1",
        Billable: "0",
      },
      {
        Order_id: "500608",
        Ship_To: "Astralwerks",
        Attention: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Status: "Depot Pending",
        Material: "0",
        Billable: "1",
      },
    ],
  };

  depot_history = {
    recordset: [
      {
        Order_id: "500612",
        Ship_To: "Music Today",
        Attention: "RAJA PAUL/Concord",
        Request_Date: "20/06/2020",
        Status: "Depot Pending",
        Material: "1",
        Billable: "0",
        Label: "CN",
        Requstor: "TODD TURNER",
      },
      {
        Order_id: "500611",
        Ship_To: "Ticketmaster",
        Attention: "JOE REAGOSO",
        Request_Date: "22/06/2020",
        Status: "Depot Pending",
        Material: "0",
        Billable: "1",
        Label: "SPM",
        Requstor: "VANKOPPENHAGEN",
      },
      {
        Order_id: "500610",
        Ship_To: "Official Community",
        Attention: "Music Today",
        Request_Date: "18/06/2020",
        Status: "Depot Pending",
        Material: "0",
        Billable: "0",
        Label: "SPM",
        Requstor: "TODD TURNER",
      },
      {
        Order_id: "500609",
        Ship_To: "One Live Media",
        Attention: "lady gaga",
        Request_Date: "19/06/2020",
        Status: "Depot Pending",
        Material: "1",
        Billable: "0",
        Label: "JAZ",
        Requstor: "JOSH REINER",
      },
      {
        Order_id: "500608",
        Ship_To: "Astralwerks",
        Attention: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Status: "Depot Pending",
        Material: "0",
        Billable: "1",
        Label: "CN",
        Requstor: "TODD TURNER",
      },
    ],
  };
  thArrayDepotOrderView = [
    "#Catalog",
    "Artist",
    "Title",
    "Config",
    "Qty",
    "Item Exception",
  ];
  tdArrayDepotOrderView = [
    ["B002849001", "POST MALONE", "BEERBONGS & BEN(EXP)", "Album", "2", ""],
    ["B003189901", "POST MALONE", "HOLLYWOOD'S(EX)(TGT)", "Album", "2", ""],
    ["0121532592", "BIG TYMERS", "I GOT THAT WORK (EX)", "Album", "2", ""],
  ];

  thArrayDepotOrderHisView = [
    "#Catalog",
    "Artist",
    "Title",
    "Config",
    "Qty",
    "Item Exception",
  ];
  tdArrayDepotOrderHisView = [
    [
      "B002849001",
      "POST MALONE",
      "BEERBONGS & BEN(EXP)",
      "Album",
      "2",
      "Depot Processed",
    ],
    [
      "B003189901",
      "POST MALONE",
      "HOLLYWOOD'S(EX)(TGT)",
      "Album",
      "2",
      "Depot Processed",
    ],
    [
      "0121532592",
      "BIG TYMERS",
      "I GOT THAT WORK (EX)",
      "Album",
      "2",
      "Back Order",
    ],
  ];
  thArrayDepotOrderApprovers = ["Type", "Approver", "Date/Time", "Notes"];
  tdArrayDepotOrderApprovers = [
    [
      "Label Approver",
      "KEVIN LIPSON",
      "6/18/2020 5:18:07 PM",
      "This is approved",
    ],
    ["Label Approver", "DOUG KOCH", "6/22/2020 1:53:30 PM", "I approved"],
    ["Overnight Approver", "DOUG KOCH", "6/22/2020 1:53:32 PM", "I approved"],
  ];

  tdArray_FtpHistory = {
    recordset: [
      {
        Order_id: "500612",
        Requestor: "RAJA PAUL",
        Request_Date: "Jun 29 2020 3:49PM",
        Material: "1",
        Billable: "0",
        Ftp_file: "egra1045.txt",
        Ftp_dateTime: "Jun 29 2020 4:28PM",
        Status: "Depot Pending",
        IsChecked: false,
      },
      {
        Order_id: "500611",
        Requestor: "Rajesh",
        Request_Date: "Jun 25 2020 11:08PM",
        Material: "0",
        Billable: "1",
        Ftp_file: "egra1065.txt",
        Ftp_dateTime: "Jun 26 2020 10:28AM",
        Status: "Depot Pending",
        IsChecked: false,
      },
      {
        Order_id: "500610",
        Requestor: "Music Today",
        Request_Date: "Jun 24 2020 08:54PM",
        Material: "0",
        Billable: "0",
        Ftp_file: "egra1011.txt",
        Ftp_dateTime: "Jun 25 2020 10:00AM",
        Status: "Depot Pending",
        IsChecked: false,
      },
      {
        Order_id: "500609",
        Requestor: "lady gaga",
        Request_Date: "Jun 23 2020 12:09PM",
        Material: "1",
        Billable: "0",
        Ftp_file: "egra1084.txt",
        Ftp_dateTime: "Jul 7 2020 11:00AM",
        Status: "Depot Pending",
        IsChecked: false,
      },
      {
        Order_id: "500608",
        Requestor: "Sports and Music One Live",
        Request_Date: "Jun 22 2020 09:44PM",
        Material: "0",
        Billable: "1",
        Ftp_file: "egra1066.txt",
        Ftp_dateTime: "Jul 7 2020 4:30PM",
        Status: "Depot Pending",
        IsChecked: false,
      },
      {
        Order_id: "500613",
        Requestor: "Sports and Music One Live",
        Request_Date: "1Jun 21 2020 08:354PM",
        Material: "2",
        Billable: "2",
        Ftp_file: "egra1034.txt",
        Ftp_dateTime: "Jul 6 2020 3:35PM",
        Status: "Depot Pending",
        IsChecked: false,
      },
    ],
  };

  tdArray_AutoAddExecToDept = {
    recordset: [
      { record_label: "SPM - UME", Department: "Inside Sessions" },
      {
        record_label: "UMG - UMG North America",
        Department: "Universal Music Mobile",
      },
      { record_label: "UMD - UMG (CS)", Department: "Home Office" },
      { record_label: "UMG - UMG North America", Department: "DLBS" },
      { record_label: "SPM - UME", Department: "Production" },
      { record_label: "SPM - UME", Department: "Sales-Customs/Premiums" },
      { record_label: "SPM - UME", Department: "Universal Music Media" },
      { record_label: "LAB - Global Digital Business", Department: "eLabs" },
      { record_label: "AST - Astralwerks", Department: "A&R" },
      { record_label: "CLA - Classics", Department: "" },
      { record_label: "CN - Concord", Department: "33" },
      { record_label: "SPM - UME", Department: "Film & TV Licensing" },
    ],
  };

  Order_history = {
    recordset: [
      {
        Order_id: "500612",
        Requstor: "TODD TURNER",
        Artist: "CHEMICAL BROTHERS, T",
        Title: "EXIT PLANET DUST",
        Selection: "405401B",
        Configuration: "Album",
        Qty: "1",
        Material: "1",
        Billable: "0",
        ShipToName: "ANANDARAMAN S",
        ShipToCo: "Astralwerks",
      },
      {
        Order_id: "500611",
        Requstor: "VANKOPPENHAGEN",
        Artist: "KOOKS,THE",
        Title: "PUSH THE BUTTON",
        Selection: "4356330214",
        Configuration: "Album",
        Qty: "1",
        Material: "0",
        Billable: "1",
        ShipToName: "ANANDARAMAN S",
        ShipToCo: "bas",
      },
      {
        Order_id: "500610",
        Requstor: "TODD TURNER",
        Artist: "CHEMICAL BROTHERS, T",
        Title: "JUNK OF THE HEART",
        Selection: "846921 ",
        Configuration: "CD",
        Qty: "1",
        Material: "0",
        Billable: "0",
        ShipToName: "Basheer",
        ShipToCo: "company1 public",
      },
      {
        Order_id: "500609",
        Requstor: "JOSH REINER",
        Artist: "SUICIDAL TENDENCIES",
        Title: "EXIT PLANET DUST",
        Selection: "613362 ",
        Configuration: "CD",
        Qty: "1",
        Material: "1",
        Billable: "0",
        ShipToName: "ANANDARAMAN S",
        ShipToCo: "Astralwerks",
      },
      {
        Order_id: "500608",
        Requstor: "TODD TURNER",
        Artist: "CHEMICAL BROTHERS, T",
        Title: "JOIN THE ARMY",
        Selection: "407801B",
        Configuration: "Album",
        Qty: "1",
        Material: "0",
        Billable: "1",
        ShipToName: "Basheer",
        ShipToCo: "company1 public",
      },
    ],
  };

  approval_history = {
    recordset: [
      {
        Order_id: "500612",
        Ship_To: "Music Today",
        Attention: "RAJA PAUL/Concord",
        Request_Date: "20/06/2020",
        Status: "Approved",
        Material: "1",
        Billable: "0",
        Label: "CN",
        Requstor: "TODD TURNER",
      },
      {
        Order_id: "500611",
        Ship_To: "Ticketmaster",
        Attention: "JOE REAGOSO",
        Request_Date: "22/06/2020",
        Status: "Approved",
        Material: "0",
        Billable: "1",
        Label: "SPM",
        Requstor: "VANKOPPENHAGEN",
      },
      {
        Order_id: "500610",
        Ship_To: "Official Community",
        Attention: "Music Today",
        Request_Date: "18/06/2020",
        Status: "Approved",
        Material: "0",
        Billable: "0",
        Label: "SPM",
        Requstor: "TODD TURNER",
      },
      {
        Order_id: "500609",
        Ship_To: "One Live Media",
        Attention: "lady gaga",
        Request_Date: "19/06/2020",
        Status: "Approved",
        Material: "1",
        Billable: "0",
        Label: "JAZ",
        Requstor: "JOSH REINER",
      },
      {
        Order_id: "500608",
        Ship_To: "Astralwerks",
        Attention: "Sports and Music One Live",
        Request_Date: "16/06/2020",
        Status: "Approved",
        Material: "0",
        Billable: "1",
        Label: "CN",
        Requstor: "TODD TURNER",
      },
    ],
  };
  Orders_View = {
    recordset: [
      {
        Order_id: "500612",
        ShipMethod: "Standard Ground",
        Request_Date: "20/06/2020",
        Status: "Pending",
        Material: "1",
        Billable: "0",
        Overnight: "1",
        Clean: "1",
        Label: "CN",
        SuperLabel: "CN",
        Requstor: "TODD TURNER",
        ReqWeight: "2",
      },
      {
        Order_id: "500611",
        ShipMethod: "Overnight",
        Request_Date: "22/06/2020",
        Status: "Pending",
        Material: "0",
        Billable: "1",
        Overnight: "1",
        Clean: "0",
        Label: "SPM",
        SuperLabel: "SPM",
        Requstor: "VANKOPPEN",
        ReqWeight: "2",
      },
      {
        Order_id: "500610",
        ShipMethod: "Standard Ground",
        Request_Date: "18/06/2020",
        Status: "Pending",
        Material: "0",
        Billable: "1",
        Overnight: "1",
        Clean: "1",
        Label: "SPM",
        SuperLabel: "SPM",
        Requstor: "TODD TURNER",
        ReqWeight: "1",
      },
      {
        Order_id: "500609",
        ShipMethod: "Overnight",
        Request_Date: "19/06/2020",
        Status: "Pending",
        Material: "1",
        Billable: "0",
        Overnight: "0",
        Clean: "0",
        Label: "JAZ",
        SuperLabel: "JAZ",
        Requstor: "JOSH REINER",
        ReqWeight: "2",
      },
      {
        Order_id: "500608",
        ShipMethod: "2nd Day Air",
        Request_Date: "16/06/2020",
        Status: "Approved",
        Material: "0",
        Billable: "1",
        Overnight: "1",
        Clean: "0",
        Label: "CN",
        SuperLabel: "CN",
        Requstor: "TODD TURNER",
        ReqWeight: "0",
      },
    ],
  };

  processList = {
    recordset: [
      {
        "Process Name": "ASTKT",
        "User Name": "AlisonAA",
        "Approval Type": "Two Before Depot",
        Name: "ALISON LOYKA",
        Label: "Astralwerks",
        Department: "KT",
      },
      {
        "Process Name": "ASTKT",
        "User Name": "AlisonAA",
        "Approval Type": "Label",
        Name: "ALISON LOYKA",
        Label: "Astralwerks",
        Department: "KT",
      },
      {
        "Process Name": "IDJMARKETINGASTKT",
        "User Name": "AlisonAA",
        "Approval Type": "Label",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
      {
        "Process Name": "IDJMARKETINGASTKT",
        "User Name": "AlisonAA",
        "Approval Type": "Label-Overnight",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
      {
        "Process Name": "IDJMARKETINGASTKT",
        "User Name": "AlisonAA",
        "Approval Type": "Two Before Depot",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
      {
        "Process Name": "IDJMARKETINGASTKT",
        "User Name": "AlisonAA",
        "Approval Type": "Label-Clean",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
      {
        "Process Name": "IDJMarketing_Bill",
        "User Name": "AlisonAA",
        "Approval Type": "Label",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
      {
        "Process Name": "IDJMarketing_Bill",
        "User Name": "AlisonAA",
        "Approval Type": "Label-Overnight",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
      {
        "Process Name": "IDJMarketing_Bill",
        "User Name": "AlisonAA",
        "Approval Type": "Label-Clean",
        Name: "ALISON LOYKA",
        Label: "Island",
        Department: "Marketing",
      },
    ],
  };

  Product_View = {
    recordset: [
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE... (STD)",
        selection_id: "2547275264",
        upc: "00602547275264",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "15",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE (2LP SET",
        selection_id: "2547275288",
        upc: "00602547275288",
        configuration: "Album",
        UnitsToSet: "2",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "14",
        Moratorium: "Y",
      },
      {
        artist: "EMPIRE OF THE SUN",
        title: "ICE ON THE DUNE",
        selection_id: "B001847402",
        upc: "00602537375462",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "06182013",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "13",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE... (S)",
        selection_id: "2547275261",
        upc: "00602547275261",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "15",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE (2 SET",
        selection_id: "2547275282",
        upc: "00602547275282",
        configuration: "Album",
        UnitsToSet: "2",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "14",
        Moratorium: "Y",
      },
      {
        artist: "EMPIRE OF THE SUN",
        title: "ICE THE DUNE",
        selection_id: "B001847403",
        upc: "00602537375463",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "06182013",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "13",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE... (T)",
        selection_id: "2547275265",
        upc: "00602547275264",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "15",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE (LP SET",
        selection_id: "2547275286",
        upc: "00602547275285",
        configuration: "Album",
        UnitsToSet: "2",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "14",
        Moratorium: "Y",
      },
      {
        artist: "EMPIRE OF THE SUN",
        title: "ICE ON THE DUNE",
        selection_id: "B001847407",
        upc: "00602537375466",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "06182013",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "13",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE... (D)",
        selection_id: "2547275268",
        upc: "00602547275267",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "15",
        Moratorium: "N",
      },
      {
        artist: "CHEMICAL BROTHERS",
        title: "BORN IN THE (2LP",
        selection_id: "2547275289",
        upc: "00602547275289",
        configuration: "Album",
        UnitsToSet: "2",
        release_date: "07172015",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "14",
        Moratorium: "Y",
      },
      {
        artist: "EMPIRE OF THE SUN",
        title: "ICE ON DUNE",
        selection_id: "B001847410",
        upc: "00602537375410",
        configuration: "CD",
        UnitsToSet: "1",
        release_date: "06182013",
        cost1: "1",
        pricecode: "1",
        suggpricecode: "13",
        Moratorium: "N",
      },
    ],
  };
}
