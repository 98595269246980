import React, { Component } from "react";

export class ErrorBoundary extends Component<ErrorBoundaryParameter,Istate> {
    constructor(props:ErrorBoundaryParameter) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
      return this.props.children;
    }
  }
interface ErrorBoundaryParameter
{ 
    error?:string
}
interface Istate
{
    hasError:boolean;
}