import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container, Card, Row, Col, Form, Button, Table, Alert, Modal} from "react-bootstrap";
import '../../../assets/css/Style.css'
import { FormSelect } from "../../CustomComponents/FormSelect";
import { SysopController } from "../../../Controllers/SysopController";
import { CommonController } from "../../../Controllers/CommonController";
import { IApprovalProcessAdminParameter, Istate } from "../../../Model/ApprovalProcessAdmin";
import publicIP from 'react-native-public-ip';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import ReactExport from "react-export-excel";
import {alert} from 'react-bootstrap-confirmation';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class ApprovalProcessAdmin extends Component<IApprovalProcessAdminParameter,Istate> {
    constructor(props:IApprovalProcessAdminParameter){
        super(props);
        this.state = {
            fields: {ipAddress: ''},
            selectfields: {currentApproverId:'', currentApproverName:'', newApproverId:'', newApproverName:'', recordLabelId:'', recordLabelName:''},
            optionfields: {addApprover: true, replaceApprover: false, deleteApprover: false},
            errors: {currentApproverId:'', newApproverId:'', recordLabelId:''},
            approvers: [],
            recordLabel: [],
            process: [],
            checkAlternateApprovers: [],
            loading: {showLoading: false, proceedLoading: false}, 
            errMsg:'',
            show: false,
            modal: {modalShow: false, modalTitle: '', modalSpinnerShow: false}
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        //Load Spinner
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });

        let selectfields = this.state.selectfields;
        selectfields["currentApproverName"] = "Select Current Approver";
        selectfields["newApproverName"] = "Select New Approver";
        selectfields["recordLabelName"] = "Select All";
        this.setState({ selectfields });

        let sysopController = new SysopController();
        sysopController.getApproverNames().then(jsonApprovers => {
            this.setState({                           
                approvers : JSON.parse(JSON.stringify(jsonApprovers))
            });
        }).catch(error => {
            console.error("There was an error loading approvers name")
        });

        let commonController = new CommonController();
        commonController.getAllLabel().then(jsonLabel => {
            this.setState({                           
                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
            });
        }).catch(error => {
            console.error("There was an error loading operating company")
        });

        publicIP().then(ip => {
            let fields = this.state.fields;
            fields['ipAddress'] = ip
            this.setState({ fields });
            //Stop Spinner
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading IP Address..., " + error)
        });
    }

    pageReload = () =>{
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let selectfields = this.state.selectfields;
        let optionfields = this.state.optionfields;
        selectfields["currentApproverId"] = "Select Current Approver";
        selectfields["currentApproverName"] = "Select Current Approver";
        selectfields["newApproverId"] = "Select New Approver";
        selectfields["newApproverName"] = "Select New Approver";
        selectfields["recordLabelId"] = "Select All";
        selectfields["recordLabelName"] = "Select All";
        optionfields["addApprover"] = true;
        optionfields["replaceApprover"] = false;
        optionfields["deleteApprover"] = false;
        this.setState({selectfields, optionfields});
        this.setState({process:[], show:false});
        modal['modalSpinnerShow']=false;
        this.setState({ modal });
      }

    handleSelectChange = (e:any) => {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'currentApproverId'){
            selectfields["currentApproverName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        if(e.currentTarget.name === 'newApproverId'){
            selectfields["newApproverName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            let errors = this.state.errors;
            errors["newApproverId"] = "";
            this.setState({ errors });
        }
        if(e.currentTarget.name === 'recordLabelId'){
            selectfields["recordLabelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        this.setState({
            selectfields
        });
    }

    handleOptionChange = (e:any) =>{
        let optionfields = this.state.optionfields;
        if (e.currentTarget.id === "addApprover") {
            optionfields['addApprover'] = !this.state.optionfields.addApprover;
            optionfields['replaceApprover'] = false;
            optionfields['deleteApprover'] = false;
        }
        else if (e.currentTarget.id === "replaceApprover") {
            optionfields['addApprover'] = false;
            optionfields['replaceApprover'] = !this.state.optionfields.replaceApprover;
            optionfields['deleteApprover'] = false;
        }
        else if (e.currentTarget.id === "deleteApprover") {
            optionfields['addApprover'] = false;
            optionfields['replaceApprover'] = false;
            optionfields['deleteApprover'] = !this.state.optionfields.deleteApprover;
        }
        else {
            optionfields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({
            optionfields
        });
    }

    clearError(){
      let errors = this.state.errors;
      errors["currentApproverId"] = "";
      errors["newApproverId"] = "";
      errors["recordLabelId"] = "";
      this.setState({ errors });
    }

    validateProfile(){
        this.clearError();
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let formIsValid = true;
        if(selectfields["currentApproverName"] === "Select Current Approver"){
            formIsValid = false;
            errors["currentApproverId"] = "Please select current approver";
        }
        if(!formIsValid){
            let loading = this.state.loading;
            loading["showLoading"] = false;
            this.setState({ loading });
        }
        this.setState({ errors });
        return formIsValid;
    }

    onSubmit = (e:any) => {
        e.preventDefault();
        let loading = this.state.loading;
        loading["showLoading"] = true;
        this.setState({ loading });
        if(this.validateProfile()){
            let sysopController = new SysopController();
            var label = this.state.selectfields.recordLabelId;
            if(this.state.selectfields.recordLabelId === "Select All"){
                label = '';
            }
            sysopController.getApprovalProcessDetails(this.state.selectfields.currentApproverId,label).then(jsonProcessTable => {
                this.setState({                           
                    process : JSON.parse(JSON.stringify(jsonProcessTable))
                });
                loading["showLoading"] = false;
                this.setState({ loading });
            }).catch(error => {
                console.error("There was an error loading process table")
            });
            this.setState({
                show:true
            });
        }
    }

    handleProceed = (e:any) => {
        e.preventDefault();
        let loading = this.state.loading;
        loading["proceedLoading"] = true;
        this.setState({ loading });
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let sysopController = new SysopController();
        if(this.state.optionfields.addApprover){
            if(selectfields["newApproverName"] === "Select New Approver"){
                errors["newApproverId"] = "Please select new approver";
                loading["proceedLoading"] = false;
                this.setState({ loading });
                this.setState({errors});
            }
            else{
                sysopController.addApprover(this.state.selectfields.currentApproverId,this.state.selectfields.newApproverId,this.state.selectfields.recordLabelId).then(response => {
                    alert("Approver added successfully...")
                    loading["proceedLoading"] = false;
                    this.setState({ loading });
                    this.pageReload();
                }).catch(error => {
                    console.error("There was an error...")
                });
            }
        }
        else if(this.state.optionfields.replaceApprover){
            if(selectfields["newApproverName"] === "Select New Approver"){
                errors["newApproverId"] = "Please select new approver";
                this.setState({errors});
                loading["proceedLoading"] = false;
                this.setState({ loading });
            }
            else{
                sysopController.replaceApprover(this.state.selectfields.currentApproverId,this.state.selectfields.newApproverId,this.state.selectfields.recordLabelId,sessionStorage.getItem("uid")!,this.state.fields.ipAddress).then(response => {
                    alert("Approver replaced successfully...")
                    loading["proceedLoading"] = false;
                    this.setState({ loading });
                    this.pageReload();
                }).catch(error => {
                    console.error("There was an error...")
                });
            }
        }
        else if(this.state.optionfields.deleteApprover){
            sysopController.checkAlternateApprovers(this.state.selectfields.currentApproverId,this.state.selectfields.recordLabelId).then(jsonCheckAlternateApprovers => {
                this.setState({                           
                    checkAlternateApprovers : JSON.parse(JSON.stringify(jsonCheckAlternateApprovers))
                });
                if(Object.keys(this.state.checkAlternateApprovers).length === 0){
                    sysopController.deleteApprover(this.state.selectfields.currentApproverId,this.state.selectfields.recordLabelId,sessionStorage.getItem("uid")!,this.state.fields.ipAddress).then(response => {
                        alert("Approver removed successfully...")
                        loading["proceedLoading"] = false;
                        this.setState({ loading });
                        this.pageReload();
                    }).catch(error => {
                        console.error("There was an error...")
                    });
                }
                else{
                    loading["proceedLoading"] = false;
                    this.setState({ loading });
                    let modal = this.state.modal;
                    modal['modalShow']=true;
                    modal['modalTitle'] = 'Approval Process Model';
                    this.setState({ modal })
                }
            }).catch(error => {
                console.error("There was an error...")
            });
        }
    }

    handleModalClose = (e : any) => {
        let modal = this.state.modal;
        modal['modalShow'] = false;
        this.setState({ modal })
    }

    showModalBody = () => {
        return <Container fluid style={{paddingLeft:'0px',paddingRight:'0px'}}>
                    <Card style={{ width: '100%',marginBottom: '0px' }}>
                    <Card.Body>
                        <Row>
                            <Col sm={12}>
                                <div>No alternate approver for below approval process. Please add the alternate approver before delete the approver</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Process Name</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.checkAlternateApprovers.map((prop, key) => {
                                            return (
                                                <tr key={key} >                                                                                                                          
                                                    <td>{prop["processName"]}</td>
                                                    <td>{prop["type"]}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
    }

    render(){
        const{ loading } = this.state;
        return (
            <div className="content">
                <Modal show={this.state.modal.modalShow}
                    onHide={this.handleModalClose} 
                    backdrop="static"
                    keyboard={false}
                    dialogClassName={this.state.modal.modalBody ? "modal-lg" : "modal-70w"}
                    centered
                    >
                    <Modal.Header closeButton>
                        {this.state.modal.modalTitle}
                    </Modal.Header>
                    <Modal.Body className="show-grid" style={{padding:'0px'}} >
                        {this.showModalBody()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" style={{ marginRight: "5px" }} onClick={(e) => {this.handleModalClose(e)}}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row>
                  <Col sm={12}>
                  <Card border="light" style={{ height:'100%' }}>
                    <Card.Body>
                        <Card.Title><h4 className="title">Approval Process Admin</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={1}></Col>
                                <Col sm={5}>
                                    <FormSelect
                                        properties={{label:"Current Approver:",id:"currentApproverId",name:"currentApproverId",value:this.state.selectfields.currentApproverId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.currentApproverId}
                                        defaultOption="Select Current Approver"
                                        fillOption={this.state.approvers}
                                    />
                                </Col>
                                <Col sm={5}>
                                    <FormSelect
                                        properties={{label:"New Approver:",id:"newApproverId",name:"newApproverId",value:this.state.selectfields.newApproverId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.newApproverId}
                                        defaultOption="Select New Approver"
                                        fillOption={this.state.approvers}
                                    />
                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                            <Row>
                                <Col sm={1}></Col>
                                <Col sm={4}>
                                    <FormSelect
                                        properties={{label:"Label",id:"recordLabelId",name:"recordLabelId",value:this.state.selectfields.recordLabelId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.recordLabelId}
                                        defaultOption="Select All"
                                        fillOption={this.state.recordLabel}
                                    />
                                </Col>
                                <Col sm={6}style={{textAlign: "center" , alignSelf: "center"}}>
                                    <Button type="submit" disabled={loading.showLoading}>
                                        {loading.showLoading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" , color:"#3472F7" }}
                                        />
                                        )}
                                        {loading.showLoading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading.showLoading && <span>Show Approval Process Detail</span>}
                                    </Button>
                                    {this.state.show && <ExcelFile element={<Button type="button" style={{ marginRight: "5px" }}>Export to Excel</Button>}>
                                        <ExcelSheet data={this.state.process} name="Process">
                                            <ExcelColumn label="Process Name" value="processName"/>
                                            <ExcelColumn label="User Name" value="alias"/>
                                            <ExcelColumn label="Type" value="typedesc"/>
                                            <ExcelColumn label="Name" value="name"/>
                                            <ExcelColumn label="Label" value="labelName"/>
                                            <ExcelColumn label="Department" value="department"/>
                                        </ExcelSheet>
                                    </ExcelFile>}
                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                            {this.state.show && <Row>
                                <Col sm={1}></Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={4} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Add Approver" name="approver"  id="addApprover" onChange={this.handleOptionChange} defaultChecked={this.state.optionfields.addApprover} /></Col>
                                            <Col sm={4} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Replace Approver" name="approver"  id="replaceApprover" onChange={this.handleOptionChange} defaultChecked={this.state.optionfields.replaceApprover} /></Col>
                                            <Col sm={4} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Delete Approver" name="approver"  id="deleteApprover" onChange={this.handleOptionChange} defaultChecked={this.state.optionfields.deleteApprover} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}style={{textAlign: "center" , alignSelf: "center"}}>
                                    <Button type="button" disabled={loading.proceedLoading} onClick={(e) => this.handleProceed(e)}>
                                        {loading.proceedLoading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color:"#3472F7" }}
                                        />
                                        )}
                                        {loading.proceedLoading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading.proceedLoading && <span>Proceed</span>}
                                    </Button>
                                </Col>
                                <Col sm={1}></Col>
                            </Row>}
                        </Form>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {this.state.show && <Row>
                  <Col sm={12}>
                  <Card border="light" style={{ height:'100%' }}>
                    <Card.Body>
                        <Card.Title><h4 className="title">Approval Process Detail</h4></Card.Title>
                        <Row>
                            <Col sm={12}>
                            <div className="scroll" style={{ width: '100%' }}> 
                                <Table id="process">
                                    <thead>
                                        <tr>
                                            <th>Process Name</th>
                                            <th>User Name</th>
                                            <th>Approval Type</th>
                                            <th>Name</th>
                                            <th>Label</th>
                                            <th>Department</th>
                                        </tr>
                                    </thead>
                                    {Object.keys(this.state.process).length === 0 ?
                                    <tbody>
                                        <tr>
                                            <td colSpan={5} align="center" className="text-danger font-size-xs small">No approval process associated to selected user</td>
                                        </tr>
                                    </tbody>
                                    :<tbody>
                                        {this.state.process.map((prop, key) => {
                                            return (
                                                <tr key={key} >                                                                                                                          
                                                    <td>{prop["processName"]}</td>
                                                    <td>{prop["alias"]}</td>
                                                    <td>{prop["typedesc"]}</td>
                                                    <td>{prop["name"]}</td>
                                                    <td>{prop["labelName"]}</td>
                                                    <td>{prop["department"]}</td>
                                                </tr>
                                                );
                                        })}
                                    </tbody>
                                    }
                                </Table>
                                </div>
                            </Col>
                        </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>}
              </Container>
            </div>
        )
    }
}
