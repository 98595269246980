import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class FtpHistoryController {

    serviceProxy: ServiceProxy;
    variables: Variables;
    response : any;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }

    getFTPOrderHistory(filter : string,filter_type : string,start_date : string,end_date : string,status_id : string){
            var url = this.variables.Url + this.variables.Controller.ftpHistory + this.variables.ftpHistoryService.getFTPOrderHistory;
            var data = {
                "filter": filter,
                "filterType" : filter_type,
                "startDate":start_date,
                "endDate" : end_date,
                "statusId" : status_id
            }        
            return this.serviceProxy.get(url,data)
            .then(response => {
                var jsonFtpHistoryList : Array<{"isChecked":boolean;"Order_Id": string; "billable": boolean;"material":boolean; "Requestor":string;"RequestDate":string;"FTPFileName":string;
                                    "Date_Uploaded": string;"StatusDesc": string }> = [];
                if(response.success){                   
                    response.recordset.forEach(list);                   
                    function list(item: {  Order_Id:string;billable:boolean;material:boolean;Requestor:string;RequestDate:string;FTPFileName:string;
                        Date_Uploaded:string;StatusDesc:string }, _index: number){
                            jsonFtpHistoryList.push({"isChecked":false,"Order_Id": item.Order_Id, "billable": item.billable,"material":item.material,
                    "Requestor":item.Requestor,"RequestDate":item.RequestDate,"FTPFileName":item.FTPFileName,"Date_Uploaded":item.Date_Uploaded,"StatusDesc":item.StatusDesc })
                    }
                    return JSON.parse(JSON.stringify(jsonFtpHistoryList).replace(/"\s+|\s+"/g, '"'));
                }
                else {                                    
                    this.response.msg = response.message.toString();
                    this.response.success = false;
                    return JSON.parse(JSON.stringify(jsonFtpHistoryList).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });        
        }

        resetFTPOrders(order_id : string){        
            var url = this.variables.Url+ this.variables.Controller.ftpHistory + this.variables.ftpHistoryService.resetFTPOrders;
            var data = {               
                order_id : order_id,          
            }
            return this.serviceProxy.put(url,data).then(response => {
                return response;
            }).catch(error => {
                return error;
            })
        }
}