import React, { Component } from "react";
import { History, LocationState } from "History";
import { Container,Navbar, Nav, Card, Row, Col, Table, Form, Alert, Button, FormGroup, FormControl, Modal } from "react-bootstrap";
//import { Container, Row, Col, Form, Alert, Button, ProgressBar } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/AdminLayout.css'
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { FormCheckBox } from '../../CustomComponents/FormCheckBox';
import { FormLabelField } from '../../CustomComponents/FormLabelField';
import jsonState from '../../../json/State.json';
import { CommonController } from '../../../Controllers/CommonController';
import { QuickProductSearchController } from '../../../Controllers/QuickProductSearchController';
//import Table from 'react-bootstrap/Table'
import { Variables } from '../../../Variables/Variables';
import '../../../assets/css/Style.css'
import { RegisterParameter, Istate } from "../../../Model/QuickProductSearch"; 

export class QuickProductSearch extends Component<RegisterParameter, Istate> {

  constructor(props: RegisterParameter) {

    super(props)
    this.state = {
      fields: { artist: '', title: '', selection: '', upc: '' },
      selectfields: { recordLabelId: '',recordLabelName:'' },
      checkfields: { multipleSelect: false },
      errors: { title: '', artist: '', selection: '', upc: '' },
      Recordlabel: [],
      tdArray: [],
      errMsg: '',
      loading: false,
      thArray: [],
      SearchGrid: [],
      hidediv: true,
      isAvl:false,
      isAvlMsg:'List is Empty...Please search for the needs'
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);

  }

  componentWillMount() {
    let commonController = new CommonController();
    commonController.getAllLabel().then(jsonLabel => {
      this.setState({
        Recordlabel: JSON.parse(JSON.stringify(jsonLabel))
      });
    }).catch(error => {
      console.error("There was an error loading operating company")
    });

    this.setState({
      hidediv: true
    });

  }

  handleSelectChange(e: any) {

    let selectfields = this.state.selectfields;
    if (e.currentTarget.name === 'recordLabelId')
    {
      if (e.currentTarget.value === "All Companies")
      {
        selectfields['recordLabelName'] = ""
      }
      else
      {
        selectfields['recordLabelName'] =  e.currentTarget.value;
      }
    }
    selectfields[e.currentTarget.name] = e.currentTarget.value;

    this.setState({
      selectfields
    });

  }

  handleChange(e: any) {
    let fields = this.state.fields;
      if (e.currentTarget.value.trim().length > 0)
      {
        fields[e.currentTarget.name] = e.currentTarget.value;
      }
      else
      {
        fields[e.currentTarget.name] = e.currentTarget.value.trim();
      }
      this.setState({
          fields
      })
  }

  handleClick(e: any) {
    let fields = this.state.fields;
    fields['artist'] = "";
    fields['title'] = "";
    fields['selection'] = "";
    fields['upc'] = "";   
    this.setState({
      hidediv: true, fields, SearchGrid: [], isAvlMsg:"List is Empty...Please search for the needs", isAvl:false
    });
  }


  onSubmit = (e: any) => {
    this.setState({ loading: true });
    e.preventDefault();
    
    var data = {
      recordLabelId: this.state.selectfields.recordLabelName,
      artist: this.state.fields.artist,
      title: this.state.fields.title,
      selection: this.state.fields.selection,
      upc: this.state.fields.upc
    }
    let quickproductSearchController = new QuickProductSearchController();
    quickproductSearchController.getSearchProductInfo(data).then(jsonProductSearch => {
      this.setState({
        SearchGrid: JSON.parse(JSON.stringify(jsonProductSearch)), loading: false
      });
      if(this.state.SearchGrid.length > 0)
          {            
            this.setState({isAvl:true})
            this.setState({isAvlMsg:""})
            this.setState({ loading: false });
          }
          else
          {           
            this.setState({isAvl:false})
            this.setState({isAvlMsg:"No record(s) found"})
            this.setState({ loading: false });
          }
    }).catch(error => {
      this.setState({ loading: false });
      console.error("There was an error loading in search product")
    });

    this.setState({
      hidediv: false
    });
    
  }
 
  displayTableRecord=()=>{
    let stateCnt:number = this.state.SearchGrid.length;
    
    if (this.state.isAvl)
    {
      return  this.state.SearchGrid.map(function (prop, i) {
        return <tr key={i}>
          <td>{prop["item1"]}</td>
          <td>{prop["item2"]}</td>
          <td>{prop["item3"]}</td>
          <td>{prop["item4"]}</td>
          <td>{prop["item5"]}</td>
          <td>{prop["item6"]}</td>
          <td>{prop["item7"]}</td>
          <td>{prop["item8"]}</td>
        </tr>
      })
    }
    else
    {
    return <tr><td colSpan={8} align="center" className="text-danger font-size-xs small">{this.state.isAvlMsg}</td></tr>
    }
  }
  render() {
    const { loading } = this.state;
    const displayOperatingCompanyOptionforUserTypeS = () => {
      // if(sessionStorage.getItem("usertype_id")==="S"){
      return <Row>
        <Col>
          <FormSelect
            properties={{ label: "Record Label", id: "recordLabelId", name: "recordLabelId", value: this.state.selectfields.recordLabelId, className: "form-control", onChange: this.handleSelectChange }}
            fieldRequired={false}
            errorMsg={this.state.errors.recordLabelId}
            defaultOption="All Companies"
            fillOption={this.state.Recordlabel}
          />
        </Col>
      </Row>
      // }
    }

    return (
      <div className="content">
        <Container fluid>
          <Row className="small">
            <Col md={12}>
              <Card border="light" style={{ width: '100%', height:'auto' }}>
                  <Card.Body>
                  <Card.Title><h4 className="title">Quick Product Search</h4></Card.Title>
                  <Form onSubmit={this.onSubmit} >
                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                    <Row>
                      <Col sm={4} >
                        {displayOperatingCompanyOptionforUserTypeS()}
                      </Col>
                      <Col sm={2}>
                        <FormInputs
                          properties={{ id: "artist", label: "Artist", type: "text", name: "artist", className: "form-control input-sm", maxlength: 25, value: this.state.fields.artist, onChange: this.handleChange }}
                          errorMsg={this.state.errors.artist}
                          fieldRequired={false}
                        />
                      </Col>
                      <Col sm={2}>
                        <FormInputs
                          properties={{ id: "title", label: "Title", type: "text", name: "title", className: "form-control input-sm", maxlength: 25, value: this.state.fields.title, onChange: this.handleChange }}
                          errorMsg={this.state.errors.title}
                          fieldRequired={false}
                        />
                      </Col>

                      <Col sm={2}>
                        <FormInputs
                          properties={{ id: "selection", label: "Selection", type: "text", name: "selection", className: "form-control input-sm", maxlength: 25, value: this.state.fields.selection, onChange: this.handleChange }}
                          errorMsg={this.state.errors.selection}
                          fieldRequired={false}
                        />
                      </Col>
                      <Col sm={2}>
                        <FormInputs
                          properties={{ id: "upc", label: "UPC", type: "text", name: "upc", className: "form-control input-sm", maxlength: 25, value: this.state.fields.upc, onChange: this.handleChange }}
                          errorMsg={this.state.errors.upc}
                          fieldRequired={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} style={{ textAlign: "center" }}>
                        <Button type="submit" disabled={loading}>
                          {loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px", color:"#3472F7" }}
                            />
                          )}
                          {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                          {!loading && <span>Search</span>}
                        </Button>
                        <span>   </span>
                        <Button type="reset" onClick={this.handleClick}>Clear</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}  className="mb-4">                        
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                          <div id="navbar">
                              <Navbar expand="lg" >
                                  <Navbar.Brand className="align-center">Search Product</Navbar.Brand>
                                  <Nav className="ml-auto">
                                  </Nav>
                              </Navbar>
                          </div>
                      </Col>
                    </Row>  
                    <Row>
                      <Col sm={12} style={{ paddingBottom: "15px"}}>
                      <div className="scroll" style={{ width: '100%' }}>
                          <Table hover>
                            <thead>
                              <tr>
                                {new Variables().thProdArray.map((prop, key) => {
                                  return <th key={key}>{prop}</th>;
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {this.displayTableRecord()}
                            </tbody>
                          </Table>
                          </div>
                      </Col>
                    </Row>
                  </Form>
                  </Card.Body>                                  
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
