import React, { Component } from "react";
import { Container, Card, Row, Col, Table, Form, Alert, Button, Navbar, Nav } from "react-bootstrap";
import { History, LocationState } from "History";
import { format } from 'date-fns'
import { Variables } from '../../../Variables/Variables';
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { NavLink } from 'react-router-dom'
import '../../../assets/css/Style.css'
import { FormCheckBox } from "../../CustomComponents/FormCheckBox"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css';
import { IApprovalHistoryParameter, Istate } from "../../../Model/ApprovalHistory"; 
import{OrderApprovalController} from "../../../Controllers/OrderApprovalController"

export class ApprovalOrderHistory extends Component<IApprovalHistoryParameter, Istate> {
    constructor(props: IApprovalHistoryParameter) {

        super(props)
        this.state = {
            fields: { find: '', searchType: '', checkid: '', searchText: '' },
            errors: { find: '', searchType: '', checkid: '' },
            errMsg: "",
            todayDate: "",
            previousdate: "",
            Order_id: "",
            goodsReq: "",
            loading: false,
            selectedDate: '2017-08-13',          
            startDate: "",   
            endDate:"",
            displayListGrid:[],
            isListAvlMsg:"",
            isListAvl:false,            
            task_For:"",
        }
    }


    componentDidMount() {
        var today    =new Date();  
        var sD=new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));     
        var curr_date = sD.getDate();
        var curr_month = sD.getMonth() + 1; //Months are zero based
        var curr_year = sD.getFullYear();
        var startDate = curr_month + "/" + curr_date + "/" + curr_year;
        
        let orderApprovalController = new OrderApprovalController();
        orderApprovalController.getApproverHistoryList(sessionStorage.getItem("uid")!,startDate)
            .then(jsonApproverHistory =>{
              this.setState({                           
                displayListGrid : JSON.parse(JSON.stringify(jsonApproverHistory))
            });                 
               
            if(this.state.displayListGrid.length > 0)
              {      
                this.setState({isListAvl:true})
                this.setState({isListAvlMsg:""})
              }
              else
              {                                        
                this.setState({isListAvlMsg:"No record(s) found"})               
              }  
            }).catch(error => {         
            console.log(error);
          })

    }

    handleLog(date) {
        this.setState({
            selectedDate: date
        });
    }
  

    getItemInfo= (item) =>{
      const styleObj = {
      fontSize: 10,
      color: "red",      
    } 
    const styleBilObj = {
      fontSize: 10,
      color: "#800000",      
    } 
 
    if(item["material"] === true)
    {
      return  (<span style={styleObj}>Clean</span>) 
      }
      else{
      return ""
      } 
  };

   getBillItemInfo= (item) =>{

  const styleBilObj = {
    fontSize: 10,
    color: "#800000",      
  } 
  if(item["billable"] === true)
  {
   return  (<span style={styleBilObj}>Billable</span>) 
  }
  else if(item["NTBillableT"] === true)
  {
    return  (<span  style={styleBilObj}>Non Billable Transfer</span>)      
  }
  else{
    return ""
   } 
};

     getLinkWithPath= (item) =>{
        return (<NavLink
        to={{
          pathname:'/admin/viewOrderDetails',
          state:{
            Order_id:item["order_id"],
            task_For:"V",           
          }
        }}                                        
        activeClassName="active">{item["order_id"]}</NavLink>)      
   }

    render() {
        const { loading } = this.state;
        const alignCardCenter = {
            "margin": "0 0 0 0"
        };
        const { isListAvl } = this.state;  
        return (
            <div className="content">
             <Container fluid>            
             <Row>
              <Col md={12}>
                <Card border="light" style={{ height:'100%' }}>
                  <Card.Body>
                  <Card.Title><h4 className="title">Approver History</h4></Card.Title>
                    <form>
                      <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                       <Row>
                          <Col sm={12}>
                          <div className="scroll" style={{ width: '100%' }}>                         
                          {isListAvl ? 
                             <Table>
                              <thead>
                              <tr>
                              {new Variables().thApproverHistoryList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}                   
                              </tr>                         
                            </thead>
                            <tbody>                           
                                {this.state.displayListGrid.map((prop, key) => { 
                                    return (
                                      <tr key={key}>
                                      <td>{this.getLinkWithPath(prop)}&nbsp;{this.getBillItemInfo(prop)}&nbsp;{this.getItemInfo(prop)}</td>                                    
                                        <td>{prop["request_date"]}</td>                                                                           
                                        <td>{prop["ShipToName"]}</td>                                 
                                        <td>{prop["Name"]}</td>
                                        <td>{prop["statusdesc"]}</td>                                  
                                      </tr>
                                    )            
                                    })} 
                              </tbody>  
                             </Table>:
                             <Table>
                               <thead>
                                 <tr>
                                   {new Variables().thApproverHistoryList.map((prop, key) => {
                                     return <th key={key}>{prop}</th>;
                                   })}
                                 </tr>
                               </thead> 
                               <tbody>
                                 <tr>
                                   <td colSpan={11} align="center" className="text-danger font-size-xs small">{this.state.isListAvlMsg}</td>
                                 </tr>
                               </tbody>
                             </Table>} 
                             </div>  
                          </Col>
                        </Row>
                    </form>
                  </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
             </Container>
            </div>
        )
    }
}