import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class AppSettingsController {

    serviceProxy: ServiceProxy;
    variables: Variables;
   
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }

    getAllAppSetting(){
        var url = this.variables.Url+ this.variables.Controller.appSettings + this.variables.appSettingsService.getAllAppSetting;
        return this.serviceProxy.get(url,{})
            .then(response => {
                var jsonAppSet : Array<{"name": string, "value": string}> = [];
                if(response.success){
                    response.recordset.forEach(dept);
                    function dept(item: { Name: any; Value: any;}, index: number){
                        jsonAppSet.push({"name": item.Name, "value": item.Value})
                    }
                    return JSON.parse(JSON.stringify(jsonAppSet).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonAppSet).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    } 

    updateAppSetting(name : string , value : string){        
        var url = this.variables.Url+ this.variables.Controller.appSettings + this.variables.appSettingsService.updateAppSetting;
        var data = {
            name : name,
            value : value,           
        }
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error => {
            return error;
        })
    }

}