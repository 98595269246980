import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class SearchHistoryController {

    serviceProxy: ServiceProxy;
    variables: Variables;
    response : any;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }

    getSearchHistory(data){
        var url = this.variables.Url + this.variables.Controller.SearchHistory + this.variables.SearchHistoryServices.getSearchHistory;
        var data1 = {
            filter: data.filter,
            filterType : data.filterType,
            uId : data.uId,
            startDate:data.startDate,
            endDate : data.endDate           
        }        
        return this.serviceProxy.get(url,data1)
        .then(response => {
            var jsonSearchHistoryList : Array<{"Order_Id": string; "billable": boolean;"material":boolean; "Name":string;"Artist":string;"Title":string;
                                "Selection_Id": string;"Configuration": string;"Quantity": string;"statusdesc": string;"Requestor": string;
                                "ShipToName": string;"ShipToCo": string }> = [];
            if(response.success){                   
                response.recordset.forEach(list);                   
                function list(item: {  Order_Id:string;billable:boolean;material:boolean;Name:string;Artist:string;Title:string;
                    Selection_Id:string;Configuration:string;Quantity:string;statusdesc:string;Requestor:string
                    ;ShipToName:string;ShipToCo:string}, _index: number){
                        jsonSearchHistoryList.push({"Order_Id": item.Order_Id, "billable": item.billable,"material":item.material,
                        "Name":item.Name,"Artist":item.Artist,"Title":item.Title,"Selection_Id":item.Selection_Id,"Configuration":item.Configuration,
                        "Quantity":item.Quantity,"statusdesc":item.statusdesc,"Requestor":item.Requestor,"ShipToName":item.ShipToName,"ShipToCo":item.ShipToCo })
                }
                return JSON.parse(JSON.stringify(jsonSearchHistoryList).replace(/"\s+|\s+"/g, '"'));
            }
            else {                                    
                return JSON.parse(JSON.stringify(jsonSearchHistoryList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });        
    }

}