import React, { Component, CSSProperties } from "react";
import { NavLink,Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {AdminNavbarLinks} from "../CustomComponents/NabvBar/AdminNavbarLinks";
import logo from "../../assets/img/UMG_Logo.png";
import {MultiFilter} from "../../Common/MultiFilter"
import { faAngleDown,faAngleUp} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { CreateOrderController } from "../../Controllers/CreateOrderController";

export class Sidebar extends Component<ISidebar,Istate> {
  constructor(props) {
    super(props);
    this.state = {
      fields: {show:false, showCPanel:false},
      width: window.innerWidth,
      Routes:[]
    };
  }
  ShowHideComponent = (e:any) => {
    e.persist(); 
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation(); 
    let fields = this.state.fields;
    let navLinkText:string = e.currentTarget.innerText.toLowerCase();
    //console.log('innerText.toLowerCase');
    //console.log(navLinkText);
    //console.log('Before showCPanel');
    //console.log(fields['showCPanel']);
    //console.log('Before show');
    //console.log(fields['show']);
    if (e.currentTarget.innerText.toLowerCase().trim() === 'control panel')
    {
      if (fields['showCPanel'] === true) {
        fields['showCPanel'] = false;
      } else {
        fields['showCPanel'] = true;
      }
      this.setState({ fields });
      //console.log('After showCPanel');
      //console.log(fields['showCPanel']);
    }
    else
    {
      if (fields['show'] === true) {
        fields['show'] = false;
      } else {
        fields['show'] = true;
      }
      this.setState({ fields });
      //console.log('After show');
      //console.log(fields['show']);
    }
  };
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentWillMount() {
    toast.configure();
    this.setState({                           
      Routes : JSON.parse(JSON.stringify(this.props.routes),)
    });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  createRoute(prop:any,key:any){
    //console.log(prop);
    return (
      <li
        className={
          prop.upgrade
            ? "active active-pro"
            : this.activeRoute(prop.layout + prop.path)
        }
        key={key}
      >
        <NavLink
          to={{
            pathname:prop.layout + prop.path,
             state:{
              uid:sessionStorage.getItem("uid")
            }
          }}
          className="nav-link"
          activeClassName="active"
        >
          <FontAwesomeIcon icon={prop.icon} size="1x" />
          <p style={{paddingLeft:"5px"}}>{prop.name}</p>
        </NavLink>
      </li>
    );
  }
  //Filter the router list based on the role
  assignRoleRouter(prop:any){
    let UserType_Id: string | null = sessionStorage.getItem("usertype_id");
    var mnu: Array<string> = []; 
    mnu.push('MNU0'); 
    

    const currentPath = this.props.location.pathname.toLowerCase();

    if (currentPath !== "/admin/selectrole") {
      // Perform other actions for paths not equal to "/admin/selectrole"
      if (UserType_Id !== null){
        mnu.push('MNU0'); 
        
        if(UserType_Id === "RR"){
          mnu.push('MNU1'); 
        }
        if(UserType_Id.indexOf("A") >= 0 || UserType_Id.indexOf("L") >= 0 || UserType_Id.indexOf("C") >= 0){
          mnu.push('MNU2'); 
        }
        if(UserType_Id === "R" || UserType_Id.indexOf("A") >= 0 || UserType_Id.indexOf("L") >= 0 || UserType_Id.indexOf("C") >= 0){
          mnu.push('MNU3'); 
        }
        if(sessionStorage.getItem("uid") === sessionStorage.getItem("aliasNTB")){
          mnu.push('MNU4'); 
        }
        if(UserType_Id === "R" || UserType_Id.indexOf("A") >= 0 || UserType_Id.indexOf("L") >= 0 || UserType_Id.indexOf("C") >= 0){
          mnu.push('MNU5'); 
        }
        if(UserType_Id === "D"){
          mnu.push('MNU6'); 
        }
        if(UserType_Id === "S"){
          mnu.push('MNU7');         
        }
        if(UserType_Id === "TB"){
          mnu.push('MNU8');  
        }
        if(UserType_Id === "S89"){
          mnu.push('MNU9');  
        };
        mnu.push('MNU10');  
      }    else{
        mnu.push('MN'); 
      }
    } else {
     
    }
    
    


    //console.log(mnu);
    let filters = {
      view : mnu
    };
    let multiFilter = new MultiFilter();
    
    // console.log(filters,"filters");
        
    // console.log(this.props.routes,"this.props.routes");
    return multiFilter.multiFilter(this.props.routes,filters);
  }
  setTarget(prop:any)
  {
    if(prop.name === 'UMG B2B')
    {
      return "_blank"
    }
    else
    {
      return ""
    }
  }
  displayNavLink(prop:any) {
    let subMenuCount = prop.submenu.length;
    let propName = prop.name;
   //
    if(subMenuCount > 0){
      return(
        <NavLink
          to={{
            state:{
              uid:sessionStorage.getItem("uid"),
              orderType:''
            }
          }}
          className="nav-link"
          activeClassName="active"
          onClick={this.ShowHideComponent}
        >
          <FontAwesomeIcon icon={prop.icon} size="1x" />
          <p style={{paddingLeft:"5px"}}>{prop.name}</p>{ this.displayToggleIcon(subMenuCount, prop.name)}
        </NavLink>
      );
    }
    else
    {
      let orderTypes='';
      let orderType='';
      let validate=false; 
      if (propName === "Create a Free Goods Req"){
        orderTypes = sessionStorage.getItem("orderTypeValidate")!.split('~')[0];
        orderType = 'FG'
        validate = (orderTypes != '')?true:false;
      }
      else if(propName === "Create NonBill Trans Order"){ 
        orderTypes = sessionStorage.getItem("orderTypeValidate")!.split('~')[1];
        orderType = 'NT'
        validate = (orderTypes != '')?true:false;
      }
      else if(propName === "Create Billable Order"){ 
        orderTypes = sessionStorage.getItem("orderTypeValidate")!.split('~')[2];
        orderType = 'BI'
        validate = (orderTypes != '')?true:false;
      }
      else if(propName === "Ticket Bundling"){ 
        orderTypes = sessionStorage.getItem("orderTypeValidate")!.split('~')[3];
        orderType = 'TB'
        validate = false;
      }
      else if(propName === "D2C"){ 
        orderTypes = sessionStorage.getItem("orderTypeValidate")!.split('~')[4];
        orderType = 'S89'
        validate = false;
      }

      if(validate){       
        if (orderType === 'FG'){
          return(
            <NavLink
              to={{
                state:{
                  uid:sessionStorage.getItem("uid"),
                  orderType:orderType
                }
              }}
              className="nav-link"
              activeClassName="active"
              onClick={this.notifyFG}
            >
              <FontAwesomeIcon icon={prop.icon} size="1x" />
              <p style={{paddingLeft:"5px"}}>{prop.name}</p>
            </NavLink>
          );
        }
        else if (orderType === 'NT'){
          return(
            <NavLink
              to={{
                state:{
                  uid:sessionStorage.getItem("uid"),
                  orderType:orderType
                }
              }}
              className="nav-link"
              activeClassName="active"
              onClick={this.notifyNT}
            >
              <FontAwesomeIcon icon={prop.icon} size="1x" />
              <p style={{paddingLeft:"5px"}}>{prop.name}</p>
            </NavLink>
          );
        }
        else if(orderType === 'BI'){
          return(
            <NavLink
              to={{
                state:{
                  uid:sessionStorage.getItem("uid"),
                  orderType:orderType
                }
              }}
              className="nav-link"
              activeClassName="active"
              onClick={this.notifyBI}
            >
              <FontAwesomeIcon icon={prop.icon} size="1x" />
              <p style={{paddingLeft:"5px"}}>{prop.name}</p>
            </NavLink>
          );
        }
        
      }
      else
      {
        return(
          <NavLink
            to={{
              pathname:prop.layout + prop.path,
              state:{
                uid:sessionStorage.getItem("uid"),
                orderType:orderType
              }
            }}
            target={this.setTarget(prop)}
            className="nav-link"
            activeClassName="active"
            /*onClick={alert('selected')}*/
          >
            <FontAwesomeIcon icon={prop.icon} size="1x" />
            <p style={{paddingLeft:"5px"}}>{prop.name}</p>
          </NavLink>
        );
      }
    }
  }
  //info, success, warn, error, toast without properties
  //eg, toast('alert'), toast.info('alert'), toast.warn('alert')
  notifyFG = () => toast.warn('Approval process does not exist for ordering from this label: ' + sessionStorage.getItem("label_id"),{position:toast.POSITION.TOP_RIGHT});
  notifyNT = () => toast.warn('Your department is not authorized to submit Non Billable orders',{position:toast.POSITION.TOP_RIGHT});
  notifyBI = () => toast.warn("Your department is not authorized to submit billable orders",{position:toast.POSITION.TOP_RIGHT});

  displayToggleIcon(cnt,name) {
    let fields = this.state.fields;
    if(cnt > 0){
      if (name.toLowerCase() === "control panel")
      {
        if(fields['showCPanel']=== true)
        {
          return <span style={{float:"right",fontSize:"1.5rem"}}><FontAwesomeIcon icon={faAngleUp} size="1x" /></span>
        }
        else
        {
          return <span style={{float:"right",fontSize:"1.5rem"}}><FontAwesomeIcon icon={faAngleDown} size="1x" /></span>
        }
      }
      else
      {
        if(fields['show'] === true)
        {
          return <span style={{float:"right",fontSize:"1.5rem"}}><FontAwesomeIcon icon={faAngleUp} size="1x" /></span>
        }
        else
        {
          return <span style={{float:"right",fontSize:"1.5rem"}}><FontAwesomeIcon icon={faAngleDown} size="1x" /></span>
        }
      }
    }
  }
  displayChildNavLink(prop){
  return <>{prop.submenu.map((p, i=30) => {
    return <li
    className={
      prop.upgrade
        ? "active active-pro"
        : this.activeRoute(p.layout + p.path)
    }
    key={i}
  >
    <NavLink
      to={{
        pathname:p.layout + p.path,
        state:{
          uid:sessionStorage.getItem("uid")
        }
      }}
      className="nav-link"
      activeClassName="active"
    >
    <p style={{paddingLeft:"30px"}}><FontAwesomeIcon icon={p.icon} size="1x" /> {p.name}</p>
    </NavLink>
  </li>
  })}</>
}
render(){
  const routerList = this.assignRoleRouter(this.props.routes);
  const sidebarBackground = {
    backgroundImage: "url(" + this.props.image + ")"
  };
  var imageSize = {
    "width":"100px",
    "height":"40px"
  }
  var imageBg = {
    "background-color":"#000",
    "height": "62px",
    "padding-left":"60px"
  }
  var showTaggle:CSSProperties ={
    "display":"block"
  }
  var hideTaggle:CSSProperties ={
    "display":"none"
  }
  const showTagged=(prop)=>{
    let fields = this.state.fields;
    if (prop.submenu.length > 0){
      if(prop.name.toLowerCase().trim() === 'control panel')
      {
        return <div style={fields['showCPanel'] ? showTaggle : hideTaggle}>
          {this.displayChildNavLink(prop)}
        </div>
      }
      else
      {
        return <div style={fields['show'] ? showTaggle : hideTaggle}>
          {this.displayChildNavLink(prop)}
        </div>
      }
    }
  }
return (
  <div
    id="sidebar"
    className="sidebar"
    data-color={this.props.color}
    data-image={this.props.image}
  >
      {this.props.hasImage ? (
        <div className="sidebar-background" style={sidebarBackground} />
      ) : (
        null
      )}
    <div className="logo" style={imageBg}>
      <a href="#">
        <div className="logo-img">
          <img src={logo} alt="logo_image" style={imageSize}/>
        </div>
      </a>
    </div>
    <div className="sidebar-wrapper">
      <ul className="nav">
        {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
        {routerList.map((prop, key) => {
            if (!prop.redirect){
              //return this.createRoute(prop,key);
                return <div key={key}><li
                        className={
                          prop.upgrade
                            ? "active active-pro"
                            : this.activeRoute(prop.layout + prop.path)
                        }
                        key={key}
                      >
                        {this.displayNavLink(prop)}
                      </li>
                      {showTagged(prop)}
                    </div>
              }
              return null;
            })}
      </ul>
    </div>
  </div>
  );
  }
}

interface ISidebar
{
    location?:any;
    image?:string;
    color?:string;
    hasImage?:boolean;
    routes?:any;
 
}
interface Istate {
    fields:any;
    width:number;
    Routes:[];
  }