import React, { Component } from "react";
import {History, LocationState} from "History";
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,NavItem } from "react-bootstrap";
import {AdminNavbarLinks} from "../NabvBar/AdminNavbarLinks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt,faGlobe,faUser,faSignOutAlt,faUsers,faUserFriends } from '@fortawesome/free-solid-svg-icons';
import {LogoutController} from "../../../Controllers/LogoutController";
import { UserAgentApplication } from 'msal';

import { config } from '../../../js/confiq';
export class AdminNavbar extends Component<IAdminNavbar,Istate> {
   user;
    
  constructor(objHeader:IAdminNavbar) {
    super(objHeader)
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({sidebarExists:true});
    }
    e.preventDefault();
    ((document as Document).documentElement as HTMLElement).classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    (node as HTMLElement).onclick=function(e:any){
        var targetElement:HTMLElement=((e as Event).target as HTMLElement);
        (targetElement.parentElement as HTMLElement).removeChild(targetElement);
        ((document as Document).documentElement as HTMLElement).classList.toggle("nav-open");
      }
    document.body.appendChild(node);
  }
  
  myHandler(e) { 
    e.persist(); 
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation(); 
  }
  redirectToSelectRole=()=>{    
    this.props.history.push('/admin/selectrole');
  }
  // logoutHandler (e : any) {
  //   let logoutController = new LogoutController();
  //   logoutController.updateLoginFlag(sessionStorage.getItem("uid")?.toString().trim()!)
  // }

  logoutHandler = async () => {;
    
        const userAgentApplication = new UserAgentApplication({
          auth: {
            clientId: config.clientId,
            authority: config.authority,
            redirectUri: config.redirectUri,
            postLogoutRedirectUri: config.postLogoutRedirectUri,
          },
          // cache: {
          //   cacheLocation: 'sessionStorage',
          //   storeAuthStateInCookie: true,
          // },
        });
    
        try {
          let logoutController = new LogoutController();
          logoutController.updateLoginFlag(sessionStorage.getItem("uid")?.toString().trim()!)
          await userAgentApplication.logout();
    
              sessionStorage.clear();
          // Clear user information from the state or wherever it's stored
         
        } catch (error) {
          console.error('Logout error:', error);
        } finally {
          // Update state or perform other actions after logout, e.g., navigate to home page
          
        }
      };

  render() {
    const styles = {
      headerBGColor: {
        paddingRight:"5px",
        color: '#2AB3E2',
      },
      logoutBGColor: {
        paddingRight:"5px",
        color: '#FFFFFF',
      },
      anchorBGColor: {
        paddingRight:"20px",
        color: '#fff',
        fontSize:"17px"
      },
      headerBGColors: {
        paddingRight:"5px",
       
      }
    }

    this.user=sessionStorage.getItem("user_full_name")

    const displaySelectUserProfile =()=>{
    return <Nav.Link eventKey={2} title="Click to go to Profile Selection" href="#" style={styles.anchorBGColor} onClick={() => this.redirectToSelectRole()}><FontAwesomeIcon icon={faUserFriends} style={styles.logoutBGColor} size="2x" /><span style={styles.headerBGColors}>Select User Profile</span></Nav.Link>
   
    }
    return (
      <Navbar expand="lg" >
        <div>
        <p style={{ paddingLeft: "10px",color:"#FFFFFF",alignSelf:"flex-end",fontWeight:"bold"}}>{this.props.brandText}</p>
        </div>
        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end" style={{ width: "100%" }}>
          <Nav.Link eventKey={2} title="Click to go to Profile Selection" href="#" style={styles.anchorBGColor} onClick={() => this.redirectToSelectRole()}><FontAwesomeIcon icon={faUserFriends} style={styles.logoutBGColor} size="2x" /><span style={styles.headerBGColors}>Select User Profile</span></Nav.Link>
          {this.user ? (
        <Nav.Link  style={{ cursor: 'text', pointerEvents: 'none' }}><FontAwesomeIcon icon={faUser} style={styles.logoutBGColor} size="2x" /><span style={styles.headerBGColor}>{sessionStorage.getItem("user_full_name")}({sessionStorage.getItem("uid")})</span></Nav.Link>
            
      ) : (
        <span></span>
      )}
        {/* <Nav.Link  href="#" onClick={this.myHandler}><FontAwesomeIcon icon={faUser} style={styles.logoutBGColor} size="2x" /><span style={styles.headerBGColor}>{sessionStorage.getItem("user_full_name")}({sessionStorage.getItem("uid")})</span></Nav.Link> */} 
            <Nav.Link eventKey={2} href="#" style={styles.logoutBGColor} onClick={this.logoutHandler}><FontAwesomeIcon icon={faSignOutAlt} style={styles.logoutBGColor} size="2x"/>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

 interface IAdminNavbar
 {
    brandText?:string;
    history?:History<LocationState>;
 }
interface Istate
{
    sidebarExists:boolean;
}