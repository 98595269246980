import React, { Component } from "react";
import { History, LocationState } from "History";
import { Container,Card, Row, Col, Table, Form, Alert, Button} from "react-bootstrap";
//import { Container, Row, Col, Form, Alert, Button, ProgressBar } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/AdminLayout.css'
import {FormInputs} from '../../CustomComponents/FormInput'
import {FormSelect} from '../../CustomComponents/FormSelect';
import { AppSettingsController} from '../../../Controllers/AppSettingsController';
import {alert} from 'react-bootstrap-confirmation';

export class AppSettings extends Component<AppSettingsParameter, Istate> {

    constructor(props: AppSettingsParameter) {
        super(props)
        this.state = {        
        selectfields: { appName:''}, 
        errors: {appName:''},
        errMsg: '',     
        appName:[],      
        loading:false,
        value: ''
      }
      this.onSubmit=this.onSubmit.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount()
    {     
      let appSettingsController = new AppSettingsController();
      appSettingsController.getAllAppSetting().then(jsonappName => {
        this.setState({                           
            appName : JSON.parse(JSON.stringify(jsonappName))
        });
      }).catch(error => {
          console.error("There was an error loading Application settings..., " + error)
      });      
    }

    handleSelectChange (e:any) {
        try{ 
          let selectfields = this.state.selectfields;
          let selectvalue = e.currentTarget.value;
          let errors = this.state.errors; 
          selectfields["appName"] = e.currentTarget.value; 
          errors["appName"] = "";    
          this.state.appName.map((prop) => { 
            if(prop["name"] === selectvalue)
            {
              this.setState({                           
                value : prop["value"]
            });
            }
          })
          this.setState({
            selectfields,errors
        });   
        }
        catch(err){
            console.error(err);
            }
        }   

    handleChange =(e:any)=>{
      let value = this.state.value;
      if (e.currentTarget.value.trim().length > 0){
        value = e.currentTarget.value;
      }
      else{
        value = e.currentTarget.value.trim();
      }
      this.setState({ value });
    }

    onSubmit =(e:any)=>{
        try{
          e.preventDefault();
          if (this.ValidateSelect()) { 
            this.setState({ loading: true });   
            let appSettingsController = new AppSettingsController();
            appSettingsController.updateAppSetting(this.state.selectfields.appName,this.state.value)
            .then(response => {
              if (response.success){       
                alert("Data Updated...")
                window.location.reload();       
              }
            }).catch(error => {
              this.setState({ errMsg : "Process Failed" });
            })
          }
        }
        catch(err){
            console.error(err);
            }
        }

        ValidateSelect(){
          this.clearError();        
          let formIsValid = true;    
          let selectsetting =this.state.selectfields.appName;            
          let errors = this.state.errors;
          if (selectsetting === "" || selectsetting === "Select Setting") {
            formIsValid = false;
            errors["appName"] = "Select Setting";                
          }
          
          this.setState({
            errors
          });
          return formIsValid;
        }

        clearError()
      {
        let errors = this.state.errors;
        errors["appName"] = ""; 
        this.setState({errMsg : ""})
        this.setState({
          errors
      });
      }

    render() {
        const {loading} = this.state;        
        return (
          <div className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card border="light" style={{ width: '100%', height:'650px' }}>
                      <Card.Body>
                      <Card.Title>Edit Application Setting</Card.Title>
                      <Form onSubmit= {this.onSubmit}>
                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                        <Row>
                            <Col sm={3}> 
                              <FormSelect
                                  properties={{label:"Settings",id:"Settings",name:"Settings",className:"form-control",onChange:this.handleSelectChange}}
                                  fieldRequired={true}
                                  errorMsg={this.state.errors.appName}
                                  defaultOption="Select Setting"
                                  fillOption={this.state.appName}
                                  />
                            </Col>                           
                         </Row> 
                         <Row>
                          <Col sm={4} style={{textAlign: 'left'}}>
                            <Form.Group>
                            <Form.Label>Value</Form.Label>
                                <Form.Control as="textarea" rows={3} id="Value" name="Value" className="form-control" value={this.state.value} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                           </Col>
                        </Row>                        
                         <Row>
                             <Col sm={3} style={{ paddingTop: "25px"}}> 
                              <Button type="submit" disabled={loading} style={{marginRight: "5px"}}>
                              {loading && (
                              <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px", color:"#3472F7" }}
                              />
                              )}
                              {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                              {!loading && <span>Update Value</span>}
                              </Button>
                            </Col>   
                        </Row>                      
                       </Form>
                      </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
}

interface AppSettingsParameter {   
    history?: History<LocationState>;
  }
  
  interface Istate {    
    selectfields: any;     
    errMsg: string;  
    appName:[];       
    errors:any;
    loading:boolean;
    value:any;
  }