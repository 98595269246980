import { Variables } from '../Variables/Variables';
var variables = new Variables()
export const config = {
    clientId: variables.clientId,
    authority: "https://login.microsoftonline.com/bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78/",
    redirectUri: variables.redirectUri,
    postLogoutRedirectUri: variables.redirectUri,
    scopes: [
        'user.read'
    ]
};