import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container, Card, Row, Col, Form, Button, Table, Alert} from "react-bootstrap";
import {FormSelect} from '../../CustomComponents/FormSelect';
import {CommonController} from '../../../Controllers/CommonController';
import {SysopController} from '../../../Controllers/SysopController'
import '../../../assets/css/Style.css'
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class ViewApprovalProcess extends Component<ViewApprovalProcessParameter,Istate> {
    constructor(props:ViewApprovalProcessParameter){
        super(props);
        this.state = {
            selectfields: {recordLabelId:'', recordLabelName:''},
            errors: {recordLabelId:''},
            recordLabel: [],
            process: [],
            loading: false,
            selectLabel: false,
            errMsg:'',
            modal: {modalSpinnerShow: false}
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let commonController = new CommonController();
        commonController.getAllLabel().then(jsonLabel => {
            this.setState({                           
                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading operating company")
        });
    }

    handleSelectChange = (e:any) => {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'recordLabelId'){
            this.setState({selectLabel:true});
            selectfields["recordLabelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        if(e.currentTarget.value === "Select Your Operating Company"){
            this.setState({selectLabel:false});
        }
        this.setState({
          selectfields
        });
    }

    onSubmit = (e:any) => {
        this.setState({ loading: true });
        e.preventDefault();
        let sysopController = new SysopController();
        sysopController.getAllProcessForLabel(this.state.selectfields.recordLabelId)
        .then(jsonProcess => {
            this.setState({                           
                process : JSON.parse(JSON.stringify(jsonProcess))
            });
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error loading process")
        });  
    }

    render(){
        const{ loading } = this.state;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row>
                  <Col sm={12}>
                  <Card border="light" style={{ height:'100%' }}>
                    <Card.Body>
                        <Card.Title><h4 className="title">View Approval Process</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={4}>
                                    <FormSelect
                                        properties={{label:"Label Name",id:"recordLabelId",name:"recordLabelId",value:this.state.selectfields.recordLabelId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.recordLabelId}
                                        defaultOption="Select Your Operating Company"
                                        fillOption={this.state.recordLabel}
                                    />
                                </Col>
                                <Col sm={3} style={{textAlign: "center" , alignSelf: "center"}}>
                                    <Button type="submit" disabled={loading}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color:"#3472F7" }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading && <span>View Approval Process</span>}
                                    </Button>
                                </Col>
                                <Col sm={5}></Col>
                            </Row>
                            <div className="clearfix" />
                        </Form>
                        </Card.Body>                                  
                    </Card>
                  </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ height:'100%' }}>
                        <Card.Body>
                            <Card.Title><h4 className="title">Process Details</h4></Card.Title>
                                <Row>
                                    <Col sm={12}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Assigned Label</th>
                                                    <th>Department</th>
                                                    <th>Process Name</th>
                                                    <th>Max Approvers</th>
                                                    <th>Default</th>
                                                    <th>Overnight</th>
                                                    <th>Clean</th>
                                                    <th>Billable</th>
                                                    <th>NT Billable</th>
                                                    <th>Super User</th>
                                                    <th>Edit</th>
                                                    <th>Add/Remove Approvers</th>
                                                </tr>
                                            </thead>
                                                {this.state.selectLabel ? 
                                                <tbody>
                                                    {this.state.process.map((prop, key) => {
                                                        return(
                                                            <tr key={key}>
                                                                <td>{prop["assignedLabelId"]}</td>
                                                                <td>{prop["department"]}</td>
                                                                <td>{prop["processName"]}</td>
                                                                <td>{prop["maxApprovers"]}</td>
                                                                <td>{prop["default"]}</td>
                                                                <td>{prop["overnight"]}</td>
                                                                <td>{prop["clean"]}</td>
                                                                <td>{prop["billable"]}</td>
                                                                <td>{prop["NTBillable"]}</td>
                                                                <td>{prop["superUser"]}</td>
                                                                <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={() => {this.props.history.push({pathname:'/admin/editApprovalProcess',state:{item:prop, label:this.state.selectfields.recordLabelName}})}}>Edit</Button></td>
                                                                <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={() => {this.props.history.push({pathname:'/admin/addRemoveApprovers',state:{item:prop, label:this.state.selectfields.recordLabelName}})}}>Add/Remove Approvers</Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={12} align="center" className="text-danger font-size-xs small">List is Empty...Please Select</td>
                                                    </tr>
                                                </tbody>
                                                }
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
              </Container>
            </div>
        );
    }
}

interface ViewApprovalProcessParameter{
    uid?:string;
    pwd?:string;
    history?:History<LocationState>;
}
    
interface Istate{
    selectfields:any;
    errors:any;
    recordLabel:[];
    process:[];
    selectLabel:boolean;
    loading:boolean;
    errMsg:string;
    modal:any;
}