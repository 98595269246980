// JScript File
export var MARKED_53 = "53 Marked";
export var MARKED_54 = "54 - Marked (non-royalty bearing)";
export var MARKED_56 = "56 - Marked (fully royalty bearing)";
export var MARKED_59 = "59 - Marked (publisher royalties only)";
export var MARKED_91 = "91 Marked";
export var CLEAN_51 = "51 Clean";
export var CLEAN_54 = "54 - Clean (non-royalty bearing)";
export var CLEAN_56 = "56 - Clean (fully royalty bearing)";
export var CLEAN_59 = "59 - Clean (publisher royalties only)";
export var CLEAN_66 = "66 Ticket Bundling";
export var CLEAN_91 = "91 Clean";
export var MARKED_89 = "89 Sales Type";
export var FG = "FG"; //Free Goods Order
export var BI = "BI"; //Billable Order
export var NT = "NT"; //Non Billable Order
export var TB = "TB"; //Ticket Bundling 
export var S89 = "S89"; // Sales Type 89

