import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container, Card, Row, Col, Form, Button, Table, Alert, Modal, NavLink} from "react-bootstrap";
import '../../../assets/css/Style.css'
import { FormLabelField } from "../../CustomComponents/FormLabelField";
import { FormSelect } from "../../CustomComponents/FormSelect";
import { SysopController } from "../../../Controllers/SysopController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class AddRemoveApprovers extends Component<AddRemoveApproversParameter,Istate> {
    constructor(props:AddRemoveApproversParameter){
        super(props);
        this.state = {
            fields: {approvalProcessName:'', label:'', assignedLabel:'', department:'', maxApprovers:'', crossLabel:'', default:false, allLabels:'', exceptions:''},
            selectfields: {approverId:'', approverName:'', approvalLevelId:'', approvalLevelName:''},
            optionfields: {defaultProcessNo:true, defaultProcessYes:false},
            errors: {approverId:'', approvalLevelId:''},
            approvers: [],
            approvalLevel: [],
            approverTable: [],
            process:[],
            loading: false, 
            errMsg:'',
            modal: {modalSpinnerShow: false}
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
      let modal = this.state.modal;
      modal['modalSpinnerShow']=true;
      this.setState({ modal });

      let fields = this.state.fields;
      fields["approvalProcessName"] = this.props.location.state.item["processName"];
      fields["label"] = this.props.location.state.label;
      fields["assignedLabel"] = this.props.location.state.item["assignedLabelId"];
      fields["department"] = this.props.location.state.item["department"];
      fields["maxApprovers"] = this.props.location.state.item["maxApprovers"];
      fields["crossLabel"] = this.props.location.state.item["crossLabel"];
      if(this.props.location.state.item["default"] === "Yes"){
        fields["default"] = true;
      }
      fields["allLabels"] = this.props.location.state.item["allLabels"];
      fields["exceptions"] = this.props.location.state.item["exceptions"];
      this.setState({fields});
      let selectfields = this.state.selectfields;
      selectfields["approverName"] = "Select Approver Name";
      selectfields["approvalLevelName"] = "Select Approval Level";
      this.setState({selectfields});

      let sysopController = new SysopController();
      sysopController.getAllApprovers().then(jsonApprovers => {
        this.setState({                           
            approvers : JSON.parse(JSON.stringify(jsonApprovers))
        });
        sysopController.getApprovalLevel(this.state.fields.maxApprovers).then(jsonApprovalLevel => {
          this.setState({                           
              approvalLevel : JSON.parse(JSON.stringify(jsonApprovalLevel))
          });
          sysopController.getApprovalTable(this.state.fields.approvalProcessName).then(jsonApprovalTable => {
            this.setState({                           
                approverTable : JSON.parse(JSON.stringify(jsonApprovalTable))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
          }).catch(error => {
              console.error("There was an error loading approval Table")
          });
        }).catch(error => {
            console.error("There was an error loading approval level")
        });
      }).catch(error => {
          console.error("There was an error loading approvers name")
      });
    }

    pageReload = () =>{
      let modal = this.state.modal;
      modal['modalSpinnerShow']=true;
      this.setState({ modal });
      let selectfields = this.state.selectfields;
      let optionfields = this.state.optionfields;
      selectfields["approverName"] = "Select Approver Name";
      selectfields["approvalLevelName"] = "Select Approval Level";
      selectfields["approverId"] = "Select Approver Name";
      selectfields["approvalLevelId"] = "Select Approval Level";
      optionfields["defaultProcessNo"] = true;
      optionfields["defaultProcessYes"] = false;
      this.setState({selectfields, optionfields});
      let sysopController = new SysopController();
      sysopController.getApprovalTable(this.state.fields.approvalProcessName).then(jsonApprovalTable => {
        this.setState({                           
            approverTable : JSON.parse(JSON.stringify(jsonApprovalTable))
        });
        modal['modalSpinnerShow']=false;
        this.setState({ modal });
      }).catch(error => {
          console.error("There was an error loading approval Table")
      });
    }

    handleSelectChange = (e:any) => {
      let selectfields = this.state.selectfields;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      if(e.currentTarget.name === 'approverId'){
        selectfields["approverName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
      }
      if(e.currentTarget.name === 'approvalLevelId'){
        selectfields["approvalLevelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
      }
      this.setState({
        selectfields
      });
    }

    handleOptionChange =(e:any)=>{
      let optionfields = this.state.optionfields;
      let fields = this.state.fields;
      if (e.currentTarget.id === "defaultProcessNo") {
          optionfields['defaultProcessNo'] = !this.state.optionfields.defaultProcessNo;
          optionfields['defaultProcessYes'] = false;
      }
      else if (e.currentTarget.id === "defaultProcessYes") {
          optionfields['defaultProcessNo'] = false;
          optionfields['defaultProcessYes'] = !this.state.optionfields.defaultProcessYes;
      }
      else {
          optionfields[e.currentTarget.name] = e.currentTarget.value;
      }
      this.setState({ optionfields, fields });
    }

    clearError(){
      let errors = this.state.errors;
      errors["approverId"] = "";
      errors["approvalLevelId"] = "";
      this.setState({ errors });
    }

    validateProfile(){
      this.clearError();
      let selectfields = this.state.selectfields;
      let errors = this.state.errors;
      let formIsValid = true;
      if(selectfields["approverName"] === "Select Approver Name"){
        formIsValid = false;
        errors["approverId"] = "Please select approver";
      }
      if(selectfields["approvalLevelName"] === "Select Approval Level"){
        formIsValid = false;
        errors["approvalLevelId"] = "Please select approval level";
      }
      if(!formIsValid){
          this.setState({ loading: false });
      }
      this.setState({ errors });
      return formIsValid;
    }
    
    checkAlreadyAdded = () => {
      var alreadyAdded = false
      this.state.approverTable.map((prop, key) => {
        if(this.state.selectfields.approverId === prop['alias'] && this.state.selectfields.approvalLevelId === prop['labelId']){
          alreadyAdded = true;
        }
      })
      return alreadyAdded;
    }

    onSubmit = (e:any) => {
      e.preventDefault();
      this.setState({ loading: true });
      if(this.validateProfile()){
        if(this.checkAlreadyAdded()){
          alert("This approver has been already added for this same level...")
          this.setState({ loading: false });
          this.pageReload();
        }
        else{
          let sysopController = new SysopController();
          sysopController.getProcessLookupCounter(this.state.fields.approvalProcessName,this.state.selectfields.approverId).then(response => {
            var counter = response.value + 1;
            if(this.state.fields.crossLabel){
              sysopController.addApproverForExport(this.state.fields.approvalProcessName,this.state.selectfields.approverId,this.state.selectfields.approvalLevelId,counter).then(response => {
                sysopController.addFullLengthCleanApproverToProcess().then(response => {
                  alert("Approver added successfully...")
                  this.setState({ loading: false });
                  this.pageReload();
                })
              })
              .catch(error => {
                console.log("There was an error...", error)
              })
            }
            else{
              if(this.state.optionfields.defaultProcessYes){
                var isDefault = 1;
                sysopController.checkIsDefaultProcessLookup(this.state.fields.approvalProcessName,this.state.selectfields.approverId).then(response =>{
                  if(response.Is_Default === 1){
                    alert("A default process already exists for this approver.  Please rectify before continuing.")
                  }
                  this.setState({ loading: false });
                  this.handleIsDefault(isDefault,counter);
                }).catch(error => {
                  console.log("There was an error...", error)
                }) 
              }
              else{
                isDefault = 0;
                this.handleIsDefault(isDefault,counter);
              }
            }
          }).catch(error => {
            console.log("There was an error...", error)
          })
        }
      }
    }

    handleIsDefault = (isDefault, counter) => {
      let sysopController = new SysopController();
      if(isDefault < 1){
        sysopController.addApproverNonExport(this.state.fields.approvalProcessName,this.state.selectfields.approverId,this.state.selectfields.approvalLevelId,0,counter).then(response => {
          sysopController.addFullLengthCleanApproverToProcess().then(response => {
            alert("Approver added successfully...")
            this.setState({ loading: false });
            this.pageReload();
          })
        }).catch(error => {
          console.log("There was an error...", error)
        }) 
      }
      else{
        sysopController.getNonExportDefaultProcessCount(this.state.selectfields.approverId).then(response => {
          if(response.value < 1){
            sysopController.addApproverNonExport(this.state.fields.approvalProcessName,this.state.selectfields.approverId,this.state.selectfields.approvalLevelId,1,counter).then(response => {
              sysopController.addFullLengthCleanApproverToProcess().then(response => {
                sysopController.updatePermissions(this.state.selectfields.approverId,this.state.fields.allLabels,this.state.fields.exceptions);
                alert("Approver added successfully...")
                this.setState({ loading: false });
                this.pageReload();
              })
            }).catch(error => {
              console.log("There was an error...", error)
            })
          }
          else{
            alert("This approver is already assigned to a default process. Please proceed accordingly.")
            this.setState({ loading: false });
          }
        }).catch(error => {
          console.log("There was an error...", error)
        })
      }
    }

    handleOnDelete = (item, e:any) => {
      e.preventDefault();
      
      let sysopController = new SysopController();
      sysopController.checkAlternateApprover(this.state.fields.approvalProcessName,item['labelId']).then(response => {
        if(response.value <= 1){
          alert("No alternate approver for selected usertype: " + item['label'] + ". Kindly go back and add approver.")
        }
        else{
          if(this.state.fields.crossLabel){
            sysopController.deleteApproverByProcess(this.state.fields.approvalProcessName,item['alias'],item['labelId'],1).then(response => {
              alert("Approver removed successfully...")
              this.pageReload();
            }).catch(error => {
              console.log("There was an error...", error)
            })
          }
          else{
            sysopController.deleteApproverByProcess(this.state.fields.approvalProcessName,item['alias'],item['labelId'],0).then(response => {
              alert("Approver removed successfully...")
              this.pageReload();
            }).catch(error => {
              console.log("There was an error...", error)
            })
          }
        }
      })
    } 

    
    render(){
        const{ loading } = this.state;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row>
                  <Col sm={12}>
                  <Card border="light" style={{ height:'100%' }}>
                    <Card.Body>
                        <Card.Title><h4 className="title">Add/ Remove Approvers</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={3}>
                                  <FormLabelField properties={{label:"Approval Process Name:",id:"approvalProcessName",name:"approvalProcessName",className:"form-control",value:this.state.fields.approvalProcessName}}/>
                                </Col>
                                <Col sm={3}>
                                  <FormLabelField properties={{label:"Label:",id:"label",name:"label",className:"form-control",value:this.state.fields.label}}/>
                                </Col>
                                <Col sm={3}>
                                  <FormLabelField properties={{label:"Assigned Label:",id:"assignedLabel",name:"assignedLabel",className:"form-control",value:this.state.fields.assignedLabel}}/>
                                </Col>
                                <Col sm={3}>
                                  <FormLabelField properties={{label:"Department:",id:"department",name:"department",className:"form-control",value:this.state.fields.department}}/>
                                </Col>
                            </Row>
                            <Row>
                              <Col sm={5}>
                                  <FormSelect
                                      properties={{label:"Select Approver:",id:"approverId",name:"approverId",value:this.state.selectfields.approverId,className:"form-control",onChange:this.handleSelectChange}}
                                      fieldRequired={true}
                                      errorMsg={this.state.errors.approverId}
                                      defaultOption="Select Approver Name"
                                      fillOption={this.state.approvers}
                                  />
                              </Col>
                              <Col sm={3}>
                                  <FormSelect
                                      properties={{label:"Approval Level:",id:"approvalLevelId",name:"approvalLevelId",value:this.state.selectfields.approvalLevelId,className:"form-control",onChange:this.handleSelectChange}}
                                      fieldRequired={true}
                                      errorMsg={this.state.errors.approvalLevelId}
                                      defaultOption="Select Approval Level"
                                      fillOption={this.state.approvalLevel}
                                  />
                              </Col>
                              {this.state.fields.crossLabel ? 
                              <Col sm={4}>
                              </Col>
                              :
                              <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Default Requestor Process for Approver?</Form.Label>
                                    <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                        <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="defaultProcess"  id="defaultProcessYes" onChange={this.handleOptionChange} checked={this.state.optionfields.defaultProcessYes} /></Col>
                                        <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="defaultProcess"  id="defaultProcessNo" onChange={this.handleOptionChange} checked={this.state.optionfields.defaultProcessNo} /></Col>
                                    </Row>
                                </Form.Group>
                              </Col>
                              }
                            </Row>
                            <Row>
                                <Col sm={12} style={{textAlign: "center" , alignSelf: "center"}}>
                                    <Button type="submit" disabled={loading}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color:"#3472F7"  }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading && <span>Save</span>}
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix" />
                        </Form>
                        </Card.Body>                                  
                      </Card>
                  </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                      <Card border="light" style={{ height:'100%' }}>
                      <Card.Body>
                          <Card.Title><h4 className="title">Process Details</h4></Card.Title>
                                <Row>
                                    <Col sm={12}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Level</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.approverTable.map((prop, key) => {
                                                    return(
                                                        <tr key={key}>
                                                            <td>{prop["name"]}</td>
                                                            <td>{prop["label"]}</td>
                                                            <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={(e) => {this.handleOnDelete(prop, e)}}>Delete</Button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
              </Container>
            </div>
        )
    }
}

interface AddRemoveApproversParameter{
    uid?:string;
    pwd?:string;
    history?:History<LocationState>;
    location?:any;
}
    
interface Istate{
    fields:any;
    selectfields:any;
    optionfields:any;
    errors:any;
    approvers:[];
    approvalLevel:[];
    approverTable:[];
    process:[];
    loading:boolean;
    errMsg:string;
    modal:any;
}