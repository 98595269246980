import React, { Component} from "react";
import {Container, Navbar, Nav,Card,Row,Col,Table,Form,Alert,Button} from "react-bootstrap";
import {FormControl,Modal} from "../../../../node_modules/react-bootstrap";
import {FormInputs} from '../../CustomComponents/FormInput'
import {FormSelect} from '../../CustomComponents/FormSelect';
import {FormCheckBox} from '../../CustomComponents/FormCheckBox';
import { Variables } from '../../../Variables/Variables';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css'
import '../../../assets/css/Style.css'
import { ReportController } from "../../../Controllers/ReportController"
import { IReportParameter, Istate } from "../../../Model/Report"; 
import {CommonController} from '../../../Controllers/CommonController';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';

export class ReportView extends Component<IReportParameter,Istate> {
    constructor(props:IReportParameter){
        super(props)       
        this.state={
          fields: {reportType:'',subTitle:''},
          errors: {userList:'',deptList:'',startDate:'',endDate:'' },
          radioOptionfields:{freegoods:false,nonBillable:false,billable:false,all:false},
          selectOption:{selectUser:'1',selectDept:'%20'},
          checkfields: {groupByDept:true},
          loading: false,
          errMsg:"",
          startDate: "",   
          endDate:"",
          isUser:false,          
          isDepartment:false,
          isDate:false,
          isgroupbyDept:false,
          isReporttype:false,
          isSubmit:false,
          userList:[],
          reportType:"",
          departmentList:[],
          reportName:"",
          reportRender:"&rs:Command=Render&Label=",
          modal: {modalSpinnerShow: false}
        } 
        this.handleChange = this.handleChange.bind(this);    
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);   
        this.handleCheckChange = this.handleCheckChange.bind(this);    
        this.handleRadioOptionChange = this.handleRadioOptionChange.bind(this);
    }

    handleChange (e:any) {     
        let selectOption =this.state.selectOption;   
        if(e.currentTarget.name === "userList")
        {
            let selectUser="";
            selectUser = e.currentTarget.options[e.currentTarget.selectedIndex].value
            if (selectUser != "All")
              selectOption["selectUser"] =selectUser
            else
              selectOption["selectUser"] ="1"
        }
        else if(e.currentTarget.name === "department")
        {
            let selectDept="";
            selectDept = e.currentTarget.options[e.currentTarget.selectedIndex].value
            if (selectDept != "All")
              selectOption["selectDept"] =selectDept
            else
              selectOption["selectDept"] ="%20"
        }
        this.setState({selectOption});
      }

      componentWillMount(){        
        let pathName = this.props.history.location.pathname.split("/").pop();
        let fields = this.state.fields;
        let modal = this.state.modal;    
        modal['modalSpinnerShow']=true;    
        var today    =new Date();      
        var startDate=new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));
        this.setState({ startDate: startDate});     
        this.setState({ endDate: today});
        if(pathName === "usertotals")
        {
            fields['subTitle'] = 'User Totals Report';
            this.setState({isUser:true,isDepartment:false,isDate:true,isgroupbyDept:true,isReporttype:true,isSubmit:true,fields});         
            let radioOptionfields = this.state.radioOptionfields;
            radioOptionfields['freegoods'] = !this.state.radioOptionfields.freegoods  
            this.setState({reportType:"1",reportName:"User_Totals"})
            let reportController = new ReportController();
            reportController.getUserList(sessionStorage.getItem("label_id")!)
                .then(jsonUserList =>{
                this.setState({                           
                    userList : JSON.parse(JSON.stringify(jsonUserList))
                })
            })
        }
        else if(pathName === "selectiontotals")
        {
            fields['subTitle'] = 'Selection Totals Report';
            this.setState({isUser:false,isDepartment:false,isDate:true,isgroupbyDept:false,isReporttype:true,isSubmit:true,fields});
            let radioOptionfields = this.state.radioOptionfields;
            radioOptionfields['freegoods'] = !this.state.radioOptionfields.freegoods  
            this.setState({reportType:"1",reportName:"Selection_Totals"})
        }
        else if(pathName === "departmenttotals")
        {            
            fields['subTitle'] = 'Department Totals Report';
            this.setState({isUser:false,isDepartment:true,isDate:true,isgroupbyDept:false,isReporttype:true,isSubmit:true,fields});
            let radioOptionfields = this.state.radioOptionfields;
            radioOptionfields['freegoods'] = !this.state.radioOptionfields.freegoods  
            this.setState({reportType:"1",reportName:"Department_totals"})
            let commonController = new CommonController();
            commonController.getAllDeptForLabel(sessionStorage.getItem("label_id")!).then(jsonDept => {
                this.setState({                           
                    departmentList : JSON.parse(JSON.stringify(jsonDept))
                });
            })
        }
        else if(pathName === "detailorderreport")
        {
            fields['subTitle'] = 'Authorizer Report';
            this.setState({isUser:false,isDepartment:false,isDate:true,isgroupbyDept:false,isReporttype:true,isSubmit:true,fields});
            let radioOptionfields = this.state.radioOptionfields;
            radioOptionfields['freegoods'] = !this.state.radioOptionfields.freegoods  
            this.setState({reportType:"1",reportName:"Authorizer_Totals"})
        }
        else if(pathName === "shiptototals")
        {
            fields['subTitle'] = 'Ship To Totals Report';
            this.setState({isUser:false,isDepartment:false,isDate:true,isgroupbyDept:false,isReporttype:true,isSubmit:true,fields});
            let radioOptionfields = this.state.radioOptionfields;
            radioOptionfields['freegoods'] = !this.state.radioOptionfields.freegoods  
            this.setState({reportType:"1",reportName:"Ship_Totals"})
        }
        else 
        {
            fields['subTitle'] = '';
            this.setState({isUser:false,isDepartment:false,isDate:false,isgroupbyDept:false,isReporttype:false,isSubmit:false,fields});
        }
        modal['modalSpinnerShow']=false;
      }
      handleChangeStartDate = date => {
        var today    =new Date();
        let errors = this.state.errors;  
        
        if (date === null)   
            errors["startDate"] = "Please enter start date"; 
       else{
            if(today < date )
            errors["startDate"] = "Date is greater than today's date";        
            else
            errors["startDate"] = "";     
       }    
      this.setState({startDate: date,errors});
      };
      handleChangeEndDate = date => {
        var today    =new Date();
        let errors = this.state.errors;   
        if(date === null)
          errors["endDate"] = "Please enter start date";
        else   
        {
            if(today < date )
                errors["endDate"] = "Date is greater than today's date";     
                else
                errors["endDate"] = "";   
        }
        this.setState({endDate: date, errors});
      };
      handleCheckChange(e:any){
        try {
            let checkfields = this.state.checkfields;
            checkfields[e.currentTarget.name] = !this.state.checkfields.groupByDept
            this.setState({
                checkfields
            });
        }
        catch(err){
            console.error(err);
        }
    }
    handleRadioOptionChange =(e:any)=>{
        
        let fields = this.state.fields;
        let radioOptionfields = this.state.radioOptionfields;
        //console.log('----handleOptionChange----');
        //console.log(radioOptionfields);
        fields['reportType'] = e.currentTarget.id;
        if (e.currentTarget.id === "fGoods") {
            radioOptionfields['freegoods'] = !this.state.radioOptionfields.freegoods
            this.setState({reportType:"1"})
            radioOptionfields['nonBillable'] = false;
            radioOptionfields['billable'] = false;
            radioOptionfields['all'] = false;
        }
        else if (e.currentTarget.id === "billable") {
            radioOptionfields['freegoods'] = false;
            radioOptionfields['nonBillable'] = !this.state.radioOptionfields.nonBillable;           
            radioOptionfields['billable'] = false;
            radioOptionfields['all'] = false;
            this.setState({reportType:"2"})
        }
        else if (e.currentTarget.id === "nbillable") {
            radioOptionfields['freegoods'] = false;
            radioOptionfields['nonBillable'] = false;            
            radioOptionfields['billable'] = !this.state.radioOptionfields.billable;
            radioOptionfields['all'] = false;
            this.setState({reportType:"3"})
        }
        else if (e.currentTarget.id === "all") {
            radioOptionfields['freegoods'] = false;
            radioOptionfields['nonBillable'] = false;
            radioOptionfields['billable'] = false;
            radioOptionfields['all'] = !this.state.radioOptionfields.all;
            this.setState({reportType:"4"})
        }        
        this.setState({
            fields,
            radioOptionfields
        });
    }

    onSubmit = (e:any) => {
        if (this.ValidateDate()) 
        { 
            let pathName = this.props.history.location.pathname.split("/").pop();
            var li="";
            var groupbyDep =""
            var sD = this.state.startDate;            
            var startDate =  sD.getMonth() + 1 + "/" + sD.getDate() + "/" + sD.getFullYear();
            var eD = this.state.endDate;            
            var endDate =  eD.getMonth() + 1 + "/" + eD.getDate() + "/" + eD.getFullYear();
            if(this.state.checkfields.groupByDept)       
            groupbyDep ="1";
            
            if(pathName === "usertotals")
            {           
            li= new Variables().ReportPath + this.state.reportName + this.state.reportRender +sessionStorage.getItem("label_id")! +"&Date1="+ startDate + "&Date2="+ endDate +"&Billable="+ this.state.reportType +"&User_Select="+ this.state.selectOption.selectUser +"&chkGroupByDepartment="+ groupbyDep +"&rc:Parameters=False"
            }        
            else if(pathName === "departmenttotals")
            {
                li=new Variables().ReportPath + this.state.reportName + this.state.reportRender + sessionStorage.getItem("label_id")! + "&Date1="+ startDate + "&Date2="+ endDate + "&Department=" + this.state.selectOption.selectDept + "&Billable=" + this.state.reportType + "&rc:Parameters=False"
            }        
            else 
            {
                li=new Variables().ReportPath + this.state.reportName + this.state.reportRender + sessionStorage.getItem("label_id")! + "&Date1="+ startDate + "&Date2="+ endDate +"&Billable=" + this.state.reportType + "&rc:Parameters=False"
            }
            window.open(li)
            this.props.history.push('/admin/home');
        }
    }

    ValidateDate(){ 
        let formIsValid = true;
        if(this.state.startDate === "" ||  this.state.startDate === null)
        {
            this.setState({errMsg : "Please enter start date"})
            formIsValid=false;
        }
        else if (this.state.endDate === "" ||  this.state.endDate === null)
        {
            this.setState({errMsg : "Please enter end date"})
            formIsValid=false;
        }
        return formIsValid
    }

    render(){
        
        //console.log('this.props');
        //console.log(this.props);
        
        if (this.props !== undefined)
        {
            //console.log(this.props.history.location.pathname.split("/").pop());
        }
        
        const { loading } = this.state;
        const { isUser }  = this.state;
        const { isDate }  = this.state;
        const { isgroupbyDept } = this.state;
        const { isReporttype } = this.state;
        const { isSubmit } = this.state;
        const { isDepartment } = this.state;
        let fields = this.state.fields;
            return (            
                <div className="content"> 
                <FormModalSpinner show={this.state.modal.modalSpinnerShow } />                 
                <Container fluid>
                <Row className="small">
                    <Col md={12}>
                        <Card border="light" style={{ height:'100%' }}>
                            <Card.Body>
                            <Card.Title><h4 className="title">View/ Print Reports</h4></Card.Title>
                            <Form onSubmit = {this.onSubmit}>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                <Col sm={12}>
                                    <div id="navbar">
                                        <Navbar expand="lg" >
                                            <Navbar.Brand className="align-center">{fields['subTitle']}</Navbar.Brand>
                                            <Nav className="ml-auto">
                                            </Nav>
                                        </Navbar>
                                    </div>
                                </Col>
                                </Row>   
                                { isUser ?
                                <Row>              
                                    <Col sm={4}>
                                        <FormSelect
                                        properties={{label:"User List",id:"userList",name:"userList",className:"form-control",onChange:this.handleChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.recordlabelId}
                                        defaultOption="All"
                                        fillOption={this.state.userList}
                                    />
                                    </Col>
                                    <Col sm={8}></Col>  
                                    </Row>: <Row><Col sm={12}></Col></Row> 
                                }
                                { isDepartment ?
                                <Row>              
                                    <Col sm={4}>
                                    <Form.Group>
                                        <FormSelect
                                            properties={{label:"Select Department",id:"department",name:"department",className:"form-control",onChange:this.handleChange}}
                                            fieldRequired={true}
                                            errorMsg={this.state.errors.deptList}
                                            defaultOption="All"
                                            fillOption={this.state.departmentList}
                                        />                               
                                    </Form.Group>
                                    </Col>
                                    <Col sm={8}></Col>  
                                    </Row>: <Row><Col sm={12}></Col></Row> 
                                }
                                {isDate ?
                                <Row className="mt-2 mb-2">               
                                    <Col sm={3}>
                                    <Form.Group>
                                    <DatePicker 
                                        Id="startDate"
                                        placeholderText="Start Date"
                                        selected={this.state.startDate}
                                        onChange={this.handleChangeStartDate}
                                        isClearable
                                        showMonthDropdown
                                        showYearDropdown                            
                                        className="datePicker"/>
                                        <Form.Text style={{margin:"0 0 0 0"}}>
                                        <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.startDate}</p>
                                        </Form.Text>
                                    </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                    <Form.Group>
                                    <DatePicker  
                                        Id="endDate"
                                        placeholderText="End Date"
                                        selected={this.state.endDate}
                                        onChange={this.handleChangeEndDate}                               
                                        isClearable
                                        showMonthDropdown
                                        showYearDropdown                            
                                        className="datePicker"/>
                                        <Form.Text style={{margin:"0 0 0 0"}}>
                                            <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.endDate}</p>
                                        </Form.Text>
                                    </Form.Group>
                                    </Col>
                                    <Col sm={6}></Col> 
                                </Row>: <Row><Col sm={12}></Col></Row>                   
                                }
                                {isgroupbyDept ?
                                <Row> 
                                <Col sm={12}>
                                    <FormCheckBox
                                    properties={{label:"Check To Group By Department",id:"groupByDept",name:"groupByDept",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.groupByDept}}
                                    />
                                </Col>
                                </Row>: <Row><Col sm={12}></Col></Row> 
                                }
                                {isReporttype ?
                                <Row>               
                                    <Col sm={3}>
                                        <Form.Label>Type Of The Report</Form.Label>
                                    </Col>
                                    <Col sm={3}>
                                    <Form.Group>                        
                                        <Row>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem",color:"black"}}><Form.Check type="radio" label="Free Goods" name="reportType" id="fGoods" onChange={this.handleRadioOptionChange} checked={this.state.radioOptionfields.freegoods} /></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem",color:"black"}}><Form.Check type="radio" label="Billable" name="reportType"  id="billable" onChange={this.handleRadioOptionChange} checked={this.state.radioOptionfields.nonBillable} /></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem",color:"black"}}><Form.Check type="radio" label="Non-Billable Transfer" name="reportType"  id="nbillable" onChange={this.handleRadioOptionChange} checked={this.state.radioOptionfields.billable} /></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem",color:"black"}}><Form.Check type="radio" label="All" name="reportType"  id="all" onChange={this.handleRadioOptionChange} checked={this.state.radioOptionfields.all} /></Col>
                                        </Row>
                                    </Form.Group>
                                    </Col>  
                                    </Row>: <Row><Col sm={12}></Col></Row> 
                                }
                                <Row>               
                                    <Col sm={6}>
                                        <div className="form-group mt-4 mb-0 text-center">
                                            {isSubmit ? 
                                        <Button type="submit" disabled={loading} style={{ marginRight: "5px" }}>
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                            )}
                                            {loading && <span>wait...</span>}
                                            {!loading && <span>Get Report</span>}
                                        </Button> : ""} 
                                        <Button type="button" style={{ marginRight: "5px" }} onClick={() => {this.props.history.push('/admin/home')}}>Cancel</Button>
                                                
                                        </div>
                                    </Col> 
                                    <Col sm={6}></Col>
                                </Row>
                            </Form>
                            </Card.Body>                                  
                        </Card>
                        </Col>
                    </Row>
                </Container>
                </div>
    );
    }
    
}
