import {ServiceProxy} from "../Common/ServiceProxy";
import {Variables} from "../Variables/Variables"

export class EmailPasswordController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    response: { success: boolean; code: string; msg : string, alias: string; password: string; email: string; department: string; recordLabel: string; };
    constructor(){
        this.response = { success : true, code : '', msg : '', alias : '', password : '', email : '', department : '', recordLabel : '' }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getPasswordDetails(email : string){
        var url = this.variables.Url + this.variables.Controller.emailPassword + this.variables.emailPasswordService.getPasswordDetails;
        var data = {
            email : email
        }
        return this.serviceProxy.get(url, data).then(response =>{
            if(response.success){
                this.response.success = response.success;
                this.response.code = response.code;
                this.response.alias = response.alias;
                this.response.password = response.password;
                this.response.email = response.email;
                this.response.department = response.department;
                this.response.recordLabel = response.recordLabel;
                return this.sendEmailPassword();
            }
            else
            {
                console.log("Error in email password...," + response.message)
                if(response.code === 202)
                {
                    this.response.success = response.success;
                    this.response.code = response.code;
                    this.response.msg = response.message
                }
                else{
                    this.response.success = response.success;
                    this.response.code = response.code;
                    console.error("Error in email password...," + response.message)
                }
                return this.response;
            }
        }).catch(error => {
            console.error("Error in email password...," + error);
        });
    }

    sendEmailPassword(){
        var url = this.variables.Url + this.variables.Controller.emailPassword + this.variables.emailPasswordService.sendEmail;
        var data = {
            email : this.response.email,
            html : "<h4>Your login information for eGratis is as follows:</h4><p>Label: " + this.response.recordLabel + "</p><p> Department: " + this.response.department + "</p><p> UserName: " + this.response.alias + "</p><p> Password: " + this.response.password + "</p>"
        }
        return this.serviceProxy.post(url, data).then(response =>{
            if(response.success){
                this.response.success = response.success;
                this.response.code = response.code;
                this.response.msg = response.message
            }
            else{
                this.response.success = response.success;
                this.response.code = response.code;
                console.error("Error in sending email...," + response.message)
            }
            return this.response;
        }).catch(error => {
            console.error("Error in sending email...," + error);
        })
    }
}