// JScript File
export var CLEAN_54_DISCOUNT_THRESHOLD = .4;
export var CLEAN_56_DISCOUNT_THRESHOLD = .15;
export var CLEAN_59_DISCOUNT_THRESHOLD = .3;
export var CLEAN_66_DISCOUNT_THRESHOLD = .15;

export function RequireEVPApproval(cost, price,sale_type)
{
    var discount=(cost-price)/cost;
    
    if(sale_type=='54 Clean' && discount!=CLEAN_54_DISCOUNT_THRESHOLD)
    {
        var result=window.confirm('Discount does not equal 40%.Your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.'+String.fromCharCode(10)+'Please check the prices you have entered for Clean Product.');
        return result;
    }
}
export function EVPApproval(saletype)
{
    if(saletype=='54 - Clean (non-royalty bearing)')
    {
        var result=window.confirm('Discount does not equal 40%.Your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.'+String.fromCharCode(10)+'Please check the prices you have entered for Clean Product.');
        return result;
    }
    if(saletype=='56 - Clean (fully royalty bearing)' || saletype=='66 Ticket Bundling')
    {
         var result=window.confirm('Discount is greater than 15%.Your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.'+String.fromCharCode(10)+'Please check the prices you have entered for Clean Product.');
         return result
    }
    if(saletype=='59 - Clean (publisher royalties only)')
    {
        var result=window.confirm('Discount does not equal 30%.Your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.'+String.fromCharCode(10)+'Please check the prices you have entered for Clean Product.');
        return result
    }    
}
export function DisplayapproveFlag(percentage, sale_type)
{
	switch(sale_type)
    {
        case '54 - Clean (non-royalty bearing)':
        if(percentage!=CLEAN_54_DISCOUNT_THRESHOLD)
        {
            return true;
        }
        else
        {
            return false;
        }
        break;
        case '66 Ticket Bundling':
        case '56 - Clean (fully royalty bearing)':
        if(percentage>CLEAN_56_DISCOUNT_THRESHOLD)
        {
            return true;
        }
        else
        {
            return false;
        }
        break;
        case '59 - Clean (publisher royalties only)':
        if(percentage!=CLEAN_59_DISCOUNT_THRESHOLD)
        {
            return true;
        }
        else
        {
            return false;
        }
        
    }
}
export function CalculatePercent(cost, price)
{    
    var discount=(cost-price)/cost;    
    var float_num = parseFloat(discount); // parse as float number    
    return float_num.toFixed(2); 
}

export function CalculatedDiscountPrice(cost,salesType)
{ 
	var calculatedPrice=0;
	switch(salesType)
    {
        case 1:
        calculatedPrice=cost-(CLEAN_54_DISCOUNT_THRESHOLD * cost);
        break;
        case 9:
        case 3:
        calculatedPrice=cost-(CLEAN_56_DISCOUNT_THRESHOLD * cost);
        break;
        case 5:
        calculatedPrice=cost-(CLEAN_59_DISCOUNT_THRESHOLD * cost);        
    } 
    var float_num = parseFloat(calculatedPrice);   
    return float_num.toFixed(3); 
}

export function CalculateFinalPercentage(saletype)
{

    if(saletype=='54 - Clean (non-royalty bearing)')
    {
        var result=window.confirm('If discount does not equal 40%, your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.');
        return result;
    }
    if(saletype=='56 - Clean (fully royalty bearing)' || saletype=='66 Ticket Bundling')
    {
         var result=window.confirm('If discount is greater than 15%, your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.');
         return result
    }
    if(saletype=='59 - Clean (publisher royalties only)')
    {
        var result=window.confirm('If discount does not equal 30%, your order will be forwarded to EVP '+String.fromCharCode(10)+'& CFO of North America for Approval.');
        return result
    }    

}



