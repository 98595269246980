import React, { Component } from "react";
import { History, LocationState } from "History";
import { Container,Card, Row, Col, Table, Form, Alert, Button} from "react-bootstrap";
//import { Container, Row, Col, Form, Alert, Button, ProgressBar } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/AdminLayout.css'
import { FormInputs } from '../../CustomComponents/FormInput';
//import Table from 'react-bootstrap/Table'
import { Variables } from '../../../Variables/Variables';
import '../../../assets/css/Style.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css'
import { NavLink } from 'react-router-dom'
import { IFtpHistoryParameter, Istate } from "../../../Model/FTPHistory"; 
import{FtpHistoryController} from "../../../Controllers/FtpHistoryController"
import { format } from 'date-fns'
import {alert} from 'react-bootstrap-confirmation';

export class FtpHistory extends Component<IFtpHistoryParameter, Istate> {

    constructor(props: IFtpHistoryParameter) {
  
      super(props)
      this.state = {
        fields: { find:'',searchType:'',statusBy:'' },
        selectfields: { searchBy: '',statusBy:'' },
        checkfields: { multipleSelect: false },
        errors: {startDate:'',endDate:''},      
        errMsg: '',
        loading: false,
        loadingReset:false,      
        startDate: "",   
        endDate:"",
        hidediv:true,
        displayGrid:[],        
        checkedItems: new Map(),        
        isDateEnabled:false,
        isListAvl:false,
        isListAvlMsg: "List is Empty...Please search for the needs",
        orderList:""
      }
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
      this.handleTextChange = this.handleTextChange.bind(this);   
      this.handleChange = this.handleChange.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.onSubmit=this.onSubmit.bind(this); 
    }

    componentWillMount()
    {  
      var today    =new Date();      
      var startDate=new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));
      this.setState({ startDate: startDate});     
      this.setState({ endDate: today});
      let selectfields = this.state.selectfields;
      selectfields["statusBy"] = "17";
      this.setState({selectfields})
    }

    handleChangeStartDate = date => {
      var today    =new Date();
        let errors = this.state.errors;  
        if(date != "")      
        if(today < date )
          errors["startDate"] = "Date is greater than today's date";        
        else
          errors["startDate"] = "";       
        
         this.setState({startDate: date,errors});
      };
      handleChangeEndDate = date => {
        var today    =new Date();
        let errors = this.state.errors;       
        if(today < date )
           errors["endDate"] = "Date is greater than today's date";     
           else
           errors["endDate"] = "";      
        
        this.setState({endDate: date, errors});
      };

      handleTextChange (e:any) {        
        let fields = this.state.fields;
        fields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({
            fields
        })
      }

      handleSelectChange (e:any) {       
        let selectfields = this.state.selectfields;
        if (e.currentTarget.id == "searchBy")
        {
          selectfields.searchBy= e.currentTarget.value;   
          if (e.currentTarget.value === "Order_id") 
          {            
            this.setState({isDateEnabled : true})            
          }
          else
          {
            this.setState({isDateEnabled : false})           
          }    
        }  
        else if (e.currentTarget.id == "statusBy")
        {
          selectfields.statusBy= e.currentTarget.value; 
          if (e.currentTarget.value === "2") 
          {            
            this.setState({isDateEnabled : true})           
          }
          else
          {
            this.setState({isDateEnabled : false})
          }   
        }
        this.setState({
          selectfields
        })
      }      

      onSubmit =(e:any)=>{
        try{
        e.preventDefault();
        if (this.ValidateDate()) { 
            this.setState({ loading: true });
            this.setState({isListAvl:false})
            var sD = this.state.startDate;
            var curr_date = sD.getDate();
            var curr_month = sD.getMonth() + 1; //Months are zero based
            var curr_year = sD.getFullYear();
            var startDate = curr_month + "/" + curr_date + "/" + curr_year;

            var eD = this.state.endDate;
            var curr_date = eD.getDate();
            var curr_month = eD.getMonth() + 1; //Months are zero based
            var curr_year = eD.getFullYear();
            var endDate = curr_month + "/" + curr_date + "/" + curr_year;

            let ftpHistoryController = new FtpHistoryController();
            ftpHistoryController.getFTPOrderHistory(this.state.fields.find,this.state.selectfields.searchBy,
              startDate,endDate,this.state.selectfields.statusBy)
            .then(jsonFTPHistoryList =>{
              this.setState({                           
                displayGrid : JSON.parse(JSON.stringify(jsonFTPHistoryList)), loading: false
            });                 
            if(this.state.displayGrid.length > 0)
              {                     
                this.setState({isListAvl:true})
                this.setState({isListAvlMsg:""})
                this.setState({ loading: false });
              }
              else
              {                        
                this.setState({isListAvlMsg:"No record(s) found"})
                this.setState({ loading: false });
              }  
            }).catch(error => {          
            this.setState({ loading: false });
            console.log(error);
          })
        }
      }
      catch(err){
        console.error(err);
        } 
     }

     ValidateDate(){      
      this.clearError();
      let formIsValid = true;  
      let errors = this.state.errors;
      var sD = format(this.state.startDate, 'dd/MM/yyyy');
      var eD = format(this.state.endDate, 'dd/MM/yyyy');
      var today    =new Date();
      var todayDate = format(today, 'dd/MM/yyyy');

      if(today < this.state.startDate )
      {
        errors["startDate"] = "Date is greater than today's date"; 
        formIsValid =false;
      }
      else if(todayDate === sD)
      {
        errors["startDate"] = "Date must be less today's date";
        formIsValid =false;
      }     
      if(eD > todayDate)
      {
        errors["endDate"] = "Date is greater than today's date";
        formIsValid =false;
      }      
        return formIsValid;
     }

     clearError()
      {
        let errors = this.state.errors;
        errors["startDate"] = "";  
        errors["endDate"]="";        
        this.setState({errMsg : ""})
        this.setState({
          errors
      });
      }

      getItemInfo= (item) =>{
        if(item["material"] || item["billable"])
        {
          if (item["material"] && item["billable"])
          {
            return " Billable Clean"
          }
          else
          {
            if(item["material"] )
            {
              return " Clean"
            }
            else if(item["billable"])
            {
            return " Billable"
            }
          }
        }
        else{
         return ""
        } 
       };
     
       getLinkWithtestPath= (item) =>{
        return (<NavLink
          to={{
            pathname:'/admin/freegoodsreqform',
            state:{
              Order_id:item["Order_Id"],
              goodsReq:"E"
            }
          }}                                        
          activeClassName="active">{item["Order_Id"]}</NavLink>)
      }

      getLinkWithPath= (item) =>{
        return (<NavLink
          to={{
            pathname:'/admin/viewOrderDetails',
            state:{
              Order_id:item["Order_Id"],
              task_For:"V",           
            }
          }}                                        
          activeClassName="active">{item["Order_Id"]}</NavLink>)
      };

       resetCheckInfo=(e:any) => {     
        try{
          if (this.ValidateOrder() && this.validteConfirmation()) { 
            var orderList = this.state.orderList;
            orderList= orderList.replace(/,\s*$/, "");
            this.setState({ loadingReset: true });   
            let ftpHistoryController = new FtpHistoryController();
            ftpHistoryController.resetFTPOrders(orderList)
            .then(response => {
              if (response.success){       
                alert("Order Reseted...")
                this.props.history.push('/admin/home');    
              }
            }).catch(error => {
              this.setState({ errMsg : "Process Failed" });
            })
          }
        }
        catch(err){
          console.error(err);
          }  
        
       }

       validteConfirmation() {
        let formIsValid = true; 
        const r = window.confirm("Are you want to reset these items?"); 
        if(r != true){ formIsValid = false; }
        return formIsValid;        
       }

       ValidateOrder(){  
        this.clearError();   
        let formIsValid = true; 
        let errors = this.state.errors;  
        if(this.state.orderList === "")
        {
          formIsValid = false                      
          this.setState({errMsg : "Please slect checkbox to reset order(s)"})          
        }
        this.setState({
          errors})        
        return formIsValid;
       }

       handleChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;
        var listOrder = this.state.orderList
        let ids:any = this.state.displayGrid;  
        if(item === "checkAll")  
        {
          ids.map((prop,key) => {               
            ids[key]["isChecked"] = isChecked;
            listOrder += prop["Order_Id"] + ","           
          })
        }
        else{
        ids.map((prop,key) => {               
          if(prop["Order_Id"] === item)
          {
             ids[key]["isChecked"] = isChecked; 
             listOrder += prop["Order_Id"] + ","          
          }          
        })
      }
        this.setState({displayGrid : ids})
        this.setState({orderList : listOrder})     
      }
      
          

    render() {
        const { loading } = this.state; 
        const { loadingReset } = this.state;      
        const { isListAvl } = this.state;     
        return (
          <div className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card border="light" style={{ width: '100%', height:'100%' }}>
                      <Card.Body>
                      <Card.Title><h4 className="title">FTP History Search</h4></Card.Title>
                      <Form  onSubmit= {this.onSubmit}>
                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                        <Row>
                            <Col sm={3}> 
                               <FormInputs
                                properties={{id:"find",label:"Find",type:"text",name:"find",className:"form-control input-sm",maxlength:24,value:this.state.fields.find, onChange:this.handleTextChange}}
                                errorMsg={this.state.errors.find}
                                fieldRequired={true}
                                />             
                            </Col>
                            <Col sm={3} >
                                <Form.Group>
                                    <Form.Label>In</Form.Label>
                                    <Form.Control as="select" id="searchBy" onChange={this.handleSelectChange}>
                                            <option value="" selected>Select Search Option</option>
                                            <option value="Requestor">Requestor #</option>
                                            <option value="Order_id">Order ID</option>
                                            <option value="FTPFileName">FTP Filename</option>
                                            <option value="Artist">Artist</option>
                                            <option value="Title">Title</option>
                                            <option value="Selection_Id">Selection #</option>
                                            <option value="Name">Ship To Name</option> 
                                            <option value="ShipToName">Ship To Co</option>
                                    </Form.Control>  
                                    <Form.Text style={{margin:"0 0 0 0"}}>
                                    <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.searchType}</p>
                                    </Form.Text>                                
                            </Form.Group> 
                            </Col>                         
                         </Row>
                        <Row>                           
                            <Col sm={2.5}>                            
                                <DatePicker 
                                Id="startDate"
                                placeholderText="From Date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown  
                                disabled ={this.state.isDateEnabled}                        
                                className="datePicker"/>
                                <Form.Text style={{margin:"0 0 0 0"}}>
                                 <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.startDate}</p>
                              </Form.Text>
                            </Col>                            
                            <Col sm={2.5}>
                                <DatePicker 
                                Id="startDate"
                                placeholderText="To Date"
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown  
                                disabled ={this.state.isDateEnabled}                          
                                className="datePicker"/>
                                <Form.Text style={{margin:"0 0 0 0"}}>
                                 <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.endDate}</p>
                              </Form.Text>
                           </Col>
                           <Col sm={0.5}>
                                    <Form.Label>For Status</Form.Label>
                            </Col>
                            <Col sm={2.5} >
                                <Form.Group>                                    
                                    <Form.Control as="select" id="statusBy" onChange={this.handleSelectChange}>
                                            <option value="17" selected>Depot Pending</option>
                                            <option value="8">Depot Processed</option>
                                            <option value="2">Approved</option>
                                            <option value="9">Depot Rejected</option>                                            
                                    </Form.Control>  
                                    <Form.Text style={{margin:"0 0 0 0"}}>
                                    <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.statusBy}</p>
                                    </Form.Text>                                
                            </Form.Group> 
                            </Col> 
                        </Row> 
                        <Row>                                  
                            <Col sm={12} style={{ paddingBottom: "15px"}}>
                                <div className="form-group mb-0 text-center">                                
                                <Button type="submit" disabled={loading}>
                                  {loading && (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px", color:"#3472F7" }}
                                    />
                                  )}
                                  {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                  {!loading && <span>Search History</span>}
                                </Button>                        
                                </div>
                            </Col>
                        </Row>                        
                        <Row >
                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                        <div className="scroll" style={{ width: '100%' }}>
                        { isListAvl ? 
                                <Table hover>
                                  <thead>                                  
                                    <tr>
                                    <th>
                                      <input type="checkbox"
                                        id={"checkboxAll"}
                                        name="checkboxAll"   
                                        value="checkAll"                                                                                    
                                        onChange={this.handleChange}     
                                        ref="checkboxAll"                                 
                                      />   
                                    </th>
                                      {new Variables().thFtpHistoryList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead>                    
                                  <tbody>                           
                                    {this.state.displayGrid.map((prop, key) => { 
                                        return (
                                            <tr key={key} >
                                            <td>
                                            <input type="checkbox"
                                                    id={"checkbox" + prop["Order_id"]}
                                                    name="spatialAverages"
                                                    value={prop["Order_Id"]}                                                    
                                                    onChange={this.handleChange}
                                                    ref={'ref_' + prop["Order_id"]}
                                                    checked={prop["isChecked"]}                                             
                                            />                                  
                                            </td>                                            
                                            <td>{this.getLinkWithPath(prop)}
                                                {this.getItemInfo(prop)}
                                            </td>
                                            <td>{prop["Requestor"]}</td>
                                            <td>{prop["RequestDate"]}</td>
                                            <td>{prop["FTPFileName"]}</td>
                                            <td>{prop["Date_Uploaded"]}</td>
                                            <td>{prop["StatusDesc"]}</td>
                                        </tr>
                                        )            
                                      })} 
                                   </tbody>
                                </Table>:
                                <Table>
                                  <thead>
                                    <tr>
                                      {new Variables().thFtpHistoryList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead> 
                                  <tbody>
                                    <tr>
                                      <td colSpan={11} align="center" className="text-danger font-size-xs small">{this.state.isListAvlMsg}</td>
                                    </tr>
                                  </tbody>
                                </Table>} 
                            </div>
                        </Col>
                        </Row>                   
                        {isListAvl ?
                        <Row>                           
                            <Col sm={12} style={{ paddingBottom: "15px"}}>
                                <div className="form-group mb-0 text-center">                                
                                <Button type="button" disabled={loadingReset} onClick={this.resetCheckInfo}>
                                  {loadingReset && (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px", color:"#3472F7" }}
                                    />
                                  )}
                                  {loadingReset && <span style={{color:"#3472F7"}}>wait...</span>}
                                  {!loadingReset && <span>Reset Checked</span>}
                                </Button>                        
                                </div>
                            </Col>                                                        
                         </Row> : ""}              
                      </Form>
                      </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }

}
