import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { AdminNavbar } from "../../CustomComponents/NabvBar/AdminNavBar";
import { Footer } from "../../CustomComponents/Footer";
import { Sidebar } from "../../CustomComponents/Sidebar";
import NotificationSystem from "react-notification-system";
import { Routes } from "../../../Routing/Route";
import image from "../../../assets/img/sidebar-3.jpg";
import '../../../assets/css/eGratis-react.css';
import '../../../assets/css/AdminLayout.css';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../CustomComponents/IdleTimeOutModal';
import {History, LocationState} from "History";

export class AdminLayout extends Component<IAdmin,Istate>  {
  constructor(objAdmin:IAdmin) {
    super(objAdmin);
    //idleTimer : IdleTimer | null
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
      timeout: 20 * 60 * 1000,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false
    };
    //this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleOnAction() {
    //console.log('user did something', e)
    this.setState({isTimedOut: false})
  }
 
  handleOnActive() {
    console.log('user is active')
    this.setState({isTimedOut: false})
  }
 
  handleOnIdle() {
    //console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    this.setState({showModal: true})
  }
  handleClose() {
    this.setState({showModal: false})
  }

  handleLogout() {
    this.setState({showModal: false})
    sessionStorage.clear();
    this.props.history.push('/')
  }

  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
  /*this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });*/
  };
  getRoutes = (Routes: any[]) => {
    return Routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (routes: string | any[], path: any) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    var _notificationSystem:any = this.refs.notificationSystem;
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    /*_notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: "tr",
      autoDismiss: 15
    });*/
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      if(document.scrollingElement != undefined){
        (document.scrollingElement as HTMLElement).scrollTop = 0;
      }
      (this.refs.mainPanel as HTMLElement).scrollTop = 0;
    }
  }
  render() {
    //const { match } = this.props
    let routes = new Routes().dashboardRoutes;
    return (
      <div className="wrapper">
        {<Sidebar 
        {...this.props} 
        routes={routes} 
        image={this.state.image} 
        color={this.state.color} 
        hasImage={this.state.hasImage}
        />}
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText="eGratis Promotional Product Request"
          />
          <IdleTimer
            //ref={ref => { this.idleTimer = ref }}
          timeout={this.state.timeout}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          //onAction={this.handleOnAction}
          debounce={250} />
          <Switch>{this.getRoutes(routes)}</Switch>
          {/*<Footer />*/}
          <IdleTimeOutModal 
              showModal={this.state.showModal} 
              handleClose={this.handleClose}
              handleLogout={this.handleLogout}
          />
        </div>
      </div>
    );
  }
}
//AdminLayout.propTypes = {
//     match: PropTypes.any.isRequired,
//     history: PropTypes.func.isRequired
// }

interface Istate{
  _notificationSystem?: NotificationSystem;
  image?: any;
  color?: string;
  hasImage?:boolean;
  fixedClasses?:string;
  mini?:string;
  timeout:number;
  showModal:boolean;
  userLoggedIn:boolean;
  isTimedOut:boolean;
}

interface IAdmin{
  location:any;
  history?:History<LocationState>;
}