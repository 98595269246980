import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container, Card, Row, Col, Form, Button, Table, Alert} from "react-bootstrap";
import '../../../assets/css/Style.css'
import {FormInputs} from '../../CustomComponents/FormInput';
import {CommonController} from '../../../Controllers/CommonController';
import {SysopController} from '../../../Controllers/SysopController'
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class LabelMaintenance extends Component<LabelParameter,Istate> {
    constructor(props:LabelParameter){
        super(props);
        this.state = {
            fields: {recordLabelId:'', recordLabelName:''},
            errors: {recordLabelId:'', recordLabelName:''},
            checkfields: {parentLabel:''},
            recordLabel: [],
            loading: false,
            errMsg:'',
            edit:false,
            modal: {modalSpinnerShow: false}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let commonController = new CommonController();
        commonController.getAllLabel().then(jsonLabel => {
        this.setState({                           
            recordLabel : JSON.parse(JSON.stringify(jsonLabel))
        });
        modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading operating company...", error)
        });
    }

    handleChange = (e:any) => {
        
        let fields = this.state.fields;
        var regexpUpper = new RegExp(/^[a-zA-Z]*$/)
        var regexp = new RegExp(/^[a-zA-Z0-9]*$/)
        if(e.currentTarget.name === 'recordLabelId'){
            if(regexpUpper.test(e.currentTarget.value)){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
        }
        else if(e.currentTarget.name === 'recordLabelName'){
            // if(regexp.test(e.currentTarget.value)){
            // }
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({
          fields 
        });
    }

    handleCheckChange = (e:any) => {
        let checkfields = this.state.checkfields;
        if(e.currentTarget.name === 'parentLabel'){
            checkfields[e.currentTarget.name] = !this.state.checkfields.parentLabel;
        } 
        this.setState({
            checkfields
        });
    }

    clearAll(){
        this.clearError();
        let fields = this.state.fields;
        fields["recordLabelId"] = "";
        fields["recordLabelName"] = "";
        this.setState({
          fields, edit: false 
        });
    }

    clearError(){
        let errors = this.state.errors;
        errors["recordLabelId"] = "";
        errors["recordLabelName"] = "";
        this.setState({
            errors, errMsg: "" 
        });
    }

    insertValues(recordLabelId,recordLabelName,parentLabel){
        this.clearError();
        this.setState({edit:true});
        let fields = this.state.fields;
        let checkfields = this.state.checkfields;
        fields["recordLabelId"] = recordLabelId;
        fields["recordLabelName"] = recordLabelName;
        if(parentLabel === "Yes"){
            checkfields["parentLabel"] = true;
        }
        else{
            checkfields["parentLabel"] = false;
        }
        this.setState({
          fields, checkfields 
        });
    }

    ValidateProfile(){
        this.clearError();
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        if (!fields["recordLabelId"]) {
            formIsValid = false;
            errors["recordLabelId"] = "Please enter label id.";
        }
        if (!fields["recordLabelName"]) {
            formIsValid = false;
            errors["recordLabelName"] = "Please enter label name.";
        }
        if(!formIsValid)
        {
            this.setState({ loading: false });
        }
        this.setState({
            errors
        });
        return formIsValid;
    }

    checkDuplicateLabelId = () => {
        var duplicate = false;
        this.state.recordLabel.map((prop : any, key) => {
            if(prop["id"].toUpperCase() === this.state.fields.recordLabelId.toUpperCase()){
                duplicate = true;
            }
        })
        return duplicate;
    }

    checkDuplicateLabelName = () => {
        var duplicate = false;
        this.state.recordLabel.map((prop : any, key) => {
            if(prop["name"].toUpperCase() === this.state.fields.recordLabelName.toUpperCase()){
                duplicate = true;
            }
        })
        return duplicate;
    }



    onSubmit = (e:any) => {
        this.setState({ loading: true });
        e.preventDefault();
        if (this.ValidateProfile()) {
            if(this.checkDuplicateLabelId()){
                alert("Label Id already exists...")
                this.setState({ loading: false });
            }
            else if(this.checkDuplicateLabelName()){
                alert("Label name already exists...")
                this.setState({ loading: false });
            }
            else{
                let sysopController = new SysopController();
                if(this.state.edit){
                    sysopController.updateLabel(this.state.fields.recordLabelId,this.state.fields.recordLabelName,this.state.checkfields.parentLabel)
                    .then(response => {
                        if(response.success){
                            alert("Label updated successfully...")
                            let commonController = new CommonController();
                            commonController.getAllLabel().then(jsonLabel => {
                            this.setState({                           
                                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
                            });
                            }).catch(error => {
                                console.error("There was an error loading operating company")
                            }); 
                        }
                        else{
                            this.setState({errMsg: "Error in update Label..."})
                        }
                        this.setState({ loading: false });
                    }).catch(error => {
                        console.error("Error in update Label...," +error);
                    })
                    this.setState({edit:false});
                    this.clearAll();
                }
                else{
                    sysopController.insertLabel(this.state.fields.recordLabelId,this.state.fields.recordLabelName,this.state.checkfields.parentLabel)
                    .then(response => {
                        if(response.success){
                            alert("Label created successfully...")
                            let commonController = new CommonController();
                            commonController.getAllLabel().then(jsonLabel => {
                            this.setState({                           
                                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
                            });
                            }).catch(error => {
                                console.error("There was an error loading operating company")
                            }); 
                        }
                        else{
                            this.setState({errMsg: "Error in adding label..."})
                        }
                        this.setState({ loading: false });
                    }).catch(error => {
                        console.error("Error in insert label...," +error);
                    })
                    this.clearAll();
                }   
            }
        }
    }

    render(){
        const{ loading } = this.state;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row>
                  <Col sm={12}>
                  <Card border="light" style={{ height:'100%' }}>
                    <Card.Body>
                        <Card.Title><h4 className="title">Label Maintenance</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={2}>
                                    <FormInputs
                                        properties={{id:"recordLabelId",label:"Label Id:",type:"text",name:"recordLabelId",className:"form-control input-sm",maxlength:24, disabled:this.state.edit ? true : false, value:this.state.fields.recordLabelId,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.recordLabelId}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"recordLabelName",label:"Label Name:",type:"text",name:"recordLabelName",className:"form-control input-sm",maxlength:24, value:this.state.fields.recordLabelName,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.recordLabelName}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={3} style={{textAlign: "center", alignSelf: "center"}}>
                                    <FormCheckBox
                                        properties={{label:"Parent Label",id:"parentLabel",name:"parentLabel",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.parentLabel}}
                                    />
                                </Col>
                                <Col sm={3} style={{textAlign: "center", alignSelf: "center"}}>
                                     <Button type="submit" disabled={loading}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color:"#3472F7" }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading && <span>Save</span>}
                                    </Button>
                                    <Button type="button" style={{ marginLeft: "5px" }} onClick={() => {this.clearAll()}}>Clear</Button>
                                </Col>
                            </Row>
                            <div className="clearfix" />
                        </Form>
                      </Card.Body>                                  
                    </Card>
                  </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ height:'100%' }}>
                        <Card.Body>
                            <Card.Title><h4 className="title">Label Details</h4></Card.Title>
                                <Row>
                                    <Col sm={12}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Label Id</th>
                                                    <th>Label Name</th>
                                                    <th>Owner Label</th>
                                                    <th>Update</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.recordLabel.map((prop, key) => {
                                                    return(
                                                        <tr key={key}>
                                                            <td>{prop["id"]}</td>
                                                            <td>{prop["name"]}</td>
                                                            <td>{prop["ownerLabel"]}</td>
                                                            <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={() => {this.insertValues(prop["id"],prop["name"],prop["ownerLabel"])}}>Update</Button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
              </Container>
            </div>
        );
    }
}

interface LabelParameter{
    uid?:string;
    pwd?:string;
    history?:History<LocationState>;
}
    
interface Istate{
    fields:any;
    errors:any;
    checkfields:any;
    recordLabel:[];
    loading:boolean;
    errMsg:string;
    edit:boolean;
    modal:any;
}