import {FormGroup,FormLabel} from "react-bootstrap";
import React,{ Component } from 'react';

export class FormLabelField extends Component<IFormLabel> {

    render() { 
      const {id, label, name, value}  = this.props.properties
         return(<div id={"dv"+id} >
           <FormGroup controlId={"frm"+name}>
              <FormLabel id={id}>{label}</FormLabel>
              <div x-ms-format-detection="none">{value}</div>
            </FormGroup>
          </div>)
      }
    }

  interface IFormLabel
  {
    properties:IProperties;
  }
  interface IProperties
  {
    id:string;
    label:string;
    name:string;
    className?:string;
    value:any;
  }

  interface Istate
  {

  }