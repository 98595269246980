import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class CreateOrderController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    selectPersonalContact(username : string, label : string, orderType : string){
        var url = this.variables.Url+ this.variables.Controller.contact + this.variables.contactService.selectPersonalContact;
        var data = {
            "alias": username,
            "recordLabelId": label,
            "orderType": orderType
        }
        //console.log(data);
        return this.serviceProxy.get(url,data)
            .then(response => {
                var jsonPersonalContact : Array<{"id": string, "name": string, "shipToName": string, "addressLineOne": string, "addressLineTwo": string, "city": string, "state": string, "zip": string, "phone": string, "recordLabelId": string,  "contacttype" : string, "domesticcontact" : string, "country": string, "accountnumber": string, "Company":string, "Addresses":string,"CityStateZip":string}> = [];
                if(response.success){
                    response.recordset.forEach(personalContact);
                    function personalContact(item: { Attention_Id: any; Name: any; ShipToName: any; AddressLineOne: any; AddressLineTwo: any; City: any; State:any; Zip: any; Phone: any; RecordLabel_Id: any; ContactType: any; DomesticContact: any; Country: any; Account_Number:any;Company:any;Addresses:any;CityStateZip:any}, index: number){
                        jsonPersonalContact.push({"id": item.Attention_Id, "name": item.Name, "shipToName": item.ShipToName, "addressLineOne": item.AddressLineOne, "addressLineTwo": item.AddressLineTwo, "city": item.City, "state": item.State, "zip": item.Zip, "phone": item.Phone, "recordLabelId": item.RecordLabel_Id, "contacttype" : item.ContactType, "domesticcontact" : item.DomesticContact, "country" : item.Country, "accountnumber" : item.Account_Number, "Company":item.Company,"Addresses":item.Addresses,"CityStateZip":item.CityStateZip})
                    }

                    return JSON.parse(JSON.stringify(jsonPersonalContact).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonPersonalContact).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    selectAllContact(type: string, value: string, alias: string, recordLabelId: string){
        var url = this.variables.Url+ this.variables.Controller.contact + this.variables.contactService.selectAllContact;
        var data = {
            "alias": alias,
            "recordLabelId": recordLabelId,
            "type": type,
            "value": value
        }
        //console.log(data);
        return this.serviceProxy.get(url,data)
            .then(response => {
                var jsonAllContact : Array<{"id": string, "contactType": string, "name": string, "shipToName": string, "addressLineOne": string, "addressLineTwo": string, "city": string, "state": string, "zip": string, "phone": string, "recordLabelId": string, "country": string}> = [];
                if(response.success){
                    response.recordset.forEach(personalContact);
                    function personalContact(item: { Attention_Id: any; ContactType: any; Name: any; ShipToName: any; AddressLineOne: any; AddressLineTwo: any; City: any; State:any; Zip: any; Phone: any; RecordLabel_Id: any; Country:any }, index: number){
                        jsonAllContact.push({"id": item.Attention_Id, "contactType": item.ContactType, "name": item.Name, "shipToName": item.ShipToName, "addressLineOne": item.AddressLineOne, "addressLineTwo": item.AddressLineTwo, "city": item.City, "state": item.State, "zip": item.Zip, "phone": item.Phone, "recordLabelId": item.RecordLabel_Id, "country": item.Country})
                    }
                    return JSON.parse(JSON.stringify(jsonAllContact).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonAllContact).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    privateContact(data: any){
        var url = this.variables.Url+ this.variables.Controller.contact + this.variables.contactService.privateContact;
        var data1 = {
            type:data.type,
            username:data.username,
            name:data.name,
            shipToName:data.shipToName,
            addressLineOne:data.addressLineOne,
            addressLineTwo:data.addressLineTwo,
            city:data.city,
            state:data.state,
            zip:data.zip,
            country:data.country,
            phone:data.phone,
            contactType:data.contactType,
            recordLabelId:data.recordLabelId,
            domesticContact:data.domesticContact,
            attentionId:data.attentionId
        }
        return this.serviceProxy.post(url,data1)
        .then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getReportingCo(data: any){
        
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getReportingCo;
        var data1 = {
            recordLabelId: data.recordLabelId
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("GetReportingCo");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }

    getProductSearch(data: any){
        
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getProductSearch;
        var data1 = {
            reqsWhere: encodeURIComponent(data.sWhere)
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            var jsonAllProducts : Array<{"artist": string, "title": string, "selectionId": string, "upc": string, "configuration": string, "unitsToSet": string, "releaseDate": string, "cost": string, "priceCode": string, "suggPriceCode": string, "RecordLabel_ID":string, "moratorium": string}> = [];
                if(response.success){
                    
                    response.recordset.forEach(products);
                    function products(item: { Artist: any; Title: any; Selection_ID: any; UPC: any, Configuration: any; UnitsToSet: any; Release_Date:any; cost1: any; PriceCode: any; SuggPriceCode: any; RecordLabel_ID:any; moratorium: any}, index: number){
                        jsonAllProducts.push({"artist": item.Artist, "title": item.Title, "selectionId": item.Selection_ID, "upc": item.UPC, "configuration": item.Configuration, "unitsToSet": item.UnitsToSet, "releaseDate": item.Release_Date, "cost": item.cost1, "priceCode": item.PriceCode, "suggPriceCode": item.SuggPriceCode, "RecordLabel_ID":item.RecordLabel_ID, "moratorium": item.moratorium})
                    }
                    
                    ////console.log("cntrgetProductSearch True",JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'))
                    return JSON.parse(JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    
                    ////console.log("cntrgetProductSearch false",JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'))
                    return JSON.parse(JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error =>{
            throw new Error('There was an error! '+ error)
            console.error('There was an error!', error);
        });
    }

  getProductSearchByUPCErrMsg(data: any){
        var data2 = {
            reqsWhere: encodeURIComponent(data.sWhere),
            UPC : data.UPC,
            labelId : data.labelId,
            userId : data.userId,
            superUser : data.superUser,
            executiveUser : data.executiveUser
        }
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getAllProductsByUPCErrMsg;
        return this.serviceProxy.get(url,data2)
        .then(response => {
            var jsonAllProducts : Array<{"upc": string}> = [];
                if(response.success){
                    
                    response.recordset.forEach(products);
                    function products(item: { UPC: any}, index: number){
                        jsonAllProducts.push({"upc": item.UPC})
                    }
                    
                   // console.log("cntrgetProductSearch True",JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'))
                    return JSON.parse(JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    
                    ////console.log("cntrgetProductSearch false",JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'))
                    return JSON.parse(JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error =>{
            throw new Error('There was an error! '+ error)
            console.error('There was an error!', error);
        });
    }



    getProductSearchByUPC(data: any){
        var data2 = {
            reqsWhere: encodeURIComponent(data.sWhere),
            UPC : data.UPC,
            labelId : data.labelId,
            userId : data.userId,
            superUser : data.superUser,
            executiveUser : data.executiveUser
        }
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getAllProductsByUPC;
      
        return this.serviceProxy.get(url,data2)
        .then(response => {
            var jsonAllProducts : Array<{"artist": string, "title": string, "selectionId": string, "upc": string, "configuration": string, "unitsToSet": string, "releaseDate": string, "cost": string, "priceCode": string, "suggPriceCode": string, "RecordLabel_ID":string, "moratorium": string}> = [];
                if(response.success){
                    
                    response.recordset.forEach(products);
                    function products(item: { Artist: any; Title: any; Selection_ID: any; UPC: any, Configuration: any; UnitsToSet: any; Release_Date:any; cost1: any; PriceCode: any; SuggPriceCode: any; RecordLabel_ID:any; moratorium: any}, index: number){
                        jsonAllProducts.push({"artist": item.Artist, "title": item.Title, "selectionId": item.Selection_ID, "upc": item.UPC, "configuration": item.Configuration, "unitsToSet": item.UnitsToSet, "releaseDate": item.Release_Date, "cost": item.cost1, "priceCode": item.PriceCode, "suggPriceCode": item.SuggPriceCode, "RecordLabel_ID":item.RecordLabel_ID, "moratorium": item.moratorium})
                    }
                    
                   // console.log("cntrgetProductSearch True",JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'))
                    return JSON.parse(JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    
                    ////console.log("cntrgetProductSearch false",JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'))
                    return JSON.parse(JSON.stringify(jsonAllProducts).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error =>{
            throw new Error('There was an error! '+ error)
            console.error('There was an error!', error);
        });
    }


    getStates(){
        var url = this.variables.Url + this.variables.Controller.contact + this.variables.contactService.getStates;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonStates : Array<{"id": string; "name": string; "desc": string}> = [];
            if(response.success){
                response.recordset.forEach(state);
                function state(item: { code: string; name: string; desc: string }, _index: number){
                    jsonStates.push({"id": item.code, "name": item.name, "desc": item.desc})
                }
                return JSON.parse(JSON.stringify(jsonStates).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonStates).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getAddressValidation(data: any){
        
        var url = this.variables.Url+ this.variables.Controller.contact + this.variables.contactService.getAddressValidation;
        var data1 = {
            zip: data.zip,
            city: data.city,
            state: data.state
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("GetAddressValidation");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getPrivateContact(attentionId : string){
        
        var url = this.variables.Url+ this.variables.Controller.contact + this.variables.contactService.getPrivateContact;
        var data = {
            attentionId : attentionId
        }
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    var resPrivateContact = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                    return resPrivateContact;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    insertOrderSummary(data:any){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.insertOrderSummary;
        return this.serviceProxy.post(url,data)
            .then(response => {
                if(response.success){
                    return response
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    updateInsertOrderItems(data:any){
        
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.updateInsertOrderItems;
        return this.serviceProxy.post(url,data)
            .then(response => {
                if(response.success){
                    return response
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    
    getProcessNameforSuperUser(data: any){
        
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getProcessNameforSuperUser;
        var data1 = {
            sessionuid:data.uid,
            sessionuserlabelid: data.user_label_id,
            sessionuserdepartment: data.user_department,
            labellist: data.label_list,
            orderlabelid: data.order_label_id,
            //bNoCatalog: data.bNoCatalog,
            order_type: data.order_type
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("GetProcessNameforSuperUser", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getProcessNameforRegAndExeUser(data: any){
        
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getProcessNameforRegAndExeUser;
        var data1 = {
            sessionuid:data.uid,
            order_type: data.order_type
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("GetProcessNameforRegAndExeUser", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getApprovalProcessValidate(username : string){
        
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getApprovalProcessValidate;
        var data1 = {
            "alias": username
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("GetApprovalProcessValidate", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    checkSelfApprover(alias: string, processName: string) : any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.checkSelfApprover;
        var data = {
            alias : alias,
            processName : processName
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                return response
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getOrderItems(orderId: string): any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getOrderItems;
        var data = {
            orderId: orderId
        }
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonOrderItems : Array<{"artist": string, "title": string, "selection": string, "config": string, "quantity": string, "price": string, "cost": string, "priceTB": string, "artistStore": string, "tourDate": string}> = [];
                if(response.success){
                    response.recordset.forEach(orderItem);
                    function orderItem(item: { Artist: any; Title: any; Selection_Id: any; Configuration: any; Quantity_Requested: any; price_requested: string; Cost: any; price_ticketbundling : any; artist_store_name : any; tour_date : any }){
                        jsonOrderItems.push({"artist": item.Artist, "title": item.Title, "selection": item.Selection_Id, "config": item.Configuration, "quantity": item.Quantity_Requested, "price": item.price_requested, "cost": item.Cost, "priceTB": item.price_ticketbundling, "artistStore": item.artist_store_name, "tourDate": item.tour_date})
                    }
                    return JSON.parse(JSON.stringify(jsonOrderItems).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonOrderItems).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getOrderSummary(orderId: string){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getOrderSummary;
        var data = {
            orderId: orderId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resOrderSummary = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                //console.log(resOrderSummary);
                return resOrderSummary;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCost(selectionId: string){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getCost;
        var data = {
            selectionId: selectionId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resCost = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resCost;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}