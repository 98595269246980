import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class QuickProductSearchController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    searchInfo : any;
    dateTimeFormat : DateTimeFormat;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
        this.searchInfo = {recordLabelId:'',title:'',artist:'',selection:'',upc:'', exceptions:false}
    }

    getSearchProductInfo(data){    
                
        this.searchInfo.recordLabelId = data.recordLabelId;
        this.searchInfo.title = data.title;
        this.searchInfo.artist = data.artist;
        this.searchInfo.selection = data.selection;
        this.searchInfo.upc = data.upc;

        var url = this.variables.Url + this.variables.Controller.quickProductSearch + this.variables.quickProductSearchService.quickProductSearch;            
        var data1 : any = {
            recordLabelId : this.searchInfo.recordLabelId,
            artist : this.searchInfo.artist,
            title : this.searchInfo.title,            
            selection : this.searchInfo.selection,
            upc : this.searchInfo.upc
        };

        return this.serviceProxy.get(url,data1) 
        .then(response => {  
            var jsonProductSearch : Array<{"item1" : string; "item2" : string; "item3" : string; "item4" : string; "item5" : string; "item6" : string; "item7" : string; "item8" : string; }> = [];        
            if(response.success)
            {
                response.recordset.forEach(process);
                function process(item: { Artist : string, Title : string, Selection : string, UPC : string, Configuration : string, Release : string, Label : string, Cat : string}, _index: number){
                    jsonProductSearch.push({"item1": item.Artist, "item2": item.Title, "item3": item.Selection, "item4": item.UPC, "item5": item.Configuration, "item6": item.Release, "item7" : item.Label, "item8" : item.Cat})                  
                }
                return JSON.parse(JSON.stringify(jsonProductSearch).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonProductSearch).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

}