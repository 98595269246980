import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class AutoAddExecUserController {

    serviceProxy: ServiceProxy;
    variables: Variables;
   
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }

    getExecUserDept(){
        var url = this.variables.Url+ this.variables.Controller.autoAddExecUser + this.variables.autoAddExecUserService.getExecUserDept;
        return this.serviceProxy.get(url,{})
            .then(response => {
                var jsonDept : Array<{"id": string, "dept": string, "label": string}> = [];
                if(response.success){
                    response.recordset.forEach(dept);
                    function dept(item: { department: any; RecordLabel: any; AutoAddExecUserDeptID: any;}, index: number){
                        jsonDept.push({"id": item.AutoAddExecUserDeptID, "dept": item.department, "label": item.RecordLabel})
                    }
                    return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    insertExecUserDeptFlag(dept : string , recordLabelId : string){
        var url = this.variables.Url + this.variables.Controller.autoAddExecUser + this.variables.autoAddExecUserService.insertExecUserDeptFlag;
        var data = {
            dept : dept,
            recordLabelId : recordLabelId,           
        }
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error => {
            return error;
        })
    }

    deleteExecUserDeptFlag(id:number){
        var url = this.variables.Url + this.variables.Controller.autoAddExecUser + this.variables.autoAddExecUserService.deleteExecUserDeptFlag;
        var data = {
            id : id            
        }
        return this.serviceProxy.delete(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in update dept...," + error);
        });
    }


}
