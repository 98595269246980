import React, { Component } from "react";
import { Container, Navbar, Nav, Card, Row, Col, Table, Form, Alert, Button } from "react-bootstrap";
import { FormControl, Modal } from "../../../../node_modules/react-bootstrap";
import { FormInputs } from '../../CustomComponents/FormInput'
import { FormSelect } from '../../CustomComponents/FormSelect';
import { FormCheckBox } from '../../CustomComponents/FormCheckBox';
import { Variables } from '../../../Variables/Variables';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css'
import '../../../assets/css/Style.css'
import { ReportController } from "../../../Controllers/ReportController"
import { IReportOrderByRequestorParameter, Istate } from "../../../Model/ReportOrderByRequestor";
import { CommonController } from '../../../Controllers/CommonController';
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import Autocomplete from 'react-autocomplete';

export class ReportOrderByRequestor extends Component<IReportOrderByRequestorParameter, Istate> {
    constructor(props: IReportOrderByRequestorParameter) {
        super(props)
        this.state = {
            fields: { reportType: '', subTitle: '', salesType: '', alias: '' },
            errors: { userList: '', startDate: '', endDate: '' },
            selectOption: { selectUser: '1' },
            checkboxOptionfields: { fivefour: false, fivesix: false, fivenine: false, all: false },
            loading: false,
            errMsg: "",
            startDate: "",
            endDate: "",
            userList: [],
            salesType: "",
            reportName: "",
            reportRender: "&rs:Command=Render",
            modal: { modalSpinnerShow: false }
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handlecheckboxOptionChange = this.handlecheckboxOptionChange.bind(this);
    }
    componentWillMount() {
        

        let fields = this.state.fields;
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        var today = new Date();
        var startDate = new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));
        this.setState({ startDate: startDate });
        this.setState({ endDate: today });
        this.setState({ reportName: "OrderByRequestorPeriod" });
        fields['subTitle'] = 'Order By Requestor';
        let reportController = new ReportController();
        reportController.getUserActiveList()
            .then(jsonUserActiveList => {
                this.setState({
                    userList: JSON.parse(JSON.stringify(jsonUserActiveList))
                })
                modal['modalSpinnerShow'] = false;
                this.setState({ modal })
            })
    }
    handleChange(e: any) {
        
        let selectOption = this.state.selectOption;
        if (e.currentTarget.name === "userList") {
            let selectUser = "";
            selectUser = e.currentTarget.options[e.currentTarget.selectedIndex].value
            if (selectUser != "All")
                selectOption["selectUser"] = selectUser
            else
                selectOption["selectUser"] = "1"
        }
        this.setState({ selectOption });
    }
    handleChangeStartDate = date => {
        // 
        var today = new Date();
        let errors = this.state.errors;

        if (date === null)
            errors["startDate"] = "Please enter start date";
        else {
            if (today < date)
                errors["startDate"] = "Date is greater than today's date";
            else
                errors["startDate"] = "";
        }
        this.setState({ startDate: date, errors });
    };
    handleChangeEndDate = date => {
        var today = new Date();
        let errors = this.state.errors;
        if (date === null)
            errors["endDate"] = "Please enter start date";
        else {
            if (today < date)
                errors["endDate"] = "Date is greater than today's date";
            else
                errors["endDate"] = "";
        }
        this.setState({ endDate: date, errors });
    };
    handlecheckboxOptionChange = (e: any) => {
        
        let fields = this.state.fields;
        let checkboxOptionfields = this.state.checkboxOptionfields;

        if (e.currentTarget.name === "all") {
            checkboxOptionfields["all"] = !checkboxOptionfields["all"]

            if (checkboxOptionfields["all"] === true) {
                checkboxOptionfields["fiveone"] = true
                checkboxOptionfields["fivethree"] = true
                checkboxOptionfields["fivefourclean"] = true
                checkboxOptionfields["fivefourmarked"] = true
                checkboxOptionfields["fivesixclean"] = true
                checkboxOptionfields["fivesixmarked"] = true
                checkboxOptionfields["fivenineclean"] = true
                checkboxOptionfields["fiveninemarked"] = true
                checkboxOptionfields["sixtysix"] = true
                checkboxOptionfields["eightynine"] = true
                checkboxOptionfields["ninetyone"] = true
                this.setState({ salesType: "all" })
            }
            else {
                checkboxOptionfields["fiveone"] = false
                checkboxOptionfields["fivethree"] = false
                checkboxOptionfields["fivefourclean"] = false
                checkboxOptionfields["fivefourmarked"] = false
                checkboxOptionfields["fivesixclean"] = false
                checkboxOptionfields["fivesixmarked"] = false
                checkboxOptionfields["fivenineclean"] = false
                checkboxOptionfields["fiveninemarked"] = false
                checkboxOptionfields["sixtysix"] = false
                checkboxOptionfields["eightynine"] = false
                checkboxOptionfields["ninetyone"] = false
                this.setState({ salesType: "" })
            }

        } else {
            checkboxOptionfields[e.currentTarget.name] = !checkboxOptionfields[e.currentTarget.name]
            this.setState({ salesType: e.currentTarget.id })
        }

        this.setState({
            fields,
            checkboxOptionfields
        });

    }
    onSubmit = (e: any) => {
        
        e.preventDefault();
        if (this.ValidateDate()) {
            
            var li = "";
            var sD = this.state.startDate;
            var startDate = sD.getMonth() + 1 + "/" + sD.getDate() + "/" + sD.getFullYear();
            var eD = this.state.endDate;
            var endDate = eD.getMonth() + 1 + "/" + eD.getDate() + "/" + eD.getFullYear();

            li = new Variables().ReportPath + this.state.reportName + "&Requestor=" + this.state.fields.alias + "&StartDate=" + startDate + "&EndDate=" + endDate

            if (this.state.checkboxOptionfields.fiveone === true)
                li = li + "&SalesType=" + "51 Clean"

            if (this.state.checkboxOptionfields.fivethree === true)
                li = li + "&SalesType=" + "53 Marked"

            if (this.state.checkboxOptionfields.fivefourclean === true)
                li = li + "&SalesType=" + "54 - Clean (non-royalty bearing)"
            
            if (this.state.checkboxOptionfields.fivefourmarked === true)
                li = li + "&SalesType=" + "54 - Marked (non-royalty bearing)"

            if (this.state.checkboxOptionfields.fivesixclean === true)
                li = li + "&SalesType=" + "56 - Clean (fully royalty bearing)"

            if (this.state.checkboxOptionfields.fivesixmarked === true)
                li = li + "&SalesType=" + "56 - Marked (fully royalty bearing)"
            
            if (this.state.checkboxOptionfields.fivenineclean === true)
                li = li + "&SalesType=" + "59 - Clean (publisher royalties only)"

            if (this.state.checkboxOptionfields.fiveninemarked === true)
                li = li + "&SalesType=" + "59 - Marked (publisher royalties only)"

            if (this.state.checkboxOptionfields.sixtysix === true)
                li = li + "&SalesType=" + "66 Ticket Bundling"

            if (this.state.checkboxOptionfields.eightynine === true)
                li = li + "&SalesType=" + "89 Sales Type"
            
            if (this.state.checkboxOptionfields.ninetyone === true)
                li = li + "&SalesType=" + "91 Clean"

            li = li + "&rc:Parameters=False"

            window.open(li)
            //this.props.history.push('/admin/home');
        }
    }

    setValue = (val) => {
        let fields = this.state.fields;
        fields["alias"] = val;
        this.setState({ fields });
    }

    ValidateDate() {
        
        let formIsValid = true;
        let fields = this.state.fields;


        if (this.state.startDate === "" || this.state.startDate === null) {
            this.setState({ errMsg: "Please enter start date" })
            formIsValid = false;
        }
        else if (this.state.endDate === "" || this.state.endDate === null) {
            this.setState({ errMsg: "Please enter end date" })
            formIsValid = false;
        }
        else if (this.state.checkboxOptionfields.fiveone === false && this.state.checkboxOptionfields.fivethree === false && this.state.checkboxOptionfields.fivefourclean === false
            && this.state.checkboxOptionfields.fivefourmarked === false && this.state.checkboxOptionfields.fivesixclean === false && this.state.checkboxOptionfields.fivesixmarked === false 
            && this.state.checkboxOptionfields.fivenineclean === false && this.state.checkboxOptionfields.fiveninemarked === false && this.state.checkboxOptionfields.sixtysix === false 
            && this.state.checkboxOptionfields.eightynine === false && this.state.checkboxOptionfields.ninetyone === false) {
            this.setState({ errMsg: "Please Select Sales Type" })
            formIsValid = false;
        }
        else if (fields["alias"] === "") {
            this.setState({ errMsg: "Please Select Requestor" })
            formIsValid = false;
        }
        return formIsValid
    }

    render() {

        if (this.props !== undefined) {
            //console.log(this.props.history.location.pathname.split("/").pop());
        }
        let fields = this.state.fields;
        const { loading } = this.state;
        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row className="small">
                        <Col md={12}>
                            <Card border="light" style={{ height: '100%' }}>
                                <Card.Body>
                                    <Card.Title><h4 className="title">View / Print Reports</h4></Card.Title>
                                    <Form onSubmit={this.onSubmit}>
                                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                        <Row>
                                            <Col sm={12}>
                                                <div id="navbar">
                                                    <Navbar expand="lg" >
                                                        <Navbar.Brand className="align-center">{fields['subTitle']}</Navbar.Brand>
                                                        <Nav className="ml-auto">
                                                        </Nav>
                                                    </Navbar>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={3} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                                <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                                    <Form.Label>Requestor</Form.Label>
                                                    <Autocomplete
                                                        items={this.state.userList}
                                                        shouldItemRender={(item, value) => item.id.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                        getItemValue={item => item.id}
                                                        renderItem={(item, isHighlighted) =>
                                                            <div style={{ background: isHighlighted ? '#a3e2f7' : '#ffffff' }}
                                                                key={item.id}>
                                                                {item.name}
                                                            </div>
                                                        }
                                                        value={this.state.fields.alias}
                                                        onChange={e => this.setValue(e.target.value)}
                                                        onSelect={(val) => this.setValue(val)}
                                                        inputProps={{
                                                            style: {
                                                                height: '40px',
                                                                width: '280px',
                                                                background: '#f9f9f9',
                                                                border: '1px solid #E3E3E3',
                                                                borderRadius: '4px'
                                                            },
                                                            placeholder: 'Search By Alias'
                                                        }}
                                                    />
                                                </Row>
                                            </Col>
                                            <Col sm={2} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Group>
                                                    <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                                      
                                                        <DatePicker
                                                            Id="startDate"
                                                            placeholderText="Start Date"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeStartDate}
                                                            isClearable
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            className="datePicker" />
                                                        <Form.Text style={{ margin: "0 0 0 0" }}>
                                                            <p className="text-danger" style={{ fontSize: "100%" }}>&nbsp;{this.state.errors.startDate}</p>
                                                        </Form.Text>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={0.5} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}></Col>
                                            <Col sm={2} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                            <Form.Label>End Date</Form.Label>
                                                <Form.Group>
                                                    <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                                       
                                                        <DatePicker
                                                            Id="endDate"
                                                            placeholderText="End Date"
                                                            selected={this.state.endDate}
                                                            onChange={this.handleChangeEndDate}
                                                            isClearable
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            className="datePicker" />
                                                        <Form.Text style={{ margin: "0 0 0 0" }}>
                                                            <p className="text-danger" style={{ fontSize: "100%" }}>&nbsp;{this.state.errors.endDate}</p>
                                                        </Form.Text>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={0.5} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}></Col>
                                            <Col sm={3} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                                <Form.Label>Sales Type Of The Report</Form.Label>
                                                <Row sm={0.5} style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                                                    <Form.Group>
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="All" name="all" id="all" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.all} /></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="51 - Clean" name="fiveone" id="51clean" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fiveone} /></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="53 - Marked" name="fivethree" id="53marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivethree} /></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="54 - Clean" name="fivefourclean" id="54clean" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivefourclean} /></Col>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="54 - Marked" name="fivefourmarked" id="54marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivefourmarked} /></Col>
                                                        </Row>
                                                        {/*<Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="54 - Marked" name="fivefourmarked" id="54marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivefourmarked} /></Col>
                                                        </Row>*/}
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="56 - Clean" name="fivesixclean" id="56clean" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivesixclean} /></Col>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="56 - Marked" name="fivesixmarked" id="56marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivesixmarked} /></Col>
                                                        </Row>
                                                        {/*<Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="56 - Marked" name="fivesixmarked" id="56marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivesixmarked} /></Col>
                                                        </Row>*/}
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="59 - Clean" name="fivenineclean" id="59clean" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fivenineclean} /></Col>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="59 - Marked" name="fiveninemarked" id="59marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fiveninemarked} /></Col>
                                                        </Row>
                                                        {/*<Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="59 - Marked" name="fiveninemarked" id="59marked" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.fiveninemarked} /></Col>
                                                        </Row>*/}
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="66 - Ticket Bundling" name="sixtysix" id="66" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.sixtysix} /></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="89 - Sales Type" name="eightynine" id="89" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.eightynine} /></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={1.5} style={{ paddingRight: "0.50rem", color: "black" }}><Form.Check type="checkbox" label="91- Clean" name="ninetyone" id="91" onChange={this.handlecheckboxOptionChange} checked={this.state.checkboxOptionfields.ninetyone} /></Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem",marginBottom:"9%" }}>
                                            <Col sm={3}></Col>
                                            <Col sm={2}>
                                                <div className="form-group mt-4 mb-0 text-center">
                                                    <Button type="submit" disabled={loading} style={{ marginRight: "5px" }}>
                                                        {loading && (
                                                            <i
                                                                className="fa fa-refresh fa-spin"
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                        )}
                                                        {loading && <span>wait...</span>}
                                                        {!loading && <span>Get Report</span>}
                                                    </Button>
                                                    {/* <Button type="button" style={{ marginRight: "5px" }} onClick={() => { this.props.history.push('/admin/home') }}>Cancel</Button> */}
                                                </div>
                                                <Col sm={7}></Col>
                                            </Col>
                                        </Row>                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                    
                </Container>
            </div >
        );
    }

}
