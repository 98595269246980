import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class ReportController {

    serviceProxy: ServiceProxy;
    variables: Variables;
    response : any;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }

    

    getUserList(labelId : string){
        var url = this.variables.Url + this.variables.Controller.Report + this.variables.ReportServices.getUserList;
        var data = {
            "labelId": labelId,                    
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonUserList : Array<{"name": string; "id": string;}> = [];
            if(response.success){                   
                response.recordset.forEach(list);                   
                function list(item: {  name:string;alias:string}, _index: number){
                        jsonUserList.push({"name": item.name, "id": item.alias })
                }
                return JSON.parse(JSON.stringify(jsonUserList).replace(/"\s+|\s+"/g, '"'));
            }
            else {                                    
                return JSON.parse(JSON.stringify(jsonUserList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });        
    }
    getUserActiveList(){
        var url = this.variables.Url + this.variables.Controller.Report + this.variables.ReportServices.getUserActiveList;
        var data = {              
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonUserActiveList : Array<{"name": string; "id": string;}> = [];
            if(response.success){                   
                response.recordset.forEach(list);                   
                function list(item: {  name:string;Alias:string}, _index: number){
                    jsonUserActiveList.push({"name": item.name, "id": item.Alias })
                }
                return JSON.parse(JSON.stringify(jsonUserActiveList).replace(/"\s+|\s+"/g, '"'));
            }
            else {                                    
                return JSON.parse(JSON.stringify(jsonUserActiveList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });        
    }   
}