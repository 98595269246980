export class ServiceProxy {
    headers = {
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json; charset=utf-8'
    };

    getUrl(url : string, data : any){
        var queryParam = Object.keys(data)
            .map(k => k + '=' + data[k])
            .join('&');
        return url + '?' + queryParam;
    }

    get(url : string, data : any)
    {
        if(data !== null)
        {
            url = this.getUrl(url, data)
        }
        const requestOptions = {
            method: 'GET',
            headers: this.headers
        }
        //console.log(url);
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }

    post(url : string ,data : any)
    {
        const requestOptions = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(data)
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }

    put(url : string ,data : any)
    {
        const requestOptions = {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(data)
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }

    delete(url : string ,data : any)
    {
        if(data !== null)
        {
            url = this.getUrl(url, data)
        }
        const requestOptions = {
            method: 'DELETE',
            headers: this.headers
        }
      //  console.log(url);
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }
}

