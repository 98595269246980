import {ServiceProxy} from "../Common/ServiceProxy";
import {Variables} from "../Variables/Variables"

export class LogoutController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    updateLoginFlag(username : string){
        var url = this.variables.Url + this.variables.Controller.logout + this.variables.logoutService.updateLoginFlag;
        var data = {
            username : username
        }
        debugger;
        sessionStorage.clear();
        localStorage.clear();
        this.serviceProxy.put(url, data).then(response =>{
            sessionStorage.clear();
            localStorage.clear();
        }).catch(error => {
            console.error("Error in user logout...," + error);
        });
    }
}