import React, { Component, CSSProperties } from "react";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "../../../../node_modules/react-bootstrap";
//import {Card} from '../../CustomComponents/Card';
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormInputSearch } from '../../CustomComponents/FormInputSearch';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { FormSelectSearch } from '../../CustomComponents/FormSelectSearch';
import { FormLabelField } from '../../CustomComponents/FormLabelField';
import jsonState from '../../../json/State.json';
import { CommonController } from '../../../Controllers/CommonController';
import { Variables } from '../../../Variables/Variables';
import '../../../assets/css/CreateOrder.css'
import { FormCheckBox } from '../../CustomComponents/FormCheckBox';
import { Redirect, NavLink } from 'react-router-dom';
import { OrderApprovalParameter, Istate } from "../../../Model/OrderApproval";
import { DepotOrdersController } from "../../../Controllers/DepotOrdersController";
import { OrderController } from "../../../Controllers/OrderController"
import * as  BillableScript from "../../../js/BillableScript.js"
import flag from "../../../assets/img/redflag.gif";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class FreeGoodsReqForm extends Component<OrderApprovalParameter, Istate>{
    constructor(props: OrderApprovalParameter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: { NextAppr: [], discount: [], apprFlag: [], nextStep: '', depot: '', approvers: '', legacyOrder: '', requestDate: '', customerPO: '', salesType: '', shipmentMethod: '', orderBy: '', accountNo: '', customerNumber: '', businessUnit: '', department: '', uid: '', pwd: '', lblSearchContact: '', businessunit: '', orderType: '', billTo: '', shipTo: '', specialIns: '', orderId: '', label: '', reason: '', qtyReq: [], rejectItem: [], comments: '' },
            selectfields: { shipmentMethod: '' },
            lblFields: {
                Order_Id: '', Alias: '', Process_Name: '', ArriveBy: '', BusinessUnit: '', SpecialInstructions: '', Reason: '', DepotComments: '', LegacyOrder: '',
                Status_Id: '', DateShipped: '', ShipTrackNo: '', Name: '', ShipToName: '', AddressLineOne: '', AddressLineTwo: '', City: '', State: '',
                Zip: '', Country: '', Phone: '', Requestor_Weight: '', Approver_Id: '', Approval_Date: '', Request_Date: '', ApproverComments: '',
                Label_Id: '', Super_Label_Id: '', IP_Address: '', Material: false, Date_Processed: '', Depot_Id: '', Department: '', ApproveBy_Clean: '', ApproveBy_Overnight: '',
                Overnight: '', CustomerNumber: '', RecordLock: '', Depot_Printer: '', Depot_Printer_Date: '', Ship_Method: '', Billable: false, Created: '',
                account_no: '', freight_billing_info: '', alias_Email: '', StatusDesc: '', status_id: '', Recordlabel_Id: '', ntbillablet: false
            },
            optionfields: { clean54: false, marked54: false, clean56: false, marked56: false, clean59: false, marked59: false },
            errMsg: "",
            items: [],
            approvers: [],
            key: 0,
            loggedIn,
            loading: false,
            NextAppr: [],
            OrdSum: [],
            DepotHis: [],
            ApprovalHis: [],
            OrdDetail: [],
            OrderID: "",
            SalesType: "",
            Title: "",
            holdLoading: false,
            rejectLoading: false,
            isClean: "",
            modal: {modalSpinnerShow: false}
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onRejectSubmit = this.onRejectSubmit.bind(this);
        this.onHoldSubmit = this.onHoldSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handletextChange = this.handletextChange.bind(this);
        this.handlecheckChange = this.handlecheckChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    loadOrderSummaryData() {
        
        let ordersController = new DepotOrdersController();
        let lblFields = this.state.lblFields;
        let fields = this.state.fields;
        // let optionfields = this.state.optionfields;
        ordersController.getOrderDetailsSummary(this.props.location.state.Order_id).then(resOrderdata => {
            lblFields["Order_Id"] = resOrderdata.Order_Id || "";
            lblFields["Alias"] = resOrderdata.Alias || "";
            lblFields["Process_Name"] = resOrderdata.Process_Name || "";
            lblFields["ArriveBy"] = resOrderdata.ArriveBy || "";
            lblFields["BusinessUnit"] = resOrderdata.BusinessUnit || "";
            lblFields["SpecialInstructions"] = resOrderdata.SpecialInstructions || "";
            lblFields["Reason"] = resOrderdata.Reason || "";
            lblFields["DepotComments"] = resOrderdata.DepotComments || "";
            lblFields["LegacyOrder"] = resOrderdata.LegacyOrder || "";
            lblFields["Status_Id"] = resOrderdata.Status_Id || "";
            lblFields["DateShipped"] = resOrderdata.DateShipped || "";
            lblFields["ShipTrackNo"] = resOrderdata.ShipTrackNo || "";
            lblFields["Name"] = resOrderdata.Name || "";
            lblFields["ShipToName"] = resOrderdata.ShipToName || "";
            lblFields["AddressLineOne"] = resOrderdata.AddressLineOne || "";
            lblFields["AddressLineTwo"] = resOrderdata.AddressLineTwo || "";
            lblFields["City"] = resOrderdata.City || "";
            lblFields["State"] = resOrderdata.State || "";
            lblFields["Zip"] = resOrderdata.Zip || "";
            lblFields["Country"] = resOrderdata.Country || "";
            lblFields["Phone"] = resOrderdata.Phone || "";
            lblFields["Requestor_Weight"] = resOrderdata.Requestor_Weight || "";
            lblFields["Approver_Id"] = resOrderdata.Approver_Id || "";
            lblFields["Approval_Date"] = resOrderdata.Approval_Date || "";
            lblFields["Request_Date"] = resOrderdata.Request_Date || "";
            lblFields["ApproverComments"] = resOrderdata.ApproverComments || "";
            lblFields["Label_Id"] = resOrderdata.Label_Id || "";
            lblFields["Super_Label_Id"] = resOrderdata.Super_Label_Id || "";
            lblFields["IP_Address"] = resOrderdata.IP_Address || "";
            lblFields["Material"] = Boolean(Number(resOrderdata.Material));
            lblFields["Date_Processed"] = resOrderdata.Date_Processed || "";
            lblFields["Depot_Id"] = resOrderdata.Depot_Id || "";
            lblFields["Department"] = resOrderdata.Department || "";
            lblFields["ApproveBy_Clean"] = resOrderdata.ApproveBy_Clean || "";
            lblFields["ApproveBy_Overnight"] = resOrderdata.ApproveBy_Overnight || "";
            lblFields["Overnight"] = resOrderdata.Overnight || "";
            lblFields["CustomerNumber"] = resOrderdata.CustomerNumber || "";
            lblFields["RecordLock"] = resOrderdata.RecordLock || "";
            lblFields["Depot_Printer"] = resOrderdata.Depot_Printer || "";
            lblFields["Depot_Printer_Date"] = resOrderdata.Depot_Printer_Date || "";
            lblFields["Ship_Method"] = resOrderdata.Ship_Method || "";
            lblFields["Billable"] = Boolean(Number(resOrderdata.Billable));
            lblFields["Created"] = resOrderdata.Created || "";
            lblFields["account_no"] = resOrderdata.account_no || "";
            lblFields["freight_billing_info"] = resOrderdata.freight_billing_info || "";
            lblFields["alias_Email"] = resOrderdata.alias_Email || "";
            lblFields["alias_Name"] = resOrderdata.alias_Name || "";
            lblFields["StatusDesc"] = resOrderdata.StatusDesc || "";
            lblFields["status_id"] = resOrderdata.status_id || "";
            lblFields["Recordlabel_Id"] = resOrderdata.Recordlabel_Id || "";
            lblFields["ntbillablet"] = Boolean(Number(resOrderdata.ntbillablet));
            lblFields["sales_type"] = resOrderdata.sales_type || "";
            lblFields["bill_to_name"] = resOrderdata.bill_to_name || "";
            lblFields["bill_to_address"] = resOrderdata.bill_to_address || "";
            lblFields["bill_to_city_state_zip"] = resOrderdata.bill_to_city_state_zip || "";
            this.setState({ lblFields });

            //this.bindSalesType();
            this.bindSalesTypeList();

            this.loadOrderDetailSummary();

        }).catch(error => {

        })
    }

    componentWillMount() {

        let modal = this.state.modal;
        //Load Spinner
        modal['modalSpinnerShow']=true;
        this.setState({ modal });

        var regex = new RegExp("^[0-9]*\.?[0-9]*$");

        let ordersController = new DepotOrdersController();

        var data = {
            Order_ID: this.props.location.state.Order_id
        }
        ordersController.getNextApprover(data).then(jsonNxtAppr => {
            this.setState({
                NextAppr: JSON.parse(JSON.stringify(jsonNxtAppr)), loading: false
            });
        }).catch(error => {
            this.setState({ loading: false });
            console.error("There was an error loading")
        });


        var data = {
            Order_ID: this.props.location.state.Order_id
        }
        ordersController.getOrderSummary(data).then(jsonOrdSum => {
            this.setState({
                OrdSum: JSON.parse(JSON.stringify(jsonOrdSum)), loading: false
            });
        }).catch(error => {
            this.setState({ loading: false });
            console.error("There was an error loading")
        });

        var data1 = {
            Order_ID: this.props.location.state.Order_id,
            approverType: ""
        }
        ordersController.getOrderApprovalHistory(data1).then(jsonApprHis => {
            this.setState({
                ApprovalHis: JSON.parse(JSON.stringify(jsonApprHis)), loading: false
            });
        }).catch(error => {
            this.setState({ loading: false });
            console.error("There was an error loading")
        });

        var data2 = {
            Order_ID: this.props.location.state.Order_id
        }
        ordersController.getOrderDepotHistory(data2).then(jsonDepotHis => {
            this.setState({
                DepotHis: JSON.parse(JSON.stringify(jsonDepotHis)), loading: false
            });
        }).catch(error => {
            this.setState({ loading: false });
            console.error("There was an error loading")
        });

        this.loadOrderSummaryData();


    }

 
    loadOrderDetailSummary() {
        
        let ordersController = new DepotOrdersController();

        var data3 = {
            Order_ID: this.props.location.state.Order_id
        }
        ordersController.getFreeGoodsOrderDetail(data3).then(jsonFreeOrdDetail => {
            this.setState({
                OrdDetail: JSON.parse(JSON.stringify(jsonFreeOrdDetail)), loading: false

            });

            this.state.OrdDetail.map((prop, key) => {
                var cost
                var price
                var percentvalue: any
                var percent
                var salestype
                var flag

                salestype = this.state.lblFields.sales_type;

                cost = prop["cost"];
                price = prop["Price_Approved"];

               // alert('test1')

                if (cost !== '') {
                    if (parseFloat(price) > 0) {
                        if (parseFloat(price) <= parseFloat(cost)) {
                            percent = BillableScript.CalculatePercent(cost, price);                            
                            percentvalue = (percent * 100).toFixed(0)                            
                            var displayflag = BillableScript.DisplayapproveFlag(percent, salestype);
                            if (displayflag) {                               
                                flag = true;                            }
                            else {                               
                                flag = false;
                            }
                        }
                        else {                            
                            percentvalue = "";
                            flag = true;
                        }
                    }
                    else {                        
                        percentvalue = "";
                        flag = false;
                    }

                }

                let fields = this.state.fields;
                fields["discount"][key] = percentvalue
                fields["apprFlag"][key] = flag
                this.setState({ fields });

                let modal = this.state.modal;
        modal['modalSpinnerShow']=false;
        this.setState({ modal });

            })

        }).catch(error => {
            this.setState({ loading: false });
            console.error("There was an error loading")
        });
    }

    displayDiscountAlert = (salestype) => {
        
        var salestypemsg
        var salestype
        if (salestype === "Clean54") {
            salestypemsg = "54 - Clean (non-royalty bearing)"
            salestype = 1
        }
        else if (salestype === "Clean56") {
            salestypemsg = "56 - Clean (fully royalty bearing)"
            salestype = 3
        }
        else if (salestype === "Clean59") {
            salestypemsg = "59 - Clean (publisher royalties only)"
            salestype = 3
        }
        else {
            salestypemsg = ""
            salestype = ""
        }

        BillableScript.CalculateFinalPercentage(salestypemsg)

        this.LoopGridCalculatePer(salestype)

    }


    LoopGridCalculatePer(salestype) {
        
        var cost
        var price
        var percentvalue: any
        var percent
        var flag
        var salestype

        salestype = this.state.lblFields.sales_type;
        this.state.OrdDetail.map((prop, key) => {
            cost = prop["cost"];
            price = prop["Price_Approved"];   
           
            if (cost !== '') {
                if (parseFloat(price) > 0) {
                    if (parseFloat(price) <= parseFloat(cost)) {
                        percent = BillableScript.CalculatePercent(cost, price);                       
                        percentvalue = (percent * 100).toFixed(0)
                        var displayflag = BillableScript.DisplayapproveFlag(percent, salestype);
                        if (displayflag) {                           
                            flag = true;                        }
                        else {                            
                            flag = false;
                        }
                    }
                    else {
                        percentvalue = "";
                        flag = true;
                    }
                }
                else {                   
                    percentvalue = "";
                    flag = false;
                }

            }
            let fields = this.state.fields;
            fields["discount"][key] = percentvalue;
            fields["apprFlag"][key] = flag;
            this.setState({ fields });

            this.displayOrderItemsTable();

        })

    }

    handleOptionChange = (e: any) => {
        
        let fields = this.state.fields;
        let optionfields = this.state.optionfields;
        let lblFields = this.state.lblFields;
        var orderType
        if (e.currentTarget.id === "Marked54") {
            optionfields['marked54'] = true;
            optionfields['clean54'] = false;
            optionfields['marked56'] = false;
            optionfields['clean56'] = false;
            optionfields['marked59'] = false;
            optionfields['clean59'] = false;
            lblFields['sales_type'] = "54 - Marked (non-royalty bearing)"
            this.setState({ isClean: 0 })

            this.displayDiscountAlert(e.currentTarget.id)
        }
        else if (e.currentTarget.id === "Clean54") {
            optionfields['clean54'] = true;
            optionfields['marked54'] = false;
            optionfields['marked56'] = false;
            optionfields['clean56'] = false;
            optionfields['marked59'] = false;
            optionfields['clean59'] = false;
            lblFields['sales_type'] = "54 - Clean (non-royalty bearing)"
            this.setState({ isClean: 1 })

            this.displayDiscountAlert(e.currentTarget.id)
        }
        else if (e.currentTarget.id === "Marked56") {
            optionfields['marked56'] = !this.state.optionfields.marked56
            optionfields['marked54'] = false;
            optionfields['clean54'] = false;
            optionfields['clean56'] = false;
            optionfields['marked59'] = false;
            optionfields['clean59'] = false;
            lblFields['sales_type'] = "56 - Marked (fully royalty bearing)"
            this.setState({ isClean: 0 })

            this.displayDiscountAlert(e.currentTarget.id)
        }
        else if (e.currentTarget.id === "Clean56") {
            optionfields['clean56'] = !this.state.optionfields.clean56
            optionfields['marked54'] = false;
            optionfields['clean54'] = false;
            optionfields['marked56'] = false;
            optionfields['marked59'] = false;
            optionfields['clean59'] = false;
            lblFields['sales_type'] = "56 - Clean (fully royalty bearing)"
            this.setState({ isClean: 1 })
            this.displayDiscountAlert(e.currentTarget.id)

        }
        else if (e.currentTarget.id === "Marked59") {
            optionfields['marked59'] = !this.state.optionfields.marked59
            optionfields['marked54'] = false;
            optionfields['clean54'] = false;
            optionfields['marked56'] = false;
            optionfields['clean56'] = false;
            optionfields['clean59'] = false;
            lblFields['sales_type'] = "59 - Marked (publisher royalties only)"
            this.setState({ isClean: 0 })

            this.displayDiscountAlert(e.currentTarget.id)
        }
        else if (e.currentTarget.id === "Clean59") {
            optionfields['clean59'] = !this.state.optionfields.clean59
            optionfields['marked54'] = false;
            optionfields['clean54'] = false;
            optionfields['marked56'] = false;
            optionfields['clean56'] = false;
            optionfields['marked59'] = false;
            lblFields['sales_type'] = "59 - Clean (publisher royalties only)"
            this.setState({ isClean: 1 })
            this.displayDiscountAlert(e.currentTarget.id)
        }

        this.setState({
            optionfields, lblFields
        });

    }

    bindSalesTypeList() {
        
        let optionfields = this.state.optionfields;
        let billable = this.state.lblFields.Billable
        let ntBillable = this.state.lblFields.ntbillablet
        var str = this.state.lblFields.sales_type
        var orderType


        if (str === "54 - Marked (non-royalty bearing)") {
            //optionfields['marked54'] = "Marked54"
            orderType = 0
            optionfields['marked54'] = true;

        }
        else if (str === "54 - Clean (non-royalty bearing)") {
            // optionfields['clean54'] = "Clean54"            
            orderType = 1

            optionfields['clean54'] = true;
            this.setState({
                optionfields
            });

        }
        else if (str === "56 - Marked (fully royalty bearing)") {
            optionfields['marked56'] = true;
            orderType = 0
        }
        else if (str === "56 - Clean (fully royalty bearing)") {
            optionfields['clean56'] = true;
            orderType = 1
        }
        else if (str === "59 - Marked (publisher royalties only)") {
            optionfields['marked59'] = true;
            orderType = 0
        }
        else if (str === "59 - Clean (publisher royalties only)") {
            optionfields['clean59'] = true;
            orderType = 1
        }
        else if (str === "66 Ticket Bundling") {
            optionfields['clean66'] = true;
            orderType = 1
        }

        this.setState({
            optionfields
        });

        //alert(this.state.optionfields.clean54);

        this.setState({ isClean: orderType })
        this.bindSalesType();

    }

    bindSalesType() {
        let fields = this.state.fields;
        let optionfields = this.state.optionfields;
        let billable = this.state.lblFields.Billable
        let ntBillable = this.state.lblFields.ntbillablet
        var str = this.state.lblFields.sales_type
        var orderType
        var salesType = str.split(" ");
        this.setState({ SalesType: salesType[0] })

        this.bindTitleForAllType()

    }

    bindTitleForAllType() {
        let billable = this.state.lblFields.Billable
        let ntBillable = this.state.lblFields.ntbillablet

        if (billable) {
            this.setState({ Title: "BILLABLE ORDER REQUEST FORM" })
        }
        else if (ntBillable) {
            this.setState({ Title: "NON BILLABLE TRANSFER FORM" })
        }
        else {
            this.setState({ Title: "FREE GOODS REQUEST FORM" })
        }

        if (this.state.lblFields.sales_type === "66 Ticket Bundling") {
            this.setState({ Title: "Ticket Bundling" });
        }

        if (this.state.lblFields.sales_type === "89 Sales Type") {
            this.setState({ Title: "D2C Form" });
        }

        this.loadBillToFrightInfo()

    }


    loadBillToFrightInfo() {
        return (
            <div >
                <p className="small" >{this.state.lblFields.bill_to_name}</p>
                <p className="small">{this.state.lblFields.bill_to_address}</p>
                <p className="small">{this.state.lblFields.bill_to_city_state_zip}</p>
            </div>
        );
    }

    setFocus(target:any){
        setTimeout(function () { target.focus(); },5);
    }

    handletextChange = (prop, key, e: any) => {
        let fields = this.state.fields;
        var salestype;
        var regex = new RegExp("^[0-9]*\.?[0-9]*$");
        const target = e.currentTarget;

        if (e.currentTarget.id === "qtyRec")
        {
        prop["Quantity_Approved"] = e.currentTarget.value; 
        }
        else if (e.currentTarget.id === "price"){
            prop["Price_Approved"] = e.currentTarget.value;

            if (!regex.test(e.currentTarget.value)) {
                alert("You have entered an invalid price. Please enter valid price");
                this.setFocus(target);
                return false;
            }

            salestype = this.state.lblFields.sales_type;        

            this.LoopGridCalculatePer(salestype)
        }
        
        this.setState({
            fields
        })



    }

    handleSelectChange = (item, e: any) => {
        let fields = this.state.fields;
        item["Ship_Method"] = e.currentTarget.value
        this.setState({
            fields
        })
        sessionStorage.setItem("Ship_Method", e.currentTarget.value);
    }

    handlecheckChange = (prop, key, e: any) => {
        let fields = this.state.fields;
        if (e.currentTarget.checked) {

            prop["RejectItem"] = "1"
        }
        else {
            prop["RejectItem"] = "0"
        }
      
        this.setState({
            fields            
        })

    }

    handleChange(e: any) {
        let fields = this.state.fields;
        fields["OrderId"] = e.currentTarget.value;
        fields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ fields })

    }


    onSubmit = (e: any) => {
        this.setState({ loading: true });
        this.setState({ holdLoading: false });
        this.setState({ rejectLoading: false });
        e.preventDefault();
        

        var OrderId = sessionStorage.getItem("Order_Id")!;
        var alias = sessionStorage.getItem("uid")!;
        var sales_Type = this.state.lblFields.sales_type;
        var Ship_Method = sessionStorage.getItem("Ship_Method")!;
        var ApprCmts = this.state.fields.comments;

        var Itemdetails = this.state.OrdDetail;

        let orderController = new OrderController(OrderId, alias);
        orderController.routeOrder(Itemdetails, sales_Type, Ship_Method, ApprCmts).then(response => {
            // this.setState({ loading: false });
            alert('Order has been Processed');
            this.props.history.push('order_approval_inbox');
        }).catch(error => {
            console.log("There was an error..., ", error);
        })

    }

    onRejectSubmit = (e: any) => {
        this.setState({ loading: false });
        this.setState({ holdLoading: false });
        this.setState({ rejectLoading: true });
        e.preventDefault();
        
        var OrderId = sessionStorage.getItem("Order_Id")!;
        var alias = sessionStorage.getItem("uid")!;
        var ApprCmts = this.state.fields.comments;

        let orderController = new OrderController(OrderId, alias);
        orderController.rejectOrder(ApprCmts).then(response => {
            this.setState({ rejectLoading: false });
            alert('Order has been Rejeted');
            this.props.history.push('order_approval_inbox');
        }).catch(error => {
            console.log("There was an error..., ", error);
        })
    }

    onHoldSubmit = (e: any) => {
        this.setState({ loading: false });
        this.setState({ holdLoading: true });
        this.setState({ rejectLoading: false });
        e.preventDefault();
        
        var OrderId = sessionStorage.getItem("Order_Id")!;

        var holddata = {
            orderId: this.props.location.state.Order_id
        }

        let depotOrdersController = new DepotOrdersController();
        depotOrdersController.holdOrder(holddata).then(response => {
            if (response.success) {
                alert('Order has been Hold');
                this.props.history.push('order_approval_inbox');
            }
        }).catch(error => {
            console.log("There was an error..., ", error);
        })
    }

    displaySalesRadioButtonList = () => {
        let billable = this.state.lblFields.Billable

        if (billable && this.state.lblFields.sales_type != "66 Ticket Bundling") {
            return this.displaySalesType();
        }

    }

    displayOrderItemsTable = () => {

        let billable = this.state.lblFields.Billable

        if (billable) {
            return this.orderItemsTableForBillableView();
        }
        else {
            return this.orderItemsTableForFreeandNTView();
        }

    }

    displaySalesType = () => {
      
      return <Row>

            <Col sm={12} >
                <Form.Group>
                    <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                        <Col sm={4} style={{ paddingRight: "0.50rem" }}><Form.Check type="radio" label="54 - Marked (non-royalty bearing)" name="MarkedClean" id="Marked54" onChange={this.handleOptionChange} checked = {this.state.optionfields.marked54} /></Col>
                        <Col sm={4} style={{ paddingRight: "0.50rem" }}><Form.Check type="radio" label="54 - Clean (non-royalty bearing)" name="MarkedClean" id="Clean54" onChange={this.handleOptionChange} checked = {this.state.optionfields.clean54} /></Col>
                        <Col sm={4}></Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                        <Col sm={4} style={{ paddingRight: "0.50rem" }}><Form.Check type="radio" label="56 - Marked (fully royalty bearing)" name="MarkedClean" id="Marked56" onChange={this.handleOptionChange} checked = {this.state.optionfields.marked56} /></Col>
                        <Col sm={4} style={{ paddingRight: "0.50rem" }}><Form.Check type="radio" label="56 - Clean (fully royalty bearing)" name="MarkedClean" id="Clean56" onChange={this.handleOptionChange} checked = {this.state.optionfields.clean56} /></Col>
                        <Col sm={4}></Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem" }}>
                        <Col sm={4} style={{ paddingRight: "0.50rem" }}><Form.Check type="radio" label="59 - Marked (publisher royalties only)" name="MarkedClean" id="Marked59" onChange={this.handleOptionChange} checked = {this.state.optionfields.marked59} /></Col>
                        <Col sm={4} style={{ paddingRight: "0.50rem" }}><Form.Check type="radio" label="59 - Clean (publisher royalties only)" name="MarkedClean" id="Clean59" onChange={this.handleOptionChange} checked = {this.state.optionfields.clean59} /></Col>
                        <Col sm={4}></Col>
                    </Row>
                </Form.Group>
            </Col>
        </Row>
    }

    orderItemsTableForBillableView = () => {

        const labelFont = {
            fontSize: "5px",
        };
        return <Row>
            <Col sm={12} className="small">
                <div className="scroll"  style={{ width: '100%', height: "auto" }}>
                    <Table hover id="myTable">
                        <thead>
                            <tr className="small" style={labelFont}>

                                {(this.state.isClean === 1) ? new Variables().thArrayBillableOrderEditClean.map((prop, key) => {
                                    return <th key={key}>{prop}</th>;
                                }) : new Variables().thArrayBillableOrderEditMarked.map((prop, key) => {
                                    return <th key={key}>{prop}</th>;
                                })}

                            </tr>
                        </thead>
                        <tbody >
                            {this.state.OrdDetail.map((prop, key) => {
                                return (
                                    <tr key={key} className="small" style={labelFont} >
                                        <td >{prop["Selection_Id"]} </td>
                                        <td> {prop["ReleaseDate"]} </td>
                                        <td> {prop["label_id"]} </td>
                                        <td> {prop["Artist"]} </td>
                                        <td> {prop["Title"]} </td>
                                        <td> {prop["Configuration"]} </td>
                                        <td> {prop["unitstoset"]} </td>

                                        <td> $ {prop["Price_Requested"]} </td>

                                        <td> $ {prop["Price_Approved"]} </td>

                                        <td>
                                            <FormControl style={{ height: 'auto', width: '64px' }} size="sm" id="qtyRec" maxLength = {5}   type="text" name="qtyRec" className="form-control input-sm" onChange={(e) => this.handletextChange(prop, key, e)} value={prop["Quantity_Approved"]} ></FormControl>
                                        </td>
                                        <td>
                                            <FormControl style={{ height: 'auto', width: '73px' }} size="sm" id="price" maxLength = {7} type="text" name="price" className="form-control input-sm" onChange={(e) => this.handletextChange(prop, key, e)} value={prop["Price_Approved"]} ></FormControl>
                                        </td>
                                        <td>
                                            <FormControl style={{ height: 'auto' }} size="sm" id="rejectItem" type="checkbox" name="rejectItem" className="form-control input-sm" value={prop["RejectItem"][key]} onChange={(e) => this.handlecheckChange(prop, key, e)}></FormControl>
                                        </td>
                                        {(this.state.isClean === 1) ?
                                            <td> {prop["cost"]} </td>
                                            : ""}

                                        {(this.state.isClean === 1) ?
                                            <td>                                                
                                                 {this.state.fields.discount[key]} 
                                             </td>
                                            : ""}

                                        {(this.state.isClean === 1) ?                                        
                                            <td>
                                                {this.state.fields.apprFlag[key] ? 
                                                <img src={flag} alt='Product discount requires EVP approval' title='Product discount requires EVP approval' /> : "" }
                                            </td>
                                            : ""}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>

    }

    displayShipingMethod = () => {
        if (this.props.location.state.goodsReq === 'V') {
            return <FormLabelField
                properties={{ label: "Shipment Method", id: "shipmentMethod", name: "shipmentMethod", className: "form-control", value: this.state.fields.shipmentMethod }}
            />
        }
        else {
            return <Form.Group>
                <Form.Label>Shipment Method</Form.Label>
                {this.state.OrdSum.map((item) => {
                    return <Form.Control as="select" id="shipmentMethod" name="shipmentMethod" value={item["Ship_Method"]} onChange={(e) => { this.handleSelectChange(item, e) }} className="form-control" >
                        <option selected value="standardGround">Standard Ground</option>
                        <option selected value="2nd Day Air">2nd Day Air</option>
                        <option selected value="Overnight">Overnight</option>
                        <option selected value="Pick-Up">Pick-Up</option>
                    </Form.Control>
                })}

            </Form.Group>
        }
    }

    orderItemsTableForFreeandNTView = () => {
        const labelFont = {
            fontSize: "5px",
        };
        return <Row>
            <Col sm={12} className="small">
                <div className="scroll"  style={{ width: '100%', height: "auto" }}>
                    <Table hover id="myTable">
                        <thead>
                            <tr className="small" style={labelFont}>
                                {new Variables().thArrayFreeGoodsOrderEdit.map((prop, key) => {
                                    return <th key={key}>{prop}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody >
                            {this.state.OrdDetail.map((prop, key) => {
                                return (
                                    <tr key={key} >
                                        <td >{prop["Selection_Id"]} </td>
                                        <td> {prop["ReleaseDate"]} </td>
                                        <td> {prop["label_id"]} </td>
                                        <td> {prop["Artist"]} </td>
                                        <td> {prop["Title"]} </td>
                                        <td> {prop["Configuration"]} </td>
                                        <td> {prop["unitstoset"]} </td>
                                        <td>
                                            <FormControl style={{ height: 'auto', width: '64px' }} size="sm" id="qtyRec" type="text" name="qtyRec" className="form-control input-sm" onChange={(e) => this.handletextChange(prop, key, e)} value={prop["Quantity_Approved"]} ></FormControl>
                                        </td>
                                        <td>
                                            <FormControl style={{ height: 'auto' }} size="sm" id="rejectItem" type="checkbox" name="rejectItem" className="form-control input-sm" value={prop["RejectItem"]} onChange={(e) => this.handlecheckChange(prop, key, e)}></FormControl>
                                        </td>
                                        <td> {prop["ReqQty"]} </td>
                                        <td> {prop["DeptQty"]} </td>
                                        <td> {prop["DJCQty"]}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>

    }
    orderItemsTableForEdit = () => {

        return <Row>
            <Col sm={12}>
                <div style={{ width: '100%', height: "auto" }}>
                    <Table hover>
                        <thead>
                            <tr>
                                {new Variables().thArrayFreeGoodsOrderEdit.map((prop, key) => {
                                    return <th key={key}>{prop}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.OrdDetail.map((prop, key) => {
                                return (
                                    <tr key={key}>

                                        {this.formControl(prop, key)};

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>

    }
    orderItemsTableForEditButton = () => {
        const { loading } = this.state;
        const { holdLoading } = this.state;
        const { rejectLoading } = this.state;
        if (this.props.location.state.goodsReq === 'V') {
            return <></>
        }
        else {
            return <div className="form-group mt-4 mb-0 text-center">
                <Row>
                    <Col sm={4} style={{ textAlign: 'left' }}>
                        <Form.Group>
                            <Form.Label>Comments (Order Changes/Depot)</Form.Label>
                            <Form.Control as="textarea" rows={3} id="comments" name="comments" className="form-control" value={this.state.fields.comments} onChange={this.handleChange} ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={8} style={{ alignSelf: "center" }}>
                        <Button onClick={this.onSubmit} disabled={loading} style={{ marginRight: "5px" }}>
                            {loading && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                />
                            )}
                            {loading && <span style={{ color: "#3472F7" }} >wait...</span>}
                            {!loading && <span>Process Order</span>}
                        </Button>
                        <Button disabled={holdLoading} style={{ marginRight: "5px" }} onClick={this.onHoldSubmit} >
                            {holdLoading && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {holdLoading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!holdLoading && <span>Hold Order</span>}
                        </Button>
                        <Button disabled={rejectLoading} style={{ marginRight: "5px" }} onClick={this.onRejectSubmit}>
                            {rejectLoading && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {rejectLoading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!rejectLoading && <span>Reject Order</span>}
                        </Button>
                    </Col>
                </Row>
            </div>
        }
    }

    formControl = (prop, key) => {
        return <td> {prop["selection_id"]}</td>
    }

    render() {
        if (this.state.loggedIn === false) {
            return <Redirect to="/" />
        }
        const labelFont = {
            fontSize: "5px",
        };
        return (
        <div className="content">
             <FormModalSpinner show={this.state.modal.modalSpinnerShow }>   </FormModalSpinner>
            <Container fluid>
                <Row>
                    <Col sm={12} className="small">
                        <Form >
                            <Card border="light" style={{ width: '100%' }}>
                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                    <div style={{ width: '100%', borderBottom: '1px solid', borderColor: "rgba(0, 0, 0, 0.125)" }}>
                                        <Row>
                                            <Col sm={6}>

                                                <Form.Group >
                                                    <Form.Label>Next Step</Form.Label> <br />
                                                    {this.state.OrdSum.map((item) => {
                                                        return <Form.Label><b>{item["Statusdesc"]}</b></Form.Label>
                                                    })}
                                                (
                                                    {this.state.NextAppr.map((item) => {
                                                        return <Form.Label><b>{item["Approver"]}</b></Form.Label>
                                                    })}
                                                )
                                                </Form.Group>
                                            </Col>
                                            <Col sm={2}></Col>
                                            <Col sm={4}>
                                                <Form.Label>Legacy Order</Form.Label> <br />
                                                {this.state.OrdSum.map((item) => {
                                                    return <Form.Label><b>{item["LegacyOrder"]}</b></Form.Label>
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} className="small">
                                                <Form.Label>Approvers</Form.Label> <br />
                                                    {this.state.ApprovalHis.map((item) => {
                                                        return <tr><td>{item["ApproverDatetime"]}</td></tr>
                                                    })}
                                            </Col>
                                            <Col sm={4}></Col>
                                            <Col sm={4}>
                                                <Form.Label>Depot</Form.Label> <br />
                                                {this.state.DepotHis.map((item) => {
                                                    return <Form.Label><b>{item["Notes"]}</b><br /></Form.Label>
                                                })}
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row style={{ textAlign: 'center' }}>
                                        <Col sm={12}>
                                            <div className="header"><h4 className="title">{this.state.Title}</h4><p className="category"></p></div>

                                            <h4 className="align-center" style={{ color: 'maroon' }}>{this.state.SalesType}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div id="navbar">
                                                <Navbar expand="lg" >
                                                    <Navbar.Brand>Request Details</Navbar.Brand>
                                                    <Nav className="ml-auto">
                                                    </Nav>
                                                </Navbar>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>

                                            <Form.Label>Request Date</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["Request_Date"]}</b></Form.Label>
                                            })}

                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>Customer P.O.</Form.Label> <br />
                                            <Form.Label><b>{this.props.location.state.Order_id}</b></Form.Label>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>Sales Type</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["sales_type"]}</b></Form.Label>
                                            })}

                                        </Col>
                                        <Col sm={3}>
                                            {this.displayShipingMethod()}
                                        </Col>
                                    </Row>
                                    <Row>                                    
                                        {this.state.lblFields.Billable 
                                        ?                                      
                                        <Col sm={3}>
                                            <Form.Label>FREIGHT BILLING INFO</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["freight_billing_info"]}</b></Form.Label>
                                            })}
                                        </Col> 
                                        :
                                        ''
                                     }    
                                         
                                    </Row>
                                    <div style={{ width: '100%', borderBottom: '1px solid', borderColor: "rgba(0, 0, 0, 0.125)" }}>
                                        <Row>
                                            <Col sm={12}>
                                                <div id="navbar">
                                                    <Navbar expand="lg">
                                                        <Navbar.Brand>Order Details</Navbar.Brand>
                                                        <Nav className="ml-auto">
                                                        </Nav>
                                                    </Navbar>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>Order By</Form.Label> <br />
                                                        {this.state.OrdSum.map((item) => {
                                                            return <Form.Label><b>{item["Alias_name"]}</b></Form.Label>
                                                        })}
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>Customer Number</Form.Label> <br />
                                                        {this.state.OrdSum.map((item) => {
                                                            return <Form.Label><b>{item["CustomerNumber"]}</b></Form.Label>
                                                        })}

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>Account No</Form.Label> <br />
                                                        {this.state.OrdSum.map((item) => {
                                                            return <Form.Label><b>{item["account_no"]}</b></Form.Label>
                                                        })}
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>Department</Form.Label> <br />
                                                        {this.state.OrdSum.map((item) => {
                                                            return <Form.Label><b>{item["Department"]}</b></Form.Label>
                                                        })}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <Form.Label>Business Unit</Form.Label> <br />
                                                        {this.state.OrdSum.map((item) => {
                                                            return <Form.Label><b>{item["BusinessUnit"]}</b></Form.Label>
                                                        })}
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>Bill To:</Form.Label>
                                                        {this.loadBillToFrightInfo()}
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup >
                                                            <FormLabel>{'Ship To'}</FormLabel> <br />
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b>{item["ship_Name"]}</b></Form.Label>
                                                            })}
                                                            <br />
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b>{item["ship_ShipToName"]}</b></Form.Label>
                                                            })}
                                                            <br />
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b>{item["ship_AddressLineOne"]}</b></Form.Label>
                                                            })}
                                                            <br />


                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b>{item["ship_City"]}</b></Form.Label>
                                                            })}
                                                            ,
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b>{item["ship_State"]}</b></Form.Label>
                                                            })}
                                                            ,
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b>{item["ship_Zip"]}</b></Form.Label>
                                                            })}
                                                            <br />
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b><u>{item["ship_Country"]}</u></b></Form.Label>
                                                            })}
                                                            <br />
                                                            {this.state.OrdSum.map((item) => {
                                                                return <Form.Label><b><u>{item["ship_Phone"]}</u></b></Form.Label>
                                                            })}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col sm={3}>
                                            <Form.Label>Special Instructions</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["SpecialInstructions"]}</b></Form.Label>
                                            })}
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>Order Id</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["Order_Id"]}</b></Form.Label>
                                            })}
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>Label</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["Label"]}</b></Form.Label>
                                            })}

                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>Requestor Reason</Form.Label> <br />
                                            {this.state.OrdSum.map((item) => {
                                                return <Form.Label><b>{item["Reason"]}</b></Form.Label>
                                            })}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div id="navbar">
                                                <Navbar expand="lg">
                                                    <Navbar.Brand>Ordered Items</Navbar.Brand>
                                                    <Nav className="ml-auto">
                                                    </Nav>
                                                </Navbar>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div style={{ width: '100%', borderBottom: '1px solid', borderColor: "rgba(0, 0, 0, 0.125)" }}>
                                        {this.displayOrderItemsTable()}
                                    </div>

                                    {this.displaySalesRadioButtonList()}

                                    {this.orderItemsTableForEditButton()}
                                    <Row>
                                        <Col sm={12}>
                                            <div id="navbar">
                                                <Navbar expand="lg">
                                                    <Navbar.Brand>Approver Details</Navbar.Brand>
                                                    <Nav className="ml-auto">
                                                    </Nav>
                                                </Navbar>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Table hover>
                                                    <thead>
                                                        <tr>
                                                            {new Variables().thArrayFreeGoodsOrderApprovers.map((prop, key) => {
                                                                return <th key={key}>{prop}</th>;
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.ApprovalHis.map((prop, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td> {prop["ApproverType"]} </td>
                                                                    <td> {prop["Approver"]} </td>
                                                                    <td> {prop["ApprovalDateTime"]} </td>
                                                                    <td> {prop["Notes"]} </td>
                                                                </tr>
                                                            );
                                                        })}

                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
           
        </div>
        );
    }
}
