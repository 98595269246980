import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class UserViewController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    userInfo : any;
    dateTimeFormat : DateTimeFormat;
    constructor(){        
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();        
    }

    getUserList(val : string,searchType : number){
        var url = this.variables.Url + this.variables.Controller.userView + this.variables.userViewService.getUserList;
        var data = {
            "val": val,
            "searchBy" : searchType   
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonUserList : Array<{"alias": string; "userType": string;"processName":string; "name":string;"email":string;"label":string;
                                "department": string;"password": string;"customerNumber": string;"businessUnit": string;
                                "executiveUser":string;"superUser":string }> = [];
            if(response.success){
                response.recordset.forEach(list);

                function list(item: {  alias:string;Usertype_id:string;Process_name:string;Name:string;Email:string;RecordLabel_id:string;
                    Department:string;Password:string;CustomerNumber:string;BusinessUnit:any;ExecutiveUser:string;SuperUser:string }, _index: number){
                        jsonUserList.push({"alias": item.alias, "userType": item.Usertype_id,"processName":item.Process_name,
                "name":item.Name,"email":item.Email,"label":item.RecordLabel_id,"department":item.Department,"password":item.Password,  
                "customerNumber":item.CustomerNumber,"businessUnit":item.BusinessUnit,"executiveUser":item.ExecutiveUser,"superUser":item.SuperUser })
                }
                return JSON.parse(JSON.stringify(jsonUserList).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return JSON.parse(JSON.stringify(jsonUserList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });        
    }


    getUserListByProcessName(val : string){
        var url = this.variables.Url + this.variables.Controller.userView + this.variables.userViewService.getUserListByProcessName;
        var data = {
            "processname": val           
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonUserList : Array<{"alias": string; "userType": string;"name":string;"password": string }> = [];
                                
            if(response.success){
                response.recordset.forEach(list);

                function list(item: {  Alias:string;user_type:string;Name:string;password:string}, _index: number){
                        jsonUserList.push({"alias": item.Alias, "userType": item.user_type,"name":item.Name,"password":item.password })
                }
                return JSON.parse(JSON.stringify(jsonUserList).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return JSON.parse(JSON.stringify(jsonUserList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });        
    }

}