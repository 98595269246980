import { isPropertyAccessOrQualifiedName } from "typescript";
import {ServiceProxy} from "../Common/ServiceProxy";
import {Variables} from "../Variables/Variables"

export class SysopController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    insertDept(label : string, dept : string, customerNumber : string, businessUnit : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.insertDept;
        var data = {
            label : label,
            dept : dept,
            customerNumber : customerNumber,
            businessUnit : businessUnit
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert dept...," + error);
        });
    }

    updateDept(label : string, dept : string, customerNumber : string, businessUnit : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.updateDept;
        var data = {
            label : label,
            dept : dept,
            customerNumber : customerNumber,
            businessUnit : businessUnit
        }
        return this.serviceProxy.put(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in update dept...," + error);
        });
    }

    getAllProcessForLabel(label: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getAllProcessForLabel;
        var data = {
            recordLabelId : label
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonProcess : Array<{"assignedLabelId": string, "department": string, "processName": string, "maxApprovers": string, "default": string, "overnight": string, "clean": string, "billable": string, "NTBillable": string, "superUser": string, "crossLabel": string, "allLabels": string, "exceptions": string}> = [];
                if(response.success){
                    response.recordset.forEach(process);
                    function process(item: { Assigned_Label_ID: any; Department: any, Process_Name: any; Max_Approvers: any; Is_Default: any; Is_Overnight: any; Is_Clean: any; Is_Billable: any; is_NTBillable: any, Is_Super_User: any, Is_Cross_Label:any, is_all_labels: any, is_exceptions: any }, index: number){
                        jsonProcess.push({"assignedLabelId": item.Assigned_Label_ID, "department": item.Department, "processName": item.Process_Name, "maxApprovers": item.Max_Approvers, "default": item.Is_Default ? "Yes" : "No", "overnight": item.Is_Overnight ? "Yes" : "No", "clean": item.Is_Clean ? "Yes" : "No", "billable": item.Is_Billable ? "Yes" : "No", "NTBillable": item.is_NTBillable ? "Yes" : "No", "superUser": item.Is_Super_User ? "Yes" : "No", "crossLabel": item.Is_Cross_Label, "allLabels": item.is_all_labels, "exceptions": item.is_exceptions})
                    }
                    return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive process for given label...," + error);
        });
    }

    getApprovalProcess(processName: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getApprovalProcess;
        var data = {
            processName: processName
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
        }).catch(error => {
            console.error("Error in retrive approval level for given max approvers...," + error);
        });
    }

    getProcessByLabelDept(labelId: string, department: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getAllProcessForLabel;
        var data = {
            recordLabelId : labelId,
            department : department
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonProcess : Array<{"assignedLabelId": string, "department": string, "processName": string, "maxApprovers": string, "default": string, "overnight": string, "clean": string, "billable": string, "NTBillable": string, "superUser": string}> = [];
                if(response.success){
                    response.recordset.forEach(process);
                    function process(item: { Assigned_Label_ID: any; Department: any, Process_Name: any; Max_Approvers: any; Is_Default: any; Is_Overnight: any; Is_Clean: any; Is_Billable: any; is_NTBillable: any, Is_Super_User: any }, index: number){
                        jsonProcess.push({"assignedLabelId": item.Assigned_Label_ID, "department": item.Department, "processName": item.Process_Name, "maxApprovers": item.Max_Approvers, "default": item.Is_Default ? "Yes" : "No", "overnight": item.Is_Overnight ? "Yes" : "No", "clean": item.Is_Clean ? "Yes" : "No", "billable": item.Is_Billable ? "Yes" : "No", "NTBillable": item.is_NTBillable ? "Yes" : "No", "superUser": item.Is_Super_User ? "Yes" : "No"})
                    }
                    return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive process for given label...," + error);
        });
    }

    countProcessByOrderType(labelId: string, department: string, assignedLabelId: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.countProcessByOrderType;
        var data = {
            labelId : labelId,
            assignedLabelId:assignedLabelId,
            department : department
        }
        return this.serviceProxy.get(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in retrive process for given label and dept...," + error);
        });
    }

    insertLabel(recordLabelId : string, recordLabelName : string, ownerLabel : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.insertLabel;
        var data = {
            recordLabelId : recordLabelId,
            recordLabelName : recordLabelName,
            ownerLabel : ownerLabel,
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert Label...," + error);
        });
    }

    updateLabel(recordLabelId : string, recordLabelName : string, ownerLabel : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.updateLabel;
        var data = {
            recordLabelId : recordLabelId,
            recordLabelName : recordLabelName,
            ownerLabel : ownerLabel,
        }
        return this.serviceProxy.put(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in update Label...," + error);
        });
    }

    getAllApprovers(){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getAllApprovers;
        var data = {}
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonApprovers : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(approvers);
                    function approvers(item: { Alias: any; Name: any; UserType_Id: any; Department: any; RecordLabel_Id: any}){
                        jsonApprovers.push({"id": item.Alias, "name": item.Name+"..."+item.UserType_Id+"..."+item.Department+"..."+item.RecordLabel_Id})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovers).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApprovers).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive process for given label...," + error);
        });
    }

    getApprovalLevel(maxApprovers: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getApprovalLevel;
        var data = {
            maxApprovers: maxApprovers
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonApprovalLevel : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(approvalLevel);
                    function approvalLevel(item: { Usertype_Id: any; TypeDesc: any}){
                        jsonApprovalLevel.push({"id": item.Usertype_Id, "name": item.TypeDesc})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovalLevel).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApprovalLevel).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive approval level for given max approvers...," + error);
        });
    }

    getApprovalTable(processName: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getApprovalTable;
        var data = {
            processName: processName
        }
        return this.serviceProxy.post(url, data).then(response =>{
            var jsonApprovalTable : Array<{"alias":string, "name": string, "label": string, "labelId": string}> = [];
                if(response.success){
                    response.recordset.forEach(approvalTable);
                    function approvalTable(item: { Alias: any; Name: any; TypeDesc: any, User_Type: any}){
                        jsonApprovalTable.push({"alias": item.Alias, "name": item.Name, "label": item.TypeDesc, "labelId": item.User_Type})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovalTable).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApprovalTable).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive approval level for given max approvers...," + error);
        });
    }

    getAllProcessForApproverLabel(username: string, recordLabelId : string, recordLabelName: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getAllProcessForApproverLabel;
        var data = {
            username: username,
            recordLabelId: recordLabelId
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonProcessTable : Array<{"id": string, "username": string, "typedesc": string, "name": string, "label": string, "department": string}> = [];
                if(response.success){
                    response.recordset.forEach(processTable);
                    function processTable(item: { Process_Name: any; TypeDesc: any; Name: any; Department:any}){
                        jsonProcessTable.push({"id": item.Process_Name, "username": username, "typedesc": item.TypeDesc, "name": item.Name, "label": recordLabelName, "department": item.Department})
                    }
                    return JSON.parse(JSON.stringify(jsonProcessTable).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonProcessTable).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive details...," + error);
        });
    }

    getDefaultProcess(labelId : string, department : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getDefaultProcess;
        var data = {
            labelId: labelId,
            department: department
        }
        return this.serviceProxy.get(url, data).then(response =>{
            return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
        }).catch(error => {
            console.error("Error in retrive details...," + error);
        });
    }

    insertApprovalProcess(approvalProcessData : any){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.insertApprovalProcess;
        var data = {
            processName: approvalProcessData.processName,
            labelId: approvalProcessData.labelId,
            assignedLabelId: approvalProcessData.assignedLabelId,
            maxApprovers: approvalProcessData.maxApprovers,
            department: approvalProcessData.department,
            processDescription: approvalProcessData.processDescription,
            isDefault: approvalProcessData.isDefault,
            isAllLabels: approvalProcessData.isAllLabels,
            isExceptions: approvalProcessData.isExceptions,
            isOvernight: approvalProcessData.isOvernight,
            isClean: approvalProcessData.isClean,
            isCrossLabel: approvalProcessData.isCrossLabel,
            isOnePerLabel: approvalProcessData.isOnePerLabel,
            isActive: approvalProcessData.isActive,
            isSuperUser: approvalProcessData.isSuperUser,
            isCatalog: approvalProcessData.isCatalog,
            isBillable: approvalProcessData.isBillable,
            isNTBillable: approvalProcessData.isNTBillable
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert Approval Process...," + error);
        });
    }

    addFullLengthCleanApproverToProcess(){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.addFullLengthCleanApproverToProcess;
        var data = {}
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert dept...," + error);
        }); 
    }

    insertCrossLabels(crossLabelData : any){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.insertCrossLabels;
        var data = {
            processName: crossLabelData.processName,
            labelId: crossLabelData.labelId,
            ownerLabel: crossLabelData.ownerLabel,
            isOn: crossLabelData.isOn,
            defaultDept: crossLabelData.defaultDept,
            isBillable: crossLabelData.isBillable,
            isNonBillableTransfer: crossLabelData.isNonBillableTransfer
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert cross labels...," + error);
        });
    }

    insertLabelExceptions(LabelExceptionData : any){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.insertLabelExceptions;
        var data = {
            processName: LabelExceptionData.processName,
            labelId: LabelExceptionData.labelId,
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert label Exceptions...," + error);
        });
    }

    //Approval process admin page

    getApprovalProcessDetails(username : string, recordLabelId: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getApprovalProcessDetails;
        var data = {
            username:username,
            recordLabelId:recordLabelId
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonApprovalProcessDetails : Array<{"processName": string, "alias": string, "typedesc": string, "name": string, "labelName": string, "department": string}> = [];
                if(response.success){
                    response.recordset.forEach(approvalProcessDetails);
                    function approvalProcessDetails(item: { process_name: any; alias: any; typedesc:any; name:any; labelname:any; Department:any }){
                        jsonApprovalProcessDetails.push({"processName": item.process_name, "alias": item.alias, "typedesc": item.typedesc, "name": item.name, "labelName": item.labelname, "department": item.Department})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovalProcessDetails).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApprovalProcessDetails).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrieve details...," + error);
        });
    }

    getApproverNames(){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getApproverNames;
        var data = {}
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonApprovers : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(approvers);
                    function approvers(item: { alias: any; name: any }){
                        jsonApprovers.push({"id": item.alias, "name": item.name})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovers).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApprovers).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrieve details...," + error);
        });
    }

    replaceApprover(currentApprover : string, newApprover : string, recordLabelId : string, updatedBy : string, currentIP: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.replaceApprover;
        var data = {
            currentApprover:currentApprover,
            newApprover:newApprover,
            recordLabelId:recordLabelId,
            updatedBy:updatedBy,
            currentIP:currentIP
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response;
        }).catch(error => {
            console.error("Error in replace approver...," + error);
        });
    }

    addApprover(currentApprover : string, newApprover : string, recordLabelId : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.addApprover;
        var data = {
            currentApprover:currentApprover,
            newApprover:newApprover,
            recordLabelId:recordLabelId
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in add approver...," + error);
        });
    }

    checkAlternateApprovers(currentApprover : string, recordLabelId : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.checkAlternateApprovers;
        var data = {
            currentApprover:currentApprover,
            recordLabelId:recordLabelId,
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonCheckAlternateApprovers : Array<{"processName": string, "type": string}> = [];
                if(response.success){
                    response.recordset.forEach(checkAlternateApprovers);
                    function checkAlternateApprovers(item: { process_name: any; typedesc: any }){
                        jsonCheckAlternateApprovers.push({"processName": item.process_name, "type": item.typedesc})
                    }
                    return JSON.parse(JSON.stringify(jsonCheckAlternateApprovers).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCheckAlternateApprovers).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in check alternate approver...," + error);
        });
    }

    deleteApprover(currentApprover : string, recordLabelId : string, updatedBy : string, currentIP: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.deleteApprover;
        var data = {
            currentApprover:currentApprover,
            recordLabelId:recordLabelId,
            updatedBy:updatedBy,
            currentIP:currentIP
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in delete approver...," + error);
        });
    }

    getLabelExceptions(processName: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getLabelExceptions;
        var data = {
            processName: processName
        }
        return this.serviceProxy.post(url, data).then(response =>{
            var jsonLabelExceptions : Array<{"id": string}> = [];
                if(response.success){
                    response.recordset.forEach(labelExceptions);
                    function labelExceptions(item: { Label_ID: any }){
                        jsonLabelExceptions.push({"id": item.Label_ID})
                    }
                    return JSON.parse(JSON.stringify(jsonLabelExceptions).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonLabelExceptions).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrieve details...," + error);
        });
    }

    getCrossLabels(processName : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getCrossLabels;
        var data = {
            processName: processName
        }
        return this.serviceProxy.post(url, data).then(response =>{
            var jsonCrossLabels : Array<{"id": string}> = [];
                if(response.success){
                    response.recordset.forEach(crossLabels);
                    function crossLabels(item: {Label_ID: any }){
                        jsonCrossLabels.push({"id": item.Label_ID})
                    }
                    return JSON.parse(JSON.stringify(jsonCrossLabels).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCrossLabels).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrieve details...," + error);
        });
    }

    updateMaxApprovers(processName : string, maxApprovers : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.updateMaxApprovers;
        var data = {
            processName : processName,
            maxApprovers : maxApprovers
        }
        return this.serviceProxy.put(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in update max approvers...,", error);
        });
    }

    deleteCrossLabels(processName : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.deleteCrossLabels;
        var data = {
            processName : processName
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in delete cross labels...,", error);
        });
    }

    deleteLabelExceptions(processName : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.deleteLabelExceptions;
        var data = {
            processName : processName
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in delete label exceptions...,", error);
        });
    }

    getAllProcessByAlias(alias: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getAllProcessByAlias;
        var data = {
            alias : alias
        }
        return this.serviceProxy.get(url, data).then(response =>{
            var jsonProcess : Array<{"alias": string, "processName": string, "default": string, "userType": string}> = [];
                if(response.success){
                    response.recordset.forEach(process);
                    function process(item: { Alias: any; Process_Name: any, Is_Default: any; User_Type: any}){
                        jsonProcess.push({"alias": item.Alias, "processName": item.Process_Name, "default": item.Is_Default ? "Yes" : "No", "userType": item.User_Type})
                    }
                    return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error("Error in retrive process for given alias...," + error);
        });
    }

    deleteApproverByProcess(processName : string, alias : string, userType: string, crossLabel: number){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.deleteApproverByProcess;
        var data = {
            processName : processName,
            alias : alias,
            userType : userType,
            crossLabel : crossLabel
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in delete approver...,", error);
        });
    }

    checkIsDefault(processName : string, alias : string, userType: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.checkIsDefault;
        var data = {
            processName : processName,
            alias : alias,
            userType : userType
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in check is default...,", error);
        });
    }

    getProcessLookupCounter(processName: string, alias: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getProcessLookupCounter;
        var data = {
            processName : processName,
            alias : alias
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in retrive details...," + error);
        });
    }

    addApproverForExport(processName : string, alias : string, userType : string, counter : number){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.addApproverForExport;
        var data = {
            processName : processName,
            alias : alias,
            userType : userType,
            counter : counter
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert dept...," + error);
        });
    }

    checkIsDefaultProcessLookup(processName : string, alias : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.checkIsDefaultProcessLookup;
        var data = {
            processName : processName,
            alias : alias
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in check is default...,", error);
        });
    }

    addApproverNonExport(processName : string, alias : string, userType : string, isDefault: number, counter : number){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.addApproverNonExport;
        var data = {
            processName : processName,
            alias : alias,
            userType : userType,
            default: isDefault,
            counter : counter
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in insert dept...," + error);
        });
    }

    getNonExportDefaultProcessCount(alias: string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.getNonExportDefaultProcessCount;
        var data = {
            alias : alias
        }
        return this.serviceProxy.get(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in retrive details...," + error);
        });
    }

    updatePermissions(alias : string, otherLabels : string, exceptions : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.updatePermissions;
        var data = {
            alias: alias,
            otherLabels: otherLabels,
            exceptions: exceptions
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in update permissions...," + error);
        });
    }

    checkAlternateApprover(processName : string, userType : string){
        var url = this.variables.Url + this.variables.Controller.sysop + this.variables.sysopService.checkAlternateApprover;
        var data = {
            processName : processName,
            userType : userType
        }
        return this.serviceProxy.post(url, data).then(response =>{
            return response
        }).catch(error => {
            console.error("Error in check alternate approver...,", error);
        });
    }
}