import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class EditUserController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getUser(alias : string){
        var url = this.variables.Url + this.variables.Controller.editUser + this.variables.editUserService.getUser;
        var data = {
            "username":alias
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){
                return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        }); 
    }

    
    getUserType(){
        var url = this.variables.Url + this.variables.Controller.editUser + this.variables.editUserService.getUserType;
        var jsonUserType : Array<{"id" : string, "name" : string}> = [];
        return this.serviceProxy.get(url,{})
            .then(response => {
                if(response.success){
                    response.recordset.forEach(userType);
                    function userType(item: { Usertype_Id: any; TypeDesc: any; }, index: number){
                        jsonUserType.push({"id":item.Usertype_Id, "name": item.TypeDesc})
                    }
                    return JSON.parse(JSON.stringify(jsonUserType).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonUserType).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            }); 
    }

    getProcessName(label : string){
        var url = this.variables.Url + this.variables.Controller.editUser + this.variables.editUserService.getProcessName;
        var data = {
            "recordLabelId": label
        }
        var jsonProcessName : Array<{"id" : string, "name" : string }> = [];
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    response.recordset.forEach(process);
                    function process(item: { Process_Name: string; }, index: number){
                        jsonProcessName.push({"id":item.Process_Name, "name": item.Process_Name})
                    }
                    return JSON.parse(JSON.stringify(jsonProcessName).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonProcessName).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            }); 
    }

    UpdateUser(userInfo : any){
        var url : string = this.variables.Url + this.variables.Controller.editUser + this.variables.editUserService.updateUser;
        var data : any = userInfo;
        return this.serviceProxy.put(url,data)
        .then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }
// -----
getDeptProcessInfo(data){
    var url : string = this.variables.Url + this.variables.Controller.register + this.variables.registerService.getDeptProcessInfo;
    var data : any = {
        recordLabelId : data.recordLabelId,
        department : data.department
    };
    return this.serviceProxy.get(url, data)
    .then(response => {
        
        if(response.success)
        {
            var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
        
        }
        return res
    }).catch(error => {
        return error;
    });
}
///=------
    UpdateSysopUser(userInfo : any){
        var url : string = this.variables.Url + this.variables.Controller.editUser + this.variables.editUserService.updateSysopUser;
        var data : any = userInfo;
        return this.serviceProxy.put(url,data)
        .then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }

    DeleteUser(alias : string){
        var url : string = this.variables.Url + this.variables.Controller.editUser + this.variables.editUserService.deleteUser;
        var data = {
            alias : alias            
        }
        return this.serviceProxy.put(url,data)
        .then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }
    
}

