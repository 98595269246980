import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class OrderViewController {

    serviceProxy: ServiceProxy;
    variables: Variables;
    response : any;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }

    getListOrderView(filter : string,filter_type : string,start_date : string,end_date : string){
        var url = this.variables.Url + this.variables.Controller.orderView + this.variables.orderViewService.getListOrderView;
        var data = {
            "filter": filter,
            "filterType" : filter_type,
            "startDate":start_date,
            "endDate" : end_date         
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonOrderViewList : Array<{ "Order_Id": string; "Billable": boolean;"Material":boolean;"Overnight":boolean; "Requestor":string;"Label_Id":string;
            "Super_Label_Id": string;"Status": string;"Requestor_Weight":string;"Ship_Method":string;"Created":string}> = [];
            if(response.success){                   
                response.recordset.forEach(list);                   
                function list(item: { Order_Id:string;Billable:boolean;Material:boolean;Overnight:boolean;Requestor:string;Label_Id:string;Super_Label_Id:string;Status:string;Requestor_Weight:string;Ship_Method:string;Created:string
                    Date_Uploaded:string;StatusDesc:string }, _index: number){
                        jsonOrderViewList.push({"Order_Id": item.Order_Id,"Billable":item.Billable,"Material":item.Material,"Overnight":item.Overnight,
                    "Requestor":item.Requestor,"Label_Id":item.Label_Id,"Super_Label_Id":item.Super_Label_Id,"Status":item.Status,"Requestor_Weight":item.Requestor_Weight,"Ship_Method":item.Ship_Method,"Created":item.Created})
                }
                return JSON.parse(JSON.stringify(jsonOrderViewList).replace(/"\s+|\s+"/g, '"'));
            }
            else {                                  
                this.response.msg = response.message.toString();
                this.response.success = false;
                return JSON.parse(JSON.stringify(jsonOrderViewList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });        
    }

}