import React, { Component } from "react";
import { Container, Card, Row, Col, Table, Form, Alert, Button, Navbar, Nav } from "react-bootstrap";
import { ISearchHistoryParameter, Istate } from "../../../Model/SearchHistory"; 
import { SearchHistoryController } from "../../../Controllers/SearchHistoryController"
import { format } from 'date-fns'
import { Variables } from '../../../Variables/Variables';
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { NavLink, withRouter } from 'react-router-dom'
import '../../../assets/css/Style.css'
import { FormCheckBox } from "../../CustomComponents/FormCheckBox"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css'

export class SearchHistory extends Component<ISearchHistoryParameter, Istate> {
    constructor(props: ISearchHistoryParameter) {
        super(props)
        this.state = {
            fields: { find:'',searchType:'',statusBy:'' },
            selectfields: { searchBy: '',statusBy:'' },
            checkfields: { multipleSelect: false },
            errors: {startDate:'',endDate:''},      
            errMsg: '',
            loading: false,
            loadingReset:false,      
            startDate: "",   
            endDate:"",
            hidediv:true,
            displayGrid:[],        
            checkedItems: new Map(),        
            isDateEnabled:false,
            isListAvl:false,
            isListAvlMsg: "List is Empty...Please search for the needs",
            orderList:""

        }
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);   
       this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this); 

    }


    componentDidMount() {
        
        var today    =new Date();      
        var startDate=new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));
        this.setState({ startDate: startDate});     
        this.setState({ endDate: today});
        let selectfields = this.state.selectfields;
        selectfields["statusBy"] = "17";
        this.setState({selectfields})

        sessionStorage.setItem('ViewOrderStatus', '0' )
        sessionStorage.setItem('ViewOrder','')
        let SH
        SH = sessionStorage.getItem("SearchHistoryView")  
       sessionStorage.setItem('SearchHistoryView', '0' )   
       
       var temparray = sessionStorage.getItem('SearchHistory') || ""

       
       if (SH ==='1' && temparray != "" )
       {         
        this.setState({isListAvl:true})
        this.setState({ 
         displayGrid: JSON.parse(sessionStorage.getItem('SearchHistory') || "") 
        });

        this.setState({ startDate: ""});     
        this.setState({ endDate: ""});

      }
      else
      {
        this.setState({isListAvl:false})
      }

    }
    
    
    handleChangeStartDate = date => {
    var today    =new Date();
        let errors = this.state.errors;  
        if(date != "")      
        if(today < date )
        errors["startDate"] = "Date is greater than today's date";        
        else
        errors["startDate"] = "";       
        
        this.setState({startDate: date,errors});
    };

    handleChangeEndDate = date => {
        var today    =new Date();
        let errors = this.state.errors;       
        if(today < date )
            errors["endDate"] = "Date is greater than today's date";     
            else
            errors["endDate"] = "";      
        
        this.setState({endDate: date, errors});
    };
  
    handleTextChange (e:any) {        
        let fields = this.state.fields;
        fields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({
            fields
        })
    }
  
    handleSelectChange (e:any) {       
        let selectfields = this.state.selectfields;
        if (e.currentTarget.id == "searchBy")
        {
        selectfields.searchBy= e.currentTarget.value;   
            if (e.currentTarget.value === "Order_id") 
            {            
                this.setState({isDateEnabled : true})            
            }
            else
            {
                this.setState({isDateEnabled : false})           
            }    
        }  
        else if (e.currentTarget.id == "statusBy")
        {
        selectfields.statusBy= e.currentTarget.value; 
            if (e.currentTarget.value === "2") 
            {            
                this.setState({isDateEnabled : true})           
            }
            else
            {
                this.setState({isDateEnabled : false})
            }   
        }
        this.setState({
        selectfields
        })
    } 

    onSubmit =(e:any)=>{
        try{
        e.preventDefault();
        if (this.ValidateDate()) 
        { 
            this.setState({ loading: true });
            this.setState({isListAvl:false})
            var sD = this.state.startDate;
            var curr_date = sD.getDate();
            var curr_month = sD.getMonth() + 1; //Months are zero based
            var curr_year = sD.getFullYear();
            var startDate = "";
            if(this.state.selectfields.searchBy != "Order_id")
               startDate = curr_month + "/" + curr_date + "/" + curr_year;

            var eD = this.state.endDate;
            var curr_date = eD.getDate();
            var curr_month = eD.getMonth() + 1; //Months are zero based
            var curr_year = eD.getFullYear();
            var endDate = "";
            if(this.state.selectfields.searchBy != "Order_id")
              endDate = curr_month + "/" + curr_date + "/" + curr_year;

            let searchHistoryController = new SearchHistoryController();
            var data = {
              filter: this.state.fields.find,
              filterType : this.state.selectfields.searchBy,
              uId : sessionStorage.getItem("uid")!,
              startDate:startDate,
              endDate : endDate           
          }        
            
            searchHistoryController.getSearchHistory(data).then(jsonSearchHistoryList =>{
              this.setState({                           
                displayGrid : JSON.parse(JSON.stringify(jsonSearchHistoryList)), loading: false
              });                 
               //console.log(this.state.displayGrid.length)
               sessionStorage.setItem('SearchHistory', JSON.stringify(jsonSearchHistoryList))
            if(this.state.displayGrid.length > 0)
              {             
                //console.log(this.state.displayGrid.length)           
                this.setState({isListAvl:true})
                this.setState({isListAvlMsg:""})
                this.setState({ loading: false });                
              }
              else
              {                        
                this.setState({isListAvlMsg:"No record(s) found"})
                this.setState({ loading: false });
              }  
            }).catch(error => {          
            this.setState({ loading: false });
            console.log(error);
          })
        }
      }
      catch(err){
        console.error(err);
        } 
     }

     ValidateDate(){      
        this.clearError();
        let formIsValid = true;  
        let errors = this.state.errors;
        var sD = format(this.state.startDate, 'dd/MM/yyyy');
        var eD = format(this.state.endDate, 'dd/MM/yyyy');
        var today    =new Date();
        var todayDate = format(today, 'dd/MM/yyyy');
          
        if(today < this.state.startDate )
        {
          errors["startDate"] = "Date is greater than today's date"; 
          formIsValid =false;
        }
        else if(todayDate === sD)
        {
          errors["startDate"] = "Date must be less today's date";
          formIsValid =false;
        }     
        if(eD < todayDate)
        {
          errors["endDate"] = "Date must be less than today's date";
          formIsValid =false;
        }
        if(this.state.selectfields.searchBy === "")
        {
          errors["searchType"] = "Please select the Option";
          formIsValid =false;
        }      
          return formIsValid;
     }

     clearError()
     {
       let errors = this.state.errors;
       errors["startDate"] = "";  
       errors["endDate"]="";        
       this.setState({errMsg : ""})
       this.setState({
         errors
     });
     }

    getItemInfo= (item) =>{
        const styleObj = {
        fontSize: 10,
        color: "red",      
      } 
      const styleBilObj = {
        fontSize: 10,
        color: "#800000",      
      } 
   
      if(item["material"] === true)
      {
        return  (<span style={styleObj}>Clean</span>) 
        }
        else{
        return ""
        } 
    };
  
getBillItemInfo= (item) =>{
  
    const styleBilObj = {
      fontSize: 10,
      color: "#800000",      
    } 
    if(item["billable"] === true)
    {
     return  (<span style={styleBilObj}>Billable</span>) 
    }
    else if(item["NTBillableT"] === true)
    {
      return  (<span  style={styleBilObj}>Non Billable Transfer</span>)      
    }
    else{
      return ""
     } 
  };

   
    getLinkWithPath= (item) =>{
        return (<NavLink
        to={{
        pathname:'/admin/viewOrderDetails',
        state:{
            Order_id:item["Order_Id"],
            task_For:"V",           
        }
        }}                                        
        activeClassName="active">{item["Order_Id"]}</NavLink>)      
    };
     

       render() {
        const { loading } = this.state; 
        const { loadingReset } = this.state;      
        const { isListAvl } = this.state;     
        return (
          <div className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card border="light" style={{ width: '100%', height:'100%' }}>
                      <Card.Body>
                      <Card.Title><h4 className="title">Search History</h4></Card.Title>
                      <Form  onSubmit= {this.onSubmit}>
                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                        <Row>
                            <Col sm={3}> 
                               <FormInputs
                                properties={{id:"find",label:"Find",type:"text",name:"find",className:"form-control input-sm",maxlength:24,value:this.state.fields.find, onChange:this.handleTextChange}}
                                errorMsg={this.state.errors.find}
                                fieldRequired={true}
                                />             
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>In</Form.Label>
                                    <Form.Control as="select" id="searchBy" onChange={this.handleSelectChange}>
                                            <option value="" selected>Select Search Option</option>
                                           <option value="Artist">Artist</option>
                                            <option value="Title">Title</option>
                                            <option value="Selection_Id">Selection #</option>
                                            <option value="Order_id">Order ID</option>
                                            <option value="name">Ship To Name</option> 
                                            <option value="ShipToName">Ship To Co</option>
                                    </Form.Control>  
                                    <Form.Text style={{margin:"0 0 0 0"}}>
                                    <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.searchType}</p>
                                    </Form.Text>                                
                            </Form.Group> 
                            </Col>  
                            <Col sm={2.5} style={{paddingTop: "1.95%"}}>                            
                                <DatePicker 
                                Id="startDate"
                                placeholderText="From Date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown  
                                disabled ={this.state.isDateEnabled}                        
                                className="datePicker"/>
                                <Form.Text style={{margin:"0 0 0 0"}}>
                                 <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.startDate}</p>
                              </Form.Text>
                            </Col>                            
                            <Col sm={2.5} style={{paddingTop: "1.95%"}}>
                                <DatePicker 
                                Id="startDate"
                                placeholderText="To Date"
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown  
                                disabled ={this.state.isDateEnabled}                          
                                className="datePicker"/>
                                <Form.Text style={{margin:"0 0 0 0"}}>
                                 <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.endDate}</p>
                              </Form.Text>
                           </Col>                         
                         </Row>                       
                        <Row>                                  
                            <Col sm={12} style={{ paddingBottom: "15px"}}>
                                <div className="form-group mb-0 text-center">                                
                                <Button type="submit" disabled={loading}>
                                    {loading && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px", color:"#3472F7" }}
                                    />
                                    )}
                                    {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                    {!loading && <span>Search History</span>}
                                </Button>                        
                                </div>
                            </Col>
                        </Row>                        
                        <Row >
                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                        <div className="scroll" style={{ width: '100%' }}>
                        { isListAvl ? 
                                <Table hover>
                                  <thead>                                  
                                    <tr>
                                       {new Variables().thSearchHistoryList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead>                    
                                  <tbody>                           
                                    {this.state.displayGrid.map((prop, key) => { 
                                        return (
                                            <tr key={key} >                                                                                        
                                            <td>{this.getLinkWithPath(prop)}&nbsp;{this.getBillItemInfo(prop)}&nbsp;{this.getItemInfo(prop)}</td> 
                                            <td>{prop["Requestor"]}</td>
                                            <td>{prop["Artist"]}</td>
                                            <td>{prop["Title"]}</td>
                                            <td>{prop["Selection_Id"]}</td>
                                            <td>{prop["Configuration"]}</td>
                                            <td>{prop["Quantity"]}</td>
                                            <td>{prop["ShipToName"]}</td>
                                            <td>{prop["ShipToCo"]}</td>
                                        </tr>
                                        )            
                                      })} 
                                   </tbody>
                                </Table>:
                                <Table>
                                  <thead>
                                    <tr>
                                      {new Variables().thSearchHistoryList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead> 
                                  <tbody>
                                    <tr>
                                      <td colSpan={11} align="center" className="text-danger font-size-xs small">{this.state.isListAvlMsg}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                } 
                            </div>
                        </Col>
                        </Row>                   
                     </Form>
                      </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
}

