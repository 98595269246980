import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class ViewOrderController {

    serviceProxy: ServiceProxy;
    variables: Variables;
    response : any;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();        
    }
    getListViewOrder(alias : string,start_date : string,end_date : string){
        var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getListViewOrder;
            var data = {
                "alias": alias,               
                "startDate":start_date,
                "endDate": end_date               
            }        
            return this.serviceProxy.get(url,data)
            .then(response => {                
                var jsonviewOrderList : Array<{"Order_Id": string; "billable": boolean;"material":boolean;"NTBillableT":boolean;"RequestDate":Date;"ShipToName":string;"Name":string;
                                    "Approver_Id": string;"StatusDesc": string;"Ship_method":string; "Sales_type":string }> = [];
                if(response.success){      
                     response.recordset.forEach(list);                   
                    function list(item: {  Order_Id:string;billable:boolean;material:boolean;NTBillableT:boolean;Request_Date:Date;ShipToName:string;
                        Name:string;StatusDesc:string;Approver_Id:string;ship_method:string;sales_type:string }, _index: number){
                            jsonviewOrderList.push({"Order_Id": item.Order_Id, "billable": item.billable,"material":item.material,"NTBillableT":item.NTBillableT,
                            "RequestDate":item.Request_Date,"ShipToName":item.ShipToName,"Name":item.Name,"StatusDesc":item.StatusDesc,"Approver_Id":item.Approver_Id,
                        "Ship_method" :item.ship_method, "Sales_type":item.sales_type})
                    }
                    return JSON.parse(JSON.stringify(jsonviewOrderList).replace(/"\s+|\s+"/g, '"'));
                }
                else {     
                     this.response.msg = response.message.toString();                    
                    this.response.success = false;
                    return JSON.parse(JSON.stringify(jsonviewOrderList).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });  
    }

    getOrderSummary(orderID : string){
        var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getOrderSummary;
        var data = {
            "orderID": orderID            
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {      
            var resOrderdata = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));      
           return resOrderdata;
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });  
    }

    getNextApprover(orderID : string){
        var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getNextApprover;
        var recordsetNextApprover= {"recordset" :[{"alias": "","Approver":""}]};
        var data = {
            "orderID": orderID           
        }        
        return this.serviceProxy.get(url,data)       
        .then(response => {
            var jsonNxtApproverdata : Array<{"alias": string;"Approver":string}> = [];
            if(response.success) {
                response.recordset.forEach(list);    
                function list(item: { alias:string;Approver:string;}, _index: number){
                    jsonNxtApproverdata.push({"alias": item.alias, "Approver": item.Approver})
            }               
            return JSON.parse(JSON.stringify(jsonNxtApproverdata).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                recordsetNextApprover.recordset.forEach(list);                   
                function list(item: {alias:string;Approver:string;}, _index: number){
                    jsonNxtApproverdata.push({"alias": item.alias, "Approver": item.Approver})
                }               
                return JSON.parse(JSON.stringify(jsonNxtApproverdata).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });  
    }

    getApprovalHistory(orderID : string){
    var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getApprovalHistory;
        var data = {
            "orderID": orderID           
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {                
            var jsonAppHisList : Array<{"Approver": string;"ApprovalDateTime":string;"ApproverEmail":string;"Notes":string;"ApproverType":string;"ApproverType_Id":string }> = [];
            if(response.success){  
                   response.recordset.forEach(list);                   
                function list(item: { Approver:string;ApprovalDateTime:string;ApproverEmail:string;Notes:string;ApproverType:string,ApproverType_Id:string}, _index: number){
                        jsonAppHisList.push({"Approver": item.Approver, "ApprovalDateTime": item.ApprovalDateTime,"ApproverEmail":item.ApproverEmail,
                        "Notes":item.Notes,"ApproverType":item.ApproverType,ApproverType_Id:item.ApproverType_Id})
                }               
                return JSON.parse(JSON.stringify(jsonAppHisList).replace(/"\s+|\s+"/g, '"'));
            }
            else {                
                this.variables.recordsetApprovalHistory.recordset.forEach(list);                   
                function list(item: { Approver:string;ApprovalDateTime:string;ApproverEmail:string;Notes:string;ApproverType:string,ApproverType_Id:string}, _index: number){
                        jsonAppHisList.push({"Approver": item.Approver, "ApprovalDateTime": item.ApprovalDateTime,"ApproverEmail":item.ApproverEmail,
                        "Notes":item.Notes,"ApproverType":item.ApproverType,ApproverType_Id:item.ApproverType_Id})
                }               
                return JSON.parse(JSON.stringify(jsonAppHisList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });  
    }

    getOrderDetails(orderID : string){
    var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getOrderDetails;
        var data = {
            "orderID": orderID           
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {                
            var jsonOrdDetList : Array<{"itemStatusDesc":string;"item_id":string;"label_id":string;"unitstoset":string;"billable":string;"Order_Id":string;"Selection_Id":string;
            "Configuration":string;"order_item_status_id":string;"Quantity_Approved":string; "Quantity_Requested":string;"ReqQty":string;"DeptQty":string;"DJCQty":string;
            "Price_Requested":string;"Price_Approved":string;"Approver_Id":string;"Approval_Date":string;"Artist":string;"Title":string;
            "ReleaseDate":string; "alias":string;"cost":string;}> = [];
            if(response.success){      
                    response.recordset.forEach(list);                   
                function list(item: {itemStatusDesc:string;item_id:string;label_id:string;unitstoset:string;billable:string;Order_Id:string;Selection_Id:string;
                    Configuration:string;order_item_status_id:string;Quantity_Approved:string; Quantity_Requested:string;ReqQty:string;DeptQty:string;DJCQty:string;
                    Price_Requested:string;Price_Approved:string;Approver_Id:string;Approval_Date:string;Artist:string;Title:string;
                    ReleaseDate:string; alias:string;cost:string;}, _index: number){
                        jsonOrdDetList.push({"itemStatusDesc":item.itemStatusDesc,"item_id":item.item_id,"label_id":item.label_id,"unitstoset":item.unitstoset,"billable":item.billable,"Order_Id":item.Order_Id,"Selection_Id":item.Selection_Id,
                        "Configuration":item.Configuration,"order_item_status_id":item.order_item_status_id,"Quantity_Approved":item.Quantity_Approved, "Quantity_Requested":item.Quantity_Requested,"ReqQty":item.ReqQty,"DeptQty":item.DeptQty,"DJCQty":item.DJCQty,
                        "Price_Requested":item.Price_Requested,"Price_Approved":item.Price_Approved,"Approver_Id":item.Approver_Id,"Approval_Date":item.Approval_Date,"Artist":item.Artist,"Title":item.Title,
                        "ReleaseDate":item.ReleaseDate, "alias":item.alias,"cost":item.cost})
                }
                
                return JSON.parse(JSON.stringify(jsonOrdDetList).replace(/"\s+|\s+"/g, '"'));
            }
            else {     
                    this.response.msg = response.message.toString();                    
                this.response.success = false;
                jsonOrdDetList.push({"itemStatusDesc":"","item_id":"","label_id":"","unitstoset":"","billable":"","Order_Id":"","Selection_Id":"",
                "Configuration":"","order_item_status_id":"","Quantity_Approved":"", "Quantity_Requested":"","ReqQty":"","DeptQty":"","DJCQty":"",
                "Price_Requested":"","Price_Approved":"","Approver_Id":"","Approval_Date":"","Artist":"","Title":"",
                "ReleaseDate":"", "alias":"","cost":""})
                return JSON.parse(JSON.stringify(jsonOrdDetList).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });  
    }

    getOrderDepotHistory(orderID : string){
        var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getOrderDepotHistory;
            var data = {
                "orderID": orderID           
            }        
            return this.serviceProxy.get(url,data)
            .then(response => {                
                var jsonDepotHisList : Array<{"CreateDateTime":string;"Notes":string;}> = [];
                if(response.success){ 
                      response.recordset.forEach(list);                   
                    function list(item: {CreateDateTime:string;Notes:string;}, _index: number){
                        jsonDepotHisList.push({"CreateDateTime":item.CreateDateTime,"Notes":item.Notes})
                    }
                    
                    return JSON.parse(JSON.stringify(jsonDepotHisList).replace(/"\s+|\s+"/g, '"'));
                }
                else {  
                   
                        this.variables.recordsetDepotHistory.recordset.forEach(list);                   
                    function list(item: {CreateDateTime:string;Notes:string;}, _index: number){
                        jsonDepotHisList.push({"CreateDateTime":item.CreateDateTime,"Notes":item.Notes})
                    }
                    
                    return JSON.parse(JSON.stringify(jsonDepotHisList).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });  
        }

        getOrderDepotContact(labelID : string){
            var url = this.variables.Url + this.variables.Controller.ViewOrder + this.variables.viewOrderService.getOrderDepotContact;
                var data = {
                    "labelId": labelID           
                }        
                return this.serviceProxy.get(url,data)
                .then(response => {                
                    var jsonDepotContact : Array<{"name":string;"phone":string;}> = [];
                    if(response.success){ 
                          response.recordset.forEach(list); 
                         function list(item: {name:string;phone:string;}, _index: number){
                            jsonDepotContact.push({"name":item.name,"phone":item.phone})
                        }
                        
                        return JSON.parse(JSON.stringify(jsonDepotContact).replace(/"\s+|\s+"/g, '"'));
                    }
                    else {                         
                        this.response.msg = response.message.toString();                    
                        this.response.success = false; 
                        jsonDepotContact.push({"name":"","phone":""})                      
                        return JSON.parse(JSON.stringify(jsonDepotContact).replace(/"\s+|\s+"/g, '"'));
                    }
                }).catch(error => {
                    console.error('There was an error!', error);
                    return error;
                });  
            }
}