import React, { Component, CSSProperties } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
//import {Card} from '../../CustomComponents/Card';
import {FormInputs} from '../../CustomComponents/FormInput';
import {FormInputSearch} from '../../CustomComponents/FormInputSearch';
import {FormSelect} from '../../CustomComponents/FormSelect';
import {FormSelectSearch} from '../../CustomComponents/FormSelectSearch';
import {FormLabelField} from '../../CustomComponents/FormLabelField';
import jsonState from '../../../json/State.json';
import {CommonController} from '../../../Controllers/CommonController';
import { Variables } from '../../../Variables/Variables';
import '../../../assets/css/Style.css'
import {FormCheckBox} from '../../CustomComponents/FormCheckBox';
import { Redirect, NavLink } from 'react-router-dom';
import { OrderRequestFormParameter, Istate } from "../../../Model/OrderRequestForm"; 

export class BillableReqForm extends Component<OrderRequestFormParameter,Istate> 
{
    constructor(props:OrderRequestFormParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
        fields: { nextStep:'',depot:'',approvers:'',legacyOrder:'',requestDate:'',customerPO:'',salesType:'',shipmentMethod:'',orderBy:'',accountNo:'',customerNumber:'',businessUnit:'',department:'',uid:'',pwd:'',lblSearchContact:'',businessunit:'',orderType:'',billTo:'',shipTo:'',specialIns:'',orderId:'',label:'',reason:'',qtyReq:[],rejectItem:[],comments:'' },
        selectfields: {shipmentMethod:''},
        errMsg:"",
        items:[],
        approvers:[],
        key:0,
        loggedIn,
        loading:false
        }
      }
    componentWillMount(){
        let fields = this.state.fields;
        //this.props.location.state.Order_id;
        //this.props.location.state.goodsReq === "V"
        //
        fields['nextStep'] = "Pending(RUSSEL FLINK,KEVIN LIPSON,ANNA MCCOWN)";
        fields['depot'] = "";
        fields['approvers'] = "";
        fields['legacyOrder'] = "";
        fields['requestDate'] = "6/24/2020 10:00 AM";
        fields['customerPO'] = "566748";
        fields['salesType'] = "56 Clean (fully royalty bearing)";
        fields['shipmentMethod'] = "Standard Ground";
        fields['orderBy'] = "RYAN STEVEN";
        fields['accountNo'] = "";
        fields['customerNumber'] = "ON890";
        fields['businessUnit'] = "US1A0283";
        fields['department'] = "Shared Svcs-Sales";
        fields['billTo'] = "SONY MUSIC ENTERTAINMENT \n 301 Route 17 North, 11th Floor \n Ruther ford,new jersey 07070 ";
        fields['shipTo'] = "Lauren LeTourneau \n Lauren LeTourneau \n 1347 Clearwater Drive \n Woodbury,  MN  55129 \n USA";
        fields['orderId'] = "566748";
        fields['label'] = "Republic Records";
        fields['reason'] = "Promo copy - Lauren LeTourneau (Target)";

        this.setState({ fields, items:JSON.parse(JSON.stringify(jsonState.result)), approvers:JSON.parse(JSON.stringify(jsonState.result)) }); 
    }
    displayOrderItemsTable=()=>{
        if(this.props.location.state.goodsReq === 'V')
        {
            return this.orderItemsTableForView();
        }
        else
        {
            return this.orderItemsTableForEdit();
        }

    }
    displayShipingMethod=()=>{
        if(this.props.location.state.goodsReq === 'V')
        {
            return <FormLabelField
            properties={{label:"Shipment Method",id:"shipmentMethod",name:"shipmentMethod",className:"form-control",value:this.state.fields.shipmentMethod}}
        />
        }
        else
        {
            return <Form.Group>
            <Form.Label>Shipment Method</Form.Label>
                <Form.Control as="select" id="shipmentMethod" name="shipmentMethod" value={this.state.selectfields.shipmentMethod} className="form-control" >
                    <option selected value="standardGround">Standard Ground</option> 
                    <option selected value="2nd Day Air">2nd Day Air</option>   
                    <option selected value="Overnight">Overnight</option>      
                    <option selected value="Pick-Up">Pick-Up</option>
                </Form.Control>
            </Form.Group>
        }
    }
    orderItemsTableForView=()=>{
        return <Row>
                    <Col sm={12}>
                        <div style={{width: '100%', height: "auto"}}>
                            <Table hover>                    
                                <thead>
                                    <tr>
                                        {new Variables().thArrayBillableOrderView.map((prop, key) => {
                                            return <th key={key}>{prop}</th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {new Variables().tdArrayBillableOrderView.map((prop, key) => {
                                        return (
                                        <tr key={key}>
                                            {prop.map((prop, key) => {
                                                 return <td key={key}>{prop}</td>;
                                            })}
                                        </tr>
                                        );
                                    })}
                                    </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

    }
    orderItemsTableForEdit=()=>{
       
            return <Row>
                    <Col sm={12}>
                        <div style={{width: '100%', height: "auto"}}>
                            <Table hover>                    
                                <thead>
                                    <tr>
                                        {new Variables().thArrayBillableOrderEdit.map((prop, key) => {
                                            return <th key={key}>{prop}</th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {new Variables().tdArrayBillableOrderEdit.map((prop, key) => {
                                        return (
                                        <tr key={key}>
                                            {prop.map((prop, key) => {
                                                return this.formControl(prop, key);
                                            })}
                                        </tr>
                                        );
                                    })}
                                    </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

    }
    orderItemsTableForEditButton=()=>{
        const {loading} = this.state;
        if(this.props.location.state.goodsReq === 'V')
        {
            return <></>
        }
        else
        {
            return <div className="form-group mt-4 mb-0 text-center">
                <Row>
                    <Col sm={4} style={{textAlign: 'left'}}>
                        <Form.Group>
                        <Form.Label>Comments (Order Changes/Depot)</Form.Label>
                            <Form.Control as="textarea" rows={3} id="comments" name="comments" className="form-control" value={this.state.fields.comments} ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={8}  style={{alignSelf: "center"}}>
                        <Button disabled={loading} style={{marginRight: "5px"}} onClick={() => { alert("Your Order has been submitted...");this.props.history.push('/admin/home')}}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color:"#3472F7" }}
                        />
                        )}
                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                        {!loading && <span>Process Order</span>}
                        </Button>
                        <Button disabled={loading} style={{marginRight: "5px"}} onClick={() => {this.props.history.push('/admin/home')}}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color:"#3472F7" }}
                        />
                        )}
                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                        {!loading && <span>Hold Order</span>}
                        </Button>
                        <Button disabled={loading} style={{marginRight: "5px"}} onClick={() => {this.props.history.push('/admin/home')}}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color:"#3472F7" }}
                        />
                        )}
                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                        {!loading && <span>Reject Order</span>}
                        </Button>
                    </Col>
                </Row>
            </div>
        }
    }
    formControl=(prop, key)=>{
        if(key === 0){
            return <td key={key}><NavLink
            to={{
              pathname:'#'
            }}                                        
            activeClassName="active"
          >{prop}</NavLink></td>;
        }
        else if(key === 9){
            return <td style={{padding: '0px',paddingLeft:'5px'}}><FormControl style={{height: 'auto',width:'50px'}} size="sm" id="qtyRec" type="text" name="qtyRec" className="form-control input-sm" defaultValue={prop} ></FormControl></td>
        }
        else if(key === 10){
            return <td style={{padding: '0px',paddingLeft:'5px'}}><FormControl style={{height: 'auto',width:'75px'}} size="sm" id="price" type="text" name="price" className="form-control input-sm" defaultValue={prop} ></FormControl></td>
        }
        else if(key === 11){
            return <td style={{padding: '0px'}}><FormControl style={{height: 'auto'}} size="sm" id="rejectItem" type="checkbox" name="rejectItem" className="form-control input-sm" ></FormControl></td>
        }
        else
        {
            return <td key={key}>{prop}</td>;
        }
    }

    render() {
        if (this.state.loggedIn === false){
            return <Redirect to="/" />
        }
        return (<div className="content">
                <Container fluid>
                    <Row>
                        <Col sm={12} className="small">
                        <Form>
                            <Card border="light" style={{ width: '100%' }}>
                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                        <div style={{width: '100%',borderBottom:'1px solid',borderColor:"rgba(0, 0, 0, 0.125)"}}>
                                            <Row>
                                                <Col sm={6}>
                                                    <FormLabelField
                                                        properties={{label:"Next Step",id:"nextStep",name:"nextStep",className:"form-control",value:this.state.fields.nextStep}}
                                                    />
                                                </Col>
                                                <Col sm={2}></Col>
                                                <Col sm={4}>
                                                    <FormLabelField
                                                        properties={{label:"Legacy Order",id:"legacyOrder",name:"legacyOrder",className:"form-control",value:this.state.fields.legacyOrder}}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <FormLabelField
                                                        properties={{label:"Approvers",id:"approvers",name:"approvers",className:"form-control",value:this.state.fields.approvers}}
                                                    />
                                                </Col>
                                                <Col sm={2}></Col>
                                                <Col sm={4}>
                                                    <FormLabelField
                                                        properties={{label:"Depot",id:"depot",name:"depot",className:"form-control",value:this.state.fields.depot}}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row style={{textAlign: 'center'}}>
                                            <Col sm={12}>
                                                <div className="header"><h4 className="title">BILLABLE ORDER REQUEST FORM</h4><p className="category"></p></div>
                                                <div className="header"><h4 className="title" style={{color:"#2AB3E2"}}>56</h4><p className="category"></p></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <div id="navbar">
                                                    <Navbar expand="lg" >
                                                        <Navbar.Brand>Request Details</Navbar.Brand>
                                                        <Nav className="ml-auto">
                                                        </Nav>
                                                    </Navbar>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={3}>
                                                <FormLabelField
                                                    properties={{label:"Request Date",id:"requestDate",name:"requestDate",className:"form-control",value:this.state.fields.requestDate}}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <FormLabelField
                                                    properties={{label:"Customer P.O.",id:"customerPO",name:"customerPO",className:"form-control",value:this.state.fields.customerPO}}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <FormLabelField
                                                    properties={{label:"Sales Type",id:"salesType",name:"salesType",className:"form-control",value:this.state.fields.salesType}}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                {this.displayShipingMethod()}
                                            </Col>
                                        </Row>
                                        <div style={{width: '100%',borderBottom:'1px solid',borderColor:"rgba(0, 0, 0, 0.125)"}}>
                                             <Row>
                                                <Col sm={12}>
                                                    <div id="navbar">
                                                        <Navbar expand="lg">
                                                            <Navbar.Brand>Order Details</Navbar.Brand>
                                                            <Nav className="ml-auto">
                                                            </Nav>
                                                        </Navbar>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormLabelField
                                                                properties={{label:"Order By",id:"orderBy",name:"orderBy",className:"form-control",value:this.state.fields.orderBy}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormLabelField
                                                                properties={{label:"Account No",id:"accountNo",name:"accountNo",className:"form-control",value:this.state.fields.accountNo}}
                                                            />
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormLabelField
                                                                properties={{label:"Customer Number",id:"customerNumber",name:"customerNumber",className:"form-control",value:this.state.fields.customerNumber}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormLabelField
                                                                properties={{label:"Business Unit",id:"businessUnit",name:"businessUnit",className:"form-control",value:this.state.fields.businessUnit}}
                                                            />
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormLabelField
                                                                properties={{label:"Department",id:"department",name:"department",className:"form-control",value:this.state.fields.department}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6}>
                                                    <Row>
                                                    <Col sm={6}>
                                                    <FormLabel>{'Bill To'}</FormLabel>
                                                            {this.state.fields.billTo.split('\n').map((item, key) => {
                                                                return <div x-ms-format-detection="none" key={key}>{item}<br/></div>
                                                            })}
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup >
                                                            <FormLabel>{'Ship To'}</FormLabel>
                                                            {this.state.fields.shipTo.split('\n').map((item, key) => {
                                                                return <div x-ms-format-detection="none" key={key}>{item}<br/></div>
                                                            })}
                                                        </FormGroup>
                                                    </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col sm={3}>
                                                <FormLabelField
                                                    properties={{label:"Special Instructions",id:"specialIns",name:"specialIns",className:"form-control",value:this.state.fields.specialIns}}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                            <FormLabelField
                                                properties={{label:"Order Id",id:"orderId",name:"orderId",className:"form-control",value:this.state.fields.orderId}}
                                            />
                                            </Col>
                                            <Col sm={3}>
                                                <FormLabelField
                                                    properties={{label:"Label",id:"label",name:"label",className:"form-control",value:this.state.fields.label}}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <FormLabelField
                                                    properties={{label:"Requestor Reason",id:"reason",name:"reason",className:"form-control",value:this.state.fields.reason}}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <div id="navbar">
                                                    <Navbar expand="lg">
                                                        <Navbar.Brand>Ordered Items</Navbar.Brand>
                                                        <Nav className="ml-auto">
                                                        </Nav>
                                                    </Navbar>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div style={{width: '100%',borderBottom:'1px solid',borderColor:"rgba(0, 0, 0, 0.125)"}}>
                                            {this.displayOrderItemsTable()}
                                        </div>
                                        {this.orderItemsTableForEditButton()}
                                        <Row>
                                            <Col sm={12}>
                                                <div id="navbar">
                                                    <Navbar expand="lg">
                                                        <Navbar.Brand>Approver Details</Navbar.Brand>
                                                        <Nav className="ml-auto">
                                                        </Nav>
                                                    </Navbar>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <div style={{width: '100%', height: "auto"}}>
                                                    <Table hover>                    
                                                        <thead>
                                                            <tr>
                                                                {new Variables().thArrayFreeGoodsOrderApprovers.map((prop, key) => {
                                                                    return <th key={key}>{prop}</th>;
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {new Variables().tdArrayFreeGoodsOrderApprovers.map((prop, key) => {
                                                            return (
                                                            <tr key={key}>
                                                                {prop.map((prop, key) => {
                                                                    return <td key={key}>{prop}</td>;
                                                                })}
                                                            </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                </Card.Body>                                  
                            </Card>
                        </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
      }
    }
