import React, { Component } from "react";
import {Container,Card,Row,Col,Form,Alert,Button} from "../../../../node_modules/react-bootstrap";
import Faq from "react-faq-component";
import { Redirect } from 'react-router-dom';
import '../../../assets/css/faqPage.css';

const data = {
    rows: [
        {
            title: 'My order status says "Pending". How can I tell which Approver has my order?',
            content: `On the Main Menu, click on “View Order Status”. Then select the order in 
            question. Once opened, on the banner at the top you will see the name(s) of the Approver(s) for the order.`,
        },
        {
            title: 'How do I change a mailing address in eGratis?',
            content: `From the Main Menu select “Create Free Goods Request.” From here, on the top 
            right of the screen select “Edit.” Using the drop-down menu, select the contact profile 
            you wish to update. Update the desired fields and click “Edit Contact” to save.</br>
            </br>Note: changing your own information on the Edit User page will not change the mailing 
            address used to deliver your order.`,
        },
        {
            title: 'The item I am trying to order does not come up when I do the search on the Selection screen.',
            content: `Try typing in part of the artist or title instead of searching for the whole 
            name. (For example, instead of typing in "Marvin Gaye", try "marvin" or "marv").</br>
            </br>Available items for Free Goods Requests are limited to only products within your 
            label. If you wish to check what label an item belongs to, you can use the “Quick Product 
            Search” from the Main Menu. The label code is listed in the search results.</br></br>Certain titles 
            are not available for Free Goods orders, including New Release titles without a release date.`,
        },
        {
            title: 'I need to send the same items on one order to a different person. How can I do that in eGratis? Can I select multiple contacts?',
            content: `You are not able to select multiple contacts on a single order. However, you 
            can use the COPY feature to quickly duplicate an order and change the ship to contact. 
            After entering the first order, from the Main Menu select “View Order Status.” Find the 
            order you wish to copy and click the “Copy” icon to the left of it. This will take you 
            to the Ship To screen of the order entry. From here you can enter in the new contact 
            information, or auto-populate using the drop-down menu for existing contacts. After the 
            contact is selected you can add special instructions and change the service level for the 
            new order. The product selection screen for all copied orders will have the items already 
            selected.</br></br>Note: The only change you can make on the product screen is the 
            quantity ordered, and if it is clean/marked product. If you want to add/remove items 
            from the order you cannot use the COPY function.`,
        },
        {
            title: "What's the difference between Marked and Clean? Does this refer to the lyrics?",
            content: `No, it does not refer to the lyrics. Selections with explicit lyrics are 
            indicated with an "EX" in the title. Selections with edited lyrics have an "ED" in the 
            title. “Marked” and “Clean” refer to the product itself. Marked has an indication on 
            the product that denotes it is promotional product.</br></br>Note: Orders requesting 
            clean product will also require the approval of Jason Gallien, UMG North America CFO.`,
        },
        {
            title: 'How do I change the Quantity? I can not see the Quantity field.',
            content: `Try adjusting the eGratis window on your computer screen. The Quantity field 
            may have been pushed off the screen to the far right if the configuration description 
            is lengthy.`,
        },
    ],
};
 
const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
};

export class EgratisFAQ extends Component<IFaq,Istate> {
    constructor(props){ 
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
    
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state = {
            loggedIn
        }
      } 
    render() {
        if (this.state.loggedIn === false){
            return <Redirect to="/" />
        }
        return (
            <div className="content">
                <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%', height:'650px' }}>
                        <Card.Body>
                        <Card.Title><h4 className="title">Frequently Asked Questions (FAQ's)</h4></Card.Title>
                            <div>
                                <Faq data={data} styles={styles} config={config}/>
                                <Row>
                                    <Col><p></p></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className="font-size-xs large">For additional questions or concerns, please email the UMG Customer Service Team at UMGLabelRequests@umusic.com or call one of the Customer Service Team Members.</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><p></p></Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={10}>
                                        <div className="font-size-xs large">Alison Loyka, Customer Service Manager</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={10}>
                                        <div className="font-size-xs large">(818) 286-4375</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><p></p></Col>
                                </Row>
                                <Row>
                                    <Col><p></p></Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={10}>
                                        <div className="font-size-xs large">Jacob Vega, Customer Service Analyst</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={10}>
                                        <div className="font-size-xs large">(818) 286-4384</div>
                                    </Col>
                                </Row>
                            </div>    
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        )}
}

interface Istate{
    loggedIn:boolean;
}
    
interface IFaq{
    Faq:string;
}