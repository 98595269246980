import React, { Component } from "react";
import { Container, Card, Row, Col, Table, Form, Alert, Button, Navbar, Nav } from "react-bootstrap";
import { History, LocationState } from "History";
import { UserViewController } from "../../../Controllers/UserViewController"
import { format } from 'date-fns'
import { NavLink } from 'react-router-dom'
import '../../../assets/css/CreateOrder.css'
import { FormCheckBox } from "../../CustomComponents/FormCheckBox"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css'
import '../../../assets/css/Style.css'
import { DepotHistoryParameter, Istate } from "../../../Model/DepotOrderHistory";
import { DepotOrdersController } from "../../../Controllers/DepotOrdersController";
import ReactExport from "react-export-excel";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class DepotOrderHistory extends Component<DepotHistoryParameter, Istate> {
    constructor(props: DepotHistoryParameter) {

        super(props)
        this.state = {
            fields: { find: '', searchType: '', checkid: '', searchText: '', filter: '', startDate: "", endDate: "" },
            selectfields: { search_field: '', },
            errors: { find: '', searchType: '', checkid: '' },
            errMsg: "",
            todayDate: "",
            previousdate: "",
            Order_id: "",
            goodsReq: "",
            loading: false,
            selectedDate: '2017-08-13',
            hidediv: false,
            startDate: "",
            endDate: "",
            SearchGrid: [],
            SearchGridExp: [],
            filter: "",
            modal: {modalSpinnerShow: false}
        }


        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleSelectChange(e: any) {

        let selectfields = this.state.selectfields;
        let fields = this.state.fields;
        let SearchGrid = this.state.SearchGrid;
       
        //if (e.currentTarget.value === 'search_field') {
            if (e.currentTarget.value === "Select Search Option") {
                selectfields['search_field'] = ""
                fields['filter'] = '';
                SearchGrid = [];
            }
            else {
                selectfields['search_field'] = e.currentTarget.value;
                fields['filter'] = '';
                 selectfields.search_field = e.currentTarget.value;
                 this.state.fields.filter = '';
                 SearchGrid = [];

            }
       // }

       // selectfields.search_field = e.currentTarget.value;
       // this.state.fields.filter = '';

        this.setState({
            selectfields
        });

        this.setState({
            fields
        });

        this.setState(
            {
                SearchGrid 
            }
        )


    }

    handleChange(e: any) {
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0)
        {
            fields[e.currentTarget.name] = e.currentTarget.value;
            fields.filter = e.currentTarget.value;
        }
        else
        {
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
            fields.filter = e.currentTarget.value;
        }
        this.setState({
            fields
        })
    }

    componentDidMount() {
        let fields = this.state.fields;
        let modal = this.state.modal;
        
        var newDate = new Date();
        var pdate = new Date();
        newDate.setDate(newDate.getDate() - 1);
       // pdate.setDate(pdate.getDate() + 1);
        fields['startDate'] = format(newDate, 'MM/dd/yyyy');
        fields['endDate'] = format(pdate, 'MM/dd/yyyy')

        // this.state.startDate = format(newDate, 'dd/MM/yyyy');
        this.setState({
            hidediv: false, fields
        });      

       let DH
       DH = sessionStorage.getItem("DepotHistoryView")  
       sessionStorage.setItem('DepotHistoryView', '0' )    
       if (DH==='1'){
        this.setState({ 
            SearchGrid: JSON.parse(sessionStorage.getItem('DepotHistory') || "")           
        });
        this.setState({            
            SearchGridExp: JSON.parse(sessionStorage.getItem('DepotHistoryExport') || "") 
        })
        var temps = Date.parse(sessionStorage.getItem('startDate') || "")
        var tempe = Date.parse(sessionStorage.getItem('endDate') || "")
        if(sessionStorage.getItem('startDate') != '' && sessionStorage.getItem('endDate') != '' ){
            fields['startDate'] =  format(temps, 'MM/dd/yyyy');
            fields['endDate'] =  format(tempe, 'MM/dd/yyyy');
        }
      }
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        
        var fromdate;
        var todate;
        let modal = this.state.modal;
        //modal['modalSpinnerShow']=true;
        //this.setState({ modal });

        if (this.state.startDate == '') {
            fromdate = this.state.fields.startDate;
        }
        else if (this.state.startDate == null) {
            // fromdate = format(this.state.startDate, 'MM/dd/yyyy');
            fromdate = this.state.fields.startDate;
        }
        else {
            fromdate = format(this.state.startDate, 'MM/dd/yyyy');
        }

        if (this.state.endDate == '') {
            todate = this.state.fields.endDate;
        }
        else if (this.state.endDate == null) {
            todate = this.state.fields.endDate;
        }
        else {
            // todate = format(this.state.endDate, 'MM/dd/yyyy');
            todate = format(this.state.endDate, 'MM/dd/yyyy');
        }

        if (this.state.fields.filter != '') {
            fromdate = '';
            todate = '';
        }

        if (this.state.selectfields.search_field == 'order_id') {
            fromdate = '';
            todate = '';
        }
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        
            this.setState({ loading: true });
            var data = {
                search_field: this.state.selectfields.search_field,
                filter: this.state.fields.filter,
                startDate: fromdate,
                endDate: todate

            }

            let depotsearchController = new DepotOrdersController();
            depotsearchController.getDepotHistory(data).then(jsonDepotHistory => {
                this.setState({
                    SearchGrid: JSON.parse(JSON.stringify(jsonDepotHistory)), loading: false
                });
                sessionStorage.setItem('DepotHistory', JSON.stringify(jsonDepotHistory))
                sessionStorage.setItem('startDate', fromdate)
                sessionStorage.setItem('endDate', todate)
                this.setState({ loading: false });
                
            }).catch(error => {
                this.setState({ loading: false });
                console.error("There was an error loading in search product")
            });
            
            depotsearchController.getDepotHistoryExport(data).then(jsonDepotHistoryExport => {
                this.setState({
                    SearchGridExp: JSON.parse(JSON.stringify(jsonDepotHistoryExport)), loading: false
                });
                sessionStorage.setItem('DepotHistoryExport', JSON.stringify(jsonDepotHistoryExport))
               // sessionStorage.setItem('startDate', fromdate)
               // sessionStorage.setItem('endDate', todate)
               // this.setState({ loading: false });
             
                
            }).catch(error => {
                this.setState({ loading: false });
                console.error("There was an error loading in search product")
            });

        this.setState({
            hidediv: false            
        });
       
        modal['modalSpinnerShow']=false;
        this.setState({ modal });
        
        }

    handleChangeStartDate = date => {
        

        this.setState({
            startDate: date
        });
    };

    handleChangeEndDate = date => {
        
        this.setState({
            endDate: date
        });
    };

    displayOrderItemsTable = () => {
        let selection_id = this.state.selectfields.search_field

        if (selection_id === 'selection_id') {
          return  this.displayHeaderSelectionID();
        }
        else {           
            return this.displayHeaderNonSelectionID();
        }

    }
    
    displayHeaderSelectionID = () => {

        return <Row className="mt-5">
            <Col sm={12}>
                <div hidden={this.state.hidediv} >
                    <Table >
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Order #</th>
                                <th>Selection #</th>
                                <th>Requestor</th>
                                <th>Label</th>
                                <th>Ship to</th>
                                <th>Attention</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.SearchGrid.map((prop, key) => {
                                return (
                                    <tr key={key} >
                                        <td>

                                        </td>
                                        <td>{prop["request_date"]}
                                            <span style={{ color: '#FF0000' }}> <b>   {prop["billable"] === "1" ? " Billable" : ""} </b> </span>
                                        </td>
                                        <td><NavLink
                                            to={{
                                                pathname: '/admin/DepotOrderHistoryView',
                                                state: {
                                                    Order_id: prop["Order_Id"],
                                                    goodsReq: "E"
                                                }
                                            }}
                                            activeClassName="active">{prop["Order_Id"]}</NavLink>
                                        </td>
                                        <td>{prop["selection_id"]}</td>
                                        <td>{prop["Requestor"]}</td>
                                        <td>{prop["label_id"]}</td>
                                        <td>{prop["ShipToName"]}</td>
                                        <td>{prop["Name"]}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>

    }
    displayHeaderNonSelectionID = () => {

        return <Row>
            <Col sm={12}>
                <div hidden={this.state.hidediv} >
                    <Table >
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Order #</th>
                                <th>Requestor</th>
                                <th>Label</th>
                                <th>Ship to</th>
                                <th>Attention</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.SearchGrid.map((prop, key) => {
                                return (
                                    <tr key={key} >
                                        <td>

                                        </td>
                                        <td>{prop["request_date"]}
                                            <span style={{ color: '#FF0000' }}> <b>   {prop["billable"] === "1" ? " Billable" : ""} </b> </span>
                                        </td>
                                        <td><NavLink
                                            to={{
                                                pathname: '/admin/depotorderhistoryview',
                                                state: {
                                                    Order_id: prop["Order_Id"],
                                                    goodsReq: "E"
                                                }
                                            }}
                                            activeClassName="active">{prop["Order_Id"]}</NavLink>
                                        </td>

                                        <td>{prop["Requestor"]}</td>
                                        <td>{prop["label_id"]}</td>
                                        <td>{prop["ShipToName"]}</td>
                                        <td>{prop["Name"]}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>

    }


    getItemInfo = (item) => {
        
        if (item == "1") {
            return "Clean";
        }
        else {
            return ""
        }
    };

    render() {
        const { loading } = this.state;
        const alignCardCenter = {
            "margin": "0 0 0 0"
        };
        return (
            <div className="content">
                <Container fluid>
                <FormModalSpinner show={this.state.modal.modalSpinnerShow }>   </FormModalSpinner>
                    <Card border="light" style={{ width: '100%', height: '600px' }}>
                        <Card.Body>
                            <Card.Title><h4 className="title">Depot History</h4></Card.Title>
                            <form onSubmit={this.onSubmit}>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                <Row style={alignCardCenter}>
                                    <Col sm={12} >
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4} style={{ paddingBottom: "17px" }}> </Col>
                                    <Col sm={8}> </Col>
                                </Row>
                                <Row style={alignCardCenter}>
                                    <Col sm={3} >
                                        <Form.Control as="select" id="search_field" name="search_field" onChange={this.handleSelectChange}  >
                                            <option value="0" selected>Select Search Option</option>
                                            <option value="selection_id">Selection #</option>
                                            <option value="order_id">Order ID</option>
                                            <option value="legacyorder">Legacy #</option>
                                            <option value="requestor">Requestor</option>
                                            <option value="shiptoname">Ship To</option>
                                            <option value="name">Attention</option>
                                        </Form.Control>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Control id="filter" type="text" onChange={this.handleChange} value = {this.state.fields.filter}  placeholder="" />
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                                <Row>
                                    <Col sm={4} style={{ paddingBottom: "17px" }}> </Col>
                                    <Col sm={8}> </Col>
                                </Row>
                                <Row style={alignCardCenter}>
                                    <Col sm={3}>
                                        <Form.Label>Fields for Date Between</Form.Label>
                                    </Col>
                                    <Col sm={3}>
                                        <DatePicker
                                            Id="startDate"
                                            placeholderText={this.state.fields.startDate}
                                            selected={this.state.startDate}
                                            onChange={this.handleChangeStartDate}
                                            isClearable
                                            showMonthDropdown
                                            showYearDropdown
                                            className="datePicker" />
                                    </Col>
                                    <Col sm={1.5}>
                                        <Form.Label>and</Form.Label>
                                    </Col>
                                    <Col sm={3}>
                                        <DatePicker
                                            Id="endDate"
                                            placeholderText={this.state.fields.endDate}
                                            selected={this.state.endDate}
                                            onChange={this.handleChangeEndDate}
                                            isClearable
                                            showMonthDropdown
                                            showYearDropdown
                                            className="datePicker" />
                                    </Col>
                                    <Col sm={1.5}></Col>
                                </Row>
                                <Row>
                                    <Col sm={4} style={{ paddingBottom: "17px" }}> </Col>
                                    <Col sm={8}> </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} style={{ textAlign: "center" }}>
                                        <Button type="submit" disabled={loading} style={{ marginRight: "5px", marginBottom: "15px" }} >
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px",color:"#3472F7"  }}
                                                />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                            {!loading && <span>Search History</span>}
                                        </Button>
                                    </Col>
                                   
                                    <Col sm={4}> 
                                             {this.state.selectfields.search_field ==='selection_id'
                                             ?                         
                                        <ExcelFile filename = "Depot History" element={<Button type="button" style={{ marginRight: "5px" }}>Export to Excel</Button>}>
                                            <ExcelSheet data={this.state.SearchGridExp} name="Depot History">                                                
                                                <ExcelColumn label="Date" value="request_date" />
                                                <ExcelColumn label="Order #" value="Order_Id" />
                                                <ExcelColumn label="Selection #" value="selection_id" />
                                                <ExcelColumn label="Requestor" value="Requestor" />
                                                <ExcelColumn label="Label" value="label_id" />
                                                <ExcelColumn label="Ship To" value="ShipToName" />
                                                <ExcelColumn label="Attention" value="Name" />
                                                <ExcelColumn label="Account #" value="account_no" />
                                                <ExcelColumn label="Sale Type" value="sales_type" />
                                                <ExcelColumn label="Config" value="Configuration" />
                                                <ExcelColumn label="QTY" value="Quantity_Approved" />
                                                <ExcelColumn label="Price" value="price_approved" />
                                            </ExcelSheet>
                                         </ExcelFile>
                                        :
                                        <ExcelFile filename = "Depot History" element={<Button type="button" style={{ marginRight: "5px" }}>Export to Excel</Button>}>
                                            <ExcelSheet data={this.state.SearchGridExp} name="Depot History">                                                
                                                <ExcelColumn label="Date" value="request_date" />
                                                <ExcelColumn label="Order #" value="Order_Id" />
                                                <ExcelColumn label="Requestor" value="Requestor" />
                                                <ExcelColumn label="Label" value="label_id" />
                                                <ExcelColumn label="Ship To" value="ShipToName" />
                                                <ExcelColumn label="Attention" value="Name" />
                                                <ExcelColumn label="Account #" value="account_no" />
                                                <ExcelColumn label="Sale Type" value="sales_type" />
                                                <ExcelColumn label="Config" value="Configuration" />
                                                <ExcelColumn label="QTY" value="Quantity_Approved" />
                                                <ExcelColumn label="Price" value="price_approved" />
                                            </ExcelSheet>
                                         </ExcelFile>
                                        }  
                                    </Col> 
                                </Row>
                               {this.displayOrderItemsTable()}
                            </form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        )
    }
}