import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class OrderApprovalController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    response : any;
    searchInfo : any;
    dateTimeFormat : DateTimeFormat;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
        this.searchInfo = {userName: '', orderItem:'', nrecordLabelId:'',title:'',artist:'',selection:'',upc:'', exceptions:false}
    }

    getApproverInbox(data){    
            
        var url = this.variables.Url + this.variables.Controller.orderApproval + this.variables.orderApprovalService.getApproverInbox;            
        var data1 : any = {
            sortBy : data.sortBy,
            aliasIn : data.aliasIn            
        }
        return this.serviceProxy.get(url,data1) 
        .then(response => {  
            var jsonOrderApproval : Array<{ "total_qty" : string; "billable" : string;  "Order_ID" : string; "Name" : string; "ShipToName" : string; "Requestor" : string; "RequestAlias" : string; "Request_Date" : string; "Label" : string; "ship_method" : string; "material" : string; "NTBillable" : string;}> = [];        
            if(response.success)
            {
                response.recordset.forEach(OrdApproval);
                function OrdApproval(item: {total_qty : string,  billable : string, Order_ID : string, Name: string, ShipToName: string, Requestor: string, RequestAlias: string, Request_Date: string, Label: string, ship_method: string, material : string, NTBillable : string} , _index: number)
                {                         
                    jsonOrderApproval.push({"total_qty": item.total_qty, "billable": item.billable, "Order_ID": item.Order_ID,"Name": item.Name, "ShipToName" : item.ShipToName, "Requestor": item.Requestor, "RequestAlias" : item.RequestAlias, "Request_Date" : item.Request_Date, "Label" : item.Label, "ship_method" : item.ship_method, "material" : item.material, "NTBillable" : item.NTBillable })                  
                }

                return JSON.parse(JSON.stringify(jsonOrderApproval).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonOrderApproval).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getApproverHistoryList(alias : string,start_date : string)
    {
        var url = this.variables.Url + this.variables.Controller.orderApproval + this.variables.orderApprovalService.getApproverHistoryList;            
        var data = {
            "alias": alias,               
            "startDate":start_date               
        } 
        return this.serviceProxy.get(url,data) 
        .then(response => {  
            var jsonApproverHistory : Array<{ "order_id": string;"request_date":string;"ShipToName":string;"Name":string;"statusdesc" :string;"billable": boolean;"material":boolean}> = [];
            if(response.success){                   
                response.recordset.forEach(list);                   
                function list(item: { order_id:string;request_date:string;ShipToName:string;Name:string;statusdesc:string;billable:boolean;material:boolean }, _index: number){
                        jsonApproverHistory.push({"order_id": item.order_id,"request_date": item.request_date,"ShipToName": item.ShipToName,"Name": item.Name,"statusdesc": item.statusdesc,"billable":item.billable,"material":item.material})
                }
                return JSON.parse(JSON.stringify(jsonApproverHistory).replace(/"\s+|\s+"/g, '"'));
            }
            else {                                    
                this.response.msg = response.message.toString();
                this.response.success = false;
                return JSON.parse(JSON.stringify(jsonApproverHistory).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

}