import React, { Component } from "react";
import { History, LocationState } from "History";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Alert,
  Button,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import "../../../assets/css/AdminLayout.css";
import { FormInputs } from "../../CustomComponents/FormInput";
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { FormLabelField } from "../../CustomComponents/FormLabelField";
import jsonState from "../../../json/State.json";
import { CommonController } from "../../../Controllers/CommonController";
import { EditUserController } from "../../../Controllers/EditUserController";
import { RegisterParameter, Istate } from "../../../Model/Register";
import "../../../assets/css/Style.css";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";
import { alert } from "react-bootstrap-confirmation";

export class EditUser extends Component<RegisterParameter, Istate> {
  redBorder: string;
  defaultBorder: string;
  constructor(props: RegisterParameter) {
    super(props);
    const userLogin = sessionStorage.getItem("userLogin");
    let loggedIn = true;
    if (userLogin == null) {
      loggedIn = false;
    }
    //Change Border color Red, if alert triggered in onBlur Event
    this.redBorder = "1px solid #DC3545";
    this.defaultBorder = "1px solid #E3E3E3";
    this.state = {
      fields: {
        uid: "",
        user_full_name: "",
        user_first_name: "",
        user_last_name: "",
        password: "",
        confirm: "",
        AddressLineOne: "",
        AddressLinetwo: "",
        city: "",
        zip: "",
        email: "",
        phone: "",
        customerNumber: "",
        BusUnit: "",
        OS: "",
        Browser: "",
        labelName: "",
      },
      selectfields: {
        recordLabelId: "",
        recordLabelName: "",
        userTypeId: "",
        userTypeName: "",
        department: "",
        departmentName: "",
        process: "",
        processName: "",
        state: "",
        stateName: "",
      },
      checkfields: {
        multiple_select: false,
        super_user: false,
        executive: false,
      },
      errors: {
        user_first_name: "",
        user_last_name: "",
        AddressLineOne: "",
        city: "",
        zip: "",
        email: "",
        phone: "",
        processName: "",
        UserTypeName: "",
        recordLabelName: "",
        department: "",
        stateName: "",
        Name: "",
      },
      Department: [],
      Process: [],
      Recordlabel: [],
      UserType: [],
      State: [],
      errMsg: "",
      loginFailed: 0,
      loading: false,
      loggedIn,
      modal: { modalSpinnerShow: false },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }
  componentWillMount() {
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({ modal });
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let checkfields = this.state.checkfields;
    let editUserController = new EditUserController();
    let uid = "";
    if (this.props.location.state.orgin === "V") {
      uid = this.props.location.state.vuid;
    } else {
      uid = this.props.location.state.uid;
    }
    editUserController
      .getUser(uid)
      .then((response) => {
        var splitName = response.Name.split(" ");
        fields["uid"] = response.Alias;
        fields["user_first_name"] = splitName[0];
        fields["user_last_name"] = splitName[1];
        fields["password"] = response.password || "";
        fields["confirm"] = response.password || "";
        fields["AddressLineOne"] = response.AddressLineOne || "";
        fields["AddressLinetwo"] = response.AddressLineTwo || "";
        fields["city"] = response.City || "";
        fields["zip"] = response.Zip || "";
        fields["email"] = response.Email || "";
        fields["phone"] = response.Phone || "";
        fields["customerNumber"] = response.CustomerNumber || "";
        fields["BusUnit"] = response.BusinessUnit || "";
        fields["Browser"] = response.Browser || "";
        fields["OS"] = response.OS || "";
        fields["labelName"] = response.LabelName || "";
        selectfields["recordLabelId"] = response.RecordLabel_Id.trim() || "";
        selectfields["department"] = response.Department.trim() || "";
        selectfields["userTypeId"] = response.UserType_Id.trim() || "";
        selectfields["process"] = response.Process_Name.trim() || "";
        selectfields["state"] = response.State.trim() || "";
        checkfields["multiple_select"] = Boolean(
          Number(response.Multiple_Select)
        );
        checkfields["super_user"] = Boolean(Number(response.Is_Super_User));
        checkfields["executive"] = Boolean(Number(response.Is_Executive));

        this.setState({
          fields,
          selectfields,
          checkfields,
        });
        let commonController = new CommonController();
        commonController
          .getAllLabel()
          .then((jsonLabel) => {
            this.setState({
              Recordlabel: JSON.parse(JSON.stringify(jsonLabel)),
            });
          })
          .catch((error) => {
            console.error("There was an error loading operating company");
          });
        editUserController
          .getUserType()
          .then((jsonUserType) => {
            this.setState({
              UserType: JSON.parse(JSON.stringify(jsonUserType)),
            });
          })
          .catch((error) => {
            console.error("There was an error loading user type");
          });
        commonController
          .getAllDeptForLabel(this.state.selectfields.recordLabelId)
          .then((jsonDept) => {
            this.setState({
              Department: JSON.parse(JSON.stringify(jsonDept)),
            });
          })
          .catch((error) => {
            console.error("There was an error loading department");
          });
        editUserController
          .getProcessName(this.state.selectfields.recordLabelId)
          .then((jsonProcessName) => {
            this.setState({
              Process: JSON.parse(JSON.stringify(jsonProcessName)),
            });
            modal["modalSpinnerShow"] = false;
            this.setState({ modal });
          })
          .catch((error) => {
            console.error("There was an error loading process");
          });
      })
      .catch((error) => {
        console.error("There was an error");
      });

    this.setState({
      State: JSON.parse(JSON.stringify(jsonState.result)),
    });
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    if (e.currentTarget.value.trim().length > 0) {
      fields[e.currentTarget.name] = e.currentTarget.value;
    } else {
      fields[e.currentTarget.name] = e.currentTarget.value.trim();
    }
    this.setState({
      fields,
    });
  };

  handleDeleteClick = () => {
    let editUserController = new EditUserController();
    editUserController
      .DeleteUser(this.state.fields.uid)
      .then((response) => {
        if (response.success) {
          this.setState({ loading: false });
          alert("User Profile Deleted.");
          this.props.history.push("/admin/home");
        }
      })
      .catch((error) => {
        this.setState({
          errMsg: "Update failed",
          loading: false,
        });
      });
  };

  handleBlur(e: any) {
    e.preventDefault();
    const target = e.currentTarget;
    target.style.border = this.defaultBorder;
    if (
      e.currentTarget.name === "password" &&
      e.currentTarget.value.length > 0
    ) {
      var regexSpl = /[!`@#$%^&+\=*?_~]/g;
      var regexUpper = /[A-Z]/g;
      var regexLower = /[a-z]/g;
      var regexNum = /[0-9]/g;
      if (e.currentTarget.value.length < 8) {
        alert("Password should contain atleast 8 character");
        target.style.border = this.redBorder;
        this.setFocus(target);
      } else if (!regexUpper.test(e.currentTarget.value)) {
        alert("Password should contain atleast one upper case");
        target.style.border = this.redBorder;
        this.setFocus(target);
      } else if (!regexLower.test(e.currentTarget.value)) {
        alert("Password should contain atleast one lower case");
        target.style.border = this.redBorder;
        this.setFocus(target);
      } else if (!regexNum.test(e.currentTarget.value)) {
        alert("Password should contain atleast one number");
        target.style.border = this.redBorder;
        this.setFocus(target);
      } else if (!regexSpl.test(e.currentTarget.value)) {
        alert("Password should contain atleast one special character");
        target.style.border = this.redBorder;
        this.setFocus(target);
      }
    }
    if (
      e.currentTarget.name === "confirm" &&
      e.currentTarget.value.length > 0
    ) {
      if (e.currentTarget.value !== this.state.fields.password) {
        alert("Password do not match");
        target.style.border = this.redBorder;
        this.setFocus(target);
      }
    }

    if (e.currentTarget.name === "zip" && e.currentTarget.value.length > 0) {
      var valid = "0123456789-";
      var hyphencount = 0;
      var field = e.currentTarget.value;

      if (field.length !== 5 && field.length !== 10) {
        alert("Please enter your 5 digit or 5+4 digit zip code.");
        target.style.border = this.redBorder;
        this.setFocus(target);
      }
      for (var i = 0; i < field.length; i++) {
        var temp = "" + field.substring(i, i + 1);
        if (temp == "-") hyphencount++;
        if (valid.indexOf(temp) == -1) {
          alert("Invalid characters in your zip code.  Please try again.");
          target.style.border = this.redBorder;
          this.setFocus(target);
          return false;
        }
        if (
          hyphencount > 1 ||
          (field.length == 10 && "" + field.charAt(5) != "-")
        ) {
          alert(
            "The hyphen character should be used with a properly formatted 5 digit+four zip code; example: 12345-6789.   Please try again."
          );
          this.setFocus(target);
          target.style.border = this.redBorder;
          return false;
        }
      }
    }

    if (e.currentTarget.name === "email" && e.currentTarget.value.length > 0) {
      var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regexEmail.test(e.currentTarget.value)) {
        alert("Plesae enter the valid email address");
        target.style.border = this.redBorder;
        this.setFocus(target);
      }
    }

    if (e.currentTarget.name === "phone" && e.currentTarget.value.length > 0) {
      var regexPhone =
        /^[(][1-9][0-9][0-9][)][ ][1-9][0-9][0-9][-][0-9][0-9][0-9][0-9]$/;
      if (!regexPhone.test(e.currentTarget.value)) {
        alert(
          "Please enter phone number in the following format:(123) 456-7890"
        );
        target.style.border = this.redBorder;
        this.setFocus(target);
      }
    }
  }

  setFocus(target: any) {
    //setTimeout(function () { target.focus(); },5);
  }
  handleSelectChange = (e: any) => {
    let selectfields = this.state.selectfields;
    
    selectfields[e.currentTarget.name] = e.currentTarget.value;
    if (e.currentTarget.name === "recordLabelId") {
      selectfields["recordlabelName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
      this.setState({
        Department: [],
        Process: [],
      });
      let commonController = new CommonController();
      commonController
        .getAllDeptForLabel(
          e.currentTarget.options[e.currentTarget.selectedIndex].value
        )
        .then((jsonDept) => {
          this.setState({
            Department: JSON.parse(JSON.stringify(jsonDept)),
          });
        })
        .catch((error) => {
          console.error("There was an error loading department");
        });
      let editUserController = new EditUserController();
      editUserController
        .getProcessName(
          e.currentTarget.options[e.currentTarget.selectedIndex].value
        )
        .then((jsonProcessName) => {
          this.setState({
            Process: JSON.parse(JSON.stringify(jsonProcessName)),
          });
          
        })
        .catch((error) => {
          console.error("There was an error loading process");
        });
    }

    if (e.currentTarget.name === "department") {
      let editUserController = new EditUserController();

      selectfields["departmentName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;

      var data = {
        recordLabelId: selectfields.recordLabelId,
        department: selectfields.department,
      };

      editUserController
        .getDeptProcessInfo(data)
        .then((res) => {
          let fields = this.state.fields;
          selectfields["process"] = res.processName;
          fields["customerNumber"] = res.customerNumber;
          fields["BusUnit"] = res.businessUnit;
          this.setState({ fields, selectfields });
        })
        .catch((error) => {
          console.error("There was an error loading department");
        });
    }

    if (e.currentTarget.name === "userTypeId") {
      selectfields["userTypeName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
    }
    if (e.currentTarget.name === "process") {
      selectfields["processName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
    }
    if (e.currentTarget.name === "state") {
      selectfields["stateName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
    }
    this.setState({
      selectfields,
    });
  };
  handleCheckChange = (e: any) => {
    let checkfields = this.state.checkfields;
    if (e.currentTarget.name === "multiple_select") {
      checkfields[e.currentTarget.name] =
        !this.state.checkfields.multiple_select;
    } else if (e.currentTarget.name === "super_user") {
      checkfields[e.currentTarget.name] = !this.state.checkfields.super_user;
    } else if (e.currentTarget.name === "executive") {
      checkfields[e.currentTarget.name] = !this.state.checkfields.executive;
    } else {
      checkfields[e.currentTarget.name] = e.currentTarget.value;
    }
    this.setState({
      checkfields,
    });
  };
  clearError() {
    let errors = this.state.errors;
    errors["UserTypeName"] = "";
    errors["user_first_name"] = "";
    errors["user_last_name"] = "";
    errors["Name"] = "";
    errors["password"] = "";
    errors["confirm"] = "";
    errors["AddressLineOne"] = "";
    errors["city"] = "";
    errors["zip"] = "";
    errors["email"] = "";
    errors["phone"] = "";
    errors["recordLabelName"] = "";
    errors["department"] = "";
    errors["processName"] = "";
    errors["state"] = "";
    errors["stateName"] = "";
    this.setState({
      errors,
    });
  }
  onSubmit = (e: any) => {
    
    this.setState({ loading: true });
    e.preventDefault();
    if (this.ValidateProfile()) {
      if (sessionStorage.getItem("usertype_id") === "S") {
        var updateSysopUserData = {
          username: this.state.fields.uid,
          userTypeId: this.state.selectfields.userTypeId,
          processName: this.state.selectfields.process,
          firstName: this.state.fields.user_first_name,
          lastName: this.state.fields.user_last_name,
          email: this.state.fields.email,
          recordLabelId: this.state.selectfields.recordLabelId,
          department: this.state.selectfields.department,
          addressLineOne: this.state.fields.AddressLineOne,
          addressLineTwo: this.state.fields.AddressLinetwo,
          city: this.state.fields.city,
          state: this.state.selectfields.state,
          zip: this.state.fields.zip,
          phone: this.state.fields.phone,
          customerNumber: this.state.fields.customerNumber,
          businessUnit: this.state.fields.BusUnit,
          multipleSelect: this.state.checkfields.multiple_select,
          isExecutiveUser: this.state.checkfields.executive,
          isSuperUser: this.state.checkfields.super_user,
        };
        let editUserController = new EditUserController();
        editUserController
          .UpdateSysopUser(updateSysopUserData)
          .then((response) => {
            if (this.props.location.state.orgin != "V") {
              sessionStorage.setItem("uid", this.state.fields.uid);
              sessionStorage.setItem(
                "usertype_id",
                this.state.selectfields.userTypeId
              );
              sessionStorage.setItem(
                "user_full_name",
                this.state.fields.user_first_name +
                  " " +
                  this.state.fields.user_last_name
              );
              sessionStorage.setItem(
                "addressLineOne",
                this.state.fields.AddressLineOne
              );
              sessionStorage.setItem(
                "addressLineTwo",
                this.state.fields.AddressLinetwo
              );
              sessionStorage.setItem("city", this.state.fields.city);
              sessionStorage.setItem("state", this.state.selectfields.state);
              sessionStorage.setItem("zip", this.state.fields.zip);
              sessionStorage.setItem("phone", this.state.fields.phone);
              sessionStorage.setItem("email", this.state.fields.email);
              sessionStorage.setItem("label_name", this.state.fields.labelName);
              sessionStorage.setItem(
                "process_name",
                this.state.selectfields.process
              );
              sessionStorage.setItem(
                "label_id",
                this.state.selectfields.recordLabelId
              );
              sessionStorage.setItem(
                "department",
                this.state.selectfields.department
              );
              sessionStorage.setItem(
                "user_label_id",
                this.state.selectfields.recordLabelId
              );
              sessionStorage.setItem(
                "user_department",
                this.state.selectfields.department
              );
              sessionStorage.setItem(
                "business_unit",
                this.state.fields.BusUnit
              );
              sessionStorage.setItem(
                "CustomerNumber",
                this.state.fields.customerNumber
              );
              sessionStorage.setItem(
                "is_super_user",
                this.state.checkfields.super_user ? "1" : "0"
              );
              sessionStorage.setItem(
                "is_executive",
                this.state.checkfields.executive ? "1" : "0"
              );
              sessionStorage.setItem(
                "multiple_select",
                this.state.checkfields.multiple_select ? "1" : "0"
              );
            }
            this.setState({ loading: false });
            alert("User Profile Updated.");
            this.props.history.push("/admin/home");
          })
          .catch((error) => {
            this.setState({
              errMsg: "Update failed",
              loading: false,
            });
          });
      } else {
        var updateUserData: any = {
          username: this.state.fields.uid,
          firstName: this.state.fields.user_first_name,
          lastName: this.state.fields.user_last_name,
          email: this.state.fields.email,
          addressLineOne: this.state.fields.AddressLineOne,
          addressLineTwo: this.state.fields.AddressLinetwo,
          city: this.state.fields.city,
          state: this.state.selectfields.state,
          zip: this.state.fields.zip,
          phone: this.state.fields.phone,
          multipleSelect: this.state.checkfields.multiple_select,
        };
        let editUserController = new EditUserController();
        editUserController
          .UpdateUser(updateUserData)
          .then((response) => {
            if (this.props.location.state.orgin != "V") {
              sessionStorage.setItem("uid", this.state.fields.uid);
              sessionStorage.setItem(
                "user_full_name",
                this.state.fields.user_first_name +
                  " " +
                  this.state.fields.user_last_name
              );
              sessionStorage.setItem(
                "addressLineOne",
                this.state.fields.AddressLineOne
              );
              sessionStorage.setItem(
                "addressLineTwo",
                this.state.fields.AddressLinetwo
              );
              sessionStorage.setItem("city", this.state.fields.city);
              sessionStorage.setItem("state", this.state.selectfields.state);
              sessionStorage.setItem("zip", this.state.fields.zip);
              sessionStorage.setItem("phone", this.state.fields.phone);
              sessionStorage.setItem("email", this.state.fields.email);
              sessionStorage.setItem(
                "multiple_select",
                this.state.checkfields.multiple_select ? "1" : "0"
              );
            }
            this.setState({ loading: false });
            alert("User Profile Updated.");
            this.props.history.push("/admin/home");
          })
          .catch((error) => {
            this.setState({
              errMsg: "Update failed",
              loading: false,
            });
          });
      }
    }
    //this.setState({ loading: false });
  };

  ValidateProfile() {
    this.clearError();
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let errors = this.state.errors;
    let formIsValid = true;
    if (!fields["user_first_name"]) {
      formIsValid = false;
      errors["user_first_name"] = "The First Name field is empty.";
    }
    if (!fields["user_last_name"]) {
      formIsValid = false;
      errors["user_last_name"] = "The Last Name field is empty.";
    }
    if (!fields["AddressLineOne"]) {
      formIsValid = false;
      errors["AddressLineOne"] = "The Address Line One field is empty.";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "The City field is empty.";
    }
    if (!fields["zip"]) {
      formIsValid = false;
      errors["zip"] = "The Zip field is empty.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "The Work Email field is empty.";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "The Work Phone field is empty.";
    }
    if (fields["recordLabelName"] === "Select Your Operating Company") {
      formIsValid = false;
      errors["recordLabelName"] = "You must select an Operating Company.";
    }
    if (selectfields["departmentName"] === "Select Department") {
      formIsValid = false;
      errors["departmentName"] = "You must select a Department.";
    }
    if (selectfields["state"] === "Select A State") {
      formIsValid = false;
      errors["state"] = "You must select a State.";
    }
    if (sessionStorage.getItem("usertype_id") === "S") {
      if (selectfields["processName"] === "Select Process") {
        formIsValid = false;
        errors["processName"] = "You must select a Process Name.";
      }
      if (selectfields["userTypeName"] === "Select User Type") {
        formIsValid = false;
        errors["userTypeName"] = "You must select User Type.";
      }
    }

    if (formIsValid && fields["zip"].length > 0) {
      var valid = "0123456789-";
      var hyphencount = 0;
      var field = fields["zip"];

      if (field.length !== 5 && field.length !== 10) {
        alert("Please enter your 5 digit or 5+4 digit zip code.");
        formIsValid = false;
      }
      for (var i = 0; i < field.length; i++) {
        var temp = "" + field.substring(i, i + 1);
        if (temp == "-") hyphencount++;
        if (valid.indexOf(temp) == -1) {
          alert("Invalid characters in your zip code.  Please try again.");
          formIsValid = false;
          return false;
        }
        if (
          hyphencount > 1 ||
          (field.length == 10 && "" + field.charAt(5) != "-")
        ) {
          alert(
            "The hyphen character should be used with a properly formatted 5 digit+four zip code; example: 12345-6789.   Please try again."
          );
          formIsValid = false;
          return false;
        }
      }
    }

    if (formIsValid && fields["email"].length > 0) {
      var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regexEmail.test(fields["email"])) {
        alert("Plesae enter the valid email address");
        formIsValid = false;
      }
    }

    if (formIsValid && fields["phone"].length > 0) {
      var regexPhone =
        /^[(][1-9][0-9][0-9][)][ ][1-9][0-9][0-9][-][0-9][0-9][0-9][0-9]$/;
      if (!regexPhone.test(fields["phone"])) {
        alert(
          "Please enter phone number in the following format:(123) 456-7890"
        );
        formIsValid = false;
      }
    }
    if (formIsValid && this.props.location.state.orgin != "V") {
      //Password Validation
      var errMsgPassword =
        "Your Current password is not adhere to UMG Password Policy. Please change your password first before edit the profile";
      if (fields["password"].length > 0 && formIsValid) {
        var regexSpl = /[!`@#$%^&+\=*?_~]/g;
        var regexUpper = /[A-Z]/g;
        var regexLower = /[a-z]/g;
        var regexNum = /[0-9]/g;
        if (fields["password"].length < 8) {
          //alert("Password should contain atleast 8 character")
          formIsValid = false;
          this.setState({ errMsg: errMsgPassword });
        } else if (!regexUpper.test(fields["password"])) {
          //alert("Password should contain atleast one upper case")
          formIsValid = false;
          this.setState({ errMsg: errMsgPassword });
        } else if (!regexLower.test(fields["password"])) {
          //alert("Password should contain atleast one lower case")
          formIsValid = false;
          this.setState({ errMsg: errMsgPassword });
        } else if (!regexNum.test(fields["password"])) {
          //alert("Password should contain atleast one number")
          formIsValid = false;
          this.setState({ errMsg: errMsgPassword });
        } else if (!regexSpl.test(fields["password"])) {
          //alert("Password should contain atleast one special character")
          formIsValid = false;
          this.setState({ errMsg: errMsgPassword });
        }
      }
      if (fields["confirm"].length > 0) {
        if (fields["confirm"] !== fields["password"]) {
          //
          formIsValid = false;
          this.setState({ errMsg: errMsgPassword });
        }
      }
    }

    if (!formIsValid) {
      this.setState({ loading: false });
    }
    this.setState({
      errors,
    });
    return formIsValid;
  }

  displayDeleteButton = () => {
    if (sessionStorage.getItem("usertype_id") === "S") {
      return (
        <Button
          type="button"
          style={{ marginLeft: "5px" }}
          onClick={this.handleDeleteClick.bind(this)}
        >
          Delete
        </Button>
      );
    }
  };

  render() {
    const { loading } = this.state;
    const bottonAlignCenter = {
      "text-align": "-webkit-center",
    };
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const displayOperatingCompanyOptionforUserTypeS = () => {
      if (sessionStorage.getItem("usertype_id") === "S") {
        return (
          <Row>
            <Col sm={4}>
              <FormSelect
                properties={{
                  label: "Operating Company",
                  id: "recordLabelId",
                  name: "recordLabelId",
                  value: this.state.selectfields.recordLabelId,
                  className: "form-control",
                  onChange: this.handleSelectChange,
                }}
                fieldRequired={true}
                errorMsg={this.state.errors.recordLabelId}
                defaultOption="Select Your Operating Company"
                fillOption={this.state.Recordlabel}
              />
            </Col>
            <Col sm={4}>
              <FormSelect
                properties={{
                  label: "Department",
                  id: "department",
                  name: "department",
                  value: this.state.selectfields.department,
                  className: "form-control",
                  onChange: this.handleSelectChange,
                }}
                fieldRequired={true}
                errorMsg={this.state.errors.department}
                defaultOption="Select Department"
                fillOption={this.state.Department}
              />
            </Col>
            <Col sm={4}></Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col sm={4}>
              <FormLabelField
                properties={{
                  label: "Operating Company",
                  id: "recordLabelId",
                  name: "recordLabelId",
                  className: "form-control",
                  value: this.state.fields.labelName,
                }}
              />
            </Col>
            <Col sm={4}>
              <FormLabelField
                properties={{
                  label: "Department",
                  id: "department",
                  name: "department",
                  className: "form-control",
                  value: sessionStorage.getItem("department"),
                }}
              />
            </Col>
            <Col sm={4}></Col>
          </Row>
        );
      }
    };
    const displayUserTypeS = () => {
      if (sessionStorage.getItem("usertype_id") === "S") {
        return (
          <div>
            <Row>
              <Col sm={4}>
                <FormSelect
                  properties={{
                    label: "User Type",
                    id: "userTypeId",
                    name: "userTypeId",
                    value: this.state.selectfields.userTypeId,
                    className: "form-control",
                    onChange: this.handleSelectChange,
                  }}
                  fieldRequired={false}
                  errorMsg={this.state.errors.UserType}
                  defaultOption="Select User Type"
                  fillOption={this.state.UserType}
                />
              </Col>
              <Col sm={4}>
                <FormSelect
                  properties={{
                    label: "Process Name",
                    id: "process",
                    name: "process",
                    value: this.state.selectfields.process,
                    className: "form-control",
                    onChange: this.handleSelectChange,
                  }}
                  fieldRequired={false}
                  defaultOption="Select Process Name"
                  fillOption={this.state.Process}
                />
              </Col>
              <Col sm={4}></Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormInputs
                  properties={{
                    id: "customerNumber",
                    label: "Customer Number",
                    type: "text",
                    name: "customerNumber",
                    className: "form-control input-sm",
                    maxlength: 10,
                    value: this.state.fields.customerNumber,
                    onChange: this.handleChange,
                  }}
                  fieldRequired={false}
                />
              </Col>
              <Col sm={4}>
                <FormInputs
                  properties={{
                    label: "Business Unit",
                    id: "BusUnit",
                    name: "BusUnit",
                    value: this.state.fields.BusUnit,
                    type: "text",
                    className: "form-control",
                    maxlength: 30,
                    onChange: this.handleChange,
                  }}
                  fieldRequired={false}
                />
              </Col>
              <Col sm={4}></Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormLabelField
                  properties={{
                    label: "OS",
                    id: "OS",
                    name: "OS",
                    className: "form-control",
                    value: this.state.fields.OS,
                  }}
                />
              </Col>
              <Col sm={4}>
                <FormLabelField
                  properties={{
                    label: "Browser",
                    id: "Browser",
                    name: "Browser",
                    className: "form-control",
                    value: this.state.fields.Browser,
                  }}
                />
              </Col>
              <Col sm={4}></Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormCheckBox
                  properties={{
                    label: "Super User",
                    id: "super_user",
                    name: "super_user",
                    type: "checkbox",
                    className: "form-form-control-md",
                    onChange: this.handleCheckChange,
                    checked: this.state.checkfields.super_user,
                  }}
                />
              </Col>
              <Col sm={4}>
                <FormCheckBox
                  properties={{
                    label: "Executive User",
                    id: "executive",
                    name: "executive",
                    type: "checkbox",
                    className: "form-control-md",
                    onChange: this.handleCheckChange,
                    checked: this.state.checkfields.executive,
                  }}
                />
              </Col>
              <Col sm={4}></Col>
            </Row>
            {this.props.location.state.orgin === "V" ? (
              <Row>
                <Col sm={12}>
                  <div className="text-dark font-size-xs">
                    When deactivating a User, change password to 'removed',
                    delete email address, change Customer Number to 'Del' and
                    Business Unit to 0
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        );
      } else {
        return <Col sm={12}></Col>;
      }
    };
    return (
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <Container fluid>
          <Row>
            <Col sm={12} className="small">
              <Card border="light" style={{ width: "100%", height: "auto" }}>
                <Card.Body>
                  <Card.Title>
                    <h4 className="title">Edit Profile</h4>
                  </Card.Title>
                  <Form onSubmit={this.onSubmit}>
                    <Alert
                      variant="danger"
                      style={{
                        display: this.state.errMsg ? "inherit" : "none",
                      }}
                    >
                      {this.state.errMsg}
                    </Alert>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "user_first_name",
                                label: "First Name",
                                type: "text",
                                name: "user_first_name",
                                className: "form-control input-sm",
                                maxlength: 24,
                                value: this.state.fields.user_first_name,
                                onChange: this.handleChange,
                              }}
                              errorMsg={this.state.errors.user_first_name}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "user_last_name",
                                label: "Last Name",
                                type: "text",
                                name: "user_last_name",
                                className: "form-control input-sm",
                                maxlength: 24,
                                value: this.state.fields.user_last_name,
                                onChange: this.handleChange,
                              }}
                              errorMsg={this.state.errors.user_last_name}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={4}></Col>
                        </Row>
                        {/* <Row>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "password",
                                label: "Password",
                                type: "password",
                                name: "password",
                                className: "form-control input-sm",
                                value: this.state.fields.password,
                                onChange: this.handleChange,
                                disabled: true,
                              }}
                              fieldRequired={false}
                            />
                          </Col>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "confirm",
                                label: "Confirm Password",
                                type: "password",
                                name: "confirm",
                                className: "form-control input-sm",
                                value: this.state.fields.confirm,
                                onChange: this.handleChange,
                                disabled: true,
                              }}
                              fieldRequired={false}
                            />
                          </Col>
                          <Col sm={4} className="align-self-center">
                            <div className="text-danger font-size-xs small">
                              Password Policy: Minimum of 8 character and max of
                              15. At least one Upper case and small case. At
                              least one number. At least one special character
                              (~, `, !, @, #, $, %, ^, &, *, (, ), +, =, \).
                            </div>
                          </Col>
                        </Row> */}
                        {displayOperatingCompanyOptionforUserTypeS()}
                        {/*{displayDepartmentOptionforUserTypeS()}*/}
                        <Row>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "AddressLineOne",
                                label: "Work Address 1",
                                type: "text",
                                name: "AddressLineOne",
                                className: "form-control input-sm",
                                maxlength: 30,
                                value: this.state.fields.AddressLineOne,
                                onChange: this.handleChange,
                              }}
                              errorMsg={this.state.errors.AddressLineOne}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "AddressLinetwo",
                                label: "Work Address 2",
                                type: "text",
                                name: "AddressLinetwo",
                                className: "form-control input-sm",
                                maxlength: 30,
                                value: this.state.fields.AddressLinetwo,
                                onChange: this.handleChange,
                              }}
                              fieldRequired={false}
                            />
                          </Col>
                          <Col sm={4} className="align-self-center">
                            <div className="text-danger font-size-xs small">
                              Please note that field is limited to 30 characters
                              to prevent address from not displaying properly on
                              address labels.{" "}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={3}>
                            <FormInputs
                              properties={{
                                id: "city",
                                label: "City",
                                type: "text",
                                name: "city",
                                className: "form-control input-sm",
                                maxlength: 50,
                                value: this.state.fields.city,
                                onChange: this.handleChange,
                              }}
                              errorMsg={this.state.errors.city}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={3}>
                            <FormSelect
                              properties={{
                                label: "State",
                                id: "state",
                                name: "state",
                                value: this.state.selectfields.state,
                                className: "form-control",
                                onChange: this.handleSelectChange,
                              }}
                              fieldRequired={true}
                              defaultOption="Select A State"
                              fillOption={this.state.State}
                              errorMsg={this.state.errors.state}
                            />
                          </Col>
                          <Col sm={2}>
                            <FormInputs
                              properties={{
                                id: "zip",
                                label: "Zip",
                                type: "text",
                                name: "zip",
                                className: "form-control input-sm",
                                maxlength: 10,
                                value: this.state.fields.zip,
                                onChange: this.handleChange,
                                onBlur: this.handleBlur,
                              }}
                              errorMsg={this.state.errors.zip}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={4}></Col>
                        </Row>
                        <Row>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "email",
                                label: "Work Email",
                                type: "email",
                                name: "email",
                                className: "form-control input-sm",
                                maxlength: 50,
                                value: this.state.fields.email,
                                onChange: this.handleChange,
                                onBlur: this.handleBlur,
                              }}
                              errorMsg={this.state.errors.email}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={4}>
                            <FormInputs
                              properties={{
                                id: "phone",
                                label: "Work Phone",
                                type: "text",
                                name: "phone",
                                className: "form-control input-sm",
                                maxlength: 35,
                                value: this.state.fields.phone,
                                onChange: this.handleChange,
                                onBlur: this.handleBlur,
                              }}
                              errorMsg={this.state.errors.phone}
                              fieldRequired={true}
                            />
                          </Col>
                          <Col sm={4} className="align-self-center">
                            <div className="text-danger font-size-xs small">
                              example: (123) 456-7890
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <FormCheckBox
                              properties={{
                                label:
                                  "Check if you wish to make multiple selections in search result lists",
                                id: "multiple_select",
                                name: "multiple_select",
                                type: "checkbox",
                                className: "form-control-md",
                                onChange: this.handleCheckChange,
                                checked: this.state.checkfields.multiple_select,
                              }}
                            />
                          </Col>
                          <Col sm={4}></Col>
                        </Row>
                        {displayUserTypeS()}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        sm={12}
                        style={{ textAlign: "center", alignSelf: "center" }}
                      >
                        <Button type="submit" disabled={loading}>
                          {loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px", color: "#3472F7" }}
                            />
                          )}
                          {loading && (
                            <span style={{ color: "#3472F7" }}>wait...</span>
                          )}
                          {!loading && <span>Update</span>}
                        </Button>
                        {this.props.location.state.orgin === "V" ? (
                          <Button
                            type="button"
                            style={{ marginLeft: "5px" }}
                            onClick={this.handleDeleteClick.bind(this)}
                          >
                            Delete
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <div className="clearfix" />
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
