export class DateTimeFormat {
    dtsnow = new Date();
    getDate(){
        let dd : string = ("0" + (this.dtsnow.getDate())).slice(-2);
        let mm : string = ("0" + (this.dtsnow.getMonth() + 1)).slice(-2);
        let yy : string =  this.dtsnow.getFullYear().toString();
        return (yy + "-" + mm + "-" + dd)
    }
    getTime(){
        let hh : string = ("0" + (this.dtsnow.getHours())).slice(-2); 
        let nn : string = ("0" + (this.dtsnow.getMinutes())).slice(-2); 
        let ss : string = ("0" + (this.dtsnow.getSeconds())).slice(-2);
        return (hh + ":" + nn + ":" + ss)
    }
    getDateTime(){
        return this.getDate() + " "  + this.getTime(); 
    }
}




    

    