import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//import "./assets/css/animate.min.css";
import "./assets/sass/eGratis-react.scss?v=1.3.0";
//import "./assets/css/demo.css";
//import "./assets/css/pe-icon-7-stroke.css";
//import App from './App';
import * as serviceWorker from './serviceWorker';
import {Login} from  '../src/Components/View/Login/Login'
import {AdminLayout} from '../src/Components/View/Layout/AdminLayout';
import {CreateProfile} from '../src/Components/View/Register/CreateProfile';
import {EmailPassword} from '../src/Components/View/EmailPassword/EmailPassword'; 
import {ChangePassword} from '../src/Components/View/PasswordReset/PasswordResetLogin';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={props => <Login {...props} />} />
      <Route path="/login" render={props => <Login {...props} />} />
      <Redirect from="/login" to="/admin/home" />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/createprofile" render={props => <CreateProfile {...props} />} />
      <Route path="/emailpassword" render={props => <EmailPassword {...props} />} />
      <Route path="/changePassword" render={props => <ChangePassword {...props} />} />
    </Switch>
  </BrowserRouter>,
  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
