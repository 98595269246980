import React, { Component,useEffect, useState } from "react";
import {History, LocationState} from "History";
import {Row,Col,Container, Card, Button, NavLink, Form, Alert} from "../../../../node_modules/react-bootstrap";
import {LoginNavBar} from '../../CustomComponents/NabvBar/LoginNavBar'
import {FormInputs} from '../../CustomComponents/FormInput'
import "../../../assets/css/LoginPage.css"
import {LoginController} from "../../../Controllers/LoginController"
import {ErrorBoundary} from "../../../Components/View/ErrorBoundary/ErrorBoundary";
import { LoginParameter, Istate } from "../../../Model/Login";
import { CreateOrderController } from "../../../Controllers/CreateOrderController";
import {alert} from 'react-bootstrap-confirmation';
//import { UserAgentApplication } from 'msal';
import{ UserAgentApplication } from 'msal'
import { config } from '../../../js/confiq';
import { useHistory } from 'react-router-dom';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {getUserProfile} from '../../../utils/MSUtils'
export class Login extends Component<LoginParameter,Istate> {
  constructor(props:LoginParameter){
    super(props)
    this.state={
      fields: { uid:'',pwd:'' },
      errors: { uid:'',pwd:'' },
      errMsg:"",
      loginFailed: 0,
      loading: false,
      modal: {
        modalSpinnerShow: false, 
      }
      
    }
    this.handleChange = this.handleChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
  }
  async componentWillMount(){
    
    // var userAgentApplication
    // userAgentApplication = new UserAgentApplication({
    //   auth: {
    //     clientId: config.clientId,
    //     authority:config.authority,
    //     redirectUri: config.redirectUri,
    //  //   postLogoutRedirectUri: config.postLogoutRedirectUri
    //   },
    //   cache: {
    //     cacheLocation: "sessionStorage",
    //     storeAuthStateInCookie: true
    //   }
    // });
  }
  handleChange(e:any){
    let fields= this.state.fields;
    fields[e.currentTarget.name] = e.currentTarget.value.trim();
    this.setState({ fields });
  }





  async login() { 

    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({modal});



    // console.log("step:1");
    var userAgentApplication;
    userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: config.clientId,
        authority:config.authority,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri
      },
      // cache: {
      //   cacheLocation: "sessionStorage",
      //   storeAuthStateInCookie: true
      // }
    });
    // console.log("step:2");


    try {
      await userAgentApplication.loginPopup(
      {
        scopes: config.scopes,
        prompt: "select_account"
      });
const user = await getUserProfile(userAgentApplication, config.scopes);
if(user.mail.includes('dxc.com')){
  this.setState({
    fields: {
      ...this.state.fields,
      uid: user.userPrincipalName,
      // uid:user.mail
    },
  });
}else{
  this.setState({
    fields: {
      ...this.state.fields,
      //uid: user.userPrincipalName,
       uid:user.mail
    },
  });

}





   
    
      try {
        // Attempt to get the authenticated user account
        const account = userAgentApplication.getAccount();

        if (account) {
          // User is authenticated, perform actions for authenticated user

          // console.log(this.state.fields,this.state,"this.state.fields")

          let loginController = new LoginController(this.state.fields.uid,this.state.fields.pwd, this.state.loginFailed);
          
          loginController.authenticateUserSSO().then((response : any) => { 
            // console.log(response,"response") 
            if (response.length > 0){
              modal["modalSpinnerShow"] = false;
              this.setState({modal});

              if (response.length == 1){
          this.handleUserClick(response[0])

              }else{

               this.props.history.push('/admin/selectRole');

              }


                   }
            else{

              console.log("No profile for this email. Please contact application services.");
          // Display a message to the user (you can set it in state and render it)
          this.setState({ errMsg: "You do not have access to eGratis. Please reach out to Application Support (applicationsupport@umusic.com) to request access." });
              modal["modalSpinnerShow"] = false;
              this.setState({modal});
            }
          }).catch(error => {
            modal["modalSpinnerShow"] = false;
        this.setState({modal});
            console.log(error);
          })




        } else {
          // User is not authenticated, perform actions for unauthenticated user
           console.log('User is not authenticated');
          // Your actions here
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    





  }

  catch (err) {
   
    };


  }



  handleUserClick = (user) => {
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({modal});
    //  this.selectedUser = user;
    
      let loginController = new LoginController(user.Alias,user.pwd,user.loginFailed);
     
      loginController.authenticateUserSSO1().then((response : any) => {
          if (response.success){
            sessionStorage.setItem("userLogin","success"); 
            
            //checking to enable side bars for FG, NT and BI
            let createOrderController = new CreateOrderController();
            createOrderController.getApprovalProcessValidate(user.Alias).then(response => {
              
              var result = (response.orderTypes === null)?'':response.orderTypes.trim();
              if (result != '')
              {
                sessionStorage.setItem("orderTypeValidate",result);
              }
              else
              {
                sessionStorage.setItem("orderTypeValidate",result);
              } 
            //redirect to actual page.
              if (sessionStorage.getItem("usertype_id")==='A'){
                this.props.history.push('/admin/order_approval_inbox');
              }
              else
              {
                this.props.history.push('/admin/home');
              }
            }).catch(error => {
              console.error("There was an error getApprovalProcessValidate")
            });   
          }
          else {
          //   this.setState({
          //    // errMsg : response.msg,
          //     loginFailed : response.loginFailed,
          //     loading: false
          //   });
            // if(response.changePassword){
            //   alert("Your Password Has been Expired.Its Time To Change")
            //   this.props.history.push('/changePassword')
            // }
          }
        }).catch(error => {
          console.log(error);
        })
  
  
  
  
  
  
  
  };









  render() {
    const { loading } = this.state;
    const alignCardCenter = {
      "margin": "10% 0 19% 0"
    };
    const changebgColor = {
      backgroundColor:"rgba(203, 203, 210, 0.15)"
    };
    return (
      <ErrorBoundary>
         <FormModalSpinner show={this.state.modal.modalSpinnerShow } /> 
      <div className="wrapper">
        <div id="main-panel" className="login-panel" ref="mainPanel">
          <LoginNavBar
            {...this.props}
            brandText={'eGratis Promotional Product Request'}
          />
          <div className="content" style={changebgColor}>
            <Container>
              <Row style={alignCardCenter}>
                <Col sm={1} md={3} lg={3}></Col>
                <Col sm={10} md={6} lg={6}>
                <div className="text-center mb-10 mb-lg-20"><h3 className="font-size-h1">eGratis now supports Single Sign On (SSO) login, Please login with your email and password</h3>
                  
                  </div>
              
                  <div className="text-center mb-10 mb-lg-20">
                      <Button type="submit" size="sm" disabled={loading}  variant="primary"  onClick={() => this.login()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px",color:"#6c757d" }}
                        />
                        )}
                        {loading && <span style={{color:"#6c757d"}}>wait...</span>}
                        {!loading && <span>Log In</span>}
                      </Button>
                     </div>
                     <div className="content" >

                     <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                     </div>

                <div style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#f8d7da', borderColor: '#f5c6cb', color: '#721c24', borderRadius: '.25rem' }}>
                {/* Access to eGratis has moved to a new URL. Single Sign On via UMG Azure is now available for a more secure way to access UMG data. Please update your bookmark and use the following URL going forward:
              <br />
            <a href="https://egratisprod-inter.umusic.com" target="_blank" rel="noopener noreferrer">
            eGratis new URL: https://egratisprod-inter.umusic.com </a>
            <br /> */}

            If you are a UMG partner accessing eGratis from outside the UMG Network you should have received an email with instructions on setting up access to the new URL. If you need support please contact  
            <br />
            <a href={`mailto:UMGLabelRequests@umusic.com`} target="_blank" rel="noopener noreferrer">
             UMGLabelRequests@umusic.com </a>
           </div>
                {/* <Card border="light" style={{ width: '100%', height:'auto' }}>
                  <Card.Body>
                    <Card.Title><h4 className="title">eGratis Version 8.2</h4></Card.Title>
                      <Form onSubmit = {this.onSubmit}>
                        <NavLink href={'/CreateProfile'} >New users click here</NavLink>
                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                        <FormInputs
                          properties={{id:"uid",label:"User Name",type:"text",name:"uid",value:this.state.fields.uid,className:"form-control input-sm",maxlength:12,onChange:this.handleChange}}
                          errorMsg={this.state.errors.uid}
                          fieldRequired={true}
                        />
                        <FormInputs
                          properties={{id:"pwd",label:"Password",type:"password",name:"pwd",value:this.state.fields.pwd,className:"form-control input-sm",maxlength:15,onChange:this.handleChange}}
                          errorMsg={this.state.errors.pwd}
                          fieldRequired={true}
                        />
                        <Button type="submit" disabled={loading} style={{float:"right"}}>
                          {loading && (
                          <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" , color:"#3472F7" }}
                          />
                          )}
                          {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                          {!loading && <span>Log In</span>}
                        </Button>
                        <NavLink href={'/emailpassword'} className="small">Forgot your password? click here</NavLink>
                        <div className="clearfix" />
                      </Form>
                    </Card.Body>                                  
                  </Card> */}
                </Col>
                <Col sm={1} md={3} lg={6}></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      </ErrorBoundary>
    );
  }
}