import React, { Component } from "react";
import {Container,Card,Row,Col,Form,Alert,Button,Table} from "../../../../node_modules/react-bootstrap";
import { CreateOrderController } from "../../../Controllers/CreateOrderController";
import {LoginController} from "../../../Controllers/LoginController"
import { Redirect } from 'react-router-dom';
import '../../../assets/css/faqPage.css';

import { withRouter } from 'react-router-dom';

import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';





export class SelectRole extends Component<IProps,Istate> {
    storedResponse: any[] = [];
    selectedUser: any = null;
    constructor(props){ 
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
    
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state = {
            loggedIn,
    modal: {
      modalSpinnerShow: false, 
    }
        }

     // Retrieve the string from sessionStorage
     const storedResponseString = sessionStorage.getItem("profiles")
     // Check if the retrieved value is not null
     if (storedResponseString !== null) {
       // Convert the string back to an array using JSON.parse
       this.storedResponse = JSON.parse(storedResponseString);
       // Now, storedResponse contains the original array
     } else {
       // Handle the case where the key is not found in sessionStorage
       console.error("No data found in sessionStorage for the key 'profiles'");
     }

      } 


    handleUserClick = (user) => {
      let modal = this.state.modal;
      modal["modalSpinnerShow"] = true;
      this.setState({modal});
        this.selectedUser = user;
      
        let loginController = new LoginController(user.Alias,user.pwd,user.loginFailed);
       
        loginController.authenticateUserSSO1().then((response : any) => {
            if (response.success){
              sessionStorage.setItem("userLogin","success"); 
              
              //checking to enable side bars for FG, NT and BI
              let createOrderController = new CreateOrderController();
              createOrderController.getApprovalProcessValidate(user.Alias).then(response => {
                
                var result = (response.orderTypes === null)?'':response.orderTypes.trim();
                if (result != '')
                {
                  sessionStorage.setItem("orderTypeValidate",result);
                }
                else
                {
                  sessionStorage.setItem("orderTypeValidate",result);
                } 
              //redirect to actual page.
                if (sessionStorage.getItem("usertype_id")==='A'){
                  this.props.history.push('/admin/order_approval_inbox');
                }
                else
                {
                  this.props.history.push('/admin/home');
                }
              }).catch(error => {
                console.error("There was an error getApprovalProcessValidate")
              });   
            }
            else {
            //   this.setState({
            //    // errMsg : response.msg,
            //     loginFailed : response.loginFailed,
            //     loading: false
            //   });
              // if(response.changePassword){
              //   alert("Your Password Has been Expired.Its Time To Change")
              //   this.props.history.push('/changePassword')
              // }
            }
          }).catch(error => {
            console.log(error);
          })
    
    
    
    
    
    
    
    };
















    render() {
        return (
          
          <div className="content">
              <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
                <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%', height:'650px' }}>
                        <div className="content">
        
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: '100%' }}>
                <div className="header"><h4 className="title">eGratis - Select User Profile</h4><p className="category"></p></div>
                <Card.Body>
               <Row>
                      <Col sm={12}>
                        <div style={{width: '100%', height: "auto"}}>
                          <Table hover striped bordered>                    
                            <thead>
                     <tr>        
            <th>Alias</th>
            <th>User Type</th>
            <th>Label</th>
            <th>Department</th>
            
          </tr>
                            </thead>
                            <tbody>
          {this.storedResponse.map((user) => (
             <tr key={user.UserType_Id}>
                 
                 {/* <td onClick={() => this.handleUserClick(user)}>{user.UserType_Id}</td> */}
                 <td
  onClick={() => this.handleUserClick(user)}
  style={{color: 'blue', cursor: 'pointer', backgroundColor: user.isHovered ? 'lightgray' : 'inherit' }}
>
  {user.Alias}
</td> 
 <td>{user.TypeDesc}</td>
<td>{user.Label_Name}</td>
 <td>{user.Department}</td>
              
            </tr>
          ))}
        </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                
               
                </Card.Body>                                  
              </Card>
            </Col>
          </Row>
        </Container>
      </div>                                
                        </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        )}
}

interface Istate{
    loggedIn:boolean;
  modal?:any;
}
    
interface IProps {
    history: any;
  }

// Wrap the component with withRouter to inject the history prop
export default withRouter(SelectRole);