import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class PasswordResetController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    userInfo : any;
    dateTimeFormat : DateTimeFormat;
    response: { msg: string; success: boolean;};
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
        this.userInfo = {username:'',npwd:'',opwd:''}
        this.response = { msg : '', success : true}
    }


    updateUserPassword(userId : string , password : string){
        
        var url = this.variables.Url + this.variables.Controller.passwordReset + this.variables.passwordResetService.updateUserPassword;
        var data = {
            username : userId,
            newPassword : password,           
        }
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error => {
            return error;
        })
    }

    checkUserPreviousPassword(userId : string , password : string){
        
        var url = this.variables.Url + this.variables.Controller.passwordReset + this.variables.passwordResetService.checkPreviousPassword;
        var data = {
            username : userId,
            newPassword : password,           
        }
        return this.serviceProxy.get(url,data).then(response => {           
            if(response.success){
                if(response.IsAvailable === 0 || response.IsAvailable === '0'){
                    return this.updateUserPassword(userId,password);
                }
                else{
                    this.response.msg = 'Hi "'+ userId +'", your password should not be similar to the last 13 passwords';
                    this.response.success = false;
                    return this.response;
                }   
               
            }
            
        }).catch(error => {
            return error;
        })
    }

}