import React, { Component } from "react";
import { History, LocationState } from "History";
import { Container, Card, Row, Col, Table, Form, Alert, Button} from "react-bootstrap";
//import { Container, Row, Col, Form, Alert, Button, ProgressBar } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/AdminLayout.css'
import {FormSelect} from '../../CustomComponents/FormSelect';
import {CommonController} from '../../../Controllers/CommonController';
import { AutoAddExecUserController} from '../../../Controllers/AutoAddExecUserController';
import { IAddExecutiveUserParameter, Istate } from "../../../Model/AddExecutiveUser"; 
import {alert} from 'react-bootstrap-confirmation';

export class AddExecutiveUser extends Component<IAddExecutiveUserParameter, Istate> {
    constructor(props: IAddExecutiveUserParameter) {
        super(props)
      this.state = {        
        selectfields: { recordlabelId:'',department:'',}, 
        errors: {recordLabelName:'',department:''},
        errMsg: '',     
        Recordlabel:[],
        Department:[],
        ExecUserDeptList:[],
        loading:false 
      }
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.onSubmit=this.onSubmit.bind(this);
    }

    componentWillMount()
    {
      let commonController = new CommonController();
      let autoAddExecUserController = new AutoAddExecUserController();
      commonController.getAllLabel().then(jsonLabel => {
        this.setState({                           
            Recordlabel : JSON.parse(JSON.stringify(jsonLabel))
        });
      }).catch(error => {
          console.error("There was an error loading operating company..., " + error)
      });       
      
    commonController.getAllDepartment().then(jsonDept => {
        this.setState({                           
            Department : JSON.parse(JSON.stringify(jsonDept))
        });
    }).catch(error => {
        console.error("There was an error loading department..., " +error )
    }); 

    autoAddExecUserController.getExecUserDept().then(jsonExecUser =>{
      this.setState({                           
        ExecUserDeptList : JSON.parse(JSON.stringify(jsonExecUser))
    });
    }).catch(error => {
      console.error("There was an error loading the list..., " +error )
     });

    }

    deleteExecUser = (e) => {                 
      let autoAddExecUserController = new AutoAddExecUserController();
          autoAddExecUserController.deleteExecUserDeptFlag(e).then(response => {          
            if (response.success){       
              alert("Data Deleted...")
              window.location.reload();       
            }
          }).catch(error => {
            this.setState({ errMsg : "Process Failed" });
          })
    }

    handleSelectChange (e:any) {
        try{
            let selectfields = this.state.selectfields;
            let errors = this.state.errors;          
            if (e.currentTarget.name === "recordlabelId"){        
              selectfields[e.currentTarget.name] = e.currentTarget.value;  
              errors["recordLabelName"] = "";                                
            }            
            if (e.currentTarget.name === "department"){
                selectfields['department'] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
                errors["department"] = "";   
            }
            this.setState({
                selectfields,errors
            });
        }
        catch(err){
            console.error(err);
        }
      };

      onSubmit =(e:any)=>{
        try{
        e.preventDefault();
        if (this.ValidateSelect()) { 
          this.setState({ loading: true });   
          let autoAddExecUserController = new AutoAddExecUserController();
          autoAddExecUserController.insertExecUserDeptFlag(this.state.selectfields.department,this.state.selectfields.recordlabelId)
          .then(response => {
            if (response.success){       
              alert("Data Added...")
              window.location.reload();       
            }
          }).catch(error => {
            this.setState({ errMsg : "Process Failed" });
          })
        }
        }
        catch(err){
          console.error(err);
          }
      }

      ValidateSelect(){
        this.clearError();        
        let formIsValid = true;    
        let selectLabel =this.state.selectfields.recordlabelId;  
        let selectDepartment =this.state.selectfields.department;
        let errors = this.state.errors;
        if (selectLabel === "" || selectLabel === "Select Label") {
          formIsValid = false;
          errors["recordLabelName"] = "Select Label";                
        }
        if(selectDepartment === "" || selectDepartment === "Select Department"){
          formIsValid = false;
          errors["department"] = "Select Department";
        }
        this.setState({
          errors
        });
        return formIsValid;
      }

      clearError()
      {
        let errors = this.state.errors;
        errors["recordLabelName"] = "";  
        errors["department"]="";
        this.setState({errMsg : ""})
        this.setState({
          errors
      });
      }

    render() {
        const {loading} = this.state;        
        return (
          <div className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card border="light" style={{ width: '100%', height:'650px' }}>
                      <Card.Body>
                      <Card.Title>Auto Add Executive User to Department</Card.Title>
                      <Form onSubmit= {this.onSubmit}>
                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                        <Row>
                            <Col sm={3}> 
                              <FormSelect
                                  properties={{label:"Record Label",id:"recordlabelId",name:"recordlabelId",className:"form-control",onChange:this.handleSelectChange}}
                                  fieldRequired={true}
                                  errorMsg={this.state.errors.recordLabelName}
                                  defaultOption="Select Label"
                                  fillOption={this.state.Recordlabel}
                                  />
                            </Col>
                            <Col sm={3}> 
                              <FormSelect
                                  properties={{label:"Department",id:"department",name:"department",className:"form-control",onChange:this.handleSelectChange}}
                                  fieldRequired={true}
                                  errorMsg={this.state.errors.department}
                                  defaultOption="Select Department"
                                  fillOption={this.state.Department}
                                  />
                            </Col>
                            <Col sm={3} style={{ paddingTop: "25px"}}> 
                              <Button type="submit" disabled={loading} style={{marginRight: "5px"}}>
                              {loading && (
                              <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px", color:"#3472F7" }}
                              />
                              )}
                              {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                              {!loading && <span>Add</span>}
                              </Button>
                            </Col>                           
                         </Row>                        
                        <Row>
                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                        <div className="scroll" style={{ width: '100%' }}>
                            <Table hover>
                                <thead>
                                <tr> 
                                    <th>Record Label</th>                                                     
                                    <th>Department</th>                                                          
                                    <th></th>                                                   
                                </tr>
                                </thead>
                                <tbody>                              

                              {this.state.ExecUserDeptList.map((prop, key) => { 
                                        return (
                                          <tr key={key}>                                            
                                            <td>{prop["label"]}</td>
                                            <td>{prop["dept"]}</td>
                                            <td><a href="" onClick={() => this.deleteExecUser(prop["id"])}>Delete</a></td>  
                                                                             
                                          </tr>
                                      )            
                                })} 
                                </tbody>
                            </Table>
                            </div>
                        </Col>
                        </Row>                 
                      </Form>
                      </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
}