import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class RegisterController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    userInfo : any;
    dateTimeFormat : DateTimeFormat;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
        this.userInfo = {username:'',userTypeId:'',name:'',email:'',recordLabelId:'',department:'',addressLineOne:'',addressLineTwo:'',city:'',state:'',zip:'',phone:'',password:'',multipleSelect:'',dateOfChange:'',processName:'', businessUnit:'', customerNumber:'', shipToName:'', otherLabels:false, exceptions:false}
    }

    checkAliasEmail(username : string , email : string){
        var url : string = this.variables.Url + this.variables.Controller.register + this.variables.registerService.checkAliasEmail;
        var data : any = {
            username : username,
            email : email
        };
        return this.serviceProxy.get(url, data)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
    }

    getDeptProcessInfo(data){
        
        this.userInfo.username = data.username;
        this.userInfo.userTypeId = data.userTypeId;
        this.userInfo.name = data.name; 
        this.userInfo.email = data.email;
        this.userInfo.recordLabelId = data.recordLabelId;
        this.userInfo.department = data.department;
        this.userInfo.addressLineOne = data.addressLineOne;
        this.userInfo.addressLineTwo = data.addressLineTwo;
        this.userInfo.city = data.city;
        this.userInfo.state = data.state;
        this.userInfo.zip = data.zip;
        this.userInfo.phone = data.phone;
        this.userInfo.password = data.password;
        this.userInfo.multipleSelect = data.multipleSelect;
        this.userInfo.dateOfChange = this.dateTimeFormat.getDateTime();
        var url : string = this.variables.Url + this.variables.Controller.register + this.variables.registerService.getDeptProcessInfo;
        var data : any = {
            recordLabelId : this.userInfo.recordLabelId,
            department : this.userInfo.department
        };
        return this.serviceProxy.post(url, data)
        .then(response => {
            
            if(response.success)
            {
                var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                this.userInfo.processName = res.processName;
                this.userInfo.customerNumber = res.customerNumber;
                this.userInfo.businessUnit = res.businessUnit;
                this.userInfo.otherLabels = res.otherLabels;
                this.userInfo.exceptions = res.exceptions;
               
            }
            return this.addNewUser();
        }).catch(error => {
            return error;
        });
    }

    addNewUser(){
        var url : string = this.variables.Url + this.variables.Controller.register + this.variables.registerService.addNewUser
        var data = {
            username : this.userInfo.username,
            userTypeId : this.userInfo.userTypeId,
            processname : this.userInfo.processName,
            name : this.userInfo.name,
            email : this.userInfo.email,
            recordLabelId : this.userInfo.recordLabelId,
            department : this.userInfo.department,
            customerNumber : this.userInfo.customerNumber,
            businessUnit : this.userInfo.businessUnit,
            addressLineOne : this.userInfo.addressLineOne,
            addressLineTwo : this.userInfo.addressLineTwo,
            city : this.userInfo.city,
            state : this.userInfo.state,
            zip : this.userInfo.zip,
            phone : this.userInfo.phone,
            password : this.userInfo.password,
            multipleSelect : this.userInfo.multipleSelect,
            dateOfChange : this.userInfo.dateOfChange
        }
        return this.serviceProxy.post(url,data).then(response => {
            return this.updateSuperExecUser();
        }).catch(error => {
            return error;
        })
    }

    updateSuperExecUser(){
        var url = this.variables.Url + this.variables.Controller.register + this.variables.registerService.updateSuperExecUser;
        var data = {
            userTypeId : this.userInfo.username,
            recordLabelId : this.userInfo.recordLabelId,
            department : this.userInfo.department
        }
        return this.serviceProxy.put(url,data).then(response => {
            return this.getLabel();
        }).catch(error => {
            return error;
        })
    }

    getLabel(){
        var url = this.variables.Url + this.variables.Controller.common + this.variables.registerService.getLabel;
        var data = {
            recordLabelId : this.userInfo.recordLabelId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                this.userInfo.shipToName = response.Name.trim();
            }
            return this.insertPrivateContact();
        }).catch(error => {
            return error;
        })
    }

    insertPrivateContact(){
        var url = this.variables.Url + this.variables.Controller.common +this.variables.registerService.insertPrivateContact;
        var data = {
            username : this.userInfo.username,
            name : this.userInfo.name,
            shipToName : this.userInfo.shipToName,
            addressLineOne : this.userInfo.addressLineOne,
            addressLineTwo : this.userInfo.addressLineTwo,
            city : this.userInfo.city,
            state : this.userInfo.state,
            zip : this.userInfo.zip,
            country : "USA",
            phone : this.userInfo.phone,
            contactType : this.userInfo.contactType,
            recordLabelId : this.userInfo.recordLabelId
        }
        return this.serviceProxy.post(url,data).then(response => {
            return this.insertPermissionsExceptions();
        }).catch(error => {
            return error;
        })
    }

    insertPermissionsExceptions(){
        var url = this.variables.Url + this.variables.Controller.register + this.variables.registerService.insertPermissionsExceptions;
        var data = {
            username : this.userInfo.username,
            processName : this.userInfo.processName,
            otherLabels : this.userInfo.otherLabels,
            exceptions : this.userInfo.exceptions
        }
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error => {
            return error;
        })
    }

}