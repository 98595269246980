import React, { Component } from "react";
import {History, LocationState} from "History";
import {Row,Col,Container, Card, Button, NavLink, Form, Alert} from "react-bootstrap";
import {LoginNavBar} from '../../CustomComponents/NabvBar/LoginNavBar';
import {FormInputs} from '../../CustomComponents/FormInput';
import {FormSelect} from '../../CustomComponents/FormSelect';
import {FormCheckBox} from '../../CustomComponents/FormCheckBox';
import {FormLabelField} from '../../CustomComponents/FormLabelField';
import "../../../assets/css/LoginPage.css";
import jsonState from "../../../json/State.json";
import {RegisterController} from '../../../Controllers/RegisterController';
import {CommonController} from '../../../Controllers/CommonController';
import {Redirect} from 'react-router-dom';
import { RegisterParameter, Istate } from "../../../Model/Register"; 
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class CreateProfile extends Component<RegisterParameter,Istate> {
  redBorder:string;
  defaultBorder:string;
  constructor(props:RegisterParameter){
    super(props)
    //Change Border color Red, if alert triggered in onBlur Event
    this.redBorder = "1px solid #DC3545";
    this.defaultBorder = "1px solid #E3E3E3";
    this.state = {
        fields: {uid:'',user_full_name:'',user_first_name:'',user_last_name:'',password:'',confirm:'',AddressLineOne:'',AddressLinetwo:'',city:'',zip:'',email:'',phone:'',userTypeId:'',CustomerNumber:'',BusinessUnit:''},
        selectfields: {recordlabelId:'',department:'',state:'',recordLabelName:'',departmentName:'',stateName:''},
        checkfields: {multipleSelect:false},
        errors: {uid:'',user_first_name:'',user_last_name:'',AddressLineOne:'',city:'',zip:'',email:'',phone:'',Process_name:'',UserTypeName:'',recordLabelName:'',department:'',stateName:'',Name:'',password:'',confirm:''},
        Recordlabel:[],
        Department:[],
        State:[],
        errMsg:'',
        loading:false,
        modal: {modalSpinnerShow: false}
      } 
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    //Load operating company and state dropdown on page load...
    componentWillMount(){
      let modal = this.state.modal;
      modal['modalSpinnerShow']=true;
      this.setState({ modal });
      let selectfields = this.state.selectfields;
      selectfields["recordLabelName"]="Select Your Operating Company";
      selectfields["departmentName"] = "Select Department";
      selectfields["state"]="Select A State";
      this.setState({                           
        State : JSON.parse(JSON.stringify(jsonState.result)),
        selectfields
      });
      let commonController = new CommonController();
      commonController.getAllLabel().then(jsonLabel => {
        this.setState({                           
            Recordlabel : JSON.parse(JSON.stringify(jsonLabel))
        });
        modal['modalSpinnerShow']=false;
        this.setState({ modal });
      }).catch(error => {
          console.error("There was an error loading operating company..., " + error)
      });
      
    }

    handleChange (e:any) {
      let fields = this.state.fields;
      if (e.currentTarget.value.trim().length > 0)
      {
        fields[e.currentTarget.name] = e.currentTarget.value;
      }
      else
      {
        fields[e.currentTarget.name] = e.currentTarget.value.trim();
      }
      this.setState({
          fields
      })
    }

    handleBlur (e:any) {
      
      //e.preventDefault();
      //e.stopPropagation();
      const target = e.currentTarget;
      target.style.border=this.defaultBorder;
      //let control = document.getElementById(e.currentTarget.id);
      let controls=document.getElementById(e.currentTarget.id) as HTMLElement
      if(e.currentTarget.name === "uid" && (e.currentTarget.value).length > 0){
        var regex = /^[A-Za-z0-9 ]+$/
        if((e.currentTarget.value).length < 5){
          alert("Your user name must be atleast 5 character")
          target.style.border=this.redBorder;
          this.setFocus(target);
          return false;
        }
        else if(!regex.test(e.currentTarget.value)){
          alert("Your user name contains illegal character-please use letters or numbers only and no space")
          target.style.border=this.redBorder;
          this.setFocus(target);
        }
        else if((e.currentTarget.value).indexOf(' ') >= 0){
          alert("Your user name contains illegal character-please use letters or numbers only and no space")
          target.style.border=this.redBorder;
          this.setFocus(target);
        } 
      }
      if(e.currentTarget.name === "password" && (e.currentTarget.value).length > 0){
          var regexSpl = /[!`@#$%^&+\=*?_~]/g;
          var regexUpper = /[A-Z]/g;
          var regexLower = /[a-z]/g;
          var regexNum = /[0-9]/g;
          if((e.currentTarget.value).length < 8){
            alert("Password should contain atleast 8 character")
            target.style.border=this.redBorder;
            this.setFocus(target);
          }
          else if(!regexUpper.test(e.currentTarget.value)){
            alert("Password should contain atleast one upper case")
            target.style.border=this.redBorder;
            this.setFocus(target);
          }
          else if(!regexLower.test(e.currentTarget.value)){
            alert("Password should contain atleast one lower case")
            target.style.border=this.redBorder;
            this.setFocus(target);
          }
          else if(!regexNum.test(e.currentTarget.value)){
            alert("Password should contain atleast one number")
            target.style.border=this.redBorder;
            this.setFocus(target);
          }
          else if(!regexSpl.test(e.currentTarget.value)){
            alert("Password should contain atleast one special character")
            target.style.border=this.redBorder;
            this.setFocus(target);
          }
      }
      if(e.currentTarget.name === "confirm" && (e.currentTarget.value).length > 0){
        if((e.currentTarget.value) !== (this.state.fields.password)){
          alert("Password do not match")
          target.style.border=this.redBorder;
          this.setFocus(target);
        }
      }

      if(e.currentTarget.name === "zip" && (e.currentTarget.value).length > 0){
        var valid = "0123456789-";
        var hyphencount = 0;
        var field = e.currentTarget.value;

        if (field.length!==5 && field.length!==10) {
          alert("Please enter your 5 digit or 5+4 digit zip code.");
          target.style.border=this.redBorder;
          this.setFocus(target);
        }
        for (var i=0; i < field.length; i++) {
          var temp = "" + field.substring(i, i+1);
          if (temp == "-") hyphencount++;
          if (valid.indexOf(temp) == -1) {
            alert("Invalid characters in your zip code.  Please try again.");
            target.style.border=this.redBorder;
            this.setFocus(target);
            return false;
          }
          if ((hyphencount > 1) || ((field.length==10) && ""+field.charAt(5)!="-")) {
            alert("The hyphen character should be used with a properly formatted 5 digit+four zip code; example: 12345-6789.   Please try again.");
            target.style.border=this.redBorder;
            this.setFocus(target);
            return false;
          }
        }
        
      }

      if(e.currentTarget.name === "email" && (e.currentTarget.value).length > 0){
        var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!regexEmail.test(e.currentTarget.value)){
          alert("Plesae enter the valid email address")
          target.style.border=this.redBorder;
          this.setFocus(target);
        }
      }

      if(e.currentTarget.name === "phone" && (e.currentTarget.value).length > 0){
        var regexPhone = /^[(][1-9][0-9][0-9][)][ ][1-9][0-9][0-9][-][0-9][0-9][0-9][0-9]$/;
        if(!regexPhone.test(e.currentTarget.value)){
          alert("Please enter phone number in the following format:(123) 456-7890")
          target.style.border=this.redBorder;
          this.setFocus(target);
        }
      } 
    }
    
    setFocus(target:any)
    {
      //setTimeout(function () { target.select(); },5);
    }

    handleSelectChange (e:any) {
      try{
          let selectfields = this.state.selectfields;
          selectfields[e.currentTarget.name] = e.currentTarget.value;
          let fields = this.state.fields;
          if (e.currentTarget.name === "recordlabelId"){ 
              selectfields["recordLabelId"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;   
              selectfields["recordLabelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
              if(e.currentTarget.options[e.currentTarget.selectedIndex].text === "DEPOT"){
                  fields["userTypeId"] = "D";
              }else{
                  fields["userTypeId"] = "R";
              }
              let commonController = new CommonController();
              commonController.getAllDeptForLabel(e.currentTarget.options[e.currentTarget.selectedIndex].value).then(jsonDept => {
                  this.setState({                           
                      Department : JSON.parse(JSON.stringify(jsonDept))
                  });
              }).catch(error => {
                  console.error("There was an error loading department..., " +error )
              });
              this.setState({
                  fields
              });
          }
          if (e.currentTarget.name === "state"){
              selectfields['stateName'] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
          }
          if (e.currentTarget.name === "department"){
              selectfields['departmentName'] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
          }
          this.setState({ selectfields });
      }
      catch(err){
          console.error(err);
      }
    };

    handleCheckChange(e:any){
        try {
            let checkfields = this.state.checkfields;
            checkfields[e.currentTarget.name] = !this.state.checkfields.multipleSelect
            this.setState({
                checkfields
            });
        }
        catch(err){
            console.error(err);
        }
    }
    clearError()
    {
      let errors = this.state.errors;
      errors["uid"] = "";
      errors["user_first_name"] = "";
      errors["user_last_name"] = "";
      errors["password"] = "";
      errors["confirm"] = "";
      errors["AddressLineOne"] = "";
      errors["city"] = "";
      errors["zip"] = "";
      errors["email"] = "";
      errors["phone"] = "";
      errors["recordLabelName"] = "";
      errors["department"] = "";
      errors["state"] = "";
      this.setState({
        errors
    });
    }
    ValidateProfile(){
      this.clearError();
      let fields = this.state.fields;
      let selectfields = this.state.selectfields;
      let errors = this.state.errors;
      let formIsValid = true;
      if (!fields["uid"]) {
        formIsValid = false;
        errors["uid"] = "The Username field is empty";
      }
      if (!fields["user_first_name"]) {
        formIsValid = false;
        errors["user_first_name"] = "The First Name field is empty.";
      }
      if (!fields["user_last_name"]) {
        formIsValid = false;
        errors["user_last_name"] = "The Last Name field is empty.";
      }
      // if (!fields["password"]) {
      //   formIsValid = false;
      //   errors["password"] = "The Password field is empty.";
      // }
      // if (!fields["confirm"]) {
      //   formIsValid = false;
      //   errors["confirm"] = "The Confirm Password field is empty.";
      // }
      if (!fields["AddressLineOne"]) {
        formIsValid = false;
        errors["AddressLineOne"] = "The Address Line One field is empty.";
      }
      if (!fields["city"]) {
        formIsValid = false;
        errors["city"] = "The City field is empty.";
      }
      if (!fields["zip"]) {
        formIsValid = false;
        errors["zip"] = "The Zip field is empty.";
      }
      if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = "The Work Email field is empty.";
      }
      if (!fields["phone"]) {
        formIsValid = false;
        errors["phone"] = "The Work Phone field is empty.";
      }
      if(fields["recordLabelName"] === "Select Your Operating Company"){
        formIsValid = false;
        errors["recordLabelName"] = "You must select an Operating Company.";
      }
      if(selectfields["departmentName"] === "Select Department"){
        formIsValid = false;
        errors["department"] = "You must select a Department.";
      }
      
      if(selectfields["state"] === "Select A State"){
        formIsValid = false;
        errors["state"] = "You must select a State.";
      }
      if(formIsValid){
        if(fields["uid"] !== undefined && fields["uid"].length > 0){
          var regex = /^[A-Za-z0-9 ]+$/
          if(fields["uid"].length < 5){
            alert("Your user name must be atleast 5 character")
            formIsValid = false;
          }
          else if(!regex.test(fields["uid"])){
            alert("Your user name contains illegal character-please use letters or numbers only and no space")
            formIsValid = false;
          }
          else if(fields["uid"].indexOf(' ') >= 0){
            alert("Your user name contains illegal character-please use letters or numbers only and no space")
            formIsValid = false;
          } 
        }
        // if(formIsValid && fields["password"]!==undefined && fields["password"].length > 0){
        //     var regexSpl = /[!`@#$%^&+\=*?_~]/g;
        //     var regexUpper = /[A-Z]/g;
        //     var regexLower = /[a-z]/g;
        //     var regexNum = /[0-9]/g;
        //     if(fields["password"].trim().length < 8){
        //       alert("Password should contain atleast 8 character")
        //       formIsValid = false;
        //     }
        //     else if(!regexUpper.test(fields["password"])){
        //       alert("Password should contain atleast one upper case")
        //       formIsValid = false;
        //     }
        //     else if(!regexLower.test(fields["password"])){
        //       alert("Password should contain atleast one lower case")
        //       formIsValid = false;
        //     }
        //     else if(!regexNum.test(fields["password"])){
        //       alert("Password should contain atleast one number")
        //       formIsValid = false;
        //     }
        //     else if(!regexSpl.test(fields["password"])){
        //       alert("Password should contain atleast one special character")
        //       formIsValid = false;
        //     }
        // }
        // if(formIsValid && fields["confirm"]!==undefined && fields["confirm"].length > 0){
        //   if(fields["confirm"] !== fields["password"]){
        //     alert("Password do not match")
        //     formIsValid = false;
        //   }
        // }
  
        if(formIsValid && fields["zip"]!==undefined && fields["zip"].length > 0){
          var valid = "0123456789-";
          var hyphencount = 0;
          var field = fields["zip"];
  
          if (field.length!==5 && field.length!==10) {
            alert("Please enter your 5 digit or 5+4 digit zip code.");
            formIsValid = false;
          }
          for (var i=0; i < field.length; i++) {
            var temp = "" + field.substring(i, i+1);
            if (temp == "-") hyphencount++;
            if (valid.indexOf(temp) == -1) {
              alert("Invalid characters in your zip code.  Please try again.");
              formIsValid = false;
              //return false;
            }
            if ((hyphencount > 1) || ((field.length==10) && ""+field.charAt(5)!="-")) {
              alert("The hyphen character should be used with a properly formatted 5 digit+four zip code; example: 12345-6789.   Please try again.");
              formIsValid = false;
              //return false;
            }
          }
          
        }
  
        if(formIsValid && fields["email"]!==undefined && fields["email"].length > 0){
          var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if(!regexEmail.test(fields["email"])){
            alert("Plesae enter the valid email address")
            formIsValid = false;
          }
        }
  
        if(formIsValid && fields["phone"]!==undefined && fields["phone"].length > 0){
          var regexPhone = /^[(][1-9][0-9][0-9][)][ ][1-9][0-9][0-9][-][0-9][0-9][0-9][0-9]$/;
          if(!regexPhone.test(fields["phone"])){
            alert("Please enter phone number in the following format:(123) 456-7890")
            formIsValid = false;
          }
        } 
      }
      if(!formIsValid)
      {
        this.setState({ loading: false });
      }
      this.setState({
        errors
      });
      return formIsValid;
    }

















    onSubmit = (e:any) => {
        this.setState({ loading: true });
        e.preventDefault();
        
        if (this.ValidateProfile()) {
          
            let registerController = new RegisterController();
            registerController.checkAliasEmail(this.state.fields.uid,this.state.fields.email).then( response => {
                // if(response.cAlias >= 1 && response.cEmail >= 1) {
                //     this.setState({ errMsg : "Username and email already exists..." });
                //     this.setState({ loading: false });
                // }
                 if(response.cAlias >= 1) {
                    this.setState({ errMsg : "Username already exists..." });
                    this.setState({ loading: false });
                }
                // else if(response.cEmail >= 1) {
                //     this.setState({ errMsg : "Email already exists..." });
                //     this.setState({ loading: false });
                // }
                else {
                  var data = {
                    username : this.state.fields.uid,
                    userTypeId : this.state.fields.userTypeId,
                    name : this.state.fields.user_first_name + " " + this.state.fields.user_last_name,
                    email : this.state.fields.email,
                    recordLabelId : this.state.selectfields.recordLabelId,
                    department : this.state.selectfields.departmentName,
                    addressLineOne : this.state.fields.AddressLineOne,
                    addressLineTwo : this.state.fields.AddressLinetwo,
                    city : this.state.fields.city,
                    state : this.state.selectfields.state,
                    zip : this.state.fields.zip,
                    phone : this.state.fields.phone,
                    password : this.state.fields.password,
                    multipleSelect : this.state.checkfields.multipleSelect
                  }
                  registerController.getDeptProcessInfo(data).then(response => {
                    if (response.success){
                      alert("User profile has been created successfully")
                      this.props.history.push('/admin/home');
                      this.setState({ loading: false });
                    }
                  }).catch(error => {
                    this.setState({ errMsg : "Self-Registration Failed" });
                  })
                 }
            })
        } 
    }
    
    DisplayCreateProfileButton =()=> {
      const {loading} = this.state;
      if(this.state.selectfields["recordLabelName"]!=="Select Your Operating Company"){
          return<div className="form-group mt-4 mb-0 text-center">
            <Button type="submit" disabled={loading} style={{marginRight: "5px"}}>
              {loading && (
              <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" , color:"#3472F7"  }}
              />
              )}
              {loading && <span style={{color:"#3472F7"}}>wait...</span>}
              {!loading && <span>Create</span>}
          </Button>
          <Button type="button" style={{ marginRight: "5px" }} onClick={() => {this.props.history.push('/admin/home')}}>Cancel</Button>
          </div>
      }
      else
      {
          return<div className="form-group mt-4 mb-0 text-center"><Button type="button" style={{ marginRight: "5px" }} onClick={() => {this.props.history.push('/admin/home')}}>Cancel</Button></div>
      }
    }
    render() {
        const alignCardCenter = {
          "margin": "1% 0 1% 0"
        }
        const changebgColor = {
          backgroundColor:"rgba(203, 203, 210, 0.15)"
        }
        /*const styles = {
          alignCardCenter: {
            margin: "1% 0 1% 0"
          },
          changebgColor: {
            backgroundColor:"rgba(203, 203, 210, 0.15)"
        }*/
        return (
          <div className="wrapper">
            <div id="main-panel" className="login-panel" ref="mainPanel">
              {/* <LoginNavBar
                {...this.props}
                brandText={'eGratis Promotional Product Request'}
              /> */}
              
              <div className="content" style={changebgColor}>
              <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
                <Container>
                    <Row style={alignCardCenter}>
                    <Col sm={12} className="small">
                      <Card border="light" style={{ width: '100%', height:'auto' }}>
                      <Card.Body>
                      <Card.Title><h4 className="title">User Profile</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                          <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                          <Row>
                            <Col sm={12}>
                            <Row>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"uid",label:"User Name",type:"text",name:"uid",className:"form-control input-sm",maxlength:12,value:this.state.fields.uid,onChange:this.handleChange, onBlur:this.handleBlur}}
                                    errorMsg={this.state.errors.uid}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}></Col>
                                <Col sm={4}></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"user_first_name",label:"First Name",type:"text",name:"user_first_name",className:"form-control input-sm",maxlength:24,value:this.state.fields.user_first_name,onChange:this.handleChange}}
                                    errorMsg={this.state.errors.user_first_name}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"user_last_name",label:"Last Name",type:"text",name:"user_last_name",className:"form-control input-sm",maxlength:24,value:this.state.fields.user_last_name,onChange:this.handleChange}}
                                    errorMsg={this.state.errors.user_last_name}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}></Col>
                              </Row>
                              {/* <Row>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"password",label:"Password",type:"password",name:"password",className:"form-control input-sm",maxlength:15,value:this.state.fields.password,onChange:this.handleChange, onBlur:this.handleBlur}}
                                    errorMsg={this.state.errors.password}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"confirm",label:"Confirm Password",type:"password",name:"confirm",className:"form-control input-sm",maxlength:15,value:this.state.fields.confirm,onChange:this.handleChange, onBlur:this.handleBlur}}
                                    errorMsg={this.state.errors.confirm}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4} className="align-self-center"><div className="text-danger font-size-xs small">Password Policy: Minimum of 8 character and max of 15. At least one Upper case and small case. At least one number. At least one special character (~, `, !, @, #, $, %, ^, &, *, (, ), +, =, \).</div></Col>
                              </Row> */}
                              <Row>
                                <Col sm={4}>
                                  <FormSelect
                                    properties={{label:"Operating Company",id:"recordlabelId",name:"recordlabelId",className:"form-control",onChange:this.handleSelectChange}}
                                    fieldRequired={true}
                                    errorMsg={this.state.errors.recordlabelId}
                                    defaultOption="Select Your Operating Company"
                                    fillOption={this.state.Recordlabel}
                                  />
                                </Col>
                                <Col sm={4}>
                                  <FormSelect
                                    properties={{label:"Department",id:"department",name:"department",className:"form-control",onChange:this.handleSelectChange}}
                                    fieldRequired={true}
                                    errorMsg={this.state.errors.department}
                                    defaultOption="Select Department"
                                    fillOption={this.state.Department}
                                  />
                                </Col>
                                <Col sm={4}></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"AddressLineOne",label:"Work Address 1",type:"text",name:"AddressLineOne",className:"form-control input-sm",maxlength:30,value:this.state.fields.AddressLineOne,onChange:this.handleChange}}
                                    errorMsg={this.state.errors.AddressLineOne}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"AddressLinetwo",label:"Work Address 2",type:"text",name:"AddressLinetwo",className:"form-control input-sm",maxlength:30,value:this.state.fields.AddressLinetwo,onChange:this.handleChange}}
                                    errorMsg={this.state.errors.addressLinetwo}
                                    fieldRequired={false}
                                  />
                                </Col>
                                <Col sm={4} className="align-self-center"><div className="text-danger font-size-xs small">Please note that field is limited to 30 characters to prevent address from not displaying properly on address labels. </div></Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <FormInputs
                                    properties={{id:"city",label:"City",type:"text",name:"city",className:"form-control input-sm",maxlength:50,value:this.state.fields.city,onChange:this.handleChange}}
                                    errorMsg={this.state.errors.city}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={3}>
                                  <FormSelect
                                    properties={{label:"State",id:"state",name:"state",className:"form-control",onChange:this.handleSelectChange,value:this.state.selectfields.state}}
                                    fieldRequired={true}
                                    defaultOption="Select A State"
                                    fillOption={this.state.State}
                                    errorMsg={this.state.errors.state}
                                  />
                                </Col>
                                <Col sm={2}>
                                  <FormInputs
                                    properties={{id:"zip",label:"Zip",type:"text",name:"zip",className:"form-control input-sm",maxlength:10,value:this.state.fields.zip,onChange:this.handleChange,onBlur:this.handleBlur}}
                                    errorMsg={this.state.errors.zip}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"email",label:"Email Id",type:"email",name:"email",className:"form-control input-sm",maxlength:50,value:this.state.fields.email,onChange:this.handleChange,onBlur:this.handleBlur}}
                                    errorMsg={this.state.errors.email}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4}>
                                  <FormInputs
                                    properties={{id:"phone",label:"Work Phone",type:"text",name:"phone",className:"form-control input-sm",maxlength:35,value:this.state.fields.phone,onChange:this.handleChange,onBlur:this.handleBlur}}
                                    errorMsg={this.state.errors.phone}
                                    fieldRequired={true}
                                  />
                                </Col>
                                <Col sm={4} className="align-self-center"><div className="text-danger font-size-xs small">example: (123) 456-7890</div></Col>
                              </Row>
                              <Row>
                                <Col sm={12}>
                                  <FormCheckBox
                                    properties={{label:"Check if you wish to make multiple selections in search result lists",id:"multipleSelect",name:"multipleSelect",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.multipleSelect}}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              {this.DisplayCreateProfileButton()}
                            </Col>
                          </Row>
                          <div className="clearfix" />
                        </Form>
                        </Card.Body>                                  
                      </Card>
                    </Col>
                    </Row>
                </Container>
                </div> 
            </div>
          </div>
        );
    }
  }
