import React, { Component} from "react";
import {Container,Card, Row, Col,Table,Form,Alert,Button,Navbar, Nav,FormText} from "react-bootstrap";
import { Variables } from '../../../Variables/Variables';
import { NavLink } from 'react-router-dom'
import '../../../assets/css/Style.css'
import DatePicker from 'react-datepicker';
import { ViewOrderParameter, Istate } from "../../../Model/ViewOrder"; 
import{ViewOrderController} from "../../../Controllers/ViewOrderController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { convertToObject } from "typescript";
import { format } from 'date-fns'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class ViewOrder extends Component<ViewOrderParameter,Istate> {
    constructor(props:ViewOrderParameter){
        super(props)       
        this.state={
            fields: {find:'', searchType:'', orderType:'' },
            errors: {find:'',searchType:'' },            
            errMsg:"",
            startDate:"",
            previousdate:"",
            Order_id:"",
            goodsReq:"",
            alias:"",
            displayListGrid:[],
            isListAvlMsg:"",
            isListAvl:false,
            todayDate:"",
            task_For:"",
            depotContactList:[],
            isContactAvl:false,             
            endDate:"",
            loading: false,
            modalSpinnerShow:false
        }
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);        
    }

    componentWillMount(){
      this.setState({modalSpinnerShow:true});

      var today    =new Date();  
      var sD=new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));    
      var curr_date = sD.getDate();
      var curr_month = sD.getMonth() + 1; //Months are zero based
      var curr_year = sD.getFullYear();
      var startDate = curr_month + "/" + curr_date + "/" + curr_year;

      var eD = today;
      var curr_date = eD.getDate();
      var curr_month = eD.getMonth() + 1; //Months are zero based
      var curr_year = eD.getFullYear();
      var endDate = curr_month + "/" + curr_date + "/" + curr_year;

      sessionStorage.setItem('SearchHistoryView', '0' )
      sessionStorage.setItem('SearchHistory','')
      let SH
        SH = sessionStorage.getItem("ViewOrderStatus")  
       sessionStorage.setItem('ViewOrderStatus', '0' )  
       
       var temparray = sessionStorage.getItem('ViewOrder') || ""  
       var srhBk = sessionStorage.getItem('ViewOrderStatusSR') || "" 

       if (SH==='1' && temparray != "")
       {
        this.setState({isListAvl:true})
        this.setState({ 
          displayListGrid: JSON.parse(sessionStorage.getItem('ViewOrder') || "") 
        });
              
       var sdate =  Date.parse(sessionStorage.getItem('startDate') || "")
       var edate =  Date.parse(sessionStorage.getItem('endDate') || "")
      
       this.setState({ startDate: ""});      
       this.setState({ endDate: ""}); 

       //this.setState({ startDate: format(sdate, 'MM/dd/yyyy')});      
       //this.setState({ endDate: format(edate, 'MM/dd/yyyy')});  

      }
      else
      {
        this.setState({isListAvl:false})      

      this.setState({ startDate: sD});      
      this.setState({ endDate: today});

      }

      let viewOrderController = new ViewOrderController();
      viewOrderController.getOrderDepotContact(sessionStorage.getItem("label_id")!)
      .then(jsonDepotContact=>{
        this.setState({                           
          depotContactList : JSON.parse(JSON.stringify(jsonDepotContact))
      });     
      if(this.state.depotContactList.length > 0) 
      {       
        this.setState({isContactAvl:true})
      }

      }).catch(error => {
        console.error("There was an error loading Depot Contact")
      })

              
     if (srhBk !=='1')
       {
       // sessionStorage.setItem('ViewOrderStatusSR', '0' ) 

      viewOrderController.getListViewOrder(sessionStorage.getItem("uid")!,startDate,endDate)
            .then(jsonviewOrderList =>{
              this.setState({                           
                displayListGrid : JSON.parse(JSON.stringify(jsonviewOrderList))
            }); 
           
             if(this.state.displayListGrid.length > 0)
              {      
                this.setState({isListAvl:true})
                this.setState({isListAvlMsg:""})
              }
              else
              {                                        
                this.setState({isListAvlMsg:"No record(s) found"})               
              }
              this.setState({modalSpinnerShow:false})
           }).catch(error => {         
            console.log(error);
          })
          
        }
        else
        {
          this.setState({modalSpinnerShow:false})
        }

        this.setState({modalSpinnerShow:false})
      

      }
  
  getItemInfo= (item) =>{
      const styleObj = {
      fontSize: 10,
      color: "red",      
    } 
    const styleBilObj = {
      fontSize: 10,
      color: "#800000",      
    } 
 
    if(item["material"] === true)
    {
      return  (<span style={styleObj}>Clean</span>) 
      }
      else{
      return ""
      } 
  };

   getBillItemInfo= (item) =>{

  const styleBilObj = {
    fontSize: 10,
    color: "#800000",      
  } 
  if(item["billable"] === true)
  {
   return  (<span style={styleBilObj}>Billable</span>) 
  }
  else if(item["NTBillableT"] === true)
  {
    return  (<span  style={styleBilObj}>Non Billable Transfer</span>)      
  }
  else{
    return ""
   } 
};
   getShipment=(item) =>{
    const styleObj = {
      fontSize: 10,
      color: "red",      
    }    

    if(item["Ship_method"] !="Standard Ground")
    {      //return "2nd Day Air"
      if(!item["NTBillableT"] && !item["billable"])
      {
         return  (<span  style={styleObj}>{item["Ship_method"]}</span >)    
      }

    }   
   }
 
   getLinkWithPath= (item) =>{
        return (<NavLink
        to={{
          pathname:'/admin/viewOrderDetails',
          state:{
            Order_id:item["Order_Id"],
            task_For:"V",           
          }
        }}                                        
        activeClassName="active">{item["Order_Id"]}</NavLink>)      
   };

   isDepotMsg =() =>{
    const styleObj = {
      fontSize: 13,
      color: "red",      
    } 
    var i =0;
      this.state.depotContactList.map((prop)=> {  
       if(i === 0 && prop["name"] != "")
        {          
          i++;
          return (<Row><Col sm={12}>
            <span  style={styleObj}>If you have any questions regarding a Depot Processed order, please contact: UMGLabelRequests@umusic.com</span >
            </Col></Row>)
        }       
      })                   
   };

   onSubmit =(e:any)=>{
    try{
    e.preventDefault();
    if (this.ValidateDate()) { 
      
        this.setState({ loading: true });
        this.setState({isListAvl:false})

        //var temps =   this.state.startDate

        var sD =  this.state.startDate
                   
        var curr_date = sD.getDate();
        var curr_month = sD.getMonth() + 1; //Months are zero based
        var curr_year = sD.getFullYear();
        var startDate = "";   

        startDate = curr_month + "/" + curr_date + "/" + curr_year;
  

        var eD = this.state.endDate;        
        var curr_date = eD.getDate();
        var curr_month = eD.getMonth() + 1; //Months are zero based
        var curr_year = eD.getFullYear();
        var endDate = "";
        
        endDate = curr_month + "/" + curr_date + "/" + curr_year;
        
        let viewOrderController = new ViewOrderController();
        viewOrderController.getListViewOrder(sessionStorage.getItem("uid")!,startDate,endDate)
            .then(jsonviewOrderList =>{
              this.setState({                           
                displayListGrid : JSON.parse(JSON.stringify(jsonviewOrderList))
            }); 
            sessionStorage.setItem('ViewOrder', JSON.stringify(jsonviewOrderList))
            sessionStorage.setItem('startDate',startDate)
            sessionStorage.setItem('endDate',endDate)
            sessionStorage.setItem('Bchk','1')

             if(this.state.displayListGrid.length > 0)
              {      
                this.setState({isListAvl:true})
                this.setState({isListAvlMsg:""})
              }
              else
              {                                        
                this.setState({isListAvlMsg:"No record(s) found"})                               
              }
              this.setState({ loading: false });
           }).catch(error => {         
            console.log(error);
          })
    }
  }
  catch(err){
    console.error(err);
    } 
 }

 getUrlInfo= (item) =>{
  let fields= this.state.fields;
  if(item["Sales_type"] === "66 Ticket Bundling"){
    fields['orderType'] = "TB"; 
    this.setState({fields})
    return "/admin/TicketBundling"
  }
  else if(item["billable"] === true){
    fields['orderType'] = "BI"; 
    this.setState({fields})
    return "/admin/CreateBillableOrder"
  }
  else if(item["NTBillableT"] === true){
    fields['orderType'] = "NT"; 
    this.setState({fields})
    return "/admin/TransferNonBillable" 
  }
  else{
    fields['orderType'] = "FG";
    this.setState({fields})
    return "/admin/FreeGoodsOrder";
   } 
   
};

 ValidateDate(){ 
  let formIsValid = true;
  this.clearError();
  if(this.state.startDate === "" ||  this.state.startDate === null)
  {
      this.setState({errMsg : "Please enter start date"})
      formIsValid=false;
  }
  else if (this.state.endDate === "" ||  this.state.endDate === null)
  {
      this.setState({errMsg : "Please enter end date"})
      formIsValid=false;
  }
  
  return formIsValid
}

clearError()
{
 let errors = this.state.errors;
 errors["startDate"] = "";  
 errors["endDate"]="";        
 this.setState({errMsg : ""})
 this.setState({
   errors
});
}
  
   handleChangeStartDate = date => {
    var today    =new Date();
    let errors = this.state.errors;  
    
    if (date === null)   
        errors["startDate"] = "Please enter start date"; 
   else{
        if(today < date )
        errors["startDate"] = "Date is greater than today's date";        
        else
        errors["startDate"] = "";     
   }    
  this.setState({startDate: date,errors});
  };
  handleChangeEndDate = date => {
    var today    =new Date();
    let errors = this.state.errors;   
    if(date === null)
      errors["endDate"] = "Please enter start date";
    else   
    {
        if(today < date )
            errors["endDate"] = "Date is greater than today's date";     
            else
            errors["endDate"] = "";   
    }
    this.setState({endDate: date, errors});
  };

    render(){
      const { isListAvl } = this.state; 
      const { loading } = this.state; 
      const styleObj = {
        fontSize: 13,
        color: "red",      
      }   
        return (
            <div className="content">
              <FormModalSpinner show={this.state.modalSpinnerShow} />
             <Container fluid>            
             <Row>
              <Col md={12}>
                <Card border="light" style={{ height:'100%' }}>
                  <Card.Body>
                  <Card.Title><h4 className="title">View Order</h4></Card.Title>
                    <form>                   
                      <Row>
                        <Col sm={12} style={{ display: this.state.isContactAvl ? "inherit" : "none", marginBottom : "10px"}}>
                          <span  style={styleObj}>If you have any questions regarding a Depot Processed order, please contact: UMGLabelRequests@umusic.com</span >
                        </Col>
                      </Row>
                      <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                      <Row>
                        <Col sm={3}>
                          <Form.Group>
                          <DatePicker 
                              Id="startDate"
                              placeholderText="Start Date"
                              selected={this.state.startDate}
                              onChange={this.handleChangeStartDate}
                              isClearable
                              showMonthDropdown
                              showYearDropdown                            
                              className="datePicker"/>
                              <Form.Text style={{margin:"0 0 0 0"}}>
                              <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.startDate}</p>
                              </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col sm={3}>
                          <Form.Group>
                          <DatePicker  
                              Id="endDate"
                              placeholderText="End Date"
                              selected={this.state.endDate}
                              onChange={this.handleChangeEndDate}                               
                              isClearable
                              showMonthDropdown
                              showYearDropdown                            
                              className="datePicker"/>
                              <Form.Text style={{margin:"0 0 0 0"}}>
                                  <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.endDate}</p>
                              </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Button type="submit" onClick = {this.onSubmit} disabled={loading} style={{ marginRight: "5px" }}>
                              {loading && (
                              <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" , color:"#3472F7"}}
                              />
                              )}
                              {loading && <span style={{ color:"#3472F7" }} >wait...</span>}
                              {!loading && <span>Search</span>}
                          </Button>
                            </Col>  
                          <Col sm={3}>
                           
                            <ExcelFile filename="ViewOrder" element={<Button type="button" style={{ marginLeft: "15px" }}>Export to Excel</Button>}>
                              <ExcelSheet data={this.state.displayListGrid} name="View Order">
                                <ExcelColumn label="Request Date" value="RequestDate" />
                                <ExcelColumn label="Order ID" value="Order_Id" />
                                <ExcelColumn label="Ship TO" value="ShipToName" />
                                <ExcelColumn label="Attention" value="Name" />
                                <ExcelColumn label="Status" value="StatusDesc" />
                              </ExcelSheet>
                              </ExcelFile>

                          </Col >
                        </Row>
                        <Row>
                          <Col sm={12}>
                          <div className="scroll" style={{ width: '100%' }}>                         
                          {isListAvl ? 
                             <Table>
                              <thead>
                              <tr>
                              {new Variables().thViewOrderList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}                   
                              </tr>                         
                            </thead>
                            <tbody>                           
                                {this.state.displayListGrid.map((prop, key) => { 
                                    return (
                                      <tr key={key}>
                                        <td><Button style={{height: 'auto'}}  size="sm" onClick={() => {this.props.history.push({pathname:this.getUrlInfo(prop),state:{item:prop,orderId:prop["Order_Id"] ,orderType:this.state.fields.orderType, action: 'copy'}})}}>Copy</Button></td>                                    
                                        <td>{prop["RequestDate"]}&nbsp;{this.getShipment(prop)}</td>
                                        <td>{this.getLinkWithPath(prop)}&nbsp;{this.getBillItemInfo(prop)}&nbsp;{this.getItemInfo(prop)}</td>                                    
                                        <td>{prop["ShipToName"]}</td>                                 
                                        <td>{prop["Name"]}</td>
                                        <td>{prop["StatusDesc"]}</td>                                  
                                      </tr>
                                    )            
                                    })} 
                              </tbody>  
                             </Table>:
                             <Table>
                               <thead>
                                 <tr>
                                   {new Variables().thFtpHistoryList.map((prop, key) => {
                                     return <th key={key}>{prop}</th>;
                                   })}
                                 </tr>
                               </thead> 
                               <tbody>
                                 <tr>
                                   <td colSpan={11} align="center" className="text-danger font-size-xs small">{this.state.isListAvlMsg}</td>
                                 </tr>
                               </tbody>
                             </Table>} 
                             </div>  
                          </Col>
                        </Row>
                    </form>
                  </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
             </Container>
            </div>
        )        
    }
}