import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container,Card, Row, Col, Form, Button, Table, Alert} from "react-bootstrap";
import {FormInputs} from '../../CustomComponents/FormInput';
import {FormSelect} from '../../CustomComponents/FormSelect';
import '../../../assets/css/Style.css';
import {CommonController} from '../../../Controllers/CommonController';
import {SysopController} from "../../../Controllers/SysopController";
import {FormLabelField} from "../../CustomComponents/FormLabelField";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class EditApprovalProcess extends Component<EditProcessParameter,Istate> {
    constructor(props:EditProcessParameter){
        super(props);
        this.state = {
            fields:{ownerLabelId:'', assignedLabelId:'', department:'', processName:'', maxApprovers:'', processDescription:''},
            errors:{ownerLabelId:'', assignedLabelId:'', processName:'', department:'', maxApprovers:''},
            selectfields:{},
            optionfields: {defaultDeptNo:true, defaultDeptYes:false, superUserNo:true, superUserYes:false, freeGoods:true, billable:false, nonBillableTransfer:false, overnightOrdersYes:true, overnightOrdersNo:false,cleanOrdersYes:true, cleanOrdersNo:false, otherLabelOrdersYes:true, otherLabelOrdersNo:false, labelExceptOrdersYes:true, labelExceptOrdersNo:false, crossLabelProcessYes:false, crossLabelProcessNo:true, exportProcessYes:false, exportProcessNo:true },
            recordLabel:[],
            department:[],
            labelExceptOrders:[], 
            crossLabelProcess:[],
            errMsg:'',
            loading: false,
            modal: {modalSpinnerShow: false},
            update: {maxApprovers: false, crossLabels: false, labelExceptions: false}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let commonController = new CommonController();
        commonController.getAllLabel().then(jsonLabel => {
            this.setState({                           
                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
            });
        }).catch(error => {
            console.error("There was an error loading operating company")
        });
        let sysopController = new SysopController();
        sysopController.getApprovalProcess(this.props.location.state.item["processName"]).then(response =>{
            let fields = this.state.fields;
            let selectfields = this.state.selectfields;
            let optionfields = this.state.optionfields;
            fields["ownerLabelId"] = response.Label_ID;
            fields["assignedLabelId"] = response.Assigned_Label_ID;
            fields["department"] = response.Department;
            fields["processName"] = response.Process_Name;
            fields["maxApprovers"] = response.Max_Approvers;
            fields["processDescription"] = response.Process_Description;
            if(response.Is_Default){
                optionfields['defaultDeptNo'] = false;
                optionfields['defaultDeptYes'] = true;
            }
            else{
                optionfields['defaultDeptNo'] = true;
                optionfields['defaultDeptYes'] = false;
            }
            if(response.Is_Billable){
                optionfields['freeGoods'] = false;
                optionfields['billable'] = true;
                optionfields['nonBillableTransfer'] = false;
            }
            else if(response.is_NTBillable){
                optionfields['freeGoods'] = false;
                optionfields['billable'] = false;
                optionfields['nonBillableTransfer'] = true;
            }
            else{
                optionfields['freeGoods'] = true;
                optionfields['billable'] = false;
                optionfields['nonBillableTransfer'] = false;
            }
            if(response.Is_Super_User){
                optionfields['superUserYes'] = true;
                optionfields['superUserNo'] = false;
            }
            else{
                optionfields['superUserYes'] = false;
                optionfields['superUserNo'] = true;
            }
            if(response.Is_Overnight){
                optionfields['overnightOrdersYes'] = true;
                optionfields['overnightOrdersNo'] = false;
            }
            else{
                optionfields['overnightOrdersYes'] = false;
                optionfields['overnightOrdersNo'] = true;
            }
            if(response.Is_Clean){
                optionfields['cleanOrdersYes'] = true;
                optionfields['cleanOrdersNo'] = false;
            }
            else{
                optionfields['cleanOrdersYes'] = false;
                optionfields['cleanOrdersNo'] = true;
            }
            if(response.Is_Cross_Label){
                optionfields['crossLabelProcessYes'] = true;
                optionfields['crossLabelProcessNo'] = false;
            }
            else{
                optionfields['crossLabelProcessYes'] = false;
                optionfields['crossLabelProcessNo'] = true;
            }
            if(response.Is_Exceptions){
                optionfields['labelExceptOrdersYes'] = true;
                optionfields['labelExceptOrdersNo'] = false;
            }
            else{
                optionfields['labelExceptOrdersYes'] = false;
                optionfields['labelExceptOrdersNo'] = true;
            }
            if(response.Is_All_Labels){
                optionfields['otherLabelOrdersYes'] = true;
                optionfields['otherLabelOrdersNo'] = false;
            }
            else{
                optionfields['otherLabelOrdersYes'] = false;
                optionfields['otherLabelOrdersNo'] = true;
            }
            this.setState({fields, selectfields, optionfields});
        }).catch(error => {
            console.error("There was an error loading operating company")
        }); 
        sysopController.getCrossLabels(this.props.location.state.item["processName"]).then(jsonCrossLabels =>{
            var crossLabels: string[]= [];
            jsonCrossLabels.map((data) => {
                crossLabels.push(data.id)
            })
            this.setState({                           
                crossLabelProcess: crossLabels
            });
            //console.log(this.state.crossLabelProcess);
        }).catch(error => {
            console.error("There was an error loading cross Labels")
        });
        sysopController.getLabelExceptions(this.props.location.state.item["processName"]).then(jsonLabelExceptions =>{
            var labelExceptions: string[]= [];
            jsonLabelExceptions.map((data) => {
                labelExceptions.push(data.id)
            })
            this.setState({                           
                labelExceptOrders : labelExceptions
            });
            //console.log(this.state.labelExceptOrders);
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading label exceptions")
        });
    }

    onSubmit = (e:any) => {
        
        e.preventDefault();
        this.setState({ loading: true });
        let sysopController =new SysopController();
        if(this.state.update.maxApprovers){
            sysopController.updateMaxApprovers(this.state.fields.processName, this.state.fields.maxApprovers)
        }
        if(this.state.update.crossLabels){
            sysopController.deleteCrossLabels(this.state.fields.processName).then(response => {
                this.state.crossLabelProcess.map(data =>{
                    var crossLabelData ={
                        processName: this.state.fields.processName,
                        labelId: data,
                        ownerLabel: this.state.selectfields.ownerLabelId,
                        isOn: '1',
                        defaultDept: this.state.selectfields.department,
                        isBillable: this.state.optionfields.billable,
                        isNonBillableTransfer: this.state.optionfields.nonBillableTransfer
                    }
                    //console.log(crossLabelData);
                    sysopController.insertCrossLabels(crossLabelData);
                })
            }).catch(error => {
                console.error("There was an error")
            });
        }
        if(this.state.update.labelExceptions){
            sysopController.deleteLabelExceptions(this.state.fields.processName).then(response => {
                this.state.labelExceptOrders.map(data =>{
                    var labelExceptionData ={
                        processName: this.state.fields.processName,
                        labelId: data,   
                    }
                    //console.log(labelExceptionData);
                    sysopController.insertLabelExceptions(labelExceptionData);
                })
            })
            .catch(error => {
                console.error("There was an error")
            });
        }
        this.setState({ loading: false });
        alert("Approval process: " + this.state.fields.processName + " updated successfully.")
        this.props.history.push('/admin/home');
    }

    handleChange = (e:any) => {
        let fields = this.state.fields;
        let update = this.state.update;
        if(e.currentTarget.name === "maxApprovers"){
            update['maxApprovers'] = true
            if(e.currentTarget.value <= 4){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                fields[e.currentTarget.name] = 1;
                alert("Maximum limit for max approvers is 4...")
            }
        }
        this.setState({
            fields, update
          });
    }

    handleSelectMultipleChange = (e: any) => {
        var options = e.currentTarget.options;
        var value : string[] = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }
        let update = this.state.update;
        if(e.currentTarget.name === 'labelExceptOrders'){
            this.setState({labelExceptOrders: value});
            update['labelExceptions'] = true;
        }
        if(e.currentTarget.name === 'crossLabelProcess'){
            this.setState({crossLabelProcess: value});
            update['crossLabels'] = true;
        }
        this.setState({ update });
    }

    render(){
        const{ loading } = this.state;
        return(
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row>
                  <Col sm={12}>
                    <Card border="light" style={{ height:'100%' }}>
                        <Card.Body>
                        <Card.Title><h4 className="title">Edit Approval Process</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={4}>
                                    <FormLabelField
                                        properties={{label:"Owner Label",id:"ownerLabelId",name:"ownerLabelId",value:this.state.fields.ownerLabelId,className:"form-control"}}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <FormLabelField
                                        properties={{label:"Assigned Label",id:"assignedLabelId",name:"assignedLabelId",value:this.state.fields.assignedLabelId,className:"form-control"}}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormLabelField
                                        properties={{label:"Department",id:"department",name:"department",value:this.state.fields.department,className:"form-control"}}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Billable Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Free Goods" name="billableProcess" id="freeGoods" disabled  checked={this.state.optionfields.freeGoods} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Billable" name="billableProcess" id="billable" disabled  checked={this.state.optionfields.billable} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Non Billable Transfer" name="billableProcess" id="nonBillableTransfer" disabled  checked={this.state.optionfields.nonBillableTransfer} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Super User Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="superUser"  id="superUserNo" disabled checked={this.state.optionfields.superUserNo} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="superUser"  id="superUserYes" disabled checked={this.state.optionfields.superUserYes} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <FormLabelField
                                        properties={{label:"Process Name",id:"processName",name:"processName",className:"form-control",value:this.state.fields.processName}}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Is this Default for this Department?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="defaultDept"  id="defaultDeptNo" disabled checked={this.state.optionfields.defaultDeptNo} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="defaultDept"  id="defaultDeptYes" disabled checked={this.state.optionfields.defaultDeptYes} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"maxApprovers",label:"Max Approvers",type:"text",name:"maxApprovers",className:"form-control input-sm",maxlength:24, value:this.state.fields.maxApprovers,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.maxApprovers}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Overnight Orders Allowed</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="overnightOrders"  id="overnightOrdersYes" disabled  checked={this.state.optionfields.overnightOrdersYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="overnightOrders"  id="overnightOrdersNo" disabled  checked={this.state.optionfields.overnightOrdersNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Clean Orders Allowed</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="cleanOrders"  id="cleanOrdersYes" disabled checked={this.state.optionfields.cleanOrdersYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="cleanOrders"  id="cleanOrdersNo" disabled checked={this.state.optionfields.cleanOrdersNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Can requestor orders from other label?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="otherLabelOrders"  id="otherLabelOrdersYes" disabled checked={this.state.optionfields.otherLabelOrdersYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="otherLabelOrders"  id="otherLabelOrdersNo" disabled checked={this.state.optionfields.otherLabelOrdersNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Are there labels the requestor may not order from ?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="labelExceptOrders"  id="labelExceptOrdersYes" disabled checked={this.state.optionfields.labelExceptOrdersYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="labelExceptOrders"  id="labelExceptOrdersNo" disabled checked={this.state.optionfields.labelExceptOrdersNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    {this.state.optionfields.labelExceptOrdersYes ?
                                    <Form.Group>
                                        <Form.Label>Which Labels may the requestor NOT order from?</Form.Label>
                                        <Form.Control as="select" multiple id="labelExceptOrders" name="labelExceptOrders" value={this.state.labelExceptOrders} onChange={this.handleSelectMultipleChange}>
                                            {this.state.recordLabel.map((data: any) => {
                                                return(
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    :
                                    <Form.Group>
                                        <Form.Label>Which Labels may the requestor NOT order from?</Form.Label>
                                        <Form.Control as="select" multiple id="labelExceptOrders" name="labelExceptOrders" value={this.state.labelExceptOrders} disabled onChange={this.handleSelectMultipleChange}>
                                            {this.state.recordLabel.map((data: any) => {
                                                return(
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    }
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Is this a Cross-Label Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="crossLabelProcess"  id="crossLabelProcessYes" disabled checked={this.state.optionfields.crossLabelProcessYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="crossLabelProcess"  id="crossLabelProcessNo" disabled checked={this.state.optionfields.crossLabelProcessNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    {this.state.optionfields.crossLabelProcessYes ? 
                                    <Form.Group>
                                        <Form.Label>If this is a cross-label process, which labels does it pertain to?</Form.Label>
                                        <Form.Control as="select" multiple id="crossLabelProcess" name="crossLabelProcess" value={this.state.crossLabelProcess} onChange={this.handleSelectMultipleChange}>
                                            {this.state.recordLabel.map((data: any) => {
                                                return(
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    :
                                    <Form.Group>
                                        <Form.Label>If this is a cross-label process, which labels does it pertain to?</Form.Label>
                                        <Form.Control as="select" multiple id="crossLabelProcess" name="crossLabelProcess" value={this.state.crossLabelProcess} disabled onChange={this.handleSelectMultipleChange}>
                                            {this.state.recordLabel.map((data: any) => {
                                                return(
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    }
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Is this an EXPORT Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="exportProcess"  id="exportProcessYes" disabled checked={this.state.optionfields.exportProcessYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="exportProcess"  id="exportProcessNo" disabled checked={this.state.optionfields.exportProcessNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Please enter a description for this process</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="processDescription" id="processDescription" value={this.state.fields.processDescription} disabled onChange={this.handleChange}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={12} style={{textAlign: "center" , alignSelf: "center"}}>
                                    {this.state.update.maxApprovers || this.state.update.crossLabels || this.state.update.labelExceptions ? 
                                        <Button type="submit" disabled={loading} >
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7"  }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                            {!loading && <span>Update Approval Process</span>}
                                        </Button>
                                    :
                                    <Button disabled={true}><span style={{color:"#3472F7"}}>Update Approval Process</span></Button>
                                    }
                                </Col>
                            </Row>
                            <div className="clearfix" />
                        </Form>
                      </Card.Body>                                  
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
        )
    }
}
interface EditProcessParameter{
    uid?:string;
    pwd?:string;
    history?:History<LocationState>;
    location?:any;
}
interface Istate{
    fields:any;
    errors:any;
    selectfields:any;
    optionfields:any;
    recordLabel:[];
    department:[];
    labelExceptOrders:string[]; 
    crossLabelProcess:string[];
    errMsg:string;
    loading:boolean;
    modal:any;
    update:any;
}