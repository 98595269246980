import React, { Component, CSSProperties} from "react";
import {Container,Card, Row, Col,Table,Form,Alert,Button,Navbar, Nav,FormText} from "react-bootstrap";
import { Variables } from '../../../Variables/Variables';
import {FormLabelField} from '../../CustomComponents/FormLabelField';
import { NavLink, withRouter } from 'react-router-dom'
import '../../../assets/css/Style.css'
import '../../../assets/css/ViewOrder.css'
import * as  CopyToClipboard from "../../../js/CopyToClipboard.js"
import { ViewOrderDetailsParameter, Istate } from "../../../Model/ViewOrderDetails"; 
import{ViewOrderController} from "../../../Controllers/ViewOrderController";
import flag from "../../../assets/img/redflag.gif";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';


export class ViewOrderDetails extends Component<ViewOrderDetailsParameter,Istate> {
    constructor(props:ViewOrderDetailsParameter){      
        super(props)    
        
        this.state={ 
            lblFields: {Order_Id:'',Alias:'',Process_Name:'',ArriveBy:'',BusinessUnit:'',SpecialInstructions:'',Reason:'',DepotComments:'',LegacyOrder:'',
            Status_Id:'',DateShipped:'',ShipTrackNo:'',Name:'',ShipToName:'',AddressLineOne:'',AddressLineTwo:'',City:'',State:'',label:'',
            Zip:'',Country:'',Phone:'',Requestor_Weight:'',Approver_Id:'',Approval_Date:'',Request_Date:'',ApproverComments:'',
            Label_Id:'',Super_Label_Id:'',IP_Address:'',Material:false,Date_Processed:'',Depot_Id:'',Department:'',ApproveBy_Clean:'',ApproveBy_Overnight:'',
            Overnight:'',CustomerNumber:'',RecordLock:'',Depot_Printer:'',Depot_Printer_Date:'',Ship_Method:'',Billable:false,Created:'',
            account_no:'',freight_billing_info:'',alias_Email:'',StatusDesc:'',status_id:'',Recordlabel_Id:'',ntbillablet:false,prePaid:false},        
            lblApproverFields: {approver_Alias:'',approver_Name:'',sales_type:'',alias_Name:'',bill_to_name:'',bill_to_address:'',bill_to_city_state_zip:''},            
            errors: {},            
            errMsg:"",           
            Order_id:"",            
            alias:"", 
            lblNextApprover:"", 
            lblPageTitle:"",   
            salesType:"",
            requestDate:"",
            isPrint:false,
            discount:"",
            clean_54_DISCOUNT_THRESHOLD:.4.toFixed(2),
            clean_56_DISCOUNT_THRESHOLD:.15.toFixed(2),
            clean_59_DISCOUNT_THRESHOLD:.3.toFixed(2),
            clean_66_DISCOUNT_THRESHOLD:.15.toFixed(2),
            displayOrdDetListGrid:[],
            displayAppHistoryList:[],   
            displayDepotHistoryList:[],  
            displayNxtLevelApprover:[],
            modal: {modalSpinnerShow: false},
            formdata: []            
                      
        }  

        this.onSubmit = this.onSubmit.bind(this);
          
    }

    componentWillMount(){

      sessionStorage.setItem('SearchHistoryView', '1' )
      sessionStorage.setItem('ViewOrderStatus', '1' )
      sessionStorage.setItem('OrdersViewStatus', '1' ) 
      var bchk =  sessionStorage.getItem('Bchk')


      if (bchk === '1')
      {
        sessionStorage.setItem('Bchk', '0' ) 
        sessionStorage.setItem('ViewOrderStatusSR', '1' )
      }
      else
      {
        sessionStorage.setItem('ViewOrderStatusSR', '0' ) 
      }

      let oId= "";
      let isPrint = this.state.isPrint;
      let modal = this.state.modal;
      if(this.props.location.state.Order_id != "")   
      {
        oId= this.props.location.state.Order_id;
      } 
      if(this.props.location.state.task_For != "")   
      {
        if(this.props.location.state.task_For === "P") 
        {
          isPrint = true;
        }
        else
        {
          isPrint = false;
          //Load Spinner
          modal['modalSpinnerShow']=true;  
        }
      }
     
      
                
      this.setState({Order_id:oId,isPrint,modal })    
      this.loadOrderData()
    
         
    } 

      

    loadOrderData() {
      let viewOrderController = new ViewOrderController();
      let lblFields = this.state.lblFields;      
      viewOrderController.getOrderSummary(this.props.location.state.Order_id).then(resOrderdata=>{  
        this.loadApproverData()             
        lblFields["Order_Id"] = resOrderdata.Order_Id || "";
        lblFields["Alias"] = resOrderdata.Alias || "";
        lblFields["Process_Name"] = resOrderdata.Process_Name || "";
        lblFields["ArriveBy"] = resOrderdata.ArriveBy || "";
        lblFields["BusinessUnit"] = resOrderdata.BusinessUnit || "";
        lblFields["SpecialInstructions"] = resOrderdata.SpecialInstructions || "";
        lblFields["Reason"] = resOrderdata.Reason || "";
        lblFields["DepotComments"] = resOrderdata.DepotComments || "";
        lblFields["LegacyOrder"] = resOrderdata.LegacyOrder || "";
        lblFields["Status_Id"] = resOrderdata.Status_Id || "";
        lblFields["DateShipped"] = resOrderdata.DateShipped || "";
        lblFields["ShipTrackNo"] = resOrderdata.ShipTrackNo || "";
        lblFields["Name"] = resOrderdata.Name || "";
        lblFields["ShipToName"] = resOrderdata.ShipToName || "";
        lblFields["AddressLineOne"] = resOrderdata.AddressLineOne || "";
        lblFields["AddressLineTwo"] = resOrderdata.AddressLineTwo || "";
        lblFields["City"] = resOrderdata.City || "";
        lblFields["State"] = resOrderdata.State || "";
        lblFields["Zip"] = resOrderdata.Zip || "";
        lblFields["Country"] = resOrderdata.Country || "";
        lblFields["Phone"] = resOrderdata.Phone || "";
        lblFields["Requestor_Weight"] = resOrderdata.Requestor_Weight || "";
        lblFields["Approver_Id"] = resOrderdata.Approver_Id || "";
        lblFields["Approval_Date"] = resOrderdata.Approval_Date || "";
        lblFields["Request_Date"] = resOrderdata.Request_Date || "";
        lblFields["ApproverComments"] = resOrderdata.ApproverComments || "";
        lblFields["Label_Id"] = resOrderdata.Label_Id || "";
        lblFields["Super_Label_Id"] = resOrderdata.Super_Label_Id || "";
        lblFields["IP_Address"] = resOrderdata.IP_Address || "";
        lblFields["Material"] = Boolean(Number(resOrderdata.Material));
        lblFields["Date_Processed"] = resOrderdata.Date_Processed || "";
        lblFields["Depot_Id"] = resOrderdata.Depot_Id || "";
        lblFields["Department"] = resOrderdata.Department || "";
        lblFields["ApproveBy_Clean"] = resOrderdata.ApproveBy_Clean || "";
        lblFields["ApproveBy_Overnight"] = resOrderdata.ApproveBy_Overnight || "";
        lblFields["Overnight"] = resOrderdata.Overnight || "";
        lblFields["CustomerNumber"] = resOrderdata.CustomerNumber || "";
        lblFields["RecordLock"] = resOrderdata.RecordLock || "";
        lblFields["Depot_Printer"] = resOrderdata.Depot_Printer || "";
        lblFields["Depot_Printer_Date"] = resOrderdata.Depot_Printer_Date || "";
        lblFields["Ship_Method"] = resOrderdata.Ship_Method || "";
        lblFields["Billable"] = Boolean(Number(resOrderdata.Billable));
        lblFields["Created"] = resOrderdata.Created || "";
        lblFields["account_no"] = resOrderdata.account_no || "";
        lblFields["freight_billing_info"] = resOrderdata.freight_billing_info || "";
        lblFields["alias_Email"] = resOrderdata.alias_Email || "";
        lblFields["alias_Name"] = resOrderdata.alias_Name || "";
        lblFields["StatusDesc"] = resOrderdata.StatusDesc || "";
        lblFields["status_id"] = resOrderdata.status_id || "";
        lblFields["Recordlabel_Id"] = resOrderdata.Recordlabel_Id || "";
        lblFields["ntbillablet"] = Boolean(Number(resOrderdata.ntbillablet)); 
        lblFields["sales_type"] = resOrderdata.sales_type || "";
        lblFields["bill_to_name"] = resOrderdata.bill_to_name || "";
        lblFields["bill_to_address"] = resOrderdata.bill_to_address || "";
        lblFields["bill_to_city_state_zip"] = resOrderdata.bill_to_city_state_zip || "";
        lblFields["prePaid"] = Boolean(Number(resOrderdata.prepaid));        
        lblFields["label"] = resOrderdata.label|| "";
        this.setState({lblFields});                     
      }).catch(error => {

      })

     
    }

    loadApproverData() {
      let viewOrderController = new ViewOrderController();
      let lblApproverFields = this.state.lblApproverFields; 
      let modal = this.state.modal;     
      viewOrderController.getNextApprover(this.props.location.state.Order_id).then(jsonNxtApproverdata=>{
       
        this.setState({                           
          displayNxtLevelApprover : JSON.parse(JSON.stringify(jsonNxtApproverdata))
      });
       
      let approver = ""; 
     this.state.displayNxtLevelApprover.map((prop, key) => {        
        if(prop["Approver"] != "")
        {
          if(approver === "")
          {
          approver = prop["Approver"];
          }
          else
          {
          approver = approver + "," + prop["Approver"];
          }          
        }     
      })       
                 
      let lblNextApprover = ""
      lblNextApprover = this.state.lblFields.StatusDesc + " (" + approver + " )"
      this.loadLabelData()
            
      this.setState({lblNextApprover :lblNextApprover});  
        this.setState({lblApproverFields});

        viewOrderController.getApprovalHistory(this.props.location.state.Order_id).then(jsonAppHisList=>{
          this.setState({                           
            displayAppHistoryList : JSON.parse(JSON.stringify(jsonAppHisList))
        });
              viewOrderController.getOrderDetails(this.props.location.state.Order_id).then(jsonOrdDetList=>{
                this.setState({                           
                  displayOrdDetListGrid : JSON.parse(JSON.stringify(jsonOrdDetList))
              });
                      viewOrderController.getOrderDepotHistory(this.props.location.state.Order_id).then(jsonDepotHisList=>{
                        this.setState({                           
                          displayDepotHistoryList : JSON.parse(JSON.stringify(jsonDepotHisList))
                      });
                        if(this.state.isPrint)
                        {         
                          window.print();
                          alert('Your Order has been Submitted');
                          this.props.history.push('/admin/home');
                        }
                        //Stop Spinner
                        modal['modalSpinnerShow']=false;
                        this.setState({ modal });

                      }).catch(error => {
                        console.error("There was an error loading Approval History")
                      })
              }).catch(error => {
                console.error("There was an error loading Order Details")
              })
         }).catch(error => {
          console.error("There was an error loading Approval History")
        })
                 
        
      }).catch(error => {

      })
    }

    
    loadLabelData()
    {
      let billable = this.state.lblFields.Billable
      let ntBillable = this.state.lblFields.ntbillablet
      let material = this.state.lblFields.Material
      var str =this.state.lblFields.sales_type
      var salesType = str.split(" ");      
      this.setState({salesType : salesType[0]})    
      var dateString = this.state.lblFields.Request_Date
      var date = new Date(dateString);
     
      var curr_date = date.getDate();
      var curr_month = date.getMonth() + 1; //Months are zero based
      var curr_year = date.getFullYear();
      var reqDate = curr_month + "/" + curr_date + "/" + curr_year;
      this.setState({requestDate : date.toLocaleString()})
     if (billable)
			{
				this.setState({lblPageTitle : "BILLABLE ORDER REQUEST FORM"})
				//sProcessName=dataSet.spGetOrderSummary[0].Process_Name;
			}
			else if(!billable&&!ntBillable)
			{
				this.setState({lblPageTitle :  "FREE GOODS REQUEST FORM"})
				//this place needs to be change
			}
			else
			{
				this.setState({lblPageTitle :  "NON BILLABLE TRANSFER FORM"})				
      }
      
      if (this.state.lblFields.sales_type === "66 Ticket Bundling") {
        this.setState({ lblPageTitle: "Ticket Bundling" })
      }
      if (this.state.lblFields.sales_type === "89 Sales Type") {
        this.setState({ lblPageTitle: "D2C Form" })
      }
    }

    loadShipTo()
    {
      return (
        <div>        
          <p className="small">{this.state.lblFields.Name}</p>
          <p className="small">{this.state.lblFields.ShipToName}</p>
          <p className="small">{this.state.lblFields.AddressLineOne}</p>
          <p className="small">{this.state.lblFields.AddressLineTwo}</p>
          <p className="small">{this.state.lblFields.City}&nbsp;{this.state.lblFields.State}&nbsp;{this.state.lblFields.Zip}</p>
          <p className="small">{this.state.lblFields.Country}</p>
          <p className="small">{this.state.lblFields.Phone}</p>
        </div>
      );    
    }   

    loadBillTo()
    {
      return (
        <div >        
          <p className="small">{this.state.lblFields.bill_to_name}</p>
          <p className="small">{this.state.lblFields.bill_to_address}</p>
          <p className="small">{this.state.lblFields.bill_to_city_state_zip}</p>          
        </div>
      );    
    } 

    convertDateTimeFormat=(item) =>{
      if(item["ApprovalDateTime"] != "")
      {
        var dt = item["ApprovalDateTime"];
        var date = new Date(dt);
        return date.toLocaleString() + " :";
      }
    }

    calculatePercent=(item)=>{      
      if(item["Price_Approved"]>0 && item["Price_Approved"]<=item["cost"])
					{	
            var strDiscount =  (item["cost"] - item["Price_Approved"]) / item["cost"];					
              var num = strDiscount*100 ;               
              return num.toFixed();
					}
    }

    displayFlag=(item)=>{
      
      var strDiscount =  (item["cost"] - item["Price_Approved"]) / item["cost"];	
       var num = strDiscount*100 ;               
       
      if(this.state.lblFields.sales_type === "54 - Clean (non-royalty bearing)")
      {
        if (item["Price_Approved"]>0)
        {
          if(item["Price_Approved"]<=item["cost"])
          {
            if(strDiscount.toFixed(2) != this.state.clean_54_DISCOUNT_THRESHOLD)
            {
              return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
            }
            else 
            return "";
          }
          else
          {
            return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
          }
        }
        else
        {								 
          return "";
        }
      }
      else if(this.state.lblFields.sales_type === "56 - Clean (fully royalty bearing)")
      {
        if (item["Price_Approved"]>0)
        {
          if(item["Price_Approved"]<=item["cost"])
          {
            if(strDiscount.toFixed(2) > this.state.clean_56_DISCOUNT_THRESHOLD)
            {
              return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
            }
            else 
             return "";
          }
          else
          {
            return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
          }
        }
        else
        {
          return "";
        }
      }
      else if(this.state.lblFields.sales_type === "59 - Clean (publisher royalties only)")
      {
        if (item["Price_Approved"]>0)
        {
          if(item["Price_Approved"]<=item["cost"])
          {
            if(strDiscount.toFixed(2) != this.state.clean_59_DISCOUNT_THRESHOLD)
            {
              return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
            }
            else 
              return "";
          }
          else
          {
            return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
          }
        }
        else
        {
          return "";
        }
      }
      else if(this.state.lblFields.sales_type === "66 Ticket Bundling")
      {
        if (item["Price_Approved"]>0)
        {
          if(item["Price_Approved"]<=item["cost"])
          {
            if(strDiscount.toFixed(2) > this.state.clean_66_DISCOUNT_THRESHOLD)
            {
              return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
            }
            else 
              return "";
          }
          else
          {
            return (<img src={flag} alt='Product discount requires EVP approval' title='The discount % for this product will require approval of EVP & CFO of North America'></img>);
          }
        }
        else
        {
          return "";
        }
      }
    }


    onSubmit = (e: any) => {
      
      e.preventDefault();
      
      CopyToClipboard.CopyToClipboard("copycontent")

          
    }
  
   render(){  
    let billable = this.state.lblFields.Billable
    let ntBillable = this.state.lblFields.ntbillablet
    let material = this.state.lblFields.Material    
    const bottomBorder = {
      borderBottom: "1px solid rgb(212, 212, 212)" 
    };    
    const labelFont = {
      fontSize: "5px",
    }; 
        return (
            <div className="content" id="copycontent">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
             <Container fluid >                        
             <Row>
              <Col md={12}>
                <Card border="light" style={{ height:'100%' }}>
                  <Card.Body>
                  <Button type="button" style={{marginBottom: '15px'}} onClick={() => this.props.history.goBack()} >Back</Button>
                  <Card.Title><h4 className="title">View Order Details</h4></Card.Title>                  
                    <form onSubmit= {this.onSubmit}>
                      <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                        <Row style={bottomBorder}>
                         <Col>                                                              
                          <Row className="small">                            
                            <Col sm={6} className="small">
                              <FormLabelField 
                                properties={{label:"Next Step:",id:"nextStep",name:"nextStep",className:"form-control",value:this.state.lblNextApprover}}
                              />
                            </Col>
                            <Col sm={5} className="small">
                              <FormLabelField
                                properties={{label:"Legacy Order:",id:"legacyOrder",name:"legacyOrder",className:"form-control",value:this.state.lblFields.LegacyOrder}}
                              />
                            </Col>   
                            <Col sm={1} >
                            <Button type="submit"  value="CopyToClipboard" >
                              Copy
                            </Button>
                           

                            </Col>                        

                          </Row>
                          <Row className="small">
                           <Col sm={6} className="small">
                              <Form.Label>Approvers:</Form.Label>
                                  {this.state.displayAppHistoryList.map((prop, key) => { 
                                    return (
                                      <tr key={key}>                                                                     
                                        <td>{this.convertDateTimeFormat(prop)}&nbsp;{prop["Approver"]}</td> 
                                      </tr>
                                    )            
                                    })}
                             </Col>
                             <Col sm={6} className="small">
                              <Form.Label>Depot:</Form.Label>
                                  {this.state.displayDepotHistoryList.map((prop, key) => { 
                                    return (
                                      <tr key={key}>                                                                     
                                        <td>{prop["Notes"]}</td> 
                                      </tr>
                                    )            
                                    })}
                             </Col>
                          </Row>
                         </Col>                          
                        </Row>
                        <Row style={bottomBorder} className="small">
                        <Col sm={12} className="align-center">
                          <FormLabelField
                            properties={{label:"",id:"lblPageTitle",name:"lblPageTitle",className:"form-control",value:this.state.lblPageTitle}}
                          />
                           <h4 className="align-center" style={{ color:'maroon' }}>{this.state.salesType}</h4>
                          </Col>
                        </Row>
                        <Row  style={bottomBorder} className="small">
                          <Col sm={3} className="small">
                            <FormLabelField
                              properties={{label:"Request Date:",id:"reqDate",name:"reqDate",className:"form-control",value:this.state.requestDate}}
                            />
                          </Col>
                          <Col sm={3} className="small">
                            <FormLabelField
                              properties={{label:"Customer P.O:",id:"customerPo",name:"customerPo",className:"form-control",value:this.state.lblFields.Order_Id}}
                            />
                          </Col>
                          <Col sm={3} className="small">
                            <FormLabelField
                              properties={{label:"Sales Type:",id:"salesType",name:"salesType",className:"form-control",value:this.state.lblFields.sales_type}}
                            />
                          </Col>
                          <Col sm={3} className="small">
                            <FormLabelField
                              properties={{label:"Shipment Method:",id:"shipMethod",name:"shipMethod",className:"form-control",value:this.state.lblFields.Ship_Method}}
                            />
                          </Col>
                          { billable ?
                            <Col sm={3} className="small">
                              <FormLabelField
                                properties={{label:"Freight Billing Info:",id:"freightBillingInfo",name:"freightBillingInfo",className:"form-control",value:this.state.lblFields.freight_billing_info}}
                              />
                            </Col> :
                            ""
                          }                          
                        </Row>
                        <Row  style={bottomBorder} className="small">
                          <Col className="small">
                              <Row>
                                <Col sm={3}>
                                  <FormLabelField
                                    properties={{label:"Ordered By:",id:"orderedBy",name:"orderedBy",className:"form-control",value:this.state.lblFields.alias_Name}}
                                  />
                                </Col>
                                <Col sm={3}>
                                  <FormLabelField
                                    properties={{label:"Account No:",id:"accNo",name:"accNo",className:"form-control",value:this.state.lblFields.account_no}}
                                  />
                                </Col>
                                <Col sm={3}>
                                  <FormLabelField
                                    properties={{label:"Customer No:",id:"cusNo",name:"cusNo",className:"form-control",value:this.state.lblFields.CustomerNumber}}
                                  />
                                </Col>
                                <Col sm={3}>
                                  <FormLabelField
                                    properties={{label:"Business Unit:",id:"busUnit",name:"busUnit",className:"form-control",value:this.state.lblFields.BusinessUnit}}
                                  />
                                </Col>

                            </Row>
                            <Row>
                            <Col sm={3}>
                              <FormLabelField
                                properties={{label:"Department:",id:"department",name:"department",className:"form-control",value:this.state.lblFields.Department}}
                              />
                            </Col>
                            { billable ?
                            <Col sm={3} className="small">
                              <FormLabelField
                                properties={{label:"Pre-Paid:",id:"prePaid",name:"prePaid",className:"form-control",value:this.state.lblFields.prePaid?"Yes" : "No"}}
                              />
                            </Col> :
                            ""
                          }    
                            <Col sm={6}>
                            <FormLabelField
                                properties={{label:"SPecial Instructions:",id:"spIns",name:"spIns",className:"form-control",value:this.state.lblFields.SpecialInstructions}}
                              />
                            </Col>
                            </Row>
                          </Col>           
                        </Row>
                        <Row style={bottomBorder} className="small">
                           <Col sm={4} >
                            <Form.Label>Bill To:</Form.Label>
                            {this.loadBillTo()}
                          </Col>
                          <Col sm={4}>
                            <Form.Label>Ship To:</Form.Label>
                            {this.loadShipTo()}
                          </Col>
                       </Row> 
                        <Row style={bottomBorder} className="small">
                          <Col sm={12}>
                            <Row>
                            <Col sm={3} className="small">
                              <FormLabelField
                                properties={{label:"Order Id:",id:"ordId",name:"ordId",className:"form-control",value:this.state.lblFields.Order_Id}}
                              />
                            </Col>
                            <Col sm={3} className="small">
                              <FormLabelField
                                properties={{label:"Label:",id:"label",name:"label",className:"form-control",value:this.state.lblFields.label}}
                              />
                            </Col>                            
                            </Row>
                            <Row>
                              <Col sm={12}className="small">
                                <div className="scroll" style={{ width: '100%' }}>
                                  <Table>
                                    <thead>
                                    <tr>
                                    { billable && material ?
                                      new Variables().thOrderDetailBillableCleanList.map((prop, key) => {
                                                  return <th key={key}>{prop}</th>;
                                                }):
                                       billable  ?
                                        new Variables().thOrderDetailBillableList.map((prop, key) => {
                                                    return <th key={key}>{prop}</th>;
                                                  }):
                                        new Variables().thOrderDetailList.map((prop, key) => {
                                          return <th key={key}>{prop}</th>;
                                        })
                                      
                                    }
                                    
                                    </tr>                         
                                  </thead>
                                  <tbody >                           
                                      {this.state.displayOrdDetListGrid.map((prop, key) => { 
                                          return (
                                            <tr className="small" style={labelFont} key={key}>
                                              <td >{prop["Selection_Id"]}</td>                                    
                                              <td>{prop["ReleaseDate"]}</td>
                                              <td>{prop["label_id"]}</td> 
                                              <td>{prop["Artist"]}</td>                                    
                                              <td>{prop["Title"]}</td>                                 
                                              <td>{prop["Configuration"]}</td>
                                              <td>{prop["unitstoset"]}</td>  
                                              <td>{prop["Quantity_Requested"]}</td> 
                                              <td>{prop["Quantity_Approved"]}</td>
                                             {billable ?<td>${prop["Price_Requested"]}</td>: "" }
                                             {billable ?<td>${prop["Price_Approved"]}</td>: "" } 
                                            <td>{prop["itemStatusDesc"]}</td> 
                                            { billable && material ? <td>{prop["cost"]}</td>: "" } 
                                            { billable && material ? <td>{this.calculatePercent(prop)}</td>: "" }
                                            { billable && material ? <td>{this.displayFlag(prop)}</td>: "" }                               
                                            </tr>
                                          )            
                                          })} 
                                      </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </Col> 
                        </Row>
                        <Row className="small">
                          <Col sm={9} className="small">
                              <FormLabelField
                                properties={{label:"Reason:",id:"reason",name:"reason",className:"form-control",value:this.state.lblFields.Reason}}
                              />
                          </Col>
                          <Col sm={3} className="small">
                            <FormLabelField
                                  properties={{label:"Sales Type:",id:"sales_Type",name:"sales_Type",className:"form-control",value:this.state.lblFields.sales_type}}
                                />    
                          </Col>
                        </Row>
                        <Row style={bottomBorder} className="small">
                          <Col sm={7}>                          
                            <Table className="small">
                              <thead>
                              <tr>
                              {new Variables().thApproverList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}                   
                              </tr>                         
                            </thead>
                            <tbody>                           
                                {this.state.displayAppHistoryList.map((prop, key) => { 
                                    return (
                                      <tr style={labelFont} key={key}>
                                      <td>{prop["ApproverType"]}</td>
                                      <td>{prop["Approver"]}</td>                                   
                                      <td>{this.convertDateTimeFormat(prop)}</td> 
                                      <td>{prop["Notes"]}</td>                                   
                                      </tr>
                                    )            
                                    })} 
                              </tbody>  
                            </Table>
                               
                          </Col>
                        </Row>                        
                       </form>
                  </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
             </Container>
            </div>
        )        
    }
}

export default withRouter(ViewOrderDetails);