import React, { Component } from "react";
import {Container,Card,Row,Col,Form,Alert,Button} from "../../../../node_modules/react-bootstrap";
import imgBGDisplay from '../../../assets/img/universal.png';
import { Redirect } from 'react-router-dom';
import '../../../assets/css/Style.css'


export class Home extends Component<IHome,Istate> 
{
  constructor(props){ 
    super(props)
    const userLogin = sessionStorage.getItem("userLogin")
    let loggedIn = true;
    if (userLogin == null){
        loggedIn = false;
    } 
    this.state = {
        loggedIn
    }
  } 
    render() {      
        if (this.state.loggedIn === false){
          return <Redirect to="/" />
        }
        const styleAlignBG={
            "max-width":"50rem",
            "width":"100%",
            "margin": "0 0 50% 0"
        }
        const divStyle = {
          width: '100%',
          height: '500px',
          backgroundImage: `url(${imgBGDisplay})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };
        const instructionFontColor={
          "color" : "#000000!important",
          "backgroundColor" : "#F8E9A1"
        }
        const TicketBundlingWarn=()=>{
                    
          return <Card border="warning" style={{ width: '100%',border:"0px" }}>            
                  <Card.Body className="small text-dark" style={instructionFontColor}>
                      <div><b>Ticket Bundle Order</b></div>
                      <div>If you are placing a Ticket Bundle Order, please log out and log into the current system with your correct account to place your orders.</div>
                      <div><b>D2C Order</b></div>
                      <div>If you are placing a D2C Order, please log out and log into the current system with your correct account to place your orders.</div>  
                      <div></div>
                      
                  </Card.Body>
                </Card>
                
        }
        const TBWarn=()=>{
                    
          return <Card border="warning" style={{ width: '100%',border:"0px" }}>            
                  <Card.Body className="small text-dark" style={instructionFontColor}>
                      <div><b>Ticket Bundle Order</b></div>
                      <div>If you are placing a Ticket Bundle Order, please log out and log into the current system with your correct account to place your orders.</div>
                      
                      </Card.Body>
                </Card>
                
        }
        const D2CWarn=()=>{
                    
          return <Card border="warning" style={{ width: '100%',border:"0px" }}>            
                  <Card.Body className="small text-dark" style={instructionFontColor}>
                       <div><b>D2C Order</b></div>
                      <div>If you are placing a D2C Order, please log out and log into the current system with your correct account to place your orders.</div>                  
                     
                  </Card.Body>
                </Card>
                
        }
        const displayPasswordWarning =()=>{
            let PasswordVerify: string | null = sessionStorage.getItem("PasswordVerify") || "";
            if(PasswordVerify === "0"){
            return <div className="content" style={{height:"690px"}}>
              <Container fluid>
              <Row>
                <Col sm={12}>
                {(sessionStorage.getItem("user_type")!='TB' && sessionStorage.getItem("user_type")!='S89')?TicketBundlingWarn():""}
                {(sessionStorage.getItem("user_type") =='TB')? D2CWarn():""}
                {(sessionStorage.getItem("user_type") =='S89')? TBWarn():""}
                  {/* <Card border="light" style={{ width: '100%' }}>
                    <Card.Body>
                      <Form>
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <div><div className="card border-danger mb-3" style={styleAlignBG}>
                                <div className="card-header card-title text-danger font-weight-bold">Kindly update your password with Password Policy </div>
                                <div className="card-body text-dark">
                                    <p className="card-text small">1. Length of the password should be minimum of 8 character and maxmimum of 15 character </p>
                                    <p className="card-text small">2. Password should contain at-least one special character. Example :(~`!@\#$%^&*()+=) </p>
                                    <p className="card-text small">3. Password should contain at-least one upper case </p>
                                    <p className="card-text small">4. Password should contain at-least one lower case </p>
                                    <p className="card-text small">5. Password should contain at-least one number </p>
                                </div>
                                </div></div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <div className="clearfix" />  
                      </Form>
                     </Card.Body>                                  
                  </Card> */}
                </Col>
              </Row>
            </Container>
          </div>
          }
          else
          {
            return <div className="content">
                    <Container fluid>
                      <Row>
                        <Col sm={12}>
                        {(sessionStorage.getItem("user_type")!='TB' && sessionStorage.getItem("user_type")!='S89')?TicketBundlingWarn():""}
                        {(sessionStorage.getItem("user_type") =='TB')? D2CWarn():""}
                        {(sessionStorage.getItem("user_type") =='S89')? TBWarn():""}
                        
                          <Card border="light" style={{ width: '100%' }}>
                           <Card.Body>
                           <div><b>System Messages</b></div>
                            <div className="text-dark font-size-s small" style={{marginTop:"20px",paddingLeft:"20px"}}>No New Messages</div>

                           </Card.Body>                                  
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                </div>
          }
        }
        return (<>
                {displayPasswordWarning()}
                </>
        );
      }
    }

    interface Istate{
      loggedIn:boolean;
    }
    
    interface IHome{
      PasswordVerify:number;
    }