
export function CopyToClipboard(containerid) {
  
    if (document.selection)
     {
      
      var range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand("copy");
    } 
    else if (window.getSelection)
     {
     
     // var range = document.createRange();
     // range.selectNode(document.getElementById(containerid));
     // window.getSelection().addRange(range);

      var selection = window.getSelection();    // Save the selection.
      var range = document.createRange();
      range.selectNodeContents(document.getElementById(containerid));
      selection.removeAllRanges();          // Remove all ranges from the selection.
      selection.addRange(range);

      document.execCommand("copy");    
    }
  }
  