//import {FieldGroup}  from './FieldGroup'
import {FormControl,FormLabel,FormGroup,InputGroup,Button} from "react-bootstrap";
import React,{ Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export class FormSelectSearch extends Component<IFormSelectSearch> {
  render() { 
    const {errorMsg,fieldRequired,defaultOption,fillOption,loading} = this.props;
    const {id,label} = this.props.properties;

    //
    let GetMandatoryGroupField=(fldReqd)=>{
      if(fldReqd===true) 
      {return (<span className="text-danger">*</span>)}
      else
       return (<span></span>);
    }
    
    const DisplayErrorMessageGroupField=(errMsg)=>{
      if(errMsg!=='') 
      {return <div className="text-danger font-size-xs small font-weight-bold">{errMsg}</div>}
      else
      return (<></>);
    }

   const DefaultValueOption=(defOption)=>{
      if(defOption!=='') return <option>{defOption}</option>
    }

   let renderOptions = fillOption.map((data) =>
              <option key={data.id} value={data.id}>{data.name}</option>
        );

      //
      return(<div id={"div"+id} >
        <FormGroup style={{paddingRight:'15px'}}>
          <FormLabel>{label}</FormLabel>
          {GetMandatoryGroupField(fieldRequired)}
          <InputGroup>
            <FormControl as="select" {...this.props.properties}>
              {DefaultValueOption(defaultOption)}
              {renderOptions}
            </FormControl>
            <InputGroup.Append>
                <Button disabled={loading} style={{float:"left",height:'40px',border: '2px solid',backgroundColor:'transparent',borderColor: '#1D62F0',color:'#1D62F0',cursor:'pointer'}} {...this.props.buttonProperties}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ color:"#3472F7" }}
                        />)}
                        {!loading && <span><FontAwesomeIcon icon={faSearch} size="1x"/></span>}
                </Button>    
            </InputGroup.Append>
          </InputGroup>
          {DisplayErrorMessageGroupField(errorMsg)}
        </FormGroup>
        </div>)
      }
    }

  interface IFormSelectSearch
  {
      properties:IProperties;
      buttonProperties:IButtonProperties;
      errorMsg?:string;
      fieldRequired?:boolean;
      defaultOption:string;
      fillOption:any;
      loading?:boolean
  }
  interface IProperties
  {
    id:string;
    label:string;
    type?:any;
    name:string;
    className?:string;
    onChange:any;
    value?:string;
  }
  interface IButtonProperties
  {
      id:string;
      name:string;
      value?:string;
      className?:string;
      disabled?:boolean;
      onClick?:any;
  }
  interface Istate
  {

  }