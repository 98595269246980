import React,{ Component } from 'react';
import {Modal, Button, Row, Col} from "react-bootstrap";

export class IdleTimeOutModal extends Component<IIdleTimeOutModal> {
    render() { 
        //
        const {showModal, handleClose, handleLogout}  = this.props;
        //Clear session variables
        return (
          <Modal show={showModal} centered>
              <Modal.Header>Session TimeOut</Modal.Header>
              <Modal.Body>Your Session has been timed out, Please login again</Modal.Body>
              <Modal.Footer>
              <Button variant="danger" onClick={handleLogout}>
                  Ok
              </Button>
              </Modal.Footer>
          </Modal>
      )
      }
    }

interface IIdleTimeOutModal
  {
    showModal:boolean;
    handleClose:any;
    handleLogout:any;
  }
  interface IProperties
  {
      
  }
  interface Istate
  {

  }