import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class CommonController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getAllLabel(){
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getAllLabel;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string; "ownerLabel": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { RecordLabel_Id: string; Name: string; Owner_label: string }, _index: number){
                    jsonLabel.push({"id": item.RecordLabel_Id, "name": item.Name, "ownerLabel": item.Owner_label ? "Yes" : "No"})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getAllDeptForLabel(label : string){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getAllDeptForLabel;
        var data = {
            "recordLabelId": label
        }
        return this.serviceProxy.get(url,data)
            .then(response => {
                var jsonDept : Array<{"id": string, "name": string, "label": string, "customerNumber": string, "businessUnit": string}> = [];
                if(response.success){
                    response.recordset.forEach(dept);
                    function dept(item: { Department: any; Label: any; CustomerNumber: any; BusinessUnit: any }, index: number){
                        jsonDept.push({"id": item.Department, "name": item.Department, "label": item.Label, "customerNumber": item.CustomerNumber, "businessUnit": item.BusinessUnit})
                    }
                    return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getAllDepartment(){
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getAllDepartment;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonDept : Array<{"id": string, "name": string}> = [];
            if(response.success){
                response.recordset.forEach(dept);
                function dept(item: { Department: string }, _index: number){
                    jsonDept.push({"id": item.Department, "name":item.Department})
                }
                return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getApprovalProcess(processName : string){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getApprovalProcess;
        var data = {
            "processName": processName
        }
        return this.serviceProxy.post(url,data)
            .then(response => {
                if(response.success){
                    var resApprovalProcess = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                    return resApprovalProcess;
                }
                else {
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getSelfApprovers(uid : string){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getSelfApprovers;
        var data = {
            "uid": uid
        }
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    var resSelfApprovers = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                    return resSelfApprovers;
                }
                else {
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getProcessLookupUserType(processName : string, uid :string){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getProcessLookupUserType;
        var data = {
            "processName": processName,
            "alias": uid
        }
        return this.serviceProxy.get(url,data)
            .then(response => {
                var resProcessLookupUserType = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resProcessLookupUserType;
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getLabel(recordLabelId : string){
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getLabel;
        var data = {
            "recordLabelId" : recordLabelId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resLabel = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resLabel;
            }
            else {
                return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        })
    }

}
