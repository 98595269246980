import React, { Component } from "react";
import {History, LocationState} from "History";
import {Row,Col,Container, Card, Button, Form, Alert} from "react-bootstrap";
import {LoginNavBar} from '../../CustomComponents/NabvBar/LoginNavBar'
import {FormInputs} from '../../CustomComponents/FormInput'
import "../../../assets/css/LoginPage.css"
import "../../../assets/css/Style.css";
import {EmailPasswordController} from "../../../Controllers/EmailPasswordController"
import { EmailParameter, Istate } from "../../../Model/EmailPassword";
import {alert} from 'react-bootstrap-confirmation';

export class EmailPassword extends Component<EmailParameter,Istate> {
  redBorder:string;
  defaultBorder:string;
  constructor(props:EmailParameter){
    super(props)
    //Change Border color Red, if alert triggered in onBlur Event
    this.redBorder = "1px solid #DC3545";
    this.defaultBorder = "1px solid #E3E3E3";
    this.state={
      fields: { email:'' },
      errors: { email:'' },
      errMsg:"",
      loading: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
    
  handleChange(e:any){
    let fields= this.state.fields;
    fields[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ fields });
  }

  handleBlur(e:any){
    e.preventDefault();
    const target = e.currentTarget;
    target.style.border="1px solid #E3E3E3";
    if(e.currentTarget.name === "email" && (e.currentTarget.value).length > 0){
      var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(!regexEmail.test(e.currentTarget.value)){
        alert("Please enter the valid email address")
        target.style.border="1px solid #DC3545";
      }
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "You must type an e-mail address. Please try again with a real e-mail address.";
      this.setState({ loading: false });
    }
    if(fields["email"] !== undefined && fields["email"].length > 0 && formIsValid){
      var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(!regexEmail.test(fields["email"])){
        alert("Please enter the valid email address")
        formIsValid = false;
      }
    }
    this.setState({ errors });
    return formIsValid;
  }

  onSubmit = (e:any) => {
    let errors = this.state.errors;
    errors["email"] = "";
    this.setState({ errors });
    this.setState({ loading: true });
    e.preventDefault();
    if (this.validateForm()) {
      let emailPasswordController = new EmailPasswordController();
      emailPasswordController.getPasswordDetails(this.state.fields.email)
      .then((response : any) => {
        this.setState({errMsg : response.msg, loading:false});
      }).catch(error => {
        console.error("Error is email password...," + error);
      })
    } 
  }
  handleClick() {
    this.props.history.push("/");
  }
  
  render() {
    const { loading } = this.state;
    const alignCardCenter = {
      "margin": "10% 0 19% 0"
    };
    const changebgColor = {
      backgroundColor:"rgba(203, 203, 210, 0.15)"
    };
    return (
      <div className="wrapper">
        <div id="main-panel" className="login-panel" ref="mainPanel">
          <LoginNavBar
            {...this.props}
            brandText={'eGratis Promotional Product Request'}
          />
          <div className="content" style={changebgColor}>
            <Container>
              <Row className="small" style={alignCardCenter}>
                <Col sm={1} md={3} lg={3}></Col>
                <Col sm={10} md={6} lg={6}>
                  <Card border="light" style={{ width: '100%'}}>
                    <Card.Body>
                    <Card.Title><h4 className="title">E-mail My Password</h4></Card.Title>
                        <Form onSubmit = {this.onSubmit}>
                          <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                          <Row>
                            <Col sm={12}>                            
                              <FormInputs
                                properties={{id:"email",label:"E-mail",type:"email",name:"email",className:"form-control input-sm",maxlength:50,onChange:this.handleChange,onBlur:this.handleBlur}}
                                errorMsg={this.state.errors.email}
                                fieldRequired={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <div className="form-group mt-0 mb-0 text-center">
                                <Button type="submit" disabled={loading}>
                                  {loading && (
                                  <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px", color:"#3472F7" }}
                                  />
                                  )}
                                  {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                  {!loading && <span>E-Mail It!</span>}
                                </Button>
                                <Button style={{marginLeft:'5px'}} onClick={this.handleClick.bind(this)}>Cancel</Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <div className="align-self-center" style={{textAlign: "justify", paddingTop:"10px"}}>
                                <div className="text-danger font-size-xs small">Type your e-mail address in the blank above and click "E-Mail It."  If there is an e-mail address match for your login in our records, we will e-mail your login information to you.</div>
                              </div>
                            </Col>
                          </Row>
                          <div className="clearfix" />
                        </Form>
                      </Card.Body>                                  
                  </Card>
                </Col>
                <Col sm={1} md={3} lg={6}></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
  
