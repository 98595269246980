import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container,Card, Row, Col, Form, Button, Table, Alert} from "react-bootstrap";
import {FormInputs} from '../../CustomComponents/FormInput';
import {FormSelect} from '../../CustomComponents/FormSelect';
import {FormLabelField} from "../../CustomComponents/FormLabelField";
import '../../../assets/css/Style.css';
import {CommonController} from '../../../Controllers/CommonController';
import {SysopController} from '../../../Controllers/SysopController';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class CreateApprovalProcess extends Component<CreateProcessParameter,Istate> {
    constructor(props:CreateProcessParameter){
        super(props);
        this.state = {
            fields:{processName:'', maxApprovers:'1', processDescription:''},
            errors:{ownerLabelId:'', assignedLabelId:'', processName:'', department:'', maxApprovers:''},
            selectfields:{ownerLabelId:'', ownerLabelName:'', assignedLabelId:'', assignedLabelName:'', department:''},
            optionfields: {defaultDeptNo:true, defaultDeptYes:false, superUserNo:true, superUserYes:false, freeGoods:true, billable:false, nonBillableTransfer:false, overnightOrdersYes:true, overnightOrdersNo:false,cleanOrdersYes:true, cleanOrdersNo:false, allLabelsYes:true, allLabelsNo:false, exceptionsYes:true, exceptionsNo:false, crossLabelsYes:false, crossLabelsNo:true, exportProcessYes:false, exportProcessNo:true },
            recordLabel:[],
            department:[],
            exceptions:[], 
            crossLabels:[],
            errMsg:'',
            loading: false,
            modal: {modalSpinnerShow: false}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let commonController = new CommonController();
        commonController.getAllLabel().then(jsonLabel => {
            this.setState({                           
                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading operating company...", error)
        });
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        if (!fields["processName"]) {
            formIsValid = false;
            errors["uid"] = "Please enter processName";
            this.setState({ loading: false });
        }
        this.setState({ errors });
        return formIsValid;
    }

    onSubmit = (e:any) => {
        
        this.setState({ loading: true });
        e.preventDefault();
        if(this.validateForm()){
            let sysopController = new SysopController();
            sysopController.getApprovalProcess(this.state.fields.processName).then(response =>{
                if(response.success){
                    alert("Process name: " + response.Process_Name + " already exists.");
                    this.setState({ loading: false });
                    this.props.history.push('/admin/home');
                }
                else if(response.code === 202){
                    sysopController.countProcessByOrderType(this.state.selectfields.ownerLabelId,this.state.selectfields.department,this.state.selectfields.assignedLabelId).then(response =>{
                debugger;
                        if(this.state.optionfields.superUserYes){
                        if(response.SuValue !== 0 && response.SuBillableCount === 0 && response.SuNtBillableCount === 0 && this.state.optionfields.freeGoods){
                            alert("Approval process is already exist for selected label and Department for free goods order.");
                            this.setState({ loading: false });
                            this.props.history.push('/admin/home');
                        }
                        else if(response.SuValue !== 0 && response.SuBillableCount > 0 && this.state.optionfields.billable){
                            alert("Approval process is already exist for selected label and Department for billable order.");
                            this.setState({ loading: false });
                            this.props.history.push('/admin/home');
                        }
                        else if(response.SuValue !== 0 && response.SuNtBillableCount > 0 && this.state.optionfields.nonBillableTransfer){
                            alert("Approval process is already exist for selected label and Department for non billable transfer order.");
                            this.setState({ loading: false });
                            this.props.history.push('/admin/home');
                        }
                        else{
                            if(this.state.optionfields.defaultDeptYes){
                                sysopController.getDefaultProcess(this.state.selectfields.ownerLabelId,this.state.selectfields.department).then(response =>{
                                    if(response.success){
                                        alert("Another Default Process exists for this Department. Process Name: " + response.Process_Name + " & Department: " + response.Department + ". Please de-activate the process before assigning a new deafult process.");
                                        this.setState({ loading: false });
                                        this.props.history.push('/admin/home');
                                    }
                                    else if(response.code === 202){
                                       this.insertProcess();
                                    }
                                }).catch(error => {
                                    console.error("There was an error")
                                });
                            }
                            else{
                               this.insertProcess();
                            }
                        }

                       }
                       else{
                         if(response.value !== 0 && response.billableCount === 0 && response.ntBillableCount === 0 && this.state.optionfields.freeGoods){
                            alert("Approval process is already exist for selected label and Department for free goods order.");
                            this.setState({ loading: false });
                            debugger;
                            this.props.history.push('/admin/home');
                        }
                        else if(response.value !== 0 && response.billableCount > 0 && this.state.optionfields.billable){
                            alert("Approval process is already exist for selected label and Department for billable order.");
                            debugger;
                            this.setState({ loading: false });
                            this.props.history.push('/admin/home');
                        }
                        else if(response.value !== 0 && response.ntBillableCount > 0 && this.state.optionfields.nonBillableTransfer){
                            alert("Approval process is already exist for selected label and Department for non billable transfer order.");
                            debugger;
                            this.setState({ loading: false });
                            this.props.history.push('/admin/home');
                        }
                        else{
                            if(this.state.optionfields.defaultDeptYes){
                                sysopController.getDefaultProcess(this.state.selectfields.ownerLabelId,this.state.selectfields.department).then(response =>{
                                    if(response.success){
                                        alert("Another Default Process exists for this Department. Process Name: " + response.Process_Name + " & Department: " + response.Department + ". Please de-activate the process before assigning a new deafult process.");
                                        this.setState({ loading: false });
                                        this.props.history.push('/admin/home');
                                    }
                                    else if(response.code === 202){
                                       this.insertProcess();
                                    }
                                }).catch(error => {
                                    console.error("There was an error")
                                });
                            }
                            else{
                               this.insertProcess();
                            }
                        }
                       }
            }).catch(error => {
                        console.error("There was an error")
                    });  
                }
            }).catch(error => {
                console.error("There was an error")
            });
        }
    }

    insertProcess(){
        let sysopController = new SysopController();
        var approvalProcessData = {
            processName: this.state.fields.processName,
            labelId: this.state.selectfields.ownerLabelId,
            assignedLabelId: this.state.selectfields.assignedLabelId,
            maxApprovers: this.state.fields.maxApprovers,
            department: this.state.selectfields.department,
            processDescription: this.state.fields.processDescription,
            isDefault: this.state.optionfields.defaultDeptYes,
            isAllLabels: this.state.optionfields.allLabelsYes,
            isExceptions: this.state.optionfields.exceptionsYes,
            isOvernight: this.state.optionfields.overnightOrdersYes,
            isClean: this.state.optionfields.cleanOrdersYes,
            isCrossLabel: this.state.optionfields.crossLabelsYes,
            isOnePerLabel: '0',
            isActive: '1',
            isSuperUser: this.state.optionfields.superUserYes,
            isCatalog: '0',
            isBillable: this.state.optionfields.billable,
            isNTBillable: this.state.optionfields.nonBillableTransfer
        }
        sysopController.insertApprovalProcess(approvalProcessData).then(response => {
            
            if(response.success){
                sysopController.addFullLengthCleanApproverToProcess().then(response =>{
                    
                    if(response.success){
                        if(this.state.optionfields.crossLabelsYes){
                            this.state.crossLabels.map(data =>{
                                var crossLabelData ={
                                    processName: this.state.fields.processName,
                                    labelId: data,
                                    ownerLabel: this.state.selectfields.ownerLabelId,
                                    isOn: '1',
                                    defaultDept: this.state.selectfields.department,
                                    isBillable: this.state.optionfields.billable,
                                    isNonBillableTransfer: this.state.optionfields.nonBillableTransfer
                                }
                                sysopController.insertCrossLabels(crossLabelData);
                            })
                        }
                        if(this.state.optionfields.exceptionsYes){
                            this.state.exceptions.map(data =>{
                                var labelExceptionData ={
                                    processName: this.state.fields.processName,
                                    labelId: data,   
                                }
                                sysopController.insertLabelExceptions(labelExceptionData);
                            })
                        }
                    }
                    this.setState({ loading: false });
                    alert("Approval process: " + this.state.fields.processName + " created successfully.")
                    this.props.history.push('/admin/home');
                }).catch(error => {
                    console.error("There was an error")
                });
            }
        }).catch(error => {
            console.error("There was an error")
        });
    }

    handleSelectChange = (e:any) => {
        let selectfields = this.state.selectfields;
        let fields = this.state.fields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'ownerLabelId'){
            selectfields["ownerLabelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            let commonController = new CommonController();
            commonController.getAllDeptForLabel(this.state.selectfields.ownerLabelId).then(jsonDept => {
                this.setState({                           
                    department : JSON.parse(JSON.stringify(jsonDept))
                });
            }).catch(error => {
                console.error("There was an error loading department")
            });
        }
        if(e.currentTarget.name === 'assignedLabelId'){
            selectfields["assignedLabelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        this.setState({ selectfields });
        if(e.currentTarget.name === 'department'){
            this.generateProcessName();
        }
        
        let optionfields = this.state.optionfields;
        if(selectfields["assignedLabelName"] != ''){
            if(selectfields["ownerLabelName"] != selectfields["assignedLabelName"]){
                optionfields['superUserNo'] = false;
                optionfields['superUserYes'] = true;
                this.generateProcessName();
            }
            else if(selectfields["ownerLabelName"] === selectfields["assignedLabelName"]){
                optionfields['superUserNo'] = true;
                optionfields['superUserYes'] = false;
                this.generateProcessName();
            }
        }
        this.setState({ optionfields, fields });
    }

    handleSelectMultipleChange = (e: any) => {
        var options = e.currentTarget.options;
        var value : string[] = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }
        if(e.currentTarget.name === 'exceptions'){
            this.setState({exceptions: value});
        }
        if(e.currentTarget.name === 'crossLabels'){
            this.setState({crossLabels: value});
        }
    }

    handleChange = (e:any) => {
        let fields = this.state.fields;
        if(e.currentTarget.name === 'processName'){
            var regexp = new RegExp(/^[a-zA-Z_]*$/)
            if (regexp.test(e.currentTarget.value)){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                alert("Process name allows alphabets and underscore only...")
            }
        }
        else if(e.currentTarget.name === 'maxApprovers'){
            if(e.currentTarget.value <= 4){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                fields[e.currentTarget.name] = 1;
                alert("Maximum limit for max approvers is 4...")
            }
        }
        else if (e.currentTarget.value.trim().length > 0){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
        }
        this.setState({
          fields 
        });
    }

    handleOptionChange =(e:any)=>{
        let optionfields = this.state.optionfields;
        let fields = this.state.fields;
        if (e.currentTarget.id === "defaultDeptNo") {
            optionfields['defaultDeptNo'] = !this.state.optionfields.defaultDeptNo;
            optionfields['defaultDeptYes'] = false;
        }
        else if (e.currentTarget.id === "defaultDeptYes") {
            optionfields['defaultDeptNo'] = false;
            optionfields['defaultDeptYes'] = !this.state.optionfields.defaultDeptYes;
        }
        else if (e.currentTarget.id === "freeGoods") {
            optionfields['freeGoods'] = !this.state.optionfields.freeGoods;
            optionfields['billable'] = false;
            optionfields['nonBillableTransfer'] = false;
            this.generateProcessName();
        }
        else if (e.currentTarget.id === "billable") {
            optionfields['freeGoods'] = false;
            optionfields['billable'] = !this.state.optionfields.billable;
            optionfields['nonBillableTransfer'] = false;
            this.generateProcessName();
        }
        else if (e.currentTarget.id === "nonBillableTransfer") {
            optionfields['freeGoods'] = false;
            optionfields['billable'] = false;
            optionfields['nonBillableTransfer'] = !this.state.optionfields.nonBillableTransfer;
            this.generateProcessName();
        }
        else if (e.currentTarget.id === "overnightOrdersYes") {
            optionfields['overnightOrdersYes'] = !this.state.optionfields.overnightOrdersYes;
            optionfields['overnightOrdersNo'] = false;
        }
        else if (e.currentTarget.id === "overnightOrdersNo") {
            optionfields['overnightOrdersYes'] = false;
            optionfields['overnightOrdersNo'] = !this.state.optionfields.overnightOrdersNo;
        }
        else if (e.currentTarget.id === "cleanOrdersYes") {
            optionfields['cleanOrdersYes'] = !this.state.optionfields.cleanOrdersYes;
            optionfields['cleanOrdersNo'] = false;
        }
        else if (e.currentTarget.id === "cleanOrdersNo") {
            optionfields['cleanOrdersYes'] = false;
            optionfields['cleanOrdersNo'] = !this.state.optionfields.cleanOrdersNo;
        }
        else if (e.currentTarget.id === "allLabelsYes") {
            optionfields['allLabelsYes'] = !this.state.optionfields.allLabelsYes;
            optionfields['allLabelsNo'] = false;
        }
        else if (e.currentTarget.id === "allLabelsNo") {
            optionfields['allLabelsYes'] = false;
            optionfields['allLabelsNo'] = !this.state.optionfields.allLabelsNo;
        }
        else if (e.currentTarget.id === "exceptionsYes") {
            optionfields['exceptionsYes'] = !this.state.optionfields.exceptionsYes;
            optionfields['exceptionsNo'] = false;
        }
        else if (e.currentTarget.id === "exceptionsNo") {
            optionfields['exceptionsYes'] = false;
            optionfields['exceptionsNo'] = !this.state.optionfields.exceptionsNo;
        }
        else if (e.currentTarget.id === "crossLabelsYes") {
            optionfields['crossLabelsYes'] = !this.state.optionfields.crossLabelsYes;
            optionfields['crossLabelsNo'] = false;
        }
        else if (e.currentTarget.id === "crossLabelsNo") {
            optionfields['crossLabelsYes'] = false;
            optionfields['crossLabelsNo'] = !this.state.optionfields.crossLabelsNo;
        }
        else if (e.currentTarget.id === "exportProcessYes") {
            optionfields['exportProcessYes'] = !this.state.optionfields.exportProcessYes;
            optionfields['exportProcessNo'] = false;
        }
        else if (e.currentTarget.id === "exportProcessNo") {
            optionfields['exportProcessYes'] = false;
            optionfields['exportProcessNo'] = !this.state.optionfields.exportProcessNo;
        }
        else {
            optionfields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({ optionfields, fields });
    }

    generateProcessName(){
        let fields = this.state.fields;
        if(this.state.optionfields.superUserYes){
            fields['processName'] = this.state.selectfields.ownerLabelId + '_' + this.state.selectfields.assignedLabelId
            if(this.state.selectfields.department != ''){
            const cleanedString = this.state.selectfields.department.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
            fields['processName'] += '_' + cleanedString + '_SU'
            }
        }
        else{
            fields['processName'] = this.state.selectfields.ownerLabelId
            if(this.state.selectfields.department != ''){
                const cleanedString = this.state.selectfields.department.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
                fields['processName'] += '_' + cleanedString
            }
        }
        if(this.state.optionfields.billable){
            fields['processName'] += '_bill'
        }
        if(this.state.optionfields.nonBillableTransfer){
            fields['processName'] += '_NBT'
        }
        this.setState({ fields });
    }

    render(){
        const{ loading } = this.state;
        return(
            <div className="content">
              <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row>
                  <Col sm={12}>
                    <Card border="light" style={{ height:'100%' }}>
                        <Card.Body>
                        <Card.Title><h4 className="title">Create Approval Process</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={4}>
                                    <FormSelect
                                        properties={{label:"Owner Label",id:"ownerLabelId",name:"ownerLabelId",value:this.state.selectfields.ownerLabelId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.ownerLabelId}
                                        defaultOption="Select Owner Label"
                                        fillOption={this.state.recordLabel}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <FormLabelField
                                        properties={{label: "",id:"ownerLabelId",name:"ownerLabelId",value:this.state.selectfields.ownerLabelId,className:"form-control"}}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <FormSelect
                                        properties={{label:"Assigned Label",id:"assignedLabelId",name:"assignedLabelId",value:this.state.selectfields.assignedLabelId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.assignedLabelId}
                                        defaultOption="Select Assigned Label"
                                        fillOption={this.state.recordLabel}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <FormLabelField
                                        properties={{label:"",id:"assignedLabelId",name:"assignedLabelId",value:this.state.selectfields.assignedLabelId,className:"form-control"}}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormSelect
                                        properties={{label:"Department",id:"department",name:"department",value:this.state.selectfields.department,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.department}
                                        defaultOption="Select Department"
                                        fillOption={this.state.department}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Billable Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Free Goods" name="billableProcess"  id="freeGoods" onChange={this.handleOptionChange} checked={this.state.optionfields.freeGoods} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Billable" name="billableProcess"  id="billable" onChange={this.handleOptionChange} checked={this.state.optionfields.billable} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Non Billable Transfer" name="billableProcess"  id="nonBillableTransfer" onChange={this.handleOptionChange} checked={this.state.optionfields.nonBillableTransfer} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                            <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Super User Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="superUser"  id="superUserYes" checked={this.state.optionfields.superUserYes} disabled /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="superUser"  id="superUserNo" checked={this.state.optionfields.superUserNo} disabled /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"processName",label:"Process Name",type:"text",name:"processName",className:"form-control input-sm",maxlength:24, value:this.state.fields.processName,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.processName}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Is this Default for this Department?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="defaultDept"  id="defaultDeptYes" onChange={this.handleOptionChange} checked={this.state.optionfields.defaultDeptYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="defaultDept"  id="defaultDeptNo" onChange={this.handleOptionChange} checked={this.state.optionfields.defaultDeptNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"maxApprovers",label:"Max Approvers",type:"text",name:"maxApprovers",className:"form-control input-sm",maxlength:24, value:this.state.fields.maxApprovers,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.maxApprovers}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Overnight Orders Allowed</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="overnightOrders"  id="overnightOrdersYes" onChange={this.handleOptionChange} checked={this.state.optionfields.overnightOrdersYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="overnightOrders"  id="overnightOrdersNo" onChange={this.handleOptionChange} checked={this.state.optionfields.overnightOrdersNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Clean Orders Allowed</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="cleanOrders"  id="cleanOrdersYes" onChange={this.handleOptionChange} checked={this.state.optionfields.cleanOrdersYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="cleanOrders"  id="cleanOrdersNo" onChange={this.handleOptionChange} checked={this.state.optionfields.cleanOrdersNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Can requestor orders from other label?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="allLabels"  id="allLabelsYes" onChange={this.handleOptionChange} checked={this.state.optionfields.allLabelsYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="allLabels"  id="allLabelsNo" onChange={this.handleOptionChange} checked={this.state.optionfields.allLabelsNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Are there labels the requestor may not order from ?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="exceptions"  id="exceptionsYes" onChange={this.handleOptionChange} checked={this.state.optionfields.exceptionsYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="exceptions"  id="exceptionsNo" onChange={this.handleOptionChange} checked={this.state.optionfields.exceptionsNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Which Labels may the requestor NOT order from?</Form.Label>
                                        <Form.Control as="select" multiple id="exceptions" name="exceptions" value={this.state.exceptions} onChange={this.handleSelectMultipleChange}>
                                            {this.state.recordLabel.map((data: any) => {
                                                return(
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Is this a Cross-Label Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="crossLabels"  id="crossLabelsYes" onChange={this.handleOptionChange} checked={this.state.optionfields.crossLabelsYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="crossLabels"  id="crossLabelsNo" onChange={this.handleOptionChange} checked={this.state.optionfields.crossLabelsNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>If this is a cross-label process, which labels does it pertain to?</Form.Label>
                                        <Form.Control as="select" multiple id="crossLabels" name="crossLabels" value={this.state.crossLabels} onChange={this.handleSelectMultipleChange}>
                                            {this.state.recordLabel.map((data: any) => {
                                                return(
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Is this an EXPORT Process?</Form.Label>
                                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Yes" name="exportProcess"  id="exportProcessYes" onChange={this.handleOptionChange} checked={this.state.optionfields.exportProcessYes} /></Col>
                                            <Col sm={1.5} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="No" name="exportProcess"  id="exportProcessNo" onChange={this.handleOptionChange} checked={this.state.optionfields.exportProcessNo} /></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Label>Please enter a description for this process</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="processDescription" id="processDescription" value={this.state.fields.processDescription} onChange={this.handleChange}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                            <Row>
                                <Col sm={12} style={{textAlign: "center" , alignSelf: "center"}}>
                                    <Button type="submit" disabled={loading} >
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" , color:"#3472F7"  }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading && <span>Save Approval Process</span>}
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix" />
                        </Form>
                      </Card.Body>                                  
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
        )
    }
}
interface CreateProcessParameter{
    uid?:string;
    pwd?:string;
    history?:History<LocationState>;
    location?:any;
}
interface Istate{
    fields:any;
    errors:any;
    selectfields:any;
    optionfields:any;
    recordLabel:[];
    department:[];
    exceptions:any[]; 
    crossLabels:any[];
    errMsg:string;
    loading:boolean;
    modal:any;
}