import React, { Component} from "react";
import {Container,Card, Row, Col,Table ,Form ,Alert ,Button ,Navbar, Nav} from "react-bootstrap";
import {History, LocationState} from "History";
import {UserViewController} from "../../../Controllers/UserViewController";
import {format} from 'date-fns';
import {Variables} from '../../../Variables/Variables';
import {NavLink} from 'react-router-dom';
import '../../../assets/css/CreateOrder.css';
import {OrderApprovalController} from "../../../Controllers/OrderApprovalController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class ViewApprovalInbox extends Component<ViewOrderParameter,Istate> {
    constructor(props:ViewOrderParameter){
        super(props)       
        this.state={
            fields: {find:'',searchType:'' },
            errors: {find:'',searchType:'' },            
            errMsg:"",
            todayDate:"",
            previousdate:"",
            Order_id:"",
            goodsReq:"",
            SearchGrid: [],
            loading: false,
            modalSpinnerShow:false,
            currentSort: 'down',
            currentColumn: 'Order_ID'
        }   
    }

    componentDidMount(){
      var  newDate = new Date();
      var pdate = new Date();
      pdate.setDate(pdate.getDate() - 45);
      var previousDate = format(pdate, 'dd/MM/yyyy');
      var  todayDate = format(newDate, 'dd/MM/yyyy')
      this.setState({todayDate : todayDate, modalSpinnerShow:true})  
      this.setState({previousdate : previousDate})  

     
      var data = {
        sortBy: "1",
        aliasIn: sessionStorage.getItem("uid")
        
      }
      let orderApprovalController = new OrderApprovalController();
      orderApprovalController.getApproverInbox(data).then(jsonOrderApproval => {
        this.setState({
          SearchGrid: JSON.parse(JSON.stringify(jsonOrderApproval)), loading: false, modalSpinnerShow:false
        });
      }).catch(error => {
        this.setState({ loading: false });
        console.error("There was an error loading in search product")
      });

  }

  setArrow = (column) => {
    let {currentSort} = this.state;
    let {currentColumn} = this.state;
    let className = "";

    if (column === currentColumn){
      if (currentSort === "up"){
        className = 'fa fa-fw fa-sort-up';
      }
      else if (currentSort === "down"){
        className = 'fa fa-fw fa-sort-down';
      }
      else
      {
        className = 'fa fa-fw fa-sort';
      }
    }
    else
    {
      className = 'fa fa-fw fa-sort';
    }

    return className;
  };

  getItemInfo= (item) =>{//
   if((item["material"] === "1" || item["billable"] === "1") &&  item["NTBillable"] === "0")
   {
     if(item["material"] === "1" && item["billable"] === "1" )
     {
       return " Billable Clean"
     }
     else if (item["billable"] === "1")
     {
      return " Billable"
     }
     else if (item["material"] === "1")
     {
      return " Clean"
     } 
     
   }

   else if(item["billable"] === "1")
   {
    return " Billable"
   }

   else if(item["NTBillable"] === "1")
    {
      return " Non Billable Transfer"
    }
   else{
    return ""
   } 
  };

  getLinkWithPath= (item) =>{//
   

    if(item["billable"] === "1")
   {
    // if(item["material"] === "1")
   //  {
       return (<NavLink
       to={{
         pathname:'/admin/freegoodsreqform',
         state:{
           Order_id:item["Order_ID"],
           goodsReq:"E"
         }
       }}                                        
       activeClassName="active">{item["Order_ID"]}</NavLink>)
     ////}
    }
     else if(item["NTBillable"] === "1")
     {
      return (<NavLink
        to={{
          pathname:'/admin/freegoodsreqform',
          state:{ 
            Order_id:item["Order_ID"],
            goodsReq:"E"
          }
        }}                                        
        activeClassName="active">{item["Order_ID"]}</NavLink>)       
        
     }
   
   else{
    return (<NavLink
      to={{
        pathname:'/admin/freegoodsreqform',
        state:{
          Order_id:item["Order_ID"],
          goodsReq:"E"
        }
      }}                                        
      activeClassName="active">{item["Order_ID"]}</NavLink>)
     
   }
  };
  onSortChange = (col) => {
    
    const { currentSort } = this.state;
    const { currentColumn } = this.state;
    let nextSort;

    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'default';
    else if (currentSort === 'default') nextSort = 'down';

    let arrayCopy : []
    arrayCopy = [...this.state.SearchGrid];
    arrayCopy.sort(this.compareBySort(col, nextSort));

    this.setState({
      SearchGrid: arrayCopy
    });

    this.setState({
      currentSort: nextSort, currentColumn:col
    });
   
    //console.log("onSortChange - sort :",currentSort)
    //console.log("onSortChange - column :",currentColumn)
      
  };
 
  compareBySort(key,sort) {
    return function (a, b) 
    { 
      if (sort === 'up'){
        if (a[key] < b[key]) return -1;
      }
      else if (sort === 'down'){
        if (a[key] > b[key]) return -1;
      }
        return 0;
    }
  }
    render(){
      const { SearchGrid } = this.state;
      const { currentSort } = this.state;
      const { currentColumn } = this.state;
      //console.log("currentColumn", currentColumn)
      const sortTypes = {
        up: {
          class: 'fa fa-fw fa-sort-up',
          fn: (a, b) => a[currentColumn] - b[currentColumn]
        },
        down: {
          class: 'fa fa-fw fa-sort-down',
          fn: (a, b) => b[currentColumn] - a[currentColumn]
        },
        default: {
          class: 'fa fa-fw fa-sort',
          fn: (a, b) => a
        }
      };
      const alignCardCenter = {
        "margin": "0 0 0 0"
      };
      const navStyle = {
        backgroundColor: 'rgba(0,0,0,.03)',
        border: '0px solid',
        borderBottom: '1px solid rgba(0,0,0,.125)',
        height: '40px',
        width: '100%',
        marginBottom: "0"
    };
    const gridBoxHeadStyle = {
      fontSize: "8px",
      borderStyle:"groove",
      width:'15%'     
     };
     const gridBoxStyle = {
      fontSize: "9px",
      borderStyle:"groove",
      width:'15%'
    };
    //onClick={() => this.sortBy('Order_ID')}
        return (
            <div className="content">
              <FormModalSpinner show={this.state.modalSpinnerShow} />
              <Container fluid>            
                <Row>
                  <Col md={12}>
                    <Card border="light" style={{ height:'100%' }}>
                      <Card.Body>
                        <Card.Title><h4 className="title">Approval Order</h4></Card.Title>
                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                        
                        <Row>
                          <Col sm={12}>
                           
                          <ExcelFile filename="Approval Order" element={<Button type="button" style={{ marginRight: "5px" }}>Export to Excel</Button>}>
                              <ExcelSheet data={this.state.SearchGrid} name="Approval Order">
                              <ExcelColumn label="Order ID" value="Order_ID" />
                                <ExcelColumn label="Request Date" value="Request_Date" />
                                <ExcelColumn label="Requestor" value="Requestor" />
                                <ExcelColumn label="OP CO" value="Label" />
                                <ExcelColumn label="QTY" value="total_qty" />
                                <ExcelColumn label="Ship TO Attn" value="ShipToName" />
                              </ExcelSheet>
                          </ExcelFile>
                            
                          </Col> 
                         </Row>
                        <Row>
                          <Col sm={12}>
                              <div id="navbar">
                                  <Navbar expand="lg" >
                                      <Navbar.Brand className="align-center">Approval Inbox</Navbar.Brand>
                                      <Nav className="ml-auto">
                                      </Nav>
                                  </Navbar>
                              </div>
                          </Col>
                        </Row>                           
                        <Row>
                          <Col sm={12}>
                          <div className="scroll" style={{ width: '100%', height: '100%' }}>
                          <Table >
                            <thead style={{backgroundColor:'lightblue', fontSize: 'large'}}>
                              <tr>            
                                <th onClick={() => this.onSortChange('Order_ID')}>Order Id <i className={this.setArrow('Order_ID')}></i></th> 
                                <th>Request Date</th>                       
                                <th onClick={() => this.onSortChange('Requestor')}>Requestor <i className={this.setArrow('Requestor')}></i></th>
                                <th onClick={() => this.onSortChange('Label')}>Op Co <i className={this.setArrow('Label')}></i></th>
                                <th onClick={() => this.onSortChange('total_qty')}>Qty <i className={this.setArrow('total_qty')}></i></th>
                                <th onClick={() => this.onSortChange('ShipToName')}>Ship to Attn <i className={this.setArrow('ShipToName')}></i></th>
                              </tr>                         
                            </thead>
                            <tbody>                           
                                {this.state.SearchGrid.map((prop, key) => { 
                                    return (
                                      <tr key={key}>
                                        <td>
                                        {this.getLinkWithPath(prop)}
                                        <span style={{color:'#FF0000'}}> <b> {this.getItemInfo(prop)}</b> </span>
                                        </td>                                      
                                        <td>                                      
                                          {prop["Request_Date"]}
                                          <span style={{color:'#FF0000'}}>  <b> {prop["ship_method"] === "2nd Day Air" ? " 2nd Day Air" : ""} </b></span>
                                          <span style={{color:'#FF0000'}} >  <b> {prop["ship_method"] === "Overnight" ? " Overnight" : ""} </b> </span>
                                        </td>                                                                      
                                        <td>{prop["Requestor"]}</td>                                 
                                        <td>{prop["Label"]}</td>
                                        <td>{prop["total_qty"]}</td>           
                                        <td>{prop["ShipToName"]}</td>                       
                                      </tr>
                                  )            
                                    })} 
                              </tbody>                         
                          </Table> 
                          </div>
                          </Col>
                        </Row>
                      </Card.Body>  
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
        )        
    }
}

interface ViewOrderParameter
{ 
  history?:History<LocationState>;
}
interface Istate
{
  fields:any;
  errors:any; 
  errMsg:string;
  todayDate:any;
  previousdate:any;
  goodsReq:any;
  Order_id:any;  
  SearchGrid: [];
  loading: any;
  modalSpinnerShow:boolean;
  currentSort:string;
  currentColumn:string;
}