
let lastSelectedRow;
let currSelectedRow;
let trs;
let multiArr=[];

function setLastIndexRow(lastIndex)
{
    lastSelectedRow=lastIndex;
}
function setCurrIndexRow(currIndex)
{
    currSelectedRow=currIndex;
}

function tableRows(){
    trs = document.getElementById('TableMultiSelect').tBodies[0].getElementsByTagName('tr');
    return trs;
}

// disable text selection
//document.onselectstart = function() {
    //return false;
//}

export function handleTableRowClick(e) {
    
    let index = e.currentTarget.id.split("_")[1];
    if (window.event.ctrlKey) {
        toggleRow(e, index);
    }
    
    if (window.event.button === 0) {
        if (!window.event.ctrlKey && !window.event.shiftKey) {
            clearAll();
            toggleRow(e, index);
            
        }
    
        if (window.event.shiftKey && lastSelectedRow!=undefined) {
            setCurrIndexRow(index);           
            selectRowsBetweenIndexes([parseInt(lastSelectedRow)+1, parseInt(currSelectedRow)+1])
        }
    }    
}

function toggleRow(row,id) {
    if (row.currentTarget.className == 'selected'){
        row.currentTarget.className = '';
        removeMultiArr(id);
    }
    else
    {
        row.currentTarget.className = 'selected';
        addMultiArray(id);
    }
    //row.currentTarget.className = row.currentTarget.className == 'selected' ? '' : 'selected';   
    setLastIndexRow(id);
    //multiArr.splice(0, multiArr.length)
   
}

function selectRowsBetweenIndexes(indexes) {
    //
    trs = tableRows();
    indexes.sort(function(a, b) {
        return a - b;
    });

    for (var i = indexes[0]; i <= indexes[1]; i++) {
       trs[i-1].className = 'selected';
       addMultiArray(i-1);
    }
}

function clearAll() {
    trs = tableRows();
    for (var i = 0; i < trs.length; i++) {
        trs[i].className = '';
    }
    clearAllMultiArr();
}

function addMultiArray(arrIndex){
    multiArr[arrIndex] = document.getElementById('TableMultiSelect').tBodies[0].getElementsByTagName('tr')[arrIndex];
}

export function getMultiArr(){
    return multiArr;
}
export function clearAllMultiArr(){
    multiArr.splice(0, multiArr.length)
}

function removeMultiArr(obj){
    multiArr.splice(multiArr.indexOf(obj), 1 );
}
