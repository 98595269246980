import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class LoginController {
    uid: string;
    pwd: string;
    loginFailed: number;
    response: { msg: string; success: boolean; loginFailed: number; changePassword: boolean};
    serviceProxy: ServiceProxy;
    variables: Variables;
    dateTimeFormat : DateTimeFormat;
    constructor(uid : string, pwd : string, loginFailed : number){
        this.uid = uid;
        this.pwd = pwd;
        this.loginFailed = loginFailed;
        this.response = { msg : '', success : true, loginFailed : 0, changePassword : false }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
    }
    
    checkUserLocked(){
        
        var data = {
            "username": this.uid
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.checkUserLocked;
        return this.serviceProxy.get(url,data).then(response => {
            
            if(response.success){
                if(!response.IsFailedAttempt){
                    return this.authenticateUser();
                }
                else{
                    this.response.msg = 'Hi "'+ this.uid +'", your account is locked. Please contact applicationsupport@umusic.com.';
                    this.response.success = false;
                    return this.response;
                }   
            }
            else {
                if (response.code === 202){
                    this.response.msg = 'Hi, "' + this.uid + '" your login is invalid.'
                    this.response.success = false;
                    return this.response;
                    
                }
                else if (response.code === 400) {
                    console.error('There was an error!', response.message.toString());
                    this.response.success = false;
                    return this.response;
                } 
                else {
                    console.error('There was an error!', response.message.toString());
                    this.response.success = false;
                    return this.response;
                } 
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });                       
    };

    authenticateUser(){
        var data = {
            "username": this.uid,
            "password": this.pwd
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.authenticateUser;
        return this.serviceProxy.post(url,data).then(response =>{
            if(response.success){
                var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                sessionStorage.setItem("uid",res.Alias);
                sessionStorage.setItem("usertype_id",res.UserType_Id);
                sessionStorage.setItem("user_full_name",res.Name);
                sessionStorage.setItem("addressLineOne",res.AddressLineOne);
                sessionStorage.setItem("addressLineTwo",res.AddressLineTwo);
                sessionStorage.setItem("city",res.City);
                sessionStorage.setItem("state",res.State);
                sessionStorage.setItem("zip",res.Zip);
                sessionStorage.setItem("phone",res.Phone);
                sessionStorage.setItem("email",res.Email);
                sessionStorage.setItem("process_name",res.Process_Name);
                sessionStorage.setItem("is_super_user",res.Is_Super_User ? '1' : '0');
                sessionStorage.setItem("is_executive",res.Is_Executive ? '1' : '0');
                sessionStorage.setItem("multiple_select",res.Multiple_Select ? '1' : '0');
                sessionStorage.setItem("user_type",res.UserType_Id);
                sessionStorage.setItem("label_id",res.RecordLabel_Id);
                sessionStorage.setItem("department",res.Department);
                sessionStorage.setItem("user_label_id",res.RecordLabel_Id);
                sessionStorage.setItem("user_department",res.Department);
                sessionStorage.setItem("business_unit",res.BusinessUnit);
                sessionStorage.setItem("CustomerNumber",res.CustomerNumber);
                sessionStorage.setItem("Password",res.password);
                sessionStorage.setItem("IsNinetyDays",res.IsNinetyDays);
                sessionStorage.setItem("IsFailedAttempt",res.IsFailedAttempt);
                return this.loginUser();
            }
            else {
                this.loginFailed += 1;
                if(this.loginFailed === 3){
                    return this.updateUserLocked();
                }
                this.response.msg = "Sorry, " + this.uid + " is not known here with that password.  Please try again with a properly spelled user name and password, or if you'd like to create yourself as a new user, click create new user profile above, or if you have forgotten your login information, click on forgot your password? below to have your login and password e-mailed to you.";
                this.response.loginFailed = this.loginFailed;
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };

    authenticateUserSSO(){
        var data = {
            "userEmail": this.uid,
          //  "password": this.pwd
        };
        
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.authenticateUserSSO;
        return this.serviceProxy.post(url,data).then(response =>{
            // Convert the array to a string using JSON.stringify

         if(response.length >=0){
                const responseString = JSON.stringify(response);

                // Store the string in sessionStorage
                 sessionStorage.setItem("profiles", responseString);
                      return response;
            }
        

            
        }).catch(error => {
            console.error('There was an error!', error);
        });




    
    };


    authenticateUserSSO1(){
        var data = {
            "username": this.uid,
            //"password": this.pwd
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.authenticateUserSSO1;
        return this.serviceProxy.post(url,data).then(response =>{
            if(response.success){
                var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                sessionStorage.setItem("uid",res.Alias);
                sessionStorage.setItem("usertype_id",res.UserType_Id);
                sessionStorage.setItem("user_full_name",res.Name);
                sessionStorage.setItem("addressLineOne",res.AddressLineOne);
                sessionStorage.setItem("addressLineTwo",res.AddressLineTwo);
                sessionStorage.setItem("city",res.City);
                sessionStorage.setItem("state",res.State);
                sessionStorage.setItem("zip",res.Zip);
                sessionStorage.setItem("phone",res.Phone);
                sessionStorage.setItem("email",res.Email);
                sessionStorage.setItem("process_name",res.Process_Name);
                sessionStorage.setItem("is_super_user",res.Is_Super_User ? '1' : '0');
                sessionStorage.setItem("is_executive",res.Is_Executive ? '1' : '0');
                sessionStorage.setItem("multiple_select",res.Multiple_Select ? '1' : '0');
                sessionStorage.setItem("user_type",res.UserType_Id);
                sessionStorage.setItem("label_id",res.RecordLabel_Id);
                sessionStorage.setItem("department",res.Department);
                sessionStorage.setItem("user_label_id",res.RecordLabel_Id);
                sessionStorage.setItem("user_department",res.Department);
                sessionStorage.setItem("business_unit",res.BusinessUnit);
                sessionStorage.setItem("CustomerNumber",res.CustomerNumber);
                sessionStorage.setItem("Password",res.password);
                sessionStorage.setItem("IsNinetyDays",res.IsNinetyDays);
                sessionStorage.setItem("IsFailedAttempt",res.IsFailedAttempt);
                return this.loginUser();
            }
            else {
                
                this.response.msg = "Sorry, " + this.uid + " is not known here with that password.  Please try again with a properly spelled user name and password, or if you'd like to create yourself as a new user, click create new user profile above, or if you have forgotten your login information, click on forgot your password? below to have your login and password e-mailed to you.";
                this.response.loginFailed = this.loginFailed;
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };











    getUserDetails(){
        var data = {
            "username":sessionStorage.getItem("uid")?.toString().trim()
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.getUserDetails;
        return this.serviceProxy.get(url,data).then((response : any) => {
            if(response.success){
                sessionStorage.setItem("PwdUpdate", response.pwdupdate);
                if (response.tbacc !== null){
                    sessionStorage.setItem("TBAcc_No", response.tbacc);
                    sessionStorage.setItem("TicketBundling", '1');
                    sessionStorage.setItem("aliasNTB","");
                }
                else{
                    sessionStorage.setItem("TicketBundling", '0');
                }
                if(response.s89acc !== null){
                    sessionStorage.setItem("Sales89", response.s89acc);
                    sessionStorage.setItem("Sales89", '1');
                }
                else{
                    sessionStorage.setItem("Sales89", '0');
                }
                var re = new RegExp("^(?=.*[0-9])(?=.*[~`!@\#$%^&*()+=])[a-zA-Z0-9~`!@\#$%^&*()+=]{8,15}$");
                var pwd : string = sessionStorage.getItem("Password")?.toString().trim()!;
                if(re.test(pwd)){
                    sessionStorage.setItem("PasswordVerify","1")
                }
                else{
                    sessionStorage.setItem("PasswordVerify","0")
                }
                this.response.msg = "Hi "+this.uid+" Login Successful";
                this.response.success = true; 
                return this.response;
            }
            else{
                this.response.msg = response.message.toString(); 
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };

    loginUser(){
        var dtsvalue, IsNinetydays;
        // IsNinetydays = sessionStorage.getItem("IsNinetyDays");
        dtsvalue = this.dateTimeFormat.getDateTime();        
        // if (IsNinetydays === 'false'){
            var data = {
                "username": sessionStorage.getItem("uid")?.toString().trim(),
                "processname": sessionStorage.getItem("process_name")?.toString().trim(),
                "name": sessionStorage.getItem("user_full_name")?.toString().trim(),
                "lastlogin": dtsvalue
            }
            var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.loginUser;
            return this.serviceProxy.post(url,data).then(response => {
                if(response.success){
                    return this.getNTBillableUser();
                }
                else {
                    this.response.msg = response.message.toString();
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });
        // }
        // else{
        //     this.response.success = false;
        //     this.response.changePassword = true;
        //     return this.response;
        // }   
    };

    getNTBillableUser(){
        var url = this.variables.Url + this.variables.Controller.menu + this.variables.menuService.getNTBillableUser;
        var data = {
            "username":sessionStorage.getItem("uid")?.toString().trim()
        };
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                sessionStorage.setItem("aliasNTB",response.Alias.trim());
                sessionStorage.setItem("Account_no",response.Account_no.trim());
            }
            else{
                sessionStorage.setItem("aliasNTB","");
                sessionStorage.setItem("Account_no","");
            }
            return this.getUserDetails();
        }).catch(error => {
            console.error('There was an error!', error);
        })
    }

    updateUserLocked(){
        var data = {
            "username": this.uid
        };
        this.loginFailed = 0;
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.updateUserLocked;
        return this.serviceProxy.put(url, data).then(response => {
            if(response.success){
                this.response.msg = 'Hi, "'+ this.uid +'" your account is locked. Please contact applicationsupport@umusic.com.';
                this.response.loginFailed = this.loginFailed;
                this.response.success = false;
                return this.response;
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false; 
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };
}

