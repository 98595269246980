import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class OrderController {
    csOrderId : string;
    csUId : string;
    csSalesType : string = "";
    csShipMethod : string = "";
    csApproverComments : string = "";
    cbSelfOrder : boolean = false;
    cbNewOrder : boolean = false;
    orderItems : any;
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    dateTimeFormat: DateTimeFormat;

    constructor(orderId : string, uid : string){
        this.csOrderId = orderId;
        this.csUId = uid;
        this.response = true ;
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
    }

    routeNewOrder(selfOrder : boolean){
        
        this.cbSelfOrder = selfOrder;
        this.cbNewOrder = true;
        return this.approveOrder();
    }

    routeOrder(orderItems : any, salesType : string, shipMethod : string, approverComments : string){
        
        this.orderItems = orderItems;
        this.csSalesType = salesType;
        this.csShipMethod = shipMethod;
        this.csApproverComments = approverComments;
        return this.approveOrder();
    }

    approveOrder(){
        
        var sSalesType : string, sMaterial : string, sApproverLevels : string, orderStatus : string;
        var sOvernight : string;
        var bOvernight : boolean, bClean : boolean, bWasClean : boolean, bCfoRequired	: boolean;

        var bFiveBeforeDepot : boolean = false;
        var bFourBeforeDepot : boolean = false;
        var bThreeBeforeDepot : boolean = false; 
        var bTwoBeforeDepot : boolean = false; 
        var bLabelApprover : boolean = false; 
        var bOvernightApprover : boolean = false; 
        var bLabelCleanApprover : boolean = false; 
        var bFullLengthCleanApprover : boolean = false;
        var bCfoApprover : boolean = false;

        return this.getOrderSummary().then(resOrderSummary => {
            
            bWasClean = resOrderSummary.Material;
            return this.getApproverLevels(resOrderSummary.Process_Name).then(resApproverLevels => {
                
                //console.log("Approver levels" , resApproverLevels);
                sApproverLevels = resApproverLevels;
                bFiveBeforeDepot = sApproverLevels.indexOf("A6") > -1 ? true : false;
                bFourBeforeDepot = sApproverLevels.indexOf("A5") > -1 ? true : false;
                bThreeBeforeDepot = sApproverLevels.indexOf("A4") > -1 ? true : false;
                bTwoBeforeDepot = sApproverLevels.indexOf("A3") > -1 ? true : false;
                bLabelApprover = sApproverLevels.indexOf("A2") > -1 ? true : false;
                bOvernightApprover = sApproverLevels.indexOf("L2") > -1 ? true : false;
                bLabelCleanApprover = sApproverLevels.indexOf("L1") > -1 ? true : false;
                bFullLengthCleanApprover = sApproverLevels.indexOf("C") > -1 ? true : false;
                bCfoApprover = sApproverLevels.indexOf("C1") > -1 ? true : false;

                if (this.cbSelfOrder || this.cbNewOrder || !resOrderSummary.Billable) {
                    sSalesType = resOrderSummary.sales_type;					
                    sMaterial = resOrderSummary.Material ? "1" : "0";					
                    if (this.cbSelfOrder || this.cbNewOrder) {
                        this.csShipMethod = resOrderSummary.Ship_Method;
                    }
                    bClean = resOrderSummary.Material;
                }
                else {
                    if (resOrderSummary.Billable) {
                        switch (this.csSalesType) {
                            case "54 - Marked (non-royalty bearing)":					
                                sSalesType = "54 - Marked (non-royalty bearing)";				
                                sMaterial = "0";
                                break;
                            case "54 - Clean (non-royalty bearing)":					
                                sSalesType = "54 - Clean (non-royalty bearing)";				
                                sMaterial = "1";				
                                break;
                            case "56 - Marked (fully royalty bearing)":					
                                sSalesType = "56 - Marked (fully royalty bearing)";				
                                sMaterial = "0";				
                                break;
                            case "56 - Clean (fully royalty bearing)":					
                                sSalesType = "56 - Clean (fully royalty bearing)";				
                                sMaterial = "1";				
                                break;
                            case "59 - Marked (publisher royalties only)":					
                                sSalesType = "59 - Marked (publisher royalties only)";				
                                sMaterial = "0";				
                                break;
                            case "59 - Clean (publisher royalties only)":					
                                sSalesType = "59 - Clean (publisher royalties only)";				
                                sMaterial = "1";				
                                break;
                            case "66 Ticket Bundling":
                                sSalesType = "66 Ticket Bundling";
                                sMaterial = "1";
                                break;
                        }					
                        bClean = (sMaterial == "1") ? true : false;					
                    }
                    else { 
                        bClean = resOrderSummary.Material;
                    }							
                }

                return this.checkCFOApproval(resOrderSummary.Process_Name).then(cfoApprovalCount => {
                    
                    //console.log(cfoApprovalCount);
                    bCfoRequired = (cfoApprovalCount > 0) ? true : false;
                    bOvernight = (resOrderSummary.Overnight || this.csShipMethod.trim() != "Standard Ground" || this.csShipMethod.trim() != "Pick-Up") ? true : false;							
                    sOvernight = bOvernight ? "1" : "0";

                    return this.checkOrderConfiguration().then(orderConfigurationCount => {
                        
                        //console.log(orderConfigurationCount);
                        var allSingles : boolean = false;	

                        if (bClean) {
                            if (orderConfigurationCount.totalOrderItems == orderConfigurationCount.singleOrderItems) {
                                allSingles = true;	
                            }				
                        }

                        if (this.cbSelfOrder) {
                            //Just set approval date if this is a self order because user is an approver
                            return this.updateSelfOrderItems().then(response => {
                                return this.fiveBeforeDepot(resOrderSummary.Process_Name,bFiveBeforeDepot, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                            }).catch(error =>{
                                console.error('There was an error!', error);
                            });	
                        }						
                        else {
                            //Update order items if not new order because order was opened by an approver
                            if (!this.cbNewOrder)	{
                                return this.updateOrderItems().then(resOrderStatus => {
                                    orderStatus = resOrderStatus;
                                    if (orderStatus == "3") {
                                        this.getApproverNameComments().then(resApproverNameComments => {
                                            this.emailRequestor(resApproverNameComments);
                                        }).catch(error =>{
                                            console.error('There was an error!', error);
                                        });
                                    }
                                    return this.fiveBeforeDepot(resOrderSummary.Process_Name,bFiveBeforeDepot, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            else{
                                return this.fiveBeforeDepot(resOrderSummary.Process_Name,bFiveBeforeDepot, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                            }
                        }              												
                    }).catch(error =>{
                        console.error('There was an error!', error);
                    });
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }).catch(error =>{
            console.error('There was an error!', error);
        });
        
    }

    fiveBeforeDepot(processName, bFiveBeforeDepot, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if(bFiveBeforeDepot){
            //console.log("five before depot")
            //Requestor weight = 4, Usertype = Five before depot = A6
            return this.depotApprover(orderStatus,"4",sOvernight,sSalesType,sMaterial,"A6",processName,"A5").then(depotApproved => {
                return this.fourBeforeDepot(processName, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }
        else{
            //console.log("not five before depot")
            return this.fourBeforeDepot(processName, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
        }
    }

    fourBeforeDepot(processName, bFourBeforeDepot, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if(bFourBeforeDepot){
            //console.log("four before depot")
            //Requestor weight = 3, Usertype = Four before depot = A5
            return this.depotApprover(orderStatus,"3",sOvernight,sSalesType,sMaterial,"A5",processName,"A4").then(depotApproved => {
                return this.threeBeforeDepot(processName, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles)
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }
        else{
            //console.log("not four before depot")
            return this.threeBeforeDepot(processName, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles)
        }
    }

    threeBeforeDepot(processName, bThreeBeforeDepot, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if(bThreeBeforeDepot){
            //console.log("three before depot")
            //Requestor weight = 2, Usertype = Three before depot = A4
            return this.depotApprover(orderStatus,"2",sOvernight,sSalesType,sMaterial,"A4",processName,"A3").then(depotApproved => {
                return this.twoBeforeDepot(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }
        else{
            //console.log("not three before depot")
            return this.twoBeforeDepot(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
        }
    }

    twoBeforeDepot(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if(bTwoBeforeDepot){
            //console.log("two before depot")
            //Requestor weight = 1, Usertype = Two before depot = A3
            return this.depotApprover(orderStatus,"1",sOvernight,sSalesType,sMaterial,"A3",processName,"A2").then(depotApproved => {
                return this.labelApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles)
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }
        else{
            //console.log("not two before depot")
            return this.labelApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles)
        }
    }

    depotApprover(orderStatus, requestorWeight, overNight, salesType, material, approverType, processName, emailApprover){
        //Order status (Approver Rejected) = "3"
        //Order Status (Pending) = "1"
        
        //console.log("inside depot Approver");
        if (orderStatus != "3") { 
            orderStatus = "1";
        }
        var depotApproved : boolean = false;
        return this.updateCompleteOrder("one", orderStatus, requestorWeight, overNight, salesType, material).then(resUpdateCompleteOrder => {
            //console.log(resUpdateCompleteOrder);
            if(resUpdateCompleteOrder){
                return this.insertTblApprovalHistory(approverType).then(resInsertTblApprovalHistory => {
                    if(resInsertTblApprovalHistory){
                        return this.getEmailNotice(approverType,processName).then(response => {
                            if (!this.cbSelfOrder) {
                                //console.log(response.Email_Notice)
                                if (response.Email_Notice) {
                                    this.getApproverNameComments().then(resApproverNameComments => {
                                        this.emailRequestor(resApproverNameComments);
                                    }).catch(error =>{
                                        console.error('There was an error!', error);
                                    });
                                }			
                                this.emailApprover(emailApprover);				
                            }	
                            depotApproved = true;
                            return depotApproved;
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 			
    }

    labelApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if (bLabelApprover){
            //console.log("label approver")
            if (bOvernight){
                //console.log("label approver - overnight")
                //Overnight pending 10
                if (orderStatus != "3") {
                    orderStatus = "10";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("A2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("L2");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.overnightApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
            else if(bClean){
                //console.log("label approver - clean")
                //Clean approval 13
                if (orderStatus != "3") {
                    orderStatus = "13";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("A2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("L1");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.overnightApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
            else if(bCfoRequired){
                //console.log("label approver - cfo")
                //CFO Approval 16
                if (orderStatus != "3") {
                    orderStatus = "16";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("A2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("C1");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.overnightApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
            else{
                //console.log("label approver - else")
                //Status Approved 2
                if (orderStatus != "3") {
                    orderStatus = "2";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("A2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.overnightApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
        }
        else{
            //console.log("not label approver")
            return this.overnightApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
        }
    }

    overnightApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bOvernight, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if (bOvernightApprover && bOvernight){
            //console.log("overnight approver")
            if (bClean) {
                //console.log("overnight approver - clean")
                //Clean approval 13 
                if (orderStatus != "3") {
                    orderStatus = "13";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("L2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("L1");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.cleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });				
            }
            else if (bCfoRequired) {
                //console.log("overnight approver - cfo")
                //Cfo approval 16
                if (orderStatus != "3") {
                    orderStatus = "16";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("L2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("C1");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.cleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });		
            }
            else {	
                //console.log("overnight approver - else")
                //Status Approved 2				
                if (orderStatus != "3") {
                    orderStatus = "2";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        this.insertTblApprovalHistory("L2").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.cleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });				
            }										
        }
        else{
            //console.log("not overnight approver")
            return this.cleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles);
        }
    }

    cleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bClean, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial, allSingles){
        
        if (bLabelCleanApprover && bClean) {
            //console.log("clean approver")
            if (bCfoRequired) {
                //console.log("clean approver - cfo")
                //Cfo approval 16
                if (orderStatus != "3") {
                    orderStatus = "16";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("L1").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("C1");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.cfoApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean,  allSingles, bClean, orderStatus, sOvernight, sSalesType, sMaterial);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
            else if (!allSingles) {
                //console.log("clean approver - not all singles")
                //Clean approval 13
                if (orderStatus != "3") {
                    orderStatus = "13";
                }
                return this.updateCompleteOrder("one",orderStatus,"100",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("L1").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("C");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.cfoApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean,  allSingles, bClean, orderStatus, sOvernight, sSalesType, sMaterial);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }
            else {
                //console.log("clean approver - else")
                //Status Approved 2
                if (orderStatus != "3") {
                    orderStatus = "2";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        this.insertTblApprovalHistory("L1").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.cfoApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean,  allSingles, bClean, orderStatus, sOvernight, sSalesType, sMaterial);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        }); 
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });       
            }									
        }
        else{
            //console.log("not clean approver")
            return this.cfoApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean,  allSingles, bClean, orderStatus, sOvernight, sSalesType, sMaterial);
        }
    }

    cfoApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bCfoRequired, bFullLengthCleanApprover, bWasClean,  allSingles, bClean, orderStatus, sOvernight, sSalesType, sMaterial){
        
        if (bCfoApprover && bCfoRequired) {
            //console.log("cfo approver")
            if (!allSingles && bClean) {
                //console.log("cfo approver - not all singles & clean")
                //Clean approval 13
                if (orderStatus != "3") {
                    orderStatus = "13";
                }
                return this.updateCompleteOrder("one",orderStatus,"100",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        return this.insertTblApprovalHistory("C1").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.emailApprover("C");
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.fullLengthCleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover, allSingles, bClean, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        }); 
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                }); 
            }
            else {	
                //console.log("cfo approver - else")
                //Status approved 2			
                if (orderStatus != "3") {
                    orderStatus = "2";
                }
                return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                    if(resUpdateCompleteOrder){
                        this.insertTblApprovalHistory("C1").then(resInsertTblApprovalHistory =>{
                            if(resInsertTblApprovalHistory){
                                this.getApproverNameComments().then(resApproverNameComments => {
                                    this.emailRequestor(resApproverNameComments);
                                }).catch(error =>{
                                    console.error('There was an error!', error);
                                });
                            }
                            return this.fullLengthCleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover, allSingles, bClean, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial);
                        }).catch(error =>{
                            console.error('There was an error!', error);
                        });
                    }
                }).catch(error =>{
                    console.error('There was an error!', error);
                });  			
            }									
        }
        else{
            //console.log("not cfo approver")
            return this.fullLengthCleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover, allSingles, bClean, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial);
        }
    }

    fullLengthCleanApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover, allSingles, bClean, bWasClean, orderStatus, sOvernight, sSalesType, sMaterial){
        
        if (bFullLengthCleanApprover && !allSingles && (bClean || bWasClean)) {	
            //console.log("full length clean approver")
            //Status approved 2			
            if (orderStatus != "3") {
                orderStatus = "2";
            }
            return this.updateCompleteOrder("one",orderStatus,"0",sOvernight,sSalesType,sMaterial).then(resUpdateCompleteOrder => {
                if(resUpdateCompleteOrder){
                    return this.insertTblApprovalHistory("C").then(resInsertTblApprovalHistory => {
                        if(resInsertTblApprovalHistory){
                            this.getApproverNameComments().then(resApproverNameComments => {
                                this.emailRequestor(resApproverNameComments);
                            }).catch(error =>{
                                console.error('There was an error!', error);
                            });
                        }
                        return this.notAboveApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover);
                    }).catch(error =>{
                        console.error('There was an error!', error);
                    });
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            });					
        }
        else{
            //console.log(" not full length clean approver")
            return this.notAboveApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover);
        }
    }

    notAboveApprover(processName, bTwoBeforeDepot, bLabelApprover, bOvernightApprover, bLabelCleanApprover, bCfoApprover, bFullLengthCleanApprover){
        
        if (!(bTwoBeforeDepot || bLabelApprover || bOvernightApprover || bLabelCleanApprover || bCfoApprover || bFullLengthCleanApprover)) {
            //console.log("notAboveApprover")
            var sApproverType : string = "";
            return this.getNotDepotLabelApprover(processName).then(response => {
                sApproverType = response.user_type.trim();
                if (sApproverType.length>0)	{
                    this.emailApprover(sApproverType);
                }
                return this.byPassApproval();
            }).catch(error =>{
            console.error('There was an error!', error);
            });
        }
        else{
            //console.log("aboveApprover")
            return this.byPassApproval();
        }  
    }

    rejectOrder(comments : string){
        var orderStatus : string = "3";						
        var sApproverLevels : string = "";
        var bThreeBeforeDepot : boolean = false; 
        var bTwoBeforeDepot : boolean = false;
        var bLabelApprover : boolean = false;
        var bOvernightApprover : boolean = false;
        var bLabelCleanApprover: boolean = false;
        var bFullLengthCleanApprover : boolean = false;
        var bCfoApprover : boolean = false;							
    
        return this.getOrderSummary().then(resOrderSummary => {
            return this.getApproverLevels(resOrderSummary.Process_Name).then(resApproverLevels =>{
                sApproverLevels = resApproverLevels;
                bThreeBeforeDepot = sApproverLevels.indexOf("A4") > -1 ? true : false;
                bTwoBeforeDepot = sApproverLevels.indexOf("A3")>-1?true:false;
                bLabelApprover = sApproverLevels.indexOf("A2")>-1?true:false;
                bOvernightApprover = sApproverLevels.indexOf("L2")>-1?true:false;
                bLabelCleanApprover = sApproverLevels.indexOf("L1")>-1?true:false;
                bFullLengthCleanApprover = sApproverLevels.indexOf("C")>-1?true:false;
                bCfoApprover = sApproverLevels.indexOf("C1")>-1?true:false;

                return this.updateRejectOrder(orderStatus).then(response => {
                    if (bThreeBeforeDepot) {
                        this.csApproverComments = comments;
                        this.insertTblApprovalHistory("A4");					
                    }
                    else {
                        if (bTwoBeforeDepot) {
                            this.csApproverComments = comments;
                            this.insertTblApprovalHistory("A3");					
                        }
                        else {
                            if (bLabelApprover) {
                                this.csApproverComments = comments;
                                this.insertTblApprovalHistory("A2");					
                            }
                            else {
                                if (bOvernightApprover) {
                                    this.csApproverComments = comments;
                                    this.insertTblApprovalHistory("L1");					
                                }
                                else {
                                    if (bLabelCleanApprover) {
                                        this.csApproverComments = comments;
                                        this.insertTblApprovalHistory("L1");					
                                    }
                                    else {
                                        if (bFullLengthCleanApprover) {
                                            this.csApproverComments = comments;
                                            this.insertTblApprovalHistory("C");					
                                        }
                                        else {
                                            if (bCfoApprover) {
                                                this.csApproverComments = comments;
                                                this.insertTblApprovalHistory("C1");					
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.getApproverNameComments().then(resApproverNameComments => {
                        this.emailRequestor(resApproverNameComments);
                    }).catch(error =>{
                        console.error('There was an error!', error);
                    });
                    return this.response;
                }).catch(error =>{
                    console.error('There was an error!', error);
                });                
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    byPassApproval(){
        
        var salesType : string = "";
        var material : string = "";
        var cost : number;
        var price : number;
        var smmoney : string = "";
        var percen : number;
        var byPassFlag : boolean = false;
        //console.log("inside bypass approval")
        return this.getOrderSummary().then(resOrderSummary => {
            this.csSalesType = resOrderSummary.sales_type;
            return this.getOrderItemsForByPassApproval().then(resOrderItems => {
                if (resOrderSummary.Billable){
                    //console.log("billable")
                    switch (this.csSalesType){
                        case "54 - Clean (non-royalty bearing)":
                            //console.log("billable - 54 clean")
                            resOrderItems.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    byPassFlag = true;
                                    return;
                                }
                                else if (percen != parseFloat("0.4")){
                                    byPassFlag = true;
                                    return;
                                }
                            })
                            break;
                        case "56 - Clean (fully royalty bearing)":
                            //console.log("billable - 56 clean")
                            resOrderItems.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    byPassFlag = true;
                                    return;
                                }
                                else if (percen > parseFloat("0.15")){
                                    byPassFlag = true;
                                    return;
                                }
                            })
                            break;
                        case "59 - Clean (publisher royalties only)":
                            //console.log("billable - 59 clean")
                            resOrderItems.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    byPassFlag = true;
                                    return;
                                }
                                else if (percen != parseFloat("0.3")){
                                    byPassFlag = true;
                                    return;
                                }
                            })
                            break;  
                        case "66 Ticket Bundling":
                            //console.log("Ticket Bundling - 66 clean")
                            resOrderItems.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    byPassFlag = true;
                                    return;
                                }
                                else if (percen != parseFloat("0.15")){
                                    byPassFlag = true;
                                    return;
                                }
                            })
                            break;                
                    }                
                }
                else {
                    //console.log("not billable")				
                    if(this.csSalesType.toLowerCase().trim() == "51 Clean" || this.csSalesType.toLowerCase().trim() == "91 Clean"){
                        byPassFlag = true;
                    }
                }
                var requestorWeight : string = "";
                var statusId : number;
                if (!byPassFlag){
                    //console.log("not bypass approval")
                    return this.getCompleteOrder().then(resCompleteOrder => {
                        requestorWeight = resCompleteOrder.Requestor_Weight;
                        statusId = resCompleteOrder.Status_Id;
                        if (requestorWeight == "100" && statusId == 13)
                        {
                            return this.updateCompleteOrder("three").then(resUpdateCompleteOrder => {
                                if(resUpdateCompleteOrder){
                                    return true;
                                }
                            }).catch(error =>{
                                console.error('There was an error!', error);
                            });   
                        }
                    }).catch(error =>{
                        console.error('There was an error!', error);
                    }); 
                }
                return this.updateCompleteOrder("two","2").then(resUpdateCompleteOrder => {
                    //console.log(this.response)
                    return this.response 
                }).catch(error =>{
                    console.error('There was an error!', error);
                });
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    overnightBillableEmailStatus(){
        //console.log("inside overnightBillableEmailStatus")
        var salesType : string = "";
        var material : string = "";
        var cost : number;
        var price : number;
        var smmoney : string = "";
        var percen : number;
        var resSendMail : boolean = false;

        return this.getOrderSummary().then(resOrderSummary => {
            this.csSalesType = resOrderSummary.sales_type;
            return this.getOverNightBillable().then(res1 => {
                if (resOrderSummary.Billable){
                    switch (this.csSalesType){
                        case "54 - Clean (non-royalty bearing)":
                            res1.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    resSendMail = true;
                                }
                                else if (percen != parseFloat("0.4")){
                                    resSendMail = true;
                                }
                            })
                            break;
                        case "66 Ticket Bundling":
                        case "56 - Clean (fully royalty bearing)":
                            res1.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    resSendMail = true;
                                }
                                else if (percen != parseFloat("0.15")){
                                    resSendMail = true;
                                }
                            })
                            break;
                        case "59 - Clean (publisher royalties only)":
                            res1.recordset.map((prop, key)=>{
                                price = prop["price_approved"];
                                cost = prop["Cost"];
                                percen = (cost - price) / cost;
                                var per : string = percen.toFixed(2);
                                percen = parseFloat(per);
                                if(price>0 && price>cost){
                                    resSendMail = true;
                                }
                                else if (percen != parseFloat("0.3")){
                                    resSendMail = true;
                                }
                            })
                            break;                  
                    }                
                }
                else {				
                    if(this.csSalesType.toLowerCase() == "51 clean"){
                        resSendMail = true;
                    }
                }
                return resSendMail;
            }).catch(error =>{
            console.error('There was an error!', error);
            });
        }).catch(error =>{
            console.error('There was an error!', error);
        });   
    }

    updateOrderItems() : any{
        return this.getOrderSummary().then(resOrderSummary => {
            var rejectedCount : number = 0;
			var rejected : boolean;
            var itemId : string = "", qty : string = "", price : string = "", itemOrderStatus : string ="", resOrderStatus : string = "0";

            this.orderItems.map((prop, key)=>{
                itemId = prop["item_id"];
		        qty = prop["Quantity_Approved"];
                price = prop["Price_Approved"];
				rejected = prop["RejectItem"];
				itemOrderStatus = "";	
				if (rejected || qty == "0"){
					qty = "0";		
					price = "0";		
					itemOrderStatus = "3";		
					rejectedCount++;		
                }
                this.updateOrderItemsCount(resOrderSummary.Billable ? 1 : 0, itemId, itemOrderStatus, qty, price)
            })

            if (rejectedCount == Object.keys(this.orderItems).length) {
                resOrderStatus = "3" ;	
            }
			return resOrderStatus;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    emailRequestor(resApproverNameComments){
        var emailRequestor : boolean = false;
        if (this.cbSelfOrder) {
            return emailRequestor;
        } 
        var orderDetail : string = ""; 
        var sTo: string = "";
        var sBody: string = "";
        var sSubject: string = "";
        var sWebServer: string = "";

        return this.getOrderSummary().then(resOrderSummary => {
            return this.getOrderDetail().then(resOrderDetail =>{
                resOrderDetail.recordset.map((prop,key)=>{
                    orderDetail += "<p>" + "Selection: " + prop["Selection_Id"][0];
				    orderDetail += "<br>" + "Artist: " + prop["Artist"];
				    orderDetail += "<br>" + "Title: " + prop["Title"];
				    orderDetail += "<br>" + "Configuration: " + prop["Configuration"][0];
				    orderDetail += "<br>" + "Qty Req: " + prop["Quantity_Requested"]; 
				    orderDetail += "<br>" + "Qty App: " + prop["Quantity_Approved"];
                    if (resOrderSummary.Billable) {
                        orderDetail += "<br>" + "Price Req: " + prop["Price_Requested"];
                        orderDetail += "<br>" + "Price App: " + prop["Price_Approved"];
                    }
                })
                
                sTo = resOrderSummary.alias_Email;
                if (sTo.length == 0) {
                    sTo="egratis@umusic.com";
                }

			    sBody = "Order: " + resOrderSummary.Order_Id + "<br>";
			    sBody += "Status: " + resOrderSummary.StatusDesc + "<br>";
			    sBody += "Ship To: " + resOrderSummary.Name+ " at " + resOrderSummary.ShipToName + "<br>";
                if (!(resOrderSummary.Approval_Date == null ? true : false )){
                    sBody += "Status Date: " +  resOrderSummary.Approval_Date.slice(0,10) + "<br>";
                    //console.log("status Date",resOrderSummary.Approval_Date.slice(0,10));
                }
                sBody +="Submit Date:" + resOrderSummary.Request_Date.slice(0,10) + "<br>";
                if (resOrderSummary.Billable) {	
                    sBody =sBody + "Account No: " + resOrderSummary.account_no + "<br>";
                    sBody =sBody + "Freight Billing Info: " + resOrderSummary.freight_billing_info + "<br>";
                    sBody =sBody + "Pre-Paid: ";
                    if (resOrderSummary.prepaid)
                        sBody =sBody + "Yes"  + "<br>";
                    else			
                        sBody =sBody + "No"  + "<br>";
                }				
                sBody += "Shipment Method: " + resOrderSummary.Ship_Method + "<br>";
                sBody += "Business Unit: " + resOrderSummary.BusinessUnit + "<br>" + orderDetail + "<p>";

                resApproverNameComments.recordset.map((prop,key)=>{
                    sBody += "Approver: " + prop["name"];
                    sBody += "<br>" + "Approver Comments: " + prop["notes"] + "<p>"
                })
                sBody += "Thank you for using eGratis!";
                //console.log(sBody);
                sWebServer = window.location.hostname.toUpperCase();
                if (sWebServer=="USUSH2KVWS11")	sSubject="(predev) ";
                if (sWebServer=="USUSHWVIIS001") sSubject="(dev) ";
                if (sWebServer=="USUSHWVIIS002") sSubject="(test) ";
                if (sWebServer=="USUSH2KAP17" || sWebServer=="USUSHWVIIS010") sSubject="(staging) ";
                sSubject+="eGratis Order Status " + this.csOrderId + " for: "  +  resOrderSummary.Name + " at " + resOrderSummary.ShipToName;

                this.sendEmail("egratis@umusic.com", sTo, sSubject,sBody);
                emailRequestor = true;
                return emailRequestor;
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    emailApprover(approverType){
        //console.log("inside emailApprover");
        var overnightBillable : boolean = false;
        return this.overnightBillableEmailStatus().then(resSendEmail => {
            overnightBillable = resSendEmail;
            return this.getOrderSummary().then(resOrderSummary => {
                if (((!this.cbSelfOrder && (resOrderSummary.Status_Id != "3")) && (resOrderSummary.Ship_Method.Length != 0)) && (resOrderSummary.Ship_Method != "Standard Ground") && (resOrderSummary.Ship_Method != "Pick-Up")){
                    //console.log("inside if");
                    return this.getApproverEmail(approverType, resOrderSummary.Process_Name).then(resApproverEmail => {
                        //console.log(resApproverEmail.Alias + " " + this.csUId);
                        //console.log(resApproverEmail.Alias.toLowerCase() + " " + this.csUId.toLowerCase());
                        if (resApproverEmail.success && (resApproverEmail.Alias.toLowerCase() != this.csUId.toLowerCase())){
                            var str2 : string = "";
                            var str3 : string = "";
                            var str4 : string = "";
                            
                            switch (window.location.hostname.toUpperCase.toString())
                            {
                                case "USUSH2KVWS11":
                                    str3 = "(predev) ";
                                    break;
        
                                case "USUSHWVIIS001":
                                    str3 = "(dev) ";
                                    break;
        
                                case "USUSHWVIIS002":
                                    str3 = "(test) ";
                                    break;
        
                                case "USUSH2KAP17":
                                case "USUSHWVIIS010":
                                    str3 = "(staging) ";
                                    break;
                            }
                            if ((resOrderSummary.Ship_Method.length > 0) && (resOrderSummary.Ship_Method.trim() != "Standard Ground")){
                                str3 = str3 + "Overnight: eGratis Orders to Approve";
                            }
                            else{
                                str3 = str3 + "eGratis Orders to Approve";
                            }
                            str2 = resApproverEmail.Email.trim();
                            if (str2.length == 0){
                                str2 = "egratis@umusic.com";
                            }
                            
                            str4 =  "You have order #" + this.csOrderId + " waiting for your approval in eGratis.<br><a href='" + window.location.hostname + "'>" + window.location.hostname + "</a><br>Thank you for using eGratis!<br>" + this.dateTimeFormat.getDateTime() + "<p>";
                            str4 += "<br>Approver Level: " + resApproverEmail.User_Type.trim() + " " + this.csApproverComments + " " + this.dateTimeFormat.getDateTime();
                            str4 += "<br><u>Requestor Information</u>" + "<br>User Name: " + resOrderSummary.alias_Name + "<br>Email: " + resOrderSummary.alias_Email;
                            
                            var sendMail : boolean = true;
                            if(overnightBillable==false && approverType=="C"){
                                sendMail=false;
                            }
                            if (approverType=="A2" && resOrderSummary.sales_type == "53 Marked" && resOrderSummary.Billable == false){
                                sendMail=false;
                            }
    
                            return this.checkApprovalEmail(approverType).then(resApprovalEmailCount => {
                                if(resApprovalEmailCount <= 0){
                                    if (sendMail){
                                        this.sendEmail("egratis@umusic.com",str2,str3,str4);
                                    }
                                }
                                if (!this.cbNewOrder){
                                    this.approverEmailHistory(approverType);
                                }
                            }).catch(error =>{
                                console.error('There was an error!', error);
                            });	
                        }
                    }).catch(error =>{
                        console.error('There was an error!', error);
                    });	
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            });
        }).catch(error =>{
            console.error('There was an error!', error);
        });	
    }

    approverEmailHistory (approverType : string){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.insertTblEmailHistory;
        var data = {
            orderId: this.csOrderId,
            approverType: approverType,
            approverAlias: this.csUId
        }
        this.serviceProxy.post(url,data).then(response => {
            if(response.success){
                return;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    checkApprovalEmail(approverType: string){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.checkApprovalEmail;
        var data = {
            orderId: this.csOrderId,
            approverType: approverType,
            approverAlias: this.csUId
        }
        var resApprovalEmailCount : number = 0;
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                resApprovalEmailCount = response.number;
            }
            return resApprovalEmailCount;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getOrderSummary(){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getOrderSummary;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resOrderSummary = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                //console.log(resOrderSummary);
                return resOrderSummary;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getApproverLevels(processName : string) : any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getApproverLevels;
        var data = {
            processName: processName,
            alias: this.csUId
        }
        return this.serviceProxy.get(url,data).then(response => {
            var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            var resApproverLevels : string[] = [];
            if(response.success){
                res.recordset.forEach(level);
                function level(item: { user_type: string }){
                    resApproverLevels.push(item.user_type)
                }
            }
            //console.log(resApproverLevels);
            return resApproverLevels;
        }).catch(error => {
            console.error('There was an error!', error);
            throw error;  // Ensure error is propagated
        });
    }

    checkCFOApproval(processName: string){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.checkCFOApproval;
        var data = {
            processName: processName,
        }
        var cfoApprovalCount : number = 0;
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                cfoApprovalCount = response.value;
            }
            return cfoApprovalCount;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    checkOrderConfiguration() : any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.checkOrderConfiguration;
        var data = {
            orderId: this.csOrderId,
        }
        var orderConfigurationCount = {
            totalOrderItems : 0,
            singleOrderItems : 0
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                orderConfigurationCount.totalOrderItems = response.totalOrderItems;
                orderConfigurationCount.singleOrderItems = response.singleOrderItems;
            }
            return orderConfigurationCount;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    updateSelfOrderItems(){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.updateSelfOrderItems;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.put(url,data).then(response=>{
            if(response.success){
                return response;
            }
            else{
                console.log("Update not successful")
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private updateCompleteOrder(type, orderStatus ?: string, requestorWeight ?: string, overNight ?: string, salesType ?: string, material ?: string){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.updateCompleteOrder;
        var data = {
            type: type,
            requestorWeight: requestorWeight,
            statusId: orderStatus,
            overNight: overNight,
            shipMethod: this.csShipMethod,
            salesType: salesType,
            material: material,
            orderId: this.csOrderId
        }
        var resUpdateCompleteOrder : boolean = false;
        return this.serviceProxy.put(url,data).then(response=>{
            if(response.success){
                resUpdateCompleteOrder = true;
            }
            return resUpdateCompleteOrder;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private insertTblApprovalHistory(approverType){
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.insertTblApprovalHistory;
        var data = {
            orderId: this.csOrderId,
            approverType: approverType,
            approverId: this.csUId,
            notes: this.csApproverComments
        }
        var resInsertTblApprovalHistory : boolean = false;
        return this.serviceProxy.post(url,data).then(response=>{
            if(response.success){
                resInsertTblApprovalHistory = true;
            }
            return resInsertTblApprovalHistory;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getEmailNotice(userType, processName): any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getEmailNotice;
        var data = {
            alias: this.csUId,
            userType: userType,
            processName: processName
        }
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){
                return response;
            }    
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getNotDepotLabelApprover(processName): any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getNotDepotLabelApprover;
        var data = {
            processName: processName
        }
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){
                return response;
            }    
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    // private updateRejectOrder(orderStatus): any{
    //     debugger;
    //     var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.updateRejectOrder;
    //     var data = {
    //         orderStatus: orderStatus,
    //         orderId: this.csOrderId
    //     }
    //     this.serviceProxy.put(url,data).then(response=>{
    //         debugger;
    //         if(response.success){
    //             return response;
    //         }    
    //     }).catch(error => {
    //         console.error('There was an error!', error);
    //     });
    // }

    private updateRejectOrder(orderStatus: string): Promise<any> {
        debugger;
        var url = this.variables.Url + this.variables.Controller.createOrder + this.variables.createOrderService.updateRejectOrder;
        var data = {
            orderStatus: orderStatus,
            orderId: this.csOrderId
        };
    
        // Return a promise from the method
        return this.serviceProxy.put(url, data)
            .then(response => {
                debugger;
                if (response.success) {
                    return response;
                } else {
                    throw new Error('Failed to update reject order');
                }
            })
            .catch(error => {
                console.error('Error in updateRejectOrder:', error);
                throw error;  // Re-throw the error to propagate it
            });
    }
    

    private getOverNightBillable(): any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getOverNightBillable;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.get(url,data).then(response=>{
            var res1;
            if(response.success){
                res1 = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"')); 
            }   
            return res1
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getOrderItemsForByPassApproval(): any{
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getOrderItemsForByPassApproval;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.get(url,data).then(response=>{
            var resOrderItems: any;
            if(response.success){
                resOrderItems = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            } 
            return resOrderItems;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getCompleteOrder() {
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getCompleteOrder;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resCompleteOrder = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resCompleteOrder;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    updateOrderItemsCount(billable, itemId, statusId, quantityApproved, priceApproved) {
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.updateOrderItems;
        var data = {
            billable: billable,
            orderId: this.csOrderId,
            itemId: itemId,
            statusId: statusId,
            quantityApproved: quantityApproved,
            priceApproved: priceApproved,
            approverId: this.csUId
        }
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getOrderDetail() {
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getOrderDetail;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resOrderDetail = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resOrderDetail;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getApproverNameComments() {
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getApproverNameComments;
        var data = {
            orderId: this.csOrderId
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resApproverNameComments = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resApproverNameComments;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private getApproverEmail(approverType: string, processName: string) {
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.getApproverEmail;
        var data = {
            userType: approverType,
            processName: processName
        }
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                var resApproverEmail = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resApproverEmail;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    private sendEmail(from: string, to: string, subject: string, html: string) {
        var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.sendEmail;
        var data = {
            from: from,
            to: to,
            subject: subject,
            html: html
        }
        return this.serviceProxy.post(url,data).then(response => {
            if(response.success){
                return;
            }
            else{
                console.error("Error in sending email...," + response.message)
                return;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}