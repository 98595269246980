import { 
  faHome,faPrint,faInbox,faFileInvoiceDollar,faFileAlt,faFileInvoice,faHistory,faSearch,faUserEdit,faQuestionCircle,faUsers,
  faFolder,faCogs,faAngleDoubleRight,faSlidersH,faUserTie,faUserPlus,faSignal,faUser,faFileSignature,faBinoculars,faUserTag,faSignOutAlt,faKey,faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import {Home} from "../Components/View/Home/Home"
import {CreateOrder} from "../Components/View/CreateOrder/CreateOrder";
import {EditUser} from "../Components/View/Register/EditUser";
import {ResetPassword} from "../Components/View/PasswordReset/PasswordReset";
import {EgratisFAQ} from "../Components/View/EgratisFAQ/EgratisFAQ"
import { QuickProductSearch } from '../Components/View/QuickProductSearch/QuickProductSearch';
import {UserView} from "../Components/View/UserView/UserView";
import { CreateBillableOrder } from '../Components/View/CreateBillableOrder/CreateBillableOrder';
import { FreeGoodsOrder } from '../Components/View/FreeGoodsOrder/FreeGoodsOrder';
import {ViewOrder} from "../Components/View/ViewOrderStatus/ViewOrder"; 
import {FreeGoodsReqForm} from '../Components/View/FreeGoodsOrder/FreeGoodsReqForm';
import {ViewApprovalInbox} from "../Components/View/ViewOrderStatus/ViewApprovalInbox"
import { BillableReqForm } from '../Components/View/CreateBillableOrder/BillableReqForm';
import { DepartmentMaintenance } from '../Components/View/ControlPanel/DepartmentMaintenance';
import { CreateApprovalProcess } from '../Components/View/ControlPanel/CreateApprovalProcess';
import { NonBillableTransferForm } from '../Components/View/NonBillableOrder/NonBillableTransferForm';
import { NonBillableOrder } from '../Components/View/NonBillableOrder/NonBillableOrder';
import { DepotOrderUpdate } from '../Components/View/Depot/DepotOrderUpdate';
import { DepotOrderHistory } from '../Components/View/Depot/DepotOrderHistory';
import { DepotOrderUpdateView } from '../Components/View/Depot/DepotOrderUpdateView';
import { DepotOrderHistoryView } from '../Components/View/Depot/DepotOrderHistoryView';
import { ReportView } from '../Components/View/Reports/Reports';
import { ViewApprovalProcess } from '../Components/View/ControlPanel/ViewApprovalProcess';
import { LabelMaintenance } from '../Components/View/ControlPanel/LabelMaintenance';
import { AddRemoveApprovers } from '../Components/View/ControlPanel/AddRemoveApprovers';
import { FtpHistory } from '../Components/View/FTPHistory/FtpHistory';
import { SearchOrderHistory } from '../Components/View/SearchHistory/SearchOrderHistory';
import { ApprovalOrderHistory } from '../Components/View/ApprovalHistory/ApprovalHistory';
import { ApprovalHistoryView } from '../Components/View/ApprovalHistory/ApprovalHistoryView';
import { OrdersView } from '../Components/View/OrdersView/Orders_View';
import { ApprovalProcessAdmin } from '../Components/View/ApprovalProcessAdmin/ApprovalProcessAdmin';
import { AddExecutiveUser } from '../Components/View/UserView/AutoAddExecutiveUser';
import { AppSettings } from '../Components/View/AppSettings/AppSettings';
import { ViewOrderDetails } from '../Components/View/ViewOrderStatus/ViewOrder_Details';
import { EditApprovalProcess } from '../Components/View/ControlPanel/EditApprovalProcess';
import { SearchHistory } from '../Components/View/SearchHistory/SearchHistory';
import { ReportOrderByRequestor } from '../Components/View/Reports/ReportOrderByRequestor';
import{SelectRole} from'../Components/View/Login/selectRole'
import{CreateProfile} from'../Components/View/Register/CreateProfile'
/*import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";
import Upgrade from "views/Upgrade.jsx";*/
export class Routes {
  
dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: faHome,
    component: Home,
    layout: "/admin",
    view:"MNU2",
    submenu:[]
  },
  {
    path: "/selectRole",
    name: "Select Role",
    icon: faUser,
    component: SelectRole,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/CreateProfile",
    name: "Create Profile",
    icon: faUser,
    component: CreateProfile,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/report.menu",
    name: "View/Print Reports",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU1",
    //submenu:[{path: "/usertotals",name: "User Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/selectiontotals",name: "Selection Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/departmenttotals",name: "Department Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/detailorderreport",name: "Detail Order Report",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/shiptototals",name: "Ship To Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"}]
    submenu:[{path: "/usertotals",name: "User Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/selectiontotals",name: "Selection Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/departmenttotals",name: "Department Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/detailorderreport",name: "Detail Order Report",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/shiptototals",name: "Ship To Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/orderbyrequestor",name: "Order By Requestor",icon: faAngleDoubleRight,component: ReportOrderByRequestor,layout: "/admin"}]
  },
  {
    path: "/order_approval_inbox",
    name: "My Approval InBox",
    icon: faInbox,
    component: ViewApprovalInbox,
    layout: "/admin",
    view:"MNU2",
    submenu:[]
  },
  {
    path: "/order_approval_history",
    name: "My Approval History",
    icon: faHistory,
    component: ApprovalOrderHistory,
    layout: "/admin",
    view:"MNU2",
    submenu:[]
  },
  {
    path: "/report.menu",
    name: "View/Print Reports",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU2",
    //submenu:[{path: "/usertotals",name: "User Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/selectiontotals",name: "Selection Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/departmenttotals",name: "Department Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/detailorderreport",name: "Detail Order Report",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/shiptototals",name: "Ship To Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"}]
    submenu:[{path: "/usertotals",name: "User Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/selectiontotals",name: "Selection Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/departmenttotals",name: "Department Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/detailorderreport",name: "Detail Order Report",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/shiptototals",name: "Ship To Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/orderbyrequestor",name: "Order By Requestor",icon: faAngleDoubleRight,component: ReportOrderByRequestor,layout: "/admin"}]
  },
  {
    path: "/FreeGoodsOrder",
    name: "Create a Free Goods Req",
    icon: faFileAlt,
    component: CreateOrder,
    layout: "/admin",
    view:"MNU3",
    submenu:[]
  },
  {
    path: "/CreateBillableOrder",
    name: "Create Billable Order",
    icon: faFileInvoiceDollar,
    component: CreateOrder,
    layout: "/admin",
    view:"MNU3",
    submenu:[]
  },
  {
    path: "/TransferNonBillable",
    name: "Create NonBill Trans Order",
    icon: faFileInvoice,
    component: CreateOrder,
    layout: "/admin",
    view:"MNU4",
    submenu:[]
  }, 
  {
    path: "/order_status",
    name: "View Order Status",
    icon: faSignal,
    component: ViewOrder,
    layout: "/admin",
    view:"MNU5",
    submenu:[]
  },
  {
    path: "/order_history",
    name: "Search History",
    icon: faHistory,
    component: SearchHistory ,
    layout: "/admin",
    view:"MNU5",
    submenu:[]
  },
  {
    path: "/depot_pending_orders",
    name: "Depot Order Update",
    icon: faFileSignature,
    component: DepotOrderUpdate,
    layout: "/admin",
    view:"MNU6",
    submenu:[]
  },
  {
    path: "/depot_history",
    name: "Depot History",
    icon: faHistory,
    component: DepotOrderHistory,
    layout: "/admin",
    view:"MNU6",
    submenu:[]
  },
  {
    path: "/user_view",
    name: "Users View",
    icon: faUsers,
    component: UserView,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/orders_view",
    name: "Orders View",
    icon: faBinoculars,
    component: OrdersView,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/report.menu",
    name: "View/Print Reports",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU7",
    //submenu:[{path: "/usertotals",name: "User Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/selectiontotals",name: "Selection Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/departmenttotals",name: "Department Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/detailorderreport",name: "Detail Order Report",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/shiptototals",name: "Ship To Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"}]    
    submenu:[{path: "/usertotals",name: "User Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/selectiontotals",name: "Selection Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/departmenttotals",name: "Department Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/detailorderreport",name: "Detail Order Report",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/shiptototals",name: "Ship To Totals",icon: faAngleDoubleRight,component: ReportView,layout: "/admin"},{path: "/orderbyrequestor",name: "Order By Requestor",icon: faAngleDoubleRight,component: ReportOrderByRequestor,layout: "/admin"}]

  },
  {
    path: "/ftp_order_history",
    name: "FTP History",
    icon: faHistory,
    component: FtpHistory,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/update_app_setting",
    name: "Application Settings",
    icon: faCogs,
    component: AppSettings,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/new_sysopProcess",
    name: "Control Panel",
    icon: faSlidersH,
    component: Home,
    layout: "/admin",
    view:"MNU7",
    submenu:[{path: "/labelMaintenance",name: "Label Maintenance",icon: faAngleDoubleRight,component: LabelMaintenance ,layout: "/admin"},{path: "/deptmaintenance",name: "Dept Maintenance",icon: faAngleDoubleRight,component: DepartmentMaintenance,layout: "/admin"},{path: "/createapprovalprocess",name: "Create Approval Process",icon: faAngleDoubleRight,component: CreateApprovalProcess,layout: "/admin"},{path: "/viewapprovalprocess",name: "View Approval Process",icon: faAngleDoubleRight,component: ViewApprovalProcess,layout: "/admin"}]
  },
  {
    path: "/auto_add_exec_user_dept",
    name: "Add Exec User to Dept",
    icon: faUserPlus,
    component: AddExecutiveUser,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/addremoveapprover",
    name: "Approval Process Admin",
    icon: faUserTie,
    component: ApprovalProcessAdmin,
    layout: "/admin",
    view:"MNU7",
    submenu:[]
  },
  {
    path: "/TicketBundling",
    name: "Ticket Bundling",
    icon: faFileInvoiceDollar,
    component: CreateOrder,
    layout: "/admin",
    view:"MNU8",
    submenu:[]
  },
  {
    path: "/order_status",
    name: "View Order Status",
    icon: faSignal,
    component: ViewOrder,
    layout: "/admin",
    view:"MNU8",
    submenu:[]
  },
  {
    path: "/ValidateCreateOrder",
    name: "D2C",
    icon: faUserTag,
    component: CreateOrder,
    layout: "/admin",
    view:"MNU9",
    submenu:[]
  },
  {
    path: "/order_status",
    name: "View Order Status",
    icon: faSignal,
    component: EditUser,
    layout: "/admin",
    view:"MNU9",
    submenu:[]
  },
  {
    path: "/quick_product_search",
    name: "Quick Product Search",
    icon: faSearch,
    component: QuickProductSearch,
    layout: "/admin",
    view:"MNU10",
    submenu:[]
  },
  {
    path: "/eGratis_FAQ",
    name: "eGratis FAQ",
    icon: faQuestionCircle,
    component: EgratisFAQ,
    layout: "/admin",
    view:"MNU10",
    submenu:[]
  },
  {
    path: "/EditUser",
    name: "Edit My User Profile",
    icon: faUserEdit,
    component: EditUser,
    layout: "/admin",
    view:"MNU10",
    submenu:[]
  },
  {
    path: "/changepassword",
    name: "Change Password",
    icon: faKey,
    component: ResetPassword,
    layout: "/admin",
    view:"M",
    submenu:[]
  },
  {
    path: "https://www.umgb2b.com/Security/Login.aspx?ReturnUrl=%2f",
    name: "UMG B2B",
    icon: faExternalLinkAlt,
    component: 'UMG B2B',
    layout: "",
    view:"MNU10",
    submenu:[]
  },
  {
    path: "/freegoodsreqform",
    name: "Free Goods Request Form",
    icon: faKey,
    component: FreeGoodsReqForm,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/billablereqform",
    name: "Billable Request Form",
    icon: faKey,
    component: BillableReqForm,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/nonbillablereqform",
    name: "Billable Request Form",
    icon: faKey,
    component: NonBillableTransferForm,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/depotorderupdateview",
    name: "Depot Order Update",
    icon: faKey,
    component: DepotOrderUpdateView,
    layout: "/admin",
    view:"MNU11"
  },
  {
    path: "/depotorderhistoryview",
    name: "Depot View Order Update",
    icon: faKey,
    component: DepotOrderHistoryView,
    layout: "/admin",
    view:"MNU11"
  },
  {
    path: "/usertotals",
    name: "User Totals",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/selectiontotals",
    name: "Selection Totals",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/departmenttotals",
    name: "Department Totals",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/detailorderreport",
    name: "Detail Order Report",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/createApprovalprocess",
    name: "Create Approval Process",
    icon: faSlidersH,
    component: CreateApprovalProcess,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/deptmaintenance",
    name: "Dept Maintenance",
    icon: faSlidersH,
    component: DepartmentMaintenance,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/shiptototals",
    name: "Ship To Totals",
    icon: faPrint,
    component: ReportView,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/orderbyrequestor",
    name: "Order By Requestor",
    icon: faPrint,
    component: ReportOrderByRequestor,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/viewapprovalprocess",
    name: "View Approval Process",
    icon: faSlidersH,
    component: ViewApprovalProcess,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/labelMaintenance",
    name: "Label Maintenance",
    icon: faSlidersH,
    component: LabelMaintenance,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/addRemoveApprovers",
    name: "Add Remove Approvers",
    icon: faSlidersH,
    component: AddRemoveApprovers,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/editApprovalProcess",
    name: "Edit ApprovalProcess",
    icon: faSlidersH,
    component: EditApprovalProcess,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/ApprovalHistoryView",
    name: "My Approval History",
    icon: faHistory,
    component: ApprovalHistoryView,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  },
  {
    path: "/viewOrderDetails",
    name: "My Order Details",
    icon: faHistory,
    component: ViewOrderDetails,
    layout: "/admin",
    view:"MNU11",
    submenu:[]
  }

];

}
