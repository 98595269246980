import React, { Component } from "react";
import { Container, Card, Row, Col, Table, Form, Alert, Button, Navbar, Nav } from "react-bootstrap";
import { History, LocationState } from "History";
import { UserViewController } from "../../../Controllers/UserViewController"
import { format } from 'date-fns'
import { Variables } from '../../../Variables/Variables';
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { NavLink } from 'react-router-dom'
import '../../../assets/css/CreateOrder.css'
import { FormCheckBox } from "../../CustomComponents/FormCheckBox"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/demo.css'
import { IOrderViewParameter, Istate } from "../../../Model/OrdersView"; 
import{OrderViewController} from "../../../Controllers/OrderViewController"

export class OrdersView extends Component<IOrderViewParameter, Istate> {
    constructor(props: IOrderViewParameter) {

        super(props)
        this.state = {
            fields: { find: ''},
            errors: { find: ''},
            selectfields: { searchBy: '' },
            errMsg: "",
            todayDate: "",
            previousdate: "",
            Order_id: "",
            goodsReq: "",
            loading: false,
            selectedDate: '2017-08-13',
            hidediv: true,
            startDate: "",   
            endDate:"",
            isListAvl:false,
            displayGrid:[],
            isListAvlMsg:"List is Empty...Please search for the needs",
            task_For:"",
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);      
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.onSubmit=this.onSubmit.bind(this); 
        this.setActiveTab=this.setActiveTab.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentWillMount()
    {
      var today    =new Date();      
      var startDate=new Date(today.getTime() - (45 * 24 * 60 * 60 * 1000));
      this.setState({ startDate: startDate});     
      this.setState({ endDate: today});  
      let selectfields = this.state.selectfields;
      selectfields.searchBy= "Requestor";          
      this.setState({selectfields })    
    }


    componentDidMount() {
        var newDate = new Date();
        var pdate = new Date();
        pdate.setDate(pdate.getDate() - 45);
        //var previousDate = format(pdate, 'dd/MM/yyyy');
        //var  todayDate = format(newDate, 'dd/MM/yyyy')


        //this.setState({todayDate : todayDate})  
        // this.setState({previousdate : previousDate})

        // var hiddenInputElement = document.getElementById("example-datepicker");
        //   console.log(hiddenInputElement.value); // ISO String, ex: "2016-11-19T12:00:00.000Z"
        //   console.log(hiddenInputElement.getAttribute('data-formattedvalue')) // Formatted String, ex: "11/19/2016"

        sessionStorage.setItem('ViewOrderStatus', '0' )
        let SH
        SH = sessionStorage.getItem("OrdersViewStatus")  
       sessionStorage.setItem('OrdersViewStatus', '0' )    
       if (SH==='1')
       {
        this.setState({isListAvl:true})
        this.setState({ 
          displayGrid: JSON.parse(sessionStorage.getItem('OrdersView') || "") 
        });
      }
      else
      {
        this.setState({isListAvl:false})
      }

        this.setState({
            hidediv: true
          });

    }

    handleLog(date) {
        this.setState({
            selectedDate: date
        });
    }

    handleClick(e: any) {         
        this.setState({
          hidediv: false
        });
      }

      handleChangeStartDate = date => {
        this.setState({
          startDate: date
        });
      };
      handleChangeEndDate = date => {
        this.setState({
            endDate: date
        });
      };

      handleSelectChange (e:any) {       
        let selectfields = this.state.selectfields;
        selectfields.searchBy= e.currentTarget.value;          
        this.setState({
          selectfields
        })
      }

    handleCheckChange = (e: any) => {
        let checkfields = this.state.fields.checkid;
        // checkfields[e.currentTarget.name] = !this.state.optionfields.name
        // this.setState({
        //     checkfields
        // });
        // backgroundColor:'lightblue';

        const color1 = this.state.fields.checkid.CHECKED === 'Y' ? "red" : "";

    }

    handleTextChange (e:any) {
      let fields = this.state.fields;
      fields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({
          fields
      })
    }

    onSubmit =(e:any)=>{
        try{
        e.preventDefault();
        if (this.ValidateDate()) { 
            this.setState({ loading: true });
            this.setState({isListAvl:false})
            var sD = this.state.startDate;
            var curr_date = sD.getDate();
            var curr_month = sD.getMonth() + 1; //Months are zero based
            var curr_year = sD.getFullYear();
            var startDate = curr_month + "/" + curr_date + "/" + curr_year;
            
            var eD = this.state.endDate;
            var curr_date = eD.getDate();
            var curr_month = eD.getMonth() + 1; //Months are zero based
            var curr_year = eD.getFullYear();
            var endDate = curr_month + "/" + curr_date + "/" + curr_year;

            let orderViewController = new OrderViewController();
            orderViewController.getListOrderView(this.state.fields.find,this.state.selectfields.searchBy,
              startDate,endDate)
            .then(jsonOrderViewList =>{
              this.setState({                           
                displayGrid : JSON.parse(JSON.stringify(jsonOrderViewList)), loading: false
            });                 
               sessionStorage.setItem('OrdersView', JSON.stringify(jsonOrderViewList))
            if(this.state.displayGrid.length > 0)
              {                     
                this.setState({isListAvl:true})
                this.setState({isListAvlMsg:""})
                this.setState({ loading: false });
              }
              else
              {                        
                this.setState({isListAvlMsg:"No record(s) found"})
                this.setState({ loading: false });
              }  
            }).catch(error => {          
            this.setState({ loading: false });
            console.log(error);
          })
        }
      }
      catch(err){
        console.error(err);
        } 
     }

     setActiveTab = (e) => {
        e.preventDefault();
     } 

     ValidateDate(){      
        this.clearError();
        let formIsValid = true;  
        let errors = this.state.errors;
        var sD = format(this.state.startDate, 'dd/MM/yyyy');
        var eD = format(this.state.endDate, 'dd/MM/yyyy');
        var today    =new Date();
        var todayDate = format(today, 'dd/MM/yyyy');
  
        if(today < this.state.startDate )
        {
          errors["startDate"] = "Date is greater than today's date"; 
          formIsValid =false;
        }
        else if(todayDate === sD)
        {
          errors["startDate"] = "Date must be less today's date";
          formIsValid =false;
        }     
        if(eD > todayDate)
        {
          errors["endDate"] = "Date is greater than today's date";
          formIsValid =false;
        }      
          return formIsValid;
       }
  
       clearError()
        {
          let errors = this.state.errors;
          errors["startDate"] = "";  
          errors["endDate"]="";        
          this.setState({errMsg : ""})
          this.setState({
            errors
        });
        }

    getItemInfo= (item) =>{
        if(item["Material"] === "1" || item["Billable"] === "1")
        {
          if(item["Material"] === "1")
          {
            return " Clean"
          }
          else if(item["Billable"] === "1")
          {
           return " Billable"
          }
        }
        else if(item["Billable"] === "2")
        {
          return " Non Billable Transfer"
        }
        else{
         return ""
        } 
       };    
      
       getBillablewithCheckBox= (item) =>{
           if (item["Billable"] === true) {
               return (<Form.Check  aria-label="option 1" disabled checked />)
           }           
       }

       getOvernightwithCheckBox= (item) =>{
        if (item["Overnight"] === true) {
            return (<Form.Check  aria-label="option 1" disabled checked />)
        }           
    }

    getCleanwithCheckBox= (item) =>{
        if (item["Material"] === true) {
            return (<Form.Check  aria-label="option 1" disabled checked />)
        }           
    }

    convertDateTimeFormat=(item) =>{
      var dt = item["Created"];
      var date = new Date(dt);
      return date.toLocaleString();
    }

    getLinkWithOrderPath= (item) =>{
      return (<NavLink
      to={{
        pathname:'/admin/viewOrderDetails',
        state:{
            Order_id:item["Order_Id"],
            task_For:"V",           
          }
      }}                                        
      activeClassName="active">{item["Order_Id"]}</NavLink>)      
    }

    handleRefreshPage=(e:any) => {
      window.location.reload(); 
    }

    render() {
        const { loading } = this.state;
        const alignCardCenter = {
            "margin": "0 0 0 0"
        };
        const { isListAvl } = this.state;    
        return (
            <div className="content">
                <Container fluid>
                    <Card border="light" style={{ height:'100%' }}>
                        <Card.Body>
                        <Card.Title><h4 className="title">Orders</h4></Card.Title>
                            <Form onSubmit= {this.onSubmit}>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                <Row style={alignCardCenter}>
                                    <Col sm={12} >
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4} style={{ paddingBottom: "17px" }}> </Col>
                                    <Col sm={8}> </Col>
                                </Row>

                                <Row style={alignCardCenter}>

                                    <Col sm={3} >
                                    <Form.Label>Select</Form.Label>
                                        <Form.Control as="select" id="searchBy" onChange={this.handleSelectChange}>
                                            <option value="Requestor" selected>Requestor Name</option>
                                            <option value="Status">Status</option>
                                            <option value="Order_id">Order ID</option>
                                            <option value="requestor_weight">Requestor Weight</option>
                                            
                                        </Form.Control>

                                    </Col>

                                    <Col sm={4}>
                                    <FormInputs
                                    properties={{id:"find",label:"Find",type:"text",name:"find",className:"form-control input-sm",maxlength:24,value:this.state.fields.find, onChange:this.handleTextChange}}
                                    errorMsg={this.state.errors.find}
                                    fieldRequired={false}/> 
                                    </Col>
                                    <Col sm={4}></Col>

                                </Row>
                                <Row>
                                    <Col sm={4} style={{ paddingBottom: "17px" }}> </Col>
                                    <Col sm={8}> </Col>
                                </Row>

                                <Row style={alignCardCenter}>
                                    <Col sm={3}>
                                        <Form.Label>Fields for Date Between</Form.Label>
                                    </Col>

                                    <Col sm={2.5}>
                                    <DatePicker 
                                        Id="startDate"
                                        placeholderText="Start Date"
                                        selected={this.state.startDate}
                                        onChange={this.handleChangeStartDate}
                                        isClearable
                                        showMonthDropdown
                                        showYearDropdown                            
                                        className="datePicker"/>
                                    </Col>
                                    <Col sm={0.5}>
                                        <Form.Label>and</Form.Label>
                                    </Col>
                                    <Col sm={3}>

                                    <DatePicker 
                                        Id="startDate"
                                        placeholderText="End Date"
                                        selected={this.state.endDate}
                                        onChange={this.handleChangeEndDate}
                                        isClearable
                                        showMonthDropdown
                                        showYearDropdown                            
                                        className="datePicker"/>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={4} style={{ paddingBottom: "17px" }}> </Col>
                                    <Col sm={8}> </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: "center" }}>
                                        <Button type="submit" disabled={loading} style={{ marginRight: "5px" }} >
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>wait...</span>}
                                            {!loading && <span>Search Orders</span>}
                                        </Button>
                                        <Button type="button" style={{marginLeft:'5px'}} onClick={this.handleRefreshPage}>Clear Search Results</Button>

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={12} style={{ paddingBottom: "15px"}}>
                                        <div className="scroll" style={{ width: '100%' }} >
                                        { isListAvl ? 
                                            <Table >
                                                <thead style={{ backgroundColor: 'lightblue', fontSize: 'large' }}>
                                                    <tr>
                                                    {new Variables().thOrderViewList.map((prop, key) => {
                                                            return <th key={key}>{prop}</th>;
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.displayGrid.map((prop, key) => {
                                                        return (
                                                            <tr key={key} >                                                                                                                          
                                                                <td>{this.getLinkWithOrderPath(prop)}</td>
                                                                <td>{prop["Requestor"]}</td>
                                                                <td>
                                                                    {this.getBillablewithCheckBox(prop)}
                                                                </td>
                                                                <td>
                                                                    {this.getOvernightwithCheckBox(prop)}
                                                                </td>
                                                                <td>
                                                                    {this.getCleanwithCheckBox(prop)}
                                                                </td>
                                                                <td>{prop["Status"]}</td>
                                                                <td>{prop["Requestor_Weight"]}</td>
                                                                <td>{prop["Label_Id"]}</td>
                                                                <td>{prop["Super_Label_Id"]}</td>
                                                                <td>{prop["Ship_Method"]}</td>
                                                                <td>{this.convertDateTimeFormat(prop)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>:<Table>
                                                <thead>
                                                    <tr>
                                                    {new Variables().thOrderViewList.map((prop, key) => {
                                                            return <th key={key}>{prop}</th>;
                                                        })}
                                                    </tr>
                                                </thead> 
                                                <tbody>
                                                    <tr>
                                                    <td colSpan={11} align="center" className="text-danger font-size-xs small">{this.state.isListAvlMsg}</td>
                                                    </tr>
                                                </tbody>
                                                </Table>} 
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>                                  
                    </Card>
                </Container>
            </div>
        )
    }
}

