import React, { Component, CSSProperties} from "react";
import {Container, Navbar, Nav, NavItem, Card, Row, Col,Table,Form,Alert,Button} from "react-bootstrap";
import {FormControl,Modal} from "../../../../node_modules/react-bootstrap";
import { NavLink,Link } from "react-router-dom";
import {FormInputs} from '../../CustomComponents/FormInput'
import {History, LocationState} from "History";
import{UserViewController} from "../../../Controllers/UserViewController"
import { UserViewParameter, Istate } from "../../../Model/UserView"; 
import '../../../assets/css/Style.css'
import { Variables } from '../../../Variables/Variables';

export class UserView extends Component<UserViewParameter,Istate> {
    constructor(props:UserViewParameter){
        super(props)       
        this.state={
          fields: {find:'',searchType:'' },
          errors: {find:'',searchType:'' },
          selectfields: {searchBy:'0'},
          errMsg:"",
          loginFailed: 0,
          UserList:[],
          userListbyProcessName:[],
          loading: false,
          isUserAvl:false,
          isAprProcessUserAvl:false,
          modalShow: false,
          isUserAvlMsg:"List is Empty...Please search for the needs",
          isAprProcessUserAvlMsg:"",     
          vuid:"",
          processName:""
        }
        this.handleChange = this.handleChange.bind(this);  
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this); 
        this.setActiveTab=this.setActiveTab.bind(this); 
        this.handleModalClose = this.handleModalClose.bind(this);  
        this.handleRefreshPage = this.handleRefreshPage.bind(this);
    }

    handleChange (e:any) {
        let selectfields = this.state.selectfields;
        selectfields.searchBy= e.currentTarget.value;
        this.setState({
          selectfields
        })
      }

      handleTextChange (e:any) {
        let fields = this.state.fields;
        fields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({
            fields
        })
      }

      handleModalClose = (e : any) => {
        this.setState({ modalShow: false })
    }

    handleRefreshPage=(e:any) => {
      window.location.reload(); 
    }

      setActiveTab = (e) => {
        e.preventDefault();
        //console.log(e.target);  

        this.setState({processName:e.target.text})          
        let userviewController = new UserViewController();        
        userviewController.getUserListByProcessName(e.target.text)
        .then(jsonUserList =>{
          this.setState({                           
            userListbyProcessName : JSON.parse(JSON.stringify(jsonUserList))                    
        });
        //console.log(this.state.userListbyProcessName)   
        //console.log(this.state.userListbyProcessName.length)   
        if(this.state.userListbyProcessName.length > 0)
        {            
          this.setState({isAprProcessUserAvl:true})
          this.setState({modalShow:true})
          this.setState({isUserAvlMsg:""})
          this.setState({isAprProcessUserAvlMsg:""})
        }
        else
        {          
          this.setState({modalShow:true})
          this.setState({isAprProcessUserAvl:false})
          this.setState({isAprProcessUserAvlMsg:"No approvers available for this process."})
        }
        }).catch(error => {
          console.log(error);
        })
      }
                 

      onSubmit =(e:any)=>{
        
        e.preventDefault();
        if (this.ValidateProfile()) {
          let errors = this.state.errors;
          this.setState({ errors });
          errors["find"] = "";         
          this.setState({ errors });
          this.setState({ loading: true });
          e.preventDefault();
          
          let userviewController = new UserViewController();
          userviewController.getUserList(this.state.fields.find,this.state.selectfields.searchBy)
          .then(jsonUserList =>{
            this.setState({                           
              UserList : JSON.parse(JSON.stringify(jsonUserList)), loading: false
          });
          //console.log(this.state.UserList)   
          //console.log(this.state.UserList.length)   
          if(this.state.UserList.length > 0)
          {            
            this.setState({isUserAvl:true})
            this.setState({isUserAvlMsg:""})
            this.setState({ loading: false });
          }
          else
          {           
            this.setState({isUserAvl:false})
            this.setState({isUserAvlMsg:"No record(s) found"})
            this.setState({ loading: false });
          }
          }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
          })
        }
        else
        {

        }           
      }   

      ValidateProfile(){
        this.clearError();
        let formIsValid = true;      
        let fields = this.state.fields;   
        let select =this.state.selectfields.searchBy;  
        let errors = this.state.errors;
        if (!fields["find"]) {
          formIsValid = false;
          errors["find"] = "Please enter values to find";                
        }
        if(select === "0"){
          formIsValid = false;
          errors["searchType"] = "Please select a value";
        }
        return formIsValid;
      }

      clearError()
      {
        let errors = this.state.errors;
        errors["find"] = "";  
        errors["searchType"]="";
        this.setState({errMsg : ""})
        this.setState({
          errors
      });
      }


    render(){
        const { loading } = this.state;
        const { isUserAvl } = this.state;
        const { isAprProcessUserAvl } = this.state;
          const gridBoxStyle:CSSProperties = {
            fontSize: "9px",
            width:"10%"           
          };     
          const popUpHeaderstyle:CSSProperties = {
            fontSize: "9px",  
            fontWeight: 'bold' as 'bold',                   
            width:"9%",  
            borderBottom: "1px solid rgb(212, 212, 212)" 
          };
        return (          
           <div className="content"> 
              <Modal show={this.state.modalShow} 
                    onHide={this.handleModalClose} 
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="modal-lg" 
                    >
                    <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 'medium'}}>Approvers for Process {this.state.processName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid" >
                        <Container fluid>
                            <Row>
                                <Col md={12}>
                                  <div style={{width:'100%'}}>
                                  <Table>
                                    <tr>
                                    <thead style={{backgroundColor:'lightblue', fontSize: 'large'}}>
                                      <th style={popUpHeaderstyle}>Name</th>                                                
                                      <th style={popUpHeaderstyle}>Alias</th>
                                      <th style={popUpHeaderstyle}>User Type</th>                                                 
                                      <th style={popUpHeaderstyle}>Password</th> 
                                    </thead>                               
                                    </tr>                                    
                                </Table> 
                                  </div>
                                <div className="scroll"  style={{width:'100%'}}>                                                               
                                  {isAprProcessUserAvl ?
                                  <Table>                           
                                      {this.state.userListbyProcessName.map((prop, key) => { 
                                          return (
                                            <tr key={key}>
                                              <td style={gridBoxStyle}>{prop["name"]}</td>                                                
                                              <td style={gridBoxStyle}>{prop["alias"]}</td>
                                              <td style={gridBoxStyle}>{prop["userType"]}</td>                                                 
                                              <td style={gridBoxStyle}>{prop["password"]}</td>                                
                                            </tr>
                                        )            
                                          })} 

                                    </Table>:
                                    <Table>
                                      <tr>
                                        <td>{this.state.isAprProcessUserAvlMsg}</td>
                                      </tr>
                                   </Table> }                                           
                                </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>          
            <Container fluid>
            <Row>
            <Col md={12}>
              <Card border="light" style={{ height:'100%' }}>
                  <Card.Body>
                  <Card.Title><h4 className="title">User View</h4></Card.Title>
                    <Form onSubmit= {this.onSubmit}>
                      <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                      <Row>              
                        <Col sm={3}> 
                        <FormInputs
                            properties={{id:"find",label:"Find",type:"text",name:"find",className:"form-control input-sm",maxlength:24,value:this.state.fields.find, onChange:this.handleTextChange}}
                            errorMsg={this.state.errors.find}
                            fieldRequired={true}
                            />             
                        </Col>
                        <Col sm={3}>
                          <Form.Group>
                              <Form.Label>In</Form.Label>
                              <Form.Control as="select" id="searchBy" onChange={this.handleChange}>
                                  <option value="0" selected>--Select--</option>
                                  <option value="1">Name</option> 
                                  <option value="2">Alias</option> 
                                  <option value="3">User Type</option>
                                  <option value="4">Process Name</option>
                                  <option value="5">Label</option> 
                                  <option value="6">Department</option>   
                              </Form.Control>  
                              <Form.Text style={{margin:"0 0 0 0"}}>
                                <p className="text-danger" style={{fontSize: "100%"}}>&nbsp;{this.state.errors.searchType}</p>
                              </Form.Text>                                
                          </Form.Group>
                        </Col>  
                        <Col sm={6}>
                          <div className="form-group mt-4 mb-0 text-left">
                            <Button type="submit" disabled={loading}>
                            {loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color:"#3472F7" }}
                              />
                            )}
                            {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                            {!loading && <span>Search User</span>}
                          </Button>                   
                            <Button type="button" style={{marginLeft:'5px'}} onClick={this.handleRefreshPage}>Clear Results</Button>
                          </div>
                        </Col>               
                    </Row>
                    <Row>
                          <Col md={12}>     
                              <Row>
                                <Col sm={12}>
                                    <div id="navbar">
                                        <Navbar expand="lg" >
                                            <Navbar.Brand className="align-center">Search User</Navbar.Brand>
                                            <Nav className="ml-auto">
                                            </Nav>
                                        </Navbar>
                                    </div>
                                </Col>
                              </Row>             
                              <Row>
                              <Col sm={12}>
                              <div className="scroll"  style={{width:'100%'}}>
                              { isUserAvl ? 
                                <Table hover>
                                  <thead>
                                    <tr>
                                      {new Variables().thUserList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead>                    
                                  <tbody>                           
                                    {this.state.UserList.map((prop, key) => { 
                                        return (
                                          <tr key={key}>
                                            <td>{prop["name"]}</td>
                                            <td>
                                            <NavLink
                                                to={{
                                                  pathname:'/admin/EditUser',
                                                  state:{
                                                    vuid:prop["alias"],
                                                    orgin:"V"
                                                  }
                                                }}                                        
                                                activeClassName="active"
                                              >{prop["alias"]}</NavLink></td>
                                            <td>{prop["userType"]}</td>
                                            <td><a href="" onClick={this.setActiveTab}>{prop["processName"]}</a></td>  
                                            <td>{prop["label"]}</td>                                 
                                            <td>{prop["department"]}</td>
                                            <td>{prop["businessUnit"]}</td>                                   
                                            <td>{prop["customerNumber"]}</td>  
                                            <td>{prop["executiveUser"]}</td> 
                                            <td>{prop["superUser"]}</td>                                 
                                          </tr>
                                        )            
                                      })} 
                                   </tbody>
                                </Table>:
                                <Table>
                                  <thead>
                                    <tr>
                                      {new Variables().thUserList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead> 
                                  <tbody>
                                    <tr>
                                      <td colSpan={11} align="center" className="text-danger font-size-xs small">{this.state.isUserAvlMsg}</td>
                                    </tr>
                                  </tbody>
                                </Table>} 
                              </div>                    
                            
                            </Col>
                            </Row>
                        </Col>
                      </Row>
                    
                    </Form>
                    </Card.Body>                                  
                </Card>
              </Col>
            </Row>
            </Container>           
          </div>
        );
    }
}
