import React, { Component } from "react";
import {History, LocationState} from "History";
import {Container, Card, Row, Col, Form, Button, Table, Alert} from "react-bootstrap";
import '../../../assets/css/Style.css'
import {FormInputs} from '../../CustomComponents/FormInput';
import {FormSelect} from '../../CustomComponents/FormSelect';
import {CommonController} from '../../../Controllers/CommonController';
import {SysopController} from '../../../Controllers/SysopController'
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class DepartmentMaintenance extends Component<DepartmentParameter,Istate> {
    constructor(props:DepartmentParameter){
        super(props);
        this.state = {
            fields: {department:'', customerNumber:'', businessUnit:''},
            errors: {recordLabelId:'', department:'', customerNumber:'', businessUnit:''},
            selectfields: {recordLabelId:'', recordLabelName:''},
            recordLabel: [],
            department: [],
            loading: false,
            selectLabel: false,
            errMsg:'',
            edit:false,
            modal: {modalSpinnerShow: false}
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let selectfields = this.state.selectfields;
        selectfields["recordLabelName"]="Select Your Operating Company";
        this.setState({selectfields});
        let commonController = new CommonController();
        commonController.getAllLabel().then(jsonLabel => {
            this.setState({                           
                recordLabel : JSON.parse(JSON.stringify(jsonLabel))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading operating company...", error)
        });
    }

    handleSelectChange = (e:any) => {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'recordLabelId'){
            this.setState({selectLabel:true});
            selectfields["recordLabelName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            let commonController = new CommonController();
            commonController.getAllDeptForLabel(this.state.selectfields.recordLabelId).then(jsonDept => {
                this.setState({                           
                    department : JSON.parse(JSON.stringify(jsonDept))
                });
            }).catch(error => {
                console.error("There was an error loading department")
            });
        }
        if(e.currentTarget.value === "Select Your Operating Company"){
            this.setState({selectLabel:false});
        }
        this.setState({
          selectfields
        });
    }

    handleChange = (e:any) => {
        let fields = this.state.fields;
        var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/) //
        console.log(e.currentTarget.name,"e checkkkk")
        if(e.currentTarget.name === 'department'){
        if(regexp.test(e.currentTarget.value)){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }}
        else{
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({
          fields 
        });
    }

    clearAll(){
        this.clearError();
        let fields = this.state.fields;
        fields["department"] = "";
        fields["customerNumber"] = "";
        fields["businessUnit"] = "";
        this.setState({
          fields, edit:false 
        });
    }

    clearError(){
        let errors = this.state.errors;
        errors["recordLabelId"] = "";
        errors["department"] = "";
        errors["customerNumber"] = "";
        errors["businessUnit"] = "";
        this.setState({
            errors, errMsg: ""
        });
    }

    insertValues(dept,customerNumber,businessUnit){
        this.clearError();
        this.setState({edit:true});
        let fields = this.state.fields;
        fields["department"] = dept;
        fields["customerNumber"] = customerNumber;
        fields["businessUnit"] = businessUnit;
        this.setState({
          fields 
        });
    }

    ValidateProfile(){
        this.clearError();
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let formIsValid = true;
        if (!fields["department"]) {
            formIsValid = false;
            errors["department"] = "Please enter department name.";
        }
        if (!fields["customerNumber"]) {
            formIsValid = false;
            errors["customerNumber"] = "Please enter customer number.";
        }
        if (!fields["businessUnit"]) {
            formIsValid = false;
            errors["businessUnit"] = "Please enter business unit.";
        }
        if(selectfields["recordLabelName"] === "Select Your Operating Company"){
            formIsValid = false;
            errors["recordLabelId"] = "Please select label from dropdown.";
          }
        if(!formIsValid)
        {
            this.setState({ loading: false });
        }
        this.setState({
            errors
        });
        return formIsValid;
    }

    checkDuplicate = () => {
        var duplicate = false;
        this.state.department.map((prop : any, key) => {
            if(prop["name"].toUpperCase() === this.state.fields.department.toUpperCase()){
                duplicate = true;
            }
        })
        return duplicate;
    }

    onSubmit = (e:any) => {
        this.setState({ loading: true });
        e.preventDefault();
        if (this.ValidateProfile()) {
            if(this.checkDuplicate()){
                alert("Department name already exists...")
                this.setState({ loading: false });
            }
            else{
                let sysopController = new SysopController();
                if(this.state.edit){
                    sysopController.updateDept(this.state.selectfields.recordLabelId,this.state.fields.department,this.state.fields.customerNumber,this.state.fields.businessUnit)
                    .then(response => {
                        if(response.success){
                            alert("Department updated successfully...")
                            let commonController = new CommonController();
                            commonController.getAllDeptForLabel(this.state.selectfields.recordLabelId).then(jsonDept => {
                                this.setState({                           
                                    department : JSON.parse(JSON.stringify(jsonDept))
                                });
                            }).catch(error => {
                                console.error("There was an error loading department")
                            }); 
                        }
                        else{
                            this.setState({errMsg: "Error in update department..."})
                        }
                        this.setState({ loading: false });
                    }).catch(error => {
                        console.error("Error in update dept...," +error);
                    })
                    this.setState({edit:false});
                    this.clearAll();
                }
                else{
                    sysopController.insertDept(this.state.selectfields.recordLabelId,this.state.fields.department,this.state.fields.customerNumber,this.state.fields.businessUnit)
                    .then(response => {
                        if(response.success){
                            alert("Department created successfully...")
                            let commonController = new CommonController();
                            commonController.getAllDeptForLabel(this.state.selectfields.recordLabelId).then(jsonDept => {
                                this.setState({                           
                                    department : JSON.parse(JSON.stringify(jsonDept))
                                });
                            }).catch(error => {
                                console.error("There was an error loading department")
                            }); 
                        }
                        else{
                            this.setState({errMsg: "Error in adding department..."})
                        }
                        this.setState({ loading: false });
                    }).catch(error => {
                        console.error("Error in insert dept...," +error);
                    })
                    this.clearAll();
                }
            }   
        }
    }

    render(){
        const{ loading } = this.state;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow } />
              <Container fluid>
                <Row className="small">
                  <Col sm={12}>
                  <Card border="light" style={{ height:'100%' }}>
                    <Card.Body>
                        <Card.Title><h4 className="title">Department Maintenance</h4></Card.Title>
                        <Form onSubmit= {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                            <Row>
                                <Col sm={4}>
                                    <FormSelect
                                        properties={{label:"Label",id:"recordLabelId",name:"recordLabelId",disabled: this.state.edit ? true : false,value:this.state.selectfields.recordLabelId,className:"form-control",onChange:this.handleSelectChange}}
                                        fieldRequired={true}
                                        errorMsg={this.state.errors.recordLabelId}
                                        defaultOption="Select Your Operating Company"
                                        fillOption={this.state.recordLabel}
                                    />
                                </Col>
                                <Col sm={8}>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"department",label:"Department",type:"text",name:"department",className:"form-control input-sm",maxlength:24, disabled:this.state.edit ? true : false,  value:this.state.fields.department,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.department}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"customerNumber",label:"Customer Number",type:"text",name:"customerNumber",className:"form-control input-sm",maxlength:24,value:this.state.fields.customerNumber,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.customerNumber}
                                        fieldRequired={true}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <FormInputs
                                        properties={{id:"businessUnit",label:"Business Unit",type:"text",name:"businessUnit",className:"form-control input-sm",maxlength:24,value:this.state.fields.businessUnit,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.businessUnit}
                                        fieldRequired={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} style={{textAlign: "center" , alignSelf: "center"}}>
                                    <Button type="submit" disabled={loading}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color:"#3472F7" }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#3472F7"}}>wait...</span>}
                                        {!loading && <span>Save</span>}
                                    </Button>
                                    <Button type="button" style={{ marginLeft: "5px" }} onClick={() => {this.clearAll()}}>Clear</Button>
                                </Col>
                            </Row>
                            <div className="clearfix" />
                        </Form>
                        </Card.Body>                                  
                    </Card>
                  </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ height:'100%' }}>
                        <Card.Body>
                        <Card.Title><h4 className="title">Department Details</h4></Card.Title>
                                <Row>
                                    <Col sm={12}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Label</th>
                                                    <th>Department</th>
                                                    <th>Customer Number</th>
                                                    <th>Business Unit</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                                {this.state.selectLabel ? 
                                                <tbody>
                                                    {this.state.department.map((prop, key) => {
                                                        return(
                                                            <tr key={key}>
                                                                <td>{prop["label"]}</td>
                                                                <td>{prop["name"]}</td>
                                                                <td>{prop["customerNumber"]}</td>
                                                                <td>{prop["businessUnit"]}</td>
                                                                <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={() => {this.insertValues(prop["name"],prop["customerNumber"],prop["businessUnit"])}}>Edit</Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={5} align="center" className="text-danger font-size-xs small">List is Empty...Please select Label</td>
                                                    </tr>
                                                </tbody>
                                                }
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
              </Container>
            </div>
        );
    }
}

interface DepartmentParameter{
    uid?:string;
    pwd?:string;
    history?:History<LocationState>;
}
    
interface Istate{
    fields:any;
    errors:any;
    selectfields:any;
    recordLabel:[];
    department:[];
    selectLabel:boolean;
    loading:boolean;
    errMsg:string;
    edit:boolean;
    modal:any;
}