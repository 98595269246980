import React, { Component ,useState, useEffect, useRef} from "react";
import {Row,Col,Container, Card,Button, NavLink, Form, Alert} from "../../../../node_modules/react-bootstrap";
/*import {LoginNavBar} from '../../CustomComponents/NabvBar/LoginNavBar'*/
import {FormInputs} from '../../CustomComponents/FormInput'
import {History, LocationState} from "History";
import {PasswordResetController} from "../../../Controllers/PasswordResetController"
import {LogoutController} from "../../../Controllers/LogoutController";
import { ResetPasswordParameter, Istate } from "../../../Model/PasswordReset"; 
import '../../../assets/css/Style.css'
import {alert} from 'react-bootstrap-confirmation';

export class ResetPassword extends Component<ResetPasswordParameter,Istate> {
    oPwdRef:any;
    nPwdRef:any;
    cPwdRef:any;
    redBorder:string;
    defaultBorder:string;
    constructor(props:ResetPasswordParameter){
      super(props)
      //Change Border color Red, if alert triggered in onBlur Event
      this.redBorder = "1px solid #DC3545";
      this.defaultBorder = "1px solid #E3E3E3";
      this.state={
        fields: { opwd:'',npwd:'',cpwd:''},
        errors: { opwd:'',npwd:'',cpwd:'',fRef:'' },
        errMsg:"",
        loginFailed: 0,
        loading: false,
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleBlur = this.handleBlur.bind(this);  
      this.oPwdRef = React.createRef();   
      this.nPwdRef = React.createRef();
      this.cPwdRef = React.createRef();
    }

    componentDidMount(){
        //this.oPwdRef.current.focus();
    }

    handleChange (e:any) {
      
        let fields = this.state.fields;
        fields[e.currentTarget.name] = e.currentTarget.value.trim();
        this.setState({
            fields
        })
      }

      handleBlur (e:any) {    
         
        const target = e.currentTarget;
        target.style.border=this.defaultBorder;
        if(e.currentTarget.name === "opwd"){
          if(e.currentTarget.value != sessionStorage.getItem("Password")){
            alert("Old password Incorrect")            
            target.style.border=this.redBorder;
          }
        }
        if(e.currentTarget.name === "npwd"){
          var regexSpl = /[!`@#$%^&+\=*?_~]/g;
          var regexUpper = /[A-Z]/g;
          var regexLower = /[a-z]/g;
          var regexNum = /[0-9]/g;
          if((e.currentTarget.value).length < 8){
            alert("Password should contain atleast 8 character")            
            target.style.border=this.redBorder;
          }
          else if(!regexUpper.test(e.currentTarget.value)){
            alert("Password should contain atleast one upper case")            
            target.style.border=this.redBorder;
          }
          else if(!regexLower.test(e.currentTarget.value)){
            alert("Password should contain atleast one lower case")            
            target.style.border=this.redBorder;
          }
          else if(!regexNum.test(e.currentTarget.value)){
            alert("Password should contain atleast one number")            
            target.style.border=this.redBorder;
          }
          else if(!regexSpl.test(e.currentTarget.value)){
            alert("Password should contain atleast one special character")           
            target.style.border=this.redBorder;
          } 
         else if(e.currentTarget.value === sessionStorage.getItem("Password"))
          {
            alert("Old and New Password are need to be Different");
            target.style.border=this.redBorder;
          }          
        } 
        if(e.currentTarget.name === "cpwd"){
          if(e.currentTarget.value != this.state.fields.npwd){
            alert("Confirm Password Not matched with New Password")  
            target.style.border=this.redBorder;
          }
         }
      }

      onSubmit = (e:any) => {
        
        let errors = this.state.errors;
        errors["opwd"] = "";
        errors["npwd"] = "";
        errors["cpwd"] = "";
        errors["fRef"] = "";
        this.setState({ errors });
        this.setState({ loading: true });
        e.preventDefault();
        if (this.validateForm()) {
          //if(this.state.fields.opwd === sessionStorage.getItem("Password"))
          //{
            let passwordResetController = new PasswordResetController();
            passwordResetController.checkUserPreviousPassword(sessionStorage.getItem("uid")!,this.state.fields.npwd)
            .then((response : any) => {
              if (response.success){
              alert("Password has been changed successfully")
              this.props.history.push('/login');     
              }
              else {
                this.setState({
                  errMsg : response.msg,                 
                  loading: false
                });
              }
            }).catch(error => {
              console.log(error);
            })
          //}
          //else
          //{
          //  this.setState({errMsg : "Old Password Incorrect"})
          //  this.setState({ loading: false });
          //}          
        }
        /*else
        {
          if (errors["fRef"] !="")
          {
            if(errors["fRef"] == "npwd")
            {
              this.nPwdRef.current.focus();
            }
            else if(errors["fRef"] == "cpwd")
            {
              this.cPwdRef.current.focus();
            }
          }
        } */
      }

    clearError()
    {
      let errors = this.state.errors;
      errors["opwd"] = "";
      errors["npwd"] = "";
      errors["cpwd"] = "";      
      errors["state"] = "";
      this.setState({errMsg : ""})
      this.setState({
        errors
    });
    }

      validateForm(){
        this.clearError();
        
        let fields = this.state.fields;       
        let errors = this.state.errors;
        let formIsValid = true;
        if (!fields["opwd"]) {
          formIsValid = false;
          errors["opwd"] = "Please Enter Old Password";                
        }
        if (!fields["npwd"]) {
            formIsValid = false;
            errors["npwd"] = "Please Enter New Password";            
          }
          if (!fields["cpwd"]) {
            formIsValid = false;
            errors["cpwd"] = "Please Enter Confirm Password";            
          }
          if (formIsValid && fields["opwd"] !== "") 
          {
            if(fields["opwd"] != sessionStorage.getItem("Password")){
              alert("Old password Incorrect")
              formIsValid = false;            
            }
          }
          if(formIsValid && fields["npwd"] !== ""){
            var regexSpl = /[!`@#$%^&+\=*?_~]/g;
            var regexUpper = /[A-Z]/g;
            var regexLower = /[a-z]/g;
            var regexNum = /[0-9]/g;
            if((fields["npwd"]).length < 8){
              //errors["npwd"] = "Password should contain atleast 8 character"  
              alert("Password should contain atleast 8 character");
              formIsValid = false; 
              //errors["fRef"] = "npwd"         
           }
            else if(!regexUpper.test(fields["npwd"])){
              //errors["npwd"] = "Password should contain atleast one upper case" 
              alert("Password should contain atleast one upper case");
              formIsValid = false; 
              //errors["fRef"] = "npwd"            
            }
            else if(!regexLower.test(fields["npwd"])){
              //errors["npwd"] = "Password should contain atleast one lower case"
              alert("Password should contain atleast one lower case");
              formIsValid = false;
              //errors["fRef"] = "npwd"             
            }
            else if(!regexNum.test(fields["npwd"])){
             //errors["npwd"] = "Password should contain atleast one number" 
              alert("Password should contain atleast one number");
              formIsValid = false;
              //errors["fRef"] = "npwd"           
            }
            else if(!regexSpl.test(fields["npwd"])){
              //errors["npwd"] = "Password should contain atleast one special character" 
              alert("Password should contain atleast one special character");
              formIsValid = false;
              //errors["fRef"] = "npwd"            
            }           
          }
          if(formIsValid && fields["npwd"] !== fields["cpwd"])
          {
            alert("Confirm Password Not matched with New Password");
            formIsValid = false;
            //errors["cpwd"] = "Confirm Password Not matched with New Password";  
            //errors["fRef"] = "cpwd"  
          }
          if(formIsValid && fields["npwd"] === sessionStorage.getItem("Password"))
          {
            alert("Old and New Password are need to be Different");
            formIsValid = false;
            /*errors["cpwd"] = "Old and New Password are need to be Different"; */
            //this.setState({errMsg : "Old and New Password are need to be Different"})
            //errors["fRef"] = "npwd"  
          }
                      
        if(!formIsValid)
        {
          this.setState({ loading: false });
        }
        this.setState({
          errors
        });
        return formIsValid;
      }

      ChkPreviousPassword()
      {
        let formIsValid = true;
        let fields = this.state.fields;       
        let errors = this.state.errors;
        let passwordResetController = new PasswordResetController();
        return passwordResetController.checkUserPreviousPassword(sessionStorage.getItem("uid")!,this.state.fields.npwd)
        .then((response : any) => {
          const parsedResponse = JSON.parse(response);
          if (response.success){
            formIsValid = true;
            return formIsValid;
          }
          else {
            this.setState({ loading: false });
            this.setState({errMsg : "Password should not be similar to the last 13 passwords"})
            formIsValid = false;
            return formIsValid;
          }
        }).catch(error => {
          console.log(error);
        })        
      }

      DisplayChangePasswordButton =()=> {
        const {loading} = this.state;        
            return      
      }

render(){
    const { loading } = this.state;
    return (            
              <div className="content">                  
                <Container fluid>
                  <Row className="small">
                    <Col md={12}>
                      <Card border="light" style={{ width: '100%', height:'100%' }}>
                        <Card.Body>
                        <Card.Title><h4 className="title">Change Password</h4></Card.Title>
                          <Form onSubmit = {this.onSubmit}>
                            <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                          <Row>               
                              <Col sm={6}>
                              <FormInputs
                                  properties={{id:"opwd",label:"Old Password",type:"password",name:"opwd",className:"form-control input-sm",maxlength:24,value:this.state.fields.opwd,onChange:this.handleChange, onBlur:this.handleBlur}}
                                  errorMsg={this.state.errors.opwd}
                                  fieldRequired={true}
                                  />
                              </Col>
                              <Col sm={6}></Col>  
                          </Row>
                          <Row>               
                              <Col sm={6}>
                              <FormInputs
                                  properties={{id:"npwd",label:"New Password",type:"password",name:"npwd",className:"form-control input-sm",maxlength:15,value:this.state.fields.npwd,onChange:this.handleChange, onBlur:this.handleBlur}}
                                  errorMsg={this.state.errors.npwd}
                                  fieldRequired={true}                                              
                                  />
                              </Col>
                              <Col sm={6} className="align-self-center"><div className="text-danger font-size-xs small">Password Policy:  <br />Minimum of 8 character and max of 15. <br />At least one upper case, one lower case and one number. <br />At least one special character (~, `, !, @, #, $, %, ^, &, *, (, ), +, =, \).</div></Col>  
                          </Row>
                          <Row>               
                              <Col sm={6}>
                              <FormInputs
                                  properties={{id:"cpwd",label:"Confirm Password",type:"password",name:"cpwd",className:"form-control input-sm",maxlength:24,value:this.state.fields.cpwd,onChange:this.handleChange, onBlur:this.handleBlur}}
                                  errorMsg={this.state.errors.cpwd}
                                  fieldRequired={true}
                                  
                                  />
                              </Col>
                              <Col sm={6}></Col>  
                          </Row>
                          <Row>               
                              <Col sm={6}>
                                <div className="form-group mt-4 mb-0 text-center">
                                  <Button type="submit" disabled={loading} style={{ marginRight: "5px" }}>
                                      {loading && (
                                      <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                      />
                                      )}
                                      {loading && <span>wait...</span>}
                                      {!loading && <span>Change Password</span>}
                                  </Button> 
                                  <Button type="button" style={{ marginRight: "5px" }} onClick={() => {this.props.history.push('/admin/home')}}>Cancel</Button>
                                        
                                </div>
                              </Col> 
                              <Col sm={6}></Col>
                          </Row>
                          </Form>
                      </Card.Body>                                  
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
    );
}
}