//import {FieldGroup}  from './FieldGroup'
import {FormControl,FormLabel,FormGroup,InputGroup,Button} from "../../../node_modules/react-bootstrap";
import React,{ Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export class FormInputSearch extends Component<IFormInputSearch> {
    render() { 
      const {errorMsg,fieldRequired,loading} = this.props;
      const {id,label} = this.props.properties;

      let GetMandatoryGroupField=(fldReqd)=>{
        if(fldReqd===true) 
        {return (<span className="text-danger">*</span>)}
        else
         return (<span></span>);
      }
      
      const DisplayErrorMessageGroupField=(errMsg)=>{
        if(errMsg!=='') 
        {return <div className="text-danger font-size-xs small font-weight-bold">{errMsg}</div>}
        else
        return (<></>);
      }

      return(<div id={"div"+id} >
        <FormGroup style={{paddingRight:'15px'}}>
          <FormLabel>{label}</FormLabel>
          {GetMandatoryGroupField(fieldRequired)}
          <InputGroup>
            <FormControl {...this.props.properties}/>
            <InputGroup.Append>
              <Button disabled={loading} style={{float:"left",height:'40px',border: '2px solid',backgroundColor:'transparent',borderColor: '#1D62F0',color:'#1D62F0',cursor:'pointer'}} {...this.props.buttonProperties}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ color:"#3472F7" }}
                        />)}
                        {!loading && <span><FontAwesomeIcon icon={faSearch} size="1x"/></span>}
                </Button>   
            </InputGroup.Append>
          </InputGroup>
          {DisplayErrorMessageGroupField(errorMsg)}
        </FormGroup>
        </div>)
      }
    }

  interface IFormInputSearch
  {
      properties:IProperties;
      buttonProperties:IButtonProperties;
      errorMsg?:string;
      fieldRequired:boolean;
      disbled?:boolean;
      loading?:boolean
  }
  interface IProperties
  {
      id:string;
      label:string;
      type:string;
      name:string;
      value?:string;
      className?:string;
      maxlength?:number;
      placeholder?:string;
      onChange?:any;
      disabled?:boolean;
      onBlur?:any;
  }
  interface IButtonProperties
  {
      id:string;
      name:string;
      value?:string;
      className?:string;
      disabled?:boolean;
      onClick?:any;
  }
  interface Istate
  {

  }