import React, { Component }  from "react";
import { History, LocationState } from "History";
import {
  FormLabel,
  Container,
  Row,
  Col,
  Form,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Button,
  Card,
  CardGroup,
  FormControl,
  Modal,
  Table,
} from "../../../../node_modules/react-bootstrap";
import { FormInputs } from "../../CustomComponents/FormInput";
import { FormInputSearch } from "../../CustomComponents/FormInputSearch";
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormSelectSearch } from "../../CustomComponents/FormSelectSearch";
import { FormLabelField } from "../../CustomComponents/FormLabelField";
import { CommonController } from "../../../Controllers/CommonController";
import { Variables } from "../../../Variables/Variables";
import "../../../assets/css/Style.css";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { Redirect } from "react-router-dom";
import { CreateOrderParameter, Istate } from "../../../Model/CreateOrder";
import { CreateOrderController } from "../../../Controllers/CreateOrderController";
import { EditUserController } from "../../../Controllers/EditUserController";
import { FormModal } from "../../CustomComponents/FormModal";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";
import * as BillableScript from "../../../js/BillableScript.js";
import * as TableMultiSelect from "../../../js/TableMultiSelect.js";
import * as TableAllSelect from "../../../js/TableAllSelect.js";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
//import { NetworkInfo } from "../../../../node_modules/react-native-network-info";
import publicIP from "react-native-public-ip";
import { OrderController } from "../../../Controllers/OrderController";
import { truncateSync } from "fs";
import flag from "../../../assets/img/redflag.gif";
import { create } from "domain";
import { alert } from "react-bootstrap-confirmation";
import * as GlobalVar from "../../../js/GlobalVar.js";
import { read, utils, writeFile } from 'xlsx';
import { QuickProductSearch } from "../QuickProductSearch/QuickProductSearch";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";


export class CreateOrder extends Component<CreateOrderParameter, Istate> {
  redBorder: string;
  defaultBorder: string;

  constructor(props: CreateOrderParameter) {
    super(props);
    const userLogin = sessionStorage.getItem("userLogin");
    let loggedIn = true;
    if (userLogin == null) {
      loggedIn = false;
    }
    //Change Border color Red, if alert triggered in onBlur Event
    this.redBorder = "1px solid #DC3545";
    this.defaultBorder = "1px solid #E3E3E3";

    this.state = {
      fields: {
        uid: sessionStorage.getItem("uid"),
        pwd: "",
        labelId: sessionStorage.getItem("label_id"),
        lblSearchContact: "Name",
        businessunit: "",
        orderType: "",
        shipName: "",
        shipAddress: "",
        shipPhone: "",
        searchContact: "",
        specialIns: "",
        artist: [],
        title: [],
        selection: [],
        UPC: [],
        config: [],
        unitsPerSet: [],
        rlsDate: [],
        qty: [],
        reason: "",
        multipleRowSelect: [],
        moratorium: "",
        productButton: [],
        Process_Name: "",
        ipAddress: "",
        noProductMatch: "",
        maxKey: 0,
        labelName: "",
        isOvernight: false,
        accountNo: "",
        bill_to_name: "",
        bill_to_address: "",
        bill_to_city_state_zip: "",
        price: [],
        cost: [],
        discount: [],
        approvalReqd: [],
        flag: [],
        cleanBI: false,
        freightBillingInfo: "",
        action: "",
        artistStoreName: [],
        tourDate: [],
        prodCount: 0,
        upcList: "",
        fileUpload: false,
        upcLen : 0,
      },
      optionfields: {
        name: true,
        city: false,
        company: false,
        state: false,
        clean: false,
        marked: true,
        private: true,
        public: false,
        locatedYes: true,
        locatedNo: false,
        clean_56: false,
        marked_56: false,
        clean_59: false,
        marked_59: false,
      },
      selectfields: {
        state: "",
        searchstate: "",
        recordlabelId: "",
        recordLabelName: "",
        personalContactId: "",
        personalContactName: "",
        allContactId: "",
        allContactName: "",
        editAddrId: "",
        editAddrName: "",
        disableAddrId: "",
        disableAddrName: "",
        shipping_method: "",
        label_list: "",
        expCountry: "",
      },
      userfields: {
        bSuperUser: false,
        bExecutive: false,
        SuperUserRecordlabel: [],
        bCleanPrompt: false,
        selfapprovalprocess: "",
        sOrderLabelID: "",
        order_label_id: "",
      },
      checkfields: { backorder: "", prepaid: false },
      errors: {
        uid: "",
        pwd: "",
        recordlabelId: "",
        personalContactId: "",
        allContactId: "",
        reason: "",
        shipToAddr: "",
        errmsg: "",
        accountNo: "",
        bill_to_name: "",
        bill_to_address: "",
        bill_to_city_state_zip: "",
      },
      errMsg: "",
      resetContactView: false,
      loginFailed: 0,
      loading: {
        loadingOrderComplete: false,
        loadingSubmitOrder: false,
        loadingSubmitandPrintOrder: false,
        loading: false,
        loadingSearchContact: false,
      },
      State: [],
      contactfields: {
        shipName: "",
        shipAddress: "",
        shipPhone: "",
        searchContact: "",
        company: "",
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        province: "",
        postal: "",
        country: "",
        contactattentionId: "",
        attentionId: "",
      },
      PersonalContact: [],
      AllContact: [],
      Products: [],
      ProductsErr: [],
      OrderItems: [],
      modal: {
        modalShow: false,
        modalTitle: "",
        modalBody: false,
        modalAddrButton: "",
        modalAction: "",
        modalCntlDisabled: false,
        modalDisplayAddr: false,
        modalAlertShow: false,
        modalAlertTitle: "",
        modalAlertErrorMsg: "",
      },
      modalShow: false,
      key: 0,
      startKey: true,
      orderComplete: false,
      loggedIn,
      isPrint: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalAlertShow = this.handleModalAlertShow.bind(this);
    this.handleModalAlertClose = this.handleModalAlertClose.bind(this);
    this.checkAlphaNumericInput = this.checkAlphaNumericInput.bind(this);
    this.checkAlphaNumericInputCity =
      this.checkAlphaNumericInputCity.bind(this);
    this.checkNumericInput = this.checkNumericInput.bind(this);
    this.checkNumericInputcheckLengh =
      this.checkNumericInputcheckLengh.bind(this);
    this.handleOnSubmitAddress = this.handleOnSubmitAddress.bind(this);
    this.handleMultipleSelectRow = this.handleMultipleSelectRow.bind(this);
    this.handleOnSubmitMultipleSelect =
      this.handleOnSubmitMultipleSelect.bind(this);
      this.handleOnSubmitAllSelect =
      this.handleOnSubmitAllSelect.bind(this);
    this.handleOnSubmitOrderComplete =
      this.handleOnSubmitOrderComplete.bind(this);
    this.handleOnSubmitOrder = this.handleOnSubmitOrder.bind(this);
    this.handleOnBlurValidateQty = this.handleOnBlurValidateQty.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.ValidatePrice = this.ValidatePrice.bind(this);
    this.handleOnBlurValidateDate = this.handleOnBlurValidateDate.bind(this);
    this.handleOnBlurValidateString =
      this.handleOnBlurValidateString.bind(this);
      this.handleImport.bind(this);
  }

  componentWillMount() {
   
    
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let userfields = this.state.userfields;
    let optionfields = this.state.optionfields;
    let modal = this.state.modal;
    modal["modalAlertShow"] = true;
    //FG-Free Goods Order, BI-Billable Order and NT-Non Billable Order
    fields["orderType"] = this.props.location.state.orderType;
    if (this.props.location.state.action === "copy") {
      fields["action"] = "copy";
      
      let createOrderController = new CreateOrderController();
      createOrderController
        .getOrderSummary(this.props.location.state.orderId)
        .then((resOrderSummary) => {
          fields["reason"] = resOrderSummary.Reason;
          userfields["bCleanPrompt"] = true;
          userfields["sOrderLabelID"] = resOrderSummary.Label_Id;
          if (
            resOrderSummary.sales_type === GlobalVar.MARKED_54 ||
            resOrderSummary.sales_type === GlobalVar.MARKED_91 ||
            resOrderSummary.sales_type === GlobalVar.MARKED_53
          ) {
            optionfields["marked"] = true;
            optionfields["clean"] = false;
            optionfields["marked_56"] = false;
            optionfields["clean_56"] = false;
            optionfields["marked_59"] = false;
            optionfields["clean_59"] = false;
            fields["cleanBI"] = false;
          } else if (
            resOrderSummary.sales_type === GlobalVar.CLEAN_54 ||
            resOrderSummary.sales_type === GlobalVar.CLEAN_91 ||
            resOrderSummary.sales_type === GlobalVar.CLEAN_51
          ) {
            optionfields["marked"] = false;
            optionfields["clean"] = true;
            optionfields["marked_56"] = false;
            optionfields["clean_56"] = false;
            optionfields["marked_59"] = false;
            optionfields["clean_59"] = false;
            fields["cleanBI"] = true;
          } else if (resOrderSummary.sales_type === GlobalVar.MARKED_56) {
            optionfields["marked"] = false;
            optionfields["clean"] = false;
            optionfields["marked_56"] = true;
            optionfields["clean_56"] = false;
            optionfields["marked_59"] = false;
            optionfields["clean_59"] = false;
            fields["cleanBI"] = false;
          } else if (resOrderSummary.sales_type === GlobalVar.CLEAN_56) {
            optionfields["marked"] = false;
            optionfields["clean"] = false;
            optionfields["marked_56"] = false;
            optionfields["clean_56"] = true;
            optionfields["marked_59"] = false;
            optionfields["clean_59"] = false;
            fields["cleanBI"] = true;
          } else if (resOrderSummary.sales_type === GlobalVar.MARKED_59) {
            optionfields["marked"] = false;
            optionfields["clean"] = false;
            optionfields["marked_56"] = false;
            optionfields["clean_56"] = false;
            optionfields["marked_59"] = true;
            optionfields["clean_59"] = false;
            fields["cleanBI"] = false;
          } else if (resOrderSummary.sales_type === GlobalVar.CLEAN_59) {
            optionfields["marked"] = false;
            optionfields["clean"] = false;
            optionfields["marked_56"] = false;
            optionfields["clean_56"] = false;
            optionfields["marked_59"] = false;
            optionfields["clean_59"] = true;
            fields["cleanBI"] = true;
          }
          this.setState({ optionfields });
          createOrderController
            .getOrderItems(this.props.location.state.orderId)
            .then((jsonOrderItems) => {
              this.setState({
                OrderItems: JSON.parse(JSON.stringify(jsonOrderItems)),
              });
              this.state.OrderItems.map((prop: any, key) => {
                let fields = this.state.fields;
                fields["maxKey"] = key;
                fields["artist"][key] = prop["artist"];
                fields["title"][key] = prop["title"];
                fields["selection"][key] = prop["selection"];
                fields["config"][key] = prop["config"];
                fields["qty"][key] = prop["quantity"];
                if (
                  this.state.fields.orderType === GlobalVar.BI &&
                  (this.state.optionfields.marked ||
                    this.state.optionfields.marked_56 ||
                    this.state.optionfields.marked_59)
                ) {
                  createOrderController
                    .getCost(prop["selection"])
                    .then((resCost) => {
                      fields["cost"][key] = resCost.Cost;
                    });
                } else {
                  fields["cost"][key] = prop["cost"];
                }
                fields["price"][key] = String(prop["price"]);
                //Billable Order
                if (
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                ) {
                  this.dispDiscountPerc(prop["price"], key, fields);
                }
                if (this.state.fields.orderType === GlobalVar.TB) {
                  fields["artistStoreName"][key] = prop["artistStore"];
                  fields["tourDate"][key] =
                    prop["tourDate"].slice(5, 7) +
                    "/" +
                    prop["tourDate"].slice(8, 10) +
                    "/" +
                    prop["tourDate"].slice(0, 4);
                }
              });
              this.set_process_and_clean();
            })
            .catch((error) => {
            });
        })
        .catch((error) => {
        });
    } else {
      //Not from copy functionality
      userfields["sOrderLabelID"] = sessionStorage.getItem("user_label_id");
    }
    fields["searchContact"] = "";
    fields["businessunit"] =
      sessionStorage.getItem("business_unit") === "null" ||
      sessionStorage.getItem("business_unit") === ""
        ? ""
        : sessionStorage.getItem("business_unit");
    selectfields["label_list"] = "Please Select";
    selectfields["recordLabelName"] = "Select Your Operating Company";
    selectfields["shipping_method"] = "Standard Ground";
    //super user begin
    userfields["bSuperUser"] =
      sessionStorage.getItem("is_super_user") === "1" ? true : false;
    userfields["bExecutive"] =
      sessionStorage.getItem("is_executive") === "1" ? true : false;
    //66 Ticket Bundling
    if (fields["orderType"] === GlobalVar.TB) {
      fields["cleanBI"] = true;
      userfields["bCleanPrompt"] = true;
      optionfields["clean"] = true;
      //optionfields['clean'] = true;
    }
    // Sales Type 89
    if (fields["orderType"] === GlobalVar.S89) {
      fields["cleanBI"] = true;
      userfields["bCleanPrompt"] = true;
      optionfields["clean"] = true;
      //optionfields['clean'] = true;
    }
    //super user end
    if (
      userfields["sOrderLabelID"] === "CAR" &&
      fields["orderType"] === GlobalVar.NT &&
      !userfields["bSuperUser"]
    ) {
      optionfields["clean"] = true;
      optionfields["marked"] = false;
    }
    
    //NT Billable for Accountno
    if (fields["orderType"] === GlobalVar.NT) {
      fields["accountNo"] = sessionStorage.getItem("Account_no");
    }
    //Free Goods order for Accountno
    if (fields["orderType"] === GlobalVar.FG) {
      fields["accountNo"] = sessionStorage.getItem("CustomerNumber");
    }
    //this.setState({ fields, selectfields, State:JSON.parse(JSON.stringify(jsonState.result)), resetContactView:false });
    this.setState({
      fields,
      selectfields,
      resetContactView: false,
      userfields,
      optionfields,
      modal,
    });
    this.searchResult();
    let commonController = new CommonController();
    let createOrderController = new CreateOrderController();
    createOrderController
      .selectPersonalContact(
        sessionStorage.getItem("uid")!,
        sessionStorage.getItem("label_id")!,
        fields["orderType"]
      )
      .then((jsonPersonalContact) => {
        this.setState({
          PersonalContact: JSON.parse(JSON.stringify(jsonPersonalContact)),
        });
        modal["modalAlertShow"] = false;
        this.setState({ modal });
      })
      .catch((error) => {
        console.error(
          "There was an error loading Personal Contact..., " + error
        );
      });

    if (userfields["bSuperUser"]) {
      //Super User
      commonController
        .getAllLabel()
        .then((jsonLabel) => {
          let superUserRecordlabelList = JSON.parse(
            JSON.stringify(
              jsonLabel.filter(
                (item) =>
                  item.ownerLabel == "Yes" &&
                  item.id !== sessionStorage.getItem("user_label_id")
              )
            )
          );
          let getCurrentRecordlabel = JSON.parse(
            JSON.stringify(
              jsonLabel.filter(
                (item) =>
                  item.ownerLabel == "Yes" &&
                  item.id == sessionStorage.getItem("user_label_id")
              )
            )
          );
          if (getCurrentRecordlabel.length > 0) {
            superUserRecordlabelList.unshift({
              id: getCurrentRecordlabel[0]["id"],
              name: getCurrentRecordlabel[0]["name"],
              ownerLabel: getCurrentRecordlabel[0]["ownerLabel"],
            });
          }
         
          userfields["SuperUserRecordlabel"] = superUserRecordlabelList;
          this.setState({
            userfields,
          });
          /*let basketballPlayers = jsonLabel.filter(function (student) { 
                    return student.id === "UMD"; 
                }).map(function (student) { 
                    return student.name; 
                }) */
        })
        .catch((error) => {
          console.error(
            "There was an error loading operating company..., " + error
          );
        });
    } else if (!userfields["bSuperUser"] && !userfields["bExecutive"]) {
      //display Label description if Regular user.
      commonController
        .getLabel(fields["labelId"])
        .then((resLabel) => {
          fields["labelName"] = resLabel.Name;
        })
        .catch((error) => {
          console.error("There was an error loading getLabel..., " + error);
        });
    }
    if (!userfields["bSuperUser"]) {
      //Exeutive and Regular User
      this.set_process_and_clean();
    }
    commonController
      .getApprovalProcess(sessionStorage.getItem("process_name")!)
      .then((resApprovalProcess) => {
        //if is_Overnight set as true, to enable dropdown else display only Standard Ground
        if (resApprovalProcess.success) {
          fields["isOvernight"] = resApprovalProcess.Is_Overnight;
          this.setState({ fields });
        }
      })
      .catch((error) => {
        console.error("There was an error getApprovalProcess IsOverNight");
      });

    createOrderController
      .getStates()
      .then((jsonState) => {
        this.setState({
          State: JSON.parse(JSON.stringify(jsonState)),
        });
      })
      .catch((error) => {
        console.error("There was an error loading States..., " + error);
      });
    // Get Local IP
    publicIP()
      .then((ip) => {
        let fields = this.state.fields;
        fields["ipAddress"] = ip;
        this.setState({ fields });
      })
      .catch((error) => {
        console.error("There was an error loading IP Address..., " + error);
        // 'Unable to get IP address.'
      });
  }

  handleAddressModalShow = (action, e: any) => {
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    e.persist();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    //A-add, E-Edit, D-Disable
    if (action === "A") {
      this.clearError();
      this.clearfields();
      modal["modalAddrButton"] = "Add New Contact";
      modal["modalCntlDisabled"] = false;
      modal["modalDisplayAddr"] = true;
    } else if (action === "E") {
      this.clearError();
      this.clearfields();
      selectfields["editAddrId"] = "0";
      this.setState({ selectfields });
      modal["modalAddrButton"] = "Edit Contact";
      modal["modalCntlDisabled"] = false;
      modal["modalDisplayAddr"] = false;
    } else {
      this.clearError();
      this.clearfields();
      selectfields["editAddrId"] = "0";
      this.setState({ selectfields });
      modal["modalAddrButton"] = "Disable Contact";
      modal["modalCntlDisabled"] = true;
      modal["modalDisplayAddr"] = false;
    }
    modal["modalAction"] = action;
    modal["modalBody"] = true;
    modal["modalShow"] = true;
    modal["modalTitle"] = "Contact";
    this.setState({ modal });
  };
  searchProducts = (row, artist, title, selection, upc, labelId) => {
    if (artist + title + selection + upc == "") {
      return;
    }

    let fields = this.state.fields;
    let userfields = this.state.userfields;
    let loading = this.state.loading;
    let sWhere: string = "";

    fields["fileUpload"] = false;
    //if (e.currentTarget.name === "search"){
    fields["productButton"][row] = "wait.....";
    let noProductMatchMsg = "";
    if (artist !== "") {
      noProductMatchMsg = noProductMatchMsg + 'Artist: "' + artist + '"';
    }
    if (title !== "") {
      noProductMatchMsg = noProductMatchMsg + ' Title: "' + title + '"';
    }
    if (selection !== "") {
      noProductMatchMsg = noProductMatchMsg + ' Selection: "' + selection + '"';
    }
    if (upc !== "") {
      noProductMatchMsg = noProductMatchMsg + ' UPC: "' + upc + '"';
    }

    fields["noProductMatch"] = noProductMatchMsg; //'Artist: '+artist+', Title: '+title+', Selection: '+selection+' ,UPC: '+upc;
    //get maximum selected row
    //if (row > fields['maxKey'])
    //fields['maxKey'] = row;
    this.setState({ fields });
    //}
    //alert("MaxKey: "+ row)
    if (artist !== "") {
      if (sWhere !== "") {
        sWhere = sWhere + " AND ";
      }
      sWhere = sWhere + "Artist LIKE '%" + artist + "%'";
    }

    if (title !== "") {
      if (sWhere !== "") {
        sWhere = sWhere + " AND ";
      }
      sWhere = sWhere + "Title LIKE '%" + title + "%'";
    }

    if (selection !== "") {
      if (sWhere !== "") {
        sWhere = sWhere + " AND ";
      }
      sWhere = sWhere + "selection_id LIKE '%" + selection + "%'";
    }

    if (upc !== "") {
      if (sWhere !== "") {
        sWhere = sWhere + " AND ";
      }
      sWhere = sWhere + "UPC LIKE '%" + upc + "%'";
    }

    var data = {};
    data = {
      recordLabelId: labelId,
    };

    let createOrderController = new CreateOrderController();
    createOrderController
      .getReportingCo(data)
      .then((response) => {
        let sLabels = response.sLabels;

        if (sLabels !== "") {
          if (
            labelId === "CN" ||
            labelId === "DIS" ||
            labelId === "AST" ||
            labelId === "HBN" ||
            labelId === "CAP" ||
            labelId === "HAR" ||
            labelId === "VIR" ||
            labelId === "WEL" ||
            labelId === "ATO"
          ) {
            sLabels = "(reportingunit in (" + sLabels + "))";
          } else {
            sLabels = "(RecordLabel_ID in ('" + labelId + "'," + sLabels + "))";
          }
        } else {
          sLabels = "(RecordLabel_ID='" + labelId + "')";
        }
        //Super user and Regular User, not for executive user
        if (!userfields["bExecutive"]) {
          //if UME user, force catalog product search
          if (sessionStorage.getItem("user_label_id") === "SPM") {
            if (sWhere !== "") {
              sWhere = sWhere + " AND ";
            }
            sWhere = sWhere + " (musicline = '1520' or " + sLabels + ")";
          } else if (
            sessionStorage.getItem("user_label_id") === "UMD" &&
            userfields["bSuperUser"]
          ) {
            //UMVD Super user
            if (labelId === "SPM") {
              //force catalog product search for UME record label
              if (sWhere !== "") {
                sWhere = sWhere + " AND ";
              }
              sWhere =
                sWhere + " (musicline = '1520' or RecordLabel_ID in ('SPM'))";
            }
            //****Comments the code not displaying option 'catalog' and 'label' for super user****
            ////As per Business request, catalog relevant details has been ignored.
            //elseif (sSearchType === "catalog"){ //'force label product search for selected record label
            //    sWhere = sWhere + " musicline = '1520' and " + sLabels
            //}
            //else{
            //    sWhere = sWhere + " musicline <> '1520' and " + sLabels
            //}
            if (sWhere !== "") {
              sWhere = sWhere + " AND ";
            }
            sWhere = sWhere + sLabels;
          } else if (
            sessionStorage.getItem("user_label_id") !== "UMD" &&
            userfields["bSuperUser"]
          ) {
            //Super user who is not UMVD
            if (sWhere !== "") {
              sWhere = sWhere + " AND ";
            }
            if (labelId === "SPM") {
              //force catalog product search for UME record label
              sWhere =
                sWhere + " (musicline = '1520' or RecordLabel_ID in ('SPM'))";
            } else {
              //force catalog product search for selected record label
              sWhere = sWhere + sLabels;
            }
          } else {
            //force catalog product search for users assigned record label
            if (sWhere !== "") {
              sWhere = sWhere + " AND ";
            }
            sWhere = sWhere + sLabels;
          }
        }

        //'Filter results for Buena Vista Music.
        if (sessionStorage.getItem("user_label_id") === "BVM") {
          sWhere = sWhere + " AND RecordLabel_ID = 'BVM'";
        } else {
          sWhere = sWhere + " AND RecordLabel_ID <> 'BVM'";
        }
         data = {
          sWhere: sWhere,
        };
        //createOrderController = new CreateOrderController();
        createOrderController
          .getProductSearch(data)
          .then((jsonAllProducts) => {
            // if(sessionStorage.getItem("multiple_select") === "1"){
            //    TableMultiSelect.clearAllMultiArr();
            //}

            //console.log ('Products: ',JSON.parse(JSON.stringify(jsonAllProducts)))
            this.setState({
              Products: JSON.parse(JSON.stringify(jsonAllProducts)),
            });

            this.setState({ key: row });
            let modal = this.state.modal;
            modal["modalShow"] = true;
            modal["modalTitle"] = "Select Product";
            modal["modalBody"] = false;
            fields["productButton"][row] = "Search";
            loading["loading"] = false;
            this.setState({ modal, fields, loading });
          })
          .catch((error) => {
            loading["loading"] = false;
            this.setState({ loading });
            console.error("There was an error in fetch products..., " + error);
          });
      })
      .catch((error) => {
        loading["loading"] = false;
        this.setState({ loading });
        console.error("There was an error in fetch products..., " + error);
      });
  };

  handleModalShow = (i, e: any) => {
    
    //alert(e.currentTarget.name + " : " +i)
    let fields = this.state.fields;
    let userfields = this.state.userfields;
    let selectfields = this.state.selectfields;

    //Clearing the current index qty value
    if (fields["qty"][i] !== undefined || fields["qty"][i] !== "") {
      fields["qty"][i] = "";
      this.setState({ fields });
    }

    if (fields["artist"][i] === undefined) {
      fields["artist"][i] = "";
      this.setState({ fields });
    }
    if (fields["title"][i] === undefined) {
      fields["title"][i] = "";
      this.setState({ fields });
    }
    if (fields["selection"][i] === undefined) {
      fields["selection"][i] = "";
      this.setState({ fields });
    }
    if (fields["UPC"][i] === undefined) {
      fields["UPC"][i] = "";
      this.setState({ fields });
    }

    if (
      userfields["bSuperUser"] &&
      selectfields["label_list"] == "Please Select"
    ) {
      alert("You may not search for product until you choose a label.");
      return false;
    }

    if (sessionStorage.getItem("multiple_select") === "1") {
      TableMultiSelect.clearAllMultiArr();
    }
    var prevTREmptyEntry = "N";
    for (var d = i - 1; d >= 0; d--) {
      if (fields["productButton"][d] === "Search") {
        alert("Please select the product for empty row first");
        prevTREmptyEntry = "Y";
        //fields['productButton'][d] = "Search";
              
        fields["productButton"][i] = "Search";
        fields["artist"][i] = "";
        fields["title"][i] = "";
        fields["selection"][i] = "";
        fields["UPC"][i] = "";
        this.setState({ fields });
        return false;
        break;
      }
    }

    //Do not show this message for a UMVD super user ordering UME product
    //if (!((selectfields['label_list'] === "SPM" && sessionStorage.getItem("user_label_id") === "UMD" && fields['bSuperUser']) || fields['bExecutive'])) {
    //    alert ("You may not search for product until you choose 'Label Product'");
    //    return false;
    //}

    if (
      fields["artist"][i] === undefined &&
      fields["title"][i] === undefined &&
      fields["selection"][i] === undefined &&
      fields["UPC"][i] === undefined
    ) {
      alert("Please enter a title, artist, selection, or UPC.");
      return false;
    }
    if (
      fields["artist"][i] === "" &&
      fields["title"][i] === "" &&
      fields["selection"][i] === "" &&
      fields["UPC"][i] === ""
    ) {
      alert("Please enter a title, artist, selection, or UPC.");
      return false;
    }

    //var sInvalidCharList=/[*!@#$%^&()_+=-\;/\?,\']/g;
    var regexSpl = "*!@#$%^&()_+=-][;/?,'";
    //var sInvalidCharList = /[!`@#$%^&+\=*?_~]/g;
    var sInvalidCharList = /[$-/:-?{-~@#!"^_`\[\]]/g;
    if (
      sInvalidCharList.test(fields["artist"][i]) ||
      sInvalidCharList.test(fields["title"][i]) ||
      sInvalidCharList.test(fields["selection"][i]) ||
      sInvalidCharList.test(fields["UPC"][i])
    ) {
      alert(
        "Please do not use special characters such as " +
          regexSpl +
          " . Use letter and numbers only please."
      );
      return false;
    }

    if (prevTREmptyEntry === "N") {
      var data = {};
      var flag = true;
      if (
        e.currentTarget.name === "search" &&
        (fields["artist"][i] !== "" ||
          fields["title"][i] !== "" ||
          fields["selection"][i] !== "" ||
          fields["UPC"][i] !== "")
      ) {
        var artist =
          fields["artist"][i] === undefined ? "" : fields["artist"][i];
        var title = fields["title"][i] === undefined ? "" : fields["title"][i];
        var selection =
          fields["selection"][i] === undefined ? "" : fields["selection"][i];
        var upc = fields["UPC"][i] === undefined ? "" : fields["UPC"][i];

        this.searchProducts(
          i,
          artist,
          title,
          selection,
          upc,
          userfields["sOrderLabelID"]
        );
      }
    }
  };
  set_process_and_clean = () => {
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let errors = this.state.errors;
    let userfields = this.state.userfields;
    var uid = sessionStorage.getItem("uid")!;
    let commonController = new CommonController();
    if (this.state.fields.orderType !== GlobalVar.TB) {
      userfields["bCleanPrompt"] = false;
    }

    commonController
      .getSelfApprovers(uid)
      .then((resSelfApprovers) => {
        //Self Approval process
        userfields["selfapprovalprocess"] = resSelfApprovers.success
          ? resSelfApprovers.process_name
          : "";
        this.setState({ userfields });

        //Super user calling spGetProcessNameforSuperUser
        var data = {};
        let createOrderController = new CreateOrderController();
        if (userfields["bSuperUser"]) {
          data = {
            uid: uid,
            user_label_id: sessionStorage.getItem("user_label_id"),
            user_department: sessionStorage.getItem("user_department"),
            label_list:
              this.state.fields.action === "copy"
                ? userfields["sOrderLabelID"]
                : selectfields["label_list"],
            order_label_id: userfields["sOrderLabelID"],
            //bNoCatalog : 1,
            order_type: fields["orderType"], //FG-Free Goods Order, BI-Billable Order and NT-Non Billable Order
          };
          createOrderController
            .getProcessNameforSuperUser(data)
            .then((response) => {
              var result =
                response.process_name === null
                  ? ""
                  : response.process_name.trim();
              if (result !== "") {
                //Self Approval process
                var sProcessName =
                  userfields["selfapprovalprocess"] === ""
                    ? result
                    : userfields["selfapprovalprocess"];
                fields["Process_Name"] = sProcessName;
                this.setState({ fields });
                commonController
                  .getApprovalProcess(sProcessName)
                  .then((resApprovalProcess) => {
                    //if the clean flag is set, we create the prompt for ordering clean product
                    if (this.state.fields.orderType !== GlobalVar.TB) {
                      userfields["bCleanPrompt"] = resApprovalProcess.Is_Clean
                        ? true
                        : false;
                    }
                    errors["errmsg"] = "";
                    this.setState({ userfields, errors });
                  })
                  .catch((error) => {
                    console.error("There was an error getApprovalProcess");
                  });
              } else {
                commonController
                  .getLabel(userfields["sOrderLabelID"])
                  .then((resLabel) => {
                    errors["errmsg"] =
                      "Approval process does not exist for ordering from this label: " +
                      resLabel.Name;
                    this.setState({ errors });
                   
                  })
                  .catch((error) => {
                    console.error(
                      "There was an error loading getLabel..., " + error
                    );
                  });
              }
            })
            .catch((error) => {
              console.error("There was an error getProcessNameforSuperUser");
            });
        } else {
          data = {
            uid: uid,
            order_type: fields["orderType"], //FG-Free Goods Order, BI-Billable Order and NT-Non Billable Order
          };
          createOrderController
            .getProcessNameforRegAndExeUser(data)
            .then((response) => {
              var result =
                response.process_name === null
                  ? ""
                  : response.process_name.trim();
              if (result !== "") {
                //Self Approval process
                let sProcessName =
                  userfields["selfapprovalprocess"] === ""
                    ? result
                    : userfields["selfapprovalprocess"];
                fields["Process_Name"] = sProcessName;
                this.setState({ fields });
                commonController
                  .getApprovalProcess(sProcessName)
                  .then((resApprovalProcess) => {
                
                    //if the clean flag is set, we create the prompt for ordering clean product
                    if (this.state.fields.orderType !== GlobalVar.TB) {
                      userfields["bCleanPrompt"] = resApprovalProcess.Is_Clean
                        ? true
                        : false;
                    }
                    errors["errmsg"] = "";
                    this.setState({ userfields, errors });
                  })
                  .catch((error) => {
                    console.error("There was an error getApprovalProcess");
                  });
              } else {
                commonController
                  .getLabel(userfields["sOrderLabelID"])
                  .then((resLabel) => {
                    errors["errmsg"] =
                      "Approval process does not exist for ordering from this label: " +
                      resLabel.Name;
                    this.setState({ errors });
                  })
                  .catch((error) => {
                    console.error(
                      "There was an error loading getLabel..., " + error
                    );
                  });
              }
            })
            .catch((error) => {
              console.error(
                "There was an error getProcessNameforRegAndExeUser"
              );
            });
        }
      })
      .catch((error) => {
        console.error("There was an error getSelfApprovers");
      });
  };
  handleModalClose = (e: any) => {
    let modal = this.state.modal;
    let loading = this.state.loading;
    modal["modalShow"] = false;
    loading["loading"] = false;
    this.setState({ modal, loading });
  };
  handleDelete = (key, e: any) => {
    
    let fields = this.state.fields;
    fields["artist"][key] = "";
    fields["title"][key] = "";
    fields["selection"][key] = "";
    fields["UPC"][key] = "";
    fields["config"][key] = "";
    fields["unitsPerSet"][key] = "";
    fields["rlsDate"][key] = "";
    fields["qty"][key] = "";
    fields["productButton"][key] = "Search";
    if (fields["orderType"] === GlobalVar.BI) {
      fields["price"][key] = "";
      fields["cost"][key] = "";
      fields["discount"][key] = "";
      fields["flag"][key] = false;
    } else if (fields["orderType"] === GlobalVar.TB) {
      fields["price"][key] = "";
      fields["cost"][key] = "";
      fields["discount"][key] = "";
      fields["artistStoreName"][key] = "";
      fields["tourDate"][key] = "";
      fields["flag"][key] = false;
    }
    //finding revers maximum delete row.
    if (key === fields["maxKey"]) {
      for (var d = key - 1; d >= 0; d--) {
        if (fields["productButton"][d] === "Delete") {
          fields["maxKey"] = d;
          break;
        }
      }
    }
    fields["prodCount"] = fields["prodCount"] - 1;
    this.setState({
      fields,
    });
  };

  handleSearchClick = (e: any) => {
    let selectfields = this.state.selectfields;
    let loading = this.state.loading;
    let value = this.state.fields.searchContact;
    loading["loadingSearchContact"] = true;
    this.setState({ AllContact: [], loading });
    if (this.state.fields.lblSearchContact === "State") {
      value = this.state.selectfields.searchstate.split("-")[0].trim();
    }
    //if (e.currentTarget.name === 'searchstate'){
    //selectfields['searchstate'] = "Select A State";
    // }

    let createOrderController = new CreateOrderController();
    createOrderController
      .selectAllContact(
        this.state.fields.lblSearchContact,
        value,
        sessionStorage.getItem("uid")!,
        sessionStorage.getItem("label_id")!
      )
      .then((jsonAllContact) => {
        loading["loadingSearchContact"] = false;
        this.setState({
          AllContact: JSON.parse(JSON.stringify(jsonAllContact)),
          loading,
        });
      })
      .catch((error) => {
        console.error(
          "There was an error loading operating company..., " + error
        );
      });
    this.setState({ selectfields, resetContactView: true });
  };

  handleChange = (e: any) => {
    let fields = this.state.fields;
    if (e.currentTarget.value.trim().length > 0) {
      fields[e.currentTarget.name] = e.currentTarget.value;
    } else {
      fields[e.currentTarget.name] = e.currentTarget.value.trim();
    }
    this.setState({ fields });
  };
  handleContactChange = (e: any) => {
    let contactfields = this.state.contactfields;
    if (e.currentTarget.value.trim().length > 0) {
      contactfields[e.currentTarget.name] = e.currentTarget.value;
    } else {
      contactfields[e.currentTarget.name] = e.currentTarget.value.trim();
    }
    this.setState({ contactfields });
  };

  handleFormChange = (i, e: any) => {
    let fields = this.state.fields;
    if (this.state.fields.action === "copy") {
      fields[e.currentTarget.name][i] = e.currentTarget.value;
    }
    if (fields["productButton"][i] === "Search") {
      if (e.currentTarget.value.trim().length > 0) {
        fields[e.currentTarget.name][i] = e.currentTarget.value;
      } else {
        fields[e.currentTarget.name][i] = e.currentTarget.value.trim();
      }
      //fields[e.currentTarget.name][i] = e.currentTarget.value;
      if (e.currentTarget.name === "qty") {
        if (fields["qty"][i] === undefined) {
          fields["qty"][i] = "";
          //this.setState({ fields });
        }
        if (fields["qty"][i] !== "") {
          //clear index qty if no search begin
          fields[e.currentTarget.name][i] = "";
          alert("Please search product first, before entering Qty");
        }
      }
      if (e.currentTarget.name === "price") {
        if (fields["price"][i] === undefined) {
          fields["price"][i] = "";
          //this.setState({ fields });
        }
        if (fields["price"][i] !== "") {
          //clear index qty if no search begin
          fields["price"][i] = "";
          alert("Please search product first, before entering Price");
        }
      }
      if (e.currentTarget.name === "artistStoreName") {
        if (fields["artistStoreName"][i] === undefined) {
          fields["artistStoreName"][i] = "";
          //this.setState({ fields });
        }
        if (fields["artistStoreName"][i] !== "") {
          //clear index qty if no search begin
          fields["artistStoreName"][i] = "";
          alert(
            "Please search product first, before entering Artist Store Name"
          );
        }
      }
      if (e.currentTarget.name === "tourDate") {
        if (fields["tourDate"][i] === undefined) {
          fields["tourDate"][i] = "";
          //this.setState({ fields });
        }
        if (fields["tourDate"][i] !== "") {
          //clear index qty if no search begin
          fields["tourDate"][i] = "";
          alert("Please search product first, before entering Tour Date");
        }
      }
    }
    if (fields["productButton"][i] === "Delete") {
      if (e.currentTarget.name === "qty") {
        this.checkNumericInputSearchProduct(i, e);
      } else {
        if (e.currentTarget.value.trim().length > 0) {
          if (e.currentTarget.name === "artistStoreName") {
            fields["artistStoreName"][i] = e.currentTarget.value;
          } else if (e.currentTarget.name === "tourDate") {
            fields["tourDate"][i] = e.currentTarget.value;
          } else if (e.currentTarget.name === "price") {
            fields["price"][i] = e.currentTarget.value;
          } else {
            fields[e.currentTarget.name][i] = e.currentTarget.value;
          }
        } else {
          fields[e.currentTarget.name][i] = e.currentTarget.value.trim();
        }
        //fields[e.currentTarget.name][i] = e.currentTarget.value;
      }
    }
    this.setState({ fields });
  };

  handleSelectChange = (e: any) => {
    let fields = this.state.fields;
    let userfields = this.state.userfields;
    let selectfields = this.state.selectfields;
    let optionfields = this.state.optionfields;
    let contactfields = this.state.contactfields;
    let errors = this.state.errors;
    let modal = this.state.modal;
    let currentTargetName = e.currentTarget.name;
    if (currentTargetName === "personalContactId") {
      selectfields["personalContactName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
      var selectedText =
        e.currentTarget.options[e.currentTarget.selectedIndex].text.split(
          "..."
        );
      var selectedValue =
        e.currentTarget.options[e.currentTarget.selectedIndex].value.split(
          "~~"
        );
      if (
        e.currentTarget.options[e.currentTarget.selectedIndex].value === "0"
      ) {
        contactfields["shipName"] = "";
        contactfields["shipAddress"] = "";
        contactfields["shipPhone"] = "";
        if (fields["orderType"] === GlobalVar.TB) {
          fields["accountNo"] = "";
          fields["bill_to_name"] = "";
          fields["bill_to_address"] = "";
          fields["bill_to_city_state_zip"] = "";
        }
      } else {
        contactfields["attentionId"] = selectedValue[0];
        contactfields["shipName"] = selectedText[0];
        contactfields["shipAddress"] =
          selectedValue[1] +
          ", " +
          selectedValue[2] +
          ", " +
          selectedText[2] +
          ", " +
          selectedText[3] +
          ", " +
          selectedValue[3];
        contactfields["shipPhone"] = selectedValue[4];
        selectfields["expCountry"] = selectedValue[6];
        if (
          selectfields["expCountry"] === "USA" ||
          selectfields["expCountry"] === "US"
        ) {
          fields["specialIns"] = "";
        } else {
          fields["specialIns"] = "Export";
        }
        errors["shipToAddr"] = "";
        if (
          fields["orderType"] === GlobalVar.TB ||
          fields["orderType"] === GlobalVar.S89
        ) {
          fields["accountNo"] = selectedValue[5]; //Display Account_number for Ticket bundling users.
          fields["bill_to_name"] = selectedValue[7];
          fields["bill_to_address"] = selectedValue[8];
          fields["bill_to_city_state_zip"] = selectedValue[9];
        }
      }
    } else if (currentTargetName === "allContactId") {
      if (
        e.currentTarget.options[e.currentTarget.selectedIndex].text ===
        "Select All Contact"
      ) {
        contactfields["shipName"] = "";
        contactfields["shipAddress"] = "";
        contactfields["shipPhone"] = "";
        return false;
      }
      selectfields["allContactName"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
      var selectedText =
        e.currentTarget.options[e.currentTarget.selectedIndex].text.split(
          "..."
        );
      var selectedValue =
        e.currentTarget.options[e.currentTarget.selectedIndex].value.split(
          "~~"
        );
      contactfields["attentionId"] = selectedValue[0];
      contactfields["shipName"] = selectedText[0];
      contactfields["shipAddress"] =
        selectedValue[1] +
        ", " +
        selectedValue[2] +
        ", " +
        selectedText[2] +
        ", " +
        selectedText[3] +
        ", " +
        selectedValue[3];
      contactfields["shipPhone"] = selectedValue[4];
      selectfields["searchstate"] = "Select A State";
      fields["lblSearchContact"] = "Name";
      fields["searchContact"] = "";
      optionfields["name"] = true;
      optionfields["city"] = false;
      optionfields["company"] = false;
      optionfields["searchstate"] = false;
      selectfields["personalContactId"] = "";
      errors["shipToAddr"] = "";
      selectfields["expCountry"] = selectedValue[5];

      if (
        selectfields["expCountry"] === "USA" ||
        selectfields["expCountry"] === "US"
      ) {
        fields["specialIns"] = "";
      } else {
        fields["specialIns"] = "Export";
      }
    } else if (currentTargetName === "editAddrId") {
      if (
        e.currentTarget.options[e.currentTarget.selectedIndex].text !==
        "Select Contact To Edit"
      ) {
        modal["modalDisplayAddr"] = true;
        this.clearError();
        selectfields["editAddrName"] =
          e.currentTarget.options[e.currentTarget.selectedIndex].text;
        var selectedText = e.currentTarget.options[
          e.currentTarget.selectedIndex
        ].text.split(".......................................");
        var selectedValue =
          e.currentTarget.options[e.currentTarget.selectedIndex].value.split(
            "~~"
          );
        contactfields["company"] = selectedText[1];
        contactfields["name"] = selectedText[0];
        contactfields["address1"] = selectedValue[1];
        contactfields["address2"] = selectedValue[2];
        contactfields["city"] = selectedValue[3];
        contactfields["zip"] = selectedValue[5].replace(".", "");
        contactfields["phone"] = selectedValue[6];
        contactfields["contactattentionId"] = selectedValue[0];
        if (selectedValue[7] === "C") {
          //C=Private, P=Public
          optionfields["private"] = true;
          optionfields["public"] = false;
        } else {
          optionfields["private"] = false;
          optionfields["public"] = true;
        }
        if (selectedValue[8] === "1" || selectedValue[8] === "true") {
          //IsContactUS => 1=Yes, 0=No
          selectfields["state"] = selectedValue[4];
          contactfields["zip"] = selectedValue[5].replace(".", "");
          optionfields["locatedYes"] = true;
          optionfields["locatedNo"] = false;
        } else {
          contactfields["province"] = selectedValue[4];
          contactfields["postal"] = selectedValue[5].replace(".", "");
          contactfields["country"] = selectedValue[9];
          optionfields["locatedYes"] = false;
          optionfields["locatedNo"] = true;
        }
      }
    } else if (currentTargetName === "disableAddrId") {
      if (
        e.currentTarget.options[e.currentTarget.selectedIndex].text !==
        "Select Contact To Disable"
      ) {
        modal["modalDisplayAddr"] = true;
        this.clearError();
        selectfields["disableAddrName"] =
          e.currentTarget.options[e.currentTarget.selectedIndex].text;
        var selectedText = e.currentTarget.options[
          e.currentTarget.selectedIndex
        ].text.split(".......................................");
        var selectedValue =
          e.currentTarget.options[e.currentTarget.selectedIndex].value.split(
            "~~"
          );
        contactfields["company"] = selectedText[1];
        contactfields["name"] = selectedText[0];
        contactfields["address1"] = selectedValue[1];
        contactfields["address2"] = selectedValue[2];
        contactfields["city"] = selectedValue[3];
        contactfields["phone"] = selectedValue[6];
        contactfields["contactattentionId"] = selectedValue[0];
        if (selectedValue[7] === "C") {
          //C=Private, P=Public
          optionfields["private"] = true;
          optionfields["public"] = false;
        } else {
          optionfields["private"] = false;
          optionfields["public"] = true;
        }
        if (selectedValue[8] === "1" || selectedValue[8]) {
          //IsContactUS => 1=Yes, 0=No
          selectfields["state"] = selectedValue[4];
          contactfields["zip"] = selectedValue[5].replace(".", "");
          optionfields["locatedYes"] = true;
          optionfields["locatedNo"] = false;
        } else {
          contactfields["province"] = selectedValue[4];
          contactfields["postal"] = selectedValue[5].replace(".", "");
          contactfields["country"] = selectedValue[9];
          optionfields["locatedYes"] = false;
          optionfields["locatedNo"] = true;
        }
      }
    } else if (currentTargetName === "label_list") {
      //Super User

      if (fields["productButton"][0] === "Delete" && userfields["bSuperUser"]) {
        if (window.confirm("Do you want to change the label ?") == true) {
          this.clearAllProductOrder();
        } else {
          return false;
        }
      }

      //fields['labelId'] = e.currentTarget.value;
      userfields["sOrderLabelID"] = e.currentTarget.value;
      selectfields["label_list"] = e.currentTarget.value;
      let errors = this.state.errors;
      errors["errmsg"] = "";
      //By default choose marked option while selecting label.
      optionfields["marked_56"] = false;
      optionfields["marked_59"] = false;
      if (
        this.state.fields.orderType === GlobalVar.TB ||
        this.state.fields.orderType === GlobalVar.S89
      ) {
        //Ticket Bundling: enable option 66 Ticket Bundling and display discount 15% in product order
        optionfields["clean"] = true;
        optionfields["marked"] = false;
      } else {
        optionfields["marked"] = true;
        optionfields["clean"] = false;
      }
      optionfields["clean_56"] = false;
      optionfields["clean_59"] = false;
      this.setState({ userfields, selectfields, errors, optionfields });

      if (e.currentTarget.value !== "Please Select") {
        if (userfields["bSuperUser"]) {
          if (fields["orderType"] === GlobalVar.NT) {
            if (userfields["sOrderLabelID"] === "CAR") {
              optionfields["clean"] = true; //!this.state.optionfields.Clean;
              optionfields["marked"] = false;
            } else {
              optionfields["marked"] = true; //!this.state.optionfields.marked
              optionfields["clean"] = false;
            }
            this.setState({ optionfields });
          }
          if (
            sessionStorage.getItem("user_label_id") ===
            selectfields["label_list"].trim()
          ) {
            //'Not a super user
            this.set_process_and_clean();
          } else if (
            sessionStorage.getItem("user_label_id") === "UMD" &&
            selectfields["label_list"].trim() === "SPM"
          ) {
            //'UMVD Super User ordering UME product
            this.set_process_and_clean();
          } else if (
            sessionStorage.getItem("user_label_id") === "UMD" &&
            userfields["bSuperUser"]
          ) {
            //'UMVD Super User not ordering UME product
            //if (bNoCatalog and request("action") = "select_label")or request("action") = "select_type" then
            this.set_process_and_clean();
          } else if (selectfields["label_list"] !== "") {
            //'Super User ordering product
            this.set_process_and_clean();
          }
        } else {
          this.set_process_and_clean();
        }
      } else {
        if (this.state.fields.orderType !== GlobalVar.TB) {
          userfields["bCleanPrompt"] = false;
        }
      }
    } else {
    }

    selectfields[e.currentTarget.name] = e.currentTarget.value;
    this.setState({
      fields,
      optionfields,
      selectfields,
      resetContactView: false,
      modal,
      errors,
      userfields,
      contactfields,
    });
  };
  clearAllProductOrder = () => {
    let fields = this.state.fields;
    if (fields["maxKey"] === 0 && fields["productButton"][0] === "Search") {
      return false;
    }
    for (var i = fields["maxKey"]; i >= 0; i--) {
      fields["artist"][i] = "";
      fields["title"][i] = "";
      fields["selection"][i] = "";
      fields["UPC"][i] = "";
      fields["config"][i] = "";
      fields["unitsPerSet"][i] = "";
      fields["rlsDate"][i] = "";
      fields["qty"][i] = "";
      if (fields["orderType"] === GlobalVar.BI) {
        fields["price"][i] = "";
        fields["cost"][i] = "";
        fields["discount"][i] = "";
        fields["flag"][i] = false;
      } else if (fields["orderType"] === GlobalVar.TB) {
        fields["price"][i] = "";
        fields["cost"][i] = "";
        fields["discount"][i] = "";
        fields["artistStoreName"][i] = "";
        fields["tourDate"][i] = "";
        fields["flag"][i] = false;
      }
      fields["productButton"][i] = "Search";
      this.setState({
        fields,
      });
    }
  };
  displayAccountNumber = () => {
    
    if (this.state.fields.orderType === GlobalVar.TB) {
      return (
        <Col sm={4}>
          <FormInputs
            properties={{
              id: "accountNo",
              label: "Enter Account Number:",
              type: "text",
              name: "accountNo",
              className: "form-control form-control-sm",
              value: this.state.fields.accountNo,
              onChange: this.handleChange,
              maxlength: 5,
              disabled: true,
            }}
            errorMsg={this.state.errors.accountNo}
            fieldRequired={true}
          />
        </Col>
      );
    } else if (this.state.fields.orderType === GlobalVar.S89) {
      return (
        <Col sm={4}>
          <FormInputs
            properties={{
              id: "accountNo",
              label: "Enter Account Number:",
              type: "text",
              name: "accountNo",
              className: "form-control form-control-sm",
              value: this.state.fields.accountNo,
              onChange: this.handleChange,
              maxlength: 5,
              disabled: true,
            }}
            errorMsg={this.state.errors.accountNo}
            fieldRequired={true}
          />
        </Col>
      );
    } else if (this.state.fields.orderType === GlobalVar.BI) {
      return (
        <Col sm={4}>
          <FormInputs
            properties={{
              id: "accountNo",
              label: "Enter Bill-To Account #:",
              type: "text",
              name: "accountNo",
              className: "form-control form-control-sm",
              value: this.state.fields.accountNo,
              onChange: this.handleChange,
              maxlength: 5,
              disabled: false,
            }}
            errorMsg={this.state.errors.accountNo}
            fieldRequired={true}
          />
        </Col>
      );
    } else if (this.state.fields.orderType === GlobalVar.NT) {
      return (
        <Col sm={4}>
          <FormInputs
            properties={{
              id: "accountNo",
              label: "Enter Non-Billable Account #:",
              type: "text",
              name: "accountNo",
              className: "form-control form-control-sm",
              value: this.state.fields.accountNo,
              onChange: this.handleChange,
              maxlength: 5,
              disabled: false,
            }}
            errorMsg={this.state.errors.accountNo}
            fieldRequired={true}
          />
        </Col>
      );
    } else {
      return (
        <Col sm={4}>
          <FormInputs
            properties={{
              id: "accountNo",
              label: "Enter Account Number:",
              type: "text",
              name: "accountNo",
              className: "form-control form-control-sm",
              value: this.state.fields.accountNo,
              onChange: this.handleChange,
              maxlength: 5,
              disabled: false,
            }}
            errorMsg={this.state.errors.accountNo}
            fieldRequired={true}
          />
        </Col>
      );
    }
  };
  displayFreightNTAndBIBillable = () => {
    if (
      this.state.fields.orderType === GlobalVar.NT ||
      this.state.fields.orderType === GlobalVar.BI
    ) {
      return (
        <div>
          <Row>
            {this.displayAccountNumber()}
            <Col
              sm={8}
              className="align-self-center"
              style={{ textAlign: "justify" }}
            >
              <div className="text-primary font-size-xs small">
                You must have a valid account number to place a Non-Billable and
                Billable Order. If you do not have an account number, please contact the UMG Credit team.
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              className="align-self-center"
              style={{ fontSize: "100%", textAlign: "justify" }}
            >
              <div className="text-Info font-size-xs large">
                {this.state.fields.orderType === GlobalVar.BI
                  ? "Enter Bill To Information:"
                  : "Freight Info:"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "bill_to_name",
                  label: "Name/Company:",
                  type: "text",
                  name: "bill_to_name",
                  className: "form-control form-control-sm",
                  value: this.state.fields.bill_to_name,
                  onChange: this.handleChange,
                  maxlength: 100,
                }}
                fieldRequired={
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                    ? true
                    : false
                }
                errorMsg={this.state.errors.bill_to_name}
              />
            </Col>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "bill_to_address",
                  label: "Address:",
                  type: "text",
                  name: "bill_to_address",
                  className: "form-control form-control-sm",
                  value: this.state.fields.bill_to_address,
                  onChange: this.handleChange,
                  maxlength: 100,
                }}
                fieldRequired={
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                    ? true
                    : false
                }
                errorMsg={this.state.errors.bill_to_address}
              />
            </Col>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "bill_to_city_state_zip",
                  label: "City, State, Zip:",
                  type: "text",
                  name: "bill_to_city_state_zip",
                  className: "form-control form-control-sm",
                  value: this.state.fields.bill_to_city_state_zip,
                  onChange: this.handleChange,
                  maxlength: 75,
                }}
                fieldRequired={
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                    ? true
                    : false
                }
                errorMsg={this.state.errors.bill_to_city_state_zip}
              />
            </Col>
          </Row>
          {this.state.fields.orderType === GlobalVar.BI ? (
            <Row>
             {/* <Col sm={4}>
              <FormCheckBox 
                  properties={{
                    label: "Pre-Paid",
                    id: "prepaid",
                    name: "prepaid",
                    type: "checkbox",                    
                    className: "form-control-md",
                    onChange: this.handleCheckChange,
                    checked: this.state.checkfields.prepaid,
                  }}
                />
                </Col>*/}
              <Col sm={4}>
                
                <FormInputs 
                  properties={{
                    id: "freightBillingInfo",
                    label: "Freight Billing Info:",
                    type: "text",
                    name: "freightBillingInfo",
                    className: "form-control form-control-sm",
                    value: this.state.fields.freightBillingInfo,
                    onChange: this.handleChange,
                  }}
                  fieldRequired={false}
                />
                
              </Col>
              <Col
                sm={4}
                className="align-self-center"
                style={{ textAlign: "justify" }}
              >
                <div className="text-primary font-size-xs small">
                  Enter the third-party billing account for Fedex or UPS if the
                  recipient is paying shipping charges
                </div>
              </Col>
            </Row>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  displayAccountNumberFG = () => {
    if (this.state.fields.orderType === GlobalVar.FG) {
      return (
        <Row>
          <Col sm={4}>
            <FormInputs
              properties={{
                id: "accountNo",
                label: "Enter Account Number:",
                type: "text",
                name: "accountNo",
                className: "form-control form-control-sm",
                value: this.state.fields.accountNo,
                onChange: this.handleChange,
                maxlength: 5,
                disabled: false,
              }}
              errorMsg={this.state.errors.accountNo}
              fieldRequired={true}
            />
          </Col>
          <Col
            sm={8}
            className="align-self-center"
            style={{ textAlign: "justify" }}
          >
            <div className="text-primary font-size-xs small">
              You must have a valid account number to place a Free Goods Order.
              If you do not have an account number, please contact the UMG Credit team.
            </div>
          </Col>
        </Row>
      );
    } else {
      return <div></div>;
    }
  };
  displayFreightTB = () => {
    if (this.state.fields.orderType === GlobalVar.TB) {
      return (
        <div>
          <Row>
            {this.displayAccountNumber()}
            <Col
              sm={8}
              className="align-self-center"
              style={{ textAlign: "justify" }}
            >
              <div className="text-primary font-size-xs small">
                You must have a valid account number to place a Non-Billable and
                Billable Order. If you do not have an account number, please contact the UMG Credit team.
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              className="align-self-center"
              style={{ fontSize: "100%", textAlign: "justify" }}
            >
              <div className="text-Info font-size-xs large">
                {this.state.fields.orderType === GlobalVar.BI
                  ? "Enter Bill To Information:"
                  : "Freight Info:"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "bill_to_name",
                  label: "Name/Company:",
                  type: "text",
                  name: "bill_to_name",
                  className: "form-control form-control-sm",
                  value: this.state.fields.bill_to_name,
                  onChange: this.handleChange,
                  maxlength: 100,
                  disabled: true,
                }}
                fieldRequired={
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                    ? true
                    : false
                }
                errorMsg={this.state.errors.bill_to_name}
              />
            </Col>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "bill_to_address",
                  label: "Address:",
                  type: "text",
                  name: "bill_to_address",
                  className: "form-control form-control-sm",
                  value: this.state.fields.bill_to_address,
                  onChange: this.handleChange,
                  maxlength: 100,
                  disabled: true,
                }}
                fieldRequired={
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                    ? true
                    : false
                }
                errorMsg={this.state.errors.bill_to_address}
              />
            </Col>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "bill_to_city_state_zip",
                  label: "City, State, Zip:",
                  type: "text",
                  name: "bill_to_city_state_zip",
                  className: "form-control form-control-sm",
                  value: this.state.fields.bill_to_city_state_zip,
                  onChange: this.handleChange,
                  maxlength: 75,
                  disabled: true,
                }}
                fieldRequired={
                  this.state.fields.orderType === GlobalVar.BI ||
                  this.state.fields.orderType === GlobalVar.TB
                    ? true
                    : false
                }
                errorMsg={this.state.errors.bill_to_city_state_zip}
              />
            </Col>
          </Row>
          {this.state.fields.orderType === GlobalVar.TB ? (
            <Row>
            { /*<Col sm={4}>
                <FormCheckBox
                  properties={{
                    label: "Pre-Paid",
                    id: "prepaid",
                    name: "prepaid",
                    type: "checkbox",
                    className: "form-control-md",
                    onChange: this.handleCheckChange,
                    checked: this.state.checkfields.prepaid,
                  }}
                />
              </Col>*/}
              <Col sm={4}>
                <FormInputs
                  properties={{
                    id: "freightBillingInfo",
                    label: "Freight Billing Info:",
                    type: "text",
                    name: "freightBillingInfo",
                    className: "form-control form-control-sm",
                    value: this.state.fields.freightBillingInfo,
                    onChange: this.handleChange,
                  }}
                  fieldRequired={false}
                />
              </Col>
              <Col
                sm={4}
                className="align-self-center"
                style={{ textAlign: "justify" }}
              >
                <div className="text-primary font-size-xs small">
                  Enter the third-party billing account for Fedex or UPS if the
                  recipient is paying shipping charges
                </div>
              </Col>
            </Row>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  displayFreightS89 = () => {
    if (this.state.fields.orderType === GlobalVar.S89) {
      return (
        <div>
          <Row>
            {this.displayAccountNumber()}
            <Col
              sm={8}
              className="align-self-center"
              style={{ textAlign: "justify" }}
            >
              <div className="text-primary font-size-xs small">
                You must have a valid account number to place a Non-Billable and
                Billable Order. If you do not have an account number, please contact the UMG Credit team.
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  displayBillToInformationforBI = () => {
    return (
      <Row>
        <Col sm={4}>
          <FormInputs
            properties={{
              id: "freightBillingInfo",
              label: "Freight Billing Info:",
              type: "text",
              name: "freightBillingInfo",
              className: "form-control input-sm",
              value: this.state.fields.freightBillingInfo,
              onChange: this.handleChange,
            }}
            fieldRequired={false}
          />
        </Col>
        <Col
          sm={8}
          className="align-self-center"
          style={{ textAlign: "justify" }}
        >
          <div className="text-primary font-size-xs small">
            Enter the third-party billing account for Fedex or UPS if the
            recipient is paying shipping charges
          </div>
        </Col>
      </Row>
    );
  };
  build_label_list = () => {
    if (this.state.fields.action === "copy") {
      return <></>;
    } else if (this.state.userfields.bSuperUser) {
      //Super User
      return (
        <Col sm={4}>
          <Form.Group>
            <Form.Label>Select Label</Form.Label>
            <Form.Control
              as="select"
              className="form-control form-control-sm"
              id="label_list"
              name="label_list"
              value={this.state.selectfields.label_list}
              onChange={this.handleSelectChange}
            >
              <option value="Please Select">Please Select</option>
              {this.state.userfields.SuperUserRecordlabel.map((prop, key) => {
                return <option value={prop["id"]}>{prop["name"]}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>
      );
    } else if (
      this.state.userfields.bExecutive ||
      sessionStorage.getItem("user_label_id") === "SPM"
    ) {
      return (
        <Col sm={4}>
          <FormLabelField
            properties={{
              label: "Label",
              id: "label",
              name: "label",
              className: "form-control form-control-sm",
              value: "All Labels",
            }}
          />
        </Col>
      );
    } else {
      //Regular User
      return (
        <Col sm={4}>
          <FormLabelField
            properties={{
              label: "Label",
              id: "label",
              name: "label",
              className: "form-control form-control-sm",
              value: this.state.fields.labelName,
            }}
          />
        </Col>
      );
    }
  };
  handleCheckChange = (e: any) => {
    try {
      let checkfields = this.state.checkfields;
      checkfields[e.currentTarget.name] = !this.state.checkfields.prepaid;
      this.setState({
        checkfields,
      });
      if (checkfields["prepaid"]) {
        alert(
          "Copies of all checks for Pre-Paid order must be sent to Fishers Credit Department prior to shipment of product.  The order will not be released until copies of checks have been received."
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleOptionChange = (e: any) => {
    let fields = this.state.fields;
    let optionfields = this.state.optionfields;
    let currentTargetId = e.currentTarget.id;
    fields["cleanBI"] = false;
    if (currentTargetId === "Name") {
      optionfields["name"] = !this.state.optionfields.name;
      optionfields["city"] = false;
      optionfields["state"] = false;
      optionfields["company"] = false;
      fields["lblSearchContact"] = e.currentTarget.id;
    } else if (currentTargetId === "City") {
      optionfields["city"] = !this.state.optionfields.city;
      optionfields["name"] = false;
      optionfields["state"] = false;
      optionfields["company"] = false;
      fields["lblSearchContact"] = e.currentTarget.id;
    } else if (currentTargetId === "Company") {
      optionfields["company"] = !this.state.optionfields.company;
      optionfields["name"] = false;
      optionfields["city"] = false;
      optionfields["state"] = false;
      fields["lblSearchContact"] = e.currentTarget.id;
    } else if (currentTargetId === "State") {
      optionfields["state"] = !this.state.optionfields.state;
      optionfields["name"] = false;
      optionfields["city"] = false;
      optionfields["company"] = false;
      fields["lblSearchContact"] = e.currentTarget.id;
    } else if (currentTargetId === "Marked") {
      optionfields["marked"] = !this.state.optionfields.marked;
      optionfields["clean"] = false;
      if (fields["orderType"] === GlobalVar.BI) {
        optionfields["marked_56"] = false;
        optionfields["clean_56"] = false;
        optionfields["marked_59"] = false;
        optionfields["clean_59"] = false;

        this.bCleanOrderFldsDisplay(true);
      }
    } else if (currentTargetId === "Clean") {
      optionfields["clean"] = !this.state.optionfields.clean;
      optionfields["marked"] = false;
      if (fields["orderType"] === GlobalVar.BI) {
        optionfields["marked_56"] = false;
        optionfields["clean_56"] = false;
        optionfields["marked_59"] = false;
        optionfields["clean_59"] = false;
        fields["cleanBI"] = true;
        alert(
          "If discount does not equal 40%, your order will be forwarded to EVP & CFO of North America for Approval."
        );

        this.bCleanOrderFldsDisplay(false);
      }
    } else if (currentTargetId === "Marked_56") {
      optionfields["marked"] = false;
      optionfields["clean"] = false;
      optionfields["marked_56"] = !this.state.optionfields.marked_56;
      optionfields["clean_56"] = false;
      optionfields["marked_59"] = false;
      optionfields["clean_59"] = false;

      this.bCleanOrderFldsDisplay(true);
    } else if (currentTargetId === "Clean_56") {
      optionfields["marked"] = false;
      optionfields["clean"] = false;
      optionfields["marked_56"] = false;
      optionfields["clean_56"] = !this.state.optionfields.clean_56;
      optionfields["marked_59"] = false;
      optionfields["clean_59"] = false;
      fields["cleanBI"] = true;
      alert(
        "If discount is greater than 15%, your order will be forwarded to EVP & CFO of North America for Approval."
      );

      this.bCleanOrderFldsDisplay(false);
    } else if (currentTargetId === "Marked_59") {
      optionfields["marked"] = false;
      optionfields["clean"] = false;
      optionfields["marked_56"] = false;
      optionfields["clean_56"] = false;
      optionfields["marked_59"] = !this.state.optionfields.marked_59;
      optionfields["clean_59"] = false;

      this.bCleanOrderFldsDisplay(true);
    } else if (currentTargetId === "Clean_59") {
      optionfields["marked"] = false;
      optionfields["clean"] = false;
      optionfields["marked_56"] = false;
      optionfields["clean_56"] = false;
      optionfields["marked_59"] = false;
      optionfields["clean_59"] = !this.state.optionfields.clean_59;
      fields["cleanBI"] = true;
      alert(
        "If discount does not equal 30%, your order will be forwarded to EVP & CFO of North America for Approval."
      );

      this.bCleanOrderFldsDisplay(false);
    } else if (currentTargetId === "locatedYes") {
      this.clearError();
      optionfields["locatedYes"] = !this.state.optionfields.locatedYes;
      optionfields["locatedNo"] = false;
    } else if (currentTargetId === "locatedNo") {
      this.clearError();
      optionfields["locatedNo"] = !this.state.optionfields.locatedNo;
      optionfields["locatedYes"] = false;
    } else if (currentTargetId === "private") {
      optionfields["private"] = !this.state.optionfields.private;
      optionfields["public"] = false;
    } else if (currentTargetId === "public") {
      optionfields["public"] = !this.state.optionfields.public;
      optionfields["private"] = false;
    } else {
      optionfields[e.currentTarget.name] = e.currentTarget.value;
    }
    this.setState({
      fields,
      optionfields,
    });
  };
  handleOptionClick = (e: any) => {
    if (this.state.fields.orderType === GlobalVar.FG) {
      if (this.state.userfields.sOrderLabelID !== "DRW") {
        alert("This Order will be forwarded to Clean Approval");
        //alert('Order for Clean, Full-length Commercial Albums will be forwarded to Chuck Ciongoli for his additional approval.');
      }
    } else if (this.state.fields.orderType === GlobalVar.NT) {
      if (this.state.userfields.sOrderLabelID !== "DRW") {
        alert(
          "Order for Clean, Full-length Commercial Albums will be forwarded to CFO for his additional approval."
        );
      }
    }
  };
  handleOnBlurValidateQty = (key, e: any) => {
    let fields = this.state.fields;
    if (fields["productButton"][key] === "Delete") {
      let qty = "";
      qty = e.currentTarget.value;
      const target = e.currentTarget;
      target.style.border = this.defaultBorder;
      //validation allow only dot and numeric values.
      var regex = new RegExp("^[0-9]*$");
      if (qty.trim() === "") {
        alert("You must enter the quantity before you can complete an order.");
        this.setFocus(target);
        target.style.border = this.redBorder;
        return false;
      }
      if (!regex.test(qty)) {
        alert(
          "You have entered an invalid quantity. Please enter valid quantity"
        );
        this.setFocus(target);
        target.style.border = this.redBorder;
        return false;
      }
      if (parseInt(qty) < 1) {
        alert(
          "You appear to have a quantity set to less than 1.  Please enter a quantity greater than 0 or click the Delete button to delete that item."
        );
        this.setFocus(target);
        target.style.border = this.redBorder;
        return false;
      }
    }
    /*if (qty.length > 0 && parseInt(qty)!=undefined)
        {
            if (parseInt(qty)<1 ) {
                alert('You appear to have a quantity set to less than 1.  Please enter a quantity greater than 0 or click the Delete button to delete that item.');
                this.setFocus(target);
                target.style.border=this.redBorder;
            }
            else
            {
                target.style.border=this.defaultBorder;
            }
        }
        else {
            alert('You appear to have a quantity set to less than 1.  Please enter a quantity greater than 0 or click the Delete button to delete that item.');
            this.setFocus(target);
            target.style.border=this.redBorder;
        }*/
  };

  handleImport= ( e: any) => {
    let fields = this.state.fields;
    //let userfields = this.state.userfields;
    const files = e.target.files;
    var  data;    
    let modal = this.state.loading;
    let loading = this.state.loading;
    modal["modalAlertShow"] = true;
    this.setState({ modal });
  
    if ((this.state.userfields.bSuperUser) && (this.state.selectfields.label_list =="Please Select" ))
    {
      modal["modalAlertShow"] = false;
      this.setState({ modal });
      alert("You may not search for product until you choose a label.");
    }
    else
    {    
    if (files.length) {
     // alert(files.length);
            TableAllSelect.clearAllMultiArr(); 
            const file = files[0];
            const reader = new FileReader();  
            var subitem ;
            let items1="";                
            reader.onload = (e: any) => {
                const wb = read(e.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                  var rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                 // data = JSON.parse(JSON.stringify(rows));
                // console.log(rows);
                 // console.log(JSON.parse(JSON.stringify(utils.sheet_to_json(wb.Sheets[sheets[0]]))));        
                 var data1  =   JSON.parse(JSON.stringify(utils.sheet_to_json(wb.Sheets[sheets[0]])));  
                 // var data1 =  fields["upcList"] ; 
                 
                  data1.map((items) => {
                                              
                    subitem = items.UPC
                                 
                    items1 = items1 + subitem + ", " 
                                  
                    
                  });

                  var uid = sessionStorage.getItem("uid")!;
                 // var labelId = sessionStorage.getItem("user_label_id");
                 let userfields = this.state.userfields;
                 var labelId = userfields["sOrderLabelID"]
                  let fields = this.state.fields;
                 // let userfields = this.state.userfields;
                  let loading = this.state.loading;
                  let sWhere: string = "";
                  //console.log(this.state.userfields.bSuperUser)   
                  //console.log(this.state.userfields.bExecutive)   
                  // console.log( uid)
                  var data = {};
                  data = {
                    recordLabelId: labelId,
                  };

                  let createOrderController = new CreateOrderController();
                  createOrderController
                    .getReportingCo(data)
                    .then((response) => {
                      let sLabels = response.sLabels;

                      if (sLabels !== "") {
                        if (
                          labelId === "CN" ||
                          labelId === "DIS" ||
                          labelId === "AST" ||
                          labelId === "HBN" ||
                          labelId === "CAP" ||
                          labelId === "HAR" ||
                          labelId === "VIR" ||
                          labelId === "WEL" ||
                          labelId === "ATO"
                        ) {
                          sLabels = "(reportingunit in (" + sLabels + "))";
                        } else {
                          sLabels = "(RecordLabel_ID in ('" + labelId + "'," + sLabels + "))";
                        }
                      } else {
                        sLabels = "(RecordLabel_ID='" + labelId + "')";
                      }
                      //Super user and Regular User, not for executive user
                      if (!userfields["bExecutive"]) {
                        //if UME user, force catalog product search
                        if (sessionStorage.getItem("user_label_id") === "SPM") {
                          if (sWhere !== "") {
                            sWhere = sWhere + " AND ";
                          }
                          sWhere = sWhere + " (musicline = '1520' or " + sLabels + ")";
                        } else if (
                          sessionStorage.getItem("user_label_id") === "UMD" &&
                          userfields["bSuperUser"]
                        ) {
                          //UMVD Super user
                          if (labelId === "SPM") {
                            //force catalog product search for UME record label
                            if (sWhere !== "") {
                              sWhere = sWhere + " AND ";
                            }
                            sWhere =
                              sWhere + " (musicline = '1520' or RecordLabel_ID in ('SPM'))";
                          }
                          //****Comments the code not displaying option 'catalog' and 'label' for super user****
                          ////As per Business request, catalog relevant details has been ignored.
                          //elseif (sSearchType === "catalog"){ //'force label product search for selected record label
                          //    sWhere = sWhere + " musicline = '1520' and " + sLabels
                          //}
                          //else{
                          //    sWhere = sWhere + " musicline <> '1520' and " + sLabels
                          //}
                          if (sWhere !== "") {
                            sWhere = sWhere + " AND ";
                          }
                          sWhere = sWhere + sLabels;
                        } else if (
                          sessionStorage.getItem("user_label_id") !== "UMD" &&
                          userfields["bSuperUser"]
                        ) {
                          //Super user who is not UMVD
                          if (sWhere !== "") {
                            sWhere = sWhere + " AND ";
                          }
                          if (labelId === "SPM") {
                            //force catalog product search for UME record label
                            sWhere =
                              sWhere + " (musicline = '1520' or RecordLabel_ID in ('SPM'))";
                          } else {
                            //force catalog product search for selected record label
                            sWhere = sWhere + sLabels;
                          }
                        } else {
                          //force catalog product search for users assigned record label
                          if (sWhere !== "") {
                            sWhere = sWhere + " AND ";
                          }
                          sWhere = sWhere + sLabels;
                        }
                      }

                      //'Filter results for Buena Vista Music.
                      if (sessionStorage.getItem("user_label_id") === "BVM") {
                        sWhere = sWhere + " AND RecordLabel_ID = 'BVM'";
                      } else {
                        sWhere = sWhere + " AND RecordLabel_ID <> 'BVM'";
                      }

                      data = {
                        sWhere: sWhere,
                        UPC: items1,
                        labelId: this.state.selectfields.label_list,
                        userId : uid,
                        superUser : this.state.userfields.bSuperUser,
                        executiveUser : this.state.userfields.bExecutive   
                         
                       };

                       createOrderController
                       .getProductSearchByUPCErrMsg(data)
                       .then((jsonAllProductsErrMsg) => {
                         this.setState({
                           ProductsErr: JSON.parse(JSON.stringify(jsonAllProductsErrMsg)),
                         });
                        })
                            console.log('Length',this.state.ProductsErr.length); 
      
                               
                       createOrderController
                          .getProductSearchByUPC(data)
                          .then((jsonAllProducts) => {
                            this.setState({
                              Products: JSON.parse(JSON.stringify(jsonAllProducts)),
                            });
      
                            this.setState({ key: 0 });
                            let modal = this.state.modal;
                            let errors = this.state.errors;
                          if (this.state.Products.length > 0)
                          {
                            
                           // loading["loading"] = true;
                            //this.setState({ loading });
                            //  this.setState({ key: row });
                            fields["fileUpload"] = true;
                            fields["upcLen"] = this.state.Products.length;
                            modal["modalShow"] = true;
                            modal["modalAlertShow"] = false;
                            modal["modalTitle"] = "Select Product";
                            modal["modalBody"] = false;
                            errors["errmsg"] ="";
                            // fields["productButton"][row] = "Search";
                           //  loading["loading"] = false;
                            this.setState({ modal, fields, errors });
                          }
                          else{
                            modal["modalAlertShow"] = false;
                            modal["modalBody"] = false;
                            errors["errmsg"] = "Please check data and reprocess the file" ;
                          
                          this.setState({ errors });
                            this.setState({ modal, fields });
                          }
                            
                          })
                          .catch((error) => {
                            loading["loading"] = false;
                            this.setState({ loading });
                            console.error("There was an error in fetch products..., " + error);
                          });     
                     

                    })
                                                      

                }
            }
            e.target.value = null;
                      
           reader.readAsArrayBuffer(file);
    }
  }
     
    
  }

  handleOnBlurValidateString = (key, e: any) => {
    let fields = this.state.fields;
    if (fields["productButton"][key] === "Delete") {
      let artistStoreName = "";
      artistStoreName = e.currentTarget.value;
      const target = e.currentTarget;
      target.style.border = this.defaultBorder;
      if (artistStoreName === undefined) {
        alert(
          "You must enter the artist store name before you can complete an order."
        );
        return false;
      } else {
        //validation artist Store Name allow only Alphabet, number, dot, slash, back slash and space
        var regex = new RegExp("^[a-zA-Z0-9 /,.\\\\s]*$");
        if (artistStoreName.trim() === "") {
          alert(
            "You must enter the Artist Store Name before you can complete an order."
          );
          this.setFocus(target);
          target.style.border = this.redBorder;
          return false;
        } else if (!regex.test(artistStoreName)) {
          alert(
            "You have entered an invalid special characters such as " +
              regex +
              ". Please enter valid Artist Store Name"
          );
          target.style.border = this.redBorder;
          return false;
        }
      }
    }
  };
  handleOnBlurValidateDate = (key, e: any) => {
    let fields = this.state.fields;
    if (fields["productButton"][key] === "Delete") {
      let tourDate = "";
      tourDate = e.currentTarget.value;
      const target = e.currentTarget;
      target.style.border = this.defaultBorder;
      if (tourDate === undefined) {
        alert("You must enter the tour date before you can complete an order.");
        return false;
      } else {
        //validation formated date MM/dd/yyyy.
        var regex = new RegExp(
          "^(0?[1-9]|1[0-2])/(0?[1-9]|1[0-9]|2[0-9]|3[0-1])/([0-9]{4})$"
        );
        if (tourDate.trim() === "") {
          alert(
            "You must enter the tour date before you can complete an order."
          );
          this.setFocus(target);
          target.style.border = this.redBorder;
          return false;
        } else if (!regex.test(tourDate)) {
          alert("You have entered an invalid date. Please enter valid date");
          target.style.border = this.redBorder;
          return false;
        }
      }
    }
  };

  exportTextChange = () => {
    return (
      <Col sm={4}>
        <FormInputs
          properties={{
            id: "specialIns",
            label: "Comments or special instructions for Depot (optional):",
            type: "text",
            name: "specialIns",
            className: "form-control input-sm form-control-sm",
            maxlength: 60,
            value: this.state.fields.specialIns,
            onChange: this.handleChange,
          }}
          fieldRequired={false}
        />
      </Col>
    );
  };

  handlePriceChange = (i, e: any) => {
    let fields = this.state.fields;
    fields[e.currentTarget.name][i] = e.currentTarget.value;
    if (fields["productButton"][i] === "Search") {
      if (e.currentTarget.name === "price") {
        if (fields["price"][i] === undefined) {
          fields["price"][i] = "";
          //this.setState({ fields });
        }
        if (fields["price"][i] !== "") {
          //clear index qty if no search begin
          fields[e.currentTarget.name][i] = "";
          alert("Please search product first, before entering Price");
        }
        this.setState({ fields });
        return false;
      }
    } else {
      this.ValidatePrice(e, i);
    }
  };
  ValidatePrice(objPrice, row) {
    let fields = this.state.fields;
    var price = "";
    var iPos = -1;
    price = objPrice.currentTarget.value;
    const target = objPrice.currentTarget;
    var salesTypeClean = this.CheckSaleType();
    if (
      fields["productButton"][row] === "Delete" ||
      this.state.fields.action === "copy"
    ) {
      if (price.length > 0 && price !== "NaN") {
        var float_price = parseFloat(price);
        if (float_price < 0.01) {
          alert(
            "You appear to have a price set to less than $.01.  Please enter a price greater than 0 or click the Delete button to delete that item."
          );
          //objPrice.focus();
          this.setFocus(target);
          target.style.border = this.redBorder;
          return false;
        }
        iPos = price.indexOf(".");
        if (iPos > -1) {
          price = price.substring(iPos, price.length);
          if (price.length > 4) {
            alert(
              "You have entered an invalid price. Please enter up to 3 digits after the decimal."
            );
            //objPrice.focus();
            this.setFocus(target);
            target.style.border = this.redBorder;
            return false;
          }
        }
        //validation allow only dot and numeric values.
        var regex = new RegExp("^[0-9]*.?[0-9]*$");
        if (!regex.test(price) && this.state.fields.action !== "copy") {
          alert("You have entered an invalid price. Please enter valid price");
          this.setFocus(target);
          target.style.border = this.redBorder;
          return false;
        }
        if (
          salesTypeClean == 1 ||
          salesTypeClean == 3 ||
          salesTypeClean == 5 ||
          salesTypeClean == 9
        ) {
          var price_format = float_price.toFixed(3);
          var cost = fields["cost"][row];
          if (cost !== "NaN" && parseFloat(price_format) > 0.0) {
            var float_cost = parseFloat(cost).toFixed(3);

            if (parseFloat(float_cost) > 0) {
              if (parseFloat(price_format) <= parseFloat(float_cost)) {
                this.dispDiscountPerc(float_price, row, fields);
              } else {
                fields["discount"][row] = "";
                fields["flag"][row] = true;
                //document.all('td_apprvlRqrd'+row).style.display='block';
              }
            } else {
              fields["discount"][row] = "";
              fields["flag"][row] = false;
              //document.all('td_apprvlRqrd'+row).style.display='none';
            }
          }
        }
        this.setState({ fields });
        target.style.border = this.defaultBorder;
      } else {
        alert(
          "You appear to have a price set to less than $.01.  Please enter a price greater than 0 or click the Delete button to delete that item."
        );
        target.style.border = this.redBorder;
        //objPrice.focus();
        //return false;
      }
    }
  }
  bCleanOrderFldsDisplayTB = (i) => {
    let fields = this.state.fields;
    var MAX_PRODUCTS = fields["maxKey"];
    if (fields["orderType"] === GlobalVar.TB) {
      if (MAX_PRODUCTS === 0 && fields["productButton"][0] === "Search") {
        return false;
      }

      let cost: string = fields["cost"][i];
      if (cost !== "NaN") {
        let float_cost = parseFloat(cost);
        let salesType = this.CheckSaleType();
        let price: string = BillableScript.CalculatedDiscountPrice(
          float_cost.toFixed(3),
          salesType
        );
        if (price !== "NaN") {
          let float_price = parseFloat(price);
          let price_format = float_price.toFixed(3);
          if (parseFloat(price_format) > 0) {
            if (parseFloat(price_format) > 0.01) {
              if (float_cost > 1) {
                fields["price"][i] = float_price.toFixed(2);
              } else {
                fields["price"][i] = price_format;
              }
            } else {
              fields["price"][i] = "0.010";
              float_price = parseFloat("0.010");
              price_format = float_price.toFixed(3);
            }
          } else {
            fields["price"][i] = "";
            fields["discount"][i] = "";
          }

          if (cost !== "NaN" && parseFloat(price_format) > 0.0) {
            if (float_cost > 0) {
              if (parseFloat(price_format) <= float_cost) {
                this.dispDiscountPerc(float_price, i, fields);
              } else {
                fields["discount"][i] = "";
              }
            } else {
              fields["discount"][i] = "";
            }
          }
        }
      }
    }
    return true;
  };

  bCleanOrderFldsDisplay = (isMarked) => {
    let fields = this.state.fields;
    var MAX_PRODUCTS = fields["maxKey"];
    if (
      fields["orderType"] === GlobalVar.BI ||
      fields["orderType"] === GlobalVar.TB
    ) {
      if (MAX_PRODUCTS === 0 && fields["productButton"][0] === "Search") {
        return false;
      }
      if (isMarked) {
        for (var i = 0; i <= MAX_PRODUCTS; i++) {
          var priceLength = fields["price"][i];
          if (priceLength.length > 0) {
            fields["price"][i] = "1.00";
          }
        }
      } else {
        for (var i = 0; i <= MAX_PRODUCTS; i++) {
          let cost: string = fields["cost"][i];
          if (cost !== "NaN") {
            let float_cost = parseFloat(cost);
            let salesType = this.CheckSaleType();
            let price: string = BillableScript.CalculatedDiscountPrice(
              float_cost.toFixed(3),
              salesType
            );
            if (price !== "NaN") {
              let float_price = parseFloat(price);
              let price_format = float_price.toFixed(3);
              if (parseFloat(price_format) > 0) {
                if (parseFloat(price_format) > 0.01) {
                  if (float_cost > 1) {
                    fields["price"][i] = float_price.toFixed(2);
                  } else {
                    fields["price"][i] = price_format;
                  }
                } else {
                  fields["price"][i] = "0.010";
                  float_price = parseFloat("0.010");
                  price_format = float_price.toFixed(3);
                }
              } else {
                fields["price"][i] = "";
                fields["discount"][i] = "";
              }

              if (cost !== "NaN" && parseFloat(price_format) > 0.0) {
                if (float_cost > 0) {
                  if (parseFloat(price_format) <= float_cost) {
                    this.dispDiscountPerc(float_price, i, fields);
                  } else {
                    fields["discount"][i] = "";
                  }
                } else {
                  fields["discount"][i] = "";
                }
              }
            }
          }
        }
      }
    }
    return true;
  };
  dispDiscountPerc = (txtValue, intRowId, fields) => {
    var CLEAN_54_DISCOUNT_THRESHOLD =
      BillableScript.CLEAN_54_DISCOUNT_THRESHOLD;
    var CLEAN_56_DISCOUNT_THRESHOLD =
      BillableScript.CLEAN_56_DISCOUNT_THRESHOLD;
    var CLEAN_59_DISCOUNT_THRESHOLD =
      BillableScript.CLEAN_59_DISCOUNT_THRESHOLD;
    var price = txtValue;
    var saletype = this.CheckSaleType();
    switch (saletype) {
      case 1:
        var cost1 = fields["cost"][intRowId];
        var discount1 = BillableScript.CalculatePercent(cost1, price);
        var parsediscount1 = parseFloat(discount1);
        fields["discount"][intRowId] = (parsediscount1 * 100).toFixed(0); // modified by senthil
        if (parsediscount1 != CLEAN_54_DISCOUNT_THRESHOLD) {
          fields["flag"][intRowId] = true;
          //document.all('td_apprvlRqrd'+intRowId).style.display='block';
        } else {
          fields["flag"][intRowId] = false;
          //document.all('td_apprvlRqrd'+intRowId).style.display='none';
        }
        break;
      case 9:
      case 3:
        var cost2 = fields["cost"][intRowId];
        var discount2 = BillableScript.CalculatePercent(cost2, price);
        var parsediscount2 = parseFloat(discount2);
        fields["discount"][intRowId] = (parsediscount2 * 100).toFixed(0); // modified by senthil
        if (parsediscount2 > CLEAN_56_DISCOUNT_THRESHOLD) {
          fields["flag"][intRowId] = true;
          //document.all('td_apprvlRqrd'+intRowId).style.display='block';
        } else {
          fields["flag"][intRowId] = false;
          //document.all('td_apprvlRqrd'+intRowId).style.display='none';
        }
        break;
      case 5:
        var cost3 = fields["cost"][intRowId];
        var discount3 = BillableScript.CalculatePercent(cost3, price);
        var parsediscount3 = parseFloat(discount3);
        fields["discount"][intRowId] = (parsediscount3 * 100).toFixed(0); // modified by senthil
        if (parsediscount3 != CLEAN_59_DISCOUNT_THRESHOLD) {
          fields["flag"][intRowId] = true;
          //document.all('td_apprvlRqrd'+intRowId).style.display='block';
        } else {
          fields["flag"][intRowId] = false;
          //document.all('td_apprvlRqrd'+intRowId).style.display='none';
        }
    }
  };
  CheckSaleType = () => {
    var myOption = -1;
    if (this.state.fields.orderType === GlobalVar.TB) {
      if (this.state.userfields.bCleanPrompt) {
        myOption = 9;
      }
    } else {
      var thisform = document.forms[0];
      for (var i = thisform.sales_type.length - 1; i > -1; i--) {
        if (thisform.sales_type[i].checked) {
          myOption = i;
          i = -1;
        }
      }
    }
    return myOption;
  };
  enableSearchResult = (e: any) => {
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let optionfields = this.state.optionfields;
    fields["shipName"] = "";
    fields["shipAddress"] = "";
    fields["shipPhone"] = "";
    fields["lblSearchContact"] = "Name";
    fields["searchContact"] = "";
    selectfields["choice"] = "";
    optionfields["name"] = true;
    optionfields["company"] = false;
    optionfields["city"] = false;
    optionfields["state"] = false;
    selectfields["state"] = "Select A State";
    this.setState({
      fields,
      selectfields,
      optionfields,
      resetContactView: false,
    });
    e.persist();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  };

  SelectAllContact = () => {
    if (
      this.state.optionfields.state === false ||
      this.state.optionfields.name === true ||
      this.state.optionfields.city === true ||
      this.state.optionfields.company === true
    ) {
      return (
        <FormInputSearch
          properties={{
            id: "searchContact",
            label: this.state.fields.lblSearchContact,
            type: "text",
            name: "searchContact",
            className: "form-control form-control-sm",
            value: this.state.fields.searchContact,
            onChange: this.handleChange,
          }}
          buttonProperties={{
            id: "search",
            name: "search",
            className: "form-control input-sm",
            onClick: this.handleSearchClick,
          }}
          fieldRequired={false}
          loading={this.state.loading.loadingSearchContact}
        />
      );
    } else {
      return (
        <FormSelectSearch
          properties={{
            label: this.state.fields.lblSearchContact,
            id: "searchstate",
            name: "searchstate",
            value: this.state.selectfields.searchstate,
            className: "form-control form-control-sm",
            onChange: this.handleSelectChange,
          }}
          buttonProperties={{
            id: "search",
            name: "search",
            className: "form-control input-sm",
            onClick: this.handleSearchClick,
          }}
          fieldRequired={false}
          defaultOption="Select A State"
          fillOption={this.state.State}
          loading={this.state.loading.loadingSearchContact}
        />
      );
    }
  };

  searchResult = () => {
    if (this.state.resetContactView) {
      return (
        <Form.Group>
          <Form.Label>Search Result</Form.Label>
          <Form.Control
            as="select"
            multiple={true}
            id="allContactId"
            name="allContactId"
            value={this.state.selectfields.allContactId}
            className="form-control form-control-sm"
            onChange={this.handleSelectChange}
          >
            <option value="0">Select All Contact</option>
            {this.state.AllContact.map((prop, key) => {
              return (
                <option
                  value={
                    prop["id"] +
                    "~~" +
                    prop["addressLineOne"] +
                    "~~" +
                    prop["addressLineTwo"] +
                    "~~" +
                    prop["zip"] +
                    "~~" +
                    prop["phone"] +
                    "~~" +
                    prop["country"]
                  }
                >
                  {prop["name"] +
                    "..." +
                    prop["shipToName"] +
                    "..." +
                    prop["city"] +
                    "..." +
                    prop["state"]}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      );
    } else {
      return <></>;
    }
  };

  formControl = (key) => {
    //
    let fields = this.state.fields;
    if (this.state.startKey) {
      if (fields["productButton"][key] !== "wait.....") {
        fields["productButton"][key] = "Search";
      }
      //fields['productButton'][key]='Search';
      return (
        <tr>
          <td style={{ padding: "0px", width: "50px" }}>
            <Button
              style={{ height: "auto" }}
              key={key}
              size="sm"
              name="search"
              onClick={(e) => this.handleModalShow(key, e)}
            >
              {this.state.fields.productButton[key]}
            </Button>
          </td>
          <td style={{ padding: "0px", width: "200px" }}>
            <FormControl
              style={{ height: "auto" }}
              size="sm"
              id={"artist" + key}
              type="text"
              name="artist"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.artist[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "300px" }}>
            <FormControl
              style={{ height: "auto" }}
              size="sm"
              id={"title" + key}
              type="text"
              name="title"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.title[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "200px" }}>
            <FormControl
              style={{ height: "auto" }}
              size="sm"
              id={"selection" + key}
              type="text"
              name="selection"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.selection[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "150px" }}>
            <FormControl
              style={{ height: "auto" }}
              size="sm"
              id={"UPC" + key}
              type="text"
              name="UPC"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.UPC[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "50px" }}>
            <FormLabel style={{ height: "auto" }} id={"confiq" + key} key={key}>
              {" "}
              {this.state.fields.config[key]}{" "}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "50px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"unitsPerSet" + key}
              key={key}
            >
              {this.state.fields.unitsPerSet[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "50px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"rlsDate" + key}
              key={key}
            >
              {" "}
              {this.state.fields.rlsDate[key]}{" "}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "100px" }}>
            <FormControl
              style={{ height: "auto" }}
              size="sm"
              id={"qty" + key}
              type="text"
              name="qty"
              maxLength={5}
              className="form-control input-sm"
              key={key}
              value={this.state.fields.qty[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          {this.state.fields.orderType === GlobalVar.BI ||
          this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto", textAlign: "right" }}
                size="sm"
                id={"price" + key}
                type="text"
                name="price"
                maxLength={7}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.price[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0px", width: "50px" }}>
              <FormLabel
                style={{ height: "auto", textAlign: "right" }}
                id={"cost" + key}
                key={key}
              >
                {" "}
                {this.state.fields.cost[key]}{" "}
              </FormLabel>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0px", width: "50px" }}>
              <FormLabel
                style={{ height: "auto", textAlign: "center" }}
                id={"discount" + key}
                key={key}
              >
                {" "}
                {this.state.fields.discount[key]}{" "}
              </FormLabel>
            </td>
          ) : (
            ""
          )}
          {this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto" }}
                size="sm"
                id={"artistStoreName" + key}
                type="text"
                name="artistStoreName"
                maxLength={50}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.artistStoreName[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto" }}
                size="sm"
                id={"tourDate" + key}
                type="text"
                name="tourDate"
                maxLength={10}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.tourDate[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td
              style={{ padding: "0px", width: "50px", textAlign: "center" }}
            ></td>
          ) : (
            ""
          )}
        </tr>
      );
    } else if (
      (key <= this.state.key && this.state.fields.qty[key] !== "") ||
      fields["productButton"][key] === "Delete"
    ) {
      //else if (key <= this.state.key && fields['productButton'][key]!=='Search')
      fields["productButton"][key] = "Delete";
      return (
        <tr>
          <td style={{ padding: "0px", width: "50px" }}>
            <Button
              style={{ height: "auto" }}
              key={key}
              size="sm"
              name="delete"
              onClick={(e) => this.handleDelete(key, e)}
            >
              {this.state.fields.productButton[key]}
            </Button>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "200px" }}>
            <FormLabel style={{ height: "auto" }} id={"artist" + key} key={key}>
              {this.state.fields.artist[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "300px" }}>
            <FormLabel style={{ height: "auto" }} id={"title" + key} key={key}>
              {this.state.fields.title[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "200px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"selection" + key}
              key={key}
            >
              {this.state.fields.selection[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "150px" }}>
            <FormLabel style={{ height: "auto" }} id={"UPC" + key} key={key}>
              {this.state.fields.UPC[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "50px" }}>
            <FormLabel style={{ height: "auto" }} id={"confiq" + key} key={key}>
              {" "}
              {this.state.fields.config[key]}{" "}
            </FormLabel>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "50px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"unitsPerSet" + key}
              key={key}
            >
              {this.state.fields.unitsPerSet[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0 0 0 10px", width: "50px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"rlsDate" + key}
              key={key}
            >
              {" "}
              {this.state.fields.rlsDate[key]}{" "}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "100px" }}>
            <FormControl
              style={{ height: "auto" }}
              size="sm"
              id={"qty" + key}
              type="text"
              name="qty"
              maxLength={5}
              className="form-control input-sm"
              key={key}
              value={this.state.fields.qty[key]}
              onChange={(e) => this.handleFormChange(key, e)}
              onBlur={(e) => this.handleOnBlurValidateQty(key, e)}
            ></FormControl>
          </td>
          {this.state.fields.orderType === GlobalVar.BI ||
          this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto" }}
                size="sm"
                id={"price" + key}
                type="text"
                name="price"
                maxLength={7}
                className="form-control input-sm"
                key={key}
                tabIndex={key}
                value={this.state.fields.price[key]}
                onChange={(e) => this.handleFormChange(key, e)}
                onBlur={(e) => this.ValidatePrice(e, key)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0 0 0 10px", width: "50px" }}>
              <FormLabel
                style={{ height: "auto", textAlign: "right" }}
                id={"cost" + key}
                key={key}
              >
                {" "}
                {this.state.fields.cost[key]}{" "}
              </FormLabel>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0 0 0 10px", width: "50px" }}>
              <FormLabel
                style={{ height: "auto", textAlign: "center" }}
                id={"discount" + key}
                key={key}
              >
                {" "}
                {this.state.fields.discount[key]}{" "}
              </FormLabel>
            </td>
          ) : (
            ""
          )}
          {this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto" }}
                size="sm"
                id={"artistStoreName" + key}
                type="text"
                name="artistStoreName"
                maxLength={50}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.artistStoreName[key]}
                onChange={(e) => this.handleFormChange(key, e)}
                onBlur={(e) => this.handleOnBlurValidateString(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto" }}
                size="sm"
                id={"tourDate" + key}
                type="text"
                name="tourDate"
                maxLength={10}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.tourDate[key]}
                onChange={(e) => this.handleFormChange(key, e)}
                onBlur={(e) => this.handleOnBlurValidateDate(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0px", width: "50px", textAlign: "center" }}>
              {this.state.fields.flag[key] ? (
                <img
                  src={flag}
                  alt="Product discount requires EVP approval"
                  title="Product discount requires EVP approval"
                ></img>
              ) : (
                ""
              )}
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    } else {
      if (
        fields["productButton"][key] !== "wait....." &&
        this.state.fields.artist[key] === ""
      ) {
        fields["productButton"][key] = "Search";
      }
      return (
        <tr>
          <td style={{ padding: "0px", width: "50px" }}>
            <Button
              style={{ height: "auto" }}
              key={key}
              size="sm"
              name="search"
              onClick={(e) => this.handleModalShow(key, e)}
            >
              {this.state.fields.productButton[key]}
            </Button>
          </td>
          <td style={{ padding: "0px", width: "200px" }}>
            <FormControl
              style={{ height: "auto", border: "1px solid #E3E3E3" }}
              size="sm"
              id={"artist" + key}
              type="text"
              name="artist"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.artist[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "300px" }}>
            <FormControl
              style={{ height: "auto", border: "1px solid #E3E3E3" }}
              size="sm"
              id={"title" + key}
              type="text"
              name="title"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.title[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "200px" }}>
            <FormControl
              style={{ height: "auto", border: "1px solid #E3E3E3" }}
              size="sm"
              id={"selection" + key}
              type="text"
              name="selection"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.selection[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "150px" }}>
            <FormControl
              style={{ height: "auto", border: "1px solid #E3E3E3" }}
              size="sm"
              id={"UPC" + key}
              type="text"
              name="UPC"
              className="form-control input-sm"
              key={key}
              value={this.state.fields.UPC[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          <td style={{ padding: "0px", width: "50px" }}>
            <FormLabel style={{ height: "auto" }} id={"confiq" + key} key={key}>
              {" "}
              {this.state.fields.config[key]}{" "}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "50px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"unitsPerSet" + key}
              key={key}
            >
              {this.state.fields.unitsPerSet[key]}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "50px" }}>
            <FormLabel
              style={{ height: "auto" }}
              id={"rlsDate" + key}
              key={key}
            >
              {" "}
              {this.state.fields.rlsDate[key]}{" "}
            </FormLabel>
          </td>
          <td style={{ padding: "0px", width: "100px" }}>
            <FormControl
              style={{ height: "auto", border: "1px solid #E3E3E3" }}
              size="sm"
              id={"qty" + key}
              type="text"
              name="qty"
              maxLength={5}
              className="form-control input-sm"
              key={key}
              value={this.state.fields.qty[key]}
              onChange={(e) => this.handleFormChange(key, e)}
            ></FormControl>
          </td>
          {this.state.fields.orderType === GlobalVar.BI ||
          this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto", border: "1px solid #E3E3E3" }}
                size="sm"
                id={"price" + key}
                type="text"
                name="price"
                maxLength={7}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.price[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0px", width: "50px" }}>
              <FormLabel
                style={{ height: "auto", textAlign: "right" }}
                id={"cost" + key}
                key={key}
              >
                {" "}
                {this.state.fields.cost[key]}{" "}
              </FormLabel>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td style={{ padding: "0px", width: "50px" }}>
              <FormLabel
                style={{ height: "auto", textAlign: "center" }}
                id={"discount" + key}
                key={key}
              >
                {" "}
                {this.state.fields.discount[key]}{" "}
              </FormLabel>
            </td>
          ) : (
            ""
          )}
          {this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto", border: "1px solid #E3E3E3" }}
                size="sm"
                id={"artistStoreName" + key}
                type="text"
                name="artistStoreName"
                maxLength={50}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.artistStoreName[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {this.state.fields.orderType === GlobalVar.TB ? (
            <td style={{ padding: "0px", width: "120px" }}>
              <FormControl
                style={{ height: "auto", border: "1px solid #E3E3E3" }}
                size="sm"
                id={"tourDate" + key}
                type="text"
                name="tourDate"
                maxLength={10}
                className="form-control input-sm"
                key={key}
                value={this.state.fields.tourDate[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          ) : (
            ""
          )}
          {(this.state.fields.orderType === GlobalVar.BI ||
            this.state.fields.orderType === GlobalVar.TB) &&
          this.state.fields.cleanBI ? (
            <td
              style={{ padding: "0px", width: "50px", textAlign: "center" }}
            ></td>
          ) : (
            ""
          )}
        </tr>
      );
    }
    this.setState({ fields });
  };
  salesType = () => {
    if (this.state.fields.orderType === GlobalVar.FG) {
      return (
        <Form.Group>
          <Row
            style={{
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "0.50rem",
            }}
          >
            <Col sm={2} style={{ paddingRight: "0.50rem" }}>
              <Form.Check
                type="radio"
                label={GlobalVar.MARKED_53}
                name="sales_type"
                id="Marked"
                onChange={this.handleOptionChange}
                defaultChecked={this.state.optionfields.marked}
                checked={this.state.optionfields.marked}
                value="0"
              />
            </Col>
            {this.radioCleanPrompt()}
          </Row>
        </Form.Group>
      );
    } else if (this.state.fields.orderType === GlobalVar.NT) {
      return (
        <Form.Group>
          <Row
            style={{
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "0.50rem",
            }}
          >
            <Col sm={2} style={{ paddingRight: "0.50rem" }}>
              <Form.Check
                type="radio"
                label={GlobalVar.MARKED_91}
                name="sales_type"
                id="Marked"
                onChange={this.handleOptionChange}
                defaultChecked={this.state.optionfields.marked}
                checked={this.state.optionfields.marked}
                value="6"
              />
            </Col>
            {this.radioCleanPrompt()}
          </Row>
        </Form.Group>
      );
    } else if (this.state.fields.orderType === GlobalVar.BI) {
      return (
        <Form.Group>
          <Row
            style={{
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "0.50rem",
            }}
          >
            <Col sm={2} style={{ paddingRight: "0.50rem" }}>
              <Form.Check
                type="radio"
                label={GlobalVar.MARKED_54}
                name="sales_type"
                id="Marked"
                onChange={this.handleOptionChange}
                defaultChecked={this.state.optionfields.marked}
                checked={this.state.optionfields.marked}
                value="0"
              />
            </Col>
            {this.radioCleanPrompt()}
            <Col sm={2} style={{ paddingRight: "0.50rem" }}>
              <Form.Check
                type="radio"
                label={GlobalVar.MARKED_56}
                name="sales_type"
                id="Marked_56"
                onChange={this.handleOptionChange}
                defaultChecked={this.state.optionfields.marked_56}
                checked={this.state.optionfields.marked_56}
                value="2"
              />
            </Col>
            {this.radioCleanPrompt_56()}
            <Col sm={2} style={{ paddingRight: "0.50rem" }}>
              <Form.Check
                type="radio"
                label={GlobalVar.MARKED_59}
                name="sales_type"
                id="Marked_59"
                onChange={this.handleOptionChange}
                defaultChecked={this.state.optionfields.marked_59}
                checked={this.state.optionfields.marked_59}
                value="4"
              />
            </Col>
            {this.radioCleanPrompt_59()}
          </Row>
        </Form.Group>
      );
    } else if (this.state.fields.orderType === GlobalVar.TB) {
      return (
        <Form.Group>
          <Row
            style={{
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "0.50rem",
            }}
          >
            {this.radioCleanPrompt()}
            <Col sm={2} style={{ paddingRight: "0.50rem" }}></Col>
          </Row>
        </Form.Group>
      );
    } else if (this.state.fields.orderType === GlobalVar.S89) {
      return (
        <Form.Group>
          <Row
            style={{
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "0.50rem",
            }}
          >
            {this.radioCleanPrompt()}
            <Col sm={2} style={{ paddingRight: "0.50rem" }}></Col>
          </Row>
        </Form.Group>
      );
    }
  };
  orderComplete = () => {
    const {
      loadingOrderComplete,
      loadingSubmitOrder,
      loadingSubmitandPrintOrder,
    } = this.state.loading;
    if (this.state.fields.action === "copy") {
      return (
        <Col sm={8} style={{ textAlign: "center", alignSelf: "center" }}>
          <Button
            disabled={loadingSubmitandPrintOrder}
            name="submitAndPrintOrder"
            style={{ marginRight: "5px" }}
            onClick={(e) => this.handleOnSubmitOrder(e)}
          >
            {loadingSubmitandPrintOrder && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", color: "#3472F7" }}
              />
            )}
            {loadingSubmitandPrintOrder && (
              <span style={{ color: "#3472F7" }}>wait...</span>
            )}
            {!loadingSubmitandPrintOrder && <span>Submit and Print Order</span>}
          </Button>
        </Col>
      );
    } else if (this.state.orderComplete) {
      return (
        <Col sm={8} style={{ textAlign: "center", alignSelf: "center" }}>
          <Button
            style={{ marginRight: "5px" }}
            onClick={() => {
              this.props.history.push("/admin/home");
            }}
          >
            Cancel Order
          </Button>
          <Button
            disabled={loadingSubmitOrder}
            name="submitOrder"
            style={{ marginRight: "5px" }}
            onClick={(e) => this.handleOnSubmitOrder(e)}
          >
            {loadingSubmitOrder && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", color: "#3472F7" }}
              />
            )}
            {loadingSubmitOrder && (
              <span style={{ color: "#3472F7" }}>wait...</span>
            )}
            {!loadingSubmitOrder && <span>Submit Order</span>}
          </Button>
          <Button
            disabled={loadingSubmitandPrintOrder}
            name="submitAndPrintOrder"
            style={{ marginRight: "5px" }}
            onClick={(e) => this.handleOnSubmitOrder(e)}
          >
            {loadingSubmitandPrintOrder && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", color: "#3472F7" }}
              />
            )}
            {loadingSubmitandPrintOrder && (
              <span style={{ color: "#3472F7" }}>wait...</span>
            )}
            {!loadingSubmitandPrintOrder && <span>Submit and Print Order</span>}
          </Button>
        </Col>
      );
    } else {
      return (
        <Col sm={8} style={{ textAlign: "center", alignSelf: "center" }}>
          <Button
            disabled={loadingOrderComplete}
            style={{ marginRight: "5px" }}
            onClick={this.handleOnSubmitOrderComplete}
          >
            {loadingOrderComplete && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", color: "#3472F7" }}
              />
            )}
            {loadingOrderComplete && (
              <span style={{ color: "#3472F7" }}>wait...</span>
            )}
            {!loadingOrderComplete && <span>Order Complete</span>}
          </Button>
        </Col>
      );
    }
  };

  formatReleaseDate = (release_date: string) => {
    var result =
      release_date.substring(0, 2) +
      "/" +
      release_date.substring(4, 2) +
      "/" +
      release_date.substring(8, 4);
    return result;
  };
  RemoveLeadingZeros = (data: string) => {
    var result = data.replace(/^0+/, "");
    return result;
  };
  handleSelectRow = (prop, e: any) => {
    let fields = this.state.fields;
    let optionfields = this.state.optionfields;
    let modal = this.state.modal;
    let { key } = this.state;
    fields["artist"][key] = prop["artist"];
    fields["title"][key] = prop["title"];
    fields["selection"][key] = prop["selectionId"];
    fields["UPC"][key] = prop["upc"];
    fields["config"][key] = prop["configuration"];
    fields["unitsPerSet"][key] = this.RemoveLeadingZeros(prop["unitsToSet"]);
    fields["rlsDate"][key] = this.formatReleaseDate(prop["releaseDate"]);
    fields["qty"][key] = "1";
    fields["cost"][key] = e.currentTarget.cells[0].children["hidCost"].value;
    fields["prodCount"] = fields["prodCount"] + 1;
    //to set maximum selected/filled Row
    if (fields["maxKey"] === 0 || key > fields["maxKey"]) {
      fields["maxKey"] = key;
      this.setState({ key: key, fields });
    } else {
      this.setState({ key: key });
    }
    //if (this.state.fields.orderType==='TB'){
    //    this.bCleanOrderFldsDisplayTB(key);
    //}

    //var hidValue = e.currentTarget.cells[0].children['hidMoratorium'].value;
    //if(hidValue === "Y"){
    //alert("SORRY! This is an exclusive title allowing only specific accounts to order.  Please check with the Label Production team to ensure your account is set up and approved to order.  If approved, the selection will be available to order the next business day in this system.\nThe below Moratorium product is.\n" + prop["selectionId"] + '-' + prop["upc"])
    //}

    //Billable Order
    if (
      this.state.fields.orderType === GlobalVar.BI ||
      this.state.fields.orderType === GlobalVar.TB
    ) {
      this.populateRow(
        key,
        e.currentTarget.cells[0].children["hidCost"].value,
        fields
      );
    }

    //alert(hidValue);
    modal["modalShow"] = false;
    this.setState({
      fields,
      modal,
    });
    this.setState({ startKey: false });
  };

  handleMultipleSelectRow = (e: any) => {
    let { key } = this.state;
    let fields = this.state.fields;
    TableMultiSelect.handleTableRowClick(e);
    fields["multipleRowSelect"] = TableMultiSelect.getMultiArr();
    this.setState({ fields, key });
    //var hidValue = e.currentTarget.cells[0].children['hidMoratorium'].value
  };

  selectProduct = (prop, key) => {
    if (sessionStorage.getItem("multiple_select") === "0") {
      return (
        <tr
          key={key}
          id={"trProduct_" + key}
          onClick={(e) => this.handleSelectRow(prop, e)}
        >
          <td>
            <input
              type="hidden"
              id="hidMoratorium"
              name="hidMoratorium"
              value={prop["moratorium"]}
            />
            <input
              type="hidden"
              id="hidCost"
              name="hidCost"
              value={prop["cost"]}
            />
            {prop["artist"]}
          </td>
          <td>{prop["title"]}</td>
          <td>{prop["selectionId"]}</td>
          <td>{prop["upc"]}</td>
          <td>{prop["configuration"]}</td>
          <td>{this.RemoveLeadingZeros(prop["unitsToSet"])}</td>
          <td>{this.formatReleaseDate(prop["releaseDate"])}</td>
          <td>{prop["RecordLabel_ID"]}</td>
        </tr>
      );
    } else {
      return (
                
        <tr
          key={key}
          id={"trProduct_" + key}
          onMouseDown={ this.state.fields.fileUpload == 1 ?  this.handleOnSubmitAllSelect : this.handleMultipleSelectRow }
        >
        
          <td>
            <input
              type="hidden"
              id="hidMoratorium"
              name="hidMoratorium"
              value={prop["moratorium"]}
            />
            <input
              type="hidden"
              id="hidCost"
              name="hidCost"
              value={prop["cost"]}
            />
            {prop["artist"]}
          </td>
          <td>{prop["title"]}</td>
          <td>{prop["selectionId"]}</td>
          <td>{prop["upc"]}</td>
          <td>{prop["configuration"]}</td>
          <td>{this.RemoveLeadingZeros(prop["unitsToSet"])}</td>
          <td>{this.formatReleaseDate(prop["releaseDate"])}</td>
          <td>{prop["RecordLabel_ID"]}</td>
        </tr>
      );
    }
  };

  showModalBody = () => {
    if (this.state.modal.modalBody) {
      return (
        <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Card style={{ width: "100%", marginBottom: "0px" }}>
            <Card.Body>
              {this.selectAddress()}
              {this.displayAddress()}
            </Card.Body>
          </Card>
        </Container>
      );
    } else {
      return (
        <Container fluid style={{ cursor: "pointer" }}>
          <Row style={{ marginTop: "15px" }} className="small">
            <Col md={12}>
              <div style={{ width: "100%" }}>
                <label style={{ color: "blue" }}>
                  Use Ctrl Key and click for selecting multiple products
                </label>
                <Table  >  
                {this.state.ProductsErr.length !== 0 ? (
                  <tr><td style={{ color: "red" }}>Invalid UPCs in the uploaded file are listed below:</td></tr>
                ) : ''}              
                {this.state.ProductsErr.length !== 0 ? (
                  <tbody >
                  {this.state.ProductsErr.map((prop, key) =>
                      <tr>
                      <td style={{height : "auto"}} className="text-danger font-size-xs small" >
                        {prop["upc"]}
                        </td>
                    </tr>
                  )}
                </tbody>
                
                ): ('')}                                 
                </Table>
                <Table hover id="TableMultiSelect">
                  <thead>
                    <tr>
                      <th>Artist</th>
                      <th>Title</th>
                      <th>Selection_id</th>
                      <th>UPC</th>
                      <th>Configuration</th>
                      <th>UnitsToSet</th>
                      <th>Release_Date</th>
                      <th>RecordLabel_ID</th>
                    </tr>
                  </thead>
                  {this.state.Products.length === 0 ? (
                    <tbody>
                      <tr>
                        <td
                          colSpan={8}
                          align="center"
                          className="text-danger font-size-xs small"
                        >
                          No match for {this.state.fields.noProductMatch} on
                          this label. Please try again.
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {this.state.Products.map((prop, key) =>
                        this.selectProduct(prop, key)
                      )}
                    </tbody>
                  )}
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  selectAddress = () => {
    if (
      this.state.modal.modalAction === "E" &&
      this.state.modal.modalDisplayAddr === false
    ) {
      return (
        <>
          <Row className="small">
            <Col sm={8}>
              <Form.Group>
                <Form.Label>Select</Form.Label>
                <Form.Control
                  as="select"
                  id="editAddrId"
                  name="editAddrId"
                  title={
                    this.state.contactfields.shipName +
                    "," +
                    this.state.contactfields.shipAddress +
                    "," +
                    this.state.selectfields.expCountry
                  }
                  className="form-control form-control-sm"
                  value={this.state.selectfields.editAddrId}
                  onChange={this.handleSelectChange}
                >
                  <option value="0">Select Contact To Edit</option>
                  {this.state.PersonalContact.map((prop, key) => {
                    return (
                      <option
                        title={
                          prop["name"] +
                          "," +
                          prop["addressLineOne"] +
                          "," +
                          prop["addressLineTwo"] +
                          "," +
                          prop["city"] +
                          "," +
                          prop["state"] +
                          "," +
                          prop["zip"] +
                          "," +
                          prop["country"]
                        }
                        value={
                          prop["id"] +
                          "~~" +
                          prop["addressLineOne"] +
                          "~~" +
                          prop["addressLineTwo"] +
                          "~~" +
                          prop["city"] +
                          "~~" +
                          prop["state"] +
                          "~~" +
                          prop["zip"] +
                          "~~" +
                          prop["phone"] +
                          "~~" +
                          prop["contacttype"] +
                          "~~" +
                          prop["domesticcontact"] +
                          "~~" +
                          prop["country"]
                        }
                      >
                        {prop["name"] +
                          "......................................." +
                          prop["shipToName"]}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </>
      );
    }
    if (
      this.state.modal.modalAction === "D" &&
      this.state.modal.modalDisplayAddr === false
    ) {
      return (
        <>
          <Row className="small">
            <Col sm={8}>
              <Form.Group>
                <Form.Label>Select</Form.Label>
                <Form.Control
                  as="select"
                  id="disableAddrId"
                  title={
                    this.state.contactfields.shipName +
                    "," +
                    this.state.contactfields.shipAddress +
                    "," +
                    this.state.selectfields.expCountry
                  }
                  name="disableAddrId"
                  value={this.state.selectfields.disableAddrId}
                  onChange={this.handleSelectChange}
                >
                  <option value="0">Select Contact To Disable</option>
                  {this.state.PersonalContact.map((prop, key) => {
                    return (
                      <option
                        title={
                          prop["name"] +
                          "," +
                          prop["addressLineOne"] +
                          "," +
                          prop["addressLineTwo"] +
                          "," +
                          prop["city"] +
                          "," +
                          prop["state"] +
                          "," +
                          prop["zip"] +
                          "," +
                          prop["country"]
                        }
                        value={
                          prop["id"] +
                          "~~" +
                          prop["addressLineOne"] +
                          "~~" +
                          prop["addressLineTwo"] +
                          "~~" +
                          prop["city"] +
                          "~~" +
                          prop["state"] +
                          "~~" +
                          prop["zip"] +
                          "~~" +
                          prop["phone"] +
                          "~~" +
                          prop["contacttype"] +
                          "~~" +
                          prop["domesticcontact"] +
                          "~~" +
                          prop["country"]
                        }
                      >
                        {prop["name"] +
                          "......................................." +
                          prop["shipToName"]}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  };

  displayAddress = () => {
    if (this.state.modal.modalDisplayAddr) {
      return (
        <div id="divAddr" className="small">
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Row style={{ alignItems: "flex-end", paddingTop: "0.50rem" }}>
                  <Col sm={6}>
                    Is this contact located inside the United States?
                  </Col>
                  <Col sm={1} style={{ paddingRight: "0.50rem" }}>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      name="domesticcontact"
                      id="locatedYes"
                      value="1"
                      onChange={this.handleOptionChange}
                      defaultChecked={this.state.optionfields.locatedYes}
                      disabled={this.state.modal.modalCntlDisabled}
                    />
                  </Col>
                  <Col sm={1} style={{ paddingRight: "0.50rem" }}>
                    <Form.Check
                      type="radio"
                      label="No"
                      name="domesticcontact"
                      id="locatedNo"
                      value="0"
                      onChange={this.handleOptionChange}
                      defaultChecked={this.state.optionfields.locatedNo}
                      disabled={this.state.modal.modalCntlDisabled}
                    />
                  </Col>
                  <Col sm={4}></Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="align-self-center">
              <div className="text-primary font-size-xs small">
                No P.O. Boxes allowed for address. Only english and numeric
                values allowed.
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInputs
                properties={{
                  id: "company",
                  label: "Company",
                  type: "text",
                  name: "company",
                  className: "form-control form-control-sm",
                  maxlength: 30,
                  value: this.state.contactfields.company,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInput,
                  onKeyUp: this.checkAlphaNumericInput,
                }}
                errorMsg={this.state.errors.company}
                fieldRequired={true}
              />
            </Col>
            <Col md={6}></Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInputs
                properties={{
                  id: "name",
                  label: "Name",
                  type: "text",
                  name: "name",
                  className: "form-control form-control-sm",
                  maxlength: 50,
                  value: this.state.contactfields.name,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInput,
                  onKeyUp: this.checkAlphaNumericInput,
                }}
                errorMsg={this.state.errors.name}
                fieldRequired={true}
              />
            </Col>
            <Col sm={6} className="align-self-center small">
              <div className="text-primary font-size-xs small">
                If a reference PO# is needed for billing or paperwork purposes,
                please include on this name line.
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInputs
                properties={{
                  id: "address1",
                  label: "Address 1",
                  type: "text",
                  name: "address1",
                  className: "form-control form-control-sm",
                  maxlength: 30,
                  value: this.state.contactfields.address1,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInput,
                  onKeyUp: this.checkAlphaNumericInput,
                }}
                errorMsg={this.state.errors.address1}
                fieldRequired={true}
              />
            </Col>
            <Col md={6}>
              <FormInputs
                properties={{
                  id: "address2",
                  label: "Address 2",
                  type: "text",
                  name: "address2",
                  className: "form-control form-control-sm",
                  maxlength: 30,
                  value: this.state.contactfields.address2,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInput,
                  onKeyUp: this.checkAlphaNumericInput,
                }}
                errorMsg={this.state.errors.address2}
                fieldRequired={false}
              />
            </Col>
          </Row>
          {this.domesticContact()}
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Row
                  style={{
                    alignItems: "flex-end",
                    paddingLeft: "1rem",
                    paddingTop: "0.50rem",
                  }}
                >
                  <Col sm={2} style={{ paddingRight: "0.50rem" }}>
                    <Form.Check
                      type="radio"
                      label="Personal"
                      name="category"
                      id="private"
                      onChange={this.handleOptionChange}
                      defaultChecked={this.state.optionfields.private}
                      value="C"
                      disabled={this.state.modal.modalCntlDisabled}
                    />
                  </Col>
                  <Col sm={2} style={{ paddingRight: "0.50rem" }}>
                    <Form.Check
                      type="radio"
                      label="Public"
                      name="category"
                      id="public"
                      onChange={this.handleOptionChange}
                      defaultChecked={this.state.optionfields.public}
                      value="P"
                      disabled={this.state.modal.modalCntlDisabled}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  };

  domesticContact = () => {
    if (this.state.optionfields.locatedYes) {
      return (
        <>
          <Row>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "city",
                  label: "City",
                  type: "text",
                  name: "city",
                  className: "form-control form-control-sm",
                  maxlength: 50,
                  value: this.state.contactfields.city,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInputCity,
                  onKeyUp: this.checkAlphaNumericInputCity,
                }}
                errorMsg={this.state.errors.city}
                fieldRequired={true}
              />
            </Col>
            <Col sm={5}>
              <FormSelect
                properties={{
                  label: "State",
                  id: "state",
                  name: "state",
                  className: "form-control form-control-sm",
                  onChange: this.handleSelectChange,
                  value: this.state.selectfields.state,
                  disabled: this.state.modal.modalCntlDisabled,
                }}
                fieldRequired={true}
                defaultOption="Select A State"
                fillOption={this.state.State}
                errorMsg={this.state.errors.state}
              />
            </Col>
            <Col sm={3}>
              <FormInputs
                properties={{
                  id: "zip",
                  label: "Zip",
                  type: "text",
                  name: "zip",
                  className: "form-control form-control-sm",
                  maxlength: 10,
                  value: this.state.contactfields.zip,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkNumericInput,
                  onKeyUp: this.checkNumericInputcheckLengh,
                }}
                errorMsg={this.state.errors.zip}
                fieldRequired={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInputs
                properties={{
                  id: "phone",
                  label: "Phone",
                  type: "text",
                  name: "phone",
                  className: "form-control form-control-sm",
                  maxlength: 35,
                  value: this.state.contactfields.phone,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkNumericInput,
                  onKeyUp: this.checkNumericInput,
                }}
                errorMsg={this.state.errors.phone}
                fieldRequired={false}
              />
            </Col>
            <Col md={6}></Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col sm={4}>
              <FormInputs
                properties={{
                  id: "city",
                  label: "City",
                  type: "text",
                  name: "city",
                  className: "form-control form-control-sm",
                  maxlength: 50,
                  value: this.state.contactfields.city,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInputCity,
                  onKeyUp: this.checkAlphaNumericInputCity,
                }}
                errorMsg={this.state.errors.city}
                fieldRequired={true}
              />
            </Col>
            <Col sm={5}>
              <FormInputs
                properties={{
                  id: "province",
                  label: "Province",
                  type: "text",
                  name: "province",
                  className: "form-control form-control-sm",
                  maxlength: 50,
                  value: this.state.contactfields.province,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkAlphaNumericInput,
                  onKeyUp: this.checkAlphaNumericInput,
                }}
                errorMsg={this.state.errors.province}
                fieldRequired={false}
              />
            </Col>
            <Col sm={3}>
              <FormInputs
                properties={{
                  id: "postal",
                  label: "Postal",
                  type: "text",
                  name: "postal",
                  className: "form-control form-control-sm",
                  maxlength: 10,
                  value: this.state.contactfields.postal,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkNumericInput,
                  onKeyUp: this.checkNumericInput,
                }}
                errorMsg={this.state.errors.postal}
                fieldRequired={true}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormInputs
                properties={{
                  id: "country",
                  label: "Country",
                  type: "text",
                  name: "country",
                  className: "form-control form-control-sm",
                  maxlength: 50,
                  value: this.state.contactfields.country,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  
                }}
                errorMsg={this.state.errors.country}
                fieldRequired={true}
              />
            </Col>
            <Col md={6}>
              <FormInputs
                properties={{
                  id: "phone",
                  label: "Phone",
                  type: "text",
                  name: "phone",
                  className: "form-control form-control-sm",
                  maxlength: 35,
                  value: this.state.contactfields.phone,
                  onChange: this.handleContactChange,
                  disabled: this.state.modal.modalCntlDisabled,
                  onBlur: this.checkNumericInput,
                  onKeyUp: this.checkNumericInput,
                }}
                errorMsg={this.state.errors.phone}
                fieldRequired={false}
              />
            </Col>
          </Row>
        </>
      );
    }
  };

  handleModalAlertShow = () => {
    let modal = this.state.modal;
    modal["modalAlertShow"] = true;
    modal["modalAlertTitle"] = "Enter Value";
    modal["modalAlertErrorMsg"] = "Value is missing";
    this.setState({ modal });
  };

  handleModalAlertClose = (e: any) => {
    let modal = this.state.modal;
    modal["modalAlertShow"] = false;
    this.setState({ modal });
  };

  handleOnSubmitAllSelect = (e: any) => {
    let { key } = this.state;
    let fields = this.state.fields;
    let upcLen = this.state.fields.upcLen;    
    TableAllSelect.handleTableAllRowClick(e,upcLen);
    fields["multipleRowSelect"] = TableAllSelect.getMultiArr();   
    this.setState({ fields, key });


  }
  handleOnSubmitMultipleSelect = (e: any) => {
    
    var hidValue = "N";
    var moratoriumProduct = "";
    let fields = this.state.fields;
    let iRow = this.state.key;
    let maxLimit = 0;
    fields["multipleRowSelect"].map((target) => {
      maxLimit = iRow;
      iRow += 1;
    });

    if (maxLimit > 99) {
      alert("Product selection exceeds maximum limit");
      return false;
    } //Reset the Key
    iRow = this.state.key;

    let optionfields = this.state.optionfields;
    let modal = this.state.modal;
    let { key } = this.state;
    let maxSelectedRow = 0;

    this.setState({ startKey: false });
    fields["multipleRowSelect"].map((target) => {
      fields["artist"][iRow] = target.cells[0].innerText;
      fields["title"][iRow] = target.cells[1].innerText;
      fields["selection"][iRow] = target.cells[2].innerText;
      fields["UPC"][iRow] = target.cells[3].innerText;
      fields["config"][iRow] = target.cells[4].innerText;
      fields["unitsPerSet"][iRow] = target.cells[5].innerText;
      fields["rlsDate"][iRow] = target.cells[6].innerText;
      fields["qty"][iRow] = 1;
      fields["cost"][iRow] = target.cells[0].children["hidCost"].value;
      //if(target.cells[0].children['hidMoratorium'].value === "Y"){
      //hidValue = "Y";
      //if (moratoriumProduct === ''){
      //moratoriumProduct = target.cells[2].innerText + '-' + target.cells[3].innerText;
      //}else{
      //moratoriumProduct = moratoriumProduct + ",\n" + target.cells[2].innerText + '-' + target.cells[3].innerText;
      //}
      //}
      //if (this.state.fields.orderType==='TB'){
      //    this.bCleanOrderFldsDisplayTB(iRow);
      //}
      //Billable Order
      if (
        this.state.fields.orderType === GlobalVar.BI ||
        this.state.fields.orderType === GlobalVar.TB
      ) {
        this.populateRow(
          iRow,
          target.cells[0].children["hidCost"].value,
          fields
        );
      }

      maxSelectedRow = iRow;
      iRow += 1;
      fields["prodCount"] = iRow;
    });
    //to set maximum selected/filled Row
    if (fields["maxKey"] === 0 || maxSelectedRow > fields["maxKey"]) {
      fields["maxKey"] = maxSelectedRow;
      this.setState({ key: maxSelectedRow, fields });
    } else {
      this.setState({ key: maxSelectedRow });
    }

    //if(hidValue === "Y"){
    //alert("SORRY! This is an exclusive title allowing only specific accounts to order.  Please check with the Label Production team to ensure your account is set up and approved to order.  If approved, the selection will be available to order the next business day in this system.\nThe below Moratorium product are.\n" + moratoriumProduct)
    //}

    modal["modalShow"] = false;

    this.setState({ fields, modal, startKey: false });
  };
  //Billable order Populate Row
  populateRow = (row, targetCost, fields) => {
    if (fields["price"][row] !== null) {
      let float_cost;
      let cost;
      let salesType;
      let float_price;
      let price_format;
      if (targetCost !== null) {
        float_cost = parseFloat(targetCost);
        fields["cost"][row] = float_cost.toFixed(2);
        cost = float_cost.toFixed(2);
        salesType = this.CheckSaleType();
        if (
          salesType == 1 ||
          salesType == 3 ||
          salesType == 5 ||
          salesType == 9
        ) {
          float_price = parseFloat(
            BillableScript.CalculatedDiscountPrice(
              float_cost.toFixed(3),
              salesType
            )
          );
          price_format = float_price.toFixed(3);
          if (parseFloat(price_format) > 0) {
            if (parseFloat(price_format) > 0.01) {
              if (parseFloat(float_cost) > 1) {
                fields["price"][row] = float_price.toFixed(2);
              } else {
                fields["price"][row].value = price_format;
              }
            } else {
              fields["price"][row] = "0.010";
              float_price = parseFloat("0.010");
              price_format = float_price.toFixed(3);
            }
          } else {
            fields["price"][row] = "";
          }

          if (cost !== "NaN" && parseFloat(price_format) > 0.0) {
            let float_cost = parseFloat(cost).toFixed(3);

            if (parseFloat(float_cost) > 0) {
              if (parseFloat(price_format) <= parseFloat(float_cost)) {
                this.dispDiscountPerc(float_price, row, fields);
              } else {
                fields["discount"][row] = "";
                fields["flag"][row] = true;
                //document.all('td_apprvlRqrd'+row).style.display='block';
              }
            } else {
              fields["discount"][row] = "";
              fields["flag"][row] = false;
              //document.all('td_apprvlRqrd'+row).style.display='none';
            }
          }
        } else {
          fields["price"][row] = "1.00";
        }
      } else {
        fields["price"][row] = "1.00";
      }
    }
  };
  handleOnSubmitAddress = (e: any) => {
    let loading = this.state.loading;
    loading["loading"] = true;
    this.setState({ loading });

    if (this.state.modal.modalAddrButton === "Disable Contact") {
      this.InsertEditDisableContact();
    } else {
      if (this.ValidateAddress()) {
        let optionfields = this.state.optionfields;
        let selectfields = this.state.selectfields;
        let contactfields = this.state.contactfields;

        //Address Validation

        if (optionfields["locatedYes"]) {
          var addrRetval;
          var data = {
            zip: contactfields["zip"],
            city: contactfields["city"],
            state: selectfields["state"],
          };

          let createOrderController = new CreateOrderController();
          createOrderController
            .getAddressValidation(data)
            .then((response) => {
              addrRetval = response.dbAddress;
              //alert(addrRetval);
           
              
              if (
                addrRetval == "city/state/zip valid" ||
                addrRetval == "ignore"
              ) {
                this.InsertEditDisableContact();
                //alert("city/state/zip valid")
              } else {
                if (addrRetval == "city/state/zip not valid") {
                  alert(
                    "The city/state/zip combination you entered is not valid"
                  );
                  loading["loading"] = false;
                  this.setState({ loading });
                } else {
                  if (addrRetval != undefined) {
                    var address;
                    address = addrRetval.split("/");
                    if (
                      window.confirm(
                        "Did you mean " +
                          address[0].trim() +
                          ", " +
                          address[1].trim() +
                          " " +
                          address[2] +
                          "?"
                      ) == true
                    ) {
                      contactfields["city"] = address[0].trim();
                      selectfields["state"] = address[1].trim();
                      contactfields["zip"] = address[2];
                      this.setState({ contactfields, selectfields });
                    }
                    loading["loading"] = false;
                    this.setState({ loading });
                  }
                }
              }
            })
            .catch((error) => {
              loading["loading"] = false;
              this.setState({ loading });
              console.error(
                "There was an error loading operating company..., " + error
              );
            });
        } else {
          this.InsertEditDisableContact();
        }
      }
    }
  };
  InsertEditDisableContact = () => {
    let loading = this.state.loading;
    var contactType = "";
    var domesticContact = "0";
    var country = "";
    var type = "";
    var provinceOrstate = "";
    var postalOrzip = "";
    if (this.state.optionfields.private) {
      contactType = "C";
    } else {
      contactType = "P";
    }
    if (this.state.optionfields.locatedYes) {
      domesticContact = "1";
      provinceOrstate = this.state.selectfields.state;
      postalOrzip = this.state.contactfields.zip;
    } else {
      provinceOrstate = this.state.contactfields.province;
      postalOrzip = this.state.contactfields.postal;
    }
    if (domesticContact === "1") {
      country = "USA";
    } else {
      country = this.state.contactfields.country;
    }
    if (this.state.modal.modalAddrButton === "Add New Contact") {
      type = "add";
    } else if (this.state.modal.modalAddrButton === "Edit Contact") {
      type = "edit";
    } else if (this.state.modal.modalAddrButton === "Disable Contact") {
      if (window.confirm("Are you sure?") == true) {
        type = "delete";
      } else {
        type = "nodelete";
        loading["loading"] = false;
        this.setState({ loading });
      }
    }

    if (type === "add" || type === "edit" || type === "delete") {
      var data = {
        type: type,
        username: this.state.fields.uid,
        name: this.state.contactfields.name,
        shipToName: this.state.contactfields.company,
        addressLineOne: this.state.contactfields.address1,
        addressLineTwo: this.state.contactfields.address2,
        city: this.state.contactfields.city,
        state: provinceOrstate,
        zip: postalOrzip,
        country: country,
        phone: this.state.contactfields.phone,
        contactType: contactType,
        recordLabelId: this.state.fields.labelId,
        domesticContact: domesticContact,
        attentionId: this.state.contactfields.contactattentionId,
      };
      let createOrderController = new CreateOrderController();
      createOrderController
        .privateContact(data)
        .then((response) => {
          if (response.success) {
            //alert("Record")
            loading["loading"] = false;
            let modal = this.state.modal;
            modal["modalShow"] = false;
            this.setState({ modal, loading });
            //Reload the current record
            createOrderController
              .selectPersonalContact(
                sessionStorage.getItem("uid")!,
                sessionStorage.getItem("label_id")!,
                this.state.fields.orderType
              )
              .then((jsonPersonalContact) => {
                this.setState({
                  PersonalContact: JSON.parse(
                    JSON.stringify(jsonPersonalContact)
                  ),
                });
              })
              .catch((error) => {
                loading["loading"] = false;
                this.setState({ loading });
                console.error(
                  "There was an error loading operating company..., " + error
                );
              });
          } else {
            loading["loading"] = false;
            this.setState({ loading });
          }
        })
        .catch((error) => {
          loading["loading"] = false;
          this.setState({ loading });
          console.error("There is an error,..." + error);
        });
    }
  };
  checkOrder = () => {
    let errors = this.state.errors;
    let fields = this.state.fields;
    let contactfields = this.state.contactfields;
    let loading = this.state.loading;
    errors["reason"] = "";
    errors["shipToAddr"] = "";

    if (
      fields["orderType"] === GlobalVar.BI ||
      fields["orderType"] === GlobalVar.TB ||
      fields["orderType"] === GlobalVar.NT
    ) {
      errors["accountNo"] = "";
      if (
        fields["orderType"] === GlobalVar.BI ||
        fields["orderType"] === GlobalVar.TB
      ) {
        errors["bill_to_name"] = "";
        errors["bill_to_address"] = "";
        errors["bill_to_city_state_zip"] = "";
      }
    }

    this.setState({ errors });

    let formIsValid = true;

    if (!fields["reason"]) {
      formIsValid = false;
      errors["reason"] =
        "You must give a reason for your order. Please fill in your reason for ordering the merchandise in the 'Reason for Order' blank.";
    }
    if (!contactfields["shipName"]) {
      formIsValid = false;
      errors["shipToAddr"] =
        "You must give ship address for your order. Please select personal contact";
    }
    if (
      fields["orderType"] === GlobalVar.BI ||
      fields["orderType"] === GlobalVar.TB ||
      fields["orderType"] === GlobalVar.NT
    ) {
      if (!fields["accountNo"]) {
        formIsValid = false;
        errors["accountNo"] = "Account Number is required!";
      }
      if (
        fields["orderType"] === GlobalVar.BI ||
        fields["orderType"] === GlobalVar.TB
      ) {
        if (!fields["bill_to_name"]) {
          formIsValid = false;
          errors["bill_to_name"] = "Bill To Name/Company is required!";
        }
        if (!fields["bill_to_address"]) {
          formIsValid = false;
          errors["bill_to_address"] = "Bill To Address is required!";
        }
        if (!fields["bill_to_city_state_zip"]) {
          formIsValid = false;
          errors["bill_to_city_state_zip"] =
            "Bill To City, State, Zip is required!";
        }
      }
    }
    if (
      this.state.selectfields.shipping_method !== "Standard Ground" &&
      fields["businessunit"].trim() === ""
    ) {
      alert(
        "Business Unit is required if 'Overnight' or '2nd Day' is selected!"
      );
      formIsValid = false;
    }
    if (
      (fields["orderType"] === GlobalVar.NT ||
        fields["orderType"] === GlobalVar.BI ||
        fields["orderType"] === GlobalVar.TB ||
        fields["orderType"] === GlobalVar.FG) &&
      formIsValid
    ) {
      if (fields["accountNo"].length < 5) {
        alert(
          "Account Numbers must be 5 characters in length.  No spaces allowed"
        );
        formIsValid = false;
      }
    }
    //Approvar is not assigned show error message in bottom of the product
    if (errors["errmsg"] !== "") {
      formIsValid = false;
    }
    if (formIsValid) {
      var data;
      var totalQty = 0;
      var totalPrice = 0;
      var pricedata;
      var iPos = -1;
      var price = "";
      var regexPrice = new RegExp("^[0-9]*.?[0-9]*$");
      var regexQty = new RegExp("^[0-9]*$");
      var regexDate = new RegExp(
        "^(0?[1-9]|1[0-2])/(0?[1-9]|1[0-9]|2[0-9]|3[0-1])/([0-9]{4})$"
      );
      var regexStoreName = new RegExp("^[a-zA-Z0-9 /,.\\\\s]*$");

      for (var i = 0; i <= fields["maxKey"]; i++) {
        if (
          fields["productButton"][i] === "Search" &&
          this.state.fields.action !== "copy"
        ) {
          alert("You must order missing row before you can complete an order.");
          formIsValid = false;
          break;
        }
        data = parseInt(fields["qty"][i]);
        if (isNaN(data) && this.state.fields.action !== "copy") {
          alert(
            "You must enter the quantity before you can complete an order."
          );
          formIsValid = false;
          break;
        }
        if (!regexQty.test(data) && this.state.fields.action !== "copy") {
          alert(
            "You have entered an invalid quantity. Please enter valid quantity"
          );
          formIsValid = false;
          break;
        }
        if (parseInt(data) < 1 && this.state.fields.action !== "copy") {
          alert(
            "You must enter a quantity greater than 0 before you can complete an order."
          );
          formIsValid = false;
          break;
        }
        if (parseInt(data) < 1 && this.state.fields.action === "copy") {
          let indexKey = fields["qty"].reduce(function (a, e, i) {
            if (e === "0") a.push(i);
            return a;
          }, []);
          for (var i = 0; i <= indexKey.length; i++) {
            this.handleDelete(indexKey[i], "");
          }
        }

        if (!isNaN(data)) {
          totalQty = totalQty + data;
        }

        if (
          fields["orderType"] === GlobalVar.BI ||
          fields["orderType"] === GlobalVar.TB
        ) {
          if (!isNaN(data)) {
            pricedata = parseFloat(fields["price"][i]);
            price = fields["price"][i];
            if (!regexPrice.test(pricedata) && this.state.fields.action !== "copy") {
              alert(
                "You have entered an invalid price. Please enter valid price"
              );
              formIsValid = false;
              break;
            }
            if (isNaN(pricedata) && this.state.fields.action !== "copy") {
              alert(
                "You must enter the price before you can complete an order."
              );
              formIsValid = false;
              break;
            } else if (pricedata < 0.01 && this.state.fields.action !== "copy") {
              alert(
                "Please enter the price not less than $.01 before you can complete an order."
              );
              formIsValid = false;
              break;
            }
            iPos = price.indexOf(".");
            if (iPos > -1) {
              price = price.substring(iPos, price.length);
              if (price.length > 4) {
                alert(
                  "You have entered an invalid price. Please enter up to 3 digits after the decimal."
                );
                formIsValid = false;
                break;
              }
            }
          }
          data = parseFloat(fields["price"][i]);
          if (!isNaN(data)) {
            totalPrice = totalPrice + data;
          }
        }
        if (fields["orderType"] === GlobalVar.TB) {
          if (
            fields["artistStoreName"][i] === undefined ||
            fields["artistStoreName"][i] === ""
          ) {
            alert(
              "You must enter the Artist Store Name before you can complete an order."
            );
            formIsValid = false;
            break;
          }
          if (!regexStoreName.test(fields["artistStoreName"][i])) {
            //alert("You have entered an invalid Artist Store Name. Please enter valid Artist Store Name.");
            alert(
              "You have entered an invalid special characters such as " +
                regexStoreName +
                ". Please enter valid Artist Store Name"
            );
            formIsValid = false;
            break;
          }
          //validation formated date MM/dd/yyyy.
          if (
            fields["tourDate"][i] === undefined ||
            fields["tourDate"][i] === ""
          ) {
            alert(
              "You must enter the tour date before you can complete an order."
            );
            formIsValid = false;
            break;
          }
          if (!regexDate.test(fields["tourDate"][i])) {
            alert("You have entered an invalid date. Please enter valid date.");
            formIsValid = false;
            break;
          }
        }
      }
      if (totalQty == 0 && formIsValid && this.state.fields.action !== "copy") {
        alert("You must order something before you can complete an order.");
        formIsValid = false;
      }

      if (
        fields["orderType"] === GlobalVar.BI ||
        fields["orderType"] === GlobalVar.TB
      ) {
        if (
          totalPrice == 0 &&
          formIsValid &&
          this.state.fields.action !== "copy"
        ) {
          alert("You must order something before you can complete an order.");
          formIsValid = false;
        }
        if (fields["orderType"] === GlobalVar.BI) {
          //Need to write code for Billalbe Order
          if (
            !this.state.optionfields.clean &&
            !this.state.optionfields.marked &&
            !this.state.optionfields.clean_56 &&
            !this.state.optionfields.marked_56 &&
            !this.state.optionfields.clean_59 &&
            !this.state.optionfields.marked_59
          ) {
            alert("You must select a 'Sales Type'");
            formIsValid = false;
          }
        }
        if (fields["orderType"] === GlobalVar.TB) {
          //Need to write code for Billalbe Order
          if (!this.state.optionfields.clean) {
            alert("You must select a 'Sales Type'");
            formIsValid = false;
          }
        }
        //confimation alert Billable order for ApprovalRequired flag.
        if (
          (fields["orderType"] === GlobalVar.BI ||
            fields["orderType"] === GlobalVar.TB) &&
          formIsValid
        ) {
          var isConfirmed = this.confirmation(fields);
          if (!isConfirmed) {
            formIsValid = false;
          }
        }
      } else {
        if (!this.state.optionfields.clean && !this.state.optionfields.marked) {
          alert("You must select a 'Sales Type'");
          formIsValid = false;
        }
      }
    }

    if (!formIsValid) {
      loading["loadingOrderComplete"] = false;
      this.setState({ loading });
    }
    this.setState({ fields, errors });
    return formIsValid;
  };
  approvalRequired = (fields) => {
    var isApprovalRequired = false;
    var MAX_PRODUCTS = fields["maxKey"];
    for (var i = 0; i <= MAX_PRODUCTS; i++) {
      if (fields["flag"][i]) {
        isApprovalRequired = true;
        break;
      }
    }
    return isApprovalRequired;
  };
  confirmation = (fields) => {
    var isFlagApproved = this.approvalRequired(fields);
    var isConfirmed = true;
    if (isFlagApproved) {
      var salesType = this.CheckSaleType();
      switch (salesType) {
        case 1:
          isConfirmed = window.confirm(
            "Discount does not equal 40%, your order will be forwarded to EVP" +
              String.fromCharCode(10) +
              "& CFO of North America for Approval." +
              String.fromCharCode(10) +
              "Please check the prices you have entered for Clean Product."
          );
          break;
        case 9:
        case 3:
          isConfirmed = window.confirm(
            "Discount is greater than 15%, your order will be forwarded to EVP" +
              String.fromCharCode(10) +
              "& CFO of North America for Approval." +
              String.fromCharCode(10) +
              "Please check the prices you have entered for Clean Product."
          );
          break;
        case 5:
          isConfirmed = window.confirm(
            "Discount does not equal 30%, your order will be forwarded to EVP" +
              String.fromCharCode(10) +
              "& CFO of North America for Approval." +
              String.fromCharCode(10) +
              "Please check the prices you have entered for Clean Product."
          );
          break;
      }
    }
    return isConfirmed;
  };
  handleOnSubmitOrderComplete = (e: any) => {
    let loading = this.state.loading;
    loading["loadingOrderComplete"] = true;
    this.setState({ loading });
    e.preventDefault();
    if (e.currentTarget.innerText === "Order Complete") {
      //Enable submit Order and print
      if (this.checkOrder()) {
        loading["loadingOrderComplete"] = false;
        this.setState({ loading, orderComplete: true });
      }
    }
  };
  handleOnSubmitOrder = (e: any) => {
    let loading = this.state.loading;
    let userfields = this.state.userfields;
    var userType = sessionStorage.getItem("usertype_id");
    var currentTargetName = e.currentTarget.name;
    if (this.checkOrder()) {
      if (currentTargetName === "submitOrder") {
        loading["loadingSubmitOrder"] = true;
        this.setState({ isPrint: false });
      } //submi order print
      else {
        loading["loadingSubmitandPrintOrder"] = true;
        this.setState({ isPrint: true });
      }
      this.setState({ loading });

      if (
        userType === "A" &&
        sessionStorage.getItem("user_label_id") === userfields["sOrderLabelID"]
      ) {
        // if it's an approver with inbox-weight and throw-weight of 77....
        // now we know they are an approver based on their user record in the user table
        // now we look in the process_lookup table to determine what approval role they
        // play in approving this particular order
        let commonController = new CommonController();
        commonController
          .getProcessLookupUserType(
            this.state.fields.Process_Name,
            sessionStorage.getItem("uid")!
          )
          .then((resProcessLookupUserType) => {
            if (resProcessLookupUserType.success) {
              this.create_order(
                resProcessLookupUserType.User_Type,
                currentTargetName,
                loading,
                userfields,
                "true"
              );
            } else {
             
              this.create_order(
                "R",
                currentTargetName,
                loading,
                userfields,
                "false"
              );
            }
          })
          .catch((error) => {
            if (currentTargetName === "submitOrder") {
              loading["loadingSubmitOrder"] = false;
            } //submi order print
            else {
              loading["loadingSubmitandPrintOrder"] = false;
            }
            this.setState({ loading });
          });
      } else {
        this.create_order("R", currentTargetName, loading, userfields, "false");
      }
    } else {
      if (currentTargetName === "submitOrder") {
        loading["loadingSubmitOrder"] = false;
      } //submi order print
      else {
        loading["loadingSubmitandPrintOrder"] = false;
      }
      this.setState({ loading });
    }
    /*if len(iMaxApprovers)=0 or _
			len(trim(sProcessName))=0 or _
		    len(trim(request("shipping_method")))=0 or _
		    len(trim(request("business_unit")))=0 then
			Response.Write("There was an error processing your order. Please try again.<br>")
			Response.Write("<button onclick=""window.location='contact.asp?action=select';"" id=button1 name=button1>Continue</button>")
            Response.End
        */
    //let backorder = Request.Form("backorder")
  };
  create_order = (
    userType,
    currentTargetName,
    loading,
    userfields,
    adjustMaxApprover
  ) => {
    let fields = this.state.fields;
    let contactfields = this.state.contactfields;
    let selectfields = this.state.selectfields;
    let iMaterial = 0;
    let sSalesType = "";
    let isCleanedSalesType = false;
    let orderType = fields["orderType"];
    if (orderType === GlobalVar.FG) {
      iMaterial = this.state.optionfields.marked ? 0 : 1;
      sSalesType = this.state.optionfields.marked
        ? GlobalVar.MARKED_53
        : GlobalVar.CLEAN_51;
    } else if (orderType === GlobalVar.BI) {
      //Billable order
      if (this.state.optionfields.marked) {
        sSalesType = GlobalVar.MARKED_54;
        isCleanedSalesType = false;
        iMaterial = 0;
      } else if (this.state.optionfields.clean) {
        sSalesType = GlobalVar.CLEAN_54;
        isCleanedSalesType = true;
        iMaterial = 1;
      } else if (this.state.optionfields.marked_56) {
        sSalesType = GlobalVar.MARKED_56;
        isCleanedSalesType = false;
        iMaterial = 0;
      } else if (this.state.optionfields.clean_56) {
        sSalesType = GlobalVar.CLEAN_56;
        isCleanedSalesType = true;
        iMaterial = 1;
      } else if (this.state.optionfields.marked_59) {
        sSalesType = GlobalVar.MARKED_59;
        isCleanedSalesType = false;
        iMaterial = 0;
      } else if (this.state.optionfields.clean_59) {
        sSalesType = GlobalVar.CLEAN_59;
        isCleanedSalesType = true;
        iMaterial = 1;
      }
    } else if (orderType === GlobalVar.TB) {
      if (this.state.optionfields.clean) {
        sSalesType = GlobalVar.CLEAN_66;
        isCleanedSalesType = true;
        iMaterial = 1;
      }
    } else if (orderType === GlobalVar.S89) {
      // if(this.state.optionfields.clean){
      sSalesType = GlobalVar.MARKED_89;
      // isCleanedSalesType=true;
      iMaterial = 0;
      //  }
    } else if (orderType === GlobalVar.NT) {
      iMaterial = this.state.optionfields.marked ? 0 : 1;
      sSalesType = this.state.optionfields.marked
        ? GlobalVar.MARKED_91
        : GlobalVar.CLEAN_91;
    }
    var sProcessName = fields["Process_Name"]; //sessionStorage.getItem("process_name")!;
    let sRequestorWeight;
    let new_order_id;
    var iMaxApprovers = "";

    let commonController = new CommonController();
    commonController
      .getApprovalProcess(sProcessName)
      .then((resApprovalProcess) => {
        iMaxApprovers =
          adjustMaxApprover === "true"
            ? resApprovalProcess.Max_Approvers - 1
            : resApprovalProcess.Max_Approvers;
        if (
          fields["orderType"] === GlobalVar.BI ||
          fields["orderType"] === GlobalVar.TB
        ) {
          //Billable
          if (
            iMaxApprovers.length === 0 ||
            sProcessName.trim().length === 0 ||
            selectfields["shipping_method"].length === 0 ||
            fields["accountNo"].length === 0 ||
            fields["businessunit"].length === 0 ||
            fields["bill_to_name"].length === 0 ||
            fields["bill_to_address"].length === 0 ||
            fields["bill_to_city_state_zip"].length === 0
          ) {
            //Response.Write("There was an error processing your order. Please try again.<br>")
            alert(
              "There was an error processing your order. Please try again."
            );
            //Response.Write("<button onclick=""window.location='contact.asp?action=select';"" id=button1 name=button1>Continue</button>")
            this.stopSubmitLoading(currentTargetName, loading);
            return false;
          }
        } else {
          if (
            iMaxApprovers.length === 0 ||
            sProcessName.trim().length === 0 ||
            selectfields["shipping_method"].length === 0 ||
            fields["businessunit"].length === 0
          ) {
            //Response.Write("There was an error processing your order. Please try again.<br>")
            alert(
              "There was an error processing your order. Please try again."
            );
            //Response.Write("<button onclick=""window.location='contact.asp?action=select';"" id=button1 name=button1>Continue</button>")
            this.stopSubmitLoading(currentTargetName, loading);
            return false;
          }
        }
        var backorder = "0";
        let createOrderController = new CreateOrderController();
        createOrderController
          .getPrivateContact(this.state.contactfields.attentionId)
          .then((resPrivateContact) => {
            if (resPrivateContact.success) {
              var insertOrderSummary = {
                alias: sessionStorage.getItem("uid"),
                processName: sProcessName,
                arriveBy: null,
                businessUnit: fields["businessunit"],
                //accountNo : (orderType !== GlobalVar.FG)?fields["accountNo"]:'',
                accountNo: fields["accountNo"],
                freightBillingInfo: fields["freightBillingInfo"],
                prepaid: this.state.checkfields.prepaid ? 1 : 0,
                specialInstructions: fields["specialIns"],
                overnight:
                  selectfields["shipping_method"] !== "Standard Ground" ||
                  selectfields["shipping_method"] !== "Pick-Up"
                    ? 1
                    : 0,
                statusId: 1,
                legacyOrder: null,
                name: resPrivateContact.Name,
                shipToName: resPrivateContact.ShipToName,
                addressLineOne: resPrivateContact.AddressLineOne,
                addressLineTwo: resPrivateContact.AddressLineTwo,
                city: resPrivateContact.City,
                state: resPrivateContact.State,
                zip: resPrivateContact.Zip,
                country: resPrivateContact.Country,
                phone: resPrivateContact.Phone,
                requestorWeight: iMaxApprovers,
                approverId: null,
                approverDate: null,
                approveByClean: null,
                approveByOvernight: null,
                labelId: this.state.userfields.sOrderLabelID,
                superLabelId: sessionStorage.getItem("user_label_id"),
                ipAddress: fields["ipAddress"],
                material: iMaterial,
                reason: fields["reason"].substring(0, 100),
                department: sessionStorage.getItem("user_department"),
                customerNumber: sessionStorage.getItem("CustomerNumber"),
                shipMethod: selectfields["shipping_method"],
                billable:
                  orderType === GlobalVar.BI || orderType === GlobalVar.TB
                    ? 1
                    : 0,
                salesType: sSalesType,
                billToName:
                  orderType !== GlobalVar.FG ? fields["bill_to_name"] : "",
                billToAddress:
                  orderType !== GlobalVar.FG ? fields["bill_to_address"] : "",
                billToCityStateZip:
                  orderType !== GlobalVar.FG
                    ? fields["bill_to_city_state_zip"]
                    : "",
                backorder: backorder,
                deliveryDate: null,
                ntBillableT: orderType === GlobalVar.NT ? 1 : 0,
              };
      

              /*var data = {}
                    sRequestorWeight = "0";
                    for (let s=0;s<=fields['maxKey'];s++) 
                    {   
                        console.log("I:" + s);

                        data = {
                            newOrderId:parseInt("111111"),
                            selectionId:fields['selection'][s],
                            quantityApproved:(sRequestorWeight==="0")?parseInt(fields['qty'][s]):"0",
                            quantityRequested:(sRequestorWeight==="0")?parseInt(fields['qty'][s]):"0",
                            orderType:fields['orderType'],
                            isCleanedSalesType:(isCleanedSalesType)?1:0,
                            price:(orderType==='BI')?fields['price'][s]:0,
                        }
                        console.log("insertOrderSummary",data)
                    }*/

              createOrderController
                .insertOrderSummary(insertOrderSummary)
                .then((response) => {
                  if (response !== undefined) {
                    sRequestorWeight = "0";
                    let newOrderId = response.newOrderId;

                    var data = {};
                    for (let s = 0; s <= fields["maxKey"]; s++) {
                      data = {
                        newOrderId: parseInt(newOrderId),
                        selectionId: fields["selection"][s],
                        quantityApproved:
                          sRequestorWeight === "0"
                            ? parseInt(fields["qty"][s])
                            : "0",
                        quantityRequested:
                          sRequestorWeight === "0"
                            ? parseInt(fields["qty"][s])
                            : "0",
                        orderType: fields["orderType"],
                        isCleanedSalesType: isCleanedSalesType ? 1 : 0,
                        price:
                          orderType === GlobalVar.BI ||
                          orderType === GlobalVar.TB
                            ? fields["price"][s]
                            : 0,
                        artistStoreName:
                          orderType === GlobalVar.TB
                            ? fields["artistStoreName"][s]
                            : "",
                        tourDate:
                          orderType === GlobalVar.TB
                            ? fields["tourDate"][s]
                            : "",
                      };
                      createOrderController
                        .updateInsertOrderItems(data)
                        .then((response) => {
                         
                          if (response !== undefined) {
                            //alert('Response: ' + response.success);
                           

                            if (s === fields["maxKey"]) {
                             
                              //Need to check if self requester in order_new.asp line no.55
                              createOrderController
                                .checkSelfApprover(
                                  sessionStorage.getItem("uid")!,
                                  sProcessName
                                )
                                .then((response) => {
                                  if (response.value > 0) {
                                    let orderController = new OrderController(
                                      newOrderId,
                                      sessionStorage.getItem("uid")!
                                    );
                                    orderController
                                      .routeNewOrder(true)
                                      .then((response) => {
                                        this.stopSubmitLoading(
                                          currentTargetName,
                                          loading
                                        );

                                        if (!this.state.isPrint) {
                                          alert(
                                            "Your Order has been Submitted"
                                          );
                                          this.props.history.push(
                                            "/admin/home"
                                          );
                                        } else {
                                    
                                          this.props.history.push({
                                            pathname: "/admin/viewOrderDetails",
                                            state: {
                                              Order_id: newOrderId,
                                              task_For: "P",
                                            },
                                          });
                                        }
                                      })
                                      .catch((error) => {
                                        
                                      });
                                  } else {
                                    let orderController = new OrderController(
                                      newOrderId,
                                      sessionStorage.getItem("uid")!
                                    );
                                    orderController
                                      .routeNewOrder(false)
                                      .then((response) => {
                                        this.stopSubmitLoading(
                                          currentTargetName,
                                          loading
                                        );

                                        if (!this.state.isPrint) {
                                          alert(
                                            "Your Order has been Submitted"
                                          );
                                          this.props.history.push(
                                            "/admin/home"
                                          );
                                        } else {
                                        
                                          
                                          this.props.history.push({
                                            pathname: "/admin/viewOrderDetails",
                                            state: {
                                              Order_id: newOrderId,
                                              task_For: "P",
                                            },
                                          });
                                        }
                                      })
                                      .catch((error) => {
                                       
                                      });
                                  }
                                })
                                .catch((error) => {
                                });
                            }
                          } else {
                            this.stopSubmitLoading(currentTargetName, loading);
                         
                          }
                        })
                        .catch((error) => {
                          this.stopSubmitLoading(currentTargetName, loading);
                        });
                    }
                  } else {
                    this.stopSubmitLoading(currentTargetName, loading);
                    
                  }
                })
                .catch((error) => {
                  this.stopSubmitLoading(currentTargetName, loading);
                });
            } else {
              alert(
                "Nice Try.  No Recipient is specified for this order. Page redirecting to 'Home', and start from the beginning."
              );
              this.props.history.push("/admin/home");
            }
          })
          .catch((error) => {
            this.stopSubmitLoading(currentTargetName, loading);
          });
      })
      .catch((error) => {
        this.stopSubmitLoading(currentTargetName, loading);
      });
  };

  stopSubmitLoading = (currentTargetName, loading) => {
    if (currentTargetName === "submitOrder") {
      loading["loadingSubmitOrder"] = false;
    } //submi order print
    else {
      loading["loadingSubmitandPrintOrder"] = false;
    }
    this.setState({ loading });
  };

  showModalFooterButton = (loading) => {
    if (this.state.modal.modalDisplayAddr && this.state.modal.modalBody) {
      return (
        <div>
          <Button disabled={loading} onClick={this.handleOnSubmitAddress}>
            {loading && (
              <i
                className="fa fa-refresh fa-spin mr-5"
                style={{ marginRight: "5px", color: "#3472F7" }}
              />
            )}
            {loading && <span style={{ color: "#3472F7" }}>wait...</span>}
            {!loading && <span>{this.state.modal.modalAddrButton}</span>}
          </Button>
        </div>
      );
    } else {
      if (sessionStorage.getItem("multiple_select") === "1") {
        if (this.state.Products.length > 0) {
          return (
            <div>                           
              <Button
                disabled={loading}
                onClick={this.handleOnSubmitMultipleSelect}
              >
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin mr-5"
                    style={{ marginRight: "5px", color: "#3472F7" }}
                  />
                )}
                {loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                {!loading && <span>Select Choice(s)</span>}
              </Button>
              <Button
                style={{ marginLeft: "5px" }}
                onClick={this.handleModalClose}
              >
                Cancel Search
              </Button>
            </div>
          );
        } else {
          return (
            <div>
              <Button
                style={{ marginLeft: "5px" }}
                onClick={this.handleModalClose}
              >
                Close
              </Button>
            </div>
          );
        }
      } else {
        return (
          <div>
            <Button
              style={{ marginLeft: "5px" }}
              onClick={this.handleModalClose}
            >
              Close
            </Button>
          </div>
        );
      }
    }
  };

  clearError() {
    let errors = this.state.errors;
    errors["company"] = "";
    errors["name"] = "";
    errors["address1"] = "";
    errors["city"] = "";
    errors["postal"] = "";
    errors["country"] = "";
    errors["state"] = "";
    errors["zip"] = "";
    this.setState({ errors });
  }

  clearfields() {
    let selectfields = this.state.selectfields;
    let optionfields = this.state.optionfields;
    let contactfields = this.state.contactfields;
    contactfields["company"] = "";
    contactfields["name"] = "";
    contactfields["address1"] = "";
    contactfields["address2"] = "";
    contactfields["city"] = "";
    contactfields["postal"] = "";
    contactfields["country"] = "";
    contactfields["state"] = "";
    selectfields["state"] = "";
    contactfields["zip"] = "";
    contactfields["phone"] = "";
    optionfields["private"] = true;
    optionfields["public"] = false;
    this.setState({ contactfields, selectfields, optionfields });
  }

  ValidateAddress() {
    this.clearError();
    //company:'',name:'',address1:'',city:'',state:'',zip:'',postal:'',country:''
    let contactfields = this.state.contactfields;
    let optionfields = this.state.optionfields;
    let selectfields = this.state.selectfields;
    let errors = this.state.errors;
    let formIsValid = true;

    let Tcompany = contactfields["company"];
    let TName = contactfields["name"];
    let Taddress1 = contactfields["address1"];
    let Taddress2 = contactfields["address2"];
    let TCity = contactfields["city"];
    let TProvince = contactfields["province"];
    let TPostal = contactfields["postal"];
    let TCountry = contactfields["country"];
    let TPhone = contactfields["phone"];

    if (!Tcompany) {
      formIsValid = false;
      errors["company"] = "Company is required!";
    }
    if (!TName) {
      formIsValid = false;
      errors["name"] = "Name is required!";
    }
    if (!Taddress1) {
      formIsValid = false;
      errors["address1"] = "Address 1 is required!";
    }
    if (!TCity) {
      formIsValid = false;
      errors["city"] = "City is required!";
    }
    if (optionfields["locatedNo"]) {
      if (!TPostal) {
        formIsValid = false;
        errors["postal"] = "Postal is required!";
      }
      if (!TCountry) {
        formIsValid = false;
        errors["country"] = "Country is required!";
      }
    }
    if (optionfields["locatedYes"]) {
      if (!selectfields["state"]) {
        formIsValid = false;
        errors["state"] = "State is required!";
      }

      if (!contactfields["zip"]) {
        formIsValid = false;
        errors["zip"] = "Zip is required!";
      } else {
        if (
          contactfields["zip"].length != 5 &&
          contactfields["zip"].length != 10
        ) {
          alert("Please enter your 5 digit or 5+4 digit zip code.");
          formIsValid = false;
        }
      }
    }

    var regex = new RegExp("^[ A-Za-z0-9,)(./-]+$");
    var retval = "";
    if (Tcompany.length > 0 && TName.length > 0 && Taddress1.length > 0) {
      //alert("length");
      if (!regex.test(Tcompany)) {
        //modal['modalAlertShow'] = true;
        //modal['modalAlertErrorMsg'] = 'Special/Non-English Characters Not Allowed';
        alert("Special/Non-English Characters Not Allowed");
        //document.getElementById('company').focus();
        Tcompany.focus();
        formIsValid = false;
      }
      if (!regex.test(TName)) {
        //modal['modalAlertShow'] = true;
        //modal['modalAlertErrorMsg'] = 'Special/Non-English Characters Not Allowed';
        alert("Special/Non-English Characters Not Allowed");
        //document.getElementById('name').focus();
        //this.nameInput.current.focus();
        formIsValid = false;
      }
      if (!regex.test(Taddress1)) {
        alert("Special/Non-English Characters Not Allowed");
        //document.getElementById('address1').focus();
        formIsValid = false;
      }
      if (Taddress2.length > 0) {
        if (!regex.test(Taddress2)) {
          alert("Special/Non-English Characters Not Allowed");
          //document.getElementById('address2').focus();
          formIsValid = false;
        }
      }
      if (TCity.length > 0) {
        if (!regex.test(TCity)) {
          alert("Special/Non-English Characters Not Allowed");
          //document.getElementById('city').focus();
          formIsValid = false;
        }
      }
      if (TProvince.length > 0) {
        if (!regex.test(TProvince)) {
          alert("Special/Non-English Characters Not Allowed");
          //document.getElementById('province').focus();
          formIsValid = false;
        }
      }
      if (TPostal.length > 0) {
        if (!regex.test(TPostal)) {
          alert("Special/Non-English Characters Not Allowed");
          //document.getElementById('postal').focus();
          formIsValid = false;
        }
      }
      // if (TCountry.length > 0) {
      //   if (!regex.test(TCountry)) {
      //     alert("Special/Non-English Characters Not Allowed");
      //     //document.getElementById('country').focus();
      //     formIsValid = false;
      //   }
      // }
      if (TPhone.length > 0) {
        if (!regex.test(TPhone)) {
          alert("Special/Non-English Characters Not Allowed");
          //document.getElementById('phone').focus();
          formIsValid = false;
        }
      }
    }

    if (!formIsValid) {
      let loading = this.state.loading;
      loading["loading"] = false;
      this.setState({ loading });
    }
    this.setState({
      errors,
      contactfields,
    });

    return formIsValid;
  }

  checkAlphaNumericInput(element: any) {
    let contactfields = this.state.contactfields;
    contactfields[element.currentTarget.name] =
      element.currentTarget.value.replace(
        /[!@#$%^&*_+\-=\`~[\]{}'\\|<>\?]/g,
        ""
      );
    this.setState({ contactfields });
  }
  checkAlphaNumericInputCity(element: any) {
    let contactfields = this.state.contactfields;
    contactfields[element.currentTarget.name] =
      element.currentTarget.value.replace(
        /[!@#$%^&*_+\-=\`~[\]{}();:"/,.'\\|<>\?]/g,
        ""
      );
    this.setState({ contactfields });
  }
  checkNumericInput(element: any) {
    let contactfields = this.state.contactfields;
    let optionfields = this.state.optionfields;
    if (optionfields["locatedNo"]) {
      contactfields[element.currentTarget.name] =
        element.currentTarget.value.replace(
          /[().!@#$%^&*_+\-=\`~[\]{}'\\|<>\/,?";:]/g,
          ""
        );
    } else {
      contactfields[element.currentTarget.name] =
        element.currentTarget.value.replace(/[^0-9]/g, "");
    }
    this.setState({ contactfields });
  }

  checkNumericInputcheckLengh(element: any) {
    let contactfields = this.state.contactfields;
    let modal = this.state.modal;
    let optionfields = this.state.optionfields;
    const target = element.currentTarget;
    if (optionfields["locatedYes"]) {
      contactfields[element.currentTarget.name] =
        element.currentTarget.value.replace(
          /[().!@#$%^&*_+\-=\`~[\]{}'\\|<>\/,?";:]/g,
          ""
        );
      if (contactfields["zip"].length > 5) {
        //modal['modalAlertShow']=true;
        //modal['modalAlertErrorMsg']="Zip length must be five";
        alert("Zip length must be five");
        contactfields["zip"] = "";
        this.setFocus(target);
        target.style.border = this.redBorder;
        this.setState({ contactfields, modal });
        return false;
      } else {
        target.style.border = this.defaultBorder;
        this.setState({ contactfields });
        return true;
      }
    }
  }

  setFocus(target: any) {
    //setTimeout(function () { target.focus(); },5);
  }

  checkNumericInputSearchProduct(i, element: any) {
    let fields = this.state.fields;
    //contactfields[element.currentTarget.name] = element.currentTarget.value.replace(/[().!@#$%^&*_+\-=\`~[\]{}'\\|<>\/,?";:]/g,'');
    fields[element.currentTarget.name][i] = element.currentTarget.value.replace(
      /[^0-9]/g,
      ""
    );
    this.setState({ fields });
  }
  radioCleanPrompt = () => {
    let cleanLabel = "";
    let sValue = "";
    if (this.state.fields.orderType === GlobalVar.FG) {
      cleanLabel = GlobalVar.CLEAN_51;
      sValue = "1";
    } else if (this.state.fields.orderType === GlobalVar.NT) {
      cleanLabel = GlobalVar.CLEAN_91;
      sValue = "7";
    } else if (this.state.fields.orderType === GlobalVar.BI) {
      cleanLabel = GlobalVar.CLEAN_54;
      sValue = "1";
    } else if (this.state.fields.orderType === GlobalVar.TB) {
      cleanLabel = GlobalVar.CLEAN_66;
      sValue = "9";
    } else if (this.state.fields.orderType === GlobalVar.S89) {
      cleanLabel = GlobalVar.MARKED_89;
      sValue = "11";
    }
    if (this.state.userfields.bCleanPrompt) {
      return (
        <Col sm={2} style={{ paddingRight: "0.50rem" }}>
          <Form.Check
            type="radio"
            label={cleanLabel}
            name="sales_type"
            id="Clean"
            onClick={this.handleOptionClick}
            onChange={this.handleOptionChange}
            defaultChecked={this.state.optionfields.clean}
            checked={this.state.optionfields.clean}
            value={sValue}
          />
        </Col>
      );
    } else {
      return <Col sm={2}></Col>;
    }
  };
  radioCleanPrompt_56 = () => {
    if (this.state.userfields.bCleanPrompt) {
      return (
        <Col sm={2} style={{ paddingRight: "0.50rem" }}>
          <Form.Check
            type="radio"
            label="56 Clean (fully royalty bearing)"
            name="sales_type"
            id="Clean_56"
            onClick={this.handleOptionClick}
            onChange={this.handleOptionChange}
            defaultChecked={this.state.optionfields.clean_56}
            checked={this.state.optionfields.clean_56}
            value="3"
          />
        </Col>
      );
    } else {
      return <Col sm={2}></Col>;
    }
  };
  radioCleanPrompt_59 = () => {
    if (this.state.userfields.bCleanPrompt) {
      return (
        <Col sm={2} style={{ paddingRight: "0.50rem" }}>
          <Form.Check
            type="radio"
            label="59 Clean (publisher royalties only)"
            name="sales_type"
            id="Clean_59"
            onClick={this.handleOptionClick}
            onChange={this.handleOptionChange}
            defaultChecked={this.state.optionfields.clean_59}
            checked={this.state.optionfields.clean_59}
            value="5"
          />
        </Col>
      );
    } else {
      return <Col sm={2}></Col>;
    }
  };
  isOvernightShipping = () => {
    let fields = this.state.fields;
    if (fields["isOvernight"]) {
      return (
        <Form.Group>
          
          <Form.Label>Shipping</Form.Label>          
            <Form.Control
              as="select"
              name="shipping_method"
              className="form-control form-control-sm"
              value={this.state.selectfields.shipping_method}
              onChange={this.handleSelectChange}
            >
              <option value="Standard Ground" selected>
                Standard Ground
              </option>
              <option value="2nd Day Air">2nd Day Air</option>
              <option value="Overnight">Overnight</option>
            </Form.Control>
          
        </Form.Group>
      );
    } else {
      return (
        <FormLabelField
          properties={{
            label: "Shipping",
            id: "shipping_method",
            name: "shipping_method",
            className: "form-control",
            value: this.state.selectfields.shipping_method,
          }}
        />
      );
    }
  };
  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const { loading } = this.state;
    const navStyle = {
      backgroundColor: "rgba(0,0,0,.03)",
      border: "0px solid",
      borderBottom: "1px solid rgba(0,0,0,.125)",
      height: "40px",
      width: "100%",
      marginBottom: "0",
    };
    const instructionFontColor = {
      color: "#dc3545!important",
    };
    const displayShiptoNavTB = () => {
      return (
        <Row>
          <Col sm={12}>
            <Navbar expand="lg" style={navStyle}>
              <Navbar.Brand
                style={{
                  color: "#656D78",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Ship To
              </Navbar.Brand>
              <Nav style={{ color: "#2AB3E2" }} className="ml-auto"></Nav>
            </Navbar>
          </Col>
        </Row>
      );
    };
    const displayShiptoNav = () => {
      if (this.state.fields.shipName !== "") {
        return (
          <Row>
            <Col sm={12}>
              <Navbar expand="lg" style={navStyle}>
                <Navbar.Brand
                  style={{
                    color: "#656D78",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Ship To
                </Navbar.Brand>
                <Nav style={{ color: "#2AB3E2" }} className="ml-auto">
                  <Nav.Item>
                    <Nav.Link style={{ color: "#2AB3E2" }} href="/">
                      Copy
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ color: "#2AB3E2" }}
                      href="#"
                      onClick={this.enableSearchResult}
                    >
                      Search
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col sm={12}>
              <Navbar expand="lg" style={navStyle}>
                <Navbar.Brand
                  style={{
                    color: "#656D78",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Ship To
                </Navbar.Brand>
                <Nav className="ml-auto">
                  <Nav.Item>
                    <Nav.Link
                      style={{ color: "#2AB3E2" }}
                      href="#"
                      onClick={(e) => this.handleAddressModalShow("A", e)}
                    >
                      Add
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ color: "#2AB3E2" }}
                      href="#"
                      onClick={(e) => this.handleAddressModalShow("E", e)}
                    >
                      Edit
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ color: "#2AB3E2" }}
                      href="#"
                      onClick={(e) => this.handleAddressModalShow("D", e)}
                    >
                      Disable
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar>
            </Col>
          </Row>
        );
      }
    };
    const displayShipTo = () => {
      if (this.state.contactfields.shipName !== "") {
        return (
          <Row>
            <Col sm={2}>
              <FormLabelField
                properties={{
                  label: "Name",
                  id: "shipName",
                  name: "shipName",
                  className: "form-control",
                  value: this.state.contactfields.shipName,
                }}
              />
            </Col>
            <Col sm={6}>
              <FormLabelField
                properties={{
                  label: "Address",
                  id: "shipAddress",
                  name: "shipAddress",
                  className: "form-control",
                  value: this.state.contactfields.shipAddress,
                }}
              />
            </Col>
            <Col sm={4}>
              <FormLabelField
                properties={{
                  label: "Phone",
                  id: "shipPhone",
                  name: "shipPhone",
                  className: "form-control",
                  value: this.state.contactfields.shipPhone,
                }}
              />
            </Col>
          </Row>
        );
      } else {
        return (
          <div className="text-danger font-size-xs small font-weight-bold">
            {this.state.errors.shipToAddr}
          </div>
        );
      }
    };
    const selectPersonalContact = () => {
      return (
        <Form.Control
          as="select"
          id="personalContactId"
          name="personalContactId"
          title={
            this.state.contactfields.shipName +
            "," +
            this.state.contactfields.shipAddress +
            "," +
            this.state.selectfields.expCountry
          }
          value={this.state.selectfields.personalContactId}
          className="form-control form-control-sm"
          onChange={this.handleSelectChange}
        >
          <option value="0">Select Personal Contact</option>
          {this.state.PersonalContact.map((prop, key) => {
            return (
              <option
                title={
                  prop["name"] +
                  "," +
                  prop["addressLineOne"] +
                  "," +
                  prop["addressLineTwo"] +
                  "," +
                  prop["city"] +
                  "," +
                  prop["state"] +
                  "," +
                  prop["zip"] +
                  "," +
                  prop["country"]
                }
                value={
                  prop["id"] +
                  "~~" +
                  prop["addressLineOne"] +
                  "~~" +
                  prop["addressLineTwo"] +
                  "~~" +
                  prop["zip"] +
                  "~~" +
                  prop["phone"] +
                  "~~" +
                  prop["accountnumber"] +
                  "~~" +
                  prop["country"] +
                  "~~" +
                  prop["Company"] +
                  "~~" +
                  prop["Addresses"] +
                  "~~" +
                  prop["CityStateZip"]
                }
              >
                {prop["name"] +
                  "..." +
                  prop["shipToName"] +
                  "..." +
                  prop["city"] +
                  "..." +
                  prop["state"]}
              </option>
            );
          })}
        </Form.Control>
      );
    };
    const displayHeader = () => {
      if (this.state.fields.orderType === GlobalVar.FG) {
        return "Create Free Goods Order"; // + this.state.fields.orderType
      } else if (this.state.fields.orderType === GlobalVar.NT) {
        return "Non-Billable Transfer Order"; // + this.state.fields.orderType
      } else if (this.state.fields.orderType === GlobalVar.BI) {
        //BILLABLE:BI
        return "Billable Order"; // + this.state.fields.orderType
      } else if (this.state.fields.orderType === GlobalVar.TB) {
        return "Ticket Bundling";
      } else if (this.state.fields.orderType === GlobalVar.S89) {
        return "D2C Order";
      }
    };
    const displayBusinessunitNote = () => {
      if (this.state.fields.orderType === GlobalVar.FG) {
        //FREE GOOD and NT BILLABLE ORDER
        return "Once approved, please allow up to 3 business days for your order to be picked and shipped. This does not include actual shipping time to reach your destination. Any Approved orders after 10:30 am EST will be processed the following business day.";
      } else {
        //BILLABLE:BI and TICKET BUNDLING
        return "Once approved, please allow up to 3 business days for your order to be picked and shipped. This does not include actual shipping time to reach your destination.";
      }
    };

    let country = "";
    return (
      <div className="content">
        <Modal
          show={this.state.modal.modalShow}
          onHide={this.handleModalClose}
          backdrop="static"
          keyboard={false}
          dialogClassName={
            this.state.modal.modalBody ? "modal-lg" : "modal-70w"
          }
          centered
        >
          <Modal.Header closeButton>{this.state.modal.modalTitle}</Modal.Header>
          <Modal.Body className="show-grid" style={{ padding: "0px" }}>
            {this.showModalBody()}
          </Modal.Body>
          <Modal.Footer>
            {this.showModalFooterButton(this.state.loading.loading)}
          </Modal.Footer>
        </Modal>
        {/*<FormModal show={this.state.modal.modalAlertShow}
                 onHide={this.handleModalAlertClose} errorMsg={this.state.modal.modalAlertErrorMsg} />*/}
        <FormModalSpinner show={this.state.modal.modalAlertShow} />
        <Container fluid>
          <Row>
            <Col sm={12} className="small">
              <Card border="success" style={{ width: "100%" }}>
                <Card.Header>
                  <b>Instructions:</b> Select Ship To contact
                </Card.Header>
                <Card.Body
                  className="small text-dark"
                  style={instructionFontColor}
                >
                  <div>
                    <b>Select Personal Contact</b> - The drop down menu provides
                    a list of all contacts you have entered, both personal and
                    public.
                  </div>
                  <div>
                    <b>Search All Contacts</b> - This section allows you to
                    search through all public contacts for your specific
                    oganization, in addition to your personal contacts. Personal
                    contacts are denoted by an (*).
                  </div>
                  <div>
                    <b>Add</b> - This link allows you to add personal and public
                    contacts.
                  </div>
                  <div>
                    <b>Edit</b> - This link allows you to edit contacts you have
                    entered, both personal and public.
                  </div>
                  <div>
                    <b>Delete</b> - This link allows you to delete a personal
                    contact only.{" "}
                  </div>
                  <div>
                    <b>Multiple Select</b> - Use CTRL + Mouse click to select
                    multiple products.{" "}
                  </div>
                  
                </Card.Body>
              </Card>
              <Form>
                <Card border="light" style={{ width: "100%" }}>
                  <div className="header">
                    <h4 className="title">{displayHeader()}</h4>
                    <p className="category"></p>
                  </div>
                  <Card.Body style={instructionFontColor}>
                    <Alert
                      variant="danger"
                      style={{
                        display: this.state.errMsg ? "inherit" : "none",
                      }}
                    >
                      {this.state.errMsg}
                    </Alert>
                    <Row>
                      <Col sm={12}>
                        <Navbar expand="lg" style={navStyle}>
                          <Navbar.Brand
                            style={{
                              color: "#656D78",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            From
                          </Navbar.Brand>
                          <Nav className="ml-auto"></Nav>
                        </Navbar>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <FormLabelField
                          properties={{
                            label: "Name",
                            id: "name",
                            name: "name",
                            className: "form-control",
                            value: sessionStorage.getItem("user_full_name"),
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormLabelField
                          properties={{
                            label: "Address",
                            id: "address",
                            name: "address",
                            className: "form-control",
                            value:
                              sessionStorage.getItem("addressLineOne") +
                              ", " +
                              sessionStorage.getItem("city") +
                              ", " +
                              sessionStorage.getItem("state") +
                              ", " +
                              sessionStorage.getItem("zip"),
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                        <FormLabelField
                          properties={{
                            label: "Phone",
                            id: "phone",
                            name: "phone",
                            className: "form-control",
                            value: sessionStorage.getItem("phone"),
                          }}
                        />
                      </Col>
                    </Row>
                    {this.state.fields.orderType !== GlobalVar.TB &&
                    this.state.fields.orderType !== GlobalVar.S89
                      ? displayShiptoNav()
                      : displayShiptoNavTB()}
                    {displayShipTo()}
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Select Personal Contact:</Form.Label>
                              {selectPersonalContact()}
                            </Form.Group>
                          </Col>
                          {this.state.fields.orderType !== GlobalVar.TB &&
                          this.state.fields.orderType !== GlobalVar.S89 ? (
                            <Col sm={3}>
                              <Form.Group>
                                <Form.Label>Search All Contacts</Form.Label>
                                <Row
                                  style={{
                                    alignItems: "flex-end",
                                    paddingLeft: "1rem",
                                    paddingTop: "0.50rem",
                                  }}
                                >
                                  <Col
                                    sm={1.5}
                                    style={{ paddingRight: "0.50rem" }}
                                  >
                                    <Form.Check
                                      type="radio"
                                      label="Name"
                                      name="searchContactGroup"
                                      id="Name"
                                      onChange={this.handleOptionChange}
                                      checked={this.state.optionfields.name}
                                    />
                                  </Col>
                                  <Col
                                    sm={1.5}
                                    style={{ paddingRight: "0.50rem" }}
                                  >
                                    <Form.Check
                                      type="radio"
                                      label="Company"
                                      name="searchContactGroup"
                                      id="Company"
                                      onChange={this.handleOptionChange}
                                      checked={this.state.optionfields.company}
                                    />
                                  </Col>
                                  <Col
                                    sm={1.5}
                                    style={{ paddingRight: "0.50rem" }}
                                  >
                                    <Form.Check
                                      type="radio"
                                      label="City"
                                      name="searchContactGroup"
                                      id="City"
                                      onChange={this.handleOptionChange}
                                      checked={this.state.optionfields.city}
                                    />
                                  </Col>
                                  <Col sm={1.5}>
                                    <Form.Check
                                      type="radio"
                                      label="State"
                                      name="searchContactGroup"
                                      id="State"
                                      onChange={this.handleOptionChange}
                                      checked={this.state.optionfields.state}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          ) : (
                            <Col sm={3}></Col>
                          )}
                          {this.state.fields.orderType !== GlobalVar.TB &&
                          this.state.fields.orderType !== GlobalVar.S89 ? (
                            <Col sm={3} style={{ paddingRight: "0.25rem" }}>
                              {this.SelectAllContact()}
                            </Col>
                          ) : (
                            <Col sm={3}></Col>
                          )}
                        </Row>
                        <Row>
                          <Col sm={6}></Col>
                          <Col sm={6}>{this.searchResult()}</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Navbar expand="lg" style={navStyle}>
                          <Navbar.Brand
                            style={{
                              color: "#656D78",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            Required
                          </Navbar.Brand>
                          <Nav className="ml-auto"></Nav>
                        </Navbar>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>{this.isOvernightShipping()}</Col>
                      <Col sm={2}>
                        <FormInputs
                          properties={{
                            id: "businessunit",
                            label: "Enter Business Unit",
                            type: "text",
                            name: "businessunit",
                            className: "form-control input-sm form-control-sm",
                            value: this.state.fields.businessunit,
                            onChange: this.handleChange,
                          }}
                          fieldRequired={false}
                        />
                      </Col>
                      <Col
                        sm={6}
                        className="align-self-center"
                        style={{ textAlign: "justify" }}
                      >
                        <div className="text-primary font-size-xs small">
                          {displayBusinessunitNote()}
                        </div>
                      </Col>
                    </Row>
                    {this.displayAccountNumberFG()}
                    {this.displayFreightNTAndBIBillable()}
                    {this.displayFreightTB()}
                    {this.displayFreightS89()}
                    <Row>
                      {this.build_label_list()}

                      {this.exportTextChange()}

                      <Col sm={4}>
                                                 
                       </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className="text-danger font-size-xs large float-left font-weight-bold">
                          {this.state.errors.errmsg}
                        </div>
                      </Col>
                    </Row>
                     <Row>
                      <Col sm={4}>
                        <div className="custom-file">
                          <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={this.handleImport}
                            accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                          <label className="custom-file-label" htmlFor="inputGroupFile">Choose upc list file</label>
                        </div>

                      </Col>
                    </Row> 


                    <Row>
                      <Col sm={12}>
                        {this.state.fields.action === "copy" ? (
                          <Navbar expand="lg" style={navStyle}>
                            <Navbar.Brand
                              style={{
                                color: "#656D78",
                                fontSize: "1rem",
                                fontWeight: "bold",
                              }}
                            >
                              Copying Order {this.props.location.state.orderId}
                            </Navbar.Brand>
                            <Nav className="ml-auto">
                              <span className="text-danger font-size-xs float-right mr-3 small"></span>
                            </Nav>
                          </Navbar>
                        ) : (
                          <Navbar expand="lg" style={navStyle}>
                            <Navbar.Brand
                              style={{
                                color: "#656D78",
                                fontSize: "1rem",
                                fontWeight: "bold",
                              }}
                            >
                              Products to Order
                            </Navbar.Brand>
                            <Nav className="ml-auto">
                              <span className="text-primary font-size-xs float-right mr-3 small">
                                Maximum product section limit is 100
                              </span>{" "}
                            </Nav>
                          </Navbar>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} style={{ paddingBottom: "15px" }}>
                        {this.state.fields.action === "copy" ? (
                          <div className="scroll" style={{ width: "100%" }}>
                            <Table hover>
                              <thead
                                style={{
                                  backgroundColor: "lightblue",
                                  fontSize: "large",
                                }}
                              >
                                <tr>
                                  {this.state.fields.orderType !== GlobalVar.BI
                                    ? this.state.fields.orderType ===
                                      GlobalVar.TB
                                      ? new Variables().thArrayCopyTicketBundlingClean.map(
                                          (prop, key) => {
                                            return <th key={key}>{prop}</th>;
                                          }
                                        )
                                      : new Variables().thArrayCopy.map(
                                          (prop, key) => {
                                            return <th key={key}>{prop}</th>;
                                          }
                                        )
                                    : this.state.fields.cleanBI
                                    ? new Variables().thArrayCopyBillableClean.map(
                                        (prop, key) => {
                                          return <th key={key}>{prop}</th>;
                                        }
                                      )
                                    : new Variables().thArrayCopyBillable.map(
                                        (prop, key) => {
                                          return <th key={key}>{prop}</th>;
                                        }
                                      )}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.fields.orderType !== GlobalVar.BI &&
                                this.state.fields.orderType != GlobalVar.TB
                                  ? this.state.OrderItems.map((prop, key) => {
                                      return (
                                        <tr key={key}>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "200px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"artist" + key}
                                              key={key}
                                            >
                                              {this.state.fields.artist[key]}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "200px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"title" + key}
                                              key={key}
                                            >
                                              {this.state.fields.title[key]}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "200px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"selection" + key}
                                              key={key}
                                            >
                                              {this.state.fields.selection[key]}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "100px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"confiq" + key}
                                              key={key}
                                            >
                                              {" "}
                                              {
                                                this.state.fields.config[key]
                                              }{" "}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0px",
                                              width: "100px",
                                            }}
                                          >
                                            <FormControl
                                              style={{ height: "auto" }}
                                              size="sm"
                                              id={"qty" + key}
                                              type="text"
                                              name="qty"
                                              maxLength={5}
                                              className="form-control input-sm"
                                              key={key}
                                              value={this.state.fields.qty[key]}
                                              onChange={(e) =>
                                                this.handleFormChange(key, e)
                                              }
                                              onBlur={(e) =>
                                                this.handleOnBlurValidateQty(
                                                  key,
                                                  e
                                                )
                                              }
                                            ></FormControl>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : this.state.OrderItems.map((prop, key) => {
                                      return (
                                        <tr key={key}>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "150px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"artist" + key}
                                              key={key}
                                            >
                                              {this.state.fields.artist[key]}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "150px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"title" + key}
                                              key={key}
                                            >
                                              {this.state.fields.title[key]}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "150px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"selection" + key}
                                              key={key}
                                            >
                                              {this.state.fields.selection[key]}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "50px",
                                            }}
                                          >
                                            <FormLabel
                                              style={{ height: "auto" }}
                                              id={"confiq" + key}
                                              key={key}
                                            >
                                              {" "}
                                              {
                                                this.state.fields.config[key]
                                              }{" "}
                                            </FormLabel>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "100px",
                                            }}
                                          >
                                            <FormControl
                                              style={{ height: "auto" }}
                                              size="sm"
                                              id={"qty" + key}
                                              type="text"
                                              name="qty"
                                              maxLength={5}
                                              className="form-control input-sm"
                                              key={key}
                                              value={this.state.fields.qty[key]}
                                              onChange={(e) =>
                                                this.handleFormChange(key, e)
                                              }
                                              onBlur={(e) =>
                                                this.handleOnBlurValidateQty(
                                                  key,
                                                  e
                                                )
                                              }
                                            ></FormControl>
                                          </td>
                                          <td
                                            style={{
                                              padding: "0 0 0 10px",
                                              width: "100px",
                                            }}
                                          >
                                            <FormControl
                                              style={{ height: "auto" }}
                                              size="sm"
                                              id={"price" + key}
                                              type="text"
                                              name="price"
                                              maxLength={7}
                                              className="form-control input-sm"
                                              key={key}
                                              tabIndex={key}
                                              value={
                                                this.state.fields.price[key]
                                              }
                                              onChange={(e) =>
                                                this.handleFormChange(key, e)
                                              }
                                              onBlur={(e) =>
                                                this.ValidatePrice(e, key)
                                              }
                                            ></FormControl>
                                          </td>
                                          {this.state.fields.cleanBI ? (
                                            <>
                                              <td
                                                style={{
                                                  padding: "0 0 0 10px",
                                                  width: "50px",
                                                }}
                                              >
                                                <FormLabel
                                                  style={{
                                                    height: "auto",
                                                    textAlign: "right",
                                                  }}
                                                  id={"cost" + key}
                                                  key={key}
                                                >
                                                  {" "}
                                                  {
                                                    this.state.fields.cost[key]
                                                  }{" "}
                                                </FormLabel>
                                              </td>
                                              <td
                                                style={{
                                                  padding: "0 0 0 10px",
                                                  width: "50px",
                                                }}
                                              >
                                                <FormLabel
                                                  style={{
                                                    height: "auto",
                                                    textAlign: "center",
                                                  }}
                                                  id={"discount" + key}
                                                  key={key}
                                                >
                                                  {" "}
                                                  {
                                                    this.state.fields.discount[
                                                      key
                                                    ]
                                                  }{" "}
                                                </FormLabel>
                                              </td>
                                              {this.state.fields.orderType ===
                                              GlobalVar.TB ? (
                                                <>
                                                  <td
                                                    style={{
                                                      padding: "0 0 0 10px",
                                                      width: "120px",
                                                    }}
                                                  >
                                                    <FormControl
                                                      style={{ height: "auto" }}
                                                      size="sm"
                                                      id={
                                                        "artistStoreName" + key
                                                      }
                                                      type="text"
                                                      name="artistStoreName"
                                                      maxLength={50}
                                                      className="form-control input-sm"
                                                      key={key}
                                                      value={
                                                        this.state.fields
                                                          .artistStoreName[key]
                                                      }
                                                      onChange={(e) =>
                                                        this.handleFormChange(
                                                          key,
                                                          e
                                                        )
                                                      }
                                                    ></FormControl>
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: "0 0 0 10px",
                                                      width: "120px",
                                                    }}
                                                  >
                                                    <FormControl
                                                      style={{ height: "auto" }}
                                                      size="sm"
                                                      id={"tourDate" + key}
                                                      type="text"
                                                      name="tourDate"
                                                      maxLength={10}
                                                      className="form-control input-sm"
                                                      key={key}
                                                      value={
                                                        this.state.fields
                                                          .tourDate[key]
                                                      }
                                                      onChange={(e) =>
                                                        this.handleFormChange(
                                                          key,
                                                          e
                                                        )
                                                      }
                                                      onBlur={(e) =>
                                                        this.handleOnBlurValidateDate(
                                                          key,
                                                          e
                                                        )
                                                      }
                                                    ></FormControl>
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              <td
                                                style={{
                                                  padding: "0px",
                                                  width: "50px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {this.state.fields.flag[key] ? (
                                                  <img
                                                    src={flag}
                                                    alt="Product discount requires EVP approval"
                                                    title="Product discount requires EVP approval"
                                                  ></img>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </tr>
                                      );
                                    })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className="scroll" style={{ width: "100%" }}>
                            <Table hover>
                              <thead
                                style={{
                                  backgroundColor: "lightblue",
                                  fontSize: "large",
                                }}
                              >
                                <tr>
                                  {this.state.fields.orderType !== GlobalVar.BI
                                    ? this.state.fields.orderType ===
                                      GlobalVar.TB
                                      ? new Variables().thArrayTicketBundlingClean.map(
                                          (prop, key) => {
                                            return <th key={key}>{prop}</th>;
                                          }
                                        )
                                      : new Variables().thArray.map(
                                          (prop, key) => {
                                            return <th key={key}>{prop}</th>;
                                          }
                                        )
                                    : this.state.fields.cleanBI
                                    ? new Variables().thArrayBillableClean.map(
                                        (prop, key) => {
                                          return <th key={key}>{prop}</th>;
                                        }
                                      )
                                    : new Variables().thArrayBillable.map(
                                        (prop, key) => {
                                          return <th key={key}>{prop}</th>;
                                        }
                                      )}
                                </tr>
                              </thead>
                              <tbody>
                                {[...Array(100)].map((prop, key) =>
                                  this.formControl(key)
                                )}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Navbar expand="lg" style={navStyle}>
                          <Navbar.Brand
                            style={{
                              color: "#656D78",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            Order Complete
                          </Navbar.Brand>
                          <Nav className="ml-auto">
                            <span className="text-primary font-size-xs float-right mr-3 small">
                              Selected Product:
                              <span style={{ fontWeight: "bold" }}>
                                {this.state.fields.prodCount}/100
                              </span>
                            </span>
                          </Nav>
                        </Navbar>
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "center" }}>
                      <Col sm={7} className="font-size-xs">
                        <FormInputs
                          properties={{
                            id: "reason",
                            label: "Reason for Order",
                            type: "text",
                            name: "reason",
                            maxlength: 100,
                            className: "form-control input-sm form-control-sm",
                            value: this.state.fields.reason,
                            onChange: this.handleChange,
                          }}
                          errorMsg={this.state.errors.reason}
                          fieldRequired={true}
                        />
                      </Col>
                      <Col sm={3}>
                        <div className="text-primary font-size-xs large">
                          Depot does not see these comments
                        </div>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                    <Row>
                      <Col sm={12}>{this.salesType()}</Col>
                    </Row>
                    <Row>
                      <Col sm={2}></Col>
                      {this.orderComplete()}
                      <Col sm={2}></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
