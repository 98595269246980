export class MultiFilter {
    // ignores case-sensitive
    getValue = value => {
        return (typeof value === 'string' ? value.toUpperCase() : value);
    }
    
    //Filter the router list based on the role
    multiFilter(array, filters) {
        const filterKeys = Object.keys(filters);
        return array.filter(item => {
        // filters using the (OR) operator
            return filterKeys.some(key => {
                // ignores an empty filter
                if (!filters[key].length) return true;
                return filters[key].find(filter => this.getValue(filter) === this.getValue(item[key]));
            });
        });
    }

    //Filter the router list based on the role
    multiFilterLike = (item, condition) => {
        const filterKeys = Object.keys(condition);
        return item.filter((eachObj) => {
        return filterKeys.every(eachKey => {
            if (!condition[eachKey].length) {
            return true; // passing an empty filter means that filter is ignored.
            }
            return (condition[eachKey].toString()).toLowerCase().includes((eachObj[eachKey].toString()).toLowerCase());
        });
        });
    };  
}