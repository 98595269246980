import React, { Component, CSSProperties } from "react";
import { Container, Card, Row, Col, Table, Form, Alert, Button, Navbar, Nav, FormText } from "react-bootstrap";
import { Variables } from '../../../Variables/Variables';
import { FormLabelField } from '../../CustomComponents/FormLabelField';
import { NavLink, withRouter } from 'react-router-dom';
import '../../../assets/css/Style.css'
import '../../../assets/css/ViewOrder.css'
import { ViewOrderController } from "../../../Controllers/ViewOrderController"
import { DepotOrderHistoryViewParameter, Istate } from "../../../Model/DepotOrderHistoryView";
import { FormInputs } from "../../CustomComponents/FormInput";
import { DepotOrdersController } from "../../../Controllers/DepotOrdersController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert} from 'react-bootstrap-confirmation';

export class DepotOrderHistoryView extends Component<DepotOrderHistoryViewParameter, Istate> {
  constructor(props: DepotOrderHistoryViewParameter) {
    super(props)
    this.state = {
      lblFields: {
        Order_Id: '', Alias: '', Process_Name: '', ArriveBy: '', BusinessUnit: '', SpecialInstructions: '', Reason: '', DepotComments: '', LegacyOrder: '',
        Status_Id: '', DateShipped: '', ShipTrackNo: '', Name: '', ShipToName: '', AddressLineOne: '', AddressLineTwo: '', City: '', State: '',
        Zip: '', Country: '', Phone: '', Requestor_Weight: '', Approver_Id: '', Approval_Date: '', Request_Date: '', ApproverComments: '',
        Label_Id: '', Super_Label_Id: '', IP_Address: '', Material: false, Date_Processed: '', Depot_Id: '', Department: '', ApproveBy_Clean: '', ApproveBy_Overnight: '',
        Overnight: '', CustomerNumber: '', RecordLock: '', Depot_Printer: '', Depot_Printer_Date: '', Ship_Method: '', Billable: false, Created: '',
        account_no: '', freight_billing_info: '', alias_Email: '', alias_phone: '', StatusDesc: '', status_id: '', Recordlabel_Id: '', ntbillablet: false,legacyordertxt: '', comments: ''
      },
      lblApproverFields: { approver_Alias: '', approver_Name: '', sales_type: '', alias_Name: '', bill_to_name: '', bill_to_address: '', bill_to_city_state_zip: '' },
      errors: {},
      errMsg: "",
      Order_id: "",
      alias: "",
      lblNextApprover: "",
      lblPageTitle: "",
      salesType: "",
      requestDate: "",
      displayOrdDetListGrid: [],
      displayAppHistoryList: [],
      displayDepotHistoryList: [],
      displayNxtLevelApprover:[],
      loading: false,
      modal: {modalSpinnerShow: false}
    }
  }

  componentWillMount() {
    

    sessionStorage.setItem('DepotHistoryView', '1' )

    let modal = this.state.modal;
    //Load Spinner
    modal['modalSpinnerShow']=true;
    this.setState({ modal });

    let oId = "";
    if (this.props.location.state.Order_id != "") {
      oId = this.props.location.state.Order_id;
    }
    this.setState({ Order_id: oId })
    this.loadOrderData()

  }

  loadOrderData() {
    
    let viewOrderController = new ViewOrderController();
    let lblFields = this.state.lblFields;
    viewOrderController.getOrderSummary(this.props.location.state.Order_id).then(resOrderdata => {
      lblFields["Order_Id"] = resOrderdata.Order_Id || "";
      lblFields["Alias"] = resOrderdata.Alias || "";
      lblFields["Process_Name"] = resOrderdata.Process_Name || "";
      lblFields["ArriveBy"] = resOrderdata.ArriveBy || "";
      lblFields["BusinessUnit"] = resOrderdata.BusinessUnit || "";
      lblFields["SpecialInstructions"] = resOrderdata.SpecialInstructions || "";
      lblFields["Reason"] = resOrderdata.Reason || "";
      lblFields["DepotComments"] = resOrderdata.DepotComments || "";
      lblFields["LegacyOrder"] = resOrderdata.LegacyOrder || "";
      lblFields["Status_Id"] = resOrderdata.Status_Id || "";
      lblFields["DateShipped"] = resOrderdata.DateShipped || "";
      lblFields["ShipTrackNo"] = resOrderdata.ShipTrackNo || "";
      lblFields["Name"] = resOrderdata.Name || "";
      lblFields["ShipToName"] = resOrderdata.ShipToName || "";
      lblFields["AddressLineOne"] = resOrderdata.AddressLineOne || "";
      lblFields["AddressLineTwo"] = resOrderdata.AddressLineTwo || "";
      lblFields["City"] = resOrderdata.City || "";
      lblFields["State"] = resOrderdata.State || "";
      lblFields["Zip"] = resOrderdata.Zip || "";
      lblFields["Country"] = resOrderdata.Country || "";
      lblFields["Phone"] = resOrderdata.Phone || "";
      lblFields["Requestor_Weight"] = resOrderdata.Requestor_Weight || "";
      lblFields["Approver_Id"] = resOrderdata.Approver_Id || "";
      lblFields["Approval_Date"] = resOrderdata.Approval_Date || "";
      lblFields["Request_Date"] = resOrderdata.Request_Date || "";
      lblFields["ApproverComments"] = resOrderdata.ApproverComments || "";
      lblFields["Label_Id"] = resOrderdata.Label_Id || "";
      lblFields["Super_Label_Id"] = resOrderdata.Super_Label_Id || "";
      lblFields["IP_Address"] = resOrderdata.IP_Address || "";
      lblFields["Material"] = Boolean(Number(resOrderdata.Material));
      lblFields["Date_Processed"] = resOrderdata.Date_Processed || "";
      lblFields["Depot_Id"] = resOrderdata.Depot_Id || "";
      lblFields["Department"] = resOrderdata.Department || "";
      lblFields["ApproveBy_Clean"] = resOrderdata.ApproveBy_Clean || "";
      lblFields["ApproveBy_Overnight"] = resOrderdata.ApproveBy_Overnight || "";
      lblFields["Overnight"] = resOrderdata.Overnight || "";
      lblFields["CustomerNumber"] = resOrderdata.CustomerNumber || "";
      lblFields["RecordLock"] = resOrderdata.RecordLock || "";
      lblFields["Depot_Printer"] = resOrderdata.Depot_Printer || "";
      lblFields["Depot_Printer_Date"] = resOrderdata.Depot_Printer_Date || "";
      lblFields["Ship_Method"] = resOrderdata.Ship_Method || "";
      lblFields["Billable"] = Boolean(Number(resOrderdata.Billable));
      lblFields["Created"] = resOrderdata.Created || "";
      lblFields["account_no"] = resOrderdata.account_no || "";
      lblFields["freight_billing_info"] = resOrderdata.freight_billing_info || "";
      lblFields["alias_Email"] = resOrderdata.alias_Email || "";
      lblFields["alias_Name"] = resOrderdata.alias_Name || "";
      lblFields["alias_phone"] = resOrderdata.alias_phone || "";
      lblFields["StatusDesc"] = resOrderdata.StatusDesc || "";
      lblFields["status_id"] = resOrderdata.status_id || "";
      lblFields["Recordlabel_Id"] = resOrderdata.Recordlabel_Id || "";
      lblFields["ntbillablet"] = Boolean(Number(resOrderdata.ntbillablet));
      lblFields["sales_type"] = resOrderdata.sales_type || "";
      lblFields["bill_to_name"] = resOrderdata.bill_to_name || "";
      lblFields["bill_to_address"] = resOrderdata.bill_to_address || "";
      lblFields["bill_to_city_state_zip"] = resOrderdata.bill_to_city_state_zip || "";
      this.setState({ lblFields });

      if (this.state.lblFields["Country"] == 'USA')
          {
            this.state.lblFields["comments"] = ''
          }
          else
          {
            this.state.lblFields["comments"] = ''
          }
          this.setState({ lblFields });

      this.loadApproverData()

    }).catch(error => {

    })

    this.onSubmit = this.onSubmit.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

  }

  /*

  loadApproverData() {
    let viewOrderController = new ViewOrderController();
    let depotOrderController = new DepotOrdersController();
    let lblApproverFields = this.state.lblApproverFields;
    var data = {
      Order_ID: this.props.location.state.Order_id
  }
  depotOrderController.getDepotNextApprover(this.props.location.state.Order_id).then(resNxtApproverdata => {
     // depotOrderController.getNextApprover(data).then(jsonNxtAppr => {

      this.setState({                           
        displayNxtLevelApprover : JSON.parse(JSON.stringify(resNxtApproverdata))
    });

      let approver = "";
      approver = resNxtApproverdata.Approver
      lblApproverFields["approver_Alias"] = resNxtApproverdata.alias || "";
      lblApproverFields["approver_Name"] = resNxtApproverdata.Approver || "";
     
     
      let lblNextApprover = ""
      this.state.displayNxtLevelApprover.map((prop, key) => {        
        if(prop["Approver"] != "")
        {
          if(approver === "")
          {
          approver = prop["Approver"];
          }
          else
          {
          approver = approver + "," + prop["Approver"];
          }          
        }     
      })

      lblNextApprover = this.state.lblFields.StatusDesc + " (" + approver + " )"


      this.loadLabelData()
      this.loadOrdDetails()
      this.loadApprovalHistory()
      this.loadDepotHistory()
      this.setState({ lblNextApprover: lblNextAppr[over });
      this.setState({ lblApproverFields });

      
    }).catch(error => {

    })

    let modal = this.state.modal;
      modal['modalSpinnerShow']=false;
      this.setState({ modal });

  }

*/

loadApproverData() {
  let viewOrderController = new ViewOrderController();
  let depotOrderController = new DepotOrdersController();
  let lblApproverFields = this.state.lblApproverFields; 
  let modal = this.state.modal;     
  viewOrderController.getNextApprover(this.props.location.state.Order_id).then(jsonNxtApproverdata=>{
   
    this.setState({                           
      displayNxtLevelApprover : JSON.parse(JSON.stringify(jsonNxtApproverdata))
  });
  
  let approver = ""; 
 
 this.state.displayNxtLevelApprover.map((prop, key) => {        
    if(prop["Approver"] != "")
    {      
      if(approver === "")
      {
      approver = prop["Approver"];
      }
      else
      {
        
      approver = approver + "," + prop["Approver"];
      }          
    }     
  })       
             
  let lblNextApprover = ""
  lblNextApprover = this.state.lblFields.StatusDesc + " (" + approver + " )"
  this.loadLabelData()
        
    this.setState({lblNextApprover :lblNextApprover});  
    this.setState({lblApproverFields});
    
    if(!this.state.displayNxtLevelApprover.length)
    {
/**/ 
    viewOrderController.getApprovalHistory(this.props.location.state.Order_id).then(jsonAppHisList=>{
      this.setState({                           
        displayAppHistoryList : JSON.parse(JSON.stringify(jsonAppHisList))
    });
          viewOrderController.getOrderDetails(this.props.location.state.Order_id).then(jsonOrdDetList=>{
            this.setState({                           
              displayOrdDetListGrid : JSON.parse(JSON.stringify(jsonOrdDetList))
          });
                  viewOrderController.getOrderDepotHistory(this.props.location.state.Order_id).then(jsonDepotHisList=>{
                    this.setState({                           
                      displayDepotHistoryList : JSON.parse(JSON.stringify(jsonDepotHisList))
                  })
                    
                    //Stop Spinner
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal });

                  }).catch(error => {
                    console.error("There was an error loading Approval History")
                  })
          }).catch(error => {
            console.error("There was an error loading Order Details")
          })
     }).catch(error => {
      console.error("There was an error loading Approval History")
    })
       /* */   
  } 
  else{
    this.loadOrdDetails()
    this.loadApprovalHistory()
    this.loadDepotHistory()
  }
    
    
  }).catch(error => {

  })
  modal['modalSpinnerShow']=false;
  this.setState({ modal });
}



  loadLabelData() {
    let billable = this.state.lblFields.Billable
    let ntBillable = this.state.lblFields.ntbillablet
    var str = this.state.lblFields.sales_type
    var salesType = str.split(" ");
    this.setState({ salesType: salesType[0] })
    var dateString = this.state.lblFields.Request_Date
    var date = new Date(dateString);

    var curr_date = date.getDate();
    var curr_month = date.getMonth() + 1; //Months are zero based
    var curr_year = date.getFullYear();
    var reqDate = curr_month + "/" + curr_date + "/" + curr_year;
    this.setState({ requestDate: date.toLocaleString() })
    if (billable) {
      this.setState({ lblPageTitle: "BILLABLE ORDER REQUEST FORM" })
      //sProcessName=dataSet.spGetOrderSummary[0].Process_Name;
    }
    else if (!billable && !ntBillable) {
      this.setState({ lblPageTitle: "FREE GOODS REQUEST FORM" })
      //this place needs to be change
    }
    else {
      this.setState({ lblPageTitle: "NON BILLABLE TRANSFER FORM" })
    }
    if (str === '66 Ticket Bundling')
    {
      this.setState({ lblPageTitle: "Ticket Bundling" })
    }
    if (str === '89 Sales Type')
    {
      this.setState({ lblPageTitle: "D2C FORM" })
    }
  }

  loadOrdDetails() {
    let depotsearchController = new DepotOrdersController();
    depotsearchController.getDepotOrderDetail(this.props.location.state.Order_id).then(jsonOrdDetList => {
      this.setState({
        displayOrdDetListGrid: JSON.parse(JSON.stringify(jsonOrdDetList))
      });
    }).catch(error => {
      console.error("There was an error loading Order Details")
    })
  }

  loadApprovalHistory() {
    let viewOrderController = new ViewOrderController();
    viewOrderController.getApprovalHistory(this.props.location.state.Order_id).then(jsonAppHisList => {
      this.setState({
        displayAppHistoryList: JSON.parse(JSON.stringify(jsonAppHisList))
      });
    }).catch(error => {
      console.error("There was an error loading Approval History")
    })
  }

  loadDepotHistory() {
    
    let viewOrderController = new ViewOrderController();
    viewOrderController.getOrderDepotHistory(this.props.location.state.Order_id).then(jsonDepotHisList => {
      this.setState({
        displayDepotHistoryList: JSON.parse(JSON.stringify(jsonDepotHisList))
      });
    }).catch(error => {
      console.error("There was an error loading Approval History")
    })
  }

  handletextChange (e: any) {
    let lblFields = this.state.lblFields;

    if (e.currentTarget.id === "legacyordertxt")
     {
      lblFields.legacyordertxt = e.currentTarget.value; 
      
    }
    else if (e.currentTarget.id === "comments")
    {
      lblFields.comments =  e.currentTarget.value;
    }

    this.setState({
      lblFields
  })
  //alert(lblFields)

  }

  handleSelectChange = (item, e: any) => {
    let lblFields = this.state.displayOrdDetListGrid;
    item["ItemException"] = e.currentTarget.value
    this.setState({
      lblFields
    })
    
}
   
  onSubmit = (e: any) => {
    this.setState({ loading: true });

    var legacyorder
    var Comments
    var orderid
    let StatusDesc = this.state.lblFields.StatusDesc

    legacyorder = this.state.lblFields.legacyordertxt
    Comments = this.state.lblFields.comments
    orderid = this.state.lblFields.Order_Id           

    let depotOrdersController = new DepotOrdersController();

    if (StatusDesc === 'Depot Pending')
    {
      if (legacyorder === '')
      {
        alert('Legacy Order Number is required!')
      }
      else
      {
        
        var depot = {
          OrderId : this.state.lblFields.Order_Id,
          depotCmts : this.state.lblFields.comments,
          legacyOrder : this.state.lblFields.legacyordertxt      
          
        }
     
        depotOrdersController.depotProcessOrder(depot).then(response => {
          if (response.success) {
           
              alert('Depot Order has been processed');
              this.props.history.push('depot_history');
          }
      }).catch(error => {

      })
      }
    }
    else if (StatusDesc === 'Depot Processed')
    {
      if(Comments === '')
      {
        alert('Comments are required!')
      }
      else
      {
        var depot = {
          OrderId : this.state.lblFields.Order_Id,
          depotCmts : this.state.lblFields.comments,
          legacyOrder : this.state.lblFields.legacyordertxt 
          
        }
        depotOrdersController.depotProcessOrder(depot).then(response => {
          if (response.success) {
              alert('Depot Order has been processed');
              this.props.history.push('depot_history');
          }
      }).catch(error => {

      })
      }

    }

    else
    {
      var depot = {
        OrderId : this.state.lblFields.Order_Id,
        depotCmts :this.state.lblFields.comments,
        legacyOrder : this.state.lblFields.legacyordertxt
        
      }
     
        depotOrdersController.depotProcessOrder(depot).then(response => {
            if (response.success) {
                alert('Depot Order has been Hold');
                this.props.history.push('depot_history');
            }
        }).catch(error => {

        })

    this.setState({ loading: false });

      }

      this.depotEmailRequestor();

  }

  loadShipTo() {
    return (
      <div>
        <p className="small">{this.state.lblFields.Name}</p>
        <p className="small">{this.state.lblFields.ShipToName}</p>
        <p className="small">{this.state.lblFields.AddressLineOne}</p>
        <p className="small">{this.state.lblFields.AddressLineTwo}</p>
        <p className="small">{this.state.lblFields.City}&nbsp;{this.state.lblFields.State}&nbsp;{this.state.lblFields.Zip}</p>
        <p className="small">{this.state.lblFields.Country}</p>
        <p className="small">{this.state.lblFields.Phone}</p>
      </div>
    );
  }

  loadBillTo() {
    return (
      <div >
        <p className="small">{this.state.lblFields.bill_to_name}</p>
        <p className="small">{this.state.lblFields.bill_to_address}</p>
        <p className="small">{this.state.lblFields.bill_to_city_state_zip}</p>
      </div>
    );
  }

  orderedBy(){
   // <p className="small">{this.state.lblFields.alias_Email}</p>
    return (
      <div >
        <p className="small">{this.state.lblFields.alias_Name}</p>
        <p className="small">{this.state.lblFields.Label_Id}</p>
        <p className="small">{this.state.lblFields.alias_phone}</p>
        
        <NavLink 
            to={{
              pathname:'mailto:' + this.state.lblFields.alias_Email +' ?subject=eGratis&body='
            }}                                        
            activeClassName="active"
            target="_blank"
          >{this.state.lblFields.alias_Email}</NavLink>
      </div>
    );
  }

  displayItemException = (prop, key) => {

    return <td style={{ padding: '0px' }}>
     
       <Form.Control as="select" style={{ height: 'auto' }} value={prop["ItemException"][key]} onChange={(e) => { this.handleSelectChange(prop, e) }}  size="sm" id="itemexception" >      
        <option value="0" >Select Exception Reason</option>
        <option value="5">Back Order</option>
        <option value="11">Restricted by Legacy</option>
        <option value="12">Pre-Street Date</option>
        <option value="15">See Comments</option>
        <option value="6">Cut Out</option>
        <option value="18">Backorder/Cancelled</option>
      </Form.Control>
  
    </td>

  }

  displayOrderItemsTable = () => {
    let StatusDesc = this.state.lblFields.StatusDesc

    if (StatusDesc === 'Depot Pending') {
      return this.displayNonBillableOrderTable();
      
    }
    else {
      return this.displayBillableOrderTable();
    }

  }

  displayDepotstatusText =() => {
    let StatusDesc = this.state.lblFields.StatusDesc

    if (StatusDesc === 'Depot Pending' || StatusDesc === 'Depot Processed' ) {
      return this.displayDepotPendingtext();     
    }

  }

  displayDepotstatusButton =() => {
    let StatusDesc = this.state.lblFields.StatusDesc

    if (StatusDesc === 'Depot Pending'|| StatusDesc === 'Depot Processed' ) {
      return this.orderItemsTableForButton();     
    }
  }

  

depotEmailRequestor()
{

  var orderDetail : string = ""; 
  var sTo: string = "";
  var sBody: string = "";
  var sSubject: string = "";
  var sWebServer: string = "";

  
  let depotOrdersController = new DepotOrdersController();
  let viewOrderController = new ViewOrderController();
  

  var depot = {
    OrderId : this.state.lblFields.Order_Id  
    
  }

  

viewOrderController.getOrderSummary(this.props.location.state.Order_id).then(resOrderdata => {

  depotOrdersController.getDepotOrderItemDetail(depot).then(resOrderDetail =>{
        resOrderDetail.recordset.map((prop,key)=>{
            orderDetail += "<p>" + "Selection: " + prop["Selection_Id"];
    orderDetail += "<br>" + "Artist: " + prop["Artist"];
    orderDetail += "<br>" + "Title: " + prop["Title"];
    orderDetail += "<br>" + "Configuration: " + prop["Configuration"];
    orderDetail += "<br>" + "Qty Req: " + prop["Quantity_Requested"]; 
    orderDetail += "<br>" + "Qty App: " + prop["Quantity_Approved"];
            
        })

        sTo = resOrderdata.alias_Email;
        if (sTo.length == 0) {
            sTo="egratis@umusic.com";
        }

  sBody = "Order #: " + resOrderdata.Order_Id + "<br>";
  sBody = "Legacy Order #: " + resOrderdata.LegacyOrder + "<br>";
  sBody += "Status: " + resOrderdata.StatusDesc + "<br>";
  sBody += "Ship To: " + resOrderdata.Name+ " at " + resOrderdata.ShipToName + "<br>";
  sBody += "Depot Comments: " + resOrderdata.DepotComments+  "<br>";
       			
        
            sBody += "Thank you for using eGratis!";

            sWebServer = window.location.hostname.toUpperCase();
            if (sWebServer=="USUSH2KVWS11")	sSubject="(predev) ";
            if (sWebServer=="USUSHWVIIS001") sSubject="(dev) ";
            if (sWebServer=="USUSHWVIIS002") sSubject="(test) ";
            if (sWebServer=="USUSH2KAP17" || sWebServer=="USUSHWVIIS010") sSubject="(staging) ";
                    

            depotOrdersController.sendEmail("egratis@umusic.com", sTo, sSubject,sBody);
            
        }).catch(error =>{

            console.error('There was an error!', error);
        });

    }).catch(error =>{
        console.error('There was an error!', error);
    });
  


}


  displayDepotPendingtext = () => {
    return <Row>

    {this.state.lblFields.StatusDesc === 'Depot Pending' ?
    
      <Col sm={4}>
        <FormInputs
          properties={{ id: "legacyordertxt", label: "Legacy Order", type: "text", name: "legacyordertxt", className: "form-control input-sm", maxlength: 25, onChange : this.handletextChange, value : this.state.lblFields.legacyordertxt }}
          fieldRequired={false}
        />
      </Col>
      :
      ''
    }
      <Col sm={4}>
        <Form.Group>
          <Form.Label>Comments</Form.Label>
          <Form.Control as="textarea" rows={3} id="comments" name="comments" className="form-control" onChange={this.handletextChange}  value={this.state.lblFields.comments} ></Form.Control>
        </Form.Group>
      </Col>
      <Col sm={4}>
        
      </Col>
    </Row>

  }

  orderItemsTableForButton = () => {
    const { loading } = this.state;

    return <div className="form-group mt-4 mb-0 text-center">
      <Row>
        <Col sm={10} style={{ alignSelf: "center" }}>
          <Button onClick={this.onSubmit} disabled={loading} style={{ marginRight: "5px" }}>
            {loading && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            )}
            {loading && <span style={{ color: "#3472F7" }}>wait...</span>}
            {!loading && <span>Save</span>}
          </Button>

        </Col>
      </Row>
    </div>
  }

  displayBillableOrderTable = () => {
    const labelFont = {
      fontSize: "5px",
    };
    return <Row>
      <Col sm={12} className="small">        
          <Table>
            <thead>
              <tr>
               <th>Catalog #</th>
               <th>Artist</th>
               <th>Title</th>
               <th>Config</th>
               <th>Qty</th>
               {(this.state.lblFields.Billable) ?
                <th>Price</th>
                :
                ''
               }
               <th>Item Exception</th>
              </tr>
            </thead>
            <tbody >
              {this.state.displayOrdDetListGrid.map((prop, key) => {
                return (
                  <tr className="small" style={labelFont} key={key}>
                    <td >{prop["Selection_Id"]}</td>
                    <td>{prop["Artist"]}</td>
                    <td>{prop["Title"]}</td>
                    <td>{prop["Configuration"]}</td>
                    <td>{prop["Quantity_Approved"]}</td>

                    {(this.state.lblFields.Billable) ?
                      <td> $ {prop["Price_Approved"]} </td>
                      : ""}

                    <td>{prop["itemStatusDesc"]} </td>

                  </tr>
                )
              })}
            </tbody>
          </Table>       
      </Col>
    </Row>

  }

  displayNonBillableOrderTable = () => {
    const labelFont = {
      fontSize: "5px",
    };
    return <Row>
      <Col sm={12} className="small">
        
          <Table>
            <thead>
              <tr>
              <th>Catalog #</th>
               <th>Artist</th>
               <th>Title</th>
               <th>Config</th>
               <th>Qty</th>
               {(this.state.lblFields.Billable) ?
                <th>Price</th>
                :
                ''
               }
               <th>Item Exception</th>
              </tr>
            </thead>
            <tbody >
              {this.state.displayOrdDetListGrid.map((prop, key) => {
                return (
                  <tr className="small" style={labelFont} key={key}>
                    <td >{prop["Selection_Id"]}</td>
                    <td>{prop["Artist"]}</td>
                    <td>{prop["Title"]}</td>
                    <td>{prop["Configuration"]}</td>
                    <td>{prop["Quantity_Approved"]}</td>

                    {(this.state.lblFields.Billable) ?
                      <td> $ {prop["Price_Approved"]} </td>
                      : ""}
                    <td>{this.displayItemException(prop, key)} </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
       
      </Col>
    </Row>
  }


  convertDateTimeFormat = (item) => {
    var dt = item["CreateDateTime"];
    var date = new Date(dt);
   
    if (date.toLocaleString() !== 'Invalid Date')
    {
      return date.toLocaleString();
    }
  }

  convertApprDateTimeFormat = (item) => {
    var dt = item["ApprovalDateTime"];
    var date = new Date(dt);
     
    if (date.toLocaleString() !== 'Invalid Date')
    {
      return date.toLocaleString();
    }
    
  }

  render() {

    const bottomBorder = {
      borderBottom: "1px solid rgb(212, 212, 212)"
    };
    const labelFont = {
      fontSize: "5px",
    };
    return (
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow }>   </FormModalSpinner>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card border="light" style={{ height: '100%' }}>
                <Card.Body>
                  <Card.Title><h4 className="title"></h4></Card.Title>
                  <Button type="button" style={{marginBottom: '15px'}} onClick={() => this.props.history.goBack()} >Back</Button>
                  <form>
                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                    <Row style={bottomBorder}>
                      <Col>
                        <Row className="small">
                          <Col sm={6} className="small">
                            <FormLabelField
                              properties={{ label: "Next Step:", id: "nextStep", name: "nextStep", className: "form-control", value: this.state.lblNextApprover }}
                            />
                          </Col>
                          <Col sm={6} className="small">
                            <FormLabelField
                              properties={{ label: "Legacy Order:", id: "legacyOrder", name: "legacyOrder", className: "form-control", value: this.state.lblFields.LegacyOrder }}
                            />
                          </Col>
                        </Row>
                        <Row className="small">
                          <Col sm={6} className="small">
                            <Form.Label>Approvers:</Form.Label>
                            {this.state.displayAppHistoryList.map((prop, key) => {
                              return (
                                <tr key={key}>
                                  <td>{this.convertApprDateTimeFormat(prop)}&nbsp;:&nbsp;{prop["Approver"]}</td>
                                </tr>
                              )
                            })}
                          </Col>
                          <Col sm={6} className="small">
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row style={bottomBorder} className="small">
                      <Col sm={12} className="align-center">
                        <FormLabelField
                          properties={{ label: "", id: "lblPageTitle", name: "lblPageTitle", className: "form-control", value: this.state.lblPageTitle }}
                        />
                        <h4 className="align-center" style={{ color: 'maroon' }}>{this.state.salesType}</h4>
                      </Col>
                    </Row>
                    <Row style={bottomBorder} className="small">
                      <Col sm={3} className="small">
                        <FormLabelField
                          properties={{ label: "Date:", id: "reqDate", name: "reqDate", className: "form-control", value: this.state.requestDate }}
                        />
                      </Col>
                      <Col sm={3} className="small">
                        <FormLabelField
                          properties={{ label: "Customer P.O:", id: "customerPo", name: "customerPo", className: "form-control", value: this.state.lblFields.Order_Id }}
                        />
                      </Col>
                      <Col sm={3} className="small">
                        <FormLabelField
                          properties={{ label: "Sales Type:", id: "salesType", name: "salesType", className: "form-control", value: this.state.lblFields.sales_type }}
                        />
                      </Col>
                      <Col sm={3} className="small">
                        <FormLabelField
                          properties={{ label: "Shipment Method:", id: "shipMethod", name: "shipMethod", className: "form-control", value: this.state.lblFields.Ship_Method }}
                        />
                      </Col>
                    </Row>
                    <Row style={bottomBorder} className="small">
                      <Col className="small">
                        <Row>
                          <Col sm={3}>
                          <FormLabelField
                              properties={{ label: "Account No:", id: "accNo", name: "accNo", className: "form-control", value: this.state.lblFields.account_no }}
                            />
                          </Col>
                          <Col sm={3}>
                          <FormLabelField
                              properties={{ label: "Customer No:", id: "cusNo", name: "cusNo", className: "form-control", value: this.state.lblFields.CustomerNumber }}
                            />
                          </Col>
                          <Col sm={3}>
                          <FormLabelField
                              properties={{ label: "Business Unit:", id: "busUnit", name: "busUnit", className: "form-control", value: this.state.lblFields.BusinessUnit }}
                            />
                          </Col>
                          <Col sm={3}>
                          <FormLabelField
                              properties={{ label: "SPecial Instructions:", id: "spIns", name: "spIns", className: "form-control", value: this.state.lblFields.SpecialInstructions }}
                            />
                          </Col>
                        </Row>                        
                      </Col>
                    </Row>
                    <Row style={bottomBorder} className="small">
                      <Col sm={4} >
                        <Form.Label>Bill To:</Form.Label>
                        {this.loadBillTo()}
                      </Col>
                      <Col sm={4}>
                        <Form.Label>Ship To:</Form.Label>
                        {this.loadShipTo()}
                      </Col>
                      <Col sm={4}>
                      <Form.Label>Ordered By:</Form.Label>
                        {this.orderedBy()}
                      </Col>
                    </Row>
                    <Row style={bottomBorder} className="small">
                      <Col sm={12}>
                        <Row>
                          <Col sm={3} className="small">
                            <FormLabelField
                              properties={{ label: "Order Id:", id: "ordId", name: "ordId", className: "form-control", value: this.state.lblFields.Order_Id }}
                            />
                          </Col>
                          <Col sm={3} className="small">
                            <FormLabelField
                              properties={{ label: "Label:", id: "label", name: "label", className: "form-control", value: this.state.lblFields.Label_Id }}
                            />
                          </Col>
                          {(this.state.lblFields.Billable) ?
                                <Col sm={3}>
                                <FormLabelField
                                    properties={{ label: "Freight Billing information:", id: "fBInfo", name: "fBInfo", className: "form-control", value: this.state.lblFields.freight_billing_info}}
                                  />
                                </Col>:''}
                          <Col sm={3} className="small">
                        <FormLabelField
                          properties={{ label: "Requestor Reason:", id: "reason", name: "reason", className: "form-control", value: this.state.lblFields.Reason }}
                        />
                      </Col>
                        </Row>                        

                      </Col>
                    </Row>
                                        
                        {this.displayOrderItemsTable()}
                        {this.displayDepotstatusText()}
                        {this.displayDepotstatusButton()}
                   
                        <Row><Col sm={12} style={{ padding: '10px' }}></Col></Row>

                        <Row style={bottomBorder} className="small">
                          <Col sm={5}>                          
                            <Table className="small">
                              <thead>
                              <tr>
                              {new Variables().thDepotHistoryList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}                   
                              </tr>                         
                            </thead>
                            <tbody>                           
                                {this.state.displayDepotHistoryList.map((prop, key) => { 
                                    return (
                                      <tr style={labelFont} key={key}>                                                                         
                                      <td>{this.convertDateTimeFormat(prop)}</td> 
                                      
                                      <td>{prop["Notes"]}</td>                                   
                                      </tr>
                                    )            
                                    })} 
                                     </tbody>  
                            </Table>
                               
                          </Col>
                              <Col sm={7}>                          
                            <Table className="small">
                              <thead>
                              <tr>
                              {new Variables().thApproverList.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}                   
                              </tr>                         
                            </thead>
                            <tbody> 
                            {this.state.displayAppHistoryList.map((prop, key) => { 
                                    return (
                                      <tr style={labelFont} key={key}>
                                       <td>{prop["ApproverType"]}</td>
                                      <td>{prop["Approver"]}</td>                                   
                                      <td>{this.convertApprDateTimeFormat(prop)}</td> 
                                      <td>{prop["Notes"]}</td>                                   
                                      </tr>
                                    )            
                                    })} 

                              </tbody>  
                            </Table>
                               
                          </Col>
                        </Row>     
                    
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withRouter(DepotOrderHistoryView);