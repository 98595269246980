import React, { Component } from "react";
import logo from "../../../assets/img/UMG_Logo.png";

export class LoginNavBar extends Component<ILoginNavBar,Istate> {
  constructor(objLoginNavBar:ILoginNavBar) {
    super(objLoginNavBar);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
        //if((this!=undefined){
      //(this as HTMLElement).parentElement.removeChild(this);
        //}
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  render() {
    const headerbgColor = {
      "background-color": "#24305E",
      "color":"white"
    };
    return (
      <div className="panel-heading" style={headerbgColor}>
        <div className="container-fluid">
          <div className="pull-left" >
            <img src={logo} width="100px" height="40px" alt="" style={{marginTop:"10px"}}/>
          </div>
          <h5 className="right" style={{marginTop:"20px"}}>{this.props.brandText}</h5>
        </div>
      </div>
    );
  }
}

interface ILoginNavBar
{
    brandText:string;
}
interface Istate
{
    sidebarExists:boolean
}

        
