import React, { Component } from "react";
import { Container, Card, Row, Col, Table, Form, Alert, Button, Navbar, Nav } from "react-bootstrap";
import { History, LocationState } from "History";
import { UserViewController } from "../../../Controllers/UserViewController"
import { format } from 'date-fns'
import { Variables } from '../../../Variables/Variables';
import { NavLink } from 'react-router-dom'
import '../../../assets/css/Style.css'
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { DepotOrderParameter, Istate } from "../../../Model/DepotOrderUpdate";
import { DepotOrdersController } from "../../../Controllers/DepotOrdersController";
import {alert} from 'react-bootstrap-confirmation';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';

export class DepotOrderUpdate extends Component<DepotOrderParameter, Istate> {
  constructor(props: DepotOrderParameter) {
    super(props)
    this.state = {
      fields: { find: '', searchType: '', isActive: [], OrderId: [], userName: '' },
      optionfields: { checkid: false },
      checkfields: { selectorder: '' },
      errors: { find: '', searchType: '', checkid: '' },
      errMsg: "",
      todayDate: "",
      previousdate: "",
      Order_id: "",
      goodsReq: "",
      loading: false,
      color1: "",
      deptOrdGrid: [],
      selected: {},
      orderItem: [],
      modal: {modalSpinnerShow: false}
    }

    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {

    let modal = this.state.modal;
        //Load Spinner
        modal['modalSpinnerShow']=true;
        this.setState({ modal });

    sessionStorage.setItem('DepotHistoryView', '0' ) 

    var newDate = new Date();
    var pdate = new Date();
    pdate.setDate(pdate.getDate() - 45);

    this.state.checkfields.checkid = false;


    let depotOrdersController = new DepotOrdersController();
    depotOrdersController.getDepotPendingOrders().then(jsonDepotOrders => {
      this.setState({
        deptOrdGrid: JSON.parse(JSON.stringify(jsonDepotOrders)), loading: false
      });
    }).catch(error => {
      this.setState({ loading: false });
      console.error("There was an error loading in search product")
    });

   // let DU
   // DU = sessionStorage.getItem("DepotUpdateView")  
   //    sessionStorage.setItem('DepotUpdateView', '0' )    
   //    if ( DU==='1')
   //    {
   //     this.setState({ 
    //      deptOrdGrid: JSON.parse(sessionStorage.getItem('DepotUpdate') || "") 
    //    });
    //  }

     modal = this.state.modal;
     modal['modalSpinnerShow']=false;
     this.setState({ modal });

  }

  handleCheckChange = (key, e: any) => {
    let fields = this.state.fields;
    var orderItem: Array<{ "Billable": string; "Order_Id": string; }>
    if (e.currentTarget.checked) {
      fields["isActive"][key] = true;
      fields["OrderId"] = e.currentTarget.value;

    }
    else {
      fields["isActive"][key] = false;
      fields["OrderId"] = ""
    }

    if (e.target.checked) {

      this.setState({
        orderItem: [...this.state.orderItem, e.target.value],
      }, () => {
        //alert(this.state.orderItem)
      });
    }
    else {
      let remove = this.state.orderItem.indexOf(e.target.value);
      this.setState({
        orderItem: this.state.orderItem.filter((_, i) => i !== remove)
      },
        () => {
          // alert(this.state.orderItem)
        }
      );
    }

    this.setState({
      fields
    });

  }



  onSubmit = (e: any) => {
    this.setState({ loading: true });
    e.preventDefault();


    if (this.state.orderItem.length == 0) {
      alert("Please select atleast one order")
    }

    else {

      const r = window.confirm("Do you really want to Cancel Order?");
      if (r == true) {

        var data = {
          userName: "dkass",
          orderItem: this.state.orderItem
        }

        let depotOrdersController = new DepotOrdersController();
        depotOrdersController.cancelPendingOrders(data).then(response => {
          if (response.success) {
            //this.props.history.push('/admin/depot_pending_orders');
            window.location.reload(false);
          }
        }).catch(error => {
          this.setState({ errMsg: "Cancel Depot Order Failed" });
        })
      }

    }

    this.setState({ loading: false });

  }


  getItemInfo = (item) => {
    
    if (item == "1") {
      return "Clean";
    }
    else {
      return ""
    }
  };

  displayTableRecord = () => {
    let stateCnt: number = this.state.deptOrdGrid.length;
  

    if (stateCnt > 0) {
      return this.state.deptOrdGrid.map((prop, key) => {
        return <tr id={'trchorder' + key} style={
          this.state.fields.isActive[key] === true
            ? { background: 'red' }
            : {}
        }>
          <td>
            <input type='checkbox' onChange={(e) => this.handleCheckChange(key, e)} value={prop["Order_Id"]} />
          </td>
          <td>
            {prop["Request_Date"]}
            <span style={{color:'#FF0000'}}> <b> {prop["Ship_Method"] === "2nd Day Air" ? " 2nd Day Air" : ""} </b> </span>
            <span style={{color:'#FF0000'}}> <b>{prop["Ship_Method"] === "Overnight" ? " Overnight" : ""}</b> </span>
          </td>
          <td>
            
          <NavLink
            to={{
         pathname:'/admin/depotorderupdateview',
         state: {
          Order_id: prop["Order_Id"],
          goodsReq: "E"
         }
         }}                                        
         activeClassName="active">{prop["Order_Id"]}</NavLink>
            
            
          <span style={{color:'#FF0000'}}> <b> {prop["Billable"] === "1" ? " Billable" : ""}</b> </span>
          <span style={{color:'#FF0000'}}>  <b> {prop["Material"] === "1" ? " Clean" : ""}</b> </span>
          <span style={{color:'#FF0000'}}>  <b> {prop["NTBillable"] === "1" ? " NonBillable Transfer" : ""}</b> </span>

          </td>
          <td>{prop["ShipToName"]}</td>
          <td>{prop["Name"]}</td>
          <td>{prop["StatusDesc"]}</td>
        </tr>
      })
    }
    else {
      return <tr><td colSpan={8} align="center" className="text-danger font-size-xs small">Record not found</td></tr>
    }
  }
  render() {
    const { loading } = this.state;
    const alignCardCenter = {
      "margin": "0 0 0 0"
    };
    return (
      <div className="content">
         <FormModalSpinner show={this.state.modal.modalSpinnerShow }>   </FormModalSpinner>   
        <Container fluid>
          <Card border="light" style={{ width: '100%', height: '100%' }}>
            <Card.Body>
              <Card.Title><h4 className="title">Depot Order Update</h4></Card.Title>
              <Form onSubmit={this.onSubmit} >
                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                <Row style={alignCardCenter}>
                  <Col sm={12}>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                  
                    <Table  >
                      <thead style={{ backgroundColor: 'lightblue', fontSize: 'large' }}>
                        <tr>
                          <th>Select</th>
                          <th>Request Date</th>
                          <th>Order Id</th>
                          <th>Ship to</th>
                          <th>Attention</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.displayTableRecord()}
                      </tbody>
                    </Table>
                    
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <Button type="submit" disabled={loading} style={{ marginRight: "5px" }}>
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span>wait...</span>}
                      {!loading && <span>Cancel Order</span>}
                    </Button>
                    <span>   </span>
                    <Button type="reset" onClick={() => window.print()}>
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span>wait...</span>}
                      {!loading && <span>Print</span>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}
