import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class DepotOrdersController {
    serviceProxy: ServiceProxy;
    variables: Variables;
    searchInfo : any;
    dateTimeFormat : DateTimeFormat;
    constructor(){
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
        this.searchInfo = {userName: '', orderItem:'', nrecordLabelId:'',title:'',artist:'',selection:'',upc:'', exceptions:false}
    }

    getDepotPendingOrders(){    
            
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getDepotPendingOrders;            
        
        return this.serviceProxy.get(url,{}) 
        .then(response => {  
            var jsonDepotOrders : Array<{ "Billable" : string; "Order_Id" : string;  "Ship_Method" : string; "Request_Date" : string; "Material" : string; "ShipToName" : string; "Name" : string; "Status_Id" : string; "Approver_Id" : string; "StatusDesc" : string; "NTBillable" : string;}> = [];        
            if(response.success)
            {
                response.recordset.forEach(deptorder);
                function deptorder(item: {Billable : string,  Order_Id : string, Ship_Method : string, Request_Date: string,Material: string,ShipToName: string, Name: string, Status_Id: string, Approver_Id: string, StatusDesc: string, NTBillable: string} , _index: number)
                {                         
                    jsonDepotOrders.push({"Billable": item.Billable, "Order_Id": item.Order_Id, "Ship_Method": item.Ship_Method,"Request_Date": item.Request_Date, "Material" : item.Material, "ShipToName": item.ShipToName, "Name" : item.Name, "Status_Id" : item.Status_Id, "Approver_Id" : item.Approver_Id, "StatusDesc": item.StatusDesc, "NTBillable" : item.NTBillable })                  
                }

                return JSON.parse(JSON.stringify(jsonDepotOrders).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonDepotOrders).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    cancelPendingOrders(data1){
       
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.cancelPendingOrders;            
        var data2 : any = {
            username : data1.userName,
            orderItem : data1.orderItem            
        }
        return this.serviceProxy.put(url,data2).then(response => {
            return response;
        }).catch(error => {
            return error;
        }) 

    }

    depotProcessOrder(data11){
       
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.depotProcessOrder;            
        var data22 : any = {
            OrderId : data11.OrderId,
            depotCmts : data11.depotCmts,
            legacyOrder : data11.legacyOrder          
        }
        return this.serviceProxy.put(url,data22).then(response => {
            return response;
        }).catch(error => {
            return error;
        }) 

    }

    holdOrder(holddata){
       
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.holdOrder;            
        
        var Hdata : any = {
            orderId : holddata.orderId            
        }

        return this.serviceProxy.put(url,Hdata).then(response => {
            return response;
        }).catch(error => {
            return error;
        }) 

    }

    getDepotHistory(data){    
            
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getDepotHistory;            
        var data2 : any = {
            search_field : data.search_field,
            filter :data.filter,
            startDate: data.startDate,
            endDate: data.endDate            
        }
        return this.serviceProxy.get(url,data2) 
        .then(response => {  
            var jsonDepotHistory : Array<{ "billable" : string; "Requestor" : string;  "request_date" : string; "Order_Id" : string; "legacyorder" : string; "label_id" : string; "Name" : string; "ShipToName" : string; "ntbillablet" : string;"selection_id" : string}> = [];        
            if(response.success)
            {
                response.recordset.forEach(depthistory);
                function depthistory(item: {billable : string,  Requestor : string, request_date : string, Order_Id: string,legacyorder: string,label_id: string, Name: string, ShipToName: string, ntbillablet: string, selection_id: string} , _index: number)
                {                         
                    jsonDepotHistory.push({"billable": item.billable, "Requestor": item.Requestor, "request_date": item.request_date,"Order_Id": item.Order_Id, "legacyorder" : item.legacyorder, "label_id": item.label_id, "Name" : item.Name, "ShipToName" : item.ShipToName, "ntbillablet" : item.ntbillablet, "selection_id" : item.selection_id})                  
                }

                return JSON.parse(JSON.stringify(jsonDepotHistory).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonDepotHistory).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getDepotHistoryExport(data){    
            
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getDepotHistoryExport;            
        var data2 : any = {
            search_field : data.search_field,
            filter :data.filter,
            startDate: data.startDate,
            endDate: data.endDate            
        }
        return this.serviceProxy.get(url,data2) 
        .then(response => {  
            var jsonDepotHistoryExport : Array<{ "billable" : string; "Requestor" : string;  "request_date" : string; "Order_Id" : string; "legacyorder" : string; "label_id" : string; "Name" : string; "ShipToName" : string; "ntbillablet" : string;"selection_id" : string;"account_no" : string; "sales_type" : string;"Configuration":string;"Quantity_Approved":string;"price_approved":string}> = [];        
            if(response.success)
            {
                response.recordset.forEach(depthistory);
                function depthistory(item: {billable : string,  Requestor : string, request_date : string, Order_Id: string,legacyorder: string,label_id: string, Name: string, ShipToName: string, ntbillablet: string, selection_id: string, account_no: string, sales_type: string,Configuration: string,Quantity_Approved:string,price_approved:string} , _index: number)
                {                         
                    jsonDepotHistoryExport.push({"billable": item.billable, "Requestor": item.Requestor, "request_date": item.request_date,"Order_Id": item.Order_Id, "legacyorder" : item.legacyorder, "label_id": item.label_id, "Name" : item.Name, "ShipToName" : item.ShipToName, "ntbillablet" : item.ntbillablet, "selection_id" : item.selection_id, "account_no" : item.account_no, "sales_type" : item.sales_type,"Configuration":item.Configuration,"Quantity_Approved":item.Quantity_Approved,"price_approved":item.price_approved})                  
                }

                return JSON.parse(JSON.stringify(jsonDepotHistoryExport).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonDepotHistoryExport).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getOrderSummarySale(OrdSumData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getOrderSummary;          
        var data3 : any = {    
            orderId : OrdSumData.Order_ID           
        }
        return this.serviceProxy.get(url,data3)        
        .then(response => {                
            //console.log(response);    
            var resOrderdata = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));  
           // sessionStorage.setItem("Sale_Type",resOrderdata.sales_type);    
           return resOrderdata;
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });  
    }
    getOrderDetailsSummary(orderID : string){
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getOrderDetailsSummary;
        var data = {
            "orderID": orderID            
        }        
        return this.serviceProxy.get(url,data)
        .then(response => {                
            //console.log(response);    
            var resOrderdata = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));      
           return resOrderdata;
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });  
    }
    getOrderSummary(OrdSumData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getOrderSummary;          
        var data3 : any = {    
            orderId : OrdSumData.Order_ID           
        }
        return this.serviceProxy.get(url,data3) 
        .then(response => {  
            var jsonOrdSum : Array<{ "Order_Id" : string; "Label" : string;  "Alias_name" : string; "Alias_department" : string; "addresslineone" : string; "addresslinetwo" : string; "Alias_city" : string; "Alias_state" : string; "Alias_zip" : string; "Alias_phone" : string; "Alias_email" : string; "Statusdesc" : string; "Status_Id" : string; "Recordlabel_Id" : string; "NTBillable" : string; "Delivery_date" : string; "LegacyOrder" : string; "Request_Date" : string; "sales_type" : string; "Ship_Method" : string; "Material" : string; "Billable" : string; "freight_billing_info" : string; "account_no" : string; "CustomerNumber" : string; "BusinessUnit" : string; "Department" : string; "SpecialInstructions" : string; "Reason" : string; "Addressline" : string; "ship_Name":string; "ship_ShipToName":string; "ship_AddressLineOne":string; "ship_AddressLineTwo" :string; "ship_City":string; "ship_State":string; "ship_Zip":string; "ship_Country":string; "ship_Phone":string;}> = [];        
            if(response.success)
            {
                 response.recordset.forEach(deptorderSum);
                function deptorderSum(item: {Order_Id : string,  Label : string, Alias_name : string, Alias_department: string,addresslineone: string, addresslinetwo: string, Alias_city: string, Alias_state: string, Alias_zip: string, Alias_phone: string, Alias_email: string , Statusdesc: string , Status_Id: string , Recordlabel_Id: string , NTBillable: string, Delivery_date: string, LegacyOrder: string, Request_Date: string, sales_type : string, Ship_Method: string, Material: string, Billable : string, freight_billing_info:string, account_no:string, CustomerNumber:string, BusinessUnit:string, Department:string, SpecialInstructions:string,Reason:string, Addressline:string, ship_Name:string, ship_ShipToName:string, ship_AddressLineOne:string, ship_AddressLineTwo:string, ship_City:string, ship_State:string, ship_Zip:string, ship_Country:string, ship_Phone:string} , _index: number)
                {                         
                    sessionStorage.setItem("Sales_Type", item.sales_type);
                    sessionStorage.setItem("Order_Id", item.Order_Id);                                        
                    sessionStorage.setItem("Ship_Method", item.Ship_Method);
                    localStorage.setItem('Sale_Type', item.sales_type);

                    jsonOrdSum.push({"Order_Id": item.Order_Id, "Label": item.Label, "Alias_name": item.Alias_name,"Alias_department": item.Alias_department, "addresslineone" : item.addresslineone, "addresslinetwo": item.addresslinetwo, "Alias_city" : item.Alias_city, "Alias_state" : item.Alias_state, "Alias_zip" : item.Alias_zip, "Alias_phone": item.Alias_phone, "Alias_email" : item.Alias_email,"Statusdesc" : item.Statusdesc, "Status_Id" : item.Status_Id, "Recordlabel_Id" : item.Recordlabel_Id, "NTBillable" : item.NTBillable, "Delivery_date" : item.Delivery_date, "LegacyOrder" : item.LegacyOrder, "Request_Date" : item.Request_Date, "sales_type" : item.sales_type, "Ship_Method" : item.Ship_Method, "Material" : item.Material, "Billable" : item.Billable, "freight_billing_info" : item.freight_billing_info, "account_no" : item.account_no, "CustomerNumber" : item.CustomerNumber, "BusinessUnit": item.BusinessUnit, "Department" : item.Department, "SpecialInstructions":item.SpecialInstructions, "Reason":item.Reason, "Addressline" : item.Addressline, "ship_Name":item.ship_Name,"ship_ShipToName":item.ship_ShipToName,"ship_AddressLineOne":item.ship_AddressLineOne,"ship_AddressLineTwo":item.ship_AddressLineTwo, "ship_City":item.ship_City, "ship_State":item.ship_State, "ship_Zip":item.ship_Zip, "ship_Country":item.ship_Country, "ship_Phone":item.ship_Phone})                  
                }
                           
                return JSON.parse(JSON.stringify(jsonOrdSum).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonOrdSum).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getNextApprover(ApprData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getNextApprover;          
        var data4 : any = {  
            sortBy: "1",  
            orderId : ApprData.Order_ID           
        }
        return this.serviceProxy.get(url,data4) 
        .then(response => {  
            var jsonNxtAppr : Array<{ "Alias" : string; "Approver" : string; }> = [];        
            if(response.success)
            {
                response.recordset.forEach(deptAppr);
                function deptAppr(item: {Alias : string,  Approver : string} , _index: number)
                {                         
                    jsonNxtAppr.push({"Alias": item.Alias, "Approver": item.Approver})                  
                }

                return JSON.parse(JSON.stringify(jsonNxtAppr).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonNxtAppr).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    

    getOrderApprovalHistory(ApprHisData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getOrderApprovalHistory;          
        var data4 : any = {    
            orderId : ApprHisData.Order_ID,
            approverType :""// ApprHisData.approverType          
        }
        return this.serviceProxy.get(url,data4) 
        .then(response => {  
            var jsonApprHis : Array<{ "Approver" : string; "ApproverEmail" : string; "ApprovalDateTime" : string; "Notes" : string; "ApproverType" : string; "ApproverDatetime" : string;}> = [];        
            if(response.success)
            {
                response.recordset.forEach(ApprHis);
                function ApprHis(item: {Approver : string,  ApproverEmail : string, ApprovalDateTime : string, Notes : string, ApproverType : string, ApproverDatetime : string} , _index: number)
                {                         
                    jsonApprHis.push({"Approver": item.Approver, "ApproverEmail": item.ApproverEmail, "ApprovalDateTime": item.ApprovalDateTime, "Notes": item.Notes, "ApproverType": item.ApproverType, "ApproverDatetime" : item.ApproverDatetime})                  
                }

                return JSON.parse(JSON.stringify(jsonApprHis).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonApprHis).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getOrderDepotHistory(DepotHisData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getOrderDepotHistory;          
        var data4 : any = {    
            orderId : DepotHisData.Order_ID,                    
        }
        return this.serviceProxy.get(url,data4) 
        .then(response => {  
            var jsonDepotHis : Array<{ "CreateDateTime" : string; "Notes" : string;}> = [];        
            if(response.success)
            {
                response.recordset.forEach(DepotHis);
                function DepotHis(item: {CreateDateTime : string,  Notes : string} , _index: number)
                {                         
                    jsonDepotHis.push({"CreateDateTime": item.CreateDateTime, "Notes": item.Notes})                  
                }

                return JSON.parse(JSON.stringify(jsonDepotHis).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonDepotHis).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getOrderDetail(OrdSumData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getOrderDetail;          
        var data4 : any = {    
            orderId : '553974', 
            Item_ID : '0' // OrdSumData.orderID           
        }
        return this.serviceProxy.get(url,data4) 
        .then(response => {  
            var jsonOrdDetail : Array<{ "itemStatusDesc" : string; "item_id" : string;  "label_id" : string; "unitstoset" : string; "billable" : string; "Order_Id" : string; "Selection_Id" : string; "Configuration" : string; "order_item_status_id" : string; "Quantity_Approved" : string; "Quantity_Requested" : string; "ReqQty" : string; "DeptQty" : string; "DJCQty" : string; "Price_Requested" : string; "Price_Approved" : string; "Approval_Date" : string; "Artist" : string; "Title" : string; "ReleaseDate" : string; "alias" : string; "cost" : string;}> = [];        
            if(response.success)
            {
                response.recordset.forEach(ordDetail);
                function ordDetail(item: {itemStatusDesc : string,  item_id : string, label_id : string, unitstoset: string, billable: string, Order_Id: string, Selection_Id: string, Configuration: string, order_item_status_id: string, Quantity_Approved: string, Quantity_Requested: string , ReqQty: string , DeptQty: string , DJCQty: string , Price_Requested: string, Price_Approved: string, Approval_Date: string, Artist: string, Title : string, ReleaseDate: string, alias: string, cost : string} , _index: number)
                {                         
                    jsonOrdDetail.push({"itemStatusDesc": item.itemStatusDesc, "item_id": item.item_id, "label_id": item.label_id,"unitstoset": item.unitstoset, "billable" : item.billable, "Order_Id": item.Order_Id, "Selection_Id" : item.Selection_Id, "Configuration" : item.Configuration, "order_item_status_id" : item.order_item_status_id, "Quantity_Approved": item.Quantity_Approved, "Quantity_Requested" : item.Quantity_Requested,"ReqQty" : item.ReqQty, "DeptQty" : item.DeptQty, "DJCQty" : item.DJCQty, "Price_Requested" : item.Price_Requested, "Price_Approved" : item.Price_Approved, "Approval_Date" : item.Approval_Date, "Artist" : item.Artist, "Title" : item.Title, "ReleaseDate" : item.ReleaseDate, "alias" : item.alias, "cost" : item.cost})                  
                }

                return JSON.parse(JSON.stringify(jsonOrdDetail).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonOrdDetail).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getFreeGoodsOrderDetail(OrdSumData){ 

        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getFreeGoodsOrderDetail;          
        var data4 : any = {    
            orderId :OrdSumData.Order_ID, 
            Item_ID : '0' // OrdSumData.orderID           
        }
        return this.serviceProxy.get(url,data4) 
        .then(response => {  
            var jsonFreeOrdDetail : Array<{ "item_id" : string;  "label_id" : string; "unitstoset" : string; "Order_Id" : string; "Selection_Id" : string; "Configuration" : string; "Quantity_Approved" : string; "ReqQty" : string; "DeptQty" : string; "DJCQty" : string; "Artist" : string; "Title" : string; "ReleaseDate" : string; "RejectItem" : string; "Price_Requested" : string; "Price_Approved" : string; "cost" : string; "discount" : string}> = [];        
            if(response.success)
            {
                response.recordset.forEach(ordDetail);
                function ordDetail(item: {item_id : string, label_id : string, unitstoset: string, Order_Id: string, Selection_Id: string, Configuration: string, order_item_status_id: string, Quantity_Approved: string, ReqQty: string , DeptQty: string , DJCQty: string , Artist: string, Title : string, ReleaseDate: string, RejectItem: string, Price_Requested : string, Price_Approved : string, cost : string, discount : string} , _index: number)
                {                         
                    jsonFreeOrdDetail.push({"item_id": item.item_id, "label_id": item.label_id,"unitstoset": item.unitstoset,"Order_Id": item.Order_Id, "Selection_Id" : item.Selection_Id, "Configuration" : item.Configuration,"Quantity_Approved": item.Quantity_Approved,"ReqQty" : item.ReqQty, "DeptQty" : item.DeptQty, "DJCQty" : item.DJCQty, "Artist" : item.Artist, "Title" : item.Title, "ReleaseDate" : item.ReleaseDate, "RejectItem" : item.RejectItem, "Price_Requested" : item.Price_Requested, "Price_Approved" : item.Price_Approved, "cost" : item.cost, "discount" : item.discount})                  
                }

                return JSON.parse(JSON.stringify(jsonFreeOrdDetail).replace(/"\s+|\s+"/g, '"'));         
                
            }
            else{
                return JSON.parse(JSON.stringify(jsonFreeOrdDetail).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            return error;
        });
    }

    getDepotOrderDetail(orderID : string){
        var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.GetDepotOrderDetail;          
            var data = {
                "orderID": orderID           
            }        
            return this.serviceProxy.get(url,data)
            .then(response => {                
                var jsonOrdDetList : Array<{"itemStatusDesc":string;"item_id":string;"label_id":string;"unitstoset":string;"billable":string;"Order_Id":string;"Selection_Id":string;
                "Configuration":string;"order_item_status_id":string;"Quantity_Approved":string; "Quantity_Requested":string;"ReqQty":string;"DeptQty":string;"DJCQty":string;
                "Price_Requested":string;"Price_Approved":string;"Approver_Id":string;"Approval_Date":string;"Artist":string;"Title":string;
                "ReleaseDate":string; "alias":string;"cost":string; "ItemException":string;}> = [];
                if(response.success){      
                        response.recordset.forEach(list);                   
                    function list(item: {itemStatusDesc:string;item_id:string;label_id:string;unitstoset:string;billable:string;Order_Id:string;Selection_Id:string;
                        Configuration:string;order_item_status_id:string;Quantity_Approved:string; Quantity_Requested:string;ReqQty:string;DeptQty:string;DJCQty:string;
                        Price_Requested:string;Price_Approved:string;Approver_Id:string;Approval_Date:string;Artist:string;Title:string;
                        ReleaseDate:string; alias:string;cost:string; ItemException:string}, _index: number){
                            jsonOrdDetList.push({"itemStatusDesc":item.itemStatusDesc,"item_id":item.item_id,"label_id":item.label_id,"unitstoset":item.unitstoset,"billable":item.billable,"Order_Id":item.Order_Id,"Selection_Id":item.Selection_Id,
                            "Configuration":item.Configuration,"order_item_status_id":item.order_item_status_id,"Quantity_Approved":item.Quantity_Approved, "Quantity_Requested":item.Quantity_Requested,"ReqQty":item.ReqQty,"DeptQty":item.DeptQty,"DJCQty":item.DJCQty,
                            "Price_Requested":item.Price_Requested,"Price_Approved":item.Price_Approved,"Approver_Id":item.Approver_Id,"Approval_Date":item.Approval_Date,"Artist":item.Artist,"Title":item.Title,
                            "ReleaseDate":item.ReleaseDate, "alias":item.alias,"cost":item.cost, "ItemException":item.ItemException})
                    }
                    
                    return JSON.parse(JSON.stringify(jsonOrdDetList).replace(/"\s+|\s+"/g, '"'));
                }
                
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });  
        }

        getDepotOrderItemDetail(depdata) {
            var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getDepotOrderItemDetail;          
            var data : any = {
                OrderId: depdata.OrderId
            }
            return this.serviceProxy.get(url,data).then(response => {
                if(response.success){
                    var resOrderDetail = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                    return resOrderDetail;
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });
        }


        sendEmail(from: string, to: string, subject: string, html: string) {
            var url = this.variables.Url+ this.variables.Controller.createOrder + this.variables.createOrderService.sendEmail;
            var data = {
                from: from,
                to: to,
                subject: subject,
                html: html
            }
            return this.serviceProxy.post(url,data).then(response => {
                if(response.success){
                    return ;
                }
                else{
                    console.error("Error in sending email...," + response.message)
                    return;
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });
        }


        getDepotNextApprover(orderID : string){
            var url = this.variables.Url + this.variables.Controller.depotOrders + this.variables.depotOrderService.getDepotNextApprover;          
            var data = {
                "orderID": orderID           
            }        
            return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success) {
                var resNxtApproverdata = JSON.parse(JSON.stringify(response.recordset[0]).replace(/"\s+|\s+"/g, '"'));      
                return resNxtApproverdata;
                }
                else{
                  var  res = {"alias": "","Approver": ""}
                  var resNxtApproverdata = JSON.parse(JSON.stringify(res).replace(/"\s+|\s+"/g, '"'));
                  return resNxtApproverdata;
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });  
        }
    

    
}